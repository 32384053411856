<template>
  <div>
    <div v-if="readReports">
      <div class="flex flex-col h-full">
        <div
          class="relative p-5 overflow-hidden bg-white rounded shadow-md compliance-container"
        >
          <div
            class="flex items-center justify-between w-full pb-4 mb-3 border-b border-gray-300"
          >
            <div class="flex items-center">
              <div class="mr-3" style="width: 14rem">
                <div class="block mb-1 text-xs font-bold text-grey-500">
                  Date Range:
                </div>
                <DatePicker
                  ref="childDatePicker"
                  :date="selectedDateRange"
                  placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                  :displayBreach="false"
                  @selected="selectedDateRange = $event"
                  range
                  noNavigation
                  :clearable="false"
                />
              </div>
            </div>
            <div>
              <div class="">&nbsp;</div>
              <button
                :disabled="!selectedDateRange"
                :class="{ 'cursor-not-allowed': !selectedDateRange }"
                class="flex items-center btn btn-primary"
                @click.prevent="onGetReport"
                style="height: 38px"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Get Report
              </button>
            </div>
          </div>

          <div
            v-if="isActive"
            class="flex items-center w-full driver-info-container"
          >
            <CompliancePeriod
              ref="childCompliancePeriod"
              :disabledBefore="disabledBefore"
              @sendReportloading="sendReportloading = $event"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!readReports && hasRole"
      class="w-full min-h-full mt-5 text-center"
    >
      <h1>
        Not authorized to view reports. Please contact your business admin.
      </h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'send-report'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm
              v-model="sendReportModel"
              @submit="onSubmitSendReport"
            >
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="email"
                  label="Email address"
                  validation="required|email"
                  @input="handleStripEmailCase($event)"
                />
                <div>
                  <label class="mb-1 text-sm font-medium"
                    >Report Date Range</label
                  >
                  <DatePicker
                    ref="childDatePicker"
                    :date="selectedDateRange"
                    :disabledBefore="disabledBefore"
                    :displayEvent="false"
                    :displayBreach="false"
                    @selected="selectedDateRange = $event"
                    range
                    noNavigation
                    :clearable="false"
                    class="mt-1 mb-5 send-datepicker"
                  />
                </div>
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="w-full mt-5 btn btn-primary"
                  :disabled="!canSend() || sendReportloading"
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<style scoped lang="scss">
.active {
  @apply border-orange-primary;
}
.vertical {
  min-width: 7px;
  line-height: 7px;
  writing-mode: tb-rl;
  white-space: nowrap;
  text-align: center;
  transform: rotate(180deg);
}
.compliance-container {
  min-height: calc(100vh - 92px);
}
.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>

<script>
import _ from 'lodash';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { Modal } from '@/components/commons';
import DatePicker from '@/components/commons/field/DatePicker';
import CompliancePeriod from '@/components/view/compliance/period.vue';

export default {
  name: 'CompliancePeriodReport',
  components: { CompliancePeriod, Modal, DatePicker },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modal: {
        title: '',
        content: null,
        height: '50vh',
      },
      sendReportModel: null,
      sendReportloading: false,
      selectedDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
      ],
      readReports: false,
      disabledBefore: null,
      hasRole: true,
      initialFalse: false,
    };
  },
  watch: {
    selectedDateRange: {
      handler: function () {
        this.onSearch();
      },
      deep: true,
    },
  },
  async mounted() {
    this.$emit('updateLoading', true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = this.setSecurityAccess(securityRoles);
      this.readReports = securityAccessObj.readReports;
    } else {
      this.hasRole = false;
    }

    await this.onSearch();
    this.$emit('updateLoading', false);
    this.initialFalse = true;
  },
  methods: {
    canSearch() {
      return (
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },

    canSend() {
      return (
        !_.isEmpty(this.sendReportModel?.email) &&
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },

    setSecurityAccess: (securityRoles) => {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value;
        }
      });
      return {
        readReports: initialReadReports,
      };
    },

    async onSearch() {
      if (this.canSearch() && this.initialFalse) {
        await this.$refs.childCompliancePeriod.onSearch(this.selectedDateRange);
      }
    },

    onGetReport() {
      this.$refs.modal.openModal();
      this.modal.content = 'send-report';
      this.modal.title = 'Send Report To Email';
    },

    handleStripEmailCase(value) {
      this.sendReportModel.email = value.toLowerCase();
    },

    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      await this.$refs.childCompliancePeriod.onSubmitSendReport(recipientEmail);
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
