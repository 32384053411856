<template>
  <div class="mb-16">
    <div
      v-if="loading"
      class="loading absolute mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto"></ui-spinner>
      Generating modules options...
    </div>
    <FormulateForm v-if="!loading" v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          v-model="cplOptionSelection"
          :options="corePlatformLicencesOptions"
          type="checkbox"
          placeholder="Select an option"
          label="Select a Core Platform Licences"
          class="custom-options checkbox"
          name="selectedCPL"
        />

        <FormulateInput
          v-model="specialtyOptionSelection"
          :options="specialtyModulesOptions"
          type="checkbox"
          placeholder="Select an option"
          label="Select a Specialty Modules Licences"
          class="custom-options checkbox"
          name="selectedSpecialty"
        />

        <div v-if="showCPLSeatCount" class="p-5 border rounded-lg group mb-10">
          <div class="seatCountTitle pb-5 font-bold bg-gray-50 group-title">
            Set Core Platform Licences Seat Count
          </div>

          <div v-if="setSeatCountLoading" class="text-center">
            <ui-spinner class="mx-auto"></ui-spinner>
            Generating seat count form...
          </div>

          <div v-if="!setSeatCountLoading">
            <FormulateInput
              type="number"
              label="Set Core Platform Licences seat"
              name="corePlatformLicencesCount"
              placeholder="Input number of seat(s)"
              class="w-max"
              validation="^required"
              :validation-messages="{
                required: 'Core Platform Licences seat is required',
              }"
            />
          </div>
        </div>

        <div
          v-if="showSpecialtySeatCount"
          class="p-5 border rounded-lg group mb-10"
        >
          <div class="seatCountTitle pb-5 font-bold bg-gray-50 group-title">
            Set Specialty Modules Seat Count
          </div>

          <div v-if="setSeatCountLoading" class="text-center">
            <ui-spinner class="mx-auto"></ui-spinner>
            Generating seat count form...
          </div>

          <div v-if="!setSeatCountLoading">
            <FormulateInput
              v-for="(licenceSchema, index) in productSpecialtyModulesCount"
              :key="index"
              type="number"
              :label="licenceSchema.label"
              :name="licenceSchema.name"
              placeholder="Input number of seat(s)"
              class="w-max"
              validation="^required"
              :validation-messages="{
                required: `${licenceSchema.label} is required`,
              }"
            />
          </div>
        </div>

        <FormulateInput
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Module-Selection",
  props: {
    preData: {},
  },
  data() {
    return {
      loading: true,
      values: {},
      errorMessage: "",
      corePlatformLicencesOptions: [],
      specialtyModulesOptions: [],
      productCorePlatformLiceneCount: [],
      productSpecialtyModulesCount: [],
      setSeatCountLoading: false,
      showCPLSeatCount: false,
      showSpecialtySeatCount: false,
      cplOptionSelection: [],
      specialtyOptionSelection: [],
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const pricingSchema = await this.$store.dispatch(
        `standardpricing/getContractModuleMaster`
      );

      if (pricingSchema) {
        const excludedFromCPL = [
          "EWD-PT",
          "Maintenance Compliance",
          "Mass Compliance",
          "Pre-Paid EWD",
        ];
        const includeForSpecialtyModules = [
          "Maintenance Compliance",
          "Mass Compliance",
        ];

        const corePlatformLicences = pricingSchema.filter(
          (s) => !excludedFromCPL.includes(s.name)
        );
        const specialtyModules = pricingSchema.filter((s) =>
          includeForSpecialtyModules.includes(s.name)
        );

        this.loading = false;

        this.corePlatformLicencesOptions = corePlatformLicences.map(
          (values) => ({
            id: values._id,
            label: values.name === "EWD-FT" ? "EWD" : values.name,
            value: values._id,
            name: values._id,
          })
        );

        this.corePlatformLicencesOptions = orderBy(
          this.corePlatformLicencesOptions,
          ["label"],
          ["asc"]
        );

        this.specialtyModulesOptions = specialtyModules.map((values) => ({
          id: values._id,
          label: values.name,
          value: values._id,
          name: values._id,
        }));

        this.specialtyModulesOptions = orderBy(
          this.specialtyModulesOptions,
          ["label"],
          ["asc"]
        );
      }
    },
    async onSubmit() {
      const preFormatted = this.values;
      const specialtyValuesPreFormatted = Object.fromEntries(
        Object.entries(preFormatted).filter(
          ([key]) =>
            ![
              "corePlatformLicencesCount",
              "selectedCPL",
              "selectedSpecialty",
            ].includes(key)
        )
      );
      const cplValues = preFormatted.selectedCPL.map((cpl) => ({
        id: cpl,
        name: this.corePlatformLicencesOptions.find((m) => m.id === cpl).label,
        numberOfSeats: this.values.corePlatformLicencesCount,
      }));

      const specialtyModuleSelectionArray = [];
      Object.entries(specialtyValuesPreFormatted).forEach(([key, value]) => {
        specialtyModuleSelectionArray.push({
          id: key,
          name: this.specialtyModulesOptions.find((m) => m.id === key).label,
          numberOfSeats: value,
        });
      });

      let preData = {
        selectedModules: {
          cplValues,
          specialtyModulesValues: specialtyModuleSelectionArray,
        },
      };

      if (this.preData) {
        preData = { ...this.preData, ...preData };
      }

      this.$emit("nextStep", preData);
    },
  },
  watch: {
    cplOptionSelection: {
      handler() {
        this.showCPLSeatCount = this.values.selectedCPL.length;
        this.setSeatCountLoading = true;
        setTimeout(() => {
          this.setSeatCountLoading = false;
          this.productCorePlatformLiceneCount =
            this.values.selectedCPL.length > 0 &&
            this.corePlatformLicencesOptions.filter((values) =>
              this.values.selectedCPL.includes(values.id)
            );
        }, 500);
      },
    },
    specialtyOptionSelection: {
      handler() {
        this.showSpecialtySeatCount = this.values.selectedSpecialty.length;
        this.setSeatCountLoading = true;
        setTimeout(() => {
          this.setSeatCountLoading = false;
          this.productSpecialtyModulesCount =
            this.values.selectedSpecialty.length > 0 &&
            this.specialtyModulesOptions.filter((values) =>
              this.values.selectedSpecialty.includes(values.id)
            );
        }, 500);
      },
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
