<template>
  <div
    class="absolute right-0 w-56 mt-0 origin-top-right"
    aria-labelledby="headlessui-menu-button-1"
    id="headlessui-menu-items-117"
    role="menu"
  >
    <div
      class="absolute right-0 bg-white rounded-md shadow-lg overflow-hidden z-20 w-60"
    >
      <div v-if="!isGeotabAccount" class="py-0">
        <router-link
          to="/account/two-factor-authentication"
          class="block px-4 py-3 border-b hover:bg-logmaster-400-light -mx-2"
        >
          <span
            class="font-bold text-sm mx-2"
            :style="{ color: theme.primaryColor }"
            >Two-Factor Authentication</span
          >
        </router-link>
        <router-link
          to="/account/change-password"
          class="block px-4 py-3 border-b hover:bg-logmaster-400-light -mx-2"
        >
          <span
            class="font-bold text-sm mx-2"
            :style="{ color: theme.primaryColor }"
            >Change Password</span
          >
        </router-link>
        <router-link
          to="/account/theme"
          v-if="role == 'partner' || role == 'reseller'"
          class="block px-4 py-3 border-b hover:bg-logmaster-400-light -mx-2"
        >
          <span
            class="font-bold text-sm mx-2"
            :style="{ color: theme.primaryColor }"
            >Configure Theme</span
          >
        </router-link>
        <router-link
          to="/account/email-preferences"
          class="block px-4 py-3 border-b hover:bg-logmaster-400-light -mx-2"
        >
          <span
            class="font-bold text-sm mx-2"
            :style="{ color: theme.primaryColor }"
            >Email Preferences</span
          >
        </router-link>

        <router-link
          v-if="role === 'business'"
          to="/account/menu-settings"
          class="block px-4 py-3 border-b hover:bg-logmaster-400-light -mx-2"
        >
          <span
            class="font-bold text-sm mx-2"
            :style="{ color: theme.primaryColor }"
            >Menu Display Preference</span
          >
        </router-link>

        <router-link
          v-if="role === 'driver'"
          to="/account/change-email"
          class="block px-4 py-3 border-b hover:bg-logmaster-400-light -mx-2"
        >
          <span
            class="font-bold text-sm mx-2"
            :style="{ color: theme.primaryColor }"
            >Change Email</span
          >
        </router-link>
      </div>
      <a
        href="/logout"
        class="block bg-orange-primary-200 text-white text-center font-semibold py-2"
        :style="{ backgroundColor: theme.secondaryColor }"
        ><font-awesome-icon icon="sign-out-alt" /> Log Out</a
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "AccountMenu",
  props: {},
  data() {
    return {
      role: "",
    };
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    isGeotabAccount() {
      return this.$store.getters["account/getIsGeotabAccount"];
    },
  },
  mounted() {
    const me = this.$store.getters[`account/me`];
    this.role = me.role?.name;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
