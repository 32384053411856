<template>
  <div>
    <div class="h-full">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="apiQueryData"
        @onPageChange="onPageChange($event)"
      >
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search"
              class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template #callToActionTop>
          <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
            <button
              class="btn btn-primary"
              @click.prevent="disableOptions()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Usage Disable Options
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="showToken()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Show Token
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="newJson()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Add new JSON
            </button>
          </div>
        </template>
        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewQuery(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>

      <div class="overflow-x-auto relative shadow-md sm:rounded-lg mx-2 mt-5">
        <div class="w-64 my-9 mx-auto">
          <PieChart
            :chartData="chartData"
            :options="chartOptions"
            ref="parentChart"
          ></PieChart>
        </div>
      </div>
    </div>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'new'">
        <NewQueryJsonForm @closeModal="closeCreateModal" />
      </div>
      <div v-if="modalContent === 'view-query'">
        <ViewJsonQueryForm
          :formatted-json="formattedJson"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent === 'disable-usage'">
        <DisableUsageOptions @closeModal="closeModal" />
      </div>
    </Modal>
    <Modal
      ref="urlModal"
      :title="modalTitle"
      size="3xl"
      :height="tokenModalHeight"
    >
      <div>
        <div class="flex flex-col items-center mx-4 my-8">
          <h1
            class="text-2xl font-bold text-gray-900 mb-4 w-full break-words text-center"
          >
            {{ link }}
          </h1>
          <h3
            class="text-lg leading-6 font-medium text-gray-900 mb-4 w-full break-words"
            id="modal-title"
          >
            {{ token }}
          </h3>
          <div class="flex items-center mt-2 xs:mt-0 gap-4">
            <button
              class="btn btn-primary"
              @click.prevent="copyShareableLink()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              <span v-if="copyToken">Copied!</span>
              <span v-if="!copyToken">Copy Token</span>
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="generateToken()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              <span v-if="submitLoading"
                ><font-awesome-icon icon="spinner" class="mr-1 loader" />
                Generating token...</span
              >
              <span v-if="!submitLoading">Generate Token</span>
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="copyProdLink()"
              :style="{ backgroundColor: theme.primaryColor }"
            >
              <span v-if="copyLink">Copied!</span>
              <span v-if="!copyLink">Copy Link</span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import { Modal, Table, Dropdown } from "@/components/commons";
import PieChart from "@/components/commons/charts/PieChart";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import NewQueryJsonForm from "@/components/forms/business/admin-tools/one-api/new-query-json-form.vue";
import ViewJsonQueryForm from "@/components/forms/business/admin-tools/one-api/view-query-json-form.vue";
import DisableUsageOptions from "@/components/forms/business/admin-tools/one-api/disable-usage-options.vue";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "OneAPI",
  components: {
    DisableUsageOptions,
    Modal,
    Table,
    Dropdown,
    NewQueryJsonForm,
    ViewJsonQueryForm,
    PieChart,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      submitLoading: false,
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      tableHeader: ["Name", "Business ID", "Date Created"],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
      },
      token: "",
      copyToken: false,
      tokenModalHeight: "300px",
      apiQuery: [],
      formattedJson: {},
      percent: 0,

      link: "https://prod-api.logmaster.au/api/",
      copyLink: false,

      chartOptions: {
        hoverBorderWidth: 20,
      },

      chartData: {
        hoverBackgroundColor: "red",
        hoverBorderWidth: 10,
        labels: ["Used", "Left"],
        datasets: [
          {
            label: "Data usage",
            backgroundColor: ["#FF9900", "#2873a5"],
            data: [0, 0],
          },
        ],
      },
    };
  },

  async mounted() {
    this.isLoading = true;
    this.$emit("updateLoading", true);

    const me = this.$store.getters[`account/me`];
    this.me = me;

    const percentage = await this.$store.dispatch(
      `business/getApiUsagePercentage`,
      { businessId: me?.business?._id }
    );
    if (percentage.percent) {
      this.percent = Number(percentage.percent);
      this.chartData.datasets[0].data = [this.percent, 100 - this.percent];
    }

    this.$emit("updateLoading", false);
    await this.init();
  },
  methods: {
    //initialize content
    async init(page = 1) {
      this.isLoading = true;
      await this.getToken();
      await this.getQueries(page);

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async getQueries(paged) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      await this.$store
        .dispatch(`business/getQueryByBusiness`, {
          businessId: this.me.business._id,
          query,
        })
        .then((response) => {
          if (response) {
            if (response?.metadata) {
              this.paginationSettings = formatPaginationSettings(
                response?.metadata
              );
            } else {
              this.paginationSettings = formatPaginationSettings();
            }

            this.apiQuery = this.formatItem(
              _.orderBy(response.results, ["createdAt"], ["desc"])
            );
          } else {
            this.apiQuery = [];
          }
        })
        .catch((error) => {
          this.apiQuery = [];
          console.error(error);
        });
    },

    async getToken() {
      const response = await this.$store.dispatch(
        `business/getTokenByBusiness`,
        this.me.business._id
      );
      this.token = response.token;
    },

    async generateToken() {
      this.submitLoading = true;
      await this.$store
        .dispatch(`business/generateToken`)
        .then((response) => {
          if (response) {
            this.token = response.token;
          }
        })
        .catch((error) => {
          console.error(error);
        });
      this.submitLoading = false;
    },

    async copyShareableLink() {
      this.copyToken = true;
      await navigator.clipboard.writeText(this.token);
      setTimeout(() => {
        this.copyToken = false;
      }, 4000);
    },

    async copyProdLink() {
      this.copyLink = true;
      await navigator.clipboard.writeText(this.link);
      setTimeout(() => {
        this.copyLink = false;
      }, 4000);
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    debouncedSearchString() {
      this.init();
    },

    newJson: function () {
      this.modalContent = "new";
      this.modalTitle = "New JSON";
      this.$refs.modal.toggleModal();
    },

    showToken: function () {
      this.modalContent = "new";
      this.modalTitle = "Authorization Token for API";
      this.$refs.urlModal.toggleModal();
    },

    viewQuery: function (id) {
      this.modalContent = "view-query";
      this.modalTitle = "API Query";
      const query = this.apiQuery.find((item) => item.id === id);
      this.formattedJson = query.query;
      this.$refs.modal.toggleModal();
    },

    disableOptions: function () {
      this.modalContent = "disable-usage";
      this.modalTitle = "Usage Disable Options";
      this.$refs.modal.toggleModal();
    },

    closeModal() {
      this.$refs.modal.closeModal();
      this.$refs.urlModal.closeModal();
    },

    closeCreateModal() {
      this.$refs.modal.closeModal();
      this.init();
    },

    formatItem: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const apiQuery = {
            id: item?._id,
            name: item?.name,
            query: item?.query,
            businessId: item?.businessId,
            created: formatDate(item?.createdAt),
          };
          nItems.push(apiQuery);
        });
      }
      return nItems;
    },

    parseApiQuery(data) {
      return data.map((apiQuery) => [
        { id: apiQuery.id, name: apiQuery.name, itemType: "name" },
        { id: apiQuery.id, name: apiQuery.businessId, itemType: "string" },
        { id: apiQuery.id, name: apiQuery.created, itemType: "string" },
      ]);
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
  },
  computed: {
    apiQueryData() {
      if (this.apiQuery) {
        return this.parseApiQuery(this.apiQuery);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
