<template>
  <div class="mb-16">

    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>

    <div v-if="!loading">
      <div class="text-center mb-10">
        <div v-if="licenceRequest.status === 'pending'" class="flex text-right justify-end">
          <a @click.prevent="onClickEdit(licenceRequest.id)" class="flex cursor-pointer">
            <font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/>
          </a>
        </div>
        <div class="mx-auto w-24"><img class="rounded-md border" :src="licenceRequest.LogoLightBackground" alt=""></div>
        <div class="font-bold text-black-primary text-2xl">
          {{licenceRequest.name}}
        </div>
      </div>

      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Request Details</div>
      </div>

      <table class="table-auto text-center leading-normal w-full border-collapse border border-gray-200 mb-6 pb-5">
        <thead>
        <tr>
          <th class="bg-gray-200">Module</th>
          <th class="bg-gray-200">Licence Count</th>
          <th class="bg-gray-200">Unit Price</th>
          <th class="bg-gray-200">Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(seatDetails, index) in licenceRequest.addedSeats" :key="index">
          <td class="px-5 py-2 font-bold bg-gray-100">
            {{ seatDetails.module }}
          </td>
          <td class="px-2 py-1">
            {{ seatDetails.value}}
          </td>
          <td class="px-2 py-1">
            ${{ seatDetails.unitPrice.toFixed(2) }}
          </td>
          <td class="px-2 py-1">
            {{ parseFloat(seatDetails.total).toLocaleString("en-US", {style:"currency", currency:"USD"}) }}
          </td>
        </tr>
        </tbody>
      </table>

      <div v-if="licenceRequest.status === 'pending'" class="mt-8">
        <div class="flex items-center justify-end w-full mt-4 space-x-4">
          <FormulateInput
              type="button"
              label="Decline Request"
              input-class="btn btn-cancel w-full"
              @click.prevent="decline"
          />
          <FormulateInput
              type="submit"
              label="Accept Request"
              input-class="btn btn-primary w-full"
              :style="{ backgroundColor: theme.secondaryColor }"
              @click.prevent="accept"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
  name: 'Change-Licence-Request-Details',
  data(){
    return{
      licenceRequest: {},
      loading: false,
    }
  },
  props: {
    requestId: {
      type: String,
      required: true
    },
  },
  async created() {
    this.loading = true;
    await this.fetchRequest();
  },
  methods: {
    parseRequest(request){
      const seats = request?.addedSeats.filter(r => r.module !== 'One API');
      return {
        id: request._id,
        name: request?.businessId?.persona?.businessName,
        LogoLightBackground: request?.businessId?.businessLogoLightBackground,
        status: request?.status,
        addedSeats: seats,
      }
    },


    async fetchRequest(){
      const changeSeatRequestsData = await this.$store.dispatch(`contract/getChangeSeatCountRequest`, {id: this.requestId});
      this.licenceRequest = this.parseRequest(changeSeatRequestsData);
      this.loading = false;
    },


    onClickEdit(id){
      this.$emit('editRequest', id)
    },


    async accept() {
      const { value: answer } = await this.$swal.fire({
        title: `Accept Licence Change Request`,
        text: `Are you sure you want to ACCEPT this request? This action cannot be undone.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, accept it now!",
        cancelButtonText: "Cancel",
      });
      if (answer) {
        try {
          this.loading = true;
          const payload = {
            id: this.requestId,
            type: 'accept',
          }
          await this.$store.dispatch(`contract/respondChangeSeatCountRequest`, payload);
          await this.toast('success', 'Licence Count successfully added.');
          this.$emit('closeModal')
        } catch (error) {
          await this.toast('error', 'Failed to accept.');
        }
      }
    },


    async decline () {
      const { value: answer } = await this.$swal.fire({
        title: `Decline Licence Change Request`,
        text: `Are you sure you want to DECLINE this request? This action cannot be undone.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, decline it now!",
        cancelButtonText: "Cancel",
      });
      if (answer) {
        try {
          this.loading = true;
          const payload = {
            id: this.requestId,
            type: 'decline',
          }
          await this.$store.dispatch(`contract/respondChangeSeatCountRequest`, payload);
          await this.toast('success', 'Request successfully declined.');
          this.$emit('closeModal')
        } catch (error) {
          await this.toast('error', 'Failed to decline.');
        }
      }
    },


    async toast(state, msg) {
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true)
      this.$store.commit('setMessageNotify', message)
    },

  },

  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }

}
</script>