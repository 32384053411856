import { Accreditation } from "@/api";
import moment from "moment";

export default {
  async getAllAccreditation({ state }, data) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const response = await Accreditation.getAllAccreditation(
        data?.userData,
        data?.pagination
      );
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async findOneCert({ state }, data) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const response = await Accreditation.findOneCertification(data?.id);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async fetchCertificationById({ state }, data) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.fetchCertificationById(data);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async fetchCertificationByCertNumber({ state }, data) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { certNumber, query } = data;
      const response = await Accreditation.fetchCertificationByCertNumber(
        certNumber.certNumber,
        query
      );
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async submitAccreditation({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.submitAccreditation(payload);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async assignDriver({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { data, certId } = payload;
    data.inductionDate = moment(data.inductionDate).format("YYYY-MM-DD");
    try {
      const response = await Accreditation.assignDriver(data, certId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async unAssignDriver({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { data, certId } = payload;
      data.inductionDate = moment(data.inductionDate).format("YYYY-MM-DD");
      const response = await Accreditation.unAssignDriver(data, certId);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async uploadAccreditationFile({ state }, file) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.uploadAccreditationFile(file);
      return response;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  async fetchAssociatedCertificates({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.fetchAssociatedCertificates(
        payload.id,
        payload.status,
        payload.query
      );
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async fetchDriversProfile({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.fetchDriversProfile(
        payload.certId,
        payload.query,
        payload.isPending
      );
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async uploadUserDocument({ state }, file) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.uploadUserDocument(file);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async fetchUserDocuments({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { id, query } = payload;
    try {
      const response = await Accreditation.fetchUserDocuments(id, query);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async fetchDriverUserDocuments({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { id, file, status } = payload;
      const response = await Accreditation.fetchDriverUserDocuments(
        id,
        file,
        status,
        payload.query
      );
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async checkDriverConsent({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { certId, driverId } = payload;
    try {
      const response = await Accreditation.checkDriverConsent(certId, driverId);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async checkConsentDriverUserDocument({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { docId, driverId } = payload;
    try {
      const response = await Accreditation.checkConsentDriverUserDocument(
        docId,
        driverId
      );
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async consentDriverUserDocument({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { docId, driverId } = payload;
    try {
      const response = await Accreditation.consentDriverUserDocument(
        docId,
        driverId
      );
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async consentDriver({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { id, driverId } = payload;
    try {
      const response = await Accreditation.consentDriver(id, driverId);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async reviewDriver({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { id, driverId, isApproved } = payload;
    try {
      const response = await Accreditation.reviewDriver(
        id,
        driverId,
        isApproved
      );
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async fetchTemplates({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.fetchTemplates(payload.query);
      return response;
    } catch (err) {
      console.log("error:", err);
      throw new Error(err);
    }
  },

  async fetchTemplatesByBusiness({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { query, businessId } = payload;
      const response = await Accreditation.fetchTemplatesByBusiness(
        query,
        businessId
      );
      return response;
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchOneTemplate({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const { templateId } = payload;
      const response = await Accreditation.fetchOneTemplate(templateId);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async generateTemplate({ state }) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.generateTemplate();
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async createTemplate({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Accreditation.createTemplate(
        payload.sanitized,
        payload.name,
        payload.documentType
      );
      return response;
    } catch (err) {
      console.log("error:", err);
      throw new Error(err);
    }
  },

  async fetchPreviewImages({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { signedUrl } = payload;
      const response = await Accreditation.fetchPreviewImages(signedUrl);
      return response;
    } catch (err) {
      console.log("error:", err);
      throw new Error(err);
    }
  },

  async activateTemplate({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { templateId } = payload;
      const response = await Accreditation.activateTemplate(templateId);
      return response;
    } catch (e) {
      console.log("error:", e);
      throw new Error(e);
    }
  },

  async editCertTemplate({ state }, payload) {
    if (!state) throw `Invalid ${state}`;
    try {
      const { certId, templateId } = payload;
      const response = await Accreditation.editCertTemplate(certId, templateId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async updateCertTemplate({ state }, payload) {
    if (!state) throw `Invalid ${state}`;
    try {
      const { content, templateId } = payload;
      const response = await Accreditation.updateCertTemplate(
        content,
        templateId
      );
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async editCert({ state }, updatePayload) {
    if (!state) throw `Invalid ${state}`;
    try {
      const { id, payload } = updatePayload;
      const response = await Accreditation.editCert(id, payload);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async setDefaultTemplate({ state }, payload) {
    if (!state) throw `Invalid ${state}`;
    try {
      const { templateId } = payload;
      const response = await Accreditation.setDefaultTemplate(templateId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async isDefault({ state }, data) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { templateId, businessId } = data;
      const response = await Accreditation.isDefault(templateId, businessId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async resetDefault({ state }, data) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { businessId } = data;
      const response = await Accreditation.resetDefault(businessId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async regenerate({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { certId, userId } = payload;
      const response = await Accreditation.regenerate(certId, userId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async removeTemplate({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const { certId } = payload;
      const response = await Accreditation.removeTemplate(certId);
      return response;
    } catch (e) {
      throw new Error(e);
    }
  },

  async renewCertificate({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    const { renewalPayload, id } = payload;
    try {
      return await Accreditation.renewCertificate(id, renewalPayload);
    } catch (e) {
      throw new Error(e);
    }
  },

  async updateActivity({ state }, certId) {
    if (!state) throw `Invalid + ${state}`;
    try {
      return Accreditation.updateActivity(certId);
    } catch (e) {
      throw new Error(e);
    }
  },

  async updateExpiry({ state }, { docId, expiryDate }) {
    if (!state) throw `Invalid + ${state}`;
    try {
      return Accreditation.updateExpiry(docId, expiryDate);
    } catch (e) {
      throw new Error(e);
    }
  },
};
