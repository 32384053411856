<template>
  <div class="container">
    <Tabs>
      <Tab title="Pre Start">
        <DriverPreStart></DriverPreStart>
      </Tab>
      <Tab title="Fitness Declaration">
        <DriverFitnessDeclarations></DriverFitnessDeclarations>
      </Tab>
    </Tabs>
  </div>
</template>


<script>

import Tab from "@/components/commons/tab/Tab.vue";
import Tabs from "@/components/commons/tab/Tabs.vue";
import DriverPreStart from '@/pages/driver/reports/PreStart';
import DriverFitnessDeclarations from '@/pages/driver/reports/FitnessDeclarations';


export default {
  name: "ReportDaily",
  components: { DriverPreStart, DriverFitnessDeclarations, Tab, Tabs },
}

</script>
