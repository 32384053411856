<template>
  <div class="w-full min-h-full items-center">
    <div class="font-bold text-lg mb-3 focus-within: text-green-500">
      Evidence Collection Sheet
    </div>
    <hr />

    <section v-if="activeDocView" class="my-6">
      <div class="mb-5">
        <div class="text-xs">Person:</div>
        <ul
          v-for="(person, index) in activeDocView.persons"
          :key="index"
          class="font-bold text-black-primary text-base"
        >
          <li>{{ person.name }}&nbsp;</li>
        </ul>
      </div>

      <div class="mb-5">
        <div class="text-xs">Date:</div>
        <div class="font-bold text-black-primary text-base">
          {{ cleanDateFormat(activeDocView.evidenceCollectionDate) }}&nbsp;
        </div>
      </div>

      <div class="mt-7 pb-2">
        <div class="text-xs mb-2">Files:</div>
        <div class="overflow-x-auto relative sm:rounded-lg bg-white">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs border text-gray-700 uppercase dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="w-20 py-3 px-6 dark:bg-gray-800">
                  Image
                </th>
                <th scope="col" class="py-3 px-6">File Name</th>
                <th scope="col" class="w-24 py-3 px-6 text-center">Type</th>
                <th scope="col" class="w-24 py-3 px-6 text-center">Action</th>
              </tr>
            </thead>
            <tbody v-if="activeDocView.evidenceCollectionFiles">
              <tr
                v-for="(item, index) in activeDocView.evidenceCollectionFiles"
                :key="index"
                class="border-b border-gray-200 dark:border-gray-700"
              >
                <th
                  scope="row"
                  class="pt-5 pb-7 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white dark:bg-gray-800"
                >
                  <img
                    v-if="item.isImage"
                    width="50"
                    class="shadow-lg"
                    :src="item.file"
                  />
                  <font-awesome-icon
                    v-else
                    icon="file-pdf"
                    class="text-red-700 shadow-lg text-4xl ml-2"
                  />
                </th>
                <td class="pt-3 pb-5 px-6">
                  {{ item.originalfileName }}
                </td>
                <td class="text-center pt-4 pb-5 px-6">
                  {{ item.isImage ? "Image" : "PDF" }}
                </td>
                <td class="text-center pt-4 pb-5 px-6">
                  <a
                    class="cursor-pointer hover:text-orange-primary"
                    @click="downloadFile(item)"
                    >Download</a
                  >
                </td>
              </tr>
              <tr
                v-if="!activeDocView.evidenceCollectionFiles.length"
                class="border-gray-200 dark:border-gray-700"
              >
                <td colspan="3" class="py-8 text-sm text-center text-gray-400">
                  No files found
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="my-5">
        <div class="text-xs">Details:</div>
        <div class="text-black-primary text-base h-auto">
          {{ activeDocView.evidenceCollectionDetails }}&nbsp;
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { cleanDateFormat } from "@/_helper";

export default {
  name: "Evidence-Collection-View",
  props: {
    activeDocView: null,
  },

  methods: {
    cleanDateFormat(date) {
      return cleanDateFormat(date);
    },
    downloadFile(evidence) {
      const { file: fileInBase64, originalfileName } = evidence;
      const cleanBase64 = fileInBase64.split(",")[1];
      // Convert the base64 data to a Blob
      const sliceSize = 512;
      const byteCharacters = atob(cleanBase64);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: "application/octet-stream" });

      // Create a download link
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Set the file name (you can customize the file name)
      link.download = originalfileName;

      // Append the link to the document and trigger the click event
      document.body.appendChild(link);
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    },
  },
};
</script>
