<template>
  <div class="w-full">
    <div
      v-if="loading"
      class="loading mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-if="!loading">
      <div class="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 mx-5 px-5">
        <div v-for="(marketPlaceItem, index) in marketplaceItems" :key="index">
          <Card
            :cardBackgroundColor="'white'"
            :cardWidth="'90%'"
            :maxCardHeight="'350px'"
            :minCardHeight="'350px'"
          >
            <template slot="cardContent">
              <div class="flex items-center w-full justify-between p-10">
                <div class="w-1/3">
                  <div
                    class="h-24 w-24 bg-gray-200 border rounded-full overflow-hidden border-3 border-gray-300 flex"
                  >
                    <img
                      v-if="marketPlaceItem.logo"
                      :src="marketPlaceItem.logo"
                      alt=""
                      class="object-cover"
                      style="max-width: 200px"
                    />
                    <font-awesome-icon
                      v-if="!marketPlaceItem.logo"
                      icon="user"
                      class="text-5xl m-auto text-gray-600"
                    />
                  </div>
                </div>
                <div class="w-2/3">
                  <div
                    class="text-sm flex flex-row-reverse"
                    :style="{ color: theme.secondaryColor }"
                  >
                    <button
                      class="btn btn-primary text-white"
                      @click.prevent="showWarning(marketPlaceItem, false)"
                      :style="{ backgroundColor: theme.secondaryColor }"
                      v-if="marketPlaceItem.subscribed"
                    >
                      Unsubscribe
                    </button>
                    <button
                      class="btn btn-primary text-white"
                      @click.prevent="showWarning(marketPlaceItem, true)"
                      :style="{ backgroundColor: theme.secondaryColor }"
                      v-if="!marketPlaceItem.subscribed"
                    >
                      Subscribe
                    </button>
                  </div>
                </div>
              </div>
              <div class="px-12">
                <div
                  class="text-xl font-bold mb-3"
                  :style="{ color: theme.secondaryColor }"
                >
                  {{ marketPlaceItem.name }}
                </div>
                <div
                  class="text-base mb-3"
                  :style="{ color: theme.secondaryColor }"
                  style="max-height: 100px; overflow-y: scroll"
                >
                  {{ marketPlaceItem.description }}
                </div>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'view'">
        <div>
          <div class="mb-16">
            <div class="text-center mb-10">
              <div class="font-bold text-black-primary text-2xl">
                {{ marketplaceItem.name }}
              </div>
            </div>
            <div>
              <div class="mb-4 w-full border-b pb-1"></div>
              <div v-show="!isSubscribing" class="mb-5">
                <div class="font-bold text-black-primary text-base">
                  To unsubscribe this module you need to contact your partner.
                </div>
              </div>
              <div v-show="isSubscribing" class="mb-5">
                <div class="flex flex-row">
                  <div class="font-bold text-black-primary text-base">
                    Are you sure you want to subscribe?
                  </div>
                </div>
                <div class="flex flex-row mt-3">
                  <button
                    @click.prevent="subscribe()"
                    class="w-1/2 px-2 mr-2 btn btn-primary"
                    :disabled="submitLoading"
                  >
                  <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" />Confirming...</span>  
                  <span v-else>Confirm</span>
                  </button>
                  <button
                    @click.prevent="closeModal()"
                    class="w-1/2 px-2 ml-2 btn btn-primary"
                    :style="{
                      background: theme.primaryColor,
                      color: 'white',
                    }"
                    :disabled="submitLoading"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
import { Modal, Card } from "@/components/commons";
import { PARTNER_ROLE, RESELLER_ROLE } from "@/constants/usertype";

export default {
  name: "MarketplaceSubscriptionTemp",
  components: { Modal, Card },
  data() {
    return {
      loading: false,
      isSubscribing: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      subscribed: false,
      marketplaceItem: {},
      marketplaceItems: [],
      me: null,
      subscribedItems: [],
      submitLoading: false,
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      this.me = this.$store.getters[`account/me`];
      this.me?.business?.modules?.map((module) =>
        this.subscribedItems.push(module.apiTag)
      );
      this.marketplaceItems = await this.getMarketplaceItems();
      this.loading = false;
    },

    async getMarketplaceItems() {
      let parentId;

      if (this.me?.business?.parentRole === RESELLER_ROLE.name) {
        const reseller = await this.$store.dispatch(
          `reseller/getResellerByUID`,
          this.me?.business?.parentUid
        );
        parentId = reseller?._id;
      } else if (this.me?.business?.parentRole === PARTNER_ROLE.name) {
        const partner = await this.$store.dispatch(
          `partner/getPartnerByUID`,
          this.me?.business?.parentUid
        );
        parentId = partner?._id;
      }

      const items = await this.$store.dispatch(
        `business/getMarketplaceItems`,
        parentId
      );
      return this.formatMarketplaceItems(items);
    },

    async subscribe() {
      this.submitLoading = true;
      let seatCount = 1;
      const contractModuleMaster = await this.$store.dispatch(
        `business/getContractModuleMaster`
      );
      const contractModule = contractModuleMaster.find(
        (contract) =>
          contract.apiTag === this.marketplaceItem.standardPricingMaster.apiTag
      );
      const monthly = this.marketplaceItem.pricing?.monthly;
      const yearly = this.marketplaceItem.pricing?.yearly;
      const businessLicenceManagement = await this.$store.dispatch(
        "contract/getBusinessContract",
        { businessId: this.me?.business?._id }
      );
      if (businessLicenceManagement.results[0]?.businessModules[0]?.minimums) {
        seatCount =
          businessLicenceManagement.results[0].businessModules[0].minimums;
      }
      const payload = {
        minimums: seatCount,
        price: {
          monthly: Number(monthly),
          yearly: Number(yearly),
        },
        masterId: contractModule._id,
        ownerMongoId: this.me?.business?._id,
        ownerRole: this.me?.role?.name,
      };

      try {
        const module = await this.$store.dispatch("business/createContractModule", payload);
        await this.$store.dispatch("billinginvoice/addInvoiceItemAndUpdateInvoice", {
          contractId: this.me.contract._id,
          moduleId: module.data.data._id
        })
        this.toast("success", "Module subscribed successfully");
        await this.init();
        await this.$store.dispatch(`account/updateMenuItems`, this.me);
        this.submitLoading = false;
      } catch (err) {
        let errorMessage = `Something went wrong please try again.`;
        if (err.message) errorMessage = err.message[0];
        this.toast("error", errorMessage);
        this.submitLoading = false;
      } finally {
        this.closeModal();
      }
    },

    async showWarning(marketplaceItem, isSubscribing) {
      this.$refs.modal.openModal();
      this.modal.content = "view";
      this.modal.title = isSubscribing ? "Confirmation" : "Reminder";
      this.marketplaceItem = marketplaceItem;
      this.isSubscribing = isSubscribing;
    },

    momentFormat(date, isShort = false) {
      if (date) {
        if (isShort) {
          return date ? moment(date).format("MMM D, YYYY") : "-";
        } else {
          return date ? moment(date).format("MMMM D, YYYY") : "-";
        }
      }
    },
    formatMarketplaceItems(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          if (item.standardPricingMaster.apiTag != "ewd-ft") {
            const marketplaceItem = {
              id: item["_id"],
              name: item["standardPricingMaster"]["name"] || "",
              createdAt: formatDate(item["createdAt"]) || "",
              isActive: item["isActive"] ? "Active" : "Inactive" || "",
              isRrp: item["isRrp"] ? "RRP" : "Non RRP" || "",
              ownerMongoId: item["ownerMongoId"] || "",
              ownerRole: item["ownerRole"] || "",
              type: item["type"] || "",
              logo: item["standardPricingMaster"]["logo"] || "",
              pricing: item["pricing"] || {},
              standardPricingMaster: item["standardPricingMaster"] || {},
              description: item["standardPricingMaster"]["description"],
              subscribed: this.subscribedItems.includes(
                item.standardPricingMaster.apiTag
              ),
            };
            nItems.push(marketplaceItem);
          }
        });
      }
      return nItems;
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.marketplace-card {
  flex: 1 1 32%;
  list-style: none;
}
</style>
