<template>
  <div class="login-container items-center max-w-screen-lg mx-auto">
    <div class="w-40 relative mx-auto">
      <img
        src="https://api.lorem.space/image/face?w=120&h=120&hash=bart89fe"
        class="rounded-full border-8 border-gray-300 mx-auto w-40"
        alt="profile picture"
        srcset=""
      />
      <div
        class="opacity-0 cursor-pointer rounded-full hover:opacity-50 duration-300 bg-gray-600 absolute inset-0 z-10 flex justify-center items-center pt-24 text-xl text-white font-semibold"
      >
        <div class="flex flex-col center">
          <font-awesome-icon icon="edit" class="mx-auto" />
          <div>Edit</div>
        </div>
      </div>
    </div>
    <div
      class="rounded-lg mt-2 mx-auto w-full md:w-3/5 p-6 bg-gray-200 flex items-center justify-center"
    >
      <div v-if="loading" class="text-center">
        <Spinner class="m-auto mb-2"></Spinner> Loading user profile...
      </div>
      <FormulateForm
        class="w-full mx-6"
        :disabled="isSubmitting"
        v-else
        v-model="values"
        :schema="schema"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="w-full flex justify-center space-x-4 items-center">
          <FormulateInput
            type="submit"
            label="Update Profile"
            input-class="btn btn-primary"
            :disabled="hasErrors"
            v-if="!isSubmitting"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
          <FormulateInput
            type="button"
            disabled
            input-class="btn btn-primary w-full"
            v-else
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <font-awesome-icon :icon="['fas', 'spinner']" spin />&nbsp;
            Submitting form....
          </FormulateInput>
          <router-link to="/account/profile" custom v-slot="{ navigate }">
            <button
              @click="navigate"
              :disabled="!isSubmitting"
              class="btn mb-3 bg-gray-400 hover:bg-gray-500 text-white"
              role="link"
            >
              Cancel
            </button>
          </router-link>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import schema from "./edit-userprofile-schema";
import Spinner from "@/components/commons/ui/Spinner";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "Edit-User-Pofile-Form",
  components: {
    Spinner,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      schema: schema,
      loading: false,
      isSubmitting: false,
      values: {},
    };
  },
  created() {
    this.loading = true;
    this.init();
  },
  methods: {
    async init() {
      const profile = await { ...this.profileData };
      this.values = await {
        _id: profile._id,
        name: profile.driverName,
        licenseNumber: profile.driversLicenseNumber,
        dateOfBirth: moment(profile.driverDateOfBirth).format("YYYY-MM-DD"),
        state: profile.driversLicenseState,
      };
      this.loading = false;
    },
    async onSubmit() {
      // const dateOfBirth = moment.tz(this.values.dateOfBirth, "Australia/Sydney")
      this.isSubmitting = true;
      const data = {
        _id: this.values._id,
        driverName: this.values.name,
        driversLicenseNumber: this.values.licenseNumber,
        driverDateOfBirth: this.values.dateOfBirth,
        driversLicenseState: this.values.state,
      };
      const response = await this.$store.dispatch(
        "driver/updateDriverProfile",
        data
      );

      if (response.success === true) {
        this.loading = false;
        this.toast("success", "Profile successfully updated.");
        const getUpdatedProfile = await this.$store.dispatch("account/getMe");

        if (getUpdatedProfile) {
          this.$router.push("/account/profile");
        }
      } else {
        this.loading = false;
      }
    },

    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
