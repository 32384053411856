<template>
  <div>
    <FormulateForm v-model="values" @submit="nextStep" #default="{ hasErrors }">
      <FormulateInput
        type="select"
        :options="providers"
        name="provider"
        label="Provider"
        validation="required"
      />
      <StyledSubmit :backgroundColor="theme.secondaryColor">
        <FormulateInput
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors"
        />
      </StyledSubmit>
    </FormulateForm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";
export default {
  name: "Fleet-Provider-Selection",
  components: {
    StyledSubmit,
  },
  data() {
    return {
      values: {
        provider: null,
      },

      providers: [
        {
          label: "Geotab",
          value: "geotab",
        },
        {
          label: "Mix",
          value: "mix",
        },
        {
          label: "Verizon",
          value: "verizon",
        },
        {
          label: "Location Management Services",
          value: "lms",
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.$emit("nextStep", { provider: this.values.provider });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
