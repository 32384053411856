<template>
  <div class="mb-16">

    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>

    <div v-if="!loading">
      <div class="text-center mb-10">
        <div class="mx-auto w-24"><img class="rounded-md border" :src="licenceRequest.LogoLightBackground" alt=""></div>
        <div class="font-bold text-black-primary text-2xl">
          {{licenceRequest.name}}
        </div>
      </div>

      <div class="form border-b-2 border-neutral-100 mb-4">
        <template v-for="(seat, index) in licenceRequest.addedSeats">
          <div :key="index">
            <div>
              <div class="font-bold text-sm border-b-2 mb-2 border-neutral" >
                {{ seat.module }}
              </div>
              <div class="flex justify-between items-center">
                <div class="flex justify-start space-x-2 items-center mb-2">
                  <FormulateInput
                      type="number"
                      :name="`${seat.module}-seat`"
                      v-model="seat.value"
                      input-class="shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500"
                      outer-class="z-50"
                      validation="required|number|min:-100"
                      min="-100"
                      max="100"
                      error-behavior="live"
                      :disabled="submitLoading"
                  />
                  <div class="text-sm text-gray-800"> seat @ ${{ getActualPrice(seat) }}/{{ billPeriod }} each.</div>
                </div>
                <div class="font-bold">
                  {{ getProratedSeatAmount(seat, index) }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>

      <div class="flex flex-col mb-4 pb-4">
        <div class="flex text-lg justify-between items-center">
          <div class="text-gray-800 font-bold">
            Total
          </div>
          <div class="text-gray-800 font-bold">
            {{ billTotal.toLocaleString("en-US", {style:"currency", currency:"USD"}) }}
          </div>
        </div>
      </div>

      <div class="mt-8">
        <div class="flex items-center justify-end w-full mt-4 space-x-4">
          <FormulateInput
              type="submit"
              label="Save"
              input-class="btn btn-primary w-full"
              :style="{ backgroundColor: theme.secondaryColor }"
              @click.prevent="save"
          />
        </div>
      </div>
    </div>

  </div>
</template>
<script>

import { mapGetters } from "vuex";

export default {
  name: 'Change-Licence-Request-Edit',
  data(){
    return{
      licenceRequest: {},
      loading: false,
      submitLoading: false,
      billPeriod: '',
      billTotal: 0,
    }
  },
  props: {
    requestId: {
      type: String,
      required: true
    },
  },
  async created() {
    this.loading = true;
    await this.fetchRequest();
  },
  methods: {
    parseRequest(request){
      const seats = request?.addedSeats.filter(r => r.module !== 'One API');
      return {
        id: request._id,
        name: request?.businessId?.persona?.businessName,
        LogoLightBackground: request?.businessId?.businessLogoLightBackground,
        status: request?.status,
        addedSeats: seats,
        contractId: request?.contractId,
        contractMongoId: request?.contractId?._id,
        businessMongoId: request?.businessId?._id,
      }
    },


    async fetchRequest(){
      const changeSeatRequestsData = await this.$store.dispatch(`contract/getChangeSeatCountRequest`, {id: this.requestId});
      this.licenceRequest = this.parseRequest(changeSeatRequestsData);

      switch(this.licenceRequest.contractId.billingPeriod.name.toUpperCase()) {
        case "MONTHLY":
          this.billPeriod = "month"
          break;
        case "QUARTERLY":
          this.billPeriod = "quarter"
          break;
        case "YEARLY":
          this.billPeriod = "year"
          break;
      }

      this.loading = false;
    },


    async save() {
      const { value: answer } = await this.$swal.fire({
        title: `Edit Licence Change Request`,
        text: `You are just editing this request, NOT ACCEPTING! You still have to accept the request.`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, I understand!",
        cancelButtonText: "Cancel",
      });
      if (answer) {
        try {
          this.loading = true;
          let payload = {
            id: this.requestId,
            addedSeats: this.licenceRequest.addedSeats,
            businessId: this.licenceRequest.businessMongoId,
            contractId: this.licenceRequest.contractMongoId,
            remainingDays: 0,
            daysPassed: 0,
            isProrated: false,
            total: this.billTotal,
          }

          await this.$store.dispatch(`contract/updateChangeSeatCountRequest`, payload);
          await this.toast('success', 'Licence Count successfully added.');
          this.$emit('closeModal')
        } catch (error) {
          await this.toast('error', 'Failed to accept.');
        }
      }
    },


    async toast(state, msg) {
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true)
      this.$store.commit('setMessageNotify', message)
    },


    getActualPrice(module) {
      return module.unitPrice.toFixed(2);
    },

    getProratedTotalAmount() {
      let totalAmount = 0;
      this.licenceRequest.addedSeats.forEach((data) => {totalAmount += data.value * data.unitPrice});

      this.billTotal = totalAmount;
    },

    getProratedSeatAmount(module, index) {
      const actualPrice = this.getActualPrice(module)
      let totalProratedPrice = 0, perSeatPrice = 0;

      totalProratedPrice = parseFloat((actualPrice * module.value).toString()).toFixed(2);
      perSeatPrice = parseFloat(actualPrice).toFixed(2);

      this.licenceRequest.addedSeats[index].total = parseFloat(totalProratedPrice);
      this.licenceRequest.addedSeats[index].unitPrice = parseFloat(perSeatPrice);
      this.licenceRequest.addedSeats[index].actualPrice = parseFloat(actualPrice);

      return parseFloat(totalProratedPrice).toLocaleString("en-US", {style:"currency", currency:"USD"});
    },

  },

  watch: {
    "licenceRequest.addedSeats": {
      handler: function() {
        this.getProratedTotalAmount()
      },
      deep: true
    }
  },

  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }

}
</script>