<template>
  <div class="mb-2">
    <div class="items-center">
      <div class="flex flex-row mb-10">
        <div class="w-1/2 flex items-center mt-5 mr-1 mx-5">
          <div class="relative block w-12 align-middle">
            <input
              type="checkbox"
              v-model="odometer"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
            />
            <label
              for="toggle"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>

          <div class="text-neutral-dark font-semibold text-sm ml-2">
            Odometer
          </div>
        </div>
        <div
          v-if="!['mix', 'lms'].includes(fleetConnection.provider)"
          class="w-1/2 flex items-center mt-5 mr-1 mx-5"
        >
          <div class="relative block w-12 align-middle">
            <input
              type="checkbox"
              v-model="driverAssignment"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
            />
            <label
              for="toggle"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>

          <div class="text-neutral-dark font-semibold text-sm ml-2">
            Driver Assignment (Legacy Mode)
          </div>
        </div>
      </div>

      <button
        class="btn btn-primary w-full"
        :disabled="loading"
        :style="{ backgroundColor: theme.secondaryColor }"
        @click.prevent="onSubmit"
      >
        Submit
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Edit-Fleet-Connection-Function",

  props: {
    fleetConnection: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      loading: false,
      odometer: false,
      driverAssignment: false,
    };
  },
  mounted() {
    const { functions } = this.fleetConnection;
    const { odometer = false, driverAssignment = false } = functions || {};
    this.odometer = odometer;
    this.driverAssignment = driverAssignment;
  },
  methods: {
    async onSubmit() {
      const { odometer, driverAssignment } = this;

      this.loading = true;
      try {
        await this.$store.dispatch("business/updateFleetConnectionFunctions", {
          fleetConnectionId: this.fleetConnection._id,
          payload: {
            odometer,
            driverAssignment,
          },
        });
        this.$emit("closeModal");
        this.toast("success", "Fleet connection functions updated");
      } catch (error) {
        const errorMessage = error.errors?.message
          ? error.errors.message
          : "Fleet connection functions update failed";
        this.toast("error", errorMessage);
      }
      this.loading = false;
    },

    toast(state, message) {
      this.$emit("toast", state, message);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
