<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
  >
    <list-select
      ref="selectRef"
      :options="options"
      :context="context"
      :isLoading="isLoading"
      :hasPrevPage="hasPrevPage"
      :hasNextPage="hasNextPage"
      v-bind="omit(context.attributes, 'class')"
      noResultText="No AFM Numbers found."
      @prevPage="onPrevPage"
      @nextPage="onNextPage"
      @search="onSearch"
    >
      <template slot="option" slot-scope="option">
        <div class="text-sm">
          <h3 class="m-0 font-semibold">{{ option.label }}</h3>
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="text-sm selected">
          {{ option.label }}
        </div>
      </template>
    </list-select>
  </div>
</template>
<script>
import { debounce } from "lodash";
import { formatPaginationSettings } from "@/_helper";
import ListSelect from "@/components/commons/ui/list-select/ListSelect";

const AfmAccessSelect = {
  name: "AfmAccessSelect",
  components: {
    ListSelect,
  },
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.$refs.selectRef.onSearch();
    if (this.context.attributes?.showTablesOnly)
      this.showTablesOnly = this.context.attributes?.showTablesOnly;
  },
  data: () => ({
    afmData: [],
    options: [],
    isLoading: false,
    hasNextPage: false,
    hasPrevPage: false,
    showTablesOnly: false,
    filter: {
      limit: 10,
      status: "all",
      search: "",
    },
    paginationSettings: {
      page: 1,
      totalPages: 5,
      totalRecords: 50,
      visiblePageItemCount: 3,
    },
  }),
  methods: {
    omit(obj, keys) {
      let result = {};
      for (const [key, value] of Object.entries(obj)) {
        if (!keys.includes(key)) {
          result[key] = value;
        }
      }
      return result;
    },
    async onSearch(search = "") {
      debounce(async () => {
        this.filter.search = search;
        this.options = [];
        this.isLoading = true;
        const me = this.$store.getters[`account/me`];
        const query = {
          skip:
            this.paginationSettings.page * this.filter.limit -
            this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {
            isActive: true,
          },
        };

        const afmData = await this.$store.dispatch(
          `business/findBusinessAFMAccess`,
          { businessId: me?.business?._id, query }
        );

        if (afmData && afmData?.results) {
          if (afmData?.metadata) {
            this.paginationSettings = formatPaginationSettings(
              afmData?.metadata
            );
            this.hasNextPage =
              this.paginationSettings.page < this.paginationSettings.totalPages;
            this.hasPrevPage = this.paginationSettings.page > 1;
          } else {
            this.paginationSettings = formatPaginationSettings();
          }

          this.afmData = afmData.results;
          if (!this.showTablesOnly) {
            this.options = this.afmData.map((afmData) => {
              afmData.label = afmData.afmNumber;
              return afmData;
            });
          } else {
            this.afmData.forEach((afm) => {
              if (afm.tables.length > 0) {
                afm.tables.forEach((table) => {
                  this.options.push({
                    ...afm,
                    label: table,
                  });
                });
              } else {
                this.options.push({
                  ...afm,
                  label: afm.afmNumber,
                });
              }
            });
          }
        }
        this.isLoading = false;
      }, 500)();
    },
    onPrevPage() {
      if (this.hasPrevPage) {
        this.paginationSettings.page = this.paginationSettings.page - 1;
        this.onSearch();
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
        this.paginationSettings.page = this.paginationSettings.page + 1;
        this.onSearch();
      }
    },
  },
};

export const VueFormulateAfmAccessSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      AfmAccessSelect,
    },
    library: {
      "afm-access-select": {
        classification: "select",
        component: "AfmAccessSelect",
      },
    },
  });
};
export default AfmAccessSelect;
</script>
