export default [
  {
    category: "Business Navigation",
    subCategories: [
      {
        name: "Tools",
        items: [
          {
            name: "Dashboard",
            link: "/business",
          },
          {
            name: "Knowledge Base",
            link: "https://logmaster.knowledgeowl.com/",
            isExternalLink: true,
          },
          {
            name: "Release Notes",
            link: "/business/support/release-notes",
          },
        ],
      },
      {
        name: "Drivers",
        items: [
          {
            name: "Drivers List",
            link: "/business/drivers",
          },
          {
            name: "Manual Events",
            link: "/business/manual-events",
          },
        ],
      },
      {
        name: "Vehicles",
        items: [
          {
            name: "Vehicle List",
            link: "/business/vehicles",
          },
          {
            name: 'Connected Fleet',
            link: '/business/connected-fleet',
            production: true,
          },
        ],
      },
      {
        name: "Admin",
        items: [
          {
            name: "User List",
            link: "/business/users",
          },
          {
            name: "Role List",
            link: "/business/roles",
          },
          {
            name: "Devices",
            link: "/business/devices",
          },
          {
            name: "Billing",
            link: "/business/billing",
          },
          {
            name: "Forms Setup",
            link: "/business/form-setup",
          },
          {
            name: "Additional Services",
            link: "/business/services/subscription",
          },
          {
            name: "Depot Config",
            link: "/business/depot-configuration",
          },
        ],
      },
    ],
  },
  {
    category: "Reports",
    subCategories: [
      {
        name: "Driver Reports",
        items: [
          {
            name: "Compliance",
            link: "/business/reports/compliance",
          },
          {
            name: "Breaches",
            link: "/business/reports/breaches",
          },
          {
            name: "Breach Confirmation",
            link: "/business/reports/breach-confirmation",
          },
          {
            name: "Daily Forms",
            link: "/business/reports/daily",
          },
          {
            name: "Forms Report",
            link: "/business/forms-report",
          },
          {
            name: "Report Scheduling",
            link: "/business/reports/scheduling",
          },
          {
            name: "Event Modifications",
            link: "/business/reports/event/modification",
          },
        ],
      },
      {
        name: "Other Reports",
        items: [
          {
            name: "Vehicles Report",
            link: "/business/reports/vehicles",
          },
        ],
      },
    ],
  },
];
