import { STATES } from '@/constants/location'

export default [
  {
    component: 'div',
    class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
    children: [
      {
            type: 'text',
            name: 'driverName',
            label: 'Full Name',
            validation: 'required',
            class: 'w-full md:w-1/2'
      },
      {
            type: 'email',
            name: 'emailAddress',
            label: 'Email Address',
            validation: '^required|email',
            "@input": "check-email",
            class: 'w-full md:w-1/2'
      },
      ]
    },
    {
    component: 'div',
    class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
    children: [
    {
        type: 'text',
        name: 'contactNumber',
        label: 'Contact Phone Number',
        validation: 'required|number',
        class: 'w-full md:w-1/2'
    },
    {
        type: 'date',
        name: 'driverDateOfBirth',
        label: 'Date Of Birth',
        validation: 'required',
        class: 'w-full md:w-1/2'
    },
],
},
{
    component: 'div',
    class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
    children: [
        {
            type: 'text',
            name: 'driversLicenseNumber',
            label: 'Licence Number',
            validation: 'required',
            class: 'w-full md:w-1/2'
        },
        {
            type: 'date',
            name: 'driversLicenseExpiry',
            label: 'Licence Expiry',
            class: 'w-full md:w-1/2'
        },
    ]
},
{
  name: 'driversLicenseState',
  type: 'select',
  label: 'Licence State',
  options: STATES,
  validation: 'required'
},
]