export default {
  colorScheme: {
    successColor: "#8dff8d",
    secondaryColor: "#ff9800",
    warningColor: "#ffff8c",
    infoColor: "#aeffff",
    primaryColor: "#2873A5",
    accentColor: "#0B5C91",
    errorColor: "#ff8484",
  },
  themeOptions: [],
  activeTheme: null,
  initializedTheme: false,
  isEnableDynamicTheme: false,
};
