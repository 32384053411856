<template>
  <div>
    <FormulateForm 
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      @check-email="checkEmail($event)"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{backgroundColor: theme.secondaryColor}"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
        <div class="text-center text-l font-bold mb-5">Sign up here or speak to {{entity.persona?.tradingName}} if you have any questions.</div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import desktopSchema from "./new-solo-driver-schema";
import mobileSchema from "./new-solo-driver-schema-mobile";
import { mapGetters } from 'vuex'

export default {
  name: "Add-Driver-Form",
  props: {
    msg: String,
    postData: null,
    windowWidth: Number,
    mobileViewMaxWidth: Number,
  },
  data() {
    return {
      loading: false,
      values: {},
      schema: [],
      errorMessage: "",
      hasErrorMessage: false,
      newDriver: {},
      entity: {},
      fullUrl: "",
      interChangingData: null,
      entityType: "",
      mobileSchema: mobileSchema,
      desktopSchema: desktopSchema,
    };
  },

  async mounted() {
    this.toggleSchema();
    this.loading = true;
    if (this.postData?.steps) {
      this.values = this.postData.steps?.driverDetails || null;
      this.interChangingData = this.postData;
    }
    this.init();
  },
  watch: {
    windowWidth() {
      this.toggleSchema();
    },
  },
  methods: {
    handleStritEmailCase(value) {
      this.values.email = value.toLowerCase();
    },
    async init() {
      const fullURL = window.location.origin + window.location.pathname;
      const params = this.$route.params;

      const entityType = params.entity;
      const urlType = params.urlType;

      if (entityType == "partner") {
        await this.$store
          .dispatch(`partner/getPartnerByURL`, {
            entityUrl: fullURL,
            urlType: urlType,
          })
          .then((response) => {
            if (response) {
              this.entity = response;
              this.fullUrl = fullURL;
              this.entityType = entityType;
              this.getFormData();
            }
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
              this.errorMessage = error?.message;
              window.location.href = "/login?message=Invalid url request&state=error";
            }
          });
      } else if (entityType == "reseller") {
        await this.$store
          .dispatch(`reseller/getResellerByUrl`, {
            entityUrl: fullURL,
            urlType: urlType,
          })
          .then((response) => {
            if (response) {
              this.entity = response;
              this.fullUrl = fullURL;
              this.entityType = entityType;
              this.getFormData();
            }
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
              this.errorMessage = error?.message;
              window.location.href = "/login?message=Invalid url request&state=error";
            }
          });
      }
    },

    async getFormData() {
      this.loading = false;
    },

    async onSubmit() {
      this.loading = true;
      let newDriver = JSON.parse(JSON.stringify(this.values));

      if (newDriver) {
        newDriver["entityId"] = this.entity._id;
        newDriver["isDemoAccount"] = false;
        newDriver["parentUid"] = this.entity.uid;
        newDriver["role"] = this.entityType;
        this.$store
          .dispatch(`driver/createDriverFromURL`, newDriver)
          .then((response) => {
            if (response && response._id) {
              this.loading = false;
              this.$emit("newDriverAdded", response);
            }
          })
          .catch((error) => {
            this.loading = false;
            this.hasErrorMessage = true;
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message;
          });
      }
      // if(newDriver) {
      //   const preData = {
      //     ...this.interChangingData,
      //     newDriver: newDriver,
      //     steps: {
      //       ...this.interChangingData?.steps,
      //       ...{driverDetails: newDriver},
      //       ...{partner: this.partner},
      //       ...{fullUrl: this.fullUrl},
      //       ...{entityType: this.entityType}
      //     },
      //     driverDetails: newDriver,
      //     partner: this.partner,
      //     fullUrl: this.fullUrl,
      //     entityType: this.entityType
      //   }
      //   this.$emit('nextStep', preData)
    },

    checkEmail(value) {
      this.values.emailAddress = value.toLowerCase();
    },

    toggleSchema() {
      if (this.windowWidth > this.mobileViewMaxWidth) {
        this.schema = this.desktopSchema;
      } else {
        this.schema = this.mobileSchema;
      }
    },
  },
   computed: {
     ...mapGetters('theme', {
    theme: 'getColorScheme'
    })
  },
};
</script>
