<template>
    <div class="mb-16">
      <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
        <div v-if="loading" class="text-center">
          <ui-spinner class="m-auto"></ui-spinner> Loading...
        </div>
        <div v-if="!loading" class="items-center justify-between">
          <FormulateInput
            type="select"
            :options="providers"
            name="provider"
            label="Provider"
            :disabled="true"
            validation="required"
            class="cursor-not-allowed"
          />
          <FormulateInput
            type="text"
            name="name"
            label="Connection Name"
            validation="required"
          />
          <div>
            <FormulateInput
              type="text"
              name="username"
              label="Service Account"
              validation="required"
            />
            <FormulateInput
              type="password"
              name="password"
              label="Password"
              validation="required"
            />
            <FormulateInput
              type="password"
              name="confirmPassword"
              label="Confirm Password"
              validation="required"
            />
            <FormulateInput
              v-if="values.provider && values.provider === 'geotab'"
              type="text"
              name="database"
              label="Database"
              validation="required"
            />
            <FormulateInput
              v-if="values.provider && values.provider === 'geotab'"
              type="text"
              name="server"
              label="Server"
              validation="required"
            />
            <FormulateInput
              v-if="values.provider && values.provider === 'mix'"
              type="text"
              name="clientId"
              label="Client ID"
              validation="required"
            />
            <FormulateInput
              v-if="values.provider && values.provider === 'mix'"
              type="password"
              name="clientSecret"
              label="Client Secret"
              validation="required"
            />
            <FormulateInput
              v-if="values.provider && values.provider === 'verizon'"
              type="text"
              name="appId"
              label="Application ID"
              validation="required"
            />
          </div>
  
          <StyledSubmit :backgroundColor="theme.secondaryColor">
            <FormulateInput
              type="submit"
              label="Submit"
              input-class="btn btn-primary w-full"
              :disabled="hasErrors || loading"
              :style="{ backgroundColor: theme.secondaryColor }"
            />
          </StyledSubmit>
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from "vuex";
  import { StyledSubmit } from "@/styled-components";
  
  export default {
    name: "Edit-Fleet-Connection",
    components: { StyledSubmit },
    props: {
      fleetConnection: {
        default: () => {},
        type: Object,
      },
    },
    data() {
      return {
        loading: false,
        values: {
          provider: "",
        },
        providers: [
          {
            label: "Geotab",
            value: "geotab",
          },
          {
            label: "Mix",
            value: "mix",
          },
          {
            label: "Verizon",
            value: "verizon",
          },
          {
            label: "Location Management Services",
            value: "lms",
          },
        ],
        errorMessage: "",
      };
    },
    mounted() {
      const {
        provider,
        name,
        metadata: {
          username,
          database,
          server,
          password,
          clientId,
          clientSecret,
        },
      } = this.fleetConnection;
      this.values = {
        provider,
        name,
        username,
        database,
        server,
        password,
        clientId,
        clientSecret,
      };
    },
    methods: {
      async onSubmit() {
        const {
          name,
          password,
          confirmPassword,
          database,
          server,
          username,
          provider,
          clientId,
          clientSecret,
          appId,
        } = this.values;
        if (password !== confirmPassword) {
          this.toast("error", "Passwords must match");
          return;
        }
  
        const me = this.$store.getters[`account/me`];
        const businessId = me.businessId ? me.businessId : me?.business?._id;
        try {
          await this.$store.dispatch("business/updateFleetConnection", {
            fleetConnectionId: this.fleetConnection._id,
            payload: {
              businessId,
              name,
              password,
              confirmPassword,
              database,
              server,
              username,
              provider,
              clientId,
              clientSecret,
              appId,
            },
          });
          this.$emit("closeModal");
          this.toast("success", "Fleet connection updated");
        } catch (error) {
          const errorMessage = error.errors?.message
            ? error.errors.message
            : "Fleet connection update failed";
          this.toast("error", errorMessage);
        }
      },
  
      toast(state, message) {
        this.$emit("toast", state, message);
      },
    },
    computed: {
      ...mapGetters("theme", {
        theme: "getColorScheme",
      }),
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped lang="scss"></style>