<template>
  <div>
    <div>
      <button @click="$router.go(-1)" class="btn btn-cancel flex flex-row">
        <StyledIconedLink>
          <font-awesome-icon icon="arrow-left" class="" />
        </StyledIconedLink>
        <div class="px-2">Back</div>
      </button>
    </div>
    <div v-if="!hasError && notification" class="flex flex-row justify-center">
      <Card :cardWidth="'50%'">
        <template slot="cardContent">
          <div class="font-bold text-lg" :style="{ color: theme.primaryColor }">
            {{ notification.title }}
          </div>
          <div class="text-md" :style="{ color: theme.primaryColor }">
            {{ notification.description }}
          </div>
          <div v-if="notification.metadata && notification.metadata.form">
            <div
              class="font-bold text-lg mt-5"
              :style="{ color: theme.primaryColor }"
            >
              Details
            </div>
            <div
              v-if="notification.metadata.form.type === 'fitness_declaration'"
              class="mt-2"
            >
              <div class="flex flex-row mt-2">
                <div class="w-1/2 font-semibold">Form Type:</div>
                <div class="w-1/2">
                  {{ formTypeKeyMapping[notification.metadata.form.type] }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Business:</div>
                <div class="w-1/2">
                  {{
                    notification.metadata.form.businessName
                      ? notification.metadata.form.businessName
                      : "N/A"
                  }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Depot:</div>
                <div class="w-1/2">
                  {{
                    notification.metadata.form.depotSite
                      ? notification.metadata.form.depotSite
                      : "N/A"
                  }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Driver:</div>
                <div class="w-1/2">
                  {{ notification.metadata.entityJson?.driverName }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Date:</div>
                <div class="w-1/2">
                  {{ notification.metadata.form.date }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Total Failed Items:</div>
                <div class="w-1/2">
                  {{ notification.metadata.form.failedItems.length }}
                </div>
              </div>
            </div>
            <div
              v-if="notification.metadata.form.type === 'prestart'"
              class="mt-2"
            >
              <div class="flex flex-row mt-2">
                <div class="w-1/2 font-semibold">Form Type:</div>
                <div class="w-1/2">
                  {{ formTypeKeyMapping[notification.metadata.form.type] }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Business:</div>
                <div class="w-1/2">
                  {{
                    notification.metadata.form.businessName
                      ? notification.metadata.form.businessName
                      : "N/A"
                  }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Depot:</div>
                <div class="w-1/2">
                  {{
                    notification.metadata.form.depotSite
                      ? notification.metadata.form.depotSite
                      : "N/A"
                  }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Vehicle:</div>
                <div class="w-1/2">
                  {{ notification.metadata.entityJson?.vehiclePlate }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Date:</div>
                <div class="w-1/2">
                  {{ notification.metadata.form.date }}
                </div>
              </div>
              <div class="flex flex-row">
                <div class="w-1/2 font-semibold">Total Failed Items:</div>
                <div class="w-1/2">
                  {{ notification.metadata.form.failedItems.length }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>
    <div
      v-if="hasError || !notification"
      class="flex flex-row justify-center text-center"
    >
      <Card :cardWidth="'50%'">
        <template slot="cardContent">
          <div class="font-bold text-lg" :style="{ color: theme.primaryColor }">
            No notification found.
          </div>
        </template>
      </Card>
    </div>

    <div v-if="!notification.isCompleted" class="flex flex-row justify-center">
      <div>
        <table>
          <td v-for="(item, index) in notification.actionItems" :key="index">
            <button
              @click="acknowledge(item._id)"
              class="w-full btn btn-primary"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Acknowledge
            </button>
          </td>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Card } from "@/components/commons";
import { StyledIconedLink } from "@/styled-components";
import { FORM_KEY_MAPPING } from "@/_helper/constants";

export default {
  name: "Notification-Details",
  components: {
    Card,
    StyledIconedLink,
  },
  metaInfo: {
    title: "Notification Details",
  },
  data() {
    return {
      hasError: false,
      notification: [],
      formTypeKeyMapping: FORM_KEY_MAPPING,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    const notificationId = this.$route.params.id;
    this.notification = await this.$store
      .dispatch(`notification/getNotificationByID`, notificationId)
      .catch(() => {
        this.hasError = true;
      });
    this.$emit("updateLoading", false);
  },
  methods: {
    async acknowledge(id) {
      const payload = {
        isCompleted: true,
        id: id,
      };

      await this.$store.dispatch(`notification/updateItemStatus`, payload)
        .then(() => {
          this.$store.commit('setDialogNotify', true);
          this.$store.commit('setMessageNotify', { state: 'success', message: 'Notification successfully acknowledged.' });
        }).catch((err) => {
          this.$store.commit('setDialogNotify', true);
          this.$store.commit('setMessageNotify', { state: 'error', message: err.message });
        })


      const notificationId = this.$route.params.id;
      this.notification = await this.$store
        .dispatch(`notification/getNotificationByID`, notificationId)
        .catch(() => {
          this.hasError = true;
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
