<template>
  <div>
    <div
      v-if="loading"
      class="loading absolute mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto"></ui-spinner>
      Generating modules options...
    </div>
    <FormulateForm v-if="!loading" v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          v-model="cplOptionSelectionModel"
          :options="corePlatformLicencesOptions"
          type="checkbox"
          placeholder="Select an option"
          label="Select a Core Platform Licences"
          class="custom-options checkbox"
          name="selectedCPL"
        />

        <div v-if="showCPLSeatCount" class="p-5 border rounded-lg group mb-10">
          <div class="seatCountTitle pb-5 font-bold bg-gray-50 group-title">
            Set Core Platform Licences Seat Count and Target
          </div>

          <div v-if="setCPLSeatCountLoading" class="text-center">
            <ui-spinner class="mx-auto"></ui-spinner>
            Generating seat count form...
          </div>

          <div v-if="!setCPLSeatCountLoading">
            <FormulateInput
              type="number"
              label="EWD seat count"
              name="corePlatformLicencesCount"
              placeholder="Input number of seat(s)"
              class="w-1/2"
              validation="^required"
              :validation-messages="{
                required: 'Core Platform Licences seat is required',
              }"
            />
            <FormulateInput
              v-if="isFatigueSelected"
              type="checkbox"
              class="formulate-input"
              name="billedExternally"
              label="Customize seat count for fatigue compliance"
              v-model="customSeatCountFC"
              label-class="formulate-input-label formulate-input-label--after"
              input-class=""
              wrapper-class="formulate-input-wrapper"
              outer-class=""
              help="*Check this box if you want fatigue compliance seat count to be different from EWD"
              element-class="formulate-input-element formulate-input-element--checkbox"
            />
            <FormulateInput
              v-if="customSeatCountFC"
              type="number"
              label="Fatigue Compliance seat count"
              name="fatigueComplianceSeatCount"
              placeholder="Input number of seat(s)"
              class="w-1/2"
              validation="^required"
              :validation-messages="{
                required: 'Fatigue Compliance seat count is required',
              }"
            />
            <FormulateInput
              name="contractTarget"
              :options="[
                { value: 'driver', label: 'Drivers' },
                { value: 'vehicle', label: 'Vehicles' },
              ]"
              type="radio"
              placeholder="Select an option"
              label="Select a target for seat count"
              class="custom-options"
              validation="^required"
              :validation-messages="{
                required: 'Contract Target is required.',
              }"
            />
          </div>
        </div>
        <div class="border-t border-neutral-light mt-8">
          <div class="flex items-center justify-end w-full mt-4 space-x-4">
            <FormulateInput
              type="button"
              label="Back"
              input-class="btn btn-cancel w-full"
              @click.prevent="onPrevious()"
            />
            <FormulateInput
              type="submit"
              label="Next"
              input-class="btn btn-primary w-full"
              :style="{ backgroundColor: theme.secondaryColor }"
            />
          </div>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Module-Selection",
  props: {
    preData: {
      type: Object,
      required: false,
      default: null,
    },
    postData: {
      type: Object,
      required: false,
      default: null,
    },
    detailsId: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      values: {},
      errorMessage: "",
      corePlatformLicencesOptions: [],
      productCorePlatformLiceneCount: [],
      setCPLSeatCountLoading: false,
      showCPLSeatCount: false,
      interChangingData: {},
      cplOptionSelectionModel: [],
      customSeatCountFC: false,
      isFatigueSelected: false,
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const pricingSchema = await this.$store.dispatch(
        `standardpricing/getContractModuleMaster`
      );

      if (this.postData?.steps) {
        this.values = this.postData.steps?.moduleSelectionDetails || null;
        this.interChangingData = this.postData;
      }

      if (pricingSchema) {
        const excludedFromCPL = [
          "EWD-PT",
          "Maintenance Compliance",
          "Pre-Paid EWD",
          "Fatigue Planning",
          "Payroll Assist",
        ];
        const corePlatformLicences = pricingSchema.filter(
          (s) => !excludedFromCPL.includes(s.name)
        );
        this.loading = false;

        this.corePlatformLicencesOptions = corePlatformLicences.map(
          (values) => ({
            id: values._id,
            label: values.name === "EWD-FT" ? "EWD" : values.name,
            value: values._id,
            name: values._id,
            apiTag: values.apiTag,
          })
        );

        if (
          this.interChangingData?.steps?.moduleSelectionDetails?.selectedCPL
        ) {
          this.interChangingData.steps.moduleSelectionDetails.selectedCPL.forEach(
            (cplModule) => this.cplOptionSelectionModel.push(cplModule)
          );
        }

        this.corePlatformLicencesOptions = orderBy(
          this.corePlatformLicencesOptions,
          ["label"],
          ["asc"]
        );
      }
    },
    async onSubmit() {
      const preFormattedModuleSelection = this.values;
      const cplValues = preFormattedModuleSelection.selectedCPL.map((cpl) => {
        const targetCorePlatformOption = this.corePlatformLicencesOptions.find(
          (m) => m.id === cpl
        );
        return {
          id: cpl,
          name: targetCorePlatformOption.label,
          numberOfSeats:
            this.customSeatCountFC &&
            targetCorePlatformOption.label == "Fatigue Compliance"
              ? this.values.fatigueComplianceSeatCount
              : this.values.corePlatformLicencesCount,
          apiTag: targetCorePlatformOption.apiTag,
        };
      });

      const preData = {
        leadId: this.detailsId,
        ...this.interChangingData,
        ...{
          selectedModules: {
            cplValues,
          },
        },
        steps: {
          ...this.interChangingData.steps,
          ...{ moduleSelectionDetails: preFormattedModuleSelection },
        },
      };
      this.$emit("nextStep", preData);
    },
    onPrevious() {
      const moduleSelectionData = this.values;
      const postData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData.steps,
          ...{ moduleSelectionDetails: moduleSelectionData },
        },
      };
      this.$emit("backStep", postData);
    },
  },
  computed: {
    cplOptionSelectionComputed: function () {
      return JSON.stringify(this.cplOptionSelectionModel);
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  watch: {
    cplOptionSelectionComputed: function () {
      this.showCPLSeatCount = this.values.selectedCPL.length;
      this.setCPLSeatCountLoading = true;
      setTimeout(() => {
        this.setCPLSeatCountLoading = false;
        this.productCorePlatformLiceneCount =
          this.values.selectedCPL.length > 0 &&
          this.corePlatformLicencesOptions.filter((values) =>
            this.values.selectedCPL.includes(values.id)
          );
      }, 500);
      const fatigueSelected = this.corePlatformLicencesOptions.filter(
        (values) => values.label == "Fatigue Compliance"
      );

      const fatigueFound = this.cplOptionSelectionModel.includes(
        fatigueSelected[0].id
      );

      if (fatigueFound) {
        this.isFatigueSelected = true;
      } else {
        this.isFatigueSelected = false;
      }
    },
  },
};
</script>
