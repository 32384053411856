
export default {
  // @BusinessContract
  setNewIntegrationLink(state, value){
      state.newIntegrationLink = value
  },
  setNewIntegrationLinks(state, value){
      state.newIntegrationLinks = value
  },
  setNewPromoSignupLink(state, value){
      state.newPromoSignupLink = value
  },
  setNewCreatedContractorSingupLink(state, value){
      state.newCreatedContractorSignupLink = value
  },
}