
import Notification from '@/pages/account/Notification.vue'
import NotificationDetail from '@/pages/account/NotificationDetail.vue'
import ProfilePage from '@/pages/ProfilePage.vue'
import ProfileEdit from '@/pages/ProfileEdit.vue'
import ChangePassword from '@/pages/account/ChangePassword.vue'
import TwoFactorAuthentication from '@/pages/account/TwoFactorAuthentication.vue'
import ThemeConfigurator from '@/pages/account/ThemeConfigurator.vue'
import ChangeEmail from '@/pages/account/ChangeEmail.vue'
import EmailPreferences from "@/pages/EmailPreferences.vue";
import MenuSettings from "@/pages/account/MenuSettings.vue"
import SSOConfig from "@/pages/account/SSOConfig.vue"

export default [
    {
        name: 'Your Notifications',
        path: 'notifications',
        component: Notification
    },
    {
        name: 'Notification Details',
        path: 'notification/:id',
        component: NotificationDetail
    },
    {
        path: 'profile',
        name: 'Profile Management',
        component: ProfilePage
    },
    {
        path: 'profile/edit',
        name: 'Profile Edit',
        component: ProfileEdit
    },
    {
        path: 'two-factor-authentication',
        name: 'Two-Factor Authentication',
        component: TwoFactorAuthentication
    },
    {
        path: 'change-password',
        name: 'Change Password',
        component: ChangePassword
    },
    {
        path: 'theme',
        name: 'Theme Configurator',
        component: ThemeConfigurator
    },
    {
        path: 'change-email',
        name: 'Change Email',
        component: ChangeEmail
    },
    {
        path: 'email-preferences',
        name: 'Email Preferences',
        component: EmailPreferences
    },
    {
        path: 'menu-settings',
        name: 'Menu Display Preference',
        component: MenuSettings
    },
    {
        path: 'sso',
        name: 'SAML Single Sign On (SSO)',
        component: SSOConfig,
    }
]