import Vue from "vue";
import VueRouter from "vue-router";

import Homepage from '@/pages/HomePage.vue'
import LoginPage from '@/pages/LoginPage.vue';
import ForgotPasswordPage from '@/pages/ForgotPasswordPage.vue'
import AccountPage from '@/pages/AccountPage.vue'
import PageNotFound from '@/pages/Page-Not-Found.vue'
import ServerStatus from '@/pages/ServerStatus.vue'
import ContactPage from '@/pages/ContactPage.vue'
import PublicPage from '@/pages/PublicPage.vue'
import BusinessAccept from '@/pages/public/business/BusinessAcceptPage.vue'
import DriverAccept from '@/pages/public/driver/DriverAcceptPage.vue'
import WebProfileAccept from '@/pages/public/web-profile/WebProfileAcceptPage.vue'
import SignUpPage from "@/pages/SignUpPage.vue"


// Account Type Routes
import AccountRoutes from './account';
import AdminRoutes from './admin';
import PartnerRoutes from './partner';
import ResellerRoutes from './reseller';
import BusinessRoutes from './business';
import DriverRoutes from './driver';
import PublicRoutes from './public';
import SecurityConfigRoutes from "./security-config";
import RoleAuditTrailRoutes from "./role-audit-trail";
import IntegrationRoutes from "./integration";

// Etc
import {
  guards,
  isNotLogged,
  authGuard,
  isAdminGuard,
  //isPartnerGuard,
  isResellerGuard,
  isBusinessGuard,
  isDriverGuard,
  logoutAccount,
  loginWithUid
} from "@/_helper/guards";
import JsonBuilderPublic from "@/pages/JsonBuilder.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Homepage,
    redirect: '/login' //redirect to login page
  },
  {
    path: "/dashboard",
    name: "DashboardRedirect",
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: isNotLogged,
    component: LoginPage,
  },
  {
    path: "/soloproviders",
    name: "Sign Up Driver",
    component: SignUpPage,
  },
  {
    path: "/businessproviders",
    name: "Sign Up Business",
    component: SignUpPage,
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordPage,
  },
  {
    path: '/logout',
    name: 'Logout',
    beforeEnter: logoutAccount,
  },
  {
    path: "/contact-us",
    name: "Contact",
    component: ContactPage,
  },
  // @Account / Common
  {
    path: "/account",
    component: AccountPage,
    beforeEnter: guards([authGuard]),
    children: [...AccountRoutes],
  },
  // @Admin Account
  {
    path: "/admin",
    component: AccountPage,
    beforeEnter: guards([authGuard, isAdminGuard]),
    children: [...AdminRoutes],
  },
  // @Partner Account
  {
    path: "/partner",
    component: AccountPage,
    //beforeEnter: guards([authGuard, isPartnerGuard]),
    beforeEnter: guards([authGuard]),
    children: [...PartnerRoutes],
  },
  // @Reseller Account
  {
    path: "/reseller",
    component: AccountPage,
    beforeEnter: guards([authGuard, isResellerGuard]),
    children: [...ResellerRoutes],
  },
  // @Business Account
  {
    path: "/business",
    component: AccountPage,
    beforeEnter: guards([authGuard, isBusinessGuard]),
    children: [...BusinessRoutes],
  },

  // Audit Trail pages
  {
    path: "/security-role/:id/user/:userId",
    component: AccountPage,
    beforeEnter: guards([authGuard]),
    props: (route) => ({
      roleId: route.params.id,
      userId: route.params.userId,
    }),
    children: [...RoleAuditTrailRoutes],
  },

  // Security Config
  {
    path: "/security-role/:id",
    component: AccountPage,
    beforeEnter: guards([authGuard]),
    props: (route) => ({ roleId: route.params.id }),
    children: [...SecurityConfigRoutes],
  },

  //Business Public Page
  {
    path: "/business/accept/:id",
    component: BusinessAccept,
    props: (route) => ({ acceptId: route.params.id }),
  },
  // @Driver Account
  {
    path: "/driver",
    component: AccountPage,
    beforeEnter: guards([authGuard, isDriverGuard]),
    children: [...DriverRoutes],
  },
  //Driver Public Page
  {
    path: "/solo-driver/accept/:id",
    component: DriverAccept,
    props: (route) => ({ acceptId: route.params.id }),
    name: 'Create Driver Account',
  },
  // All public related page
  // {
  //   path: "/mail",
  //   component: MailPage,
  //   children: [...MailRoutes],
  // },
  //Web Profile Public Page
  {
    path: "/user-account/accept/:id",
    component: WebProfileAccept,
    props: (route) => ({ acceptId: route.params.id }),
  },
  {
    path: "/driver/training/:uid",
    beforeEnter: guards([loginWithUid])
  },
  {
    path: '/public',
    component: PublicPage,
    children: [...PublicRoutes]
  },
  {
    path: "/server-status",
    name: "ServerStatus",
    component: ServerStatus,
  },
  {
    path: "*",
    component: PageNotFound,
  },

  {
    path: "/not-found",
    component: PageNotFound,
  },

  // @Integration Account
  {
    path: "/integration/:addInId",
    component: AccountPage,
    beforeEnter: guards([authGuard]),
    children: [...IntegrationRoutes]
  },

  {
    path: "/images/icon.svg",
    beforeEnter() {
      window.location.href ="https://logmaster-australia.web.app/images/icon.svg"
    },
  },
  {
    path: '/json-builder',
    name: 'JSON Builder',
    component: JsonBuilderPublic
  },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
