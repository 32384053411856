<template>
  <div>
    <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="preStartData"
        @onPageChange="onPageChange($event)"
        :has-multi-filter="true"
        @onMultiFilter="onMultiFilter"
    >
      <template #tableFilters>
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
                v-model="filter.limit"
                @change="filterChange"
                class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400"/>
            </div>
          </div>
          <div class="relative">
            <select
                v-model="filter.status"
                @change="filterChange"
                class="w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-r-none appearance-none border-r-none -backdrop-hue-rotate-15block focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                  v-for="(filterStatus, index) in filterStatuses"
                  :key="index"
                  :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400"/>
            </div>
          </div>
        </div>
        <div class="relative block">
          <span class="absolute inset-y-0 left-0 flex items-center h-full pl-2">
            <font-awesome-icon icon="search" class="text-gray-400"/>
          </span>
          <input
              placeholder="Search"
              class="h-[42px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="relative px-5 py-2 text-center">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                    class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    @click.prevent="viewPreStartCheck(data.id, data.isDefault)"
                    :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2"/>
                  View
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'view'">
        <PreStartCheckDetailsView
            v-if="!isOldView"
            :activePreStartCheck="activePreStartCheck"
        />
        <PreStartCheckDetailsOldView
            v-else
            :activePreStartCheck="activePreStartCheck"
        />
      </div>
    </Modal>

  </div>
</template>

<script>

import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { Table, Modal, Dropdown } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";
import PreStartCheckDetailsView from "@/components/view/business/prestart";
import PreStartCheckDetailsOldView from "@/components/view/business/prestart/oldView";


export default {
  name: "Pre-Start-Check",
  components: {
    Table,
    Modal,
    Dropdown,
    StyledIconedLink,
    PreStartCheckDetailsView,
    PreStartCheckDetailsOldView,
  },
  data () {
    return {
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        {
          name: "Driver Name",
          type: "text",
          fieldName: "driver.driverName",
          isFilterable: true,
        },
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehicle.vehiclePlate",
          isFilterable: true,
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicle.vehicleClass.name",
          isFilterable: true,
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicle.vehicleType.name",
          isFilterable: true,
        },
        {
          name: "Result",
          type: "boolean",
          fieldName: "isPassed",
          isFilterable: true,
          filterOptions: {
            true: "Pass",
            false: "Fail",
          },
        },
        {
          name: "Date Submitted",
          type: "date",
          fieldName: "savedData.date",
          isFilterable: true,
        },
      ],
      preStartFormatedItems: [],
      activePreStartCheck: null,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      isLoading: false,
      isOldView: false,
      multiFilter: {},
      loading: false,
      selectedFormId: "",
    };
  },
  async mounted () {
    await this.init();
  },
  methods: {
    async onMultiFilter (data) {
      this.multiFilter = { ...data };
      await this.init();
    },
    async init (paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status !== "all") {
        query.filter.status = { $eq: this.filter.status };
      }

      const driverId = me.driver?._id;

      if (Object.keys(this.multiFilter).length > 0) {
        query.filter = { ...this.multiFilter, hasMultiFilter: true };
      }

      let data = await this.$store.dispatch(
          `prestart/getPrestartByDriverId`,
          { driverId, query, }
      );

      if (data) {
        this.paginationSettings = formatPaginationSettings(
            new Object(data["metadata"])
        );

        this.preStartFormatedItems = this.formatItem(
            _.orderBy(data.results, ["createdAt"], ["desc"])
        );
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async viewPreStartCheck (id, isDefault) {
      let data;
      if (typeof isDefault == "boolean") {
        data = await this.$store.dispatch(`prestart/getPrestartById`, id);
        this.isOldView = false;
      } else {
        data = await this.$store.dispatch(`prestart/getOldPrestartById`, id);
        this.isOldView = true;
      }

      if (!_.isEmpty(data)) {
        this.activePreStartCheck = data;
      }
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Pre Start Check Details";
    },

    parsePreStartCheck (data) {
      return data.map((prestartData) => [
        {
          id: prestartData.id,
          name: prestartData.driverName,
          itemType: "name",
          hasImage: false,
          isDefault: prestartData?.isDefault,
        },
        {
          id: prestartData.id,
          name: prestartData?.vehiclePlate,
          itemType: "string",
        },
        {
          id: prestartData.id,
          name: prestartData?.vehicleClass,
          itemType: "string",
        },
        {
          id: prestartData.id,
          name: prestartData?.vehicleType,
          itemType: "string",
        },
        {
          id: prestartData.id,
          name: prestartData.isPassed,
          itemType: "boolean",
        },
        { id: prestartData.id, name: prestartData.date, itemType: "string" },
      ]);
    },

    queryForKeywords (value) {
      this.searchKeyword = value;
    },

    formatItem (items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const prStart = {
            id: item._id,
            vehiclePlate: item?.vehicle?.vehiclePlate,
            vehicleClass: item?.vehicle?.vehicleClass?.name || "-",
            vehicleType: item?.vehicle?.vehicleType?.name || "-",
            gmv: item?.vehicle?.GMV,
            isPassed: item?.isPassed ? "Pass" : "Fail",
            isDefault:
                typeof item?.isDefault == "boolean" ? item?.isDefault : null,
            date: formatDate(item?.savedData?.date),
            driverName: item?.driver?.driverName,
          };
          nItems.push(prStart);
        });
      }
      return nItems;
    },

    async onPageChange (event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange () {
      await this.init();
    },

    handleSearchKeyDown () {
      this.stoppedTyping();
    },

    debouncedSearchString () {
      this.init();
    },

  },

  computed: {
    preStartData () {
      if (this.preStartFormatedItems) {
        return this.parsePreStartCheck(this.preStartFormatedItems);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },

  created () {
    this.stoppedTyping = _.debounce(
        this.debouncedSearchString,
        SEARCH_DEBOUNCE_DELAY,
        {
          leading: false,
          trailing: true,
        }
    );
  },
};
</script>

<style scoped lang="scss">
.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>
