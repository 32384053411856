<template>
  <div>
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>

    <div v-if="!loading && breach" class="mb-16">
      <div class="mb-10 text-center">
        <div class="text-2xl font-bold text-black-primary">
          {{ driverData.driverName }}
        </div>
        <div class="text-xs font-bold" :class="['text-red-500']">
          {{ cleanLevel(breach.level) }}
        </div>
      </div>
      <div v-if="driverData">
        <div class="w-full pb-1 mb-4 border-b">
          <div class="text-xs font-bold">Breach Details</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ cleanLevel(breach.level) }}
          </div>
          <div class="text-xs">Level</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ formatTime(breach.time, breach.timezone) }}
          </div>
          <div class="text-xs">Time of Breach</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ breach.breachTime || '-' }}
          </div>
          <div class="text-xs">Breach Time</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ breach.period }}
          </div>
          <div class="text-xs">Period</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ breach.option }}
          </div>
          <div class="text-xs">Option</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ breach.type }}
          </div>
          <div class="text-xs">Type</div>
        </div>
        <div class="mb-2">
          <div class="text-base font-bold text-black-primary">
            {{ breach.location || '-' }}
          </div>
          <div class="text-xs">Location</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{
              breach.startPoint
                ? formatTime(breach.startPoint, breach.timezone)
                : '-'
            }}
          </div>
          <div class="text-xs">Counting Point Start</div>
        </div>
        <div class="mb-2" v-if="breach.note">
          <div class="text-base font-bold text-black-primary">
            {{ breach.note }}
          </div>
          <div class="text-xs">Note</div>
        </div>
        <br />

        <div class="w-full pb-1 mb-4 border-b">
          <div class="text-xs font-bold">Driver Details</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ driverData.emailAddress }}
          </div>
          <div class="text-xs">Email Address</div>
        </div>
        <div class="mb-5">
          <div class="text-base font-bold text-black-primary">
            {{ driverData.driversLicenseNumber }}
          </div>
          <div class="text-xs">Licence Number</div>
        </div>
        <div class="mb-7">
          <div class="text-base font-bold text-black-primary">
            {{ driverData.driversLicenseState }}
          </div>
          <div class="text-xs">Licence State</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
export default {
  name: 'Breach-Details-View',
  props: {
    activeBreach: {
      type: Object,
    },
  },
  watch: {
    activeBreach: {
      deep: true,
      handler() {
        this.setActivePreStartCheckData();
      },
    },
  },
  created() {
    this.loading = true;
    this.setActivePreStartCheckData();
    setTimeout(() => (this.loading = false), 300);
  },
  data() {
    return {
      breach: null,
      driverData: null,
      loading: true,
    };
  },
  methods: {
    setActivePreStartCheckData() {
      this.breach = this.activeBreach.breachData;
      this.driverData = this.activeBreach.driverData;
    },
    cleanLevel(level) {
      level = typeof level == 'string' ? level : level[0];
      return _.startCase(
        level == 'no-breach' ? level.replace('-', ' ') : level.split('-')[0]
      );
    },
    formatTime(time, timezone) {
      return moment(time).utcOffset(timezone).format('YYYY-MM-DD HH:mm:ssZ');
    },
  },
};
</script>
