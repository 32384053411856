<template>
  <div class="h-full">
    <div class="flex flex-col h-full">
      <div
        class="relative p-5 overflow-hidden bg-white rounded shadow-md breach-container"
      >
        <div
          class="flex justify-between w-full pb-4 mb-3 border-b border-gray-300"
        >
          <div class="flex">
            <div class="mr-3" style="width: 14rem">
              <div class="block mb-1 text-xs font-bold text-grey-500">
                Date Range:
              </div>
              <DatePicker
                ref="childDatePicker"
                :date="selectedDateRange"
                placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
                :selectedDriver="selectedDriver"
                :displayBreach="true"
                :displayEvent="true"
                @selected="selectedDateRange = $event"
                range
                noNavigation
                :clearable="false"
              />
            </div>
            <div class="mr-3">
              <div class="-mb-1">&nbsp;</div>
              <button
                style="height: 45px"
                :disabled="!hasBreach || isSubmitting"
                :class="{ 'cursor-not-allowed': !hasBreach || isSubmitting }"
                class="text-sm btn btn-primary"
                :style="{ backgroundColor: theme.secondaryColor }"
                @click.prevent="confirmBreaches"
              >
                <span v-if="isSubmitting" class="mr-2"
                  ><font-awesome-icon icon="spinner" class="mr-1 loader"
                /></span>
                <span>Confirm Breach</span>
              </button>
            </div>
          </div>
        </div>

        <div class="flex items-center w-full driver-info-container">
          <NHVRBreachConfirmation
            ref="childBreach"
            @updateHasBreach="hasBreach = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import DatePicker from "@/components/commons/field/DatePicker";
import NHVRBreachConfirmation from "@/components/view/business/breach-engine/NHVRBreachConfirmation.vue";

export default {
  name: "BreachConfirmation",
  components: { DatePicker, NHVRBreachConfirmation },
  data() {
    return {
      isSubmitting: false,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      dateFormat: "YYYY-MM-DD",
      selectedDateRange: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
      selectedDriver: null,
      hasBreach: false,
      me: null,
    };
  },
  watch: {
    selectedDateRange: {
      handler: function () {
        this.onSearch();
      },
      deep: true,
    },
  },
  async mounted() {
    this.me = this.$store.getters[`account/me`];
    const { results } = await this.$store.dispatch(
      `business/getContractByDriver`,
      {
        uid: this.me?.driver?._id,
        query: {
          skip: 0,
          limit: 10,
          filter: { status: { $in: ["pending", "linked-non-ewd", "linked"] } },
        },
      }
    );

    // Sort results from linked first to pending
    results.sort((a, b) => {
      const statusOrder = ["linked", "linked-non-ewd", "pending"];
      const statusComparison =
        statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      if (statusComparison !== 0) {
        return statusComparison;
      }
      // If statuses are the same, compare by createdAt
      return new Date(b.createdAt) - new Date(a.createdAt);
    });

    this.selectedDriver = _.first(results) || null;
  },
  methods: {
    async confirmBreaches() {
      const { value: answer } = await this.$swal.fire({
        title: `Confirm Breaches`,
        text: `Are you sure you want to confirm these breaches?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, confirm breaches",
        cancelButtonText: "Cancel",
      });
      if (answer) {
        this.isSubmitting = true;
        const response = await this.$refs.childBreach.onConfirmBreaches();
        if (response) {
          this.hasBreach = false;
          this.toast("success", "Breaches confirmed");
        } else {
          this.hasBreach = true;
          this.toast("error", "Opps, something went wrong.");
        }
        this.isSubmitting = false;
      }
    },
    onClear() {
      this.$refs.childBreach.onClear();
    },
    canSearch() {
      return (
        this.selectedDriver &&
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    async onSearch() {
      if (this.canSearch()) {
        await this.$refs.childBreach.onSearch(
          this.selectedDriver,
          this.selectedDateRange,
          this.selectedDriver?.businessId
        );
      }
    },
    toast(state, message) {
      this.$emit("sendReportloading", false);
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped lang="scss">
.breach-container {
  min-height: calc(100vh - 92px);
}
</style>
