<template>
  <div class="w-full flex">
    <div class="w-12/12 mx-auto">
      <FormulateForm class="bg-white border border-gray-300 rounded">
        <div class="mb-5">
          <div
            class="text-sm text-neutral-dark font-bold form-label pr-2 p-5 rounded-t border-b border-gray-300"
          >
            SAML Single Sign-On (SSO)
          </div>

          <div class="mx-5 mt-2 text-sm text-neutral-dark">
            <span
              >Enable or disable users to log in using configured and verified
              SAML single sign-on credentials</span
            >
          </div>

          <div class="flex items-center mt-5 mr-1 mx-5">
            <div
              class="relative block w-12 align-middle select-none transition duration-200 ease-in"
            >
              <input
                type="checkbox"
                name="isSideMenu"
                v-model="ssoConfig.enabled"
                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
              />
              <label
                for="toggle"
                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>

            <div class="text-neutral-dark font-semibold text-sm ml-2">
              {{ ssoConfig.enabled ? "Enabled" : "Disabled" }}
            </div>
          </div>
          <div class="mt-8 mx-5">
            <div class="my-5">
              <span class="text-neutral-dark"
                >Enter the following URLs into your Identity Provider (IDP) SAML
                configuration.</span
              >
            </div>

            <div class="w-full flex">
              <div class="w-11/12 mx-auto">
                <FormulateInput
                  v-model="serviceProviderSSOConfig.serviceProviderIssuerId"
                  label="Entity or Issuer ID"
                  class="cursor-pointer"
                  disabled
                />
              </div>
              <div class="w-1/12 mx-auto ml-10 mt-8">
                <button
                  @click="
                    copyText(serviceProviderSSOConfig.serviceProviderIssuerId)
                  "
                >
                  <font-awesome-icon
                    class="text-xl"
                    icon="copy"
                    :style="{ color: theme.secondaryColor }"
                  />
                </button>
              </div>
            </div>
            <div class="w-full flex">
              <div class="w-11/12 mx-auto">
                <FormulateInput
                  v-model="serviceProviderSSOConfig.serviceProviderSSOLoginUrl"
                  label="Assertion Customer Services (ACS) URL"
                  disabled
                />
              </div>
              <div class="w-1/12 mx-auto ml-10 mt-8">
                <button
                  @click="
                    copyText(
                      serviceProviderSSOConfig.serviceProviderSSOLoginUrl
                    )
                  "
                >
                  <font-awesome-icon
                    class="text-xl"
                    icon="copy"
                    :style="{ color: theme.secondaryColor }"
                  />
                </button>
              </div>
            </div>

            <div class="w-full flex">
              <div class="w-11/12 mx-auto">
                <FormulateInput
                  v-model="serviceProviderSSOConfig.businessId"
                  label="Business ID (Paste in RelayState field)"
                  disabled
                />
              </div>
              <div class="w-1/12 mx-auto ml-10 mt-8">
                <button @click="copyText(serviceProviderSSOConfig.businessId)">
                  <font-awesome-icon
                    class="text-xl"
                    icon="copy"
                    :style="{ color: theme.secondaryColor }"
                  />
                </button>
              </div>
            </div>

            <div class="my-5">
              <span class="text-neutral-dark"
                >Enter the value provided by your Identity Provider (IDP)</span
              >
            </div>

            <FormulateForm v-model="ssoConfig" @submit="onSubmit">
              <FormulateInput
                type="text"
                required
                name="ssoLoginUrl"
                label="Identity Provider (IDP) Single Sign-On Service URL"
              />
              <FormulateInput
                type="textarea"
                placeholder="Copy and paste content between -----BEGIN CERTIFICATE----- and -----END CERTIFICATE-----"
                required
                name="certificate"
                label="Identity Provider (IDP) Certificate"
                rows="10"
              />
              <FormulateInput
                type="submit"
                label="Save"
                class="text-center mt-10"
                input-class="btn btn-primary w-full"
              />
            </FormulateForm>
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      ssoConfig: {
        enabled: false,
        ssoLoginUrl: "",
        ssoLogoutUrl: "",
        certificate: "",
      },
      serviceProviderSSOConfig: {
        serviceProviderIssuerId: "",
        serviceProviderSSOLoginUrl: "",
      },
    };
  },
  async mounted() {
    const serviceProviderSSOConfig = await this.$store.dispatch(
      "account/getServiceProviderSSOConfiguration"
    );
    const me = this.$store.getters["account/me"];

    this.serviceProviderSSOConfig = {
      ...serviceProviderSSOConfig,
      businessId: me.businessId ? me.businessId : me.business?._id,
    };

    const businessSSOConfig = this.$store.getters["account/ssoConfiguration"];
    if (businessSSOConfig) {
      this.ssoConfig = businessSSOConfig;
    }
  },
  methods: {
    async onSubmit() {
      const me = this.$store.getters["account/me"];

      try {
        await this.$store.dispatch("account/configureSSOConfiguration", {
          ...this.ssoConfig,
          entityType: me.role.name,
          entityId: me.businessId ? me.businessId : me.business?._id,
        });
        this.toast("success", "SSO configuration update successful");
      } catch (error) {
        console.log("error: ", error);
        this.toast("error", "SSO configuration update failed");
      }
    },
    copyText(text) {
      navigator.clipboard.writeText(text);
      this.toast("success", "Text Copied");
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
