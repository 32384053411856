<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit" class="relative">
      <div class="w-full" v-if="loading">
        <div
          class="absolute inset-0 z-10 flex flex-col items-center justify-center m-auto bg-gray-50"
        >
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Fetching data...</span>
        </div>
      </div>
      <div class="flex items-center mb-1 font-semibold">
        <div class="flex flex-col">Event Details</div>
      </div>
      <div class="mb-4 border-b-2 border-gray-300"></div>
      <div class="grid grid-cols-2 gap-4" v-if="!soloDriver">
        <FormulateInput
          label="Driver"
          name="selectedDriver"
          type="driver-select"
          placeholder="Select a driver"
          element-class="mt-1 mb-2"
          validation="required"
          :getOptionKey="(option) => option.driverId"
          :getOptionLabel="(option) => option.driver.driverName"
          :filterable="false"
          :clearable="false"
          @selected="values.selectedDriver = $event"
        />
        <div class="w-full">
          <label
            class="block pr-2 mb-2 text-sm font-semibold form-label text-neutral-dark"
            >Event Date</label
          >
          <DatePicker
            ref="childDatePicker"
            class="event-datepicker"
            :date="values.selectedDateRange"
            placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
            :displayEvent="true"
            :displayBreach="true"
            :disabledAfter="isTripCheck ? '' : null"
            :selectedDriver="values.selectedDriver"
            @selected="values.selectedDateRange = $event"
            range
            noNavigation
            :clearable="false"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          label="Fatigue Plan"
          name="selectedPlan"
          type="general-select"
          element-class="mt-1 mb-2"
          placeholder="Select a plan"
          :clearable="false"
          :options="fatiguePlans"
          :reduce="(option) => option.value"
          validation="required"
        />
        <FormulateInput
          label="Time Zone"
          name="selectedTimezone"
          type="general-select"
          element-class="mt-1 mb-2"
          placeholder="Select a timezone"
          :clearable="false"
          :options="timezones"
          :reduce="(option) => option.value"
          validation="required"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <div class="w-full" v-if="soloDriver">
          <label
            class="block pr-2 mb-2 text-sm font-semibold form-label text-neutral-dark"
            >Event Date</label
          >
          <DatePicker
            ref="childDatePicker"
            class="event-datepicker"
            :date="values.selectedDateRange"
            placeholder="YYYY-MM-DD ~ YYYY-MM-DD"
            :displayEvent="true"
            :displayBreach="true"
            :disabledAfter="''"
            :selectedDriver="values.selectedDriver"
            @selected="values.selectedDateRange = $event"
            range
            noNavigation
            :clearable="false"
          />
        </div>
        <FormulateInput
          element-class="mt-1 mb-2"
          v-if="showFatigueTable()"
          label="AFM Number"
          type="afm-access-select"
          placeholder="Select an AFM Number"
          name="selectedFatigueTable"
          :showTablesOnly="true"
          :getOptionKey="(option) => option.label"
          :getOptionLabel="(option) => option.label"
          :reduce="(option) => option.label"
          validation="required"
        />
        <FormulateInput
          v-if="soloDriver && !isTripCheck"
          label="Vehicle"
          name="selectedVehicle"
          type="text"
          validation="required"
          class="custom-input-xl"
        />
        <FormulateInput
          v-if="!soloDriver && !isTripCheck"
          :label="isManualDriver ? 'Vehicle (Optional)' : 'Vehicle'"
          name="selectedVehicle"
          type="vehicle-select"
          placeholder="Select a vehicle"
          element-class="mt-1 mb-2"
          :getOptionKey="(option) => option._id"
          :getOptionLabel="(option) => option.vehiclePlate"
          :filterable="false"
          :clearable="false"
          validation="validateVehicle"
          :validation-rules="{
            validateVehicle: ({ value }) => {
              if (isManualDriver) return true;
              else if (!value) return false;
              else return true;
            },
          }"
          :validation-messages="{ validateVehicle: 'Vehicle is required.' }"
        />
      </div>

      <div v-if="showTwoupDetails()">
        <div class="flex items-center mt-5 mb-1 font-semibold">
          <div class="flex flex-col">Two-Up Driver</div>
        </div>
        <div class="mb-4 border-b-2 border-gray-300"></div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            label="Full Name"
            name="twoUpName"
            type="text"
            validation="required"
            class="custom-input-xl"
          />
          <FormulateInput
            label="UDI/PG No."
            name="twoUpUDI"
            type="text"
            validation="required"
            class="custom-input-xl"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            label="Licence Number"
            name="twoUpLicenseNumber"
            type="text"
            validation="required"
            class="custom-input-xl"
          />
          <FormulateInput
            label="Licence State"
            name="twoUpLicenseState"
            type="general-select"
            element-class="mt-1 mb-2"
            placeholder="Select a state"
            :clearable="false"
            :options="states"
            :reduce="(option) => option.value"
            validation="required"
          />
        </div>
        <div>
          <FormulateInput
            label="Fatigue Plan"
            name="twoUpFatiguePlan"
            type="general-select"
            element-class="mt-1 mb-2"
            placeholder="Select a plan"
            :clearable="false"
            :options="fatiguePlans"
            :reduce="(option) => option.value"
            validation="required"
          />
        </div>
      </div>

      <div class="items-center justify-between pt-2">
        <button
          type="submit"
          class="w-full btn btn-primary"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Next
        </button>
      </div>
    </FormulateForm>
    <div class="mb-3 text-center text-red-500" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.event-datepicker,
.event-datepicker::v-deep > div {
  @apply w-full;
}
.event-datepicker::v-deep .lhv-datepicker.no-nav .lhv-input {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}
.custom-input-xl::v-deep input {
  padding-top: 12px;
  padding-bottom: 13px;
}
</style>

<script>
import _ from "lodash";
import moment from "moment";
import Spinner from "@/components/commons/ui/Spinner";
import DatePicker from "@/components/commons/field/DatePicker";
import { STATES } from "@/constants/location";
import { TIMEZONE } from "@/constants/location";
import {
  NHVR_FATIGUE_PLAN,
  WAHVA_FATIGUE_PLAN,
} from "@/constants/fatigue-plan";
import { mapGetters } from "vuex";

export default {
  name: "Manual-Event-Event-Details",
  components: { Spinner, DatePicker },
  props: {
    postData: null,
    soloDriver: {
      type: Object,
      default: null,
    },
    isTripCheck: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      twoUpFatigue: [],
      fatiguePlans: [],
      timezones: TIMEZONE,
      states: STATES,
      loading: false,
      isManualDriver: false,
      engineRegion: null,
      values: {
        selectedDriver: this.soloDriver ? this.soloDriver : null,
        selectedDateRange: [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
        selectedPlan: null,
        selectedTimezone: null,
        selectedVehicle: null,
        twoUpName: null,
        twoUpUDI: null,
        twoUpLicenseNumber: null,
        twoUpLicenseState: null,
        twoUpFatiguePlan: null,
      },
      errorMessage: "",
      schema: [],
      disabledBefore: null,
      eventDates: [],
      breachDates: [],
    };
  },
  async mounted() {
    await this.setFatiguePlans();

    if (this.soloDriver) {
      this.values.selectedTimezone =
        this.soloDriver?.driver?.driversBaseTimeZone || null;
    }

    if (!_.isEmpty(this.postData)) {
      this.stepsData = this.postData;
      this.values = this.stepsData.step1Data;
    }
  },
  watch: {
    "values.selectedDriver": {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal !== newVal) {
          this.engineRegion =
            newVal?.profile?.engineRegion ||
            newVal?.driver?.region ||
            newVal?.driver?.engineRegion ||
            "NHVR";
          this.isManualDriver = newVal.status === "linked-non-ewd";
          this.setFatiguePlans();

          if (_.isEmpty(this.postData)) {
            this.values.selectedPlan =
              newVal?.driver?.driversBaseFatiguePlan || null;
            this.values.selectedTimezone =
              newVal?.driver?.driversBaseTimeZone || null;
            this.values.selectedVehicle =
                newVal?.driver?.vehicle || null;
          }
        }
      },
    },
  },
  methods: {
    showFatigueTable() {
      const entityRole = this.me?.role?.name;
      return (
        this.values.selectedPlan === NHVR_FATIGUE_PLAN.AFM &&
        entityRole === "business"
      );
    },
    async setFatiguePlans() {
      let fatiguePlans = [];
      if (this.engineRegion === "WAHVA") {
        fatiguePlans = WAHVA_FATIGUE_PLAN;
        this.twoUpFatigue = [
          WAHVA_FATIGUE_PLAN.TWO_UP_48_HOURS,
          WAHVA_FATIGUE_PLAN.TWO_UP_7_DAY,
        ];
      } else {
        fatiguePlans = NHVR_FATIGUE_PLAN;
        this.twoUpFatigue = [
          NHVR_FATIGUE_PLAN.BFM_TWO_UP,
          NHVR_FATIGUE_PLAN.STANDARD_TWO_UP,
        ];
      }

      let showAfmFatiguePlan = false;
      this.me = this.$store.getters[`account/me`];
      if (this.me?.business) {
        const { results } = await this.$store.dispatch(
          `business/findBusinessAFMAccess`,
          {
            businessId: this.me?.business?._id,
            query: {
              limit: 50,
              filter: { isActive: true },
            },
          }
        );
        if (results && results.length > 0) {
          showAfmFatiguePlan = true;
        }
      }

      this.fatiguePlans = [];
      _.mapValues(fatiguePlans, (val, key) => {
        if (!showAfmFatiguePlan && val === NHVR_FATIGUE_PLAN.AFM) return;
        return this.fatiguePlans.push({
          value: val,
          label: key.replace(/_/g, " "),
        });
      });
    },
    showTwoupDetails() {
      return (
        !_.isEmpty(this.values["selectedPlan"]) &&
        this.twoUpFatigue.includes(this.values["selectedPlan"])
      );
    },
    async onSubmit() {
      this.values.selectedRegion = this.engineRegion;
      if (this.isTripCheck) this.isManualDriver = true;

      const step1Data = this.values;
      const step2Data = {
        eventDetails: JSON.parse(JSON.stringify(this.values)),
        isManualDriver: this.isManualDriver,
      };
      this.$emit("nextStep", {
        ...step2Data,
        stepsData: { step1Data, step2Data },
      });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
