export default [
    {
      name:'Partner Navigation',
      items:[
        {
          name:'Dashboard',
          link:'/partner/'
        },
        {
          name:'Resellers',
          link:'/partner/resellers'
        },
        {
          name:'Businesses',
          link:'/partner/businesses'
        },
        {
          name:'Solo',
          link:'/partner/solo'
        },
        {
          name:'Lead Management',
          link:'/partner/lead-management'
        },
        {
          name:'Billing',
          link:'',
          subitems: [
            {
              name:'Buy Pricing',
              link:'/partner/buy-pricing'
            },
            {
              name:'RRP',
              link:'/partner/rrp'
            },
            {
              name: 'Invoices',
              link: '/partner/invoices',
            },
            {
              name:'Payment Gateway',
              link:'/partner/payment'
            },
            {
              name:'Licence Increase Request',
              link:'/partner/licence-request'
            },
          ]
        },
        // {
        //   name:'Contracts',
        //   link:'',
        //   subitems: [
        //      {
        //        name:'Business Contract',
        //        link:'/partner/contract-management'
        //      },
        //     {
        //       name:'Create Term Form',
        //       link:'/partner/contracts/terms-forms/builder'
        //     },
        //     {
        //       name:'Terms Forms',
        //       link:'/partner/contracts/terms-forms'
        //     },
        //     {
        //       name:'Terms',
        //       link:'/partner/contracts/terms'
        //     },
        //     // {
        //     //   name:'Requests',
        //     //   link:'/partner/contracts/requests'
        //     // }
        //   ]
        // },
        // {
        //   name:'Record Keeper Lookup',
        //   link:'/partner/record-keeper-lookup'
        // },
        // {
        //   name:'Driver Lookup',
        //   link:'/partner/driver-lookup'
        // },
        // {
        //   name:'Vehicle Lookup',
        //   link:'/partner/vehicle-lookup'
        // },
        // {
        //   name:'Billing Centre',
        //   link:'/partner/billing-centre'
        // },
        // {
        //   name:'Contract Manager',
        //   link:'/partner/contract-manager'
        // },
        // {
        //   name:'Users',
        //   link:'/partner/users'
        // }
        {
          name:'Permissions',
          link:'',
          subitems: [
            {
              name:'User List',
              link:'/partner/users'
            },
            {
              name:'Role List',
              link:'/partner/roles'
            },
          ]
        },
        {
          name:'Support',
          link:'',
          subitems: [
            {
              name:'Support Details',
              link:'/partner/support/details'
            },
            {
              name:'Release Notes',
              link:'/partner/support/release-notes'
            },
          ]
        },
      ]
    },
  ]