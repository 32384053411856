<template>
  <div
    class="absolute right-0 mt-0 origin-top-right w-80"
    aria-labelledby="headlessui-menu-button-1"
    id="headlessui-menu-items-117"
    role="menu"
  >
    <div
      class="absolute right-0 overflow-hidden bg-white rounded-md shadow-lg w-80"
    >
      <div v-if="parsedNotifications.length > 0" class="py-0">
        <Item
          v-for="(item, index) in parsedNotifications.slice(0, 5)"
          :key="index"
          :title="item.title"
          :description="item.description"
          :link="`/account/notification/${item._id}`"
          :createdAt="item.createdAt"
        ></Item>
      </div>
      <div v-if="parsedNotifications.length == 0" class="p-7">
        <p>No notification found.</p>
      </div>
      <div v-if="parsedNotifications.length > 0">
        <a
          href="/account/notifications"
          class="block py-2 font-bold text-center text-white"
          :style="{ backgroundColor: theme.secondaryColor }"
          >See all notifications</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import Item from "./NotificationItem-min";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Notification",
  components: { Item },
  props: {
    notifications: {
      default: {
        type: Array,
        default: [],
      },
    },
  },
  data() {
    return {
      uid: null,
    };
  },

  async created() {
    const me = await this.$store.getters["account/me"];
    this.uid = me?.uid;
  },

  async mounted() {
    const proxySite = this.$store.getters[`account/proxySite`];
    if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
      // Filter only notications that belongs to the switched site
      this.notifications = this.notifications.filter((notification) => {
        return notification.siteId && notification.siteId == proxySite._id;
      });
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    parsedNotifications() {
      if (!this.notifications?.length) return [];
      const targetedNotifs = this.notifications
        .map((notification) => {
          if (notification.targetedWebProfile === this.uid) {
            return notification;
          }
          //PREVIOUS NOTIFICAIONS PRIOR TO THIS FEATURE
          else if (!notification.targetedWebProfile) {
            return notification;
          }
        })
        .filter((targets) => targets !== undefined);

      return _.orderBy(targetedNotifs, ["createdAt"], ["desc"]);
    },
  },
};
</script>
