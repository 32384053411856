import { STATES } from '@/constants/location'

export default [  
  {
    name: 'driverName',
    type: 'text',
    label: 'Full Name',
    validation: 'required'
  },
  {
    name: 'emailAddress',
    type: 'email',
    label: 'Email Address',
    validation: '^required|email',
    "@input": "check-email"
  },
  {
    name: 'contactNumber',
    type: 'text',
    label: 'Contact Phone Number',
    validation: 'required|number'
  },
  {
    name: 'driverDateOfBirth',
    type: 'date',
    label: 'Date Of Birth',
    validation: 'required'
  },
  {
    name: 'driversLicenseNumber',
    type: 'text',
    label: 'Licence Number',
    validation: 'required'
  },
  {
    name: 'driversLicenseExpiry',
    type: 'date',
    label: 'Licence Expiry',
  },
  {
    name: 'driversLicenseState',
    type: 'select',
    label: 'Licence State',
    options: STATES,
    validation: 'required'
  },
]