<template>
  <div>
    <div v-if="loading" class="loading absolute mx-auto my-24 w-full h-full translate-y-2/4 flex flex-col items-center">
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-if="!loading">
      <div class="px-4 mb-12 overflow-y-auto h-96">
        <div class="mt-2 mb-10 text-lg">
          <h3>Investigation Summary</h3>
        </div>
        <section class="p-5 mb-5 border rounded-lg group">
          <div class="group-title pb-5 font-bold bg-gray-50">Driver Details</div>
            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Name: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{driverDetails.driverName || driverDetails.driverStringBasedName}}</div>
                </td>
              </tr>
              <tr v-if="driverDetails.driverName" >
                <td class="pb-1">
                  <div class="font-semibold">Licence Number: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{driverDetails.driversLicenseNumber}}</div>
                </td>
              </tr>
              <tr v-if="driverDetails.driversLicenseState" >
                <td class="pb-1">
                  <div class="font-semibold">Licence State: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{driverDetails.driversLicenseState}}</div>
                </td>
              </tr>
            </table>
          </section>
          <br>

          <section class="p-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Linked Data</div>
              <div>
                <table>
                  <tbody>
                    <tr v-if="fitnessDeclarations.length">
                      <td class="pb-1">
                        <div class="font-semibold">Type: </div>
                      </td>
                      <td class="pb-1">
                        <div class="ml-3">Fitness Declaration ({{fitnessDeclarations.length}})</div>
                      </td>
                    </tr>
                    <tr v-if="prestarts.length">
                      <td class="pb-1">
                        <div class="font-semibold">Type: </div>
                      </td>
                      <td class="pb-1">
                        <div class="ml-3">Prestart ({{prestarts.length}})</div>
                      </td>
                    </tr>
                    <tr v-if="breaches.length">
                      <td class="pb-1">
                        <div class="font-semibold">Type: </div>
                      </td>
                      <td class="pb-1">
                        <div class="ml-3">Breaches ({{breaches.length}})</div>
                      </td>
                    </tr>
                    <tr v-if="events.length">
                      <td class="pb-1">
                        <div class="font-semibold">Type: </div>
                      </td>
                      <td class="pb-1">
                        <div class="ml-3">Events ({{events.length}})</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            <div v-if="!fitnessDeclarations.length && !prestarts.length && !breaches.length  && !events.length">
              <div class="text-sm">Empty</div>
            </div>
          </section>

          <section v-if="investigationDetails" class="p-5 mb-10 border rounded-lg group">
            <div class="group-title pb-5 font-bold bg-gray-50">Investigation Details</div>
            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Date Raised: </div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{momentFormat(investigationDetails.dateRaised)}}</div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">People who raise:</div>
                </td>
                <td class="pb-1">
                  <ul class="ml-3" :class="[(investigationDetails.raisers.length > 1) ? 'list-decimal pl-4 pt-3 text-sm' : '']">
                    <li v-for="(item, index) in investigationDetails.raisers" :key="index">
                      {{ item.name }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Investigator{{(investigationDetails.investigators.length == 1)? '' : 's' }}:</div>
                </td>
                <td class="pb-1">
                  <ul class="ml-3" :class="[(investigationDetails.investigators.length > 1) ? 'list-decimal pl-4 pt-3 text-sm' : '']">
                    <li v-for="(item, index) in investigationDetails.investigators" :key="index">
                      {{ item.name }}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Decision Maker{{(investigationDetails.decisionMakers.length == 1)? '' : 's' }}: </div>
                </td>
                <td class="pb-1">
                  <ul class="ml-3" :class="[(investigationDetails.decisionMakers.length > 1) ? 'list-decimal pl-4 pt-3 text-sm' : '']">
                    <li v-for="(item, index) in investigationDetails.decisionMakers" :key="index">
                        {{ item.name }}
                    </li>
                  </ul>
                </td>
              </tr>
            </table>
          </section>
          <br>
        </div>

        <v-btn
          :submitting="submitLoading"
          label="Save"
          submittingLabel="Submitting..."
          @click.native="submitForm"
          class="my-3"></v-btn>

      <button class="btn btn-cancel w-full mb-2" @click.prevent="onPrevious()">
        Back
      </button>
    </div>
  </div>
</template>
<script>
import { removeStringUnderscore } from '@/_helper'
import moment from 'moment'
import { DATA_TYPES } from '@/_helper/constants'

export default {
  name: 'Investigation-Summary',
  props: {
    preData: null,
    postData: null
  },
  data(){
    return{
      loading: true,
      interChangingData: [],
      submitLoading: false,
      fixedData: null,
      fitnessDeclarations: [],
      prestarts: [],
      DATA_TYPES: DATA_TYPES
    }
  },
  mounted(){
    let data = this.fixedData = this.preData || this.postData || null
    if(data.steps){
      this.linkedDataDetails = data.steps?.linkedDataDetails
      this.driverDetails = data.steps?.driverDetails
      this.investigationDetails = data.steps?.investigationDetails
      this.interChangingData = data
      this.fitnessDeclarations = this.linkedDataDetails.linkedData.filter(item => item.linkDataType == this.DATA_TYPES.FITNESS_DECLARATION)
      this.prestarts = this.linkedDataDetails.linkedData.filter(item => item.linkDataType == this.DATA_TYPES.PRESTART)
      this.breaches = this.linkedDataDetails.linkedData.filter(item => item.linkDataType == this.DATA_TYPES.BREACH)
      this.events = this.linkedDataDetails.linkedData.filter(item => item.linkDataType == this.DATA_TYPES.EVENT)
    }
    setTimeout(() => this.loading = false, 300)
  },
  methods: {
    onPrevious(){
      const preData = this.interChangingData
      this.$emit('backStep', preData)
    },
    async submitForm(){
      this.submitLoading = true
      let payload = this.parshData(this.fixedData.steps)
      await this.$store.dispatch('investigation/createInvestigation', payload)
        .then(() =>{
          setTimeout(() =>{
            this.$emit('closeModal')
            this.$store.commit('setDialogNotify',true)
            this.$store.commit('setMessageNotify',{state: 'success', message: 'Investigation successfully created.'})
          }, 600)
        })
        .catch((err) =>{
          this.submitLoading = false
          this.$store.commit('setDialogNotify',true)
          this.$store.commit('setMessageNotify',{state: 'error', message: err.message})
        })
    },
    parshData(data){
      const me = this.$store.getters[`account/me`]
      const businessId = me?.business?._id

      let linkedData = data.linkedDataDetails?.linkedData.map(item => {
        let result =  {
          linkDataId: item._id,
          linkDataType: item.linkDataType,
        }
        if(item.linkDataType == 'event'){
          result.startDate = item.startDate
          result.endDate = item.endDate
        }
        return result
      })

      return {
        driver: data.driverDetails?.driver,
        driverStringBasedName: data.driverDetails?.driverStringBasedName,
        businessId: businessId,
        investigationName: data.investigationDetails?.investigationName,
        raisers: data.investigationDetails?.raisers,
        dateRaised: data.investigationDetails?.dateRaised,
        investigators: data.investigationDetails?.investigators,
        decisionMakers: data.investigationDetails?.decisionMakers,
        linkedData: linkedData,
        createdDocuments: data.parseDocumentsData?.createdDocuments,
        performance: data.performance,
        filledForm: data.filledForm,
        filledFormType: data.filledFormType
      }
    },
    cleanString(str){
      return removeStringUnderscore(str)
    },
    momentFormat(date, isShort = false){
      if(date){
        if(isShort) {
          return date ? moment(date).format('MMM D, YYYY') : '-'
        } else {
          return date ? moment(date).format('MMMM D, YYYY') : '-'
        }
      }
    }
  }
}
</script>