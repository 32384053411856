<template>
  <div>
    <div class="h-full">
      <Table
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="devicesData"
        @onPageChange="onPageChange($event)"
        :is-loading="loading"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search IMEI"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
        <template slot="callToActionTop">
          <div>
            <label
              class="font-medium text-sm mb-1"
              :style="{ color: theme.primaryColor }"
              >Filter Date Range</label
            >
            <DatePicker
              ref="childDatePicker"
              :date="selectedDateRange"
              @selected="filterByDate($event)"
              range
              noNavigation
              :clearable="false"
              class="mb-5 mt-1"
            />
          </div>
        </template>
        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li v-if="!data.isAcknowledged">
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="acknowledgeInstall(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="check" class="my-auto mr-2" />
                    Acknowledge
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="addRemarks(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>
    </div>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'remarks'">
        <AddRemarksForm :detailsId="modalId" />
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { Modal, Table, Dropdown } from "@/components/commons";
import AddRemarksForm from "@/components/forms/device/add-remarks-form";
import { formatDate, formatPaginationSettings } from "@/_helper";
import DatePicker from "@/components/commons/field/DatePicker";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Uninstall-Events",
  components: {
    Modal,
    Table,
    Dropdown,
    AddRemarksForm,
    DatePicker,
    StyledIconedLink,
  },
  data() {
    return {
      modalContent: false,
      selectedDateRange: [],
      modalTitle: "",
      modalId: "",
      deviceInstalls: [],
      modalHeight: "80vh",
      tableHeader: [
        "IMEI",
        "Driver",
        "Model",
        "OS",
        "Status",
        "Date",
        "Remarks",
      ],
      tableData: [],
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      readDevices: false,
      writeDevices: false,
      readSecurityConfig: false,
      readUserRoleAuditTrail: false,
      userType: null,
      hasRole: true,
      businessID: "",
      filter: {
        limit: 10,
        search: "",
      },
      loading: false,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init(paged = 1) {
      this.loading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.selectedDateRange.length > 0) {
        query.from = moment(this.selectedDateRange[0]).format("YYYY-MM-DD");
        query.to = moment(this.selectedDateRange[1]).format("YYYY-MM-DD");
      }

      let devices;

      const businessDeviceIdentity = await this.$store.dispatch(
        "devices/getDevicesByUninstalledFlag",
        { uninstalledFlag: "uninstalled", query }
      );
      if (!businessDeviceIdentity?.results) {
        this.deviceInstalls = [];
      }

      if (businessDeviceIdentity?.metadata) {
        this.paginationSettings = formatPaginationSettings(
          businessDeviceIdentity?.metadata
        );
        devices = businessDeviceIdentity.results;
      }
      if (devices) {
        const deviceTableData = this.formatItem(
          _.orderBy(devices, ["createdAt"], ["desc"])
        );
        this.deviceInstalls = deviceTableData;
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.loading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    async filterByDate(event) {
      this.selectedDateRange = event;
      await this.init();
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const device = {
            id: item._id,
            driverId: item.driverId,
            imei: item.imei,
            uninstalledFlag: item.uninstalledFlag ? "Uninstalled" : "Installed",
            driverName: item.driverData.driverName,
            model: `${item.maker} ${item.model}`,
            os: `${item.os} ${item.osVersion}`,
            uniqueDeviceId: item.uniqueDeviceId,
            createdAt: formatDate(item.createdAt),
            isAcknowledged: item.isAcknowledged,
            remarks: item.remarks,
          };
          nItems.push(device);
        });
      }
      return nItems;
    },

    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },

    parseDevices(data) {
      return data.map((deviceData) => [
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.imei,
          itemType: "string",
        },
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.driverName,
          itemType: "string",
        },
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.model,
          itemType: "string",
        },
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.os,
          itemType: "string",
        },
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.uninstalledFlag,
          itemType: "string",
        },
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.createdAt,
          itemType: "string",
        },
        {
          driverId: deviceData.driverId,
          id: deviceData.id,
          name: deviceData.remarks,
          itemType: "string",
        },
      ]);
    },
    addRemarks(id) {
      this.$refs.modal.openModal();
      this.modalContent = "remarks";
      this.modalTitle = "Add Remarks";
      this.modalId = id;
    },
    acknowledgeInstall(id) {
      if (confirm("Acknowledge this device identity?")) {
        this.$store
          .dispatch("devices/acknowledgeDevice", {
            id: id,
            isAcknowledged: true,
          })
          .then((device) => {
            if (device) {
              location.reload();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
  },
  computed: {
    devicesData() {
      if (this.deviceInstalls) {
        return this.parseDevices(this.deviceInstalls);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
