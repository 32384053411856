<template>
  <div>
    <Table
      v-if="readBusinesses"
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="businessesData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li v-if="data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto" />
                  View
                </StyledIconedLink>
              </li>
              <li v-if="data.uid">
                <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="showBilling(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="money-bill-wave" class="my-auto" />
                  Billing
                </StyledIconedLink>
              </li>
              <li v-if="data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="editBusiness(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto" />
                  Edit
                </StyledIconedLink>
              </li>
              <li>
                <DrillDown :uid="data.uid" role="business" />
              </li>
              <li v-if="!data.uid">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteBusiness(data)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto" />
                  Delete
                </StyledIconedLink>
              </li>
              <li v-if="!data.uid">
                <StyledIconedLink
                  title="Resend Invite Email"
                  @click.prevent="resendInvite(data.id)"
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="paper-plane" class="my-auto" />
                  Resend Invitation
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <button
          class="btn btn-primary"
          @click.prevent="newBusiness()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          New Business
        </button>
      </template>
      <div>Not Found</div>
    </Table>

    <div v-if="!readBusinesses" class="text-center">
      <h1>
        Not authorized to view businesses. Please contact your {{ userType }}.
      </h1>
    </div>

    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <BusinessResellerContractStep @closeModal="closeModal" />
      </div>
      <div v-if="modalContent == 'view'">
        <BusinessDetailView @editBusiness="editBusiness" />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditBusinessResellerStep :detailsId="modalId" />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from '@/components/commons/';
import BusinessDetailView from '@/components/view/business';
import {
  BusinessResellerContractStep,
  EditBusinessResellerStep,
} from '@/components/forms/business/';
import DrillDown from '@/components/modules/DrillDown.vue';
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from '@/_helper/constants';
import { formatDate, formatPaginationSettings } from '@/_helper';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { StyledIconedLink } from '@/styled-components';

export default {
  name: 'Admin-Businesses',
  components: {
    Modal,
    Table,
    DrillDown,
    BusinessResellerContractStep,
    BusinessDetailView,
    EditBusinessResellerStep,
    StyledIconedLink,
    Dropdown
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: '',
      modalId: '',
      businesses: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        'Business Name',
        'Email',
        'Contact Phone',
        'ABN',
        'Created At',
        'Enable / Disable',
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      readBusinesses: false,
      writeBusinesses: false,
      userType: null,
      hasRole: true,
      filterStatuses: {
        all: 'All',
        linked: 'Linked',
        pending: 'Pending',
      },
      filter: {
        limit: 10,
        status: 'all',
        search: '',
      },
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    newBusiness: function () {
      this.modalContent = 'new';
      this.modalTitle = 'New Business';
      this.$refs.modal.toggleModal();
    },
    viewBusiness: function (id) {
      this.modalContent = 'view';
      this.modalTitle = 'Business Info';
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },
    editBusiness: function (id) {
      this.modalContent = 'edit';
      this.modalTitle = 'Business Edit';
      this.$refs.modal.openModal();
      this.modalId = id;
    },
    formatItem: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          let webProfiles = item.webProfiles.filter(
            (x) => x.parentRole == 'business'
          );
          const webProfile = webProfiles.length > 0 ? webProfiles[0] : null;
          const business = {
            uid: webProfile?.uid,
            id: item?._id,
            name: item?.persona?.businessName || '',
            email: item?.supportEmail || '',
            phone: item?.supportPhone || '',
            abn: item?.persona?.abn || '',
            created: formatDate(item?.createdAt),
            status: item?.isActive || false,
            isLicensed: item.isLicensed || false,
          };
          nItems.push(business);
        });
      }
      return nItems;
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;
      this.$emit('updateLoading', true);

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != 'all')
        query.filter.status = { $eq: this.filter.status };

      const me = this.$store.getters[`account/me`];
      let securityRoles = me?.securityRoles;
      if (securityRoles && securityRoles.length > 0) {
        this.userType = securityRoles[0].type;
        const securityAccessObj = await this.setSecurityAccess(securityRoles);
        this.readBusinesses = securityAccessObj.readBusinesses;
        this.writeBusinesses = securityAccessObj.writeBusinesses;
      } else {
        this.hasRole = false;
      }
      // const me = this.$store.getters[`account/me`];
      const entityID = this.$store.getters[`account/me`].entity._id;

      const business = await this.$store.dispatch(
        `reseller/getResellerBusinessesByID`,
        { id: entityID, query }
      );
      if (business) {
        if (business?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            business?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        this.businesses = this.formatItem(
          _.orderBy(business.results, ['createdAt'], ['desc'])
        );

        this.isLoading = false;
        this.$emit('updateLoading', false);
      }
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    setSecurityAccess(securityRoles) {
      let initialReadBusinesses = false;
      let initialWriteBusinesses = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.businesses.readBusinesses.value) {
          initialReadBusinesses =
            securityConfig.businesses.readBusinesses.value;
        }
        if (securityConfig.businesses.writeBusinesses.value) {
          initialWriteBusinesses =
            securityConfig.businesses.writeBusinesses.value;
        }
      });

      return {
        readBusinesses: initialReadBusinesses,
        writeBusinesses: initialWriteBusinesses,
      };
    },
    parseBusiness(data) {
      return data.map((businessData) => [
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.name,
          itemType: 'name',
          hasImage: true,
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.email,
          itemType: 'string',
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.phone,
          itemType: 'string',
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.abn,
          itemType: 'string',
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.created,
          itemType: 'string',
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.status,
          itemType: 'toggle',
        },
      ]);
    },

    async resendInvite(businessId) {
      if (confirm('Resend invite email to this business?')) {
        await this.$store
          .dispatch('business/resendBusinessInvite', businessId)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit('setMessageNotify', {
                state: 'error',
                message: res.message,
              });
            } else {
              this.$store.commit('setMessageNotify', {
                state: 'success',
                message: res.message,
              });
            }
            this.$store.commit('setDialogNotify', true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit('setDialogNotify', true);
            this.$store.commit('setMessageNotify', {
              state: 'error',
              message: errorMessage,
            });
          });
      }
    },

    async deleteBusiness(data) {
      if (
        confirm(
          `Are you sure you want to delete ${data.name}? It cannot be undone.`
        )
      ) {
        await this.$store
          .dispatch('business/deleteBusiness', data.id)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit('setMessageNotify', {
                state: 'error',
                message: res.message,
              });
            } else {
              this.$store.commit('setMessageNotify', {
                state: 'success',
                message: res.message,
              });
            }
            this.$store.commit('setDialogNotify', true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit('setDialogNotify', true);
            this.$store.commit('setMessageNotify', {
              state: 'error',
              message: errorMessage,
            });
          });
      }
    },

    showBilling(id) {
      window.location.href = `/reseller/businesses/billing/${id}`;
    },
  },
  computed: {
    businessesData() {
      if (this.businesses) {
        return this.parseBusiness(this.businesses);
      } else {
        return [];
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
