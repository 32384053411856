<template>
  <div class="py-4 px-2">
    <div class="loading centered" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-400">Loading data...</span>
      </div>
    </div>
    <div v-else>
      <label class="block mb-2 text-md font-medium text-gray-900"
        >Select partners to add to the orphan list</label
      >
      <Table
        :isLoading="tableLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="partnersData"
        :has-actions="false"
        :has-checkbox="true"
        @onPageChange="onPageChange($event)"
        @onDataSelect="onDataSelect"
      >
        <template slot="tableFilters">
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Search"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
        </template>
      </Table>
      <div class="border-t border-neutral-light mt-8">
        <div class="flex items-center justify-end w-full mt-4">
          <FormulateInput
            type="submit"
            label="Submit"
            outer-class="w-50"
            @click="submit"
            input-class="btn btn-primary w-50"
            :disabled="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { Table } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";
export default {
  name: "AddPartnerToOrphanList",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      tableLoading: false,
      tableHeader: [
        "Business Name",
        "Email Address",
        "Phone",
        "ABN",
        "Created At",
      ],
      tableData: [],
      partners: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      filterStatuses: {
        all: "All",
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
        soloOrphan: false,
        hasPaymentGateway: true,
      },
      selectedData: [],
    };
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init(paged = 1) {
      this.tableLoading = true;
      // const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      query.filter.soloOrphan = this.filter.soloOrphan;
      query.filter.hasPaymentGateway = this.filter.hasPaymentGateway;

      const partners = await this.$store.dispatch(`partner/getPartner`, {
        query,
      });
      if (partners) {
        if (partners?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            partners?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const partnersTableData = this.formatItem(
          _.orderBy(partners.results, ["createdAt"], ["desc"])
        );
        this.partners = partnersTableData;
        this.tableLoading = false;
      }
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const driver = {
            uid: item?.uid,
            id: item?._id,
            name: item?.persona?.businessName,
            email: item?.persona?.contactEmail,
            phone: item?.persona?.contactPhoneNumber,
            abn: item?.persona?.abn,
            created: formatDate(item?.createdAt),
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    async submitSuccess() {
      await this.init();
    },
    closeRefresh() {
      this.$refs.modal.toggleModal();
      this.init();
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parsePartners(data) {
      return data.map((partnerData) => [
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.id,
          itemType: "checkbox",
          checked: false,
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.name,
          itemType: "name",
          hasImage: true,
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.email,
          itemType: "string",
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.phone,
          itemType: "string",
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.abn,
          itemType: "string",
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.created,
          itemType: "string",
        },
      ]);
    },
    onDataSelect(data) {
      this.selectedData = [];
      data.forEach((partner) => {
        if (partner[0].checked === true) {
          this.selectedData.push(partner[0].id);
        }
      });
    },
    submit() {
      const data = {
        ids: this.selectedData,
      };

      this.$store
        .dispatch(`partner/updatePartnersSoloOrphan`, data)
        .then((response) => {
          if (response) {
            this.$emit("closeModal");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Partner added successfully.",
            });
            this.$emit("submitSuccess", response);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: "Error adding partner.",
          });
        });
    },
  },
  computed: {
    partnersData() {
      if (this.partners) {
        return this.parsePartners(this.partners);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
