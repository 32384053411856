<template>
  <div>
    <FormulateForm v-model="values" @submit="onSubmit">
      <FormulateInput
        type="email"
        name="emailAddress"
        label="Email Address"
        validation="required|email"
        :disabled="isSubmitLoading"
      />
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="text"
          name="driverName"
          label="Full Name"
          validation="required"
          :disabled="isSubmitLoading"
        />
        <FormulateInput
          type="date"
          name="driverDateOfBirth"
          label="Date Of Birth"
          validation="required"
          :disabled="isSubmitLoading"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="text"
          name="driversLicenseNumber"
          label="Licence Number"
          validation="required"
          :disabled="isSubmitLoading"
        />
        <FormulateInput
          type="date"
          name="driversLicenseExpiry"
          label="Licence Expiry"
          validation="required"
          :disabled="isSubmitLoading"
        />
      </div>
      <FormulateInput
        type="select"
        name="driversLicenseState"
        label="Licence State"
        :options="states"
        validation="required"
        :disabled="isSubmitLoading"
      />

      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :disabled="isSubmitLoading"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { STATES } from "@/constants/location";
export default {
  name: "Add-Driver-Form",
  props: {
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isSubmitLoading: false,
      loading: false,
      values: {},
      errorMessage: "",
      states: STATES,
    };
  },

  methods: {
    async onSubmit() {
      this.isSubmitLoading = true;
      this.values.emailAddress = this.values.emailAddress.toLowerCase();
      let newDriver = JSON.parse(JSON.stringify(this.values));
      this.$emit("nextStep", { newDriver: newDriver });
    },
  },
};
</script>
