<template>
  <div>
    <Stepper
      :stepperSteps="stepperSteps"
      @completed-step="completeStep"
      @active-Step="isStepActive"
      :showNavigation="false"
      @closeRefresh="closeRefresh"
    />
  </div>
</template>
<script>
import { Stepper } from "@/components/commons";
import { CreateDriver, CreateBusinessProfile } from './steps';
export default {
  components: {
    Stepper
  },
  props: {
    linkData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      stepperSteps: [
        {
          stepIcon: 'user',
          stepTitle: 'Create Driver',
          stepSequence: 'first',
          stepComponent: CreateDriver,
          isCompleted: true,
          defaultData: this.linkData,
        },
        {
          stepIcon: 'briefcase',
          stepTitle: 'Business Profile',
          stepSequence: 'second',
          stepComponent: CreateBusinessProfile,
          isCompleted: false,
          defaultData: this.linkData,
        }
      ]
    }
  },
}
</script>