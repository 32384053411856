import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class DynamicLink {
  createBusinessIntegrationLink(payload) {
    return API.ACCOUNTS.post(`dynamic-link/create-integration-link`, payload)
  }
  createNotificationAPILink(payload) {
    return API.ACCOUNTS.post(`dynamic-link/create-notification-api-link`, payload)
  }
  createBatchBusinessIntegrationLinks(payload) {
    return API.ACCOUNTS.post(`dynamic-link/create-batch-integration-link`, payload)
  }

  createPromoSignupLink(payload) {
    return API.ACCOUNTS.post(`dynamic-link/create-promo-link`, payload)
  }

  createPromoSoloSignup(payload) {
    return API.ACCOUNTS.post(`dynamic-link/create-promo-solo-signup/${payload.dynamicLinkId}`, payload)
  }

  createContractorSignupLink(payload) {
    return API.ACCOUNTS.post(`dynamic-link/create-contractor-signup-link`, payload)
  }

  getBusinessIntegrationLinks(query = { skip: 0, limit: 10, filter: {} }) {
    let filter = '';
    if (typeof query.filter === 'object') try { filter = JSON.stringify(query.filter) } catch(e) { throw 'Invalid Filter object' }
    query.filter = filter;
    if (query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`dynamic-link/find-all-business-integration-links/${query}`)
  }

  getPartnerBusinessIntegrationLinks(data, query = { skip: 0, limit: 10, filter: {} }) {
    let filter = ''
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/dynamic-link/find-partner-business-integration-links/${data.partnerId}${query}`)
  }

  getMyBusinessIntegrationLinks(data, query = { skip: 0, limit: 10, filter: {} }) {
    let filter = ''
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/dynamic-link/find-my-business-integration-links/${data.businessId}${query}`)
  }


  getEntityPromoLinks(data, query = { skip: 0, limit: 10, filter: {} }) {
    let filter = ''
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/dynamic-link/find-entity-promo-links/${data.entityId}${query}`)
  }

  getPromoLinkByUrl(data) {
    return API.ACCOUNTS.post(`dynamic-link/find-promo-link-by-url`, data)
  }

  getContractorLinkByUrl(data) {
    return API.ACCOUNTS.post(`dynamic-link/find-contractor-link-by-url`, data)
  }

  getBusinessNotificationLink(payload) {
    return API.ACCOUNTS.get(`dynamic-link/find-business-notification-link/${payload.businessId}`);
  }
  getBusinessContractorInviteLink(payload) {
    return API.ACCOUNTS.get(`/dynamic-link/find-business-contractor-invite-link/${payload.businessId}`)
  }

  updateBusinessIntegrationLink(payload) {
    return API.ACCOUNTS.patch(`dynamic-link/update-dynamic-link/${payload.id}`, payload)
  }

  deleteLink(payload) {
    return API.ACCOUNTS.delete(`dynamic-link/remove-link/${payload.id}`);
  }
}