export default [
  {
    component: 'div',
    class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
    children: [
      {
            type: 'text',
            name: 'businessName',
            label: 'Business Name',
            validation: 'required',
            class: 'w-full md:w-1/2'
      },
      {
            type: 'text',
            name: 'tradingName',
            label: 'Trading Name',
            validation: 'required',
            class: 'w-full md:w-1/2'
      },
      ]
    },
    {
    component: 'div',
    class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
    children: [
    {
        type: 'text',
        name: 'abn',
        label: 'ABN',
        validation: 'required',
        class: 'w-full md:w-1/2'
    },
    {
        type: 'text',
        name: 'contactUserName',
        label: 'Contact Username',
        validation: 'required',
        class: 'w-full md:w-1/2'
    },
],
},
{
    component: 'div',
    class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
    children: [
        {
            type: 'email',
            name: 'contactEmail',
            label: 'Contact Email',
            validation: '^required|email',
            class: 'w-full md:w-1/2'
        },
        {
            type: 'text',
            name: 'contactPhoneNumber',
            label: 'Contact Phone Number',
            validation: 'required',
            class: 'w-full md:w-1/2'
        },
    ]
},
  {
  component: 'div',
  class: 'flex md:flex-row flex-col md:space-x-4 justify-between items-center',
  children: [
    {
        type: 'email',
        name: 'supportEmail',
        label: 'Support Email',
        validation: '^required|email',
        class: 'w-full md:w-1/2'
    },
    {
        type: 'text',
        name: 'supportPhoneNumber',
        label: 'Support Phone Number',
        validation: 'required',
        class: 'w-full md:w-1/2'
    },
  ]
  },
  { 
    type: 'group',
    name: 'location',
    label: 'Location:',
    labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
    class: 'border rounded p-5 mb-5 mt-10 relative',
    children: [
      {
        name: 'businessAddress',
        label: 'Business Address',
        validation: 'required',
        type: 'places-search-input',
      },
     
    ]
  },
]