<template>
<div>
    <div class="mb-16" v-if="!loading">
        <div class="text-center mb-10">
             <div class="flex text-right justify-end"> 
                <a @click.prevent="onClickEdit(solo.id)" class="flex cursor-pointer"><font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/></a>
                <!-- <a class="flex cursor-pointer"><font-awesome-icon icon="trash" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/></a> -->
            </div>
            <div class="mx-auto w-24"><img class="rounded-md border" :src="solo.LogoLightBackground" alt=""></div>
            <div class="font-bold text-black-primary text-2xl"> 
                {{solo.name}}
            </div>
            <div class="font-bold text-xs" :class="[solo.isActive ? 'text-green-500' : 'text-red-500']">{{solo.isActive ? 'ACTIVE' : 'INACTIVE'}}</div>
        </div>
        <div class="mb-5 w-full border-b pb-1">
            <div class="text-xs font-bold">Personal Information</div>
        </div>
<!--    <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{business.name}}</div>
            <div class="text-xs">Business Name</div>
        </div> -->
        <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{solo.udi}}</div>
            <div class="text-xs">UDI</div>
        </div>
        <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{solo.email}}</div>
            <div class="text-xs">Email Address</div>
        </div>
        <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{solo.dateOfBirth}}</div>
            <div class="text-xs">Date of Birth</div>
        </div>
        <div class="mt-8 mb-5 w-full border-b pb-1">
            <div class="text-xs font-bold">Licence Details</div>
        </div>
        <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{solo.licenseNumber}}</div>
            <div class="text-xs">Licence Number</div>
        </div>
        <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{solo.licenseExpiry}}</div>
            <div class="text-xs">Licence Expiry</div>
        </div>
        <div class="mb-5">
            <div class="font-bold text-black-primary text-base">{{solo.licenseState}}</div>
            <div class="text-xs">Licence State</div>
        </div>
        <div class="mt-8 mb-5 w-full border-b pb-1">
            <div class="text-xs font-bold">Subscription Plan</div>
        </div>
              <div class="flex flex-wrap justify-start -mx-4">
        <div class="w-1/2 px-4 wrapper">
          <div 
            class="
            bg-white
            rounded-xl 
            relative 
            z-10 
            overflow-hidden 
            border border-white-400 border-opacity-20
            shadow-pricing
            mb-10
            ">
              <div class="py-8 px-8 sm:p-12 lg:py-8 lg:px-6 xl:p-12">
                <span class="text-black font-bold text-xl block mb-8">
                  {{ activeSolo.billingPeriod.name }}
                </span>
                <h2 class="font-bold text-black text-4xl" v-if="activeSolo.billingPeriod.name == 'Monthly'">
                  ${{ activeSolo.businessModules[0].price.monthly.toFixed(2) }}
                </h2>
                <h2 class="font-bold text-black text-4xl" v-else>
                  ${{ activeSolo.businessModules[0].price.yearly.toFixed(2) }}
                </h2>
                <p>
                  <span v-if="activeSolo.billingPeriod.name == 'Monthly'" class="text-base text-gray-500 font-medium">
                  Per month
                  </span>
                  <span v-else-if="activeSolo.billingPeriod.name == 'Yearly'" class="text-base text-gray-500 font-medium">
                  Per year
                  </span>
                </p>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
    </div>
</div>
</template>
<script>
import { formatDate } from '@/_helper'
import moment from 'moment'
export default {
  name: 'Solo-Driver-View',
  props: {
    activeSolo: {
      type: Object,
      default: () => {}
    }
  },
  data(){
    return{
      solo: {},
      loading: false
    }
  },
  watch: {
    activeSolo: {
      deep: true,
      handler() {
        this.setActiveSoloData()
      }
    }
  },
  async created() {
    this.loading = true
    await this.setActiveSoloData()
    this.loading = false
  },
  methods: {
    parseSolo(solo){
      return {
        id: solo._id,
        name: solo.businessMetaData.driverName,
        dateOfBirth: moment(solo.businessMetaData.driverDateOfBirth).format('DD/MM/YYYY'),
        email: solo.businessMetaData.emailAddress,
        licenseNumber: solo.businessMetaData.driversLicenseNumber,
        licenseExpiry: formatDate(solo.businessMetaData.driversLicenseExpiry, 'DD/MM/YYYY'),
        licenseState: solo.businessMetaData.driversLicenseState,
        udi: solo.businessMetaData.udi,
        isActive: solo.businessMetaData.isActive
      }
    },
    setActiveSoloData(){
        this.solo = this.parseSolo(this.activeSolo);
    },
    onClickEdit(id){
        this.$emit('editDriver', id)
    }
  }
}
</script>