<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm
        v-model="values"
        :schema="editRoleSchema"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="values.isAdmin || hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="error">
        {{ errorMessage }}
      </div>
      <FormulateForm @submit="onSubmitLinkUser" #default="{ hasErrors }">
        <FormulateInput
          v-model="uid"
          type="select"
          label="Link a user"
          :disabled="hasErrors || loading"
          :options="userOptions"
        />
        <FormulateInput
          type="submit"
          label="Link"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
      <FormulateForm @submit="onSubmitLinkParentRole" #default="{ hasErrors }">
        <FormulateInput
          v-model="parentRoleId"
          type="select"
          label="Link a parent role"
          :disabled="hasErrors || loading"
          :options="parentRoleOptions"
        />
        <FormulateInput
          type="submit"
          label="Link"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
      <FormulateForm
        @submit="onSubmitLinkRespo"
        #default="{ hasErrors }"
        v-if="userType == `business`"
      >
        <FormulateInput
          v-model="respoId"
          type="select"
          label="Link a responsibility"
          :disabled="hasErrors || loading"
          :options="respoOptions"
        />
        <FormulateInput
          type="submit"
          label="Link"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
      <FormulateForm
        @submit="onSubmitLinkDepot"
        #default="{ hasErrors }"
        v-if="userType == `business`"
      >
        <FormulateInput
          v-model="depotId"
          type="select"
          label="Link a depot"
          :disabled="hasErrors || loading"
          :options="depotOptions"
        />
        <FormulateInput
          type="submit"
          label="Link"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import editRoleSchema from "./new-role-schema";
import { mapGetters } from "vuex";

export default {
  name: "Edit-Role-Form",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
    userType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      loading: false,
      values: {},
      schema: editRoleSchema,
      respoOptions: [],
      respoId: null,
      parentRoleOptions: [],
      parentRoleId: null,
      userOptions: [],
      uid: null,
      depotOptions: [],
      depotId: null,
    };
  },
  async mounted() {
    this.loading = true;
    const proxySite = this.$store.getters[`account/proxySite`];
    const isProxySite = proxySite && !proxySite.isMainBusiness;
    const users = await this.$store.dispatch(`rolesandrespo/getUsers`, {
      ...(isProxySite ? { site: proxySite._id } : {}),
    });
    if (users) {
      this.userOptions = this.formatUsers(users);
    }

    const roles = await this.$store.dispatch(`rolesandrespo/getRoles`, null);
    if (roles) {
      this.values = this.getTargetRole(roles, this.detailsId);

      this.parentRoleOptions = this.formatRoles(roles, this.detailsId);
    }

    if (this.userType == "business") {
      const respos = await this.$store.dispatch(`rolesandrespo/getRespos`, {});
      if (respos) {
        this.respoOptions = this.formatRespos(respos);
      }

      const me = this.$store.getters[`account/me`];
      const depots = await this.$store.dispatch(
        `rolesandrespo/getDepotsByOwnerUid`,
        me.uid
      );
      if (depots) {
        this.depotOptions = this.formatDepots(depots);
      }
    }
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      try {
        await this.$store.dispatch(`rolesandrespo/patchRole`, this.values);
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },

    async onSubmitLinkRespo() {
      try {
        const payload = {
          id: this.values._id,
          responsibilityId: this.respoId,
        };
        await this.$store.dispatch(`rolesandrespo/linkRespo`, payload);
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },
    async onSubmitLinkParentRole() {
      try {
        const payload = {
          id: this.values._id,
          parentRoleId: this.parentRoleId,
        };
        await this.$store.dispatch(`rolesandrespo/linkParentRole`, payload);
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },

    async onSubmitLinkUser() {
      try {
        const payload = {
          id: this.values._id,
          uid: this.uid,
        };
        await this.$store.dispatch(`rolesandrespo/linkUser`, payload);
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },

    async onSubmitLinkDepot() {
      try {
        const payload = {
          id: this.values._id,
          depotId: this.depotId,
        };
        await this.$store.dispatch(`rolesandrespo/linkDepot`, payload);
        location.reload();
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }
    },

    getTargetRole(roles, targetRoleId) {
      return roles.find((role) => {
        return role._id == targetRoleId;
      });
    },
    formatRespos(respos) {
      const respoOptions = [];
      if (respos) {
        respos.data.forEach((respo) => {
          const option = {
            value: respo._id,
            label: respo.name,
          };
          respoOptions.push(option);
        });
      }
      return respoOptions;
    },
    formatRoles(roles, currentRoleId) {
      const parentRoleOptions = [];
      if (roles) {
        roles.forEach((role) => {
          if (role._id != currentRoleId) {
            const option = {
              value: role._id,
              label: role.name,
            };
            parentRoleOptions.push(option);
          }
        });
      }
      return parentRoleOptions;
    },
    formatUsers(users) {
      return users.map((user) => {
        return {
          value: user.uid,
          label: user.userName,
        };
      });
    },
    formatDepots(depots) {
      const depotOptions = [];
      if (depots) {
        depots.forEach((depot) => {
          const option = {
            value: depot._id,
            label: depot.name,
          };
          depotOptions.push(option);
        });
      }
      return depotOptions;
    },
  },
  computed: {
    editRoleSchema() {
      return editRoleSchema;
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
