import { User } from "../common/user";

export class LinkedDriver extends User{
    dateOfBirth = '';
    licenseNumber = '';
    licenseState = '';
    licenseExpiry = '';

    constructor(driver){
        super(driver.driverId, driver.emailAddress, driver.driverName, 'driver', driver.persona?.contactPhoneNumber);
        this.dateOfBirth = driver.driverDateOfBirth;
        this.licenseNumber = driver.driversLicenseNumber;
        this.licenseState = driver.driversLicenseState;
        this.licenseExpiry = driver.driversLicenseExpiry;
    }
}