<template>
  <div>
    <div
      v-if="loading"
      class="mx-auto my-24 h-80 w-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-if="!loading">
      <div class="w-full h-full items-center">
        <div class="mb-5 relative">
          <div class="h-full">
            <button
              class="absolute ml-20 z-30"
              @click.prevent="showDriverListFromPerson"
            >
              <small
                v-if="!isDriverListOpenFromPerson"
                class="btn-primary btn-xs px-3 shadow rounded-lg"
              >
                + Select from driver list
              </small>
              <small
                v-else
                class="bg-red-600 text-white btn-xs px-3 shadow rounded-lg"
              >
                <font-awesome-icon icon="times" />&nbsp; Close
              </small>
            </button>

            <div class="relative">
              <div
                v-if="isDriverListOpenFromPerson"
                class="w-full px-3 h-96 pb-3 -mt-6 pt-7 absolute z-30 bg-white shadow-lg"
              >
                <div class="content-center items-center w-full">
                  <div classs="w-full content-center">
                    <label
                      class="block text-gray-700 text-sm font-bold mb-2"
                      for="username"
                    >
                      Search driver
                    </label>
                    <FormulateInput
                      type="driver-select"
                      placeholder="Select a driver"
                      style="margin-bottom: 0px"
                      class="pb-4 content-center"
                      :getOptionKey="(option) => option.driver.udi"
                      :getOptionLabel="(option) => option.driver.driverName"
                      :filterable="false"
                      :clearable="false"
                      validation="required"
                      @input="onChange($event, 'person')"
                    />
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label>Person</label>
          <div class="pb-2 border rounded-lg w-full">
            <input
              class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
              v-model="newPerson"
              @keydown.enter.prevent="addPerson"
              placeholder="Add person"
              onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
            />

            <ul
              v-if="newPerson && leftOverPersons.length"
              class="w-full px-3 pb-3 pt-1 absolute z-30 bg-white shadow-lg overflow-y-auto max-h-44"
            >
              <li
                v-for="(result, i) in leftOverPersons"
                :key="i"
                @click="addPerson(result, true)"
                class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600"
              >
                {{ result.name }}
              </li>
            </ul>

            <div class="flex items-center flex-wrap px-2 pb-1">
              <span class="py-2 invisible">:</span>
              <small
                v-for="(data, index) in selectedPersons"
                class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
                :key="index"
              >
                {{ data.name
                }}<button
                  @click.prevent="removePerson(data)"
                  class="ml-2 mr-1 px-1"
                >
                  <b>x</b>
                </button>
              </small>
            </div>
          </div>
        </div>
        <br />

        <FormulateInput
          type="date"
          name="findingsDate"
          label="Date"
          validation="required"
          min="2018-12-01"
          v-model="formValues.findingsDate"
        />

        <FormulateInput
          type="textarea"
          name="findingsDetails"
          label="Findings Details"
          validation="required"
          placeholder="Write the details here..."
          v-model="formValues.findingsDetails"
          rows="8"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";

export default {
  name: "Interview-Form",
  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    activeDocForm: {
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      formValues: {},
      businessUsers: [],
      newPerson: "",
      selectedPersons: [],
      alreadyAssignedPersons: [],
      newInterviewer: "",
      selectedInterviewers: [],
      alreadyAssignedInterviewers: [],
      hasDriver: false,
      isDriverListOpenFromPerson: false,
      isDriverListOpenFromInterviewer: false,
    };
  },
  computed: {
    leftOverPersons() {
      let setArr = [],
        results = [];
      if (
        this.alreadyAssignedPersons.length > 0 &&
        this.businessUsers.length > 0
      ) {
        let thisUsers = this.alreadyAssignedPersons;

        if (this.alreadyAssignedPersons.length > 0) {
          setArr = this.businessUsers.filter(function (val) {
            for (let i = 0; i < thisUsers.length; i++) {
              let arr = thisUsers[i];
              if (val.userId == arr.userId) return false;
            }
            return true;
          });
        } else {
          setArr = this.businessUsers;
        }
      } else {
        setArr = this.businessUsers;
      }

      // Search accurate match query
      if (this.newPerson && setArr.length) {
        const thisType = this.newPerson;
        results = setArr.filter((item) =>
          thisType
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v))
        );
      } else {
        results = setArr;
      }

      // Sort by name
      if (results.length) {
        return results.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return results;
      }
    },
  },
  async mounted() {
    const proxySite = this.$store.getters[`account/proxySite`];
    const isProxySite = proxySite && !proxySite.isMainBusiness;
    const users = await this.$store.dispatch(`rolesandrespo/getUsers`, {
      ...(isProxySite ? { site: proxySite._id } : {}),
    });
    if (users.length > 0) this.businessUsers = this.formatUsers(users);
    this.setActiveDocForm();
    this.loading = false;
  },
  methods: {
    setActiveDocForm() {
      if (this.isEditMode) {
        this.formValues = this.activeDocForm;
        this.selectedPersons = this.activeDocForm.persons || [];
      }
    },
    addPerson(data, fromList = false) {
      let name = data.name || this.newPerson;
      let exiting = this.selectedPersons.filter((item) => item.name == name);
      if (_.isEmpty(exiting)) {
        if (!fromList) {
          if (_.isEmpty(this.newPerson)) return;
          data = {
            userId: null,
            name: this.newPerson,
            userType: "stringbase",
          };
        } else {
          data.userType = "business";
        }
        this.selectedPersons.push(data);
        this.alreadyAssignedPersons.push(data);
      }
      this.newPerson = "";
      this.formValues.persons = this.selectedPersons;
    },
    removePerson(user) {
      this.selectedPersons.splice(this.selectedPersons.indexOf(user), 1);
      this.alreadyAssignedPersons.splice(
        this.alreadyAssignedPersons.indexOf(user),
        1
      );
    },
    formatUsers(users) {
      return users.map((user) => {
        return {
          userId: user._id,
          name: user.userName || "",
        };
      });
    },
    showDriverListFromPerson() {
      this.isDriverListOpenFromPerson = !this.isDriverListOpenFromPerson;
    },
    onChange(data, from) {
      if (data) {
        data = {
          userId: data.driver._id,
          name: data.driver.driverName,
          userType: "driver",
        };
      }
      if (from == "person") {
        this.isDriverListOpenFromPerson = false;
        let name = data.name;
        let exiting = this.selectedPersons.filter((item) => item.name == name);
        if (_.isEmpty(exiting)) {
          this.selectedPersons.push(data);
          this.alreadyAssignedPersons.push(data);
        }
      }
    },
  },
};
</script>
>
