<template>
    <div>
        <Stepper 
            :stepperSteps="stepperSteps" 
            @completed-step="completeStep" 
            @active-step="isStepActive" 
            :showNavigation="false"  
            @closeRefresh="closeRefresh"
        />
    </div>
  </template>
  
  <script>
  import { Stepper } from '@/components/commons'
  import { SelectFile, SelectDriver, InviteDriver }  from './stepper-component'
  export default {
    name: 'New-Contractor-Import',
    props: {
      isNonEwdImport: {
        type: Boolean,
        required: false,
        default: false
      },
      availableSeats:{
        type: [String, Number],
        default: 0,
      },
    },
    components: {Stepper},
    data(){
        return{
            stepperSteps: [
                 {
                    stepIcon: 'upload',
                    stepTitle: 'Upload File',
                    stepSequence: 'first',
                    stepComponent: SelectFile,
                    isCompleted: true
                },
                {
                    stepIcon: 'users',
                    stepTitle: 'Select Drivers',
                    stepSequence: 'second',
                    stepComponent: SelectDriver,
                    availableSeats: typeof this.availableSeats == 'string' ? -1 : this.availableSeats,
                    isCompleted: false
                },
                {
                    stepIcon: 'download',
                    stepTitle: 'Send Invites',
                    stepSequence: 'third',
                    stepComponent: InviteDriver,
                    isCompleted: false
                },
            ],
            activeStep: 0,
        }
    },
     methods: {
        completeStep(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    step.isCompleted = true;
                }
            })
        },
        isStepActive(payload) {
            this.stepperSteps.forEach((step) => {
                if (step.stepSequence === payload.stepSequence) {
                    if(step.isCompleted === true) {
                        step.isCompleted = false;
                    }
                }
            })
        },
        closeRefresh(){ 
          this.$emit('closeRefresh')
        }
    },
    
  }
  </script>