<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <div class="p-5 mt-5 mb-10 border rounded-lg group">
          <div class="group-title pb-5 font-bold bg-gray-50">
            Duration and Product Count Details
          </div>

          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Contract Period:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ preData.contractDuration.label }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Billing Period:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ preData.billingPeriod.label }}</div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="loading && preData.selectedModules.cplValues.length > 0"
          class="text-center mb-16"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Generating Core platform licences pricing table...
        </div>
        <div
          v-if="!loading && cplValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold bg-gray-50">
            Core Platform Licences Pricing Details
          </div>
          .

          <table class="table-auto leading-normal p-4 w-full">
            <thead>
              <th
                class="bg-gray-100 text-center p-3 font-semibold justify-center"
              >
                Core Platform Licences Modules
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
                width="50%"
              >
                Payment Type
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                {{
                  preData.contractDuration.label === "Yearly"
                    ? "Manual Entry"
                    : "Reduce Sell Price"
                }}
              </th>
            </thead>

            <tr v-for="(modules, index) in cplValues" :key="index">
              <td :key="index" class="px-3">{{ modules.name }}</td>
              <td
                v-if="preData.contractDuration.label !== 'Yearly'"
                class="p-2"
                width="50%"
              >
                <div class="flex items-center justify-center w-full">
                  <FormulateInput
                    type="radio"
                    :options="defaultPricingType"
                    v-model="defaultContractRRP"
                    class="custom-options w-full items-center justify-center pt-3"
                    :name="`cplPricingType-${modules._id}`"
                    validation="^required"
                    :validation-messages="{ required: 'Required.' }"
                    disabled
                  />
                </div>
              </td>
              <td
                v-if="preData.contractDuration.label === 'Yearly'"
                class="p-2"
                width="50%"
              >
                <div class="flex items-center justify-center w-full">
                  <FormulateInput
                    type="radio"
                    :options="pricingType"
                    :v-model="`pricingType-model-${modules._id}`"
                    class="custom-options w-full items-center justify-center pt-3"
                    :name="`cplPricingType-${modules._id}`"
                    validation="^required"
                    :validation-messages="{ required: 'Required.' }"
                  />
                </div>
              </td>
              <td class="p-2">
                <div class="flex items-center justify-center">
                  <FormulateInput
                    type="number"
                    :name="`cplOffer-${modules._id}`"
                    placeholder="$0"
                    class="w-1/2 pt-3"
                    validation="^required"
                    :validation-messages="{ required: 'Required.' }"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="
            loading && preData.selectedModules.specialtyModulesValues.length > 0
          "
          class="text-center mb-16"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Generating Specialty modules pricing table...
        </div>
        <div
          v-if="!loading && specialtyModulesValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold bg-gray-50">
            Specialty Modules Pricing Details
          </div>
          .

          <table class="table-auto leading-normal p-4 w-full">
            <thead>
              <th class="bg-gray-100 text-center p-3 font-semibold">
                Specialty Modules
              </th>
              <th class="bg-gray-200 text-center p-3 font-semibold">
                Payment Type
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                {{
                  preData.contractDuration.label === "Yearly"
                    ? "Manual Entry"
                    : "Reduce Sell Price"
                }}
              </th>
            </thead>

            <tr v-for="(modules, index) in specialtyModulesValues" :key="index">
              <td :key="index" class="px-3">{{ modules.name }}</td>
              <td
                v-if="preData.contractDuration.label !== 'Yearly'"
                class="p-2"
                width="50%"
              >
                <div class="flex items-center justify-center w-full">
                  <FormulateInput
                    type="radio"
                    :options="defaultPricingType"
                    v-model="defaultContractRRP"
                    class="custom-options w-full items-center justify-center pt-3"
                    :name="`smPricingType-${modules._id}`"
                    validation="^required"
                    :validation-messages="{ required: 'Required.' }"
                    disabled
                  />
                </div>
              </td>
              <td
                v-if="preData.contractDuration.label === 'Yearly'"
                class="p-2"
                width="50%"
              >
                <div class="flex items-center justify-center w-full">
                  <FormulateInput
                    type="radio"
                    :options="pricingType"
                    :v-model="`pricingType-model-${modules._id}`"
                    class="custom-options w-full items-center justify-center pt-3"
                    :name="`smPricingType-${modules._id}`"
                    validation="^required"
                    :validation-messages="{ required: 'Required.' }"
                  />
                </div>
              </td>
              <td class="p-2">
                <div class="flex items-center justify-center">
                  <FormulateInput
                    type="number"
                    :name="`smOffer-${modules._id}`"
                    placeholder="$0"
                    class="w-1/2 pt-3"
                    validation="^required"
                    :validation-messages="{ required: 'Required.' }"
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>

        <FormulateInput
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Pricing",
  props: {
    preData: {},
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      PartnerPricingModel: [],
      cplValues: [],
      specialtyModulesValues: [],
      cplValuesLoading: false,
      specialtyModulesValuesLoading: false,
      pricingType: ["Base", "Yearly Discount"],
      defaultPricingType: { Base: "Base" },
      defaultContractRRP: "Base",
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const me = this.$store.getters[`account/me`];

      const partnerParent = await this.$store.dispatch(
        "partner/getPartnerByUID",
        me?.uid
      );
      if (partnerParent) {
        const activeRRPBusiness = await this.$store.dispatch(
          "standardpricing/getActiveRRPforBusiness",
          { id: partnerParent?._id }
        );

        if (activeRRPBusiness) {
          const extractCPLValues = this.preData.selectedModules.cplValues.map(
            (selected) => (selected.name === "EWD" ? "EWD-FT" : selected.name)
          );
          const extractSpecialValues =
            this.preData.selectedModules.specialtyModulesValues.map(
              (selected) => selected.name
            );

          if (extractCPLValues) {
            this.cplValues = activeRRPBusiness.filter((rrp) =>
              extractCPLValues.includes(rrp.standardPricingMaster.name)
            );
          }

          if (extractSpecialValues) {
            this.specialtyModulesValues = activeRRPBusiness.filter((rrp) =>
              extractSpecialValues.includes(rrp.standardPricingMaster.name)
            );
          }

          this.loading = false;
        }
      }
    },
    async onSubmit() {
      const pricingDetails = JSON.parse(JSON.stringify(this.values));

      const extractSelectedCPLValues = this.cplValues.map((cpl) => ({
        ...cpl,
        manualOffer: +pricingDetails[`cplOffer-${cpl._id}`] || 0,
        reduceSellPrice: +pricingDetails[`cplRSP-${cpl._id}`] || 0,
        pricingType: pricingDetails[`cplPricingType-${cpl._id}`],
        numberOfSeats:
          Number(
            this.preData.selectedModules.cplValues.find(
              (cplSeats) => cplSeats.id === cpl.standardPricingMaster._id
            )?.numberOfSeats
          ) || 0,
      }));

      const extractSelectedSMValues = this.specialtyModulesValues.map((sm) => ({
        ...sm,
        manualOffer: +pricingDetails[`smOffer-${sm._id}`] || 0,
        reduceSellPrice: +pricingDetails[`smRSP-${sm._id}`] || 0,
        pricingType: pricingDetails[`smPricingType-${sm._id}`],
        numberOfSeats:
          Number(
            this.preData.selectedModules.specialtyModulesValues.find(
              (cplSeats) => cplSeats.id === sm.standardPricingMaster._id
            )?.numberOfSeats
          ) || 0,
      }));

      const preData = {
        ...this.preData,
        cplPricing: extractSelectedCPLValues,
        smPricing: extractSelectedSMValues,
      };

      this.$emit("nextStep", JSON.parse(JSON.stringify(preData)));
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.box-shadow-none div:nth-child(2) {
  width: 50%;
  input {
    box-shadow: none !important;
  }
}
</style>
