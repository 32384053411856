import { API } from '@/api/config';
import { ObjectToQueryString } from '@/_helper';

export default new (class Driver {
  //Create solo driver
  createDriver(data) {
    data.demoOption = 'NO_DEMO';
    return API.ACCOUNTS.post(`/solo-driver`, data);
  }

  createSoloWithContract(data) {
    return API.ACCOUNTS.post('/solo-driver/create-solo-with-contract', data);
  }

  createNonEWDDriver(data) {
    return API.ACCOUNTS.post('/solo-driver/create-non-ewd-driver', data);
  }

  updateNonEWDDriver(driverId, data) {
    return API.ACCOUNTS.patch(
      `/solo-driver/update-non-ewd-driver/${driverId}`,
      data
    );
  }

  moveSoloParentEntity(data) {
    return API.ACCOUNTS.patch(
      `/solo-driver/move-solo-parent-entity/${data.entityId}`,
      data
    );
  }
  setToEWDDriver(driverId, data) {
    return API.ACCOUNTS.patch(
      `/solo-driver/set-to-ewd-driver/${driverId}`,
      data
    );
  }

  createDriverFromURL(data) {
    data.demoOption = 'NO_DEMO';
    return API.ACCOUNTS.post(`/solo-driver/new-solo-driver`, data);
  }

  createContractorDriverFromURL(data) {
    data.demoOption = 'NO_DEMO';
    return API.ACCOUNTS.post(`/solo-driver/new-contractor-driver-from-url`, data);
  }

  createInviteSoloDriverContractor(data) {
    return API.ACCOUNTS.post(
      '/solo-driver/send-invite-solo-driver-contractor',
      data
    );
  }

  createContractorDriver(data) {
    return API.ACCOUNTS.post('solo-driver/new-contractor-driver', data);
  }

  //Fetch solo driver
  getDriver(id = null, query = { skip: 0, limit: 10, filter: {} }) {
    let filter = '';
    if (id) {
      return API.ACCOUNTS.get(`/solo-driver/${id}`);
    } else {
      if (typeof query.filter === 'object')
        try {
          filter = JSON.stringify(query.filter);
        } catch (e) {
          throw 'Invalid Filter object';
        }
      query.filter = filter;
      if (query) query = '?' + ObjectToQueryString(query);
      return API.ACCOUNTS.get(`/solo-driver${query}`);
    }
  }

  getBusinessPendingContractors(
    businessId,
    query = { skip: 0, limit: 10, filter: {} }
  ) {
    let filter = '';
    if (typeof query.filter === 'object')
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    query.filter = filter;
    if (query) query = '?' + ObjectToQueryString(query);
    return API.ACCOUNTS.get(
      `/solo-driver/find-all-pending-solo-contractors/${businessId}${query}`
    );
  }

  getAllSoloWithNoContract(
    entityData,
    query = { skip: 0, limit: 10, filter: {} }
  ) {
    let filter = '';
    if (typeof query.filter === 'object')
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    query.filter = filter;
    if (query) query = '?' + ObjectToQueryString(query);
    return API.ACCOUNTS.post(
      `/solo-driver/find-all-solo-with-no-contract${query}`,
      entityData
    );
  }

  getDriverByUID(uid = null) {
    // Get single Driver by UID or Get all Driver
    return API.ACCOUNTS.get(`/solo-driver/find-one-by-uid/${uid}`);
  }

  getDriverByID(id = null) {
    // Get single Driver by Id or Get all Driver
    return API.ACCOUNTS.get(`/solo-driver/find-one-by-id/${id}`);
  }

  getContractorDriverByID(id = null) {
    // Get single Driver by Id or Get all Driver
    return API.ACCOUNTS.get(`/solo-driver/find-one-contractor-by-id/${id}`);
  }

  getContractorDriverByEmail(email = null) {
    // Get single Driver by email or Get all Driver
    return API.ACCOUNTS.get(
      `/solo-driver/find-one-contractor-by-email/${email}`
    );
  }

  getDriverByEmail(emailAddress) {
    return API.ACCOUNTS.post(`/solo-driver/find-by-email`, {
      emailAddress: emailAddress,
    });
  }
  patchDriver(id = null, data) {
    // Update single Driver by Id
    return API.ACCOUNTS.patch(`/solo-driver/${id}`, data);
  }

  updateSoloParentConnection(driverId, data) {
    return API.ACCOUNTS.patch(
      `/solo-driver/update-parent-connection/${driverId}`,
      data
    );
  }

  // Create Driver password
  patchDriverCreatePassword(id = null, data) {
    return API.ACCOUNTS.patch(`/solo-driver/create-password/${id}`, data);
  }
  acceptDriver(id = null) {
    return API.ACCOUNTS.get(`/solo-driver/accept/${id}`);
  }
  changePendingDriverEmail(data) {
    return API.ACCOUNTS.patch(`/solo-driver/change-email/${data.id}`, data);
  }
  updateContractorStatus(data) {
    return API.ACCOUNTS.get(
      `/solo-driver/update-contractor-status/${data.id}/${data.status}`
    );
  }
  abortDriver(id = null) {
    return API.ACCOUNTS.get(`/solo-driver/abort/${id}`);
  }
  getDriverLicenseHistory(id) {
    // Get all Driver License History by ID
    return API.ACCOUNTS.get(`/solo-driver/license-history/${id}`);
  }
  resendSoloInvite(driverId) {
    return API.ACCOUNTS.post(`/solo-driver/resend-invite/${driverId}`);
  }
  resendContractorInvite(contractorId) {
    return API.ACCOUNTS.post(
      `/solo-driver/resend-contractor-invite/${contractorId}`
    );
  }

  rescindContractorInvite(contractorId) {
    return API.ACCOUNTS.post(
      `/solo-driver/rescind-contractor-invite/${contractorId}`
    );
  }

  checkDriverTraining(driverId) {
    return API.ACCOUNTS.get(`/training/check-driver-training/${driverId}`);
  }

  getTrainingStatus(driverId) {
    return API.ACCOUNTS.get(`/training/status/${driverId}`);
  }

  getAllTrainingStatus(driverId) {
    return API.ACCOUNTS.get(`/training/status-all/${driverId}`);
  }

  getTraining(id) {
    return API.ACCOUNTS.get(`/training/${id}`);
  }

  declareTraining(data) {
    return API.ACCOUNTS.post(`/training/declare/`, data);
  }

  getActiveDriversStatistic(
    query = { skip: 0, limit: 10, filter: {} },
    businessId
  ) {
    let filter = '';
    if (typeof query.filter === 'object') {
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    query.filter = filter;
    if (query) {
      query = ObjectToQueryString(query);
    }

    return API.ACCOUNTS.get(
      `/solo-driver/fatigue/statistics/active/business/${businessId}?${query}`
    );
  }

  getRestDriversStatistic(
    query = { skip: 0, limit: 10, filter: {} },
    businessId
  ) {
    let filter = '';
    if (typeof query.filter === 'object') {
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    query.filter = filter;
    if (query) {
      query = ObjectToQueryString(query);
    }

    return API.ACCOUNTS.get(
      `/solo-driver/fatigue/statistics/rest/business/${businessId}?${query}`
    );
  }

  getOneDriverStatistic(driverId) {
    return API.ACCOUNTS.get(`/solo-driver/${driverId}/fatigue/statistics`);
  }

  getTrips(query = { skip: 0, limit: 10, filter: {} }, businessId) {
    let filter = '';
    if (typeof query.filter === 'object') {
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    query.filter = filter;
    if (query) {
      query = ObjectToQueryString(query);
    }

    return API.ACCOUNTS.get(
      `/solo-driver/trips/business/${businessId}?${query}`
    );
  }

  getMyTrips(query = { skip: 0, limit: 10, filter: {} }) {
    let filter = '';
    if (typeof query.filter === 'object') {
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    query.filter = filter;
    if (query) {
      query = ObjectToQueryString(query);
    }

    return API.ACCOUNTS.get(`/solo-driver/trips/me?${query}`);
  }

  getOneTrip(tripId) {
    return API.ACCOUNTS.get(`/solo-driver/trip/${tripId}`);
  }

  updateTrip(tripId, payload) {
    return API.ACCOUNTS.patch(`/solo-driver/trip/${tripId}`, payload);
  }

  createTrip(payload) {
    return API.ACCOUNTS.post('/solo-driver/trip/portal', payload);
  }

  createTripBulk(payload) {
    return API.ACCOUNTS.post('/solo-driver/trip/portal/bulk', payload);
  }

  getFirebaseUserSettings(driverId) {
    return API.ACCOUNTS.get(
      `/driver-settings/firebase-user-settings/${driverId}`
    );
  }

  updateFirebaseUserSettings(driverId, payload) {
    return API.ACCOUNTS.patch(
      `/driver-settings/firebase-user-settings/${driverId}`,
      payload
    );
  }
})();
