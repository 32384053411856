import { render, staticRenderFns } from "./depot-tree.vue?vue&type=template&id=66f24cef&scoped=true"
import script from "./depot-tree.vue?vue&type=script&lang=js"
export * from "./depot-tree.vue?vue&type=script&lang=js"
import style0 from "./depot-tree.vue?vue&type=style&index=0&id=66f24cef&prod&lang=scss&rel=stylesheet%2Fscss&scoped=true"
import style1 from "./depot-tree.vue?vue&type=style&index=1&id=66f24cef&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66f24cef",
  null
  
)

export default component.exports