import Dashboard from "@/pages/driver/Dashboard";
import LinkedBusinesses from "@/pages/driver/LinkedBusinesses";
import UserCreateBusinesses from "@/pages/driver/UserCreateBusinesses";

import Contract from "@/pages/driver/billing/Contract";
import PaymentHistory from "@/pages/driver/billing/PaymentHistory";
import ComplianceReport from "@/pages/driver/ComplianceReport";
import ReportBreachConfirmation from "@/pages/driver/BreachConfirmation.vue";
import ManualEvent from "@/pages/driver/ManualEvent";
import ScheduledReport from "@/pages/ScheduledReport";
import DriverWallet from "@/pages/driver/DriverWallet";
import NonConformanceDocuments from "@/pages/NonConformanceDocuments";
import LicenseDetails from "@/pages/driver/LicenseDetails";

import DriverCertificates from "@/pages/driver/Certificates";
import DriverDocuments from "@/pages/driver/Documents";
import Invoices from "@/pages/Invoice";
import Training from "@/pages/driver/training/Training.vue";
import ReleaseNotes from "@/pages/ReleaseNotes.vue";
import ReportDaily from "@/pages/driver/reports/DailyForms.vue";

// Etc
import { guards, isDriverWithContractOrSuspended } from "@/_helper/guards";

export default [
  {
    path: "/",
    name: "Dashboard - Driver",
    component: Dashboard,
  },
  {
    path: "linked-businesses",
    name: "Linked Businesses - Driver",
    component: LinkedBusinesses,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "user-created-businesses",
    name: "User Created Businesses - Driver",
    component: UserCreateBusinesses,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "billing-centre",
    name: "Billing Centre - Driver",
    component: Contract,
  },
  {
    path: "payment-history",
    name: "Payment History - Driver",
    component: PaymentHistory,
  },
  {
    path: "compliance-report",
    name: "Compliance Report - Driver",
    component: ComplianceReport,
  },
  {
    path: "breach-confirmation",
    name: "Breach Confirmation - Driver",
    component: ReportBreachConfirmation,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "manual-event",
    name: "Manual Event - Driver",
    component: ManualEvent,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "scheduled-report",
    name: "Scheduled Report - Driver",
    component: ScheduledReport,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "driver-wallet",
    name: "Driver Wallet - Driver",
    component: DriverWallet,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "non-conformance-documents",
    name: "Non Conformance - Driver",
    component: NonConformanceDocuments,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "licence-details",
    name: "License Details - Driver",
    component: LicenseDetails,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "certificates",
    name: "Certificates - Driver",
    component: DriverCertificates,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "documents",
    name: "Documents - Driver",
    component: DriverDocuments,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "invoices",
    name: "Invoices - Driver",
    component: Invoices,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "training",
    name: "Platform Training - Driver",
    component: Training,
    beforeEnter: guards([isDriverWithContractOrSuspended]),
  },
  {
    path: "support/release-notes",
    name: "Driver - Release Notes",
    component: ReleaseNotes,
  },
  {
    path: 'reports/daily',
    name: 'Daily Forms',
    component: ReportDaily,
  },
];
