<template>
  <div class="mb-16">
    <div class="relative">
      <div class="w-full" v-if="loading">
        <div
          class="absolute inset-0 z-10 flex flex-col items-center justify-center m-auto bg-gray-50"
        >
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Initializing...</span>
        </div>
      </div>
      <div class="text-xs table-top-header">
        <div class="grid grid-cols-5 mb-5">
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Driver Name
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-gray-300"
            >
              {{ manualEventData.selectedDriver }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Fatigue Plan
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 uppercase border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedPlan }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Vehicle
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedVehicle || "&nbsp;" }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Event Date
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedDate }}
            </p>
          </div>
          <div class="flex flex-col">
            <h6 class="px-3 py-2 font-bold text-gray-500 bg-gray-300">
              Time Zone
            </h6>
            <p
              class="flex items-center flex-1 px-3 py-2 border-2 border-t-0 border-l-0 border-gray-300"
            >
              {{ manualEventData.selectedTimezone }}
            </p>
          </div>
        </div>
      </div>
      <div class="table-legends"></div>
      <div class="flex w-full h-10">
        <StyledButton
          @click.prevent="goToDate(true)"
          :disabled="disabledPrevBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-left" />
        </StyledButton>
        <div
          class="relative justify-center flex-1 text-sm leading-10 text-center text-gray-300 border-2 border-l-0 border-r-0 border-gray-300 manual-event-date"
          :class="[
            value.isActive ? 'active' : 'hidden',
            `manual-event-date-${value.dateOfSheet}`,
          ]"
          :style="{ color: theme.secondaryColor }"
          v-for="value in Object.values(values)"
          :key="value.dateOfSheet"
        >
          <span>{{ formatDate(value.dateOfSheet, "MMM DD, YYYY") }}</span>
        </div>
        <StyledButton
          @click.prevent="goToDate(false)"
          :disabled="disabledNextBtn"
          :borderColor="theme.secondaryColor"
          :hoverBorderColor="theme.secondaryColor"
          class="px-3 text-sm text-gray-300 border-2 border-gray-300 disabled:opacity-50"
        >
          <font-awesome-icon icon="chevron-right" />
        </StyledButton>
      </div>
      <div class="flex overflow-x-hidden">
        <div
          class="w-full manual-event-container"
          v-for="value in Object.values(values)"
          :class="[
            value.isActive ? 'active' : 'hidden',
            `manual-event-date-${value.dateOfSheet}`,
          ]"
          :key="value.dateOfSheet"
        >
          <div
            @click.prevent="monitorEvents($event, false, value.dateOfSheet)"
            :id="`childLhv${value.dateOfSheet.replace(/-/g, '')}`"
            class="inline-block w-full manual-events"
          ></div>
        </div>
      </div>

      <div
        class="mt-5 text-sm text-red-400"
        v-if="hasEmptyFields && !isManualDriver"
      >
        * Please enter the required manual event's location and odometer.
      </div>
      <div class="grid grid-cols-2 gap-4 my-5">
        <button
          class="w-full mb-3 bg-gray-300 btn hover:bg-gray-400"
          @click="onPrevious()"
        >
          Back
        </button>
        <button
          class="w-full mb-3 btn btn-primary"
          :disabled="loading"
          @click="onSubmit()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import _ from "lodash";
import moment from "moment";
import Spinner from "@/components/commons/ui/Spinner";
import LocationData from "@/assets/location/location.json";
import LogmasterManualEvents from "@logmaster/logmaster-manual-events";
import LogmasterWahvaManualEvents from "@logmaster/logmaster-wahva-manual-events";
import { StyledButton } from "@/styled-components";
import { mapGetters } from "vuex";

export default {
  name: "Manual-Events-Create",
  props: {
    msg: String,
    postData: null,
    preData: null,
    isTripCheck: {
      type: Boolean,
      default: false,
    },
  },
  components: { Spinner, StyledButton },
  data() {
    return {
      hasEmptyFields: false,
      params: {},
      manualEvent: null,
      engineRegion: null,
      isManualDriver: false,
      loading: false,
      values: [],
      errorMessage: "",
      schema: [],
      businessId: null,
      dateFormat: "YYYY-MM-DD",
      disabledPrevBtn: false,
      disabledNextBtn: false,
      dateRange: [],
      stepsData: [],
    };
  },
  computed: {
    manualEventData: {
      get() {
        const vehiclePlate =
          typeof this.params.selectedVehicle == "string"
            ? this.params.selectedVehicle
            : this.params.selectedVehicle?.vehiclePlate;
        return {
          selectedDriver: this.params.selectedDriver?.driver?.driverName,
          selectedPlan: this.params.selectedPlan,
          selectedVehicle: vehiclePlate,
          selectedDate:
            this.dateRange[0] == this.dateRange[1]
              ? this.dateRange[0]
              : `${this.dateRange[0]} to ${this.dateRange[1]}`,
          selectedTimezone: this.params.selectedTimezone,
        };
      },
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.businessId = me?.business?._id;
    if (me.role.name == "driver") this.businessId = me[me.role.name].entityId;
    let preData = this.preData;

    if (!_.isEmpty(this.postData)) {
      this.stepsData = this.postData;
      preData = this.stepsData.step2Data;
    }

    if (!_.isEmpty(preData)) {
      if (preData?.isManualDriver) {
        this.isManualDriver = this.isTripCheck ? true : preData.isManualDriver;
      }

      if (preData?.stepsData) {
        this.stepsData = preData.stepsData;
      }

      if (preData?.eventDetails) {
        this.params = preData.eventDetails;
        this.engineRegion = this.params.selectedRegion;

        // Setup manual event styles
        let logmasterManualEvents = null;
        if (this.engineRegion == "WAHVA") {
          logmasterManualEvents = new LogmasterWahvaManualEvents();
        } else {
          logmasterManualEvents = new LogmasterManualEvents();
        }
        $("head").append(
          `<style type="text/css">${logmasterManualEvents.getStyles()}</style>`
        );

        this.loading = true;
        this.dateRange = this.params.selectedDateRange;
        const startDate = moment(this.dateRange[0], this.dateFormat);
        const endDate = moment(this.dateRange[1], this.dateFormat);

        // Loop the date range first to dynamically add the dom elements
        for (
          var m = startDate;
          m.diff(endDate, "days") <= 0;
          m.add(1, "days")
        ) {
          const currentDate = m.format(this.dateFormat);

          this.values[currentDate] = {
            ...this.params,
            isActive: false,
            dateOfSheet: currentDate,
          };
        }

        // Set first value as active
        const values = Object.values(this.values);
        values[0].isActive = true;
        this.disabledPrevBtn = true;
        this.disabledNextBtn = values.length > 1 ? false : true;

        // After dom elements were created in a loop, initialize manual event to each of those element
        let index = 0;
        for (let value of Object.values(this.values)) {
          const currentDate = value.dateOfSheet;
          const requestData = {
            driverId: this.params.selectedDriver?.driverId,
            from: moment(currentDate, this.dateFormat).format(),
            to: moment(currentDate, this.dateFormat).format(),
          };

          await Promise.all([
            this.$store.dispatch(`business/getEventByDateRange`, requestData),
            this.$store.dispatch(`business/getPreviousEvent`, requestData),
            this.$store.dispatch(`business/getEventByDateRange`, {
              driverId: this.params.selectedDriver?.driverId,
              from: moment(currentDate, this.dateFormat)
                .subtract(56, "days")
                .format(),
              to: moment(currentDate, this.dateFormat).add(56, "days").format(),
            }),
          ]).then((data) => {
            // If there is a postData, use these data
            let eventPostData = !_.isEmpty(this.postData)
              ? this.stepsData.step3Data.eventDetails[index]
              : null;

            const previousEvent =
              eventPostData && eventPostData?.previousEvent
                ? eventPostData.previousEvent
                : data[1];

            const previousEventType =
              eventPostData || previousEvent ? previousEvent.type : "rest";

            value.rawEvents = data[0];
            value.events =
              eventPostData && eventPostData?.events
                ? eventPostData.events
                : data[0];
            value.currentEvents = value.events;
            value.eventsForBreach =
              eventPostData && eventPostData?.currentEvents
                ? value.currentEvents
                : data[2];
            value.dateOfSheet = currentDate;
            value.previousEvent = previousEvent;
            value.previousEventType = previousEventType;
            value.breaches = [];
            value.eventsForBreachDates = value?.eventsForBreach.map(
              (event) => event.startTimeWithTimezone
            );

            const vehiclePlate =
              typeof this.params.selectedVehicle == "string"
                ? this.params.selectedVehicle
                : this.params.selectedVehicle?.vehiclePlate;
            value.manualEventSettings = {
              isFutureEnabled: this.isTripCheck,
              isViewMode: false,
              isEditMode: false,
              isDateVisible: true,
              isManualDriver: this.isManualDriver,
              driverId: this.params.selectedDriver?.driverId,
              vehicleId: vehiclePlate,
              timezone: this.params.selectedTimezone,
              fatiguePlan: this.params.selectedPlan,
              locationData: LocationData,
              currentDate: currentDate,
              currentEvents: value.currentEvents,
              previousEventType: value.previousEventType,
              previousEvent: previousEvent,
            };

            value.manualEvent = $(`#childLhv${currentDate.replace(/-/g, "")}`);
            this.manualEventInstance(
              value.manualEvent,
              value.manualEventSettings
            );

            this.monitorEvents(null, true, currentDate);
            index++;
          });

          this.loading = false;
        }
      }
    }
  },
  methods: {
    manualEventInstance(elObject, method) {
      return this.engineRegion == "WAHVA"
        ? elObject.WahvaManualEvent(method)
        : elObject.ManualEvent(method);
    },
    onPrevious() {
      this.$emit("backStep", this.stepsData);
    },
    checkEventData(dateToCheck) {
      if (dateToCheck) {
        const currentManualEvent = this.values[dateToCheck];
        const currentDateEvents = this.manualEventInstance(
          currentManualEvent.manualEvent,
          "getEvents"
        );
        return currentDateEvents.some(
          (event) =>
            _.isEmpty(event["odometer"]) ||
            _.isEmpty(event["location"]["formatted_address"])
        );
      } else {
        this.hasEmptyFields = Object.values(this.values).some((value) => {
          const events = this.manualEventInstance(
            value.manualEvent,
            "getEvents"
          );
          this.values[value.dateOfSheet].events = events;
          return events.some(
            (event) =>
              _.isEmpty(event["odometer"]) ||
              _.isEmpty(event["location"]["formatted_address"])
          );
        });

        return this.hasEmptyFields;
      }
    },
    async onSubmit() {
      this.hasEmptyFields = this.checkEventData();

      if (!this.hasEmptyFields || this.isManualDriver) {
        let eventDetails = _.cloneDeep(Object.values(this.values));
        eventDetails.forEach((details) => {
          details.events.forEach((event) => {
            if (event.eventId) {
              const rawEvent = details.rawEvents.find(
                (rawEvent) => rawEvent.eventId == event.eventId
              );

              if (rawEvent && this.isManualDriver) {
                const {
                  startTimeWithTimezone,
                  location: { formatted_address },
                  odometer,
                  comment,
                } = rawEvent;

                if (
                  startTimeWithTimezone != event.startTimeWithTimezone ||
                  formatted_address != event.location.formatted_address ||
                  odometer != event.odometer ||
                  comment != event.comment
                ) {
                  event.eventId = rawEvent.eventId;
                  this.updateEvent(details, event);
                }
              }
            }
          });
        });

        const step3Data = { eventDetails, engineRegion: this.engineRegion };
        this.stepsData = { ...this.stepsData, step3Data };
        this.$emit("nextStep", { ...step3Data, stepsData: this.stepsData });
      }
    },
    updateEvent(details, event) {
      let updatedEvents = details["updatedEvents"] || [];
      let eventIndex = _.findIndex(updatedEvents, { eventId: event.eventId });
      if (eventIndex !== -1) {
        updatedEvents[eventIndex] = event;
      } else {
        updatedEvents.push(event);
      }
      details["updatedEvents"] = updatedEvents;
    },
    monitorEvents(evt, forceDisplay = false, currentDate = null) {
      if ($(evt?.target).attr("class") == "breach-element") return;
      if (
        (evt &&
          $(evt?.target).attr("class") &&
          !$(evt?.target).attr("class").includes("breach")) ||
        forceDisplay ||
        currentDate
      ) {
        // Loop through events and check for new ones
        if (this.values[currentDate]?.manualEvent) {
          let eventsForBreach = _.cloneDeep(
            this.values[currentDate].eventsForBreach
          );
          this.manualEventInstance(
            this.values[currentDate].manualEvent,
            "getEvents"
          ).forEach((event) => {
            if (
              this.values[currentDate]?.currentEvents.filter(
                (x) => x.startTimeWithTimezone == event.startTimeWithTimezone
              ).length == 0
            ) {
              this.values[currentDate].currentEvents.push(event);
            }
            if (
              !this.values[currentDate]?.eventsForBreachDates.includes(
                event.startTimeWithTimezone
              )
            ) {
              eventsForBreach.push(event);
            }
          });
        }
      }
    },
    formatDate(date) {
      return moment(date, this.dateFormat).format("MMMM DD, YYYY");
    },
    isDateNotBefore(date) {
      return moment(date, this.dateFormat).isBefore(this.dateRange[0]);
    },
    isDateNotAfter(date) {
      return moment(date, this.dateFormat).isAfter(this.dateRange[1]);
    },
    isPrevDisable(dateValue) {
      return this.isDateNotBefore(
        moment(dateValue, this.dateFormat)
          .subtract(1, "days")
          .format(this.dateFormat)
      );
    },
    isNextDisable(dateValue) {
      return (
        this.isDateNotAfter(
          moment(dateValue, this.dateFormat)
            .add(1, "days")
            .format(this.dateFormat)
        ) ||
        moment().format(this.dateFormat) ==
          moment(dateValue, this.dateFormat).format(this.dateFormat)
      );
    },

    setNavigationButton(date) {
      this.disabledPrevBtn = this.isPrevDisable(date) ? true : false;
      this.disabledNextBtn = this.isNextDisable(date) ? true : false;
    },
    goToDate(isPrevDate = false) {
      const values = Object.values(this.values);
      // Find the currently active manual event element
      const currentActive = values.find((x) => x.isActive === true);

      // Check if data validation for active element's date passes or if it's a manual driver
      if (
        !this.checkEventData(currentActive.dateOfSheet) ||
        this.isManualDriver
      ) {
        const newDate = moment(currentActive.dateOfSheet, this.dateFormat).add(
          isPrevDate ? -1 : 1,
          "days"
        );
        const activeDate = newDate.format(this.dateFormat);

        // Before showing the next day manual event, do some checking
        if (_.last(values)["dateOfSheet"] != currentActive.dateOfSheet) {
          // Loop from the current active date to the last date and check if there are any events
          const startDate = moment(currentActive.dateOfSheet, this.dateFormat);
          const endDate = moment(
            _.last(values)["dateOfSheet"],
            this.dateFormat
          );

          let index = 0;
          let currentDate = startDate.clone();
          let currentDateString = currentDate.format(this.dateFormat);
          let currentPreviousEvent = currentActive.previousEvent;

          while (currentDate.isSameOrBefore(endDate)) {
            // Skip the first date when reloading the manual event
            if (
              index > 0 &&
              this.values[currentDateString].previousEventType !=
                currentPreviousEvent.type
            ) {
              // Update next date manual event previousEvent and previousEventType
              this.updateManualEventSettings(
                currentDateString,
                currentPreviousEvent
              );

              // Reload next date manual event
              this.reloadManualEvent(currentDateString);
            }

            // If the current looped day has events, get the last event and update the previousEvent
            if (this.values[currentDateString].currentEvents.length > 0) {
              currentPreviousEvent = this.getLatestEvent(currentDateString);
            }

            index++;
            currentDate.add(1, "day");
            currentDateString = currentDate.format(this.dateFormat);
          }
        }

        // Update active state and toggle visibility of manual event dates
        currentActive.isActive = false;
        this.values[activeDate].isActive = true;
        $(`.manual-event-date-${currentActive.dateOfSheet}`).hide();
        $(`.manual-event-date-${this.values[activeDate].dateOfSheet}`).show();
        this.setNavigationButton(this.values[activeDate].dateOfSheet);
      }
    },
    updateManualEventSettings(date, previousEvent) {
      this.values[date].previousEventType = previousEvent.type;
      this.values[date].previousEvent = previousEvent;
      this.values[date].manualEventSettings.previousEventType =
        previousEvent.type;
      this.values[date].manualEventSettings.previousEvent = previousEvent;
    },
    reloadManualEvent(date) {
      this.manualEventInstance(this.values[date].manualEvent, "destroy");
      this.values[date].manualEvent = $(`#childLhv${date.replace(/-/g, "")}`);
      this.manualEventInstance(
        this.values[date].manualEvent,
        this.values[date].manualEventSettings
      );
    },
    getLatestEvent(date) {
      let clonedCurrentEvents = _.cloneDeep(this.values[date].currentEvents);
      clonedCurrentEvents = _.orderBy(
        clonedCurrentEvents,
        ["startTimeWithTimezone"],
        ["desc"]
      );
      return _.first(clonedCurrentEvents);
    },
  },
};
</script>

<style lang="scss" scoped>
.manual-events::v-deep {
  .timetable-container {
    @apply border-2 border-gray-300 rounded-b-none rounded-r-none;

    .manual-event-date {
      @apply border-r-0 rounded-none;
    }
    .timetable-header_work,
    .timetable-header_rest {
      @apply border-r-0;
    }

    #events-table .g-bg-blue-200 {
      @apply border-gray-300;
    }

    .ruler-text .time-block:last-child,
    .ruler-text .time-block:first-child span {
      top: 2px !important;
    }
  }
}
</style>
