<template>
  <div class="page-container px-2 pt-3">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-400">Loading data...</span>
      </div>
    </div>
    <div v-else>
      <label class="block mb-2 text-md font-medium text-gray-900"
        >Select driver trips to import</label
      >
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th
                scope="col"
                class="p-4"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                    @change="selectAll()"
                  />
                  <label class="sr-only">checkbox</label>
                </div>
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Driver Email
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Date
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Pick Up
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Destination
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Mass Limit
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Material
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Gross Weight
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Net Weight
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Drive Vehicle
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(driverTrip, index) in driverTrips"
              :key="index"
              :data-import-id="index"
              :ref="getRowRef(index)"
              :class="{ 'hover:bg-red-50': driverTrip.hasFieldError }"
              class="bg-white hover:bg-gray-50 relative"
            >
              <td class="p-4 w-4">
                <div class="flex items-center">
                  <input
                    v-model="driverTrip.checked"
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                    @change="onSelect($event)"
                    :disabled="driverTrip.hasFieldError"
                  />

                  <button
                    v-if="!driverTrip.isEditting"
                    class="ml-2"
                    @click="onEditDriverTrip(index)"
                  >
                    <font-awesome-icon icon="edit" />
                  </button>
                  <button
                    v-if="driverTrip.isEditting"
                    class="ml-2"
                    @click="onSaveDriverTrip(index)"
                  >
                    <font-awesome-icon class="text-green-700" icon="check" />
                  </button>
                </div>
              </td>

              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput type="text" v-model="driverTrip.driverEmail" />
              </td>
              <td v-else class="py-4 px-6">{{ driverTrip.driverEmail }}</td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput type="date" v-model="driverTrip.loadTime" />
              </td>
              <td v-else class="py-4 px-6">
                {{ formatDate(driverTrip.loadTime) }}
              </td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput type="text" v-model="driverTrip.location" />
              </td>
              <td v-else class="py-4 px-6">{{ driverTrip.location }}</td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput type="text" v-model="driverTrip.destination" />
              </td>
              <td v-else class="py-4 px-6">{{ driverTrip.destination }}</td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput
                  type="select"
                  :options="massLimitOptions"
                  v-model="driverTrip.massLimit"
                />
              </td>
              <td v-else class="py-4 px-6">
                {{ driverTrip.massLimit.toUpperCase() }}
              </td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput
                  type="number"
                  v-model="driverTrip.loadDescription"
                />
              </td>
              <td v-else class="py-4 px-6">{{ driverTrip.loadDescription }}</td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput
                  type="number"
                  v-model="driverTrip.grossWeight"
                />
              </td>
              <td v-else class="py-4 px-6">{{ driverTrip.grossWeight }}</td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput type="number" v-model="driverTrip.netWeight" />
              </td>
              <td v-else class="py-4 px-6">{{ driverTrip.netWeight }}</td>
              <td v-if="driverTrip.isEditting" class="pt-4 px-2">
                <FormulateInput
                  type="text"
                  v-model="driverTrip.driveVehiclePlate"
                />
              </td>
              <td v-else class="py-4 px-6">
                {{ driverTrip.driveVehiclePlate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <FormulateInput
        type="submit"
        :label="!isAnySelected ? 'Select a record' : 'Next'"
        input-class="btn btn-primary w-full mt-5"
        :disabled="loading || !isAnySelected"
        @click="nextPage()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "@/components/commons/ui/Spinner";
import moment from "moment";
import { formatDate } from "@/_helper";

export default {
  name: "Select-Driver-Trip",
  components: { Spinner },
  props: {
    preData: null,
  },
  data() {
    return {
      loading: false,
      driverTrips: [],
      values: null,
      isSelectAll: false,
      isAnySelected: false,
      selectedIndex: null,
      massLimitOptions: [
        {
          label: "CML",
          value: "cml",
        },
        {
          label: "GML",
          value: "gml",
        },
        {
          label: "HML",
          value: "hml",
        },
      ],
    };
  },
  created() {
    this.driverTrips =
      this.preData &&
      this.preData.driverTrips &&
      this.preData.driverTrips.length
        ? this.preData.driverTrips
        : [];
    this.validateDriverTrips();
  },
  methods: {
    getRowRef(index) {
      return "ref" + index;
    },
    validateDriverTrips() {
      if (!this.driverTrips.length) {
        return;
      }

      this.driverTrips.forEach((driverTrip, index) => {
        const { massLimit, loadTime, grossWeight, netWeight } = driverTrip;
        const invalidFields = [];
        if (!["HML", "CML", "GML"].includes(massLimit.toUpperCase())) {
          this.driverTrips[index].massLimit = null;
          invalidFields.push("massLimit");
        } else {
          // Always store lower case value of massLimit
          this.driverTrips[index].massLimit = massLimit.toLowerCase();
        }

        if (!moment(loadTime).isValid()) {
          this.driverTrips[index].loadTime = null;
          invalidFields.push("loadTime");
        }

        if (isNaN(grossWeight)) {
          this.driverTrips[index].grossWeight = 0;
          invalidFields.push("grossWeight");
        }

        if (isNaN(netWeight)) {
          this.driverTrips[index].netWeight = 0;
          invalidFields.push("netWeight");
        }

        this.driverTrips[index].hasFieldError = invalidFields.length > 0;
        this.driverTrips[index].invalidFields = invalidFields;
      });

      return;
    },
    validateSingleDriverTrip(index) {
      if (!this.driverTrips[index]) {
        return;
      }

      const { massLimit, loadTime, grossWeight, netWeight } =
        this.driverTrips[index];

      const invalidFields = [];
      if (!["HML", "CML", "GML"].includes(massLimit.toUpperCase())) {
        this.driverTrips[index].massLimit = null;
        invalidFields.push("massLimit");
      } else {
        // Always store lower case value of massLimit
        this.driverTrips[index].massLimit = massLimit.toLowerCase();
      }

      if (!moment(loadTime).isValid()) {
        this.driverTrips[index].loadTime = null;
        invalidFields.push("loadTime");
      }

      if (isNaN(grossWeight)) {
        this.driverTrips[index].grossWeight = 0;
        invalidFields.push("grossWeight");
      }

      if (isNaN(netWeight)) {
        this.driverTrips[index].netWeight = 0;
        invalidFields.push("netWeight");
      }

      this.driverTrips[index].hasFieldError = invalidFields.length > 0;
      this.driverTrips[index].invalidFields = invalidFields;

      return;
    },
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.isAnySelected = this.isSelectAll === true;
      this.driverTrips.forEach((driverTrip, index) => {
        if (!driverTrip.hasFieldError) {
          this.$set(
            this.driverTrips[index],
            "checked",
            this.isSelectAll ? true : false
          );
        }
      });
    },
    nextPage() {
      const selectedDriverTrips = this.driverTrips.filter(
        (driverTrip) => driverTrip.checked
      );
      this.$emit("nextStep", {
        driverTrips: this.driverTrips,
        selectedDriverTrips,
      });
    },
    onEditDriverTrip(index) {
      this.$set(this.driverTrips[index], "isEditting", true);
    },
    onSaveDriverTrip(index) {
      this.$set(this.driverTrips[index], "isEditting", false);
      this.validateSingleDriverTrip(index);
    },
    formatDate(date) {
      return formatDate(date, "DD/MM/YYYY", true);
    },
    onSelect(event) {
      const isAnySelected = this.driverTrips.some(
        (driverTrip) => driverTrip.checked
      );
      this.isAnySelected = event.target.value === true || isAnySelected;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
