<template>
  <div>
    <div v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-else>
      <FormulateForm
        v-model="payload"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="driver-select"
          label="Assigned Driver"
          style="margin-bottom: 0px"
          class="content-center pb-4"
          :getOptionKey="(option) => option.driverId"
          :getOptionLabel="(option) => option.driver.driverName"
          :filterable="false"
          :clearable="false"
          @input="selectDriver($event)"
          :help="`Current Driver (${payload.driverName})`"
        />
        <FormulateInput
          type="vehicle-select"
          label="Drive Vehicle"
          style="margin-bottom: 0px"
          class="content-center pb-4"
          :getOptionKey="(option) => option._id"
          :getOptionLabel="(option) => option.vehiclePlate"
          :filterable="false"
          :clearable="false"
          :isDriveVehicle="true"
          @input="selectDriveVehicle($event)"
          :help="`Current Vehicle (${payload.vehiclePlate})`"
        />
        <div class="flex flex-row justify-between mt-5">
          <label class="font-bold"
            >Add/Remove Trailer Vehicles ({{
              payload.numberOfTrailerVehicles
            }})</label
          >
          <div>
            <button
              class="mr-1 btn btn-primary"
              @click.prevent="addRow()"
              :style="{ backgroundColor: theme.secondaryColor }"
              :disabled="payload.numberOfTrailerVehicles > 7"
            >
              <font-awesome-icon icon="plus" />
            </button>
            <button
              class="ml-1 btn btn-primary"
              @click.prevent="deleteRow()"
              :style="{ backgroundColor: theme.secondaryColor }"
              :disabled="payload.numberOfTrailerVehicles <= 0"
            >
              <font-awesome-icon icon="minus" />
            </button>
          </div>
        </div>
        <div
          v-for="(element, index) in Array.from({
            length: payload.numberOfTrailerVehicles,
          })"
          :key="index"
        >
          <FormulateInput
            v-model="payload.trailers[index].trailerPlate"
            class="text-s"
            type="text"
            :label="`Trailer Vehicle #${index + 1}`"
            required
            :name="`trailer_vehicle:${index}`"
          />
          <div class="grid grid-cols-4">
            <FormulateInput
              v-for="(element2, index2) in Array.from({
                length: 4,
              })"
              :key="index2"
              v-model="payload.trailers[index].axles[index2]"
              class="text-s"
              type="number"
              min="0"
              step="any"
              :label="`Trailer Axle#${index2 + 1}`"
              :name="`trailer_axle:${index}:${index2}`"
            />
          </div>
        </div>
        <FormulateInput
          v-model="payload.loadTime"
          class="text-s"
          type="datetime-local"
          label="Pickup Time"
          required
          name="loadTime"
        />
        <FormulateInput
          v-model="payload.location"
          class="text-s"
          type="text"
          label="Pickup"
          required
          name="location"
        />
        <FormulateInput
          v-model="payload.destinationDate"
          class="text-s"
          type="datetime-local"
          label="Destination Time"
          required
          name="destinationDate"
        />
        <FormulateInput
          v-model="payload.destination"
          class="text-s"
          type="text"
          label="Destination"
          required
          name="destination"
        />
        <FormulateInput
          v-model="payload.massLimit"
          class="text-s"
          type="select"
          label="Mass Limit"
          :options="massLimitOptions"
          required
          name="massLimit"
        />
        <FormulateInput
          v-model="payload.loadDescription"
          class="text-s"
          type="text"
          label="Material"
          name="loadDescription"
        />
        <FormulateInput
          v-model="payload.weightSelector"
          class="text-s"
          type="select"
          label="Weight Unit"
          :options="weightUnitOptions"
          name="weightSelector"
        />
        <FormulateInput
          v-model="payload.steer"
          class="text-s"
          type="number"
          step="any"
          label="Steer Weight (Optional)"
          name="steer"
          min="0"
        />
        <FormulateInput
          v-model="payload.drive"
          class="text-s"
          type="number"
          step="any"
          label="Drive Weight (Optional)"
          name="drive"
          min="0"
        />
        <FormulateInput
          v-model="payload.grossWeight"
          class="text-s"
          type="number"
          step="any"
          label="Gross Weight (Optional)"
          name="grossWeight"
          min="0"
        />
        <FormulateInput
          v-model="payload.netWeight"
          class="text-s"
          type="number"
          step="any"
          label="Net Weight (Optional)"
          name="netWeight"
          min="0"
        />
        <FormulateInput
          v-model="payload.docketNumber"
          class="text-s"
          type="text"
          label="Docket Number"
          name="docketNumber"
        />
        <FormulateInput
          type="file"
          name="images"
          label="Docket Images"
          multiple
          max="5"
          accept="image/*"
          validation="mime:image/jpeg,image/png,image/gif"
          v-model="payload.images"
          label-class="formulate-input-label formulate-input-label--before"
          outer-class="formulate-input"
          wrapper-class="formulate-input-wrapper"
          upload-area-class="formulate-input-upload-area"
          upload-area-mask-class="formulate-input-upload-area-mask"
        />

        <FormulateInput
          v-model="payload.status"
          class="text-s"
          type="select"
          :options="statusOptions"
          label="Status"
          required
          name="status"
        />
        <FormulateInput
          class="text-s"
          type="submit"
          label="Submit"
          input-class="w-full btn btn-primary"
          :disabled="hasErrors || isLoading"
        />
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
export default {
  name: "edit-driver-trips",
  components: {},
  props: {
    driverTripId: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      driverTrip: {},
      payload: {},
      error: false,
      errorMessage: "",
      statusOptions: [
        {
          label: "Finalised",
          value: "finalised",
        },
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Pending",
          value: "pending",
        },
        {
          label: "Cancelled",
          value: "cancelled",
        },
      ],
      massLimitOptions: [
        {
          label: "Concessional Mass Limits (CML)",
          value: "cml",
        },
        {
          label: "General Mass Limits (GML)",
          value: "gml",
        },
        {
          label: "Higher Mass Limits (HML)",
          value: "hml",
        },
      ],
      weightUnitOptions: [
        {
          label: "Kilograms",
          value: "kg",
        },
        {
          label: "Tonnes",
          value: "tonnes",
        },
      ],
    };
  },

  created() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(
          "driver/getOneTrip",
          this.driverTripId
        );

        this.driverTrip = {
          ...response,
          numberOfTrailerVehicles: response.trailers.length,
          destinationDate:
            response.destinationDate &&
            formatDate(
              response.destinationDate,
              "YYYY-MM-DDTHH:mm",
              true,
              false
            ),
          loadTime:
            response.loadTime &&
            formatDate(response.loadTime, "YYYY-MM-DDTHH:mm", true, false),
        };

        this.payload = Object.assign({}, this.driverTrip);
      } catch (error) {
        this.$emit("toast", "error", "Something went wrong");
      }

      this.isLoading = false;
    },
    async onSubmit() {
      if (
        this.payload.images &&
        this.payload.images.fileList &&
        this.payload.images.fileList.length
      ) {
        await this.handleFileUpload();
      }
      this.isLoading = true;
      const fields = Object.keys(this.payload);
      const trailerVehicles = fields.filter((field) =>
        field.includes("trailer_vehicle")
      );

      const trailerAxles = fields.filter((field) =>
        field.includes("trailer_axle")
      );

      const trailerPayload = [];
      for (const trailerVehicle of trailerVehicles) {
        const index = trailerVehicle.split(":")[1];
        trailerPayload[index] = {
          axles: [],
          trailerPlate: this.payload[trailerVehicle],
        };
        const targetTrailerAxles = trailerAxles.filter((axle) =>
          axle.includes(`trailer_axle:${index}:`)
        );
        for (const trailerAxle of targetTrailerAxles) {
          if (this.payload[trailerAxle]) {
            const splitFields = trailerAxle.split(":");
            const trailerIndex = splitFields[1];
            const axleIndex = splitFields[2];
            if (trailerPayload[trailerIndex]?.axles) {
              trailerPayload[trailerIndex].axles[axleIndex] =
                this.payload[trailerAxle];
            } else {
              trailerPayload[trailerIndex] = {
                axles: [this.payload[trailerAxle]],
                trailerPlate: this.payload[trailerVehicle],
              };
            }
          }
        }
      }
      this.payload = {
        id: this.driverTrip.id,
        status: this.payload.status,
        vehicleId: this.payload.vehicleId,
        driverId: this.payload.driverId,
        loadTime: this.payload.loadTime,
        data: {
          location: this.payload.location,
          destinationDate: this.payload.destinationDate,
          destination: this.payload.destination,
          massLimit: this.payload.massLimit,
          steer: Number(this.payload.steer),
          drive: Number(this.payload.drive),
          grossWeight: Number(this.payload.grossWeight),
          netWeight: Number(this.payload.netWeight),
          weightSelector: this.payload.weightSelector,
          loadDescription: this.payload.loadDescription,
          docketNumber: this.payload.docketNumber,
          trailers: trailerPayload,
          docketPhotos: this.payload.docketPhotos,
        },
      };

      try {
        await this.$store.dispatch("driver/updateTrip", this.payload);
        this.$emit("toast", "success", "Driver trip successfully updated.");
      } catch (error) {
        this.$emit("toast", "error", "Something went wrong");
      }
      this.isLoading = false;
      this.$emit("closeModal");
    },
    addRow() {
      this.payload.numberOfTrailerVehicles += 1;
      this.payload.trailers.push({ trailerPlate: "", axles: [] });
    },

    deleteRow() {
      this.payload.numberOfTrailerVehicles -= 1;
      this.payload.trailers.pop();
    },
    selectDriver(data) {
      this.payload.driverId = data.driverId;
    },
    selectDriveVehicle(data) {
      this.payload.vehicleId = data._id;
    },

    async handleFileUpload() {
      try {
        const promises = [];
        this.payload.images.fileList.forEach((file) => {
          promises.push(this.$store.dispatch("file/fileUpload", file));
        });
        const responses = await Promise.all(promises);
        this.payload.docketPhotos = responses;
      } catch (error) {
        console.log(error);
        this.$emit("toast", "error", "File upload failed");
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
