<template>
  <div v-if="!loading && driverId">
    <FormulateForm
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import schema from "./new-driver-abort-schema";
//import _ from 'lodash';
import { mapGetters } from "vuex";

export default {
  name: "New-Driver-Abort",
  props: {
    msg: String,
    driverId: String,
  },
  data() {
    this.$emit("schema", schema);
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loading = false;
  },
  methods: {
    updateSchema: function (schema) {
      this.schema = schema;
    },
    async onSubmit() {
      this.loading = true;
      let abortData = JSON.parse(JSON.stringify(this.values));
      if (abortData) {
        if (this.driverId) {
          let driver;
          if (abortData.email)
            driver = await this.$store.dispatch(
              `driver/getDriver`,
              this.driverId
            );
          if (driver && driver.emailAddress == abortData.email) {
            const aborted = await this.$store.dispatch(
              `driver/abortDriver`,
              this.driverId
            );
            if (aborted && aborted._id) {
              window.location.href = "/?message=Account successfully canceled";
            }
          } else {
            window.location.href = "/404?message=Invalid request";
          }
        }
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
