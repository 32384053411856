<template>
    <div class="mb-16">
        <div class="flex flex-col justify-center items-center w-full mb-5 relative">
            <div class="w-full" v-if="loading">
                <div class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center">
                    <Spinner class="mx-auto mb-2"></Spinner>
                    <span class="font-semibold text-gray-400">Loading drivers...</span>
                </div>
            </div>
            <label class="flex flex-col justify-center items-center w-full h-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100">
                <div class="flex flex-col justify-center items-center pt-10 pb-10">
                    <font-awesome-icon
                        icon="upload"
                        class="mb-3 w-14 h-14 text-gray-400 text-6xl"
                    />
                    <p class="mb-2 text-lg text-gray-500"><span class="font-semibold">Click to upload</span> or drag and drop</p>
                    <p class="text-xs text-gray-500">CSV FILE ONLY</p>
                </div>
                <FormulateInput
                    type="file"
                    name="file"
                    accept=".csv"
                    class="hidden"
                    label="Select CSV file"
                    @change="onFileChange"
                />
            </label>
            <a href="https://logmaster.com.au/helpcentre/bulk-upload-procedure" class="text-orange-200 text-sm mt-5 hover:underline" target="_blank">CLICK for instructions</a>
            <a :href="isNonEwdImport?isNonEwdImport:ewdCsvTemplate" class="text-orange-200 text-sm mt-5 hover:underline" target="_blank">Download CSV Template</a>
            
        </div>
    </div>
</template>

<script>
import jCsv from 'jquery-csv';
import { STATES, TIMEZONES } from '@/_helper/constants';
import Spinner from '@/components/commons/ui/Spinner';

export default {
    name: "Select-File",
    components: { Spinner },
    props: {
        isNonEwdImport: Boolean
    },
    data() {
        return {
            ewdCsvTemplate: process.env.VUE_APP_EWD_CSV_URL,
            nonEwdCsvTemplate: process.env.VUE_APP_NON_EWD_CSV_URL,
            loading: false,
            states: STATES,
            timezones: TIMEZONES,
            drivers: [],
        };
    },
    methods: {
        onFileChange(e) {
            this.loading = true;
            const reader = new FileReader()
            reader.readAsText(e.target.files[0]);
            reader.onload = (event) => {
                const csv = event.target.result;
                const data = jCsv.toArrays(csv);
                
                data.slice(1).forEach(value => {
                    const driver = !this.isNonEwdImport ? {
                        emailAddress: value[0].toLowerCase(),
                        driverName: value[1],
                        driverDateOfBirth: value[2],
                        driversLicenseNumber: value[3],
                        driversLicenseExpiry: value[4],
                        driversLicenseState: value[5],
                        driversBaseLocation: value[6],
                        driverRecordKeeperAddress: value[7],
                        driversBaseTimeZone: value[8],
                        region: value[9],
                        driversBFMNumber: value[10],
                        status: 'pending',
                        checked: false,
                    } : {
                        driverName: value[0].toLowerCase(),
                        driverDateOfBirth: value[1],
                        driversLicenseNumber: value[2],
                        driversLicenseExpiry: value[3],
                        driversLicenseState: value[4],
                        driversBaseFatiguePlan: value[5],
                        driversBaseTimeZone: value[6],
                        region: value[7],
                        status: 'pending',
                        checked: false,
                    }
                    this.drivers.push(driver)
                });

                this.loading = false;
                this.$emit("nextStep", { drivers: this.drivers });
            }
        }
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
