<template>
  <div class="w-full overflow-y-auto border border-gray-200 rounded">
    <table class="min-w-full">
      <thead class="bg-gray-50">
        <tr class="text-xs tracking-wider text-gray-700 uppercase">
          <th scope="col" class="px-5 py-3 text-left">Breach Time</th>
          <th scope="col" class="px-5 py-3 text-left">Time of Breach</th>
          <th scope="col" class="px-5 py-3 text-left">Level</th>
          <th scope="col" class="px-5 py-3 text-left">Option</th>
          <th scope="col" class="px-5 py-3 text-left">Period</th>
          <th scope="col" class="px-5 py-3 text-left">Work/Rest</th>
          <th scope="col" class="px-5 py-3 text-left">Counting Point Start</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-if="breaches.length == 0"
          class="text-xs font-medium text-gray-500 bg-white border-b"
        >
          <td colspan="10" class="px-5 py-3 text-sm text-center">
            No breaches this day
          </td>
        </tr>
        <tr
          v-for="(breach, index) in breaches"
          :key="index"
          class="text-xs font-medium text-gray-500 bg-white border-b"
        >
          <td class="px-5 py-3 capitalize text-black-primary">
            {{ breach.breachTime || "" }}
          </td>
          <td class="px-5 py-3 capitalize text-black-primary">
            {{ breach.time }}
          </td>
          <td class="px-5 py-3">
            {{ capitalizeLevel(breach.level) }}
          </td>
          <td class="px-5 py-3">
            {{ breach.option }}
          </td>
          <td class="px-5 py-3">
            {{ breach.period }}
          </td>
          <td class="px-5 py-3">
            {{ breach.type }}
          </td>
          <td class="px-5 py-3">
            {{ breach.startPoint }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Breach",
  props: {
    data: Array,
  },
  data() {
    return {
      breaches: [],
    };
  },
  mounted() {
    this.breaches = this.data.reverse();
  },
  methods: {
    capitalizeLevel(level) {
      return _.startCase(level.replace("-", " "));
    },
  },
};
</script>
