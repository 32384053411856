<template>
  <div class="page-container px-2 pt-3">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-400">Loading data...</span>
      </div>
    </div>
    <div v-else>
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Driver Email
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Date
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Pick Up
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Destination
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Mass Limit
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Gross Weight
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Net Weight
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Drive Vehicle
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(driverTrip, index) in driverTrips"
              :key="index"
              :data-import-id="index"
              :ref="getRowRef(index)"
              class="bg-white hover:bg-gray-50 relative"
            >
              <td class="py-4 px-6">{{ driverTrip.driverEmail }}</td>
              <td class="py-4 px-6">
                {{ formatDate(driverTrip.loadTime) }}
              </td>
              <td class="py-4 px-6">{{ driverTrip.location }}</td>
              <td class="py-4 px-6">{{ driverTrip.destination }}</td>
              <td class="py-4 px-6">
                {{ driverTrip.massLimit.toUpperCase() }}
              </td>
              <td class="py-4 px-6">{{ driverTrip.grossWeight }}</td>
              <td class="py-4 px-6">{{ driverTrip.netWeight }}</td>
              <td class="py-4 px-6">
                {{ driverTrip.driveVehiclePlate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <FormulateInput
        type="submit"
        :label="submitting ? 'Importing' : 'Import'"
        input-class="btn btn-primary w-full mt-5"
        :disabled="submitting"
        @click="onSubmit()"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { formatDate } from "@/_helper";
import Spinner from "@/components/commons/ui/Spinner";

export default {
  name: "Import-Driver-Trip",
  components: { Spinner },
  props: {
    preData: null,
  },
  data() {
    return {
      submitting: false,
      loading: false,
      driverTrips: [],
    };
  },
  mounted() {
    if (!_.isEmpty(this.preData)) {
      this.driverTrips = this.preData.selectedDriverTrips;
    }
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "DD/MM/YYYY", true);
    },
    getRowRef(index) {
      return "ref" + index;
    },
    async onSubmit() {
      this.submitting = true;
      const parsedPayload = this.parseForSubmit();

      try {
        await this.$store.dispatch("driver/createTripBulk", parsedPayload);
        this.submitting = false;
        this.$emit("closeRefresh");
      } catch (error) {
        console.log("Error in bulk inserting driver trips: ", error);
        this.submitting = false;
      }
    },
    parseForSubmit() {
      const me = this.$store.getters[`account/me`];
      const businessId = me.businessId ? me.businessId : me.business?._id;
      return this.driverTrips.map((driverTrip) => {
        const {
          destination,
          driveVehiclePlate,
          driverEmail,
          grossWeight,
          loadTime,
          location,
          massLimit,
          netWeight,
          loadDescription,
        } = driverTrip;

        const trailers = [];

        Object.keys(driverTrip).forEach((key) => {
          if (key.includes("trailerVehicle") && driverTrip[key]) {
            trailers.push({
              axles: [],
              trailerPlate: driverTrip[key],
            });
          }
        });

        return {
          businessId,
          vehiclePlate: driveVehiclePlate,
          driverEmail,
          loadTime,
          data: {
            massLimit,
            grossWeight: Number(grossWeight),
            netWeight: Number(netWeight),
            destination,
            location,
            trailers,
            loadDescription,
          },
        };
      });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
