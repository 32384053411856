<template>
  <div>
    <div v-if="initializedTheme" class="flex flex-col h-screen">
      <div
        class="flex flex-1 overflow-hidden bg-gray-100 text-black-primary min-h-screen"
      >
        <aside
          v-if="isSideMenu && !isLoading"
          :class="[
            showSidebar ? 'relative -translate-x-0' : '-translate-x-full',
          ]"
          class="sidebar bg-gray-900 z-20 w-64 space-y-6 fixed inset-y-0 left-0 md:relative md:-translate-x-0 transform transition duration-200 ease-in-out"
        >
          <Sidebar></Sidebar>
        </aside>
        <main
          class="main flex flex-1 flex-col w-screen min-h-screen md:ml-0 transition-all duration-150 ease-in"
          :style="{ backgroundColor: theme.backgroundColor }"
        >
          <div class="flex">
            <MegaMenu v-if="!isSideMenu"></MegaMenu>
          </div>
          <div class="flex">
            <PortalHeader
              @show-sidebar="toggleSidebar"
              @fetchedProfile="isLoading = false"
            ></PortalHeader>
          </div>
          <div class="overflow-y-auto main-content-wrapper">
            <div
              v-if="!isLoading"
              class="main-content flex flex-1 w-full py-3 px-4 min-h-full"
            >
              <slot></slot>
            </div>
            <div
              v-else
              class="main-content flex flex-col justify-center items-center w-full h-screen py-3 px-4 -mt-20"
            >
              <ui-spinner class="mx-auto mb-2"></ui-spinner>
              <p class="font-bold" :style="{ color: theme.primaryColor }">
                Loading Page...
              </p>
            </div>
            <Footer v-if="!isSideMenu"></Footer>
          </div>
        </main>
      </div>
    </div>
    <div v-else class="flex w-full h-screen">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/commons/ui/Spinner";
import Sidebar from "../commons/sidebar/Sidebar.vue";
import PortalHeader from "../commons/PortalHeader.vue";
import MegaMenu from "../commons/sidebar/MegaMenu.vue";
import Footer from "../commons/Footer.vue";
import { GEOTAB_COOKIE } from "@/_helper/constants";
import { isEmpty } from "lodash";
import VueCookies from "vue-cookies";

export default {
  name: "PortalContainer",
  components: {
    Sidebar,
    PortalHeader,
    Spinner,
    Footer,
    MegaMenu,
  },
  data() {
    return {
      showSidebar: false,
      isLoading: true,
      file: null,
      accountDetails: "",
      isSideMenu: true,
    };
  },
  async mounted() {
    this.isLoading = true;
    this.accountDetails = await this.$store.getters["account/me"];

    if (!this.accountDetails?.updated) {
      this.$emit("dataLoading", "Loading user data, please wait...");
      await this.$store.dispatch(`account/updateMe`, this.accountDetails);
    }

    /*  GEOTAB
        TODO: Look for better way to handle this
    */
    const geotabCookie = await VueCookies.get(GEOTAB_COOKIE);
    const geotabView = !isEmpty(geotabCookie);
    this.$store.commit("account/setIsGeotabAccount", geotabView);

    /* THEME */
    let accountFetchTheme = this.accountDetails.uid;
    if (this.accountDetails.role.name === "driver") {
      accountFetchTheme = this.accountDetails.driver?.partnerUid;
    } else if (this.accountDetails.role.name === "business")
      accountFetchTheme = this.accountDetails.business?.parentUid;

    if (accountFetchTheme) {
      await this.$store.dispatch("settings/getUserSettings", accountFetchTheme);
    } else {
      await this.$store.dispatch("theme/getThemeListsById", "logmaster");
    }

    if (this.activeTheme) {
      this.$store.commit("theme/updateActiveTheme", this.activeTheme);
      this.$store.commit("theme/setInitializedTheme", true);
    }

    /* SIDE & TOP MENU */
    const me = this.$store.getters["account/me"];
    const isBusiness = me?.role?.name === "business";
    const isSideMenuCookie = this.$store.getters["account/getIsSideMenu"];
    if (geotabView) {
      this.isSideMenu = false;
    } else if (!isBusiness) {
      this.isSideMenu = true;
    } else {
      this.isSideMenu = isSideMenuCookie;
    }

    this.isLoading = false;
    this.$emit("dataLoading", null);
  },
  methods: {
    toggleSidebar(value) {
      this.showSidebar = value;
    },
  },
  computed: {
    theme() {
      return this.$store.getters["theme/getColorScheme"];
    },
    activeTheme() {
      return this.$store.getters["theme/getActiveTheme"];
    },
    initializedTheme() {
      return this.$store.getters["theme/getIsInitializedTheme"];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.sidebar {
  min-width: 16rem;
}
.main {
  width: calc(100vw - 16rem) !important;
}
.footer {
  width: calc(100vw - 16rem) !important;
  position: absolute;
  bottom: 0;
}

.main-content-wrapper {
  min-height: calc(100% - 64px);
}
</style>
