<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="fitnessDeclarationData"
      @onPageChange="onPageChange($event)"
      :has-multi-filter="true"
      @onMultiFilter="onMultiFilter"
    >
      <template #tableFilters>
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>

      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="
                    viewFitnessDeclaration(data.id, data.isDefault)
                  "
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'view'">
        <FitnessDeclarationDetailsView
          v-if="!isOldView"
          :activeFitnessDeclaration="activeFitnessDeclaration"
        />
        <FitnessDeclarationDetailsOldView
          v-else
          :activeFitnessDeclaration="activeFitnessDeclaration"
        />
      </div>
    </Modal>

  </div>
</template>

<script>

import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { Table, Modal, Dropdown } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";
import FitnessDeclarationDetailsView from "@/components/view/business/fitness-declaration";
import FitnessDeclarationDetailsOldView from "@/components/view/business/fitness-declaration/oldView";


export default {
  name: "Fitness-Declarations",
  components: {
    Table,
    Modal,
    Dropdown,
    StyledIconedLink,
    FitnessDeclarationDetailsView,
    FitnessDeclarationDetailsOldView,
  },
  data() {
    return {
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      isLoading: false,
      tableHeader: [
        {
          name: "Driver Name",
          type: "text",
          fieldName: "driver.driverName",
          isFilterable: true
        },
        {
          name: "Email Address",
          type: "text",
          fieldName: "driver.emailAddress",
          isFilterable: true
        },
        {
          name: "Licence",
          type: "text",
          fieldName: "driver.driversLicenseNumber",
          isFilterable: true
        },
        {
          name: "Result",
          type: "boolean",
          fieldName: "isPassed",
          isFilterable: true,
          filterOptions: {
            true: "Pass",
            false: "Fail",
          },
        },
        {
          name: "Date Submitted",
          type: "date",
          fieldName: "savedData.date",
          isFilterable: true
        },
      ],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      fitnessFormatedItems: [],
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      readFitness: false,
      hasRole: true,
      activeFitnessDeclaration: null,
      isOldView: false,
      loading: false,
      selectedFormId: '',
      multiFilter: {},
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async onMultiFilter(data) {
      this.multiFilter = {...data};
      await this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status !== "all") {
        query.filter.status = { $eq: this.filter.status };
      }

      const driverId = me.driver?._id;

      if(Object.keys(this.multiFilter).length > 0) {
        query.filter = {...this.multiFilter, hasMultiFilter: true}
      }

      let data = await this.$store.dispatch(
        `driver/getFitToDriveByDriverId`,
        { driverId, query }
      );

      if (data) {
        this.paginationSettings = formatPaginationSettings(
          new Object(data["metadata"])
        );

        this.fitnessFormatedItems = this.formatItem(
          _.orderBy(data.results, ["createdAt"], ["desc"])
        );
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },

    async viewFitnessDeclaration(id, isDefault) {
      let data;

      if (typeof isDefault == "boolean") {
        data = await this.$store.dispatch(`fittodrive/getFitToDriveById`, id);
        this.isOldView = false;
      } else {
        data = await this.$store.dispatch(
          `fittodrive/getOldFitToDriveById`,
          id
        );
        this.isOldView = true;
      }

      if (!_.isEmpty(data)) {
        this.activeFitnessDeclaration = data;
      }

      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Fitness Declaration Details";
    },

    parseFitnessDeclaration(data) {
      return data.map((fitnessDeclarationData) => [
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.name,
          itemType: "name",
          hasImage: false,
          isDefault: fitnessDeclarationData?.isDefault,
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.email,
          itemType: "string",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.license,
          itemType: "string",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.isPassed,
          itemType: "boolean",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.date,
          itemType: "string",
        },
      ]);
    },

    queryForKeywords(value) {
      this.searchKeyword = value;
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item._id,
            name: item?.driver?.driverName,
            email: item?.driver?.emailAddress,
            license: item?.driver?.driversLicenseNumber,
            phone: item?.driver?.supportPhone,
            isPassed: item?.isPassed ? "Pass" : "Fail",
            isDefault:
              typeof item?.isDefault == "boolean" ? item?.isDefault : null,
            date: formatDate(item?.savedData?.date),
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    debouncedSearchString() {
      this.init();
    },
  },

  computed: {
    fitnessDeclarationData() {
      if (this.fitnessFormatedItems) {
        return this.parseFitnessDeclaration(this.fitnessFormatedItems);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>

<style scoped lang="scss">
.send-datepicker::v-deep > div {
  @apply w-full
}
</style>
