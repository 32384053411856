import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Prestart {
  getPrestartByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/prestart/find-by-businessId/${businessId}${query}`)
  }

  getPrestartByDriverId(driverId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/prestart/find-by-driverId/${driverId}${query}`)
  }

  getPrestartByDateRange( data ){
    return API.ACCOUNTS.post(`/prestart/find-by-date-range`, data);
  }

  getPrestartById(id){
    return API.ACCOUNTS.get(`/prestart/${id}`);
  }

  getOldPrestartById(id){
    return API.ACCOUNTS.get(`/prestart/old/${id}`);
  }

  saveNote(payload){
    return API.ACCOUNTS.post(`/prestart/note/${payload.id}`, payload);
  }

}