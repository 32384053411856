<template>
  <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
    <div>
      <div v-if="!isUrlAvailable" class="flex flex-col items-center mx-4 my-8">
        <h3
          class="text-lg leading-6 font-medium text-gray-900 mb-4"
          id="modal-title"
        >
          No Link Generated
        </h3>
        <button
          class="btn btn-primary"
          @click.prevent="generateLink()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="submitLoading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Generating
            url...</span
          >
          <span v-if="!submitLoading">Generate Link</span>
        </button>
      </div>
      <div v-else class="flex flex-col items-center mx-4 my-8">
        <h3
          class="text-lg leading-6 font-medium text-gray-900 mb-4"
          id="modal-title"
        >
          {{ shareableUrl }}
        </h3>
        <div class="flex items-center space-x-2">
          <button
            class="btn btn-primary"
            @click.prevent="copyShareableLink()"
            :style="{ backgroundColor: theme.primaryColor }"
          >
            <span v-if="copyLink">Copied!</span>
            <span v-if="!copyLink">Copy Link</span>
          </button>
          <!-- <button
            class="btn btn-primary"
            @click.prevent="generateLink()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" />
              Generating url...</span
            >
            <span v-if="!submitLoading">Generate New Link</span>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Invite-Link",
  components: {},
  data() {
    return {
      submitLoading: false,
      copyLink: false,
      urlType: "",
      me: {},
    };
  },
  props: {
    isUrlAvailable: {
      type: Boolean,
      default: null,
    },
    shareableUrl: {
      type: String,
      default: "",
    },
    isSolo: {
      type: Boolean,
      default: null,
    },
  },
  async mounted() {
    if (this.isSolo == true) {
      this.urlType = "solo";
    } else {
      this.urlType = "business";
    }
    this.me = this.$store.getters[`account/me`];
  },

  methods: {
    async generateLink() {
      this.submitLoading = true;
      await this.$store
        .dispatch(`partner/generateShareableUrl`, {
          id: this.me.entity?._id,
          urlType: this.urlType,
          baseUrl: process.env.VUE_APP_URL,
        })
        .then((response) => {
          if (response) {
            setTimeout(() => {
              this.fetchUser();
            }, 4000);
          }
        })
        .catch((error) => {
          this.businessData = [];
          console.log(error);
        });
    },
    async fetchUser() {
      await this.$store.dispatch(`account/getMe`).then((response) => {
        if (response) {
          this.updateMe(response);
        }
      });
    },
    async updateMe(user) {
      if (user?.entity || user?.business || user?.driver) {
        this.$emit("fetchedProfile", user);
      } else {
        await this.$store.dispatch(`account/updateMe`, user).then(() => {
          this.submitLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Shareable url created successfully.",
          });
          this.me = this.$store.getters[`account/me`];
          if (this.isSolo == true) {
            this.shareableUrl = this.me?.entity?.soloUrl;
          } else {
            this.shareableUrl = this.me?.entity?.businessUrl;
          }

          if (this.shareableUrl) {
            this.isUrlAvailable = true;
          }
        });
      }
    },

    async copyShareableLink() {
      this.copyLink = true;
      navigator.clipboard.writeText(this.shareableUrl);
      setTimeout(() => {
        this.copyLink = false;
      }, 4000);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
