<template>
  <div class="">
    <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
      <div v-if="loading" class="text-center">
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
      <div v-if="!loading">
        <div class="mb-4 border-b-2 form border-neutral-100">
          <template v-for="(module, index) in contract.businessModules">
            <div :key="index">
              <div>
                <div class="mb-2 text-sm font-bold border-b-2 border-neutral">
                  {{ module.name }}
                </div>
                <div class="flex items-center justify-between">
                  <div class="flex items-center justify-start mb-2 space-x-2">
                    <FormulateInput
                      type="number"
                      :name="`${addedSeats[index].module}-seat`"
                      v-model="addedSeats[index].value"
                      input-class="w-full px-3 py-2 mt-1 mb-2 text-sm font-medium leading-none border border-gray-300 rounded shadow-sm outline-none focus:border-orange-primary focus:shadow border-box text-neutral-dark-500"
                      outer-class="z-50 w-1/4"
                      validation="required|number|min:0"
                      min="0"
                      error-behavior="live"
                      :disabled="
                        submitLoading || module.name.toLowerCase() === 'one api'
                      "
                    />
                    <div class="w-3/4 text-sm text-gray-800">
                      new seat @ ${{ getActualPrice(module) }}/{{
                        billPeriod
                      }}
                      each.<br /><span v-if="billPeriod == 'year'">
                        ${{ addedSeats[index]?.unitPrice?.toFixed(2) }} per seat
                        prorated to be billed now.</span
                      >
                    </div>
                  </div>
                  <div class="font-bold">
                    {{ getProratedSeatAmount(module, index) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <div
          v-if="proratedDates.remainingDays > 0"
          class="flex flex-col pb-4 mb-4 space-y-1 border-b-2 border-neutral-100"
        >
          <div class="flex items-center justify-between">
            <div class="font-semibold text-gray-500">Subtotal</div>
            <div class="font-semibold text-gray-500">
              ${{ billTotal.subTotal.toFixed(2) }}
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="font-semibold text-gray-500">GST(10%)</div>
            <div class="font-semibold text-gray-500">
              ${{ billTotal.gst.toFixed(2) }}
            </div>
          </div>
          <div class="flex items-center justify-between">
            <div class="font-semibold text-gray-500">
              Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{
                paymentGateway.otherFees
              }})
            </div>
            <div class="font-semibold text-gray-500">
              ${{ billTotal.gatewayFee.toFixed(2) }}
            </div>
          </div>
          <div class="flex items-center justify-between text-lg">
            <div class="font-bold text-gray-800">Total</div>
            <div class="font-bold text-gray-800">
              ${{ billTotal.amountDue.toFixed(2) }}
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-col pb-4 mb-4 border-b-2 border-neutral-100"
        >
          <div class="flex items-center justify-between text-lg">
            <div class="font-bold text-gray-800">Total</div>
            <div class="font-bold text-gray-800">
              ${{ billTotal.subTotal.toFixed(2) }}
            </div>
          </div>
          <!-- <div class="text-xs text-gray-500">
            *This will be added on to your next bill.
          </div> -->
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="w-full btn btn-primary"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon
                icon="spinner"
                class="mr-1 loader"
              />Submitting</span
            >
            <span v-if="!submitLoading">Submit & Pay</span>
          </FormulateInput>
        </StyledSubmit>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";
export default {
  name: "Manage-Seats-Form",
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    currentSeatCounts: {
      type: Object,
      default: () => {},
    },
    paymentMethod: {
      type: Object,
      default: () => {},
    },
    paymentGateway: {
      type: Object,
      default: () => {},
    },
    totals: {
      type: Object,
      default: () => {},
    },
  },
  components: { StyledSubmit },
  data() {
    return {
      loading: false,
      values: {
        addedSeatCount: 1,
      },
      billPeriod: "",
      addedSeats: [],
      proratedDates: {},
      billTotal: {},
      submitLoading: false,
    };
  },
  async created() {
    this.loading = true;
    //set seat availability
    let addedSeats = [...this.contract.businessModules];
    addedSeats.forEach((seat) => {
      this.addedSeats.push({
        contractModuleId: seat._id,
        module: seat.name,
        value: 0,
        total: 0,
      });
    });
    await this.calculateProratedAmount();
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.submitLoading = true;

      //Form data
      let formData = {
        addedSeats: this.addedSeats,
        businessId: this.contract.businessMongoId,
        contractId: this.contract._id,
        remainingDays: this.proratedDates.remainingDays,
        daysPassed: this.proratedDates.daysPassed,
      };

      if (this.proratedDates.remainingDays > 0) {
        formData.total = this.billTotal.subTotal;
        formData.isProrated = true;
      } else {
        formData.isProrated = false;
        formData.total = 0;
      }
      await this.$store
        .dispatch(`contract/addSeatCount`, formData)
        .then(async () => {
          this.$emit("addedSeats", true);
        })
        .catch(async (err) => {
          console.error(err);
          this.$emit("addedSeats", false);
        })
        .finally(() => {
          this.submitLoading = true;
        });
    },
    init() {},

    getActualPrice(modules) {
      let amount;
      if (this.contract.contractDuration.name.toUpperCase() == "MONTHLY") {
        this.billPeriod = "month";
        amount = parseFloat(modules.price.monthly).toFixed(2);
      } else if (
        this.contract.contractDuration.name.toUpperCase() == "YEARLY"
      ) {
        switch (this.contract.billingPeriod.name.toUpperCase()) {
          case "MONTHLY":
            this.billPeriod = "month";
            amount = parseFloat(modules.price.yearly / 12).toFixed(2);
            break;
          case "YEARLY":
            this.billPeriod = "year";
            amount = parseFloat(modules.price.yearly).toFixed(2);
        }
      }

      return amount;
    },

    getSeatCount(module) {
      let moduleCount;
      switch (module.name) {
        case "EWD-FT":
          moduleCount = this.currentSeatCounts.ewdCount;
          break;
        case "Fatigue Compliance":
          moduleCount = this.currentSeatCounts.fatigueCount;
          break;
        case "KM-TIME":
          moduleCount = this.currentSeatCounts.kmTimeCount;
          break;
        case "One API":
          moduleCount = this.currentSeatCounts.fatigueCount;
          break;
        case "Mass Compliance":
          moduleCount = this.currentSeatCounts.fatigueCount;
          break;
        default:
          moduleCount = 0;
      }

      return moduleCount;
    },

    async calculateProratedAmount() {
      let cutOffDate, remainingDays, daysPassed, remainingDurationName;
      const lastPaymentDate = moment(this.contract.lastPaymentDate).format(
        "MM-DD-YYYY"
      );
      const currentDate = moment(new Date()).format("MM-DD-YYYY");
      if (this.contract.billingPeriod.name.toUpperCase() === "MONTHLY") {
        cutOffDate = moment(lastPaymentDate, "MM-DD-YYYY")
          .add(28, "days")
          .format("MM-DD-YYYY");
        remainingDays = moment(cutOffDate).diff(currentDate, "days");
        daysPassed = moment(currentDate).diff(lastPaymentDate, "days");
        remainingDurationName = "day(s)";
      } else {
        cutOffDate = moment(lastPaymentDate, "MM-DD-YYYY")
          .add(12, "months")
          .format("MM-DD-YYYY");
        remainingDays = moment(cutOffDate).diff(currentDate, "months");
        daysPassed = moment(currentDate).diff(lastPaymentDate, "months");
        remainingDurationName = "month(s)";
      }
      //if days passed is more than 28 days, there will be no prorated amount.

      this.proratedDates = {
        lastPaymentDate: lastPaymentDate,
        currentDate: currentDate,
        cutOffDate: cutOffDate,
        remainingDays: remainingDays,
        daysPassed: daysPassed,
        remainingDurationName: remainingDurationName, // days, day, months, month
      };
    },

    getProratedSeatAmount(module, index) {
      const actualPrice = this.getActualPrice(module);
      let totalProratedPrice, perSeatPrice, seatPricePerPeriod;
      //if the billing period is yearly, set a prorated amount.

      if (this.contract.billingPeriod.name.toUpperCase() === "YEARLY") {
        if (this.proratedDates.remainingDays === 12) {
          totalProratedPrice = parseFloat(
            actualPrice * this.addedSeats[index].value
          ).toFixed(2);
          perSeatPrice = parseFloat(actualPrice).toFixed(2);
        } else {
          //get the pricing per month of the seat
          seatPricePerPeriod = actualPrice / 12;

          //get the seat price for each, based on the remaining months
          perSeatPrice = (
            seatPricePerPeriod * this.proratedDates.remainingDays
          ).toFixed(2);
          // get the computed price
          const computedPrice =
            seatPricePerPeriod * this.addedSeats[index].value;

          totalProratedPrice = (
            computedPrice * this.proratedDates.remainingDays
          ).toFixed(2);
        }
      } else {
        totalProratedPrice = parseFloat(
          actualPrice * this.addedSeats[index].value
        ).toFixed(2);
        perSeatPrice = parseFloat(actualPrice).toFixed(2);
      }

      this.addedSeats[index].total = parseFloat(totalProratedPrice);
      this.addedSeats[index].unitPrice = parseFloat(perSeatPrice);
      this.addedSeats[index].actualPrice = parseFloat(actualPrice);
      if (module.name.toLowerCase() === "one api") {
        const highestValue = this.addedSeats
          .filter((seat) => seat.module !== "one api")
          .reduce((max, item) => {
            const numericValue = Number(item.value);
            return numericValue > max ? numericValue : max;
          }, -Infinity);
        this.addedSeats[index].value = highestValue;
      }

      return `$${totalProratedPrice}`;
    },

    getProratedTotalAmount() {
      let totalAmount = 0;

      this.addedSeats.forEach((data) => {
        totalAmount += data.total;
      });
      const gst = (totalAmount / 100) * this.paymentGateway.gstPercentageFee;
      const totalGST = totalAmount + gst;
      const gatewayFee =
        (totalGST / 100) * this.paymentGateway.surchargePercentageFee +
        this.paymentGateway.otherFees;
      const amountDue = totalGST + gatewayFee;
      this.billTotal = {
        subTotal: totalAmount,
        totalGST: totalGST,
        gst: gst,
        gatewayFee: gatewayFee,
        amountDue: amountDue,
      };
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  watch: {
    addedSeats: {
      handler: function () {
        this.getProratedTotalAmount();
      },
      deep: true,
    },
  },
};
</script>
