<template>
  <div>
    <div class="flex w-full" v-if="loading">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
    <div v-if="passwordCreated">
      <h1 class="text-2xl text-green-600 text-center font-bold">
        Welcome to Logmaster Australia, your account has been created.
      </h1>
      <div class="text-sm text-center">Redirecting...</div>
    </div>
    <div v-if="!passwordCreated">
      <FormulateForm
        v-model="businessPasswordForm"
        @submit="onSubmit"
        v-if="!loading"
      >
        <FormulateInput
          type="password"
          name="password"
          label="Password"
          validation="^required"
          :validation-messages="{
            matches: 'Password must contain at least 1 number.',
          }"
        />
        <FormulateInput
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          validation="^required"
          :validation-messages="{
            matches: 'Password must contain at least 1 number.',
          }"
        />
        <div class="flex items-center justify-between w-full">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            class="w-full"
          />
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/commons/ui/Spinner";

export default {
  name: "Business-Accept-Form",
  components: { Spinner },
  data() {
    return {
      businessPasswordForm: {},
      loading: true,
      errorMessage: "",
      isValidBusiness: false,
      businessId: null,
    };
  },
  async mounted() {
    this.businessId = this.$route.params["id"];
    await this.$store
      .dispatch("business/getBusiness", { id: this.businessId })
      .then((business) => {
        if (business) {
          if (business.uid)
            window.location.href =
              "/login?message=Request is already accepted&state=error";
          this.loading = false;
          this.isValidBusiness = true;
        } else {
          this.loading = false;
          this.errorMessage = "No bussiness account found!";
          //  window.location.href = '/login?message=Business not found'
        }
      })
      .catch((error) => {
        this.loading = false;
        this.errorMessage = error?.message;
        window.location.href = "/login?message=Invalid request&state=error";
      });
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      this.errorMessage = "";
      const businessPasswordData = JSON.parse(
        JSON.stringify(this.businessPasswordForm)
      );
      if (businessPasswordData && this.isValidBusiness) {
        this.$store
          .dispatch("business/createBusinessPassword", {
            id: this.businessId,
            password: businessPasswordData,
          })
          .then((business) => {
            if (business) {
              try {
                const acceptBusiness = this.$store.dispatch(
                  "business/acceptBusiness",
                  this.businessId
                );
                this.loading = false;
                if (acceptBusiness) {
                  this.passwordCreated = true;
                  setTimeout(() => {
                    window.location.href = "/logout";
                  }, 3003);
                } else {
                  this.errorMessage =
                    "Business has already accepted the invitation";
                  window.location.href =
                    "/login?message=Request already accepted&state=error";
                }
              } catch (error) {
                this.loading = false;
                this.errorMessage = "Something went wrong";
                window.location.href =
                  "/login?message=Something went wrong&state=error";
              }
            }
          })
          .catch((error) => {
            this.loading = false;
            this.errorMessage = error?.message;
            window.location.href = "/login?message=Invalid request&state=error";
          });
      }
    },
  },
};
</script>
