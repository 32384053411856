import Dashboard from '@/pages/business/Dashboard';
import Drivers from '@/pages/business/Drivers';
import ManualDrivers from '@/pages/business/ManualDrivers';
import FitnessDeclarations from '@/pages/business/FitnessDeclarations';
import ManualEvent from '@/pages/business/ManualEvent';
import Vehicle from '@/pages/business/Vehicle';
import VehicleContractors from '@/pages/business/VehicleContractors';
import PreStart from '@/pages/business/PreStart';
import FormsReport from '@/pages/business/FormsReport';
import InvestigationRegister from '@/pages/business/InvestigationRegister';
import FormBuilderCreate from '@/pages/business/FormBuilderCreate';
import FormBuilderEdit from '@/pages/business/FormBuilderEdit';
import FormBuilderEditMaster from '@/pages/business/FormBuilderEditMaster';
import FormVersions from '@/pages/business/FormVersions';
import ContractTermsBuilderCreate from '@/pages/business/ContractTermsBuilderCreate';
import ContractTerms from '@/pages/business/ContractTerms';
import ContractTermsForms from '@/pages/business/ContractTermsForms';
import ContractTermsBuilderEdit from '@/pages/business/ContractTermsBuilderEdit';
import ContractTermRequests from '@/pages/business/ContractTermRequests';
import FormsSetup from '@/pages/business/form-setup/Index';
import FormsSetupFeature from '@/pages/business/form-setup/FormsSetupFeature';
import Depot from '@/pages/business/permissions/DepotConfigurations';
import Users from '@/pages/Users';
import Roles from '@/pages/Roles';
// import Responsibilities from '@/pages/business/permissions/Responsibilities'
import EWDInstalls from '@/pages/business/device/EWDInstalls';
import UninstallEvents from '@/pages/business/device/UninstallEvents';
import PaymentHistory from '@/pages/business/billing/PaymentHistory';
import Contract from '@/pages/business/billing/Contract';
import LicenceManagement from '@/pages/business/billing/LicenceManagement';
import DevicesIndex from '@/pages/business/device/Index';
import PaymentMethod from '@/pages/business/PaymentMethod';
import ConfigureAccreditation from '@/pages/business/ConfigureAccreditation';
import OneApi from '@/pages/business/OneApi.vue';
import DocumentTemplatesEdit from '@/pages/business/DocumentTemplatesEdit';
import MarketplaceSubscriptions from '@/pages/business/marketplace/MarketplaceSubscriptions.vue';
import Accreditation from '@/pages/business/fatigue-management/accreditation/Accreditation.vue';
import Conformance from '@/pages/business/fatigue-management/conformance/Conformance.vue';
import Register from '@/pages/business/fatigue-management/register/Register.vue';
import FormsReal from '@/pages/business/fatigue-management/forms/Forms.vue';
import FatigueDashboard from '@/pages/business/fatigue-management/dashboard/FatigueDashboard.vue';
import FatigueDashboardDetailed from '@/pages/business/fatigue-management/dashboard/FatigueDashboardDetailed.vue';
import ReportBreachConfirmation from '@/pages/business/reports/BreachConfirmation.vue';
import ReportCompliance from '@/pages/business/reports/Compliance.vue';
import ReportBreaches from '@/pages/business/reports/Breaches.vue';
import ReportDaily from '@/pages/business/reports/DailyForms.vue';
import ScheduledReport from '@/pages/ScheduledReport.vue';
import KmAndTimeReport from '@/pages/business/KmAndTimeReport.vue';
import EventModification from '@/pages/business/EventModification.vue';
import VehiclesReport from '@/pages/business/VehiclesReport.vue';
import DriverTrips from '@/pages/business/DriverTrips.vue';
import VehicleMass from '@/pages/business/VehicleMass.vue';
import VehicleMassReport from '@/pages/business/VehicleMassReport.vue';
import DriverMassReport from '@/pages/business/DriverMassReport.vue';
import FleetMassReport from '@/pages/business/FleetMassReport.vue';
import Fleet from '@/pages/business/Fleet.vue';
import ReleaseNotes from "@/pages/ReleaseNotes.vue";

export default [
  {
    path: '/',
    name: 'Dashboard - Business',
    component: Dashboard,
  },
  {
    path: 'drivers',
    name: 'Drivers - Business',
    component: Drivers,
  },
  {
    path: 'drivers-manual',
    name: 'Manual Drivers - Business',
    component: ManualDrivers,
  },
  {
    path: 'drivers-fitness-declarations',
    name: 'Fitness Declarations - Business',
    component: FitnessDeclarations,
  },
  {
    path: 'manual-events',
    name: 'Manual Events - Business',
    component: ManualEvent,
  },
  {
    path: 'vehicles',
    name: 'Vehicles - Business',
    component: Vehicle,
  },
  {
    path: 'vehicles-contractor',
    name: 'Contractor Vehicles - Business',
    component: VehicleContractors,
  },
  {
    path: 'vehicles-prestart-checks',
    name: 'Prestart Checks - Business',
    component: PreStart,
  },
  {
    path: 'forms-report',
    name: 'Forms Report - Business',
    component: FormsReport,
  },
  {
    path: 'investigation-register',
    name: 'Investigation Register - Business',
    component: InvestigationRegister,
  },
  {
    path: 'depot-configuration',
    name: 'Depot Configuration',
    component: Depot,
  },
  {
    path: 'form-builder/:id/versions',
    name: 'Form Versions - Business',
    component: FormVersions,
  },
  {
    path: 'form-setup/:featureType/set',
    name: 'Forms Setup State',
    component: FormsSetupFeature,
  },
  {
    path: 'form-setup',
    name: 'Forms Setup',
    component: FormsSetup,
  },
  {
    path: 'contracts/terms-forms/builder',
    name: 'Contracts Term Builder - Business',
    component: ContractTermsBuilderCreate,
  },
  {
    path: 'contracts/terms-forms/builder/:id/edit',
    name: 'Contracts Term Builder - Business (Edit)',
    component: ContractTermsBuilderEdit,
  },
  {
    path: 'contracts/terms-forms',
    name: 'Contracts Terms Forms',
    component: ContractTermsForms,
  },
  {
    path: 'contracts/terms',
    name: 'Contracts Terms',
    component: ContractTerms,
  },
  {
    path: 'contracts/requests',
    name: 'Term Requests',
    component: ContractTermRequests,
  },
  {
    path: 'users',
    name: 'Business - Users',
    component: Users,
  },
  {
    path: 'roles',
    name: 'Business - Roles',
    component: Roles,
  },
  // {
  //   path: 'responsibilities',
  //   name: 'Business - Responsibilities',
  //   component: Responsibilities
  // },
  {
    path: 'ewd-installs',
    name: 'Devices - EWD Installs',
    component: EWDInstalls,
  },
  {
    path: 'uninstall-events',
    name: 'Devices - Uninstall Events',
    component: UninstallEvents,
  },
  {
    path: 'payment-history',
    name: 'Billing - Payment History',
    component: PaymentHistory,
  },
  {
    path: 'billing',
    name: 'Billing - Contract',
    component: Contract,
  },
  {
    path: 'licence-management',
    name: 'Billing - Licence Management',
    component: LicenceManagement,
  },
  {
    path: 'devices',
    name: 'Devices',
    component: DevicesIndex,
  },
  {
    path: 'payment',
    name: 'Payment Method',
    component: PaymentMethod,
  },
  {
    path: 'configure-accreditation',
    name: 'Configure Accreditation',
    component: ConfigureAccreditation,
  },
  {
    path: 'api',
    name: 'One API',
    component: OneApi,
  },
  {
    path: 'fatigue-management/document-templates/:id/edit',
    name: 'Document Template - Document Template (Edit)',
    component: DocumentTemplatesEdit,
  },
  {
    path: 'fatigue-management/conformance/',
    name: 'Fatigue - Conformance',
    component: Conformance,
  },
  {
    path: 'fatigue-management/accreditation/',
    name: 'Fatigue - Accreditation',
    component: Accreditation,
  },
  {
    path: 'fatigue-management/registers/',
    name: 'Fatigue - Registers',
    component: Register,
  },
  {
    path: 'fatigue-management/forms/new-template/:formType',
    name: 'Form Builder - Create New Form Template',
    component: FormBuilderCreate,
  },
  {
    path: 'fatigue-management/forms/:formType/edit-master',
    name: 'Form Builder - Form Template (Edit Master)',
    component: FormBuilderEditMaster,
  },
  {
    path: 'fatigue-management/forms/:id/edit',
    name: 'Form Builder - Form Template (Edit)',
    component: FormBuilderEdit,
  },
  {
    path: 'fatigue-management/form/',
    name: 'Forms - Business',
    component: FormsReal,
  },
  {
    path: 'services/subscription',
    name: 'Services - Subscription',
    component: MarketplaceSubscriptions,
  },
  {
    path: 'fatigue-management/dashboard/',
    name: 'Fatigue Dashboard',
    component: FatigueDashboard,
  },
  {
    path: 'fatigue-management/dashboard/detailed/:id',
    name: 'Detailed Fatigue Dashboard',
    component: FatigueDashboardDetailed,
  },
  {
    path: 'reports/breach-confirmation',
    name: 'Breach Confirmation',
    component: ReportBreachConfirmation,
  },
  {
    path: 'reports/compliance',
    name: 'Compliance',
    component: ReportCompliance,
  },
  {
    path: 'reports/breaches',
    name: 'Breaches',
    component: ReportBreaches,
  },
  {
    path: 'reports/daily',
    name: 'Daily Forms',
    component: ReportDaily,
  },
  {
    path: 'reports/km-time',
    name: 'KM & Time Report',
    component: KmAndTimeReport,
  },
  {
    path: 'reports/vehicles',
    name: 'Vehicles Report',
    component: VehiclesReport,
  },
  {
    path: 'reports/scheduling',
    name: 'Report Scheduling',
    component: ScheduledReport,
  },
  {
    path: 'reports/event/modification/:id?',
    name: 'Event Modification',
    component: EventModification,
  },
  {
    path: 'driver-trips',
    name: 'Driver Trips',
    component: DriverTrips,
  },
  {
    path: 'vehicles/mass',
    name: 'Vehicles - Mass Compliance',
    component: VehicleMass,
  },
  {
    path: 'reports/mass/vehicles',
    name: 'Vehicles Mass Report',
    component: VehicleMassReport,
  },
  {
    path: 'reports/mass/drivers',
    name: 'Drivers Mass Report',
    component: DriverMassReport,
  },
  {
    path: 'reports/mass/fleet',
    name: 'Fleet Mass Report',
    component: FleetMassReport,
  },
  {
    path: 'connected-fleet',
    name: 'Connected Fleet',
    component: Fleet,
  },
  {
    path: "support/release-notes",
    name: "Business - Release Notes",
    component: ReleaseNotes,
  },
];