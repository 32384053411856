import { STATES } from '@/constants/location'

export default [  
  {
    component: 'div',
    class: 'grid grid-cols-1 lg:grid-cols-2 lg:gap-4',
    children: [
      {
        type: 'text',
        name: 'driverName',
        label: 'Full Name',
        validation: 'required'
      },
      {
        type: 'email',
        name: 'emailAddress',
        label: 'Email Address',
        validation: '^required|email',
        "@input": "check-email"
      },
    ]
  },
  {
    component: 'div',
    class: 'grid grid-cols-1 lg:grid-cols-2 lg:gap-4',
    children: [
      {
        type: 'text',
        name: 'contactNumber',
        label: 'Contact Phone Number',
        validation: 'required|number'
      },
      {
        type: 'date',
        name: 'driverDateOfBirth',
        label: 'Date Of Birth',
        validation: 'required'
      },
    ]
  },
  {
    component: 'div',
    class: 'grid grid-cols-1 lg:grid-cols-2 lg:gap-4',
    children: [
      {
        type: 'text',
        name: 'driversLicenseNumber',
        label: 'Licence Number',
        validation: 'required'
      },
      {
        type: 'date',
        name: 'driversLicenseExpiry',
        label: 'Licence Expiry',
      },
    ]
  },
  {
    name: 'driversLicenseState',
    type: 'select',
    label: 'Licence State',
    options: STATES,
    validation: 'required'
  },
]