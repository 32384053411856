
import { STATES } from '@/constants/location'

export default [
  {
      type: 'group',
      name: 'businessProfile',
      children: [
        {
          type: 'text',
          name: 'businessName',
          label: 'Business Name',
          validation: 'required'
        },
        {
          component: 'div',
          class: 'grid grid-cols-2 gap-4',
          children: [
            {
              type: 'text',
              name: 'tradingName',
              label: 'Trading Name',
              validation: 'required'
            },
            {
              type: 'text',
              name: 'abn',
              label: 'ABN',
              validation: 'required'
            },
          ]
        },
        {
          component: 'div',
          class: 'grid grid-cols-2 gap-4',
          children: [
            {
              type: 'email',
              name: 'supportEmail',
              label: 'Support Email',
              validation: 'optional|email'
            },
            {
              type: 'text',
              name: 'supportPhoneNumber',
              label: 'Support Phone Number'
            }
          ]
        },   
        {
          type: 'group',
          name: 'businessAddress',
          class: 'border-t-2 mt-2 pt-4',
          children: [
            {
              name: 'street',
              label: 'Street address',
              validation: 'required'
            },
            {
              component: 'div',
              class: 'grid grid-cols-2 gap-4',
              children: [
                {
                  name: 'city',
                  label: 'City',
                  validation: 'required'
                },
                {
                  name: 'state',
                  type: 'select',
                  label: 'State',
                  options: STATES,
                  validation: 'required'
                },
              ]
            }
          ]
        }
      ]
    }
  ]