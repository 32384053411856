<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="email"
          name="email"
          label="User email"
          validation="^required|email"
          input-class="disabled:opacity-70 px-3 py-3 w-full border border-gray-300 rounded text-sm"
          disabled
        />
        <FormulateInput
          type="text"
          name="userName"
          label="Full Name"
          validation="required"
        />
        <FormulateInput
          type="text"
          name="phoneNumber"
          label="Phone No."
          validation="required"
        />
        <FormulateInput
          type="select"
          name="authOption"
          label="Authentication Type"
          validation="required"
          :options="authOptions"
        />
        <div>
          <label class="text-sm font-semibold form-label pr-2 text-neutral-dark"
            >User Access</label
          >
          <div class="flex items-center mt-2">
            <input
              type="checkbox"
              v-model="rootAccess"
              :checked="rootAccess"
              class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 cursor-pointer"
            />
            <span class="ml-2 text-sm">Allow access to main business</span>
          </div>
        </div>

        <div class="my-4">
          <div
            v-if="depots.length"
            class="mt-3 border rounded p-5"
            id="depotContainer"
          >
            <label class="font-bold text-sm mb-3 block"
              >Please select which business site</label
            >
            <DepotTree
              v-for="depot in depots"
              :key="depot._id"
              :item="depot"
              class="my-3"
              @tick="onTick($event)"
              @default="setDefault($event)"
            />
          </div>
        </div>

        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :disabled="submitLoading || loading || hasErrors"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
              data...</span
            >
            <span v-if="!submitLoading">Submit</span>
          </FormulateInput>
        </StyledSubmit>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="error">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DepotTree from "./depot-tree.vue";
import { StyledSubmit } from "@/styled-components";
import { findDepotById } from "@/_helper/";
import $ from "jquery";

export default {
  name: "Edit-User-Form",
  components: { StyledSubmit, DepotTree },
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      error: false,
      errorMessage: "",
      loading: false,
      submitLoading: false,
      values: {},
      depots: [],
      defaultSite: null,
      rootAccess: false,
      authOptions: [
        {
          label: "Basic Authentication",
          value: "basic",
        },
      ],
    };
  },
  watch: {
    rootAccess: {
      immediate: true,
      handler() {
        this.showDefaultButton();
      },
    },
  },
  async mounted() {
    this.loading = true;
    const me = this.$store.getters[`account/me`];
    this.depots = await this.$store.dispatch(
      `business/getDepot`,
      me?.entity?._id
    );

    const businessSSOConfig = this.$store.getters["account/ssoConfiguration"];
    if (businessSSOConfig && businessSSOConfig.enabled) {
      this.authOptions.push({ label: "SAML", value: "saml" });
    }

    const user = await this.$store.dispatch(
      `rolesandrespo/getUser`,
      this.detailsId
    );
    if (user) {
      if (!user.authOption) {
        user.authOption = "basic";
      }
      this.values = user;
      this.rootAccess = user.site ? false : true;
    }

    setTimeout(() => {
      this.values.depotSites.forEach((depot) => {
        depot.isChecked = true;
        this.onTick(depot, true);
        if (!this.rootAccess) {
          this.setDefault({ _id: user.site._id });
        }
      });
    }, 100);
    this.loading = false;
  },
  methods: {
    showDefaultButton() {
      let checkedSiteIds = [];
      const checkedDepots = $("#depotContainer").find("input:checkbox");
      $.each(checkedDepots, (index, value) => {
        const depotParent = $(value).parent();
        const defaultBtn = depotParent.find(`.btn-defaultsite`);
        if (value.checked) {
          const isLeaf = depotParent.attr("data-leaf");
          if (isLeaf) checkedSiteIds.push(depotParent.attr("data-id"));
        }

        if (value.checked && !this.rootAccess) defaultBtn.show();
        else defaultBtn.hide();
      });

      // If default site is not on the checked sites, set first site as the default
      if (!checkedSiteIds.includes(this.defaultSite)) {
        this.setDefault({ _id: checkedSiteIds[0] });
      }
    },
    setDefault(item) {
      $(".btn-defaultsite").addClass("isNotDefault");
      $(`#default${item._id}`).removeClass("isNotDefault");

      this.defaultSite = item._id;
    },
    onTick(depot, onLoad = false) {
      const elDepot = $("#depotContainer").find(`[data-id="${depot._id}"]`);
      if (onLoad) {
        this.setDefault({ _id: depot._id });
        elDepot.find("input:checkbox").attr("checked", true);
      }
      const depth = elDepot.attr("data-depth");

      elDepot
        .parent()
        .parent()
        .children(":first-child")
        .find("input")
        .attr("checked", depot.isChecked);

      // Handle auto check/uncheck of child depots
      const childCheckboxes = elDepot.parent().find("input:checkbox");
      $.each(childCheckboxes, (index, checkbox) => {
        if (index != 0) $(checkbox).attr("checked", depot.isChecked);
      });

      // Handle auto check/uncheck of depot parents
      let element = elDepot;
      for (let index = depth; index >= 0; index--) {
        element = $(element).parent();
        if (index == depth) continue;

        const elCheckbox = element.children(":first-child").find("input");
        if (depot.isChecked) elCheckbox.attr("checked", true);
        else {
          const depotId = element.children(":first-child").attr("data-id");
          const checkedChildren = $.map(
            element.find("input:checkbox:checked"),
            (child) => {
              if ($(child).parent().attr("data-id") == depotId) return false;
              return child;
            }
          );

          if (checkedChildren.length == 0 && depotId != depot.pid) {
            elCheckbox.attr("checked", false);
          }
        }
      }

      // Hide/show default button
      this.showDefaultButton();
    },
    async onSubmit() {
      this.submitLoading = true;

      try {
        let depotSites = [];
        const checkedDepots = $("#depotContainer").find(
          "input:checkbox:checked"
        );
        $.each(checkedDepots, (index, value) => {
          const depotId = $(value).parent().attr("data-id");
          const depot = findDepotById(depotId, this.depots);
          if (!depot.isRegion) depotSites.push(depot._id);
        });

        const site = this.rootAccess ? "root" : this.defaultSite;
        await this.$store.dispatch(`rolesandrespo/patchUser`, {
          ...this.values,
          depotSites: depotSites,
          site: site,
        });
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "success",
          message: "User successfully updated.",
        });
        this.$emit("closeRefresh");
      } catch (error) {
        this.error = true;
        this.errorMessage = `Something went wrong please try again.`;
        if (error.message) {
          this.errorMessage = error.message;
        }
      }

      this.submitLoading = false;
    },
    handleStritEmailCase(value) {
      this.values.email = value.toLowerCase();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss"></style>
