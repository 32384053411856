<template>
  <div class="mb-4">
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="flex w-full space-x-4">
        <div class="w-1/2">
          <FormulateInput
            type="date"
            name="fromDate"
            v-model="values.fromDate"
            label="From Date"
            validation="required"
          />
        </div>
        <div class="w-1/2">
          <FormulateInput
            type="date"
            name="toDate"
            v-model="values.toDate"
            label="To Date"
            validation="required"
          />
        </div>
      </div>
      <div class="items-center justify-between">
        <button
          type="submit"
          class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.primaryColor }"
        >
          Submit
        </button>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { API } from "@/api/config";
import { mapGetters } from "vuex";
export default {
  props: {
    entityId: String,
    parentRole: String,
    contractType: String,
  },
  data() {
    return {
      values: {
        fromDate: new Date(),
        toDate: new Date(),
      },
      loading: false,
      hasErrorMessage: false,
      errorMessage: "",
    };
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      await this.$store
        .dispatch("contract/generateBillingReport", {
          entityId: this.entityId,
          fromDate: this.values.fromDate,
          toDate: this.values.toDate,
          parentRole: this.parentRole,
          contractType: this.contractType,
        })
        .then(async (response) => {
          if (response) {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Report generated successfully.",
            });
            this.$emit("closeModal");
            window.open(
              API.ACCOUNTS.defaults.baseURL + "/" + response.data.urlPath,
              "_blank"
            );
          }
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
