var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('FormulateForm',{scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('ui-spinner',{staticClass:"m-auto"}),_vm._v(" Loading... ")],1):_vm._e(),_c('FormulateInput',{attrs:{"type":"text","name":"name","label":"Connection Name","validation":"required"}}),_c('div',[_c('FormulateInput',{attrs:{"type":"text","name":"username","label":"Service Account","validation":"required"}}),_c('FormulateInput',{attrs:{"type":"password","name":"password","label":"Password","validation":"required"}}),_c('FormulateInput',{attrs:{"type":"password","name":"confirmPassword","label":"Confirm Password","validation":"required"}}),(_vm.preData.provider && _vm.preData.provider === 'geotab')?_c('FormulateInput',{attrs:{"type":"text","name":"database","label":"Database"}}):_vm._e(),(_vm.preData.provider && _vm.preData.provider === 'geotab')?_c('FormulateInput',{attrs:{"type":"text","name":"server","label":"Server","validation":"required"}}):_vm._e(),(_vm.preData.provider && _vm.preData.provider === 'mix')?_c('FormulateInput',{attrs:{"type":"text","name":"clientId","label":"Client ID","validation":"required"}}):_vm._e(),(_vm.preData.provider && _vm.preData.provider === 'mix')?_c('FormulateInput',{attrs:{"type":"password","name":"clientSecret","label":"Client Secret","validation":"required"}}):_vm._e(),(_vm.preData.provider && _vm.preData.provider === 'verizon')?_c('FormulateInput',{attrs:{"type":"text","name":"appId","label":"Application ID","validation":"required"}}):_vm._e()],1),_c('div',{staticClass:"flex flex-row"},[_c('button',{staticClass:"w-1/2 px-2 mr-2 btn btn-primary",style:({
          background: _vm.theme.primaryColor,
          color: 'white',
        }),on:{"click":function($event){$event.preventDefault();return _vm.backStep()}}},[_vm._v(" Back ")]),_c('button',{staticClass:"w-1/2 px-2 ml-2 btn btn-primary",style:({
          background: _vm.theme.secondaryColor,
          color: 'white',
        }),attrs:{"disabled":hasErrors},on:{"click":function($event){$event.preventDefault();return _vm.onSubmit()}}},[_vm._v(" Submit ")])])]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }