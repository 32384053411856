const DEFAULT_MODAL_HEIGHT = "90vh";
const LOGO_FILE_UPLOAD_LIMIT_SIZE = 0.5; // in Megabytes
const GEOTAB_COOKIE = "isGeotabAccount";
const GEOTAB_URL = "geotab-portal-v2-uri";

const STATES = [
  { value: "ACT", label: "ACT" },
  { value: "NSW", label: "NSW" },
  { value: "NT", label: "NT" },
  { value: "QLD", label: "QLD" },
  { value: "SA", label: "SA" },
  { value: "TAS", label: "TAS" },
  { value: "VIC", label: "VIC" },
  { value: "WA", label: "WA" },
];

const TIMEZONES = [
  { value: "ACT +10:00", label: "ACT +10:00" },
  { value: "ACT +11:00", label: "ACT +11:00" },
  { value: "NSW +10:00", label: "NSW +10:00" },
  { value: "NSW +11:00", label: "NSW +11:00" },
  { value: "NT +09:30", label: "NT +09:30" },
  { value: "QLD +10:00", label: "QLD +10:00" },
  { value: "SA +10:30", label: "SA +10:30" },
  { value: "SA +09:30", label: "SA +09:30" },
  { value: "TAS +10:00", label: "TAS +10:00" },
  { value: "TAS +11:00", label: "TAS +11:00" },
  { value: "VIC +10:00", label: "VIC +10:00" },
  { value: "VIC +11:00", label: "VIC +11:00" },
  { value: "WA +08:00", label: "WA +08:00" },
];

const VEHICLE_CLASS = [
  { name: "LV", order: 3 },
  { name: "MV", order: 2 },
  { name: "HV", order: 1 },
];

const FORM_TYPES = [
  "incident",
  "prestart",
  "fitness_declaration",
  "templates",
  "hazard",
  "defect",
  "infringement",
];

const INVESTIGATION_STATUS = {
  PENDING: "pending",
  REOPENED: "reopened",
  DECLINED: "declined",
  COMPLETED: "completed",
};

const UNFINISHED_INVESTIGATION = ["pending", "reopened"];

const FINISHED_INVESTIGATION = [
  "completed",
  "declined",
  "hazard",
  "defect",
  "infringement",
];

const FORM = {
  FITNESS: {
    key: "fitness_declaration",
    label: "Fitness Declaration",
  },
  PRESTART: {
    key: "prestart",
    label: "Prestart",
  },
  INCIDENT: {
    key: "incident",
    label: "Incident",
  },
  HAZARD: {
    key: "hazard",
    label: "Hazard",
  },
  INFRINGMENT: {
    key: "infringement",
    label: "Infringement Notice",
  },
  DEFECT: {
    key: "defect",
    label: "Defect Notice",
  },
};

const FORM_KEY_MAPPING = {
  fitness_declaration: "Fitness Declaration",
  prestart: "Prestart",
  incident: "Incident",
  hazard: "Hazard",
  infringement: "Infringement Notice",
  defect: "Defect Notice",
};

const DATA_TYPES = {
  FITNESS_DECLARATION: "fitness_declaration",
  PRESTART: "prestart",
  BREACH: "breach",
  EVENT: "event",
  INCIDENT: "incident",
  HAZARD: "hazard",
  INFRINGEMENT: "infringement",
  DEFECT: "defect",
};

const DOCUMENT_FORM_TYPES = {
  IMMEDIATE_ACTION: "immediate_action",
  INTERVIEW: "interview",
  EVIDENCE_COLLECTION: "evidence_collection",
  FINDINGS: "findings",
};

const SEARCH_DEBOUNCE_DELAY = 1000;

const ROLES = {
  ADMIN: "admin",
  BUSINESS: "business",
  PARTNERS: "partner",
  RESELLERS: "reseller",
  DRIVERS: "driver",
};

const ENABLED_LOGO_UPLOAD_ACCOUNT = [
  ROLES.ADMIN,
  ROLES.PARTNERS,
  ROLES.RESELLERS,
];

const FORM_CONTROLS = {
  PLAIN_TEXT: "plain-text",
  TEXT: "text-control",
  NUMBER: "number-control",
  EMAIL: "email-control",
  PASSWORD: "password-control",
  TEXTAREA: "textarea-control",
  SELECT: "select-control",
  DATE_PICKER: "datepicker-control",
  TIME_PICKER: "timepicker-control",
  CHECKBOX: "checkbox-control",
  RADIO: "radiobutton-control",
  SWITCH: "switch-control",
  VEHICLE_SELECT: "vehicle-select-control",
  FILE: "file-control",
  DOC: "documentlink-control",
  IMAGE: "image-control",
  SIGNATURE: "signaturepad-control",
};

const MODULE_CATEGORIES = [
  {
    label: "Category One",
    value: "CATEGORY_ONE",
  },
  {
    label: "Category Two",
    value: "CATEGORY_TWO",
  },
  {
    label: "Category Three",
    value: "CATEGORY_THREE",
  },
  {
    label: "Category Four",
    value: "CATEGORY_FOUR",
  },
  {
    label: "Category Five",
    value: "CATEGORY_FIVE",
  },
];

const REPORT_NAMES = {
  COMPLIANCE_REPORT: "COMPLIANCE_REPORT",
  DRIVER_STATS_REPORT: "DRIVER_STATS_REPORT",
  NHVR_QUARTERLY_DRIVER_REPORT: "NHVR_QUARTERLY_DRIVER_REPORT",
  REGISTER_SUMMARY_REPORT: "REGISTER_SUMMARY_REPORT",
  REGISTER_DETAILED_REPORT: "REGISTER_DETAILED_REPORT",
  COMPLIANCE_PERIOD_REPORT: "COMPLIANCE_PERIOD_REPORT",
  VEHICLE_REPORT: "VEHICLE_REPORT",
  DRIVER_CONTRACTS_REPORT: "DRIVER_CONTRACTS_REPORT",
};

const BUSINESS_REPORT_OPTIONS = [
  {
    label: "KM & Time Report",
    value: REPORT_NAMES.DRIVER_STATS_REPORT,
  },
  {
    label: "Compliance Report",
    value: REPORT_NAMES.COMPLIANCE_REPORT,
  },
];

const DRIVER_REPORT_OPTIONS = [
  {
    label: "Compliance Report",
    value: REPORT_NAMES.COMPLIANCE_REPORT,
  },
];

const PERIODIC_CHECKS = [
  {
    label: "Daily",
    value: "DAILY",
  },
  {
    label: "Weekly",
    value: "WEEKLY",
  },
  {
    label: "Fortnightly",
    value: "FORTNIGHTLY",
  },
  {
    label: "Monthly",
    value: "MONTHLY",
  },
];

const PERFORMANCE_STATUSES = {
  ONGOING: "ONGOING",
  CONCLUDED: "CONCLUDED",
};

const DAY_MONTH = [
  { label: "1st", value: "1" },
  { label: "2nd", value: "2" },
  { label: "3rd", value: "3" },
  { label: "4th", value: "4" },
  { label: "5th", value: "5" },
  { label: "6th", value: "6" },
  { label: "7th", value: "7" },
  { label: "8th", value: "8" },
  { label: "9th", value: "9" },
  { label: "10th", value: "10" },
  { label: "11th", value: "11" },
  { label: "12th", value: "12" },
  { label: "13th", value: "13" },
  { label: "14th", value: "14" },
  { label: "15th", value: "15" },
  { label: "16th", value: "16" },
  { label: "17th", value: "17" },
  { label: "18th", value: "18" },
  { label: "19th", value: "19" },
  { label: "20th", value: "20" },
  { label: "21st", value: "21" },
  { label: "22nd", value: "22" },
  { label: "23rd", value: "23" },
  { label: "24th", value: "24" },
  { label: "25th", value: "25" },
  { label: "26th", value: "26" },
  { label: "27th", value: "27" },
  { label: "28th", value: "28" },
  { label: "29th", value: "29" },
  { label: "30th", value: "30" },
  { label: "31st", value: "31" },
];
const NON_CONFORMANCE_STATUS_OPTION_AUDITOR = [
  {
    label: "Reject Contest",
    value: "CONTEST_REJECTED",
  },
  {
    label: "Withdraw",
    value: "WITHDRAWN",
  },
  {
    label: "Finalise",
    value: "FINALISED",
  },
  {
    label: "Reserve",
    value: "RESERVED",
  },
];

const NON_CONFORMANCE_STATUS_OPTION_AUDITEE = [
  {
    label: "Accept",
    value: "ACCEPTED",
  },
  {
    label: "Contest",
    value: "CONTESTED",
  },
];

const NON_CONFORMANCE_STATUSES = {
  ACCEPTED: "ACCEPTED",
  CONTESTED: "CONTESTED",
  CONTEST_REJECTED: "CONTEST_REJECTED",
  WITHDRAWN: "WITHDRAWN",
  FINALISED: "FINALISED",
  RESERVED: "RESERVED",
  PENDING: "PENDING",
};

const DRIVER_TRAINING_STATUSES = {
  FINISHED: "finished",
  NOT_FINISHED: "not_finished",
  YET_FINISHED: "yet_finished",
};

const EVENT_MODIFICATION_TYPES = {
  "event-deletion": "Event Deletion",
  "event-edit": "Event Edit",
};

export {
  DEFAULT_MODAL_HEIGHT,
  STATES,
  TIMEZONES,
  VEHICLE_CLASS,
  SEARCH_DEBOUNCE_DELAY,
  ROLES,
  FORM_TYPES,
  DATA_TYPES,
  DOCUMENT_FORM_TYPES,
  LOGO_FILE_UPLOAD_LIMIT_SIZE,
  ENABLED_LOGO_UPLOAD_ACCOUNT,
  FORM,
  FINISHED_INVESTIGATION,
  INVESTIGATION_STATUS,
  UNFINISHED_INVESTIGATION,
  GEOTAB_URL,
  FORM_CONTROLS,
  GEOTAB_COOKIE,
  MODULE_CATEGORIES,
  REPORT_NAMES,
  BUSINESS_REPORT_OPTIONS,
  DRIVER_REPORT_OPTIONS,
  PERIODIC_CHECKS,
  PERFORMANCE_STATUSES,
  DAY_MONTH,
  NON_CONFORMANCE_STATUSES,
  DRIVER_TRAINING_STATUSES,
  EVENT_MODIFICATION_TYPES,
  NON_CONFORMANCE_STATUS_OPTION_AUDITOR,
  NON_CONFORMANCE_STATUS_OPTION_AUDITEE,
  FORM_KEY_MAPPING,
};
