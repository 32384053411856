import { API } from "@/api/config";

export default new (class Settings {
  //PATCH: Update theme by theme options id
  updateThemeSettings({ accountId, themeData }) {
    return API.ACCOUNTS.patch(`/setting/update-theme/${accountId}`, {
      themeId: themeData._id,
      isDarkMode: themeData.isDarkMode,
    });
  }

  //@GET: Fetch settings per account
  getUserSettings({ accountId }) {
    return API.ACCOUNTS.get(`/setting/${accountId}`);
  }

  //@POST: create settings per account
  createSettings(settingsData) {
    return API.ACCOUNTS.post(`/setting/`, settingsData);
  }

  /** Release Notes */
  createReleaseNote(payload) {
    return API.ACCOUNTS.post(`/release-note/`, payload);
  }

  updateReleaseNote(payload) {
    return API.ACCOUNTS.patch(`/release-note/${payload.id}`, payload);
  }

  getReleaseNotes(id = null) {
    if (id) {
      return API.ACCOUNTS.get(`release-note/${id}`);
    }

    return API.ACCOUNTS.get(`/release-note/all`);
  }

  deleteReleaseNote(id) {
    return API.ACCOUNTS.delete(`/upload-logo/delete-logo/${id}`);
  }
})();
