<template>
    <div
        :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    >
        <list-select
            ref="selectRef"
            :options="options"
            :context="context"
            :isLoading="isLoading"
            :hasPrevPage="hasPrevPage"
            :hasNextPage="hasNextPage"
            noResultText="No vehicles found."
            @prevPage="onPrevPage"
            @nextPage="onNextPage"
            @search="onSearch"
        >
            <template slot="option" slot-scope="option">
                <div class="text-sm">
                    <h3 class="m-0 font-semibold">{{ option.vehiclePlate }}</h3>
                    <em v-if="option.vehicleType" class="text-xs">{{ option.vehicleType.name }}</em>
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected text-sm">
                    {{ option.vehiclePlate }}
                </div>
            </template>
        </list-select>
    </div>
</template>
<script>
import _ from 'lodash';
import { formatPaginationSettings } from '@/_helper';
import ListSelect from '@/components/commons/ui/list-select/ListSelect';

const VehicleSelect = {
    name: 'VehicleSelect',
    components: {
        ListSelect
    },
    props: {
        context: {
            type: Object,
            required: true
        },
        isDriveVehicle: {
            type: Boolean,
            default: false
        },
        isNonDriveVehicle: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.$refs.selectRef.onSearch();
    },
    data: () => ({
        vehicles: [],
        options: [],
        isLoading: false,
        hasPrevPage: false,
        hasNextPage: false,
        filter: {
            limit: 10,
            status: 'all',
            search: '',
        },
        paginationSettings: {
            page: 1,
            totalPages: 5,
            totalRecords: 50,
            visiblePageItemCount: 3,
        }
    }),
    methods: {
        async onSearch(search = '') {
            _.debounce(async () => {
                this.filter.search = search;
                this.options = [];
                this.isLoading = true;
                const query = {
                    skip: ( this.paginationSettings.page * this.filter.limit ) - this.filter.limit,
                    limit: this.filter.limit,
                    search: this.filter.search,
                    filter: {},
                }

                if (this.context.attributes.isDriveVehicle) {
                    query.filter.driveVehicle = true;
                }

                if (this.context.attributes.isNonDriveVehicle) {
                    query.filter.driveVehicle = false;
                }
                
                const me = this.$store.getters[`account/me`];
                const entityId = me[me.role?.name]._id;
                const vehicles = await this.$store.dispatch(`vehicle/getVehicleByEntityId`, { entityId, query});
                if (vehicles) {
                    if (vehicles?.metadata){
                        this.paginationSettings = formatPaginationSettings(vehicles?.metadata);
                        this.hasNextPage = this.paginationSettings.page < this.paginationSettings.totalPages;
                        this.hasPrevPage = this.paginationSettings.page > 1;
                    } else {
                        this.paginationSettings = formatPaginationSettings();
                    }

                    this.vehicles = vehicles.results;
                    this.options = this.vehicles;
                }
                this.isLoading = false;
            }, 500)();
        },
        onPrevPage() {
            if (this.hasPrevPage) {
                this.paginationSettings.page = this.paginationSettings.page - 1;
                this.onSearch();
            }
        },
        onNextPage() {
            if (this.hasNextPage) {
                this.paginationSettings.page = this.paginationSettings.page + 1;
                this.onSearch();
            }
        }
    }
}

export const VueFormulateVehicleSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      VehicleSelect,
    },
    library: {
      "vehicle-select": {
        classification: "select",
        component: "VehicleSelect",
      },
    },
  });
}

export default VehicleSelect;
</script>