<template>
  <div>
    <div>
      <Table
        :isLoading="loading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="tableData"
        @onPageChange="onPageChange($event)"
      >
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div>
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>

            <div>
              <input
                placeholder="NCR Number"
                autofill="true"
                class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                v-model="filter.search"
                @keyup="handleSearch"
              />
            </div>

            <div class="block relative">
              <DatePickerWithRange
                class="ml-2"
                ref="childDatePicker"
                @selected="selectDateRange"
                :date="selectedDateRangeData"
              />
            </div>
          </div>
        </template>
        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewDocument(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
                <li v-if="data.status == 'FINALISED' && isBusiness">
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="managePerformance(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="chart-bar" class="my-auto mr-2" />
                    Performance
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click="viewChainOfActions(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="link" class="my-auto mr-2" />
                    Actions Chain
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
        <template #buttons>
          <div class="flex flex-row"></div>
          <div class="mr-2">
            <button
              v-if="isBusiness"
              class="btn btn-primary"
              :style="{ backgroundColor: theme.secondaryColor }"
              @click.prevent="handleSummaryOrDetailedReportClick('summary')"
            >
              Summary Report
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-primary"
              :style="{ backgroundColor: theme.primaryColor }"
              @click.prevent="sendDetailedReport()"
            >
              Detailed Report
            </button>
          </div>
        </template>
        <template #callToActionTop>
          <button
            v-if="isBusiness"
            class="btn btn-primary"
            @click.prevent="raiseNonConformance()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Raise Non Conformance
          </button>
        </template>
      </Table>
    </div>

    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :bodyHeight="modalHeight"
    >
      <div v-if="modalContent === 'viewDocument'">
        <NonConformanceModal
          :propsDocument="selectedDocument"
          :entityWebProfileId="entityWebProfileId"
          :entityMongoId="entityMongoId"
          :entityType="entityRole"
          @closeModal="closeModal"
          @toast="toast"
        />
      </div>
      <div v-if="modalContent === 'managePerformance'">
        <PerformanceManagement
          :propsDocument="selectedDocument"
          :driverId="driverId"
          :entityWebProfileId="entityWebProfileId"
          @closeModal="closeModal"
          @toast="toast"
        />
      </div>
      <div v-if="modalContent === 'raise'">
        <RaiseNonConformanceForm
          :entityMongoId="businessId"
          :entityRole="entityRole"
          @closeModal="closeModal"
          @toast="toast"
        />
      </div>
      <div v-if="modalContent === 'summary'">
        <SummaryReport
          :dateRange="selectedDateRangeData"
          :businessId="businessId"
        />
      </div>
      <div v-if="modalContent === 'detailed'">
        <div class="mb-16">
          <div v-if="!sendReportLoading">
            <FormulateForm
              v-model="sendReportModel"
              @submit="onSubmitSendReport"
            >
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="email"
                  label="Email address"
                  validation="required|email"
                  @input="handleStripEmailCase($event)"
                />
                <div>
                  <label class="font-medium text-sm mb-1"
                    >Report Date Range</label
                  >
                  <DatePicker
                    ref="childDatePicker"
                    :date="selectedDateRange1"
                    :disabledBefore="null"
                    :displayEvent="false"
                    :displayBreach="false"
                    @selected="selectedDateRange1 = $event"
                    range
                    noNavigation
                    :clearable="false"
                    class="mb-5 mt-1 send-datepicker"
                  />
                </div>
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="btn btn-primary w-full mt-5"
                  :disabled="!canSend() || sendReportLoading"
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
      <div v-if="modalContent === 'viewChainOfActions'">
        <ViewChainOfActionsModal
          :entityId="nonConformanceId"
          :type="'nonConformance'"
          @toast="toast"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { ROLES, DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import {
  formatPaginationSettings,
  formatDate,
  capitalizeFirstLetter,
} from "@/_helper/";
import NonConformanceModal from "@/components/view/view-non-conformance";
import { RaiseNonConformanceForm } from "@/components/forms";
import PerformanceManagement from "@/components/view/performance-management";
import { StyledIconedLink } from "@/styled-components";
import { mapGetters } from "vuex";
import DatePickerWithRange from "@/components/commons/field/DatePickerWithRange";
import moment from "moment";
import { SummaryReport } from "@/components/view/business/fatigue-management/non-conformance";
import ViewChainOfActionsModal from "../components/view/business/fatigue-management/ViewChainOfActions.vue";
import _ from "lodash";
import DatePicker from "@/components/commons/field/DatePicker.vue";

export default {
  name: "non-conformance",
  components: {
    Modal,
    Table,
    NonConformanceModal,
    RaiseNonConformanceForm,
    PerformanceManagement,
    Dropdown,
    StyledIconedLink,
    DatePickerWithRange,
    SummaryReport,
    ViewChainOfActionsModal,
    DatePicker,
  },
  data() {
    return {
      loading: false,
      modalSize: "2xl",
      modalTitle: "",
      modalContent: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "NCR Number",
        "NCR Date",
        "Description",
        "Raised By",
        "Auditee",
        "Date Raised",
        "Status",
        "Non Ewd",
        "Source",
      ],
      documents: [],
      entityWebProfileId: null,
      entityMongoId: null,
      businessId: null,
      entityRole: null,
      filter: {
        limit: 10,
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      selectedDocument: {},
      isBusiness: null,
      selectedDateRangeData: [],
      me: null,
      nonConformanceId: null,
      sendReportLoading: false,
      sendReportModel: null,
      selectedDateRange1: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  async mounted() {
    await this.fetchNonConformanceDocuments();
  },
  methods: {
    async fetchNonConformanceDocuments(page = 1) {
      try {
        this.loading = true;
        const me = this.$store.getters["account/me"];
        this.me = me;
        this.entityRole = me.role.name;
        this.entityMongoId = me.entity?._id;
        this.businessId = me.businessId ? me.businessId : me.business?._id;
        this.isBusiness = this.entityRole == ROLES.BUSINESS;
        const query = {
          skip: page * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: { auditee: null, business: null },
        };

        if (me?.entity?._id && me.entity.webProfiles.length) {
          this.entityWebProfileId = me.entity.webProfiles.find((profile) => {
            return profile.uid === me.uid;
          })?._id;
          if (this.entityRole == ROLES.DRIVERS) {
            if (me?.entity?.demoOption === "NO_ACCOUNT") {
              query.filter = { "bundle.driver.udi": me?.entity?.udi };
            } else {
              query.filter.auditee = this.entityWebProfileId;
              delete query.filter.business;
            }
          } else {
            query.filter.business = this.businessId;
            delete query.filter.auditee;
          }
          const documents = await this.$store.dispatch(
            "report/getNonConformance",
            query
          );
          if (documents.data?.data) {
            this.documents = JSON.parse(JSON.stringify(documents.data.data));

            this.paginationSettings = formatPaginationSettings(
              documents.data.resultsMeta
            );
          }

          if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
          this.loading = false;
        } else {
          throw "Something went wrong";
        }
      } catch (e) {
        this.loading = false;
        this.toast("error", "Error fetching non conformance documents");
        throw e;
      }
    },
    async onPageChange(event) {
      await this.fetchNonConformanceDocuments(event.page);
    },
    parseData(documents) {
      return documents.map((document) => {
        const { auditee, bundle = {} } = document;
        const isNonEwdDriver = Object.keys(bundle).length > 0;
        let auditeeName;
        if (isNonEwdDriver) {
          auditeeName = bundle && bundle.driver ? bundle.driver.driverName : "";
        } else {
          auditeeName = auditee ? auditee.userName : "";
        }

        return [
          {
            id: document._id,
            name: document.ncrNumber,
            itemType: "string",
            status: document.status,
          },
          {
            id: document._id,
            name: document.ncrDate,
            itemType: "string",
            status: document.status,
          },
          {
            id: document._id,
            name: document.description,
            itemType: "string",
            status: document.status,
          },
          {
            id: document._id,
            name: document.raisedBy ? document.raisedBy.userName : "",
            itemType: "string",
            status: document.status,
          },
          {
            id: document._id,
            name: auditeeName,
            itemType: "string",
            status: document.status,
          },
          {
            id: document._id,
            name: formatDate(document.createdAt),
            itemType: "string",
            document,
            status: document.status,
          },
          {
            id: document._id,
            name: capitalizeFirstLetter(document.status),
            itemType: "string",
            status: document.status,
          },
          {
            id: document._id,
            name: isNonEwdDriver,
            itemType: "boolean",
            status: document.status,
          },
          {
            id: document._id,
            name: this.getNCRSource(document),
            itemType: "string",
            status: document.status,
          },
        ];
      });
    },
    async closeModal() {
      this.$refs.modal.closeModal();
      await this.fetchNonConformanceDocuments();
    },
    viewDocument(id) {
      this.$refs.modal.openModal();
      this.selectedDocument = this.documents.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "viewDocument";
      this.modalTitle = "Non Conformance Document Details";
    },
    managePerformance(id) {
      this.$refs.modal.openModal();
      this.selectedDocument = this.documents.filter(
        (item) => item._id === id
      )[0];
      this.modalContent = "managePerformance";
      this.modalTitle = "Performance Management";
    },
    raiseNonConformance() {
      this.$refs.modal.openModal();
      this.modalContent = "raise";
      this.modalTitle = "Raise a Non Conformance";
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    filterChange() {
      this.fetchNonConformanceDocuments();
    },
    handleSearch() {
      this.selectDateRange(this.selectedDateRangeData);
    },
    async selectDateRange(selectedDateRangeData = [null, null], page = 1) {
      this.loading = true;
      const query = {
        skip: page * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: { auditee: null, business: null },
      };

      if (
        selectedDateRangeData &&
        selectedDateRangeData.length != 0 &&
        selectedDateRangeData[0]
      ) {
        query.from = moment(selectedDateRangeData[0], "YYYY-MM-DD").format();
        query.to = moment(selectedDateRangeData[1], "YYYY-MM-DD").format();
        this.selectedDateRangeData = [
          new Date(moment(selectedDateRangeData[0])),
          new Date(moment(selectedDateRangeData[1])),
        ];
      } else {
        this.selectedDateRangeData = [];
      }

      if (this.entityRole === ROLES.DRIVERS) {
        query.filter.auditee = this.entityWebProfileId;
        delete query.filter.business;
      } else {
        query.filter.business = this.businessId;
        delete query.filter.auditee;
      }

      const documents = await this.$store.dispatch(
        "report/getNonConformance",
        query
      );
      if (documents.data?.data) {
        this.documents = JSON.parse(JSON.stringify(documents.data.data));

        this.paginationSettings = formatPaginationSettings(
          documents.data.resultsMeta
        );
      }
      this.loading = false;
    },
    handleSummaryOrDetailedReportClick(reportType) {
      if (!this.selectedDateRangeData || !this.selectedDateRangeData.length) {
        alert("Please select date range!");
        return;
      }

      this.modalContent = reportType;
      this.modalTitle = `Non Conformance ${capitalizeFirstLetter(
        reportType
      )} Report`;
      this.modalHeight = null;
      this.$refs.modal.openModal();
    },
    sendDetailedReport() {
      this.modalContent = "detailed";
      this.modalTitle = `Non Conformance Detailed Report`;
      this.modalHeight = null;
      this.$refs.modal.openModal();
    },
    getNCRSource(ncr) {
      const { investigation } = ncr;

      if (investigation) {
        return "Investigation";
      } else {
        return "Default";
      }
    },

    viewChainOfActions(nonConformanceId) {
      this.$refs.modal.openModal();
      this.modalContent = "viewChainOfActions";
      this.modalTitle = "Chain Of Actions";
      this.nonConformanceId = nonConformanceId;
      this.modalHeight = null;
    },

    handleStripEmailCase(value) {
      this.sendReportModel.email = value.toLowerCase();
    },

    canSend() {
      return (
        !_.isEmpty(this.sendReportModel?.email) &&
        this.selectedDateRange1.length > 1 &&
        !_.isEmpty(this.selectedDateRange1[0])
      );
    },

    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      if (recipientEmail) {
        this.sendReportLoading = true;

        const me = this.$store.getters[`account/me`];
        const data = {
          recipientEmail: recipientEmail,
          businessId: me?.businessId,
          startDate: moment().format(`${this.selectedDateRange1[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange1[1]}THH:mm:ssZ`),
        };

        await this.$store
          .dispatch(`business/sendNonConformanceSummaryReport`, data)
          .then((res) => {
            this.toast("success", res.message);
          })
          .catch((err) => {
            this.toast("error", err.message);
          });

        this.sendReportLoading = false;
      }
    },
  },
  computed: {
    tableData() {
      if (this.documents) {
        return this.parseData(this.documents);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped lang="scss">
.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>
