<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="w-full h-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
        <font-awesome-icon
          icon="info-circle"
          size="2x"
          :style="{ color: theme.primaryColor }"
        />
        <strong class="ml-1 text-sm font-semibold uppercase"
          >Search a driver compliance report.</strong
        >
      </div>
      <div
        v-if="isLoading"
        class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
      >
        <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400" />
        <h6 class="mt-4">Loading...</h6>
      </div>
    </div>

    <div v-if="isSuccess" class="w-full mt-5 compliance-tabs-wrapper">
      <div class="w-full p-5 border border-gray-200 rounded">
        <h4 class="mb-1 font-bold text-orange-primary">
          Driver Compliance Report
        </h4>
        <table class="w-full">
          <thead>
            <tr class="text-xs text-center text-white border bg-blue">
              <th scope="col" colspan="7" class="px-3 py-1">
                {{ reportData.businessName }}
              </th>
            </tr>
            <tr class="text-xs text-left text-white border bg-blue">
              <th scope="col" class="px-3 py-1">Fatigue Plan</th>
              <th scope="col" class="px-3 py-1">Number Of Drivers</th>
              <th scope="col" class="px-3 py-1">
                Number of driver added in period
              </th>
              <th scope="col" class="px-3 py-1">Number of Calendar Days</th>
              <th scope="col" class="px-3 py-1">
                Potential Non Conformance Identified
              </th>
              <th scope="col" class="px-3 py-1">Total Work</th>
              <th scope="col" class="px-3 py-1">Total Rest</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border">
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                Standard Solo
              </th>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-l border-r"
              >
                {{ reportData.fatigueData.standardSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.driversAdded.standardSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.calendarDay.standardSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.breachCount.standardSoloDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-r"
              >
                <p>{{ reportData.totalWork.standardSoloDrivers }}</p>
                <small>Hours</small>
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-r"
              >
                <p>{{ reportData.totalRest.standardSoloDrivers }}</p>
                <small>Hours</small>
              </td>
            </tr>
            <tr class="border">
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                BFM Solo
              </th>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-l border-r"
              >
                {{ reportData.fatigueData.bfmSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.driversAdded.bfmSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.calendarDay.bfmSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.breachCount.bfmSoloDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-r"
              >
                <p>{{ reportData.totalWork.bfmSoloDrivers }}</p>
                <small>Hours</small>
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-r"
              >
                <p>{{ reportData.totalRest.bfmSoloDrivers }}</p>
                <small>Hours</small>
              </td>
            </tr>
            <tr class="border">
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                Standard 2 Up
              </th>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-l border-r"
              >
                {{ reportData.fatigueData.standard2upDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.driversAdded.standard2upDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.calendarDay.standard2upDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.breachCount.standard2upDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-r"
              >
                <p>{{ reportData.totalWork.standard2upDrivers }}</p>
                <small>Hours</small>
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-r"
              >
                <p>{{ reportData.totalRest.standard2upDrivers }}</p>
                <small>Hours</small>
              </td>
            </tr>
            <tr class="border">
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                BFM 2 Up
              </th>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-l border-r"
              >
                {{ reportData.fatigueData.bfm2upDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.driversAdded.bfm2upDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.calendarDay.bfm2upDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.breachCount.bfm2upDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-b border-r"
              >
                <p>{{ reportData.totalWork.bfm2upDrivers }}</p>
                <small>Hours</small>
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-b border-r"
              >
                <p>{{ reportData.totalRest.bfm2upDrivers }}</p>
                <small>Hours</small>
              </td>
            </tr>
            <tr class="border">
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                Standard Bus
              </th>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-l border-r"
              >
                {{ reportData.fatigueData.standardBusDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.driversAdded.standardBusDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.calendarDay.standardBusDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.breachCount.standardBusDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-b border-r"
              >
                <p>{{ reportData.totalWork.standardBusDrivers }}</p>
                <small>Hours</small>
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-b border-r"
              >
                <p>{{ reportData.totalRest.standardBusDrivers }}</p>
                <small>Hours</small>
              </td>
            </tr>
            <tr class="border">
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                AFM
              </th>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-l border-r"
              >
                {{ reportData.fatigueData.afmDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.driversAdded.afmDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.calendarDay.afmDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r"
              >
                {{ reportData.breachCount.afmDrivers }}
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-b border-r"
              >
                <p>{{ reportData.totalWork.afmDrivers }}</p>
                <small>Hours</small>
              </td>
              <td
                class="px-3 py-2 text-sm leading-4 text-center bg-gray-300 border-b border-r"
              >
                <p>{{ reportData.totalRest.afmDrivers }}</p>
                <small>Hours</small>
              </td>
            </tr>
          </tbody>
        </table>

        <h4 class="mt-5 mb-1 font-bold text-orange-primary">
          Driver Information
        </h4>
        <table class="w-full mb-5">
          <thead>
            <tr class="text-xs text-left text-white border bg-blue">
              <th scope="col" class="px-3 py-1">Driver Name</th>
              <th scope="col" class="px-3 py-1">Standard Solo</th>
              <th scope="col" class="px-3 py-1">BFM Solo</th>
              <th scope="col" class="px-3 py-1">Standard 2 Up</th>
              <th scope="col" class="px-3 py-1">BFM 2 Up</th>
              <th scope="col" class="px-3 py-1">Standard Bus</th>
              <th scope="col" class="px-3 py-1">AFM</th>
              <th scope="col" class="px-3 py-1">Potential Non Conformance</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="border"
              v-for="(driver, index) in reportData.drivers"
              :key="index"
            >
              <th class="px-3 py-2 text-xs text-left text-white bg-blue">
                {{ driver.driverName }}
              </th>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.calendarDay.standardSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.calendarDay.bfmSoloDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.calendarDay.standard2upDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.calendarDay.bfm2upDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.calendarDay.standardBusDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.calendarDay.afmDrivers }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ driver.breach }}
              </td>
            </tr>
          </tbody>
        </table>

        <!--        <h4 class="mt-5 mb-1 font-bold text-orange-primary">Driver Information</h4>-->
        <!--        <table class="w-full mb-5" v-for="(driver, index) in reportData.drivers" :key="index">-->
        <!--          <thead>-->
        <!--          <tr class="text-xs text-center text-white border bg-blue">-->
        <!--            <th scope="col" colspan="4" class="px-3 py-1">-->
        <!--              {{ driver.driverName }}-->
        <!--            </th>-->
        <!--          </tr>-->
        <!--          <tr class="text-xs text-left text-white border bg-blue">-->
        <!--            <th scope="col" class="px-3 py-1">-->
        <!--              Fatigue Plan-->
        <!--            </th>-->
        <!--            <th scope="col" class="px-3 py-1">-->
        <!--              Number of Calendar Days-->
        <!--            </th>-->
        <!--            <th scope="col" class="px-3 py-1">-->
        <!--              Potential Non Conformance Identified-->
        <!--            </th>-->
        <!--          </tr>-->
        <!--          </thead>-->
        <!--          <tbody>-->
        <!--          <tr class="border">-->
        <!--            <th class="px-3 py-2 text-xs text-left text-white bg-blue">-->
        <!--              Standard Solo-->
        <!--            </th>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-l border-r">-->
        <!--              {{ driver.standardSoloCalendarDay }}-->
        <!--            </td>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">-->
        <!--              {{ driver.standardSoloBreachCount }}-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--          <tr class="border">-->
        <!--            <th class="px-3 py-2 text-xs text-left text-white bg-blue">-->
        <!--              BFM Solo-->
        <!--            </th>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-l border-r">-->
        <!--              {{ driver.bfmSoloCalendarDay }}-->
        <!--            </td>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">-->
        <!--              {{ driver.bfmSoloBreachCount }}-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--          <tr class="border">-->
        <!--            <th class="px-3 py-2 text-xs text-left text-white bg-blue">-->
        <!--              Standard 2 Up-->
        <!--            </th>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-l border-r">-->
        <!--              {{ driver.standard2upCalendarDay }}-->
        <!--            </td>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">-->
        <!--              {{ driver.standard2upBreachCount }}-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--          <tr class="border">-->
        <!--            <th class="px-3 py-2 text-xs text-left text-white bg-blue">-->
        <!--              BFM 2 Up-->
        <!--            </th>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-l border-r">-->
        <!--              {{ driver.bfm2upCalendarDay }}-->
        <!--            </td>-->
        <!--            <td class="px-3 py-2 text-sm text-center bg-gray-300 border-b border-r">-->
        <!--              {{ driver.bfm2upBreachCount }}-->
        <!--            </td>-->
        <!--          </tr>-->
        <!--          </tbody>-->
        <!--        </table>-->

        <h4 class="mt-5 mb-1 font-bold text-orange-primary">
          Additional Information
        </h4>
        <table class="w-full mb-5">
          <thead>
            <tr class="text-xs text-left text-white border bg-blue">
              <th scope="col" class="px-3 py-1">Total Fitness Declaration</th>
              <th scope="col" class="px-3 py-1">Passed Total</th>
              <th scope="col" class="px-3 py-1">Failed Total</th>
            </tr>
          </thead>
          <tbody>
            <tr class="border">
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.total }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.passed }}
              </td>
              <td class="px-3 py-2 text-sm text-center bg-gray-300 border-r">
                {{ reportData.failed }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "CompliancePeriod",
  props: {
    disabledBefore: String,
    disabledAfter: String,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      selectedDateRange: [],
      reportData: {},
    };
  },
  methods: {
    canSearch() {
      return (
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },

    async onSearch(selectedDateRange) {
      this.selectedDateRange = selectedDateRange;
      this.isLoading = true;
      this.isSuccess = false;

      const me = this.$store.getters[`account/me`];
      let businessId = me?.businessId;

      const requestData = {
        businessId: businessId,
        startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
        endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
      };

      this.reportData = await this.$store.dispatch(
        `report/getCompliancePeriodReport`,
        requestData
      );

      this.isLoading = false;
      this.isSuccess = true;
    },

    async onSubmitSendReport(recipientEmail) {
      if (this.canSearch() && recipientEmail) {
        this.$emit("sendReportloading", true);

        const me = this.$store.getters[`account/me`];
        const data = {
          recipientEmail: recipientEmail,
          entityId: me?.businessId,
          startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
        };

        await this.$store
          .dispatch(`business/sendCompliancePeriodReport`, data)
          .then((res) => {
            this.toast("success", res.message);
          })
          .catch((err) => {
            this.toast("error", err.message);
          });

        this.$emit("sendReportloading", false);
      }
    },

    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
