<template>
  <div>
    <div v-show="loading" class="loading centered">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-show="!loading" class="flex flex-row">
      <div class="w-1/3">
        <button @click="$router.go(-1)" class="btn btn-cancel flex flex-row">
          <StyledIconedLink class="w-1/4">
            <font-awesome-icon icon="arrow-left" />
          </StyledIconedLink>
          <div class="w-3/4 px-2">Back</div>
        </button>
      </div>
    </div>
    <div v-show="!loading" class="mt-5 px-10">
      <div>
        <FormulateForm v-model="payload" @submit="onSubmit">
          <div v-for="(vehicleType, index) in heavyVehicleTypes" :key="index">
            <Card
              :cardBackgroundColor="'white'"
              :cardWidth="'100%'"
              :minCardHeight="'300px'"
            >
              <template slot="cardContent">
                <label class="font-bold">
                  ({{ index + 1 }}) {{ vehicleType.name }}
                </label>
                <div class="flex flex-row">
                  <div class="mr-2">
                    <label class="font-weight-200 mr-1">Steer</label>
                    <input
                      type="checkbox"
                      v-model="payload[`checkbox_steer_${vehicleType.id}`]"
                    />
                  </div>
                  <div>
                    <label class="font-weight-200 mr-1">Drive</label>
                    <input
                      type="checkbox"
                      v-model="payload[`checkbox_drive_${vehicleType.id}`]"
                    />
                  </div>
                </div>
                <div class="flex flex-row">
                  <span>
                    <i
                      >Tick either 'Steer' or 'Drive' to add mass weight limits
                    </i>
                  </span>
                </div>
                <div
                  v-if="payload[`checkbox_steer_${vehicleType.id}`]"
                  class="flex flex-row"
                >
                  <FormulateInput
                    class="mr-2 w-1/3"
                    label="Steer GML"
                    type="number"
                    :name="`gmlSteer_${vehicleType.id}`"
                    :value="vehicleType[`gmlSteer_${vehicleType.id}`]"
                    step="0.01"
                  />
                  <FormulateInput
                    class="mr-2 w-1/3"
                    label="Steer CML"
                    type="number"
                    :name="`cmlSteer_${vehicleType.id}`"
                    :value="vehicleType[`cmlSteer_${vehicleType.id}`]"
                    step="0.01"
                  />
                  <FormulateInput
                    class="mr-2 w-1/3"
                    label="Steer HML"
                    type="number"
                    :name="`hmlSteer_${vehicleType.id}`"
                    :value="vehicleType[`hmlSteer_${vehicleType.id}`]"
                    step="0.01"
                  />
                </div>

                <div
                  v-if="payload[`checkbox_drive_${vehicleType.id}`]"
                  class="flex flex-row"
                >
                  <FormulateInput
                    class="mr-2 w-1/3"
                    label="Drive GML"
                    type="number"
                    :name="`gmlDrive_${vehicleType.id}`"
                    :value="vehicleType[`gmlDrive_${vehicleType.id}`]"
                    step="0.01"
                  />
                  <FormulateInput
                    class="mr-2 w-1/3"
                    label="Drive CML"
                    type="number"
                    :name="`cmlDrive_${vehicleType.id}`"
                    :value="vehicleType[`cmlDrive_${vehicleType.id}`]"
                    step="0.01"
                  />
                  <FormulateInput
                    class="mr-2 w-1/3"
                    label="Drive HML"
                    type="number"
                    :name="`hmlDrive_${vehicleType.id}`"
                    :value="vehicleType[`hmlDrive_${vehicleType.id}`]"
                    step="0.01"
                  />
                </div>

                <div class="flex flex-row justify-between mt-5">
                  <label class="font-bold"
                    >Add/Remove Axle Groups ({{
                      vehicleType.axleGroupArray?.length || 0
                    }})</label
                  >
                  <div>
                    <button
                      class="btn btn-primary mr-1"
                      @click.prevent="addRow(index)"
                      :style="{ backgroundColor: theme.secondaryColor }"
                      :disabled="vehicleType.axleGroupArray?.length > 3"
                    >
                      <font-awesome-icon icon="plus" />
                    </button>
                    <button
                      class="btn btn-primary ml-1"
                      @click.prevent="deleteRow(index)"
                      :style="{ backgroundColor: theme.secondaryColor }"
                      :disabled="vehicleType.axleGroupArray?.length <= 0"
                    >
                      <font-awesome-icon icon="minus" />
                    </button>
                  </div>
                </div>

                <div
                  v-for="(element, index) in vehicleType.axleGroupArray"
                  :key="index"
                >
                  <label class="font-bold">Axle Group # {{ index + 1 }}</label>
                  <div class="flex flex-row">
                    <FormulateInput
                      class="mr-2 w-1/3"
                      label="GML"
                      type="number"
                      :name="`gmlAxleGroup${index + 1}_${vehicleType.id}`"
                      :value="
                        vehicleType[
                          `gmlAxleGroup${index + 1}_${vehicleType.id}`
                        ]
                      "
                      step="0.01"
                    />
                    <FormulateInput
                      class="mr-2 w-1/3"
                      label="CML"
                      type="number"
                      :name="`cmlAxleGroup${index + 1}_${vehicleType.id}`"
                      :value="
                        vehicleType[
                          `cmlAxleGroup${index + 1}_${vehicleType.id}`
                        ]
                      "
                      step="0.01"
                    />
                    <FormulateInput
                      class="mr-2 w-1/3"
                      label="HML"
                      type="number"
                      :name="`hmlAxleGroup${index + 1}_${vehicleType.id}`"
                      :value="
                        vehicleType[
                          `hmlAxleGroup${index + 1}_${vehicleType.id}`
                        ]
                      "
                      step="0.01"
                    />
                  </div>
                </div>
              </template>
            </Card>
          </div>
          <button
            type="submit"
            class="btn btn-primary w-full mt-10"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Submit
          </button>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>
<script>
import { StyledIconedLink } from "@/styled-components";
import { mapGetters } from "vuex";
import { Card } from "@/components/commons";

export default {
  name: "Vehicle-Mass",
  components: {
    StyledIconedLink,
    Card,
  },
  data() {
    return {
      heavyVehicleTypes: [],
      payload: {},
      loading: false,
    };
  },

  async created() {
    await this.init();
    this.initCheckboxValues();
  },
  methods: {
    async init() {
      this.loading = true;

      try {
        const vehicleClass = await this.$store.dispatch(
          `vehicle/getVehicleClassSearch`,
          {
            searchStr: "HV",
          }
        );

        const response = await this.$store.dispatch(
          `vehicle/getVehicleTypeSearch`,
          { vehicleClassId: vehicleClass[0]._id }
        );
        this.heavyVehicleTypes = this.parseMassConfigs(response);
      } catch (error) {
        this.toast("error", "Something went wrong");
      }
      this.payload.checkbox_drive_624f101606c8b8fe94c633b4 = true;

      this.loading = false;
    },

    initCheckboxValues() {
      this.heavyVehicleTypes.forEach((vehicleType) => {
        this.$set(this.payload, `checkbox_steer_${vehicleType.id}`, true);
        this.$set(this.payload, `checkbox_drive_${vehicleType.id}`, true);
      });
    },

    addRow(index) {
      const targetVehicleType = this.heavyVehicleTypes[index];
      const currentAxleGroupArray = targetVehicleType.axleGroupArray || [];
      const updatedAxleGroupArray = Array.from({
        length: currentAxleGroupArray.length + 1,
      });
      this.heavyVehicleTypes[index].axleGroupArray = updatedAxleGroupArray;
    },

    deleteRow(index) {
      const targetVehicleType = this.heavyVehicleTypes[index];
      const currentAxleGroupArray = targetVehicleType.axleGroupArray || [];
      const updatedAxleGroupArray = Array.from({
        length: currentAxleGroupArray.length - 1,
      });
      this.heavyVehicleTypes[index].axleGroupArray = updatedAxleGroupArray;
    },

    async onSubmit() {
      this.loading = true;
      const parsedPayloadObject = {};
      Object.keys(this.payload).forEach((key) => {
        // Filtering out checkbox_steer_${vehicleTypeId} kind of values.
        if (!key.includes("checkbox")) {
          // Format of key is field_id. Example cmlDrive_1234
          const [field, id] = key.split("_");
          if (parsedPayloadObject[id]) {
            parsedPayloadObject[id] = {
              ...parsedPayloadObject[id],
              [`${field}`]: this.payload[key],
            };
          } else {
            parsedPayloadObject[id] = {
              [`${field}`]: this.payload[key],
            };
          }
        }
      });

      let parsedPayloadArray = [];
      Object.keys(parsedPayloadObject).forEach((key) => {
        parsedPayloadArray.push({
          id: key,
          ...parsedPayloadObject[key],
        });
      });
      parsedPayloadArray = this.parsedMassConfigsForSubmit(parsedPayloadArray);

      try {
        await this.$store.dispatch(
          `vehicle/updateVehicleTypeMassConfigs`,
          parsedPayloadArray
        );
        this.toast("success", "Default Mass Configs Updated");
      } catch (error) {
        this.toast("error", "Something went wrong");
      }

      this.loading = false;
    },

    parseMassConfigs(heavyVehicleTypes) {
      return heavyVehicleTypes.map((vehicleType) => {
        const { defaultMassConfigs, _id, name } = vehicleType;
        const {
          axleGroups = [],
          steer = {},
          drive = {},
        } = defaultMassConfigs || {};

        const id = _id.toString();

        const existingAxleGroupData = {};
        axleGroups.forEach((axleGroup, index) => {
          existingAxleGroupData[`gmlAxleGroup${index + 1}_${id}`] =
            axleGroup.gml;
          existingAxleGroupData[`cmlAxleGroup${index + 1}_${id}`] =
            axleGroup.cml;
          existingAxleGroupData[`hmlAxleGroup${index + 1}_${id}`] =
            axleGroup.hml;
        });

        const returnedObject = {
          id,
          name,
          ...existingAxleGroupData,
          axleGroupArray: Array.from({
            length: axleGroups.length,
          }),
        };

        returnedObject[`gmlSteer_${id}`] = steer?.gml;
        returnedObject[`cmlSteer_${id}`] = steer?.cml;
        returnedObject[`hmlSteer_${id}`] = steer?.hml;
        returnedObject[`gmlDrive_${id}`] = drive?.gml;
        returnedObject[`cmlDrive_${id}`] = drive?.cml;
        returnedObject[`hmlDrive_${id}`] = drive?.hml;

        return returnedObject;
      });
    },

    parsedMassConfigsForSubmit(parsedPayloadArray) {
      const result = [];
      parsedPayloadArray.forEach((payload) => {
        const {
          gmlSteer,
          cmlSteer,
          hmlSteer,
          gmlDrive,
          hmlDrive,
          cmlDrive,
          id,
        } = payload;

        const parsedAxleGroups = [];
        const { axleGroupArray } =
          this.heavyVehicleTypes.find((vehicleType) => vehicleType.id === id) ||
          {};
        axleGroupArray.forEach((element, index) => {
          parsedAxleGroups.push({
            gml: payload[`gmlAxleGroup${index + 1}`],
            cml: payload[`cmlAxleGroup${index + 1}`],
            hml: payload[`hmlAxleGroup${index + 1}`],
          });
        });
        const parsedSteer = {
          gml: gmlSteer || null,
          cml: cmlSteer || null,
          hml: hmlSteer || null,
        };

        const parsedDrive = {
          gml: gmlDrive || null,
          cml: cmlDrive || null,
          hml: hmlDrive || null,
        };
        result.push({
          steer: parsedSteer,
          drive: parsedDrive,
          axleGroups: parsedAxleGroups,
          id,
        });
      });
      return result;
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style>
.divider {
  border-top: 1px solid #ccc;
  margin: 10px 0; /* Adjust margin as needed */
}
</style>
