<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="w-full h-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
        <font-awesome-icon
          icon="info-circle"
          size="2x"
          :style="{ color: theme.primaryColor }"
        />
        <strong class="ml-1 text-sm font-semibold uppercase"
          >Search a compliance report.</strong
        >
      </div>
      <div
        v-if="isLoading"
        class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
      >
        <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400" />
        <h6 class="mt-4">Searching driver details...</h6>
      </div>
    </div>
    <div
      v-if="isSuccess"
      class="flex items-center w-full driver-info-container"
    >
      <div class="flex flex-1">
        <div class="flex-initial w-full driver-details">
          <span class="flex items-center font-semibold uppercase">
            <strong class="mr-1">{{ driverData.driverName || "N/A" }}</strong>
            <span
              class="px-2 mr-2 text-xs text-white rounded"
              :style="{ backgroundColor: theme.secondaryColor }"
              >{{
                driverData["fatiguePlan"] ||
                (driverData?.isNonEWD ? "NON-EWD" : "")
              }}</span
            >
          </span>
          <div class="grid max-w-lg grid-cols-2 mt-1 gap-x-4">
            <div class="inline text-sm text-gray-500">
              <span
                ><strong class="mr-1 text-xs">UDI:</strong
                >{{ driverData.udi || "N/A" }}</span
              >
            </div>
            <div class="inline text-sm text-gray-500">
              <span
                ><strong class="mr-1 text-xs">Vehicle:</strong
                >{{ driverData["vehicle"] || "N/A" }}</span
              >
            </div>
            <div class="inline text-sm text-gray-500">
              <span
                ><strong class="mr-1 text-xs">Licence #:</strong
                >{{ driverData.driversLicenseNumber || "N/A" }}</span
              >
            </div>
            <div class="inline text-sm text-gray-500">
              <span
                ><strong class="mr-1 text-xs">State of Licence:</strong
                >{{ driverData.driversLicenseState || "N/A" }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isSuccess" class="w-full mt-5 compliance-tabs-wrapper">
      <div class="flex items-center mb-3 text-sm">
        <div
          class="relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none"
          @click="toggle12HourFormat"
        >
          <input
            type="checkbox"
            v-model="use12HourFormat"
            name="toggle"
            class="absolute block w-6 h-6 bg-white border-4 border-gray-300 rounded-full appearance-none cursor-pointer toggle-checkbox"
          />
          <label
            for="toggle"
            class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label"
          ></label>
        </div>
        <label class="block text-sm font-medium">12-Hour Time</label>
      </div>
      <Tabs clickEvent @click="setTab" ref="childTabs">
        <Tab title="Graphical">
          <div class="mt-5 tab-content">
            <GraphicalSummary
              :data="graphData"
              :displayEndOfDay="showEndOfDay"
              id="graphicalSummary"
              ref="childGraphicalSummary"
              @toggleEndOfDay="toggleEndOfDay"
            />
          </div>
        </Tab>
        <Tab title="Events">
          <div class="mt-5 tab-content">
            <Event :data="eventData" />
          </div>
        </Tab>
        <Tab title="Breaches">
          <div class="mt-5 tab-content">
            <Breach :data="breachData" />
          </div>
        </Tab>
        <Tab title="Annotations">
          <div class="mt-5 tab-content">
            <Annotation :data="annotationData" />
          </div>
        </Tab>
        <Tab title="Logs">
          <div class="mt-5 tab-content">
            <Log
              :driver="driverData"
              :graphDetails="ewdDetails"
              :logs="logs"
              :reportName="'EWD'"
            />
          </div>
        </Tab>
      </Tabs>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { getStateSubInAddress } from "@/_helper";
import Tab from "@/components/commons/tab/Tab";
import Tabs from "@/components/commons/tab/Tabs";
import LogmasterGraph from "@logmaster/logmaster-graph";
import LocationData from "@/assets/location/location.json";
import GraphicalSummary from "./tabs/GraphicalSummary";
import Event from "./tabs/Event";
import Breach from "./tabs/Breach";
import Annotation from "./tabs/Annotation";
import Log from "./tabs/Log";

export default {
  name: "Compliance",
  components: { Tabs, Tab, GraphicalSummary, Event, Breach, Annotation, Log },
  props: {
    selectedDateRange: Array,
    selectedDate: String,
    selectedDriver: Object,
    disabledBefore: String,
    disabledAfter: String,
  },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      logs: {
        recordKeeper: [],
        base: [],
        accreditation: [],
        device: [],
        license: [],
      },
      ewdDetails: {
        approvalHolder: "Logmaster Pty Ltd",
        approvalNumber: process.env.VUE_APP_APPROVAL_NUMBER,
        systemName: "Logmaster Australia",
        systemNumber: "N/A",
      },
      elGraph: null,
      activeTab: null,
      driverData: {},
      graphData: "",
      eventData: [],
      breachData: [],
      annotationData: [],
      breaches: [],
      endOfDays: [],
      includeBreach: false,
      showEndOfDay: false,
      use12HourFormat: false,
      previousEvent: [],
      dateTimeFormat: "YYYY-MM-DDTHH:mm:ssZ",
      previousDateTimeFormat: "YYYY-MM-DDTHH:mm:ssZ",
      me: null,
    };
  },
  mounted() {
    console.log("ev", process.env);
    this.me = this.$store.getters[`account/me`];
  },
  methods: {
    toggleEndOfDay(showEndOfDay) {
      this.showEndOfDay = showEndOfDay;

      const graphWrapper = document.getElementById("graphicalSummary");
      if (this.showEndOfDay) {
        this.elGraph.displayEndOfDay(graphWrapper, this.endOfDays);
      } else {
        const svgs = graphWrapper.querySelectorAll(".time-col svg");
        svgs.forEach((svg) => svg.remove());
      }
    },
    toggle12HourFormat() {
      this.previousDateTimeFormat = this.dateTimeFormat;
      this.use12HourFormat = !this.use12HourFormat;
      this.dateTimeFormat = this.use12HourFormat
        ? "YYYY-MM-DDThh:mm:ssAZ"
        : "YYYY-MM-DDTHH:mm:ssZ";

      this.getGraph(this.previousEvent);
      this.formatEvent(this.eventData);
      this.formatBreach(this.breachData);
      this.formatLogs(this.logs);
    },
    setTab(tab) {
      this.activeTab = tab;
      if (tab == "Graphical") this.generateLabel();
      this.$emit("updateActiveTab", tab);
    },
    canSearch() {
      return (
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    goToLogs() {
      this.activeTab = "Logs";
      this.$emit("updateActiveTab", "Logs");
      this.$refs.childTabs.selectTab(4);
    },
    formatEvent(events) {
      let formattedEvents = [];
      events.forEach((event) => {
        if (!_.isEmpty(event["location"]["address"])) {
          event["suburb"] = event["location"]["address"];
          event["locationAddress"] = event["suburb"];
        } else {
          const location = event["location"]["formatted_address"];
          const rawLocation = getStateSubInAddress(location);
          event["suburb"] = !_.isEmpty(rawLocation)
            ? rawLocation["suburb"]
            : null;
          event["locationAddress"] = !_.isEmpty(event["suburb"])
            ? event["suburb"]
            : location;
        }

        let tampered = [];
        if (
          _.isBoolean(event["location"]["gpsTampered"]) &&
          event["location"]["gpsTampered"]
        )
          tampered.push("GPS off");
        if (
          _.isBoolean(event["location"]["mockTampered"]) &&
          event["location"]["mockTampered"]
        )
          tampered.push("Mock GPS");
        if (_.isBoolean(event["timeTampered"]) && event["timeTampered"])
          tampered.push("Time");
        event["tampered"] = tampered.join(", ");

        if (_.isEmpty(event["origin"])) event["origin"] = "EWD";
        const timezone =
          !_.isEmpty(event["timezone"]) && event["timezone"].length > 1
            ? event["timezone"].split(" ")[1]
            : event["timezone"];
        event["offset"] = timezone ? timezone : "+10:00";
        event["timeOfActivity"] = moment(
          event.startTimeWithTimezone,
          this.previousDateTimeFormat
        )
          .utcOffset(event["offset"])
          .format(`${this.use12HourFormat ? "hh:mmA" : "HH:mm"}`);

        event["originalStartTime"] = moment(
          event["originalStartTime"],
          this.previousDateTimeFormat
        )
          .utcOffset(event["offset"])
          .format(this.dateTimeFormat);
        formattedEvents.push(event);

        event.metadata.forEach((metadata) => {
          const metadataObject = metadata["metadata"];
          if (
            metadataObject &&
            !_.isEmpty(metadataObject["modified-link-profile"])
          ) {
            const linkProfile = metadataObject["modified-link-profile"];
            let accreditationLog = null;
            if (!_.isEmpty(linkProfile["BFMNumber"])) {
              accreditationLog = {
                accreditationType: "BFMNumber",
                accreditationNumber: linkProfile["BFMNumber"],
              };
            }
            if (!_.isEmpty(linkProfile["AFMNumber"])) {
              accreditationLog = {
                accreditationType: "AFMNumber",
                accreditationNumber: linkProfile["AFMNumber"],
              };
            }

            const offset = events[events.length - 1]["timezone"].split(" ")[1];
            metadata.createdAt =
              events.length > 0
                ? moment(events[0]?.startTimeWithTimezone, this.dateTimeFormat)
                    .utcOffset(offset)
                    .format(this.dateTimeFormat)
                : moment(metadata.createdAt, this.dateTimeFormat)
                    .utcOffset(offset)
                    .format(this.dateTimeFormat);
            const accreditationIndex = _.findIndex(
              this.logs.accreditation,
              (o) => _.isMatch(o, accreditationLog)
            );
            if (accreditationIndex == -1)
              this.logs.accreditation.push({
                ...accreditationLog,
                effectiveFrom: metadata.createdAt,
              });

            const recordKeeperLog = {
              location: linkProfile["recordKeeperAddress"],
            };
            const recordKeeperIndex = _.findIndex(this.logs.recordKeeper, (o) =>
              _.isMatch(o, recordKeeperLog)
            );
            if (recordKeeperIndex == -1)
              this.logs.recordKeeper.push({
                ...recordKeeperLog,
                effectiveFrom: metadata.createdAt,
              });

            const baseLocationLog = {
              location: linkProfile["baseLocation"],
              timeZone: linkProfile["baseTimeZone"],
            };
            const baseLocationIndex = _.findIndex(this.logs.base, (o) =>
              _.isMatch(o, baseLocationLog)
            );
            if (baseLocationIndex == -1)
              this.logs.base.push({
                ...baseLocationLog,
                effectiveFrom: metadata.createdAt,
              });
          }
        });
      });

      formattedEvents = _.orderBy(
        formattedEvents,
        ["startTimeWithTimezone", "originalStartTime"],
        ["desc", "desc"]
      );
      return formattedEvents;
    },
    formatBreach(breaches) {
      breaches.forEach((breach) => {
        breach.time =
          typeof breach.time == "object" ? breach.time[0] : breach.time;

        breach.time = moment(breach.time, this.previousDateTimeFormat)
          .utcOffset(breach.timezone)
          .format(this.dateTimeFormat);

        breach.startPoint = moment(
          breach.startPoint,
          this.previousDateTimeFormat
        )
          .utcOffset(breach.timezone)
          .format(this.dateTimeFormat);
      });

      return breaches;
    },
    formatAnnotation(annotations) {
      annotations.forEach((annotation) => {
        const annotationOffset = moment.parseZone(annotation.flag).utcOffset();
        annotation.flag = moment(annotation.time, this.previousDateTimeFormat)
          .utcOffset(annotationOffset)
          .format(this.dateTimeFormat);
        annotation.timeOfIntercept = moment(
          annotation.timeOfIntercept,
          this.previousDateTimeFormat
        )
          .utcOffset(annotationOffset)
          .format(this.dateTimeFormat);
      });
      return annotations;
    },
    formatLogs(logs) {
      let { accreditation, base, device, license, recordKeeper } = logs;
      accreditation.forEach((data) => {
        const offset = moment.parseZone(data.effectiveFrom).utcOffset();
        data.effectiveFrom = moment(
          data.effectiveFrom,
          this.previousDateTimeFormat
        )
          .utcOffset(offset)
          .format(this.dateTimeFormat);
      });
      base.forEach((data) => {
        const offset = moment.parseZone(data.effectiveFrom).utcOffset();
        data.effectiveFrom = moment(
          data.effectiveFrom,
          this.previousDateTimeFormat
        )
          .utcOffset(offset)
          .format(this.dateTimeFormat);
      });
      device.forEach((data) => {
        const offset = moment.parseZone(data.createdAt).utcOffset();
        data.createdAt = moment(data.createdAt, this.previousDateTimeFormat)
          .utcOffset(offset)
          .format(this.dateTimeFormat);
      });
      license.forEach((data) => {
        const offset = moment.parseZone(data.effectiveFrom).utcOffset();
        data.effectiveFrom = moment(
          data.effectiveFrom,
          this.previousDateTimeFormat
        )
          .utcOffset(offset)
          .format(this.dateTimeFormat);
      });
      recordKeeper.forEach((data) => {
        const offset = moment.parseZone(data.effectiveFrom).utcOffset();
        data.effectiveFrom = moment(
          data.effectiveFrom,
          this.previousDateTimeFormat
        )
          .utcOffset(offset)
          .format(this.dateTimeFormat);
      });

      this.logs = {
        accreditation,
        base,
        device,
        license,
        recordKeeper,
      };
    },
    async getGraph(previousEvent = null) {
      let filteredEvents = this.eventData;
      filteredEvents = filteredEvents.filter(
        (event) => event?.status && event?.status.toLowerCase() != "deleted"
      );
      if (!_.isEmpty(filteredEvents) && !previousEvent)
        previousEvent = filteredEvents[0];

      const breaches = this.breachData.map((breach) => {
        return moment(breach.time, this.previousDateTimeFormat)
          .utcOffset(breach.timezone)
          .format("YYYY-MM-DDTHH:mm:ssZ");
      });

      const activityData = {
        events: filteredEvents.reverse(),
        breaches,
        annotations: this.annotationData,
        displayOdometer: true,
        is12HourFormat: this.use12HourFormat,
      };

      this.elGraph = new LogmasterGraph(LocationData);
      this.graphData = this.elGraph.output(
        activityData,
        this.selectedDate,
        previousEvent
      );

      if (this.activeTab == "Graphical") this.generateLabel();
    },
    generateLabel() {
      setTimeout(() => {
        const graphWrapper = document.getElementById("graphicalSummary");
        this.elGraph.generateEventLabel(graphWrapper, -2, -2, -2);

        if (this.showEndOfDay) {
          this.elGraph.displayEndOfDay(graphWrapper, this.endOfDays);
        }
      }, 100);
    },
    async onSearch(
      selectedDriver = null,
      selectedDate = null,
      activeTab = "Graphical"
    ) {
      this.isLoading = true;
      this.isSuccess = false;

      const selectedDriverId = selectedDriver.driverId;
      const requestData = {
        driverId: selectedDriverId,
        from: moment(selectedDate, "YYYY-MM-DD").format(),
        to: moment(selectedDate, "YYYY-MM-DD").format(),
      };

      let eventRequestData = _.cloneDeep(requestData);
      if (this.me?.business) {
        const { results } = await this.$store.dispatch(
          `business/findBusinessAFMAccess`,
          {
            businessId: this.me?.business?._id,
            query: {
              limit: 50,
              filter: { isActive: true },
            },
          }
        );

        if (results && results.length > 0) {
          const fatigueTableFilter = results.map((x) => {
            return {
              fatigueTable: { $regex: `^${x.afmNumber.toLowerCase()}` },
            };
          });

          eventRequestData.filter = {
            driverId: selectedDriverId,
            $or: [...fatigueTableFilter, { fatigueTable: { $exists: false } }],
          };
        } else {
          eventRequestData.filter = {
            fatiguePlan: { $ne: "afm" },
            driverId: selectedDriverId,
          };
        }
      }

      await Promise.all([
        this.$store.dispatch(`business/getEventByDateRange`, eventRequestData),
        this.$store.dispatch(`business/getAnnotationByDateRange`, requestData),
        this.$store.dispatch(`business/getContractByDriver`, selectedDriverId),
        this.$store.dispatch(`business/getDeviceLogByDateRange`, requestData),
        this.$store.dispatch(`business/getPreviousEvent`, requestData),
        this.$store.dispatch(`report/getBreachesByDateRange`, {
          ...requestData,
          driverId: selectedDriverId,
          filter: { driverId: selectedDriverId },
        }),
        this.$store.dispatch(`business/getEndOfDayByDateRange`, requestData),
      ]).then((data) => {
        this.previousEvent = data[4];
        this.eventData = this.formatEvent(data[0]);
        this.endOfDays = data[6].map((x) => x.date);
        this.breachData = this.formatBreach(data[5]);
        this.breaches = this.breachData.map((breach) => {
          breach.time =
            typeof breach.time == "object" ? breach.time[0] : breach.time;
          return breach;
        });

        this.annotationData = data[1];
        const drivers = data[2].results;
        if (drivers && !_.isEmpty(drivers[0])) {
          this.driverData = drivers[0]["driver"];
          this.driverData["isNonEWD"] =
            drivers[0]?.status == "linked-non-ewd" ? true : false;
        } else {
          const me = this.$store.getters[`account/me`];
          const roles = this.$store.getters[`account/roleNames`];
          if (me.role.name === "driver" || roles.includes("driver")) {
            this.driverData = me.driver;
            this.driverData["isNonEWD"] = false;
          }
        }

        if (!_.isEmpty(this.eventData) || !_.isNil(this.eventData)) {
          this.driverData["vehicle"] = this.eventData[0]?.vehicle;
          this.driverData["fatiguePlan"] = this.eventData[0]?.fatiguePlan;
        }

        this.driverData["driverDateOfBirth"] = moment(
          this.driverData["driverDateOfBirth"]
        ).format("YYYY-MM-DD");

        this.logs.device = [];
        const deviceLogs = data[3];
        deviceLogs.forEach((device) => {
          const offset = !_.isEmpty(this.eventData)
            ? this.eventData[this.eventData.length - 1]["timezone"].split(
                " "
              )[1]
            : null;
          device.createdAt = !_.isEmpty(offset)
            ? moment(device.createdAt, this.dateTimeFormat)
                .utcOffset(offset)
                .format(this.dateTimeFormat)
            : moment(device.createdAt, this.dateTimeFormat).format(
                this.dateTimeFormat
              );
          if (!_.isEmpty(this.logs.device)) {
            const previousLog = this.logs.device[this.logs.device.length - 1];
            const previousLogObj = {
              maker: previousLog.maker,
              model: previousLog.model,
              os: previousLog.os,
            };
            const currentLogObj = {
              maker: device.maker,
              model: device.model,
              os: device.os,
            };
            if (
              JSON.stringify(previousLogObj) != JSON.stringify(currentLogObj)
            ) {
              this.logs.device.push(device);
            }
          } else {
            this.logs.device.push(device);
          }
        });
        const lastDeviceLog = _.first(
          _.orderBy(deviceLogs, ["createdAt"], ["desc"])
        );
        if (lastDeviceLog && !_.isEmpty(lastDeviceLog["app_ewd"])) {
          this.ewdDetails.approvalHolder = !_.isEmpty(
            lastDeviceLog["app_ewd"]["holder"]
          )
            ? lastDeviceLog["app_ewd"]["holder"]
            : this.ewdDetails.approvalHolder;
          this.ewdDetails.approvalNumber = !_.isEmpty(
            lastDeviceLog["app_ewd"]["approval_number"]
          )
            ? lastDeviceLog["app_ewd"]["approval_number"]
            : this.ewdDetails.approvalNumber;
          this.ewdDetails.systemName = !_.isEmpty(
            lastDeviceLog["app_ewd"]["system_name"]
          )
            ? lastDeviceLog["app_ewd"]["system_name"]
            : this.ewdDetails.systemName;
          this.ewdDetails.systemNumber = !_.isEmpty(
            lastDeviceLog["app_version"]
          )
            ? lastDeviceLog["app_version"]
            : this.ewdDetails.systemNumber;
        }
      });

      this.isLoading = false;
      this.isSuccess = true;
      await this.$nextTick(() => {
        if (this.$refs.childGraphicalSummary) {
          // Set graph
          this.getGraph(this.previousEvent);
        }

        if (
          this.$refs.childTabs &&
          typeof this.$refs.childTabs.selectTab === "function"
        ) {
          let tabIndex = 0;
          switch (activeTab) {
            case "Events":
              tabIndex = 1;
              break;
            case "Breaches":
              tabIndex = 2;
              break;
            case "Annotations":
              tabIndex = 3;
              break;
            case "Logs":
              tabIndex = 4;
              break;
            default:
              tabIndex = 0;
              break;
          }
          this.$refs.childTabs.selectTab(tabIndex);
        }
      });
    },
    async downdloadReport(selectedDriverId = null, selectedDate = null) {
      const data = {
        driverId: selectedDriverId,
        startDate: moment().format(`${selectedDate}THH:mm:ssZ`),
        endDate: moment().format(`${selectedDate}THH:mm:ssZ`),
      };
      await this.$store
        .dispatch(`business/downdloadReport`, data)
        .then((res) => {
          const url = URL.createObjectURL(res.pdf);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.filename);
          document.body.append(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });
      this.$emit("sendReportloading", false);
    },
    async onSubmitSendReport(recipientEmail, includeBreach) {
      if (this.canSearch() && recipientEmail) {
        this.$emit("sendReportloading", true);
        const breach = includeBreach ? { includeBreach: includeBreach } : null;
        const data = {
          siteId: this.selectedDriver?.siteId,
          entityId: this.me?.business?._id,
          recipientEmail: recipientEmail,
          driverId: this.selectedDriver.driverId,
          startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
          ...breach,
        };
        await this.$store
          .dispatch(`business/sendReport`, data)
          .then((res) => {
            this.toast("success", res.message);
          })
          .catch((err) => {
            this.toast("error", err.message);
          });
        this.$emit("sendReportloading", false);
      }
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
