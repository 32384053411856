<template>
  <div v-if="driverFitness">
    <div v-if="loading" class="loading centered">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" id="printCovered" class="mb-16">
      <div class="text-center mb-8">
        <div class="flex text-right justify-end">
          <button id="printBtn" class="h-8 pl-2 pr-4 m-2 text-sm transition-colors duration-150 bg-blue-300 text-white hover:bg-blue-200 rounded-md focus:shadow-outline"
            @click="printThis('printCovered')">
            <font-awesome-icon
              icon="print"
              class="my-auto mx-1.5 text-sm"/>
              Print
          </button>
        </div>
        <div class="font-bold text-black-primary text-2xl mt-3">
          {{driver?.driverName}}
        </div>
        <div
          class="font-bold text-xs"
          :class="[(isPassed) ? 'text-green-500' : 'text-red-500']"
        >
          {{isPassed ? 'FIT' : 'UNFIT'}} TO DRIVE
        </div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{driverFitness.RiskAssessment}}&nbsp;
          <span v-if="!riskAssessmentHasIssue">
            <font-awesome-icon
              icon="check-circle"
              class="text-1xl text-green-400"
            />
          </span>
          <span v-else>
            <font-awesome-icon
              icon="times-circle"
              class="text-1xl text-red-600"
            />
          </span>
        </div>
        <div class="text-xs">Risk Assessment Classification</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{driverFitness.AlertorSleepy}}&nbsp;
          <span v-if="!alertorSleepyHasIssue">
            <font-awesome-icon
              icon="check-circle"
              class="text-1xl text-green-400"
            />
          </span>
          <span v-else>
            <font-awesome-icon
              icon="times-circle"
              class="text-1xl text-red-600"
            />
          </span>
        </div>
        <div class="text-xs">How alert or sleepy are you?</div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="option-pill-view">
          <div :class="[convertBool(driverFitness.Returning) ? 'bg-red-400 text-white' : 'bg-gray-200']">Yes</div>
          <div :class="[!convertBool(driverFitness.Returning) ? 'bg-green-400 text-white' : 'bg-gray-200']">No</div>
        </div>
        <div class="text-xs">
          Are you returning from leave/time off driving activities?
        </div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="option-pill-view">
          <div :class="[convertBool(driverFitness.MedicalIssues)  ? 'bg-red-400 text-white' : 'bg-gray-200']">Yes</div>
          <div :class="[!convertBool(driverFitness.MedicalIssues)  ? 'bg-green-400 text-white' : 'bg-gray-200']">No</div>
        </div>
        <div class="text-xs">
          Are you feeling unwell, or do you have any unmanaged medical issue?
        </div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="option-pill-view">
          <div :class="[convertBool(driverFitness.AlchoholorDrugs) ? 'bg-green-400 text-white' : 'bg-gray-200']">Yes</div>
          <div :class="[!convertBool(driverFitness.AlchoholorDrugs)? 'bg-red-400 text-white' : 'bg-gray-200']">No</div>
        </div>
        <div class="text-xs">
          Do you have zero blood alcohol content and not impaired by drugs?
        </div>
      </div>
      <div class="mb-2 flex items-center">
        <div class="option-pill-view">
          <div :class="[convertBool(driverFitness.LicenceValidtoDriver) ? 'bg-green-400 text-white' : 'bg-gray-200']">Yes</div>
          <div :class="[!convertBool(driverFitness.LicenceValidtoDriver) ? 'bg-red-400 text-white' : 'bg-gray-200']">No</div>
        </div>
        <div class="text-xs">
          Do you have a current licence which is valid to drive this vehicle?
        </div>
      </div>
      <Collapse :isCollapsed="collapsed">
        <template slot="collapseHeader">
          <div class="flex items-center font-bold">
            <div class="flex items-center">
              <div class="flex items-center">
                <div class="icon-container mr-2 bg-gray-700">
                  <font-awesome-icon
                    icon="file"
                    style="height: 15px; width: 15px"
                  />
                </div>
                <div class="flex flex-col">
                  <div class="flex-auto">Fitness Declaration Consent</div>
                </div>
              </div>
            </div>
            <div class="ml-auto cursor-pointer">
              <font-awesome-icon
                :icon="collapsed ? 'caret-down' : 'caret-right'"
                class="text-2xl"
                @click="onCollapsed()"
              />
            </div>
          </div>
        </template>
        <template #default="{props}">
          <Transition name="slide">
            <div v-if="props.collapsed" class="items-center mt-5 px-5">
              <div class="mb-3 text-xs">
                You are about to submit your fit to drive declaration for the day.
                Once you have submitted it, it cannot be corrected.
              </div>
              <div class="mb-3 text-xs">
                By submitting your fit to drive declaration, you are declaring
                that the entries:
              </div>
              <ul class="list-disc mb-3 ml-8 text-xs">
                <li>were made by you personally</li>
                <li>are not false or misleading</li>
              </ul>
              <div class="mb-3 text-xs">
                Confirm that the information in the fit to drive form is correct,
                [OR], if you are not sure that the information in the fit to drive
                form is correct, correct it now prior to submitting your fit to
                drive declaration record.
              </div>
              <div class="mb-3 text-xs">
                Entries may be used in legal proceedings for an offence against
                the Heavy Vehicle National Law Act 2012, or another law of a State
                or the Commonwealth of Australia. Making a false or misleading
                entry in an electronic work diary is an offence punishable by a
                fine of over $10,000.
              </div><br/>
            </div>
          </Transition>
        </template>
      </Collapse>

      <div class="items-center mt-5 px-5">
        <div class="w-full text-left items-start pt-7 mr-2">
          <span class="pl-6 text-sm">{{ cleanDateFormat(driverFitness?.date) }}</span>
          <div class="w-40 border-t mt-1 pt-1 text-center text-xs">Date submitted</div>
        </div>
        <div class="w-full text-right flex flex-col items-end">
          <img class="fitness-signature" :src="driverFitness.signature" alt="signature"/>
          <div class="border-t mt-3 text-center">Signature <b>{{driver.driverName}}</b></div>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { Collapse } from "@/components/commons"
import { cleanDateFormat, print } from '@/_helper'

export default {
  name: "Fitness-Declaration-Details-View",
  components: { Collapse },
  props: {
    activeFitnessDeclaration: {
      type: Object,
      default: () => {}
    },
  },
  watch: {
    activeFitnessDeclaration: {
      deep: true,
      handler() {
        this.setActiveFitnessDeclaration()
      }
    }
  },
  created() {
    this.setActiveFitnessDeclaration();
    setTimeout(() => this.loading = false, 400)
  },
  data() {
    return {
      loading: true,
      driver: {},
      driverFitness: {},
      collapsed: false,
      isPassed: false,
      alertorSleepyHasIssue: false,
      riskAssessmentHasIssue: false
    };
  },
  methods: {
    setActiveFitnessDeclaration(){
      this.driver = this.activeFitnessDeclaration?.driver
      this.driverFitness = this.activeFitnessDeclaration?.savedData
      this.isPassed = this.activeFitnessDeclaration?.isPassed

      let extremelySleepyIsue =[], lessThanFiveIssue =[];

      for (const [key, value] of Object.entries(this.driverFitness)){
        if(value.substring(0,16) == 'Extremely sleepy') extremelySleepyIsue.push(key);
        else if(value.substring(0,13) == 'Less than 5.0') lessThanFiveIssue.push(key);
      }

      if(extremelySleepyIsue.length > 0){
        this.alertorSleepyHasIssue = true;
      }

      if(lessThanFiveIssue.length > 0){
        this.riskAssessmentHasIssue = true;
      }
    },
    onCollapsed() {
      this.collapsed = !this.collapsed;
    },
    convertBool(option){
      return ('Yes' == option);
    },
    printThis(toPrintId) {
      return print(toPrintId)
    },
    cleanDateFormat(date){
      if(!date) {
        return "N/A";
      }
      return cleanDateFormat(date, true, true)
    }
  },
}
</script>
<style>
  .fitness-signature{
    width: 200px!important;
    height: 90px!important;
    background: rgb(68, 63, 63);
    border: 3px solid;
    margin-top: 10px;
  }

  @media print {
    #printCovered {
      display:flex;
      justify-content:center;
      align-items:center;
      height:100%;
    }

    body { -webkit-print-color-adjust: exact }

    #printBtn {
      display: none;
    }
  }
</style>