<template>
  <div class="mb-16">
    <FormulateForm v-model="values" :schema="schema" @submit="onSubmit">
      <div class="items-center justify-between">
        <button
          type="submit"
          class="btn btn-primary w-50"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Next
        </button>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { convertAddressObject } from "@/_helper";
import schema from "./business-profile-create-schema";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  name: "Add-Business-Profile-Form",
  props: {
    msg: String,
    preData: null,
  },
  data() {
    this.$emit("schema", schema);
    return {
      driverId: null,
      newDriver: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
    };
  },
  mounted() {
    if (this.preData?.steps) {
      this.values = this.preData.steps?.businessProfile || null;
      this.interChangingData = this.preData;
    }
    if (this.postData?.steps) {
      this.values = this.postData.steps?.businessProfile || null;
      this.interChangingData = this.postData;
    }

    if (this.preData) {
      if (this.preData["linkDriver"]) {
        this.driverId = this.preData["linkDriver"];
      }
      if (this.preData["newDriver"]) {
        this.newDriver = this.preData["newDriver"];
      }
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      let newContract = JSON.parse(JSON.stringify(this.values));

      newContract["profile"] = newContract["profile"][0];
      newContract["profile"]["recordKeeperAddress"] = convertAddressObject(
        newContract["profile"]["recordKeeperAddress"][0]
      );
      newContract["profile"]["baseLocation"] = convertAddressObject(
        newContract["profile"]["baseLocation"][0]
      );

      const driverDetails = {
        driver: this.interChangingData?.steps.driverDetails,
        businessProfile: newContract,
      };

      const preData = {
        ...this.interChangingData,
        ...{ driverDetails: driverDetails },
        steps: {
          ...this.interChangingData?.steps,
          ...{ businessProfile: newContract },
        },
      };
      this.$emit("nextStep", preData);
    },

    async submitNewContract(data) {
      data["from"] = "business";
      data["startDate"] = moment();
      data["profile"]["BFMSolo"] = data["profile"]["BFMSolo"] == true;
      data["profile"]["BFM2up"] = data["profile"]["BFM2up"] == true;
      await this.$store
        .dispatch(`business/createContract`, data)
        .then((contact) => {
          this.loading = false;
          if (contact) {
            location.reload();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error?.message;
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
</style>
