import { AI } from "@/api";

export default {
  // eslint-disable-next-line no-empty-pattern
  async generateAIResponse({}, payload) {
    try {
      const response = await AI.generateAIResponse(payload);
      return response.data.data;
    } catch (e) {
      console.log("error:", e);
      throw e;
    }
  },
};
