export default [
    {
      name:'Reseller Navigation',
      items:[
        {
          name:'Dashboard',
          link:'/reseller/'
        },
        {
          name:'Businesses',
          link:'/reseller/businesses'
        },
        {
          name:'Solo',
          link:'/reseller/solo'
        },
        {
          name:'Billing',
          link:'',
          subitems: [
            {
              name:'Buy Pricing',
              link:'/reseller/buy-pricing'
            },
            {
              name:'RRP',
              link:'/reseller/rrp'
            },
            {
              name: 'Invoices',
              link: '/reseller/invoices',
            },
            {
              name:'Payment Gateway',
              link:'/reseller/payment'
            },
            {
              name:'Licence Increase Request',
              link:'/reseller/licence-request'
            },
          ]
        },
        // {
        //   name:'Contracts',
        //   link:'',
        //   subitems: [
        //  {
        //    name:'Business Contract',
        //    link:'/reseller/contract-management'
        //  },
        //     {
        //       name:'Create Term Form',
        //       link:'/reseller/contracts/terms-forms/builder'
        //     },
        //     {
        //       name:'Terms Forms',
        //       link:'/reseller/contracts/terms-forms'
        //     },
        //     {
        //       name:'Terms',
        //       link:'/reseller/contracts/terms'
        //     },
        //     // {
        //     //   name:'Requests',
        //     //   link:'/reseller/contracts/requests'
        //     // }
        //   ]
        // },
        // {
        //   name:'Drivers',
        //   link:'/reseller/drivers'
        // },
        // {
        //   name:'Record Keeper Lookip',
        //   link:'/reseller/record-keeper'
        // },
        // {
        //   name:'Driver Lookup',
        //   link:'/reseller/drivers-lookup'
        // },
        // {
        //   name:'Vehicle Lookup',
        //   link:'/reseller/vehicle-lookup'
        // },
        // {
        //   name:'Billing Centre',
        //   link:'/reseller/billing-centre'
        // },
        // {
        //   name:'Contact Manager',
        //   link:'/reseller/contact-manager'
        // },
        // {
        //   name:'Users',
        //   link:'/reseller/users'
        // },
        {
          name:'Permissions',
          link:'',
          subitems: [
            {
              name:'User List',
              link:'/reseller/users'
            },
            {
              name:'Role List',
              link:'/reseller/roles'
            },
          ]
        },
        {
          name:'Support',
          link:'',
          subitems: [
            {
              name:'Support Details',
              link:'/reseller/support/details'
            },
            {
              name:'Release Notes',
              link:'/reseller/support/release-notes'
            },
          ]
        },
      ]
    },
  ]