<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="businessesData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap text-lg flex">
            <a
              class="flex cursor-pointer"
              @click.prevent="newBusinessContract(data.id)"
            >
              <font-awesome-icon
                icon="plus-square"
                class="my-auto mx-1.5 hover:text-orange-primary"
              />
            </a>
            <a
              class="flex cursor-pointer"
              @click.prevent="viewBusiness(data.id)"
              ><font-awesome-icon
                icon="eye"
                class="my-auto mx-1.5 hover:text-orange-primary"
            /></a>
          </div>
        </td>
      </template>
      <div>Not Found</div>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'new'">
        <NewPartnerBusinessContractStep
          @closeModal="closeModal"
          :businessId="modalId"
        />
      </div>
      <div v-if="modalContent == 'view'">
        <BusinessDetailView />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table } from "@/components/commons/";
import BusinessDetailView from "@/components/view/business";
import { NewPartnerBusinessContractStep } from "@/components/forms/partner/";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";

export default {
  name: "Admin-Businesses",
  components: {
    Modal,
    Table,
    NewPartnerBusinessContractStep,
    BusinessDetailView,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      businesses: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Business Name",
        "Email",
        "Contact Phone",
        "ABN",
        "Created At",
        "Enable / Disable",
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      userType: null,
      filterStatuses: {
        all: "All",
        linked: "Linked",
        pending: "Pending",
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    newBusinessContract: function (id) {
      this.modalContent = "new";
      this.modalTitle = "New Business Contract Partner";
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },
    viewBusiness: function (id) {
      this.modalContent = "view";
      this.modalTitle = "Business Info";
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },
    formatItem: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const business = {
            uid: item?.uid,
            id: item?._id,
            name: item?.persona?.businessName || "",
            email: item?.persona?.contactEmail || "",
            phone: item?.persona?.contactPhoneNumber || "",
            abn: item?.persona?.abn || "",
            created: formatDate(item?.createdAt),
            status: item?.isActive || false,
            isLicensed: item.isLicensed || false,
          };
          nItems.push(business);
        });
      }
      return nItems;
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;
      this.$emit("updateLoading", true);

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };

      const me = this.$store.getters[`account/me`];
      const business = await this.$store.dispatch(
        `partner/getBusinessByPartnerUID`,
        { id: me.uid, query }
      );
      if (business) {
        if (business?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            business?.metadata
          );
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        this.businesses = this.formatItem(
          _.orderBy(business.results, ["createdAt"], ["desc"])
        );

        this.isLoading = false;
        this.$emit("updateLoading", false);
      } else {
        this.businesses = [];
      }
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    parseBusiness(data) {
      return data.map((businessData) => [
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.name,
          itemType: "name",
          hasImage: true,
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.email,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.phone,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.abn,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.created,
          itemType: "string",
        },
        {
          uid: businessData.uid,
          id: businessData.id,
          name: businessData.status,
          itemType: "toggle",
        },
      ]);
    },
  },
  computed: {
    businessesData() {
      if (this.businesses) {
        return this.parseBusiness(this.businesses);
      } else {
        return [];
      }
    },
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
