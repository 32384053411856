<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 font-bold text-sm text-center my-5" v-if="errorMessage">
      {{errorMessage}}
    </div>
    <div v-if="!loading">
      <FormulateForm @submit="onSubmit">
        <FormulateInput
          class="mt-3 w-full"
          type="general-select"
          label="Select a business site"
          v-model="selectedDepot"
          :options="depots"
          :getOptionKey="(option) => option._id"
          :getOptionLabel="(option) => option.name"
          validation="required"
          :clearable="false"
          :searchable="false"
        >
          <template slot="option" slot-scope="option">
            <div class="text-sm">
              <h3 class="m-0 font-semibold">{{ option.value }}</h3>
              <em class="text-xs">{{ option.label }}</em>
            </div>
          </template>
        </FormulateInput>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :disabled="loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="loading"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" />
              Submitting...</span
            >
            <span v-if="!loading">Submit</span>
          </FormulateInput>
        </StyledSubmit>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { StyledSubmit } from '@/styled-components'

export default {
  name: 'Move-Vehicle',
  components: { StyledSubmit },
  props: {
    detailsId:{
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      error:false,
      errorMessage: '',
      depots: [],
      selectedDepot: null
    };
  },
  async mounted() {
    this.loading = true;
    if (!_.isEmpty(this.detailsId)) {
      const me = this.$store.getters[`account/me`];
      const depots = await this.$store.dispatch(`business/getSites`, {
        depotId: me?.business?._id,
        isDeleted: false,
      });
      this.depots = depots.filter((x) => x._id != this.detailsId);
      if (me?.businessSite) {
        this.depots = this.depots.filter((x) => x._id != me?.businessSite?.site?._id);
        this.depots.unshift({
          _id: me?.business?._id,
          name: "Main Business",
          isRoot: true,
        });
      }
    }
    this.loading = false;
  },
  methods: {
    async onSubmit () {
      this.loading = true;
      if (!_.isEmpty(this.selectedDepot)) {
        const payload = {
          vehicleId: this.detailsId,
          depotSiteId: this.selectedDepot.isRoot ? null : this.selectedDepot._id
        }

        await this.$store.dispatch(`vehicle/moveVehicle`, payload).then(vehicle => {
          this.loading = false;
          if (vehicle && vehicle._id) {
            this.$emit('closeRefresh');
            this.$store.commit('setDialogNotify', true);
            this.$store.commit('setMessageNotify', { state: 'success', message: 'Vehicle successfully moved.' });
          }
        }).catch(error => {
          this.loading = false;
          this.$store.commit('setDialogNotify', true);
          this.$store.commit('setMessageNotify', { state: 'error', message: error.message });
        })
      } else {
        this.loading = false;
        this.errorMessage = "No Vehicle Found";
        this.$store.commit('setDialogNotify', true);
        this.$store.commit('setMessageNotify', { state: 'error', message: "Something went wrong please try again." });
      }
    }
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>