<template>
  <FormulateForm
    v-model="activeFormData"
    name="formBuilder"
    class="w-full"
    @submit="submitForm"
  >
    <div class="w-full mb-16">
      <div class="px-5 mt-4 pb-8 mb-4">
        <h6
          class="font-medium text-center pb-3 leading-tight text-base mt-6 mb-3 text-blue-600"
        >
          {{ header }}
        </h6>
        <section v-for="(section, index) in termData.sections" :key="index">
          <h6 class="text-small text-left inline-block align-middle mt-1 pb-1">
            {{ section.headline }}
          </h6>
          <span v-if="section.subHeadline"> - </span
          ><small class="italic text-small inline-block pt-4"
            >{{ section.subHeadline }}
          </small>
          <hr />
          <div
            class="mt-5"
            v-for="(control, index) in section.controls"
            :key="index"
          >
            <FieldContainer
              :component="control"
              :unique-id="control.uniqueId"
              :section-id="section.uniqueId"
              :isDisplayValue="true"
            />
          </div>
        </section>
        <div class="flex space-x-2">
          <Button :submitting="submitting" />
          <button
            @click.prevent="cancelEdit(activeTerm.id)"
            class="btn bg-transparent text-blue-700 font-semibold py-2 px-4 border border-blue-500 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </FormulateForm>
</template>

<script>
import FieldContainer from "@/views/builder/containers/FieldContainer.vue";
import Button from "@/components/controls/Button.vue";
// import { HELPER } from "@/api/helper";

export default {
  components: { FieldContainer, Button },
  props: {
    activeTerm: {
      type: Object,
      default: () => {},
    },
    pulledFormData: {
      type: Object,
      required: false,
      default: null,
    },
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisplayValue: {
      type: Boolean,
      required: false,
      default: false,
    },
    termType: {
      type: String,
      required: true,
    },
  },
  emits: ["formSubmitted", "cancelEdit"],
  computed: {
    activeFormData: {
      get() {
        return this.pulledFormData;
      },
      set(newSavedForm) {
        return newSavedForm;
      },
    },
  },
  data() {
    return {
      submitting: false,
      termData: [],
      header: "Header",
    };
  },
  mounted() {
    if (this.termType === "logmaster") {
      this.header = "Logmaster Term";
      this.termData = this.activeTerm.logmasterTerm;
    } else if (this.termType === "partner") {
      this.header = "Partner Term";
      this.termData = this.activeTerm.partnerTerm;
    } else if (this.termType === "reseller") {
      this.header = "Reseller Term";
      this.termData = this.activeTerm.resellerTerm;
    }
  },
  methods: {
    async submitForm() {
      this.submitting = true;
      // const sections = HELPER.cloneDeep(this.$store.state.form.formSections)
      // const form = {
      //   data: data,
      //   sections: sections,
      // }

      // if(this.editMode){
      //   this.$store.dispatch('contract/updateContractTerms', form)
      //     .then((res) => {
      //       this.$emit('formSubmitted', res);
      //       this.submitting = false;
      //     }).catch(() => {
      //       this.submitting = false;
      //     })
      // } else {
      //   this.$store.dispatch('contract/createContractTerms', form)
      //     .then((res) => {
      //       this.$emit('formSubmitted', res);
      //       this.submitting = false;
      //     }).catch(err => {
      //       this.submitting = false;
      //       this.toast('error', err);
      //     })
      // }
    },
    cancelEdit(contractTermId) {
      this.$emit("cancelEdit", contractTermId);
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
};
</script>
