<template>
  <div
    class="flex flex-col top-0 left-0 h-full"
    :style="{
      backgroundColor: theme.primaryColor,
      border: 'solid 2px transparent',
    }"
  >
    <div
      class="flex items-center justify-center h-20 border-b border-opacity-10 p-4"
    >
      <div><img src="@/assets/images/logo.png" alt="" class="w-full" /></div>
    </div>
    <div class="overflow-y-auto overflow-x-hidden flex-grow">
      <proxyNav v-if="isProxy"></proxyNav>
      <ul class="flex flex-col py-0">
        <item
          v-for="item in menu"
          :key="item.name"
          :name="item.name"
          :icon="item.icon"
          :items="item.items"
        >
          <item
            v-for="child in filteredItems(item.items)"
            :key="child.name"
            :name="child.name"
            :link="child.link"
            :icon="child.icon"
            :subitems="child.subitems"
            :isExternalLink="child.isExternalLink"
          ></item>
        </item>
      </ul>
    </div>

    <StyledDiv
      class="footer-content py-2"
      :backgroundColor="theme.primaryColor"
    >
      <StyledP class="text-xs text-center"
        >© Logmaster {{ new Date().getFullYear() }}. All rights
        reserved</StyledP
      >
    </StyledDiv>
  </div>
</template>

<script>
import _ from "lodash";
import item from "./Sidebar-item.vue";
import adminItems from "./menuItems/side/admin";
import partnerItems from "./menuItems/side/partner";
import resellerItems from "./menuItems/side/reseller";
import businessitems from "./menuItems/side/business";
import driverItems from "./menuItems/side/driver";
import proxyNav from "./proxyNav.vue";
import {
  ADMIN_ROLE,
  PARTNER_ROLE,
  RESELLER_ROLE,
  BUSINESS_ROLE,
  DRIVER_ROLE,
} from "@/constants/usertype";
import { StyledDiv, StyledP } from "@/styled-components";
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  components: { item, proxyNav, StyledDiv, StyledP },
  props: {},
  data() {
    const isProxy = this.$store.getters[`account/proxyUser`];
    const proxyRole = this.$store.getters[`account/proxyRole`];
    const role = this.$store.getters[`account/roleName`];
    const roles = this.$store.getters[`account/roleNames`];
    const me = this.$store.getters["account/me"];

    let accountMenu = [];
    switch (role) {
      case ADMIN_ROLE.name:
        accountMenu = adminItems;
        break;
      case PARTNER_ROLE.name:
        accountMenu = partnerItems;
        break;
      case RESELLER_ROLE.name:
        accountMenu = resellerItems;
        break;
      case BUSINESS_ROLE.name:
        accountMenu =
          proxyRole === "driver" && roles.includes("driver")
            ? driverItems
            : businessitems;
        break;
      case DRIVER_ROLE.name:
        accountMenu = driverItems;
        break;
    }

    // If role is driver and proxyRole is business
    if (
      role === "driver" &&
      isProxy &&
      proxyRole === "business" &&
      roles.includes("business")
    ) {
      accountMenu = businessitems;
    }

    let roleSettingItems = [];
    if (role === ADMIN_ROLE.name) {
      roleSettingItems = [
        {
          name: "Driver Events and Annotations",
          icon: "cog",
          link: "/admin/driver-events-annotations",
          production: false,
        },
      ];
    }
    //Setting modules to menu
    if (me?.menuItems?.length > 0) {
      const fatigueModuleMenus = me.menuItems.filter((item) => {
        return item.name === "Fatigue Compliance";
      });

      const kmAndTimeReportModuleMenu = me.menuItems.find((item) => {
        return item.name == "KM & Time";
      });

      const oneAPIModuleMenu = me.menuItems.filter((item) => {
        return item.name === "One API";
      });

      const massModuleMenus = me.menuItems.filter((item) => {
        return item.name === "Mass Management";
      });

      if (kmAndTimeReportModuleMenu) {
        const reportsMenu = accountMenu[0].items.find((menu) => {
          return menu.name == "Reports";
        });

        if (reportsMenu) {
          const reportsMenuIndex = accountMenu[0].items.indexOf(reportsMenu);
          const targetSubItems =
            accountMenu[0].items[reportsMenuIndex].subitems;
          if (
            reportsMenuIndex > 0 &&
            !targetSubItems.includes(kmAndTimeReportModuleMenu.items[0])
          ) {
            accountMenu[0].items[reportsMenuIndex].subitems.push(
              kmAndTimeReportModuleMenu.items[0]
            );
          }
        }
      }

      accountMenu = accountMenu.concat(fatigueModuleMenus);
      accountMenu = accountMenu.concat(oneAPIModuleMenu);
      accountMenu = accountMenu.concat(massModuleMenus);
    }
    const menu = [
      ...accountMenu,
      {
        items: [
          // {
          //   name:'Account',
          //   icon:'user',
          //   link:'/account'
          // },
          // {
          //   name:'Settings',
          //   icon:'cog',
          //   link:'/settings'
          // },
          ...roleSettingItems,
          {
            name: "Log Out",
            icon: "sign-out-alt",
            link: "/logout",
          },
        ],
      },
    ];
    return { menu, role, isProxy, loading: true };
  },

  async mounted() {
    // If not root business, do not show `Depot Config` menu
    const proxySite = this.$store.getters[`account/proxySite`];
    const businessNavigation = this.menu.filter(
      (x) => x.name == "Business Navigation"
    );

    if (
      !_.isEmpty(proxySite) &&
      _.isObject(proxySite) &&
      businessNavigation.length > 0
    ) {
      const adminItemMenu = businessNavigation[0].items.filter(
        (item) => item.name == "Admin"
      );
      if (adminItemMenu && adminItemMenu.length > 0) {
        adminItemMenu[0].subitems.forEach((subItem, index) => {
          if (subItem.name == "Depot Config")
            adminItemMenu[0].subitems.splice(index, 1);
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      theme: "theme/getColorScheme",
      me: "account/me",
    }),
  },

  methods: {
    filteredItems(item) {
      let filteredNavs;
      //check contract type
      let me = this.me;
      if (this.me.driver !== undefined) {
        //check if there is no business contract for driver
        if (me.driverContract && me.driverContract.status !== "delinked") {
          if (me.driverContract.isContractor) {
            if (me.contract === undefined) {
              filteredNavs = item.filter((data) => {
                return data.hiddenOnSuspendedAccount !== true;
              });
            } else if (me.contract?.status?.name === "Suspended") {
              filteredNavs = item.filter((data) => {
                return data.hiddenOnSuspendedAccount !== true;
              });
            } else {
              filteredNavs = item;
            }
          } else {
            filteredNavs = item;
          }
        } else {
          if (me.contract === undefined) {
            filteredNavs = item.filter((data) => {
              return data.hiddenOnSuspendedAccount !== true;
            });
          } else if (me.contract?.status?.name === "Suspended") {
            filteredNavs = item.filter((data) => {
              return data.hiddenOnSuspendedAccount !== true;
            });
          } else {
            filteredNavs = item;
          }
        }
      } else if (this.me.contract?.status?.name === "Suspended") {
        filteredNavs = item.filter((data) => {
          return data.hiddenOnSuspendedAccount !== true;
        });
      } else {
        filteredNavs = item;
      }

      if (process.env.NODE_ENV === "production") {
        return filteredNavs.filter((data) => {
          return data.production !== false;
        });
      } else {
        return filteredNavs;
      }
    },

    // async setUserData() {
    //   this.$emit("updateLoading", true);
    //   const me = await this.$store.getters[`account/me`];
    //   await this.$store.dispatch('account/setUserData', me);
    //   this.$emit("updateLoading", false);
    // }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
