import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Reseller {
    // @Reseller
    createReseller(data){ // Create business
        return API.ACCOUNTS.post(`/reseller`, data)
    }
    getReseller( {id = null, query} ){ // Get single Reseller by Id or Get all Reseller
        let filter = '';
        if(id){
            return API.ACCOUNTS.get(`/reseller/${id}`)
        }else{
            if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if(query) query = '?'+ObjectToQueryString(query);
            return API.ACCOUNTS.get(`/reseller${query}`)
        }
    }

    acceptReseller( id = null ) {
        return API.ACCOUNTS.get(`/reseller/accept/${id}`)
    }

    getResellerByUID( uid = null ){ // Get single Reseller by Id or Get all Reseller
        return API.ACCOUNTS.get(`/reseller/find-one-by-uid/${uid}`)
    }

    getResellerByWebProfileId (id) {
        return API.ACCOUNTS.post(`/reseller/find-by-web-profile/${id}`)
    }

    patchReseller( id = null, data ){  // Update single Reseller by Id
        delete data.id;
        return API.ACCOUNTS.patch(`/reseller/${id}`, data)
    }
    patchResellerCreatePassword( id = null, data ){ // Call this to create Reseller password
        return API.ACCOUNTS.patch(`/reseller/create-password/${id}`, data)
    }
    getBusinessByReseller({ id = null, query}){ // Get all Businesses directly Linked to Reseller by uid
        let filter = '';
            if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
            query.filter = filter;
            if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/reseller/business/${id}/${query}`)
    }

    getResellerBusinessesById({ id, query }) {
        let filter = '';
        if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
        query.filter = filter;
        if(query) query = '?'+ObjectToQueryString(query);
        return API.ACCOUNTS.get(`/reseller/find-businesses/${id}/${query}`)
    }

    getResellerOfLogmasterByUID( uid){ // Get all Reseller directly linked to Logmaster by UID
        return API.ACCOUNTS.get(`/logmaster/resellers/${uid}`)
    }

    generateShareableUrl(id, data) {
        return API.ACCOUNTS.patch(`/reseller/generate-url/${id}`, data);
    }

    getResellerByUrl(data) {
        return API.ACCOUNTS.post(`/reseller/find-reseller-by-url`, data);
    }

    resendInvite(data){
        return API.ACCOUNTS.post(`/reseller/resend-invite`, data)
    }

    deleteReseller( id ){ // Update single Partner by Id
        return API.ACCOUNTS.delete(`/reseller/${id}`)
    }

    addModuleToResellers(standardPricingMasterId) {
        return API.ACCOUNTS.post(`/reseller/module`, { standardPricingMasterId })
    }

    getDashboardData(uid) {
        return API.ACCOUNTS.get(`/reseller/dashboard/data/uid/${uid}`);
    }
}