<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="driversData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-r-none appearance-none border-r-none -backdrop-hue-rotate-15block focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="relative block">
          <span class="absolute inset-y-0 left-0 flex items-center h-full pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="relative px-5 py-2 text-center">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                  @click.prevent="viewDriver(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <DrillDown :uid="data.uid" role="driver" />
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                  @click.prevent="updateTraining(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="spinner" class="my-auto mr-2" />
                  Update Training
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                  @click.prevent="supportDriver(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon
                    icon="headphones-alt"
                    class="my-auto mr-2"
                  />
                  Support Driver
                </StyledIconedLink>
              </li>
            </ul>
            <div class="py-2">
              <StyledIconedLink
                class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                @click.prevent="setDriverTrialData(data.id)"
                :iconHoverColor="theme.secondaryColor"
              >
                <font-awesome-icon icon="balance-scale" class="my-auto mr-2" />
                Set Trial Data
              </StyledIconedLink>
            </div>
            <div class="py-2" v-if="data.isPending">
              <StyledIconedLink
                  class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                  @click.prevent="changeDriverEmail(data.id)"
                  :iconHoverColor="theme.secondaryColor"
              >
                <font-awesome-icon icon="paper-plane" class="my-auto mr-2" />
                Change Email resend Invite
              </StyledIconedLink>
            </div>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal
      ref="modal"
      :title="modal.modalTitle"
      size="3xl"
      :height="modal.modalHeight"
    >
      <div v-if="modal.modalContent === 'view'">
        <ViewDriverDetails
          @closeRefresh="closeRefresh"
          :activeDriver="activeDriver"
        />
      </div>
      <div v-if="modal.modalContent === 'trial'">
        <SetDriverTrialData @closeRefresh="closeRefresh" :id="modal.modalId" />
      </div>
      <div v-if="modal.modalContent === 'support'">
        <SupportDriver
          @closeRefresh="closeRefresh"
          :activeDriver="activeDriver"
        />
      </div>
      <div v-if="modal.modalContent === 'change-email'">
        <ChangePendingDriverEmail
            @closeRefresh="closeRefresh"
            :id="modal.modalId"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { StyledIconedLink } from '@/styled-components';
import { Dropdown, Modal, Table } from '@/components/commons';
import ViewDriverDetails from '@/components/view/driver/view-driver-details';
import { SetDriverTrialData, SupportDriver } from '@/components/forms/driver';
import { DEFAULT_MODAL_HEIGHT, SEARCH_DEBOUNCE_DELAY } from '@/_helper/constants';
import { DRIVER_ROLE } from '@/constants/usertype';
import _ from 'lodash';
import { formatDate, formatPaginationSettings } from '@/_helper';
import DrillDown from '@/components/modules/DrillDown.vue';
import { mapGetters } from 'vuex';
import ChangePendingDriverEmail from "@/components/forms/account/ChangePendingDriverEmail.vue";

export default {
  name: 'Admin-Drivers',
  components: {
    ChangePendingDriverEmail,
    Modal,
    Table,
    Dropdown,
    DrillDown,
    StyledIconedLink,
    ViewDriverDetails,
    SetDriverTrialData,
    SupportDriver,
  },
  data() {
    return {
      modal: {
        modalContent: false,
        modalTitle: '',
        modalId: '',
        modalHeight: '80vh',
      },
      modalContent: false,
      isLoading: false,
      modalTitle: '',
      drivers: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        'Full Name',
        'Email Address',
        'Phone Number',
        'Licence',
        'Date Created',
        'Enable / Disable',
      ],
      tableData: [],
      filter: {
        limit: 10,
        status: 'all',
        search: '',
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      filterStatuses: {
        all: 'All',
      },
      searchKeyword: '',
      driversOrigin: [],
      activeDriver: null,
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status !== 'all')
        query.filter.status = { $eq: this.filter.status };

      let drivers = await this.$store.dispatch(`driver/getDriver`, { query });

      if (drivers?.metadata) {
        this.paginationSettings = formatPaginationSettings(drivers?.metadata);
        drivers = drivers.results;
        this.driversOrigin = drivers;
      }

      if (drivers) {
        this.drivers = this.formatItem(
            _.orderBy(drivers, ['createdAt'], ['desc'])
        );
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async filterChange() {
      await this.init();
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    async drillDownDriver(id) {
      await this.$store.dispatch(`account/proxyUser`, { uid: id });
      window.location.href =
        this.$store.getters['allURL'][DRIVER_ROLE.name]['default'];
    },
    newDriver() {
      this.$refs.modal.openModal();
      this.modalContent = 'new';
      this.modalTitle = 'New Driver';
    },
    viewDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = 'view';
      this.modal.modalTitle = 'Driver Info';
      this.activeDriver = this.driversOrigin.filter(
        (item) => item._id === id
      )[0];
    },
    editDriver() {
      this.$refs.modal.openModal();
      this.modalContent = 'edit';
      this.modalTitle = 'Driver Edit';
    },
    setDriverTrialData(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = 'trial';
      this.modal.modalTitle = 'Driver Trial Data';
      this.modal.modalId = id;
    },
    supportDriver(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = 'support';
      this.modal.modalTitle = 'Support Driver';
      this.activeDriver = this.driversOrigin.filter(
        (item) => item._id === id
      )[0];
    },
    changeDriverEmail(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = 'change-email';
      this.modal.modalTitle = 'Change Pending Driver Email';
      this.modal.modalId = id;
    },
    async updateTraining(driverId) {
      await this.$swal.fire({
        title: 'Checking and updating driver training... ',
        timerProgressBar: true,
        showCloseButton: true,
        didOpen: async () => {
          this.$swal.showLoading();

          await this.$store.dispatch(`driver/checkDriverTraining`, driverId);
          this.toast('success', 'Driver training updated...');
          this.$swal.closeModal();
        },
      });
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },
    closeRefresh(driverProfile = null) {
      if (driverProfile) this.driverProfile = driverProfile;
      this.$refs.modal.closeModal();
      this.init();
    },
    async onSchema(schema) {
      const businesses = await this.$store.dispatch(`business/getBusiness`);
      const partners = await this.$store.dispatch(`partner/getPartner`);
      let partnerOptions = {};
      let businessOptions = {};
      if (partners) {
        _.orderBy(partners, ['createdAt'], ['desc']).forEach((partner) => {
          let email = partner['supportEmail']
            ? `( ${partner['supportEmail']} )`
            : '';
          partnerOptions[
            partner._id
          ] = `${partner['persona']['businessName']} ${email}`;
        });
      }
      if (businesses) {
        _.orderBy(businesses, ['createdAt'], ['desc']).forEach((business) => {
          let email = business['supportEmail']
            ? `( ${business['supportEmail']} )`
            : '';
          businessOptions[
            business._id
          ] = `${business['persona']['businessName']} ${email}`;
        });
      }

      let topSchema = [
        {
          name: 'partnerId',
          type: 'select',
          label: 'Partner',
          options: {
            '': ' - Select - ',
            ...partnerOptions,
          },
          validation: 'required',
        },
        {
          name: 'businessId',
          type: 'select',
          label: 'Business',
          options: {
            '': ' - Select - ',
            ...businessOptions,
          },
        },
      ];
      this.$refs.newDriverForm.updateSchema([...topSchema, ...schema]);
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item?._id,
            uid: item.uid,
            name: item.driverName,
            email: item.emailAddress,
            phoneNumber: item?.persona?.contactPhoneNumber,
            license: item.driversLicenseNumber,
            created: formatDate(item?.createdAt),
            status: item?.isActive,
            pending: item?.persona?.currentStep === "welcome_email_send" || item?.persona?.currentStep === "welcome-email-send",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData.id,
          name: driverData.name,
          itemType: 'name',
          hasImage: true,
          status: driverData.status,
          uid: driverData.uid,
          isPending: driverData.pending,
        },
        { id: driverData.id, name: driverData.email, itemType: 'string' },
        { id: driverData.id, name: driverData.phoneNumber, itemType: 'string' },
        { id: driverData.id, name: driverData.license, itemType: 'string' },
        { id: driverData.id, name: driverData.created, itemType: 'string' },
        { id: driverData.id, name: driverData.status, itemType: 'string' },
      ]);
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.init();
    },
  },
  computed: {
    driversData() {
      if (this.searchKeyword) {
        const filteredMockData = this.drivers.filter(
          (item) =>
            this.searchKeyword
              .toLowerCase()
              .split(' ')
              .every((v) => item.name.trim().toLowerCase().includes(v)) ||
            this.searchKeyword
              .toLowerCase()
              .split(' ')
              .every((v) => item.email.trim().toLowerCase().includes(v)) ||
            this.searchKeyword
              .toLowerCase()
              .split(' ')
              .every((v) => item.phone.trim().toLowerCase().includes(v)) ||
            this.searchKeyword
              .toLowerCase()
              .split(' ')
              .every((v) => item.license.trim().toLowerCase().includes(v)) ||
            this.searchKeyword
              .toLowerCase()
              .split(' ')
              .every((v) => item.created.trim().toLowerCase().includes(v))
        );
        return this.parseDrivers(filteredMockData);
      } else {
        return this.parseDrivers(this.drivers);
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
