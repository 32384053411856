<template>
  <div class="page-container px-2 pt-3">
    <Stepper
      :stepperSteps="stepperSteps"
      @completed-step="completeStep"
      @active-step="isStepActive"
      :showNavigation="false"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import { Stepper } from "@/components/commons";
import {
  EventDetails,
  ManualEventCreate,
  CheckForBreaches,
} from "./stepper-component";

export default {
  name: "TripChecker",
  props: {
    soloDriver: {
      type: Object,
      default: null,
    },
  },
  components: { Stepper },
  data() {
    return {
      stepperSteps: [
        {
          stepIcon: "list",
          stepTitle: "Details",
          stepSequence: "first",
          stepComponent: EventDetails,
          soloDriver: this.soloDriver,
          isCompleted: true,
          isTripCheck: true,
        },
        {
          stepIcon: "clipboard",
          stepTitle: "Manual Events",
          stepSequence: "second",
          stepComponent: ManualEventCreate,
          isCompleted: false,
          isTripCheck: true,
        },
        {
          stepIcon: "check",
          stepTitle: "Check for Breaches",
          stepSequence: "third",
          stepComponent: CheckForBreaches,
          isCompleted: false,
          isTripCheck: true,
        },
      ],
      activeStep: 0,
    };
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      });
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if (step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.page-container {
  min-height: calc(100vh - 136px);
}
</style>
