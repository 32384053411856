
import { STATES, TIMEZONE } from '@/constants/location'

export default [
  {
    type: 'email',
    name: 'emailAddress',
    label: 'Email Address',
    validation: '^required|email'
  },
  {
    type: 'text',
    name: 'driverName',
    label: 'Full Name',
    validation: 'required'
  },
  {
    type: 'date',
    name: 'driverDateOfBirth',
    label: 'Date Of Birth',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'driversLicenseNumber',
    label: 'Licence Number',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'driversBFMNumber',
    label: 'BFM Number',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'driversLicenseState',
    label: 'Licence State',
    validation: 'required'
  },
  {
    type: 'text',
    name: 'driversBaseLocation',
    label: 'Base Location',
    validation: 'required'
  },
  {
    name: 'driversBaseTimeZone',
    type: 'select',
    label: 'Base Time Zone',
    options: TIMEZONE,
    validation: 'required'
  },
  {
    component: 'div',
    class: 'text-red',
    contents: 'Record Keeper Address',
  },
  // {
  //   type: 'group',
  //   name: 'driverRecordKeeperAddress',
  //   label: 'Record Keeper Address:',
  //   labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
  //   class: 'border rounded p-5 mb-5 mt-10 relative',
  //   children: [
  //     {
  //       name: 'street',
  //       label: 'Street Address',
  //       validation: 'required'
  //     },
  //     {
  //       component: 'div',
  //       class: 'grid grid-cols-2 gap-4',
  //       children: [
  //         {
  //           name: 'city',
  //           label: 'City',
  //           validation: 'required'
  //         },
  //         {
  //           name: 'state',
  //           type: 'select',
  //           label: 'State',
  //           options: {
  //             '': ' - Select - ',
  //             'NSW': 'New South Wales',
  //             'QLD': 'Queensland',
  //             'SA': 'South Australia',
  //             'TAS': 'Tasmania',
  //             'VIC':'Victoria',
  //             'WA': 'Western Australia'
  //           },
  //           validation: 'required'
  //         },
  //       ]
  //     }
  //   ]
  // },
  {
    type: 'group',
    name: 'billingAddress',
    label: 'Billing Address:',
    labelClass: 'font-semibold text-sm block -top-7 -left-0 absolute',
    class: 'border rounded p-5 mb-5 mt-11 relative bg-white',
    children: [
      {
        name: 'street',
        label: 'Street Address',
        validation: 'required'
      },
      {
        component: 'div',
        class: 'grid grid-cols-2 gap-4',
        children: [
          {
            name: 'city',
            label: 'City',
            validation: 'required'
          },
          {
            name: 'state',
            type: 'select',
            label: 'State',
            options: STATES,
            validation: 'required'
          },
        ]
      }
    ]
  },
  {
    component: 'div',
    class: 'border rounded p-5 mb-5 bg-white',
    children: [
      {
        type: 'group',
        name: 'create_password',
        children: [
          {
            type: 'password',
            name: 'password',
            label: 'Enter a Password',
            validation: 'required'
          },
          {
            type: 'password',
            name: 'confirmPassword',
            label: 'Confirm your password',
            validation: '^required|confirm:password',
            validationName: 'Password confirmation'
          }
        ]
      }
    ]
  },
  
]