<template>
  <div class="page-container px-2 pt-3">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-400">Loading data...</span>
      </div>
    </div>
    <div v-else>
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500">
          <thead
            ref="tableHead"
            class="text-xs text-gray-700 uppercase bg-gray-50"
          >
            <tr>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Email Address
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(driver, index) in drivers"
              :key="index"
              :ref="getRowRef(driver.emailAddress)"
              class="bg-white border-b hover:bg-gray-50 relative"
            >
              <td
                scope="row"
                class="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap"
              >
                <div>
                  <div class="font-normal text-gray-500 text-xs">
                    {{ driver.emailAddress }}
                  </div>
                </div>
              </td>
              <td class="py-4 px-6">
                <div class="flex items-center">
                  <font-awesome-icon
                    spin
                    icon="spinner"
                    class="mr-1 text-green"
                    v-if="driver.status == 'processing'"
                  />
                  <font-awesome-icon
                    icon="check-circle"
                    class="mr-1 text-green-400"
                    v-if="driver.status == 'done'"
                  />
                  <span class="capitalize" v-if="driver.status != 'error'">
                    {{ driver.status }}
                  </span>

                  <font-awesome-icon
                    icon="times-circle"
                    class="mr-1 text-red-400"
                    v-if="driver.status == 'error'"
                  />
                  <span class="text-xs" v-if="driver.status == 'error'">
                    {{ driver.errorMessage }}
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <FormulateInput
        type="submit"
        :label="hasSubmitted ? 'Close' : 'Send Invite'"
        :input-class="
          hasSubmitted
            ? 'btn bg-gray-200 w-full mt-5'
            : 'btn btn-primary w-full mt-5'
        "
        :disabled="submitting"
        @click="submit()"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import Spinner from "@/components/commons/ui/Spinner";

export default {
  name: "Invite-Driver",
  components: { Spinner },
  props: {
    preData: null,
    isNonEwdImport: Boolean,
  },
  data() {
    return {
      hasSubmitted: false,
      submitting: false,
      loading: false,
      drivers: [],
    };
  },
  mounted() {
    if (!_.isEmpty(this.preData)) {
      this.drivers = this.preData.drivers;
    }
  },
  methods: {
    formatDate(dateToFormat) {
      let momentDate = moment(dateToFormat);
      if (!momentDate.isValid())
        momentDate = moment(dateToFormat, "DD/MM/YYYY");

      return momentDate.format("YYYY-MM-DD");
    },
    getRowRef(name) {
      return "ref" + name.replace(/\s/g, "").toLowerCase();
    },
    async submit() {
      if (this.hasSubmitted) {
        this.$emit("closeRefresh");
      } else {
        this.submitting = true;
        for (const driver of this.drivers) {
          driver.status = "processing";
          driver.entityId = this.$nextTick(() => {
            this.$refs[this.getRowRef(driver.emailAddress)][0].scrollIntoView({
              block: "center",
              scrollBehavior: "smooth",
            });
          });
          await this.saveDriver(driver)
            .then(() => (driver.status = "done"))
            .catch((error) => {
              driver.status = "error";
              driver.errorMessage = error;
            });
        }
        this.submitting = false;
        this.hasSubmitted = true;
      }
    },
    async saveDriver(driver) {
      return new Promise((resolve, reject) => {
        const me = this.$store.getters[`account/me`];
        const entityID = me.businessId;
        const partnerID = me.entity.entityId;
        const BFMNumber = !_.isEmpty(driver.driverBFMNumber)
          ? driver.driverBFMNumber
          : null;
        let newDriver = {
          businessId: entityID,
          partnerId: partnerID,
          engineRegion: driver.region,
          emailAddress: driver.emailAddress,
          baseLocation: driver.driverBaseLocation,
          recordKeeperAddress: driver.driverRecordKeeperAddress,
          baseTimeZone: driver.driverBaseTimeZone,
          driverBFMNumber: BFMNumber,
        };

        this.$store
          .dispatch(`driver/createInviteSoloDriverContractor`, newDriver)
          .then(
            () => resolve(),
            (error) => {
              reject(error?.message);
            }
          );
      });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
