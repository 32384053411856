<template>
    <div class="mb-16">
      <FormulateForm @submit="onNext">      
        <div v-if="loading" class="text-center mt-28"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
        <div v-if="!loading && partner" class="items-center justify-between">
          <FormulateInput
              type="text"
              label="Business Name"
              name="businessName"
              error-behavior="live"
              :value="partner.businessName"
              validation="required"
            />             
          <div class="grid grid-cols-2 gap-4">
            <FormulateInput
                type="text"
                label="Trading Name"
                name="tradingName"
                error-behavior="live"
                :value="partner.tradingName"
                validation="required"
              />

            <FormulateInput
                type="text"
                label="ABN"
                name="abn"
                error-behavior="live"
                :value="partner.abn"
                validation="required"
              />
              <FormulateInput
                type="text"
                label="Support Email"
                name="supportEmail"
                error-behavior="live"
                :value="partner.supportEmail"
                validation="optional|email"
              />   
              <FormulateInput
                type="text"
                label="Support Phone Number"
                name="supportPhone"
                error-behavior="live"
                :value="partner.supportPhone"
                validation="required"
              />   
          </div>
          <div v-if="stateDataFromApi" class="border-t-2 mt-2 pt-4">
            <FormulateInput
              type="text"
              label="Street address"
              name="street"
              error-behavior="live"
              :value="partner.street"
            />
            <div class="grid grid-cols-2 gap-4">
              <FormulateInput
                type="text"
                label="City"
                name="city"
                error-behavior="live"
                :value="partner.city.trim()"
              />
              <FormulateInput
                type="select"
                label="State"
                name="state"
                :options="options"
                :value="stateDataFromApi.trim()"
              />
            </div>
          </div>
          <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
            <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Next</button>
          </div>
        </div>
      </FormulateForm>
    </div>
</template>
<script>
import schema from './partner-business-profile-schema';
import { STATES } from '@/constants/location';
import _ from "lodash";
import { mapGetters } from 'vuex'

export default {
  name: 'Edit-Partner-Business-Profile-Form',
  props: {
    msg: String,
    detailsId:{
      type: String,
      default: null,
    }
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      partner: {},
      schema: schema,
      options: STATES,
      stateDataFromApi: null,
      accountInfo: null,
      pricing: null,
      prepaid: null
    };
  },
  async mounted(){
    this.loading = true;
    const partnerDetails = await this.$store.dispatch(`partner/getPartner`, {id: this.detailsId})
    if(partnerDetails){
      if(!_.isEmpty(this.preData?.pricing)){
        this.pricing = this.preData.pricing;
        this.prepaid = this.preData.prepaid;
        this.accountInfo = this.preData.accountInfo;
      }

      this.partner = this.parsePartner(partnerDetails);
      this.stateDataFromApi = this.partner.state;
      this.accountInfo = this.preData?.accountInfo || {
        contactUserName: partnerDetails.persona.contactUserName,
        contactEmail: partnerDetails.persona.contactEmail,
        contactPhoneNumber: partnerDetails.persona.contactPhoneNumber
      };

      this.loading = false;
    }
  },
  methods: {
    parsePartner(partner){
      let addressObj = partner.persona.businessAddress;
      if(addressObj){
        const arr = addressObj.split(",")
          addressObj = { 
            street: String(arr[0]).trim(),
            city: String(arr[1]).trim(),
            state: String(arr[2]).replace(/ /g, '')
          }
      }
      return {
        id: partner._id,
        personaId: partner.persona._id,
        abn: partner.persona.abn,
        businessName: partner.persona.businessName,
        tradingName: partner.persona.tradingName,
        currentStep: partner.persona.currentStep,
        contactPhoneNumber: partner.persona.contactPhoneNumber,
        contactEmail: partner.persona.contactEmail,
        contactUserName: partner.persona.contactUserName,
        supportEmail: partner.supportEmail,
        supportPhone: partner.supportPhone,
        street: addressObj.street,
        city: addressObj.city,
        state: addressObj.state,
      }
    },
    onNext(values) {
      const businessAddress = `${values.street}, ${values.city}, ${values.state}`;
      values.businessAddress = businessAddress;
      delete values.street; delete values.city; delete values.state;
      values.id = this.partner.id;
      values.personaId = this.partner.personaId;
      this.$emit('nextStep', { 
        businessProfile: values,
        pricing: this.pricing,
        prepaid: this.prepaid,
        accountInfo: this.accountInfo,
        detailsId: this.detailsId
      })
    }
  },
  computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
  },
}
</script>