import { Incident } from "@/api";

export default {
  async getIncidentByBusinessId(_, param) {
    if (typeof param === "string")
      param = { businessId: param, query: { skip: 0, limit: 999 } };
    if (!param.businessId) throw "Invalid UID or ID";
    try {
      const response = await Incident.getIncidentByBusinessId(
        param.businessId,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },

  async getIncidentByDateRange({ state }, payload){
    try{
      const response = await Incident.getIncidentByDateRange(payload);
      if (response.data?.resultsMeta) {
        return {
          results : response.data.data,
          metadata:response.data.resultsMeta
        };
      } else {
        return response.data.data
      }
    } catch (error) {
      console.log(state);
      throw error.response.data
    }
  },


  async getIncidentById({ state }, id) {
    if (!id) throw "Invalid id " + state.me;
    try {
      const response = await Incident.getIncidentById(id);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getSummaryReport({ state }, payload) {
    if (!payload) throw "Invalid payload " + state.me;
    try {
      const response = await Incident.getSummaryReport(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async getDetailedReport({ state }, payload) {
    if (!payload) throw "Invalid payload " + state.me;
    try {
      const response = await Incident.getDetailedReport(payload);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
