import { API } from "@/api/config";
import { ObjectToQueryString } from "../../../_helper";

export default new (class RolesAndRespo {
  // @Roles
  createRole(data) {
    return API.ACCOUNTS.post(`/security-role`, data);
  }
  getRoles(id = null) {
    if (id) {
      return API.ACCOUNTS.get(`/security-role/${id}`);
    } else {
      return API.ACCOUNTS.get(`/security-role`);
    }
  }

  deleteRole(id) {
    return API.ACCOUNTS.delete(`/security-role/${id}`);
  }

  patchRole(id = null, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}`, data);
  }

  linkRespo(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/link-responsibility`, data);
  }

  linkParentRole(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/link-parent-role`, data);
  }

  unlinkRespo(id, data) {
    return API.ACCOUNTS.patch(
      `/security-role/${id}/unlink-responsibility`,
      data
    );
  }

  unlinkParentRole(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/unlink-parent-role`, data);
  }

  linkUser(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/link-user`, data);
  }

  unlinkUser(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/unlink-user`, data);
  }

  // @Responsibility
  createRespo(data) {
    return API.ACCOUNTS.post(`/responsibility`, data);
  }
  getRespo(id) {
    return API.ACCOUNTS.get(`/responsibility/${id}`);
  }
  getRespos({ page, pageSize }) {
    if (page && pageSize) {
      return API.ACCOUNTS.get(
        `/responsibility?pageNumber=${page}&pageSize=${pageSize}`
      );
    } else {
      return API.ACCOUNTS.get(`/responsibility`);
    }
  }
  patchRespo(id = null, data) {
    return API.ACCOUNTS.patch(`/responsibility/${id}`, data);
  }

  deleteRespo(id) {
    return API.ACCOUNTS.delete(`/responsibility/${id}`);
  }

  // @Users
  createUser(data) {
    return API.ACCOUNTS.post("/web-profile", data);
  }

  getUsers(queryParams) {
    return API.ACCOUNTS.get(
      `/profile/list?${ObjectToQueryString(queryParams)}`
    );
  }

  getUser(id) {
    return API.ACCOUNTS.get(`/web-profile/${id}`);
  }

  getWebProfilesByUID(uid) {
    return API.ACCOUNTS.post(`/web-profile/find-all-by-uid`, { uid: uid });
  }

  getWebProfilesByEmail(email) {
    return API.ACCOUNTS.post(`/web-profile/find-all-by-email`, { email });
  }

  getWebProfileByUID(uid) {
    return API.ACCOUNTS.post(`/web-profile/find-by-uid`, { uid: uid });
  }

  patchEmailPreferences(id, data) {
    return API.ACCOUNTS.patch(
      `/web-profile/update-email-preferences/${id}`,
      data
    );
  }

  patchLeadsDestinationBox(id, data) {
    return API.ACCOUNTS.patch(
      `/web-profile/update-leads-destination-box/${id}`,
      data
    );
  }

  patchUser(id, data) {
    return API.ACCOUNTS.patch(`/web-profile/${id}`, data);
  }

  patchWebProfileCreatePassword(id, data) {
    return API.ACCOUNTS.patch(`/web-profile/create-password/${id}`, data);
  }

  acceptWebProfile(
    id,
    data = { termsAccepted: true, privatePolicyAccepted: true }
  ) {
    return API.ACCOUNTS.patch(`/web-profile/terms-accept/${id}`, data);
  }

  getSecurityConfig(id) {
    return API.ACCOUNTS.get(`/security-role/${id}/security-config`);
  }

  patchSecurityConfig(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/security-config`, data);
  }

  getAuditTrails(params) {
    const paramKeys = Object.keys(params);
    const url = ["/user-role-audit-trail", "?"];
    for (let i = 0; i < paramKeys.length; i++) {
      if (params[paramKeys[i]]) {
        url.push(`${paramKeys[i]}=${params[paramKeys[i]]}&`);
      }
    }
    return API.ACCOUNTS.get(url.join(""));
  }

  // @Depots
  getDepotsByOwnerUid(uid) {
    return API.ACCOUNTS.get(`/depot-config/get-depot-configs-by-uid/${uid}`);
  }
  linkDepot(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/link-depot`, data);
  }
  unlinkDepot(id, data) {
    return API.ACCOUNTS.patch(`/security-role/${id}/unlink-depot`, data);
  }

  // @Two-Factor Authentication
  setTwoFactor(data) {
    return API.ACCOUNTS.post(
      `/web-profile/set-two-factor-authentication`,
      data
    );
  }
  deleteBusinessUser(id) {
    return API.ACCOUNTS.delete(`/web-profile/user/${id}`);
  }
  resendInvite(id) {
    return API.ACCOUNTS.post(`/web-profile/resend-invite/${id}`);
  }
})();
