<template>
  <div>
    <div v-if="!loading" class="flex flex-col items-center space-y-4">
      <div class="flex flex-col justify-center items-center w-full">
        <div class="text-lg font-bold text-gray-500">Current Credit</div>
        <div class="text-3xl font-bold text-gray-800">
          ${{ contract.paymentCredit?.toFixed(2) || 0 }}
        </div>
      </div>
      <FormulateForm
        v-if="!loading"
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="flex flex-col w-full">
          <FormulateInput
            type="text"
            name="creditAmount"
            label="Credit Amount"
            help="Input the amount to be credited to the account in AUD."
            validation="required|number|min:1"
            min="1"
          />
          <StyledSubmit :backgroundColor="theme.secondaryColor">
            <FormulateInput
              type="submit"
              label="Submit"
              input-class="btn btn-primary w-full"
              :disabled="hasErrors || submitLoading"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              <span v-if="submitLoading"
                ><font-awesome-icon
                  icon="spinner"
                  class="mr-1 loader"
                />Submitting</span
              >
              <span v-if="!submitLoading">Submit</span>
            </FormulateInput>
          </StyledSubmit>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { StyledSubmit } from "@/styled-components";
import { mapGetters } from "vuex";
export default {
  props: {
    entityId: {
      default: "",
      type: String,
    },
    entityRole: {
      default: "",
      type: String,
    },
  },
  components: { StyledSubmit },
  data() {
    return {
      contract: {},
      loading: false,
      hasRecurringPayment: false,
      submitLoading: false,
      values: {},
    };
  },
  methods: {
    async getContract() {
      this.loading = true;

      try {
        const contract = await this.$store.dispatch(`contract/getMyContract`, {
          entityId: this.entityId,
        });

        this.contract = contract;
        if (
          contract.activationOption === "recurring_payment" ||
          contract.hasRecurringPayment === true
        ) {
          this.hasRecurringPayment = true;
        } else {
          this.hasRecurringPayment = false;
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async onSubmit() {
      try {
        this.submitLoading = true;
        const payload = {
          contractId: this.contract._id,
          amount: parseFloat(this.values.creditAmount),
          creditType: "add",
          isUsedInPayment: false,
        };

        await this.$store.dispatch("contract/updateContractCredit", payload);

        this.submitLoading = false;
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "success",
          message: "Amount credit added successfully.",
        });
        this.$emit("closeModal");
      } catch (error) {
        this.submitLoading = false;
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: "Error adding credit to account.",
        });
        console.log(error);
      }
    },
  },
  async created() {
    await this.getContract();
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
