import _ from "lodash";
import moment from "moment";
import locationData from "@/assets/location/location.json";
import VueCookies from "vue-cookies";

export const TARGET_MOMENT_TIMEZONE =
  process.env.TARGET_MOMENT_TIMEZONE || "Australia/Sydney";

// @Common
function convertAddressObject(address) {
  // Convert address object to string
  let data = address;
  let dataArray = [];
  if (!address) return "";
  if (_.isArray(address)) {
    data = address[0];
  }
  if (data["street"]) dataArray.push(String(data["street"]).trim());
  if (data["city"]) dataArray.push(String(data["city"]).trim());
  if (data["state"]) dataArray.push(String(data["state"]).trim());
  if (data["postcode"]) dataArray.push(String(data["postcode"]).trim());
  if (data["country"]) dataArray.push(String(data["country"]).trim());

  return dataArray.join(", ");
}

//calculate remaining seat count and seat availability
function calculateSeatCountAvailability(maximumSeatCount, currentSeatCount) {
  let remainingSeatCount = {
    ewd: 0,
    fatigue: 0,
    kmtime: 0,
  };
  let crudAccess = {
    ewd: true,
    fatigue: true,
    kmtime: true,
  };
  if (maximumSeatCount.ewd.isSubscribed == true) {
    remainingSeatCount.ewd =
      maximumSeatCount.ewd.count - currentSeatCount.ewdCount;
    if (remainingSeatCount.ewd < 1) {
      crudAccess.ewd = false;
    }
  }

  if (maximumSeatCount.fatigue.isSubscribed == true) {
    remainingSeatCount.fatigue =
      maximumSeatCount.fatigue.count - currentSeatCount.fatigueCount;
    if (remainingSeatCount.fatigue < 1) {
      crudAccess.fatigue = false;
    }
  }

  if (maximumSeatCount.km.isSubscribed == true) {
    remainingSeatCount.kmtime =
      maximumSeatCount.km.count - currentSeatCount.kmTimeCount;
    if (remainingSeatCount.kmtime < 1) {
      crudAccess.kmtime = false;
    }
  }

  return { remainingSeatCount, crudAccess };
}

function getStateSubInAddress(address) {
  if (!address) return;
  const addressArray = address.split(",");
  let addressRaw = { state: null, suburb: null };
  addressArray.forEach((line) => {
    line = line.trim();
    const foundLocation = _.find(locationData["data"], (loc) => {
      return loc.state == line || loc.suburb == line || loc.postcode == line;
    });

    if (!_.isEmpty(foundLocation)) {
      addressRaw.state = foundLocation["state_name"];
      addressRaw.suburb = foundLocation["suburb"];
    }
  });
  return addressRaw;
}

// Disable all fields from schema Object
function disableAllField(fields) {
  Object.keys(fields).forEach((i) => {
    if (!fields[i].children) {
      fields[i]["disabled"] = true;
    } else {
      fields[i].children = disableAllField(fields[i].children);
    }
  });
  return fields;
}

// Enable all fields from schema Object
function enableAllField(fields) {
  Object.keys(fields).forEach((i) => {
    if (!fields[i].children) {
      fields[i]["disabled"] = false;
    } else {
      fields[i].children = enableAllField(fields[i].children);
    }
  });
  return fields;
}

function formatValues(values) {
  Object.keys(values).forEach((i) => {
    if (moment(values[i]).isValid()) {
      values[i] = formatDate(values[i], "YYYY-MM-DD");
    }
  });
  return values;
}

function findRootUID(user) {
  if (!_.isEmpty(user.webProfiles)) {
    const rootProfile = _.find(user.webProfiles, { isRoot: true });
    return rootProfile ? rootProfile.uid : null;
  }
}
//date format
const dateFormat = "DD/MM/YYYY HH:mm";
function formatDate(
  date,
  format = dateFormat,
  keepTimezoneOffset = false,
  convertToTargetTz = false
) {
  if (keepTimezoneOffset) {
    date = moment.parseZone(date);
  }

  if (convertToTargetTz) {
    return moment(date).tz(TARGET_MOMENT_TIMEZONE).format(format);
  }
  return moment(date).format(format);
}

function formatCleanDate(date) {
  return moment(date).format("MMM DD, YYYY");
}

const filterRow = (keyword, attributeToFilter) =>
  keyword
    .toLowerCase()
    .split(" ")
    .every((v) => attributeToFilter.trim().toLowerCase().includes(v));

function ObjectToQueryString(object = {}) {
  var queryString = Object.keys(object)
    .map((key) => key + "=" + object[key])
    .join("&");
  return queryString;
}

function formatPaginationSettings(resultsMeta) {
  const paged = resultsMeta ? resultsMeta.skip / resultsMeta.limit + 1 : 1;
  return {
    page: paged ? paged : 1,
    totalPages: resultsMeta?.total
      ? Math.ceil(resultsMeta.total / resultsMeta.limit)
      : 1,
    totalRecords: resultsMeta?.total ? resultsMeta.total : 10,
    /* Setting the visiblePageItemCount to 5 by default to avoid overwhelming values
    visiblePageItemCount: resultsMeta?.count ? resultsMeta.count : 5,
    */
    visiblePageItemCount: resultsMeta?.count ? 5 : 1,
  };
}

function print(htmlElementId) {
  // Get HTML to print from element
  const insideDiv = document.getElementById(`${htmlElementId}`).innerHTML;

  let popupWinHeight = 650,
    popupWinWidth = 1200;

  // Set the window area
  var left = (screen.width - popupWinWidth) / 2;
  var top = (screen.width - popupWinHeight) / 6;

  // Get all stylesheets HTML
  let stylesHtml = "";
  for (const node of [
    ...document.querySelectorAll('link[rel="stylesheet"], style'),
  ]) {
    stylesHtml += node.outerHTML;
  }

  // Open the print window
  const winPrint = window.open(
    "data:text/html",
    "",
    "_blank",
    "resizable=yes, width=" +
      popupWinWidth +
      ", height=" +
      popupWinHeight +
      ", top=" +
      top +
      ", left=" +
      left
  );

  winPrint.document.write(`<!DOCTYPE html>
        <html>
            <head>
                ${stylesHtml}
            </head>
            <body>
                ${insideDiv}
            </body>
        </html>`);

  winPrint.document.close();
  setTimeout(() => {
    winPrint.print();
  }, 500);
  winPrint.onfocus = () => {
    setTimeout(() => {
      winPrint.close();
    }, 500);
  };
  return;
}

function removeStringHipen(string) {
  if (!string) return "";
  let str = string.replace(/-/g, " ");
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

function removeStringUnderscore(string) {
  if (!string) return "";
  let str = string.replace(/_/g, " ");
  return str.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

function cleanDateFormat(date, isShort = false, withTime = false) {
  let format = "MMM D, YYYY";
  format = withTime ? `${format} hh:mm A` : format;

  if (!date) return;
  if (isShort) return date ? moment(date).format(format) : "-";
  else return date ? moment(date).format(format) : "-";
}

function validateEmail(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

function isPilletOptionAvailable(option) {
  if (option) {
    option = String(option).toLowerCase();
    const dataOption = [
      "yes",
      "no",
      "pass",
      "passed",
      "fail",
      "failed",
      "true",
      "false",
    ];
    return dataOption.includes(option);
  }
  return false;
}

const roundValue = (value) => Math.round((value + Number.EPSILON) * 100) / 100;

function findDepotById(id, rootArr) {
  for (const el of rootArr) {
    if (el._id === id) {
      return el;
    }
    if (el.children) {
      const idFoundInChildren = findDepotById(id, el.children);
      if (idFoundInChildren !== null) {
        return idFoundInChildren;
      }
    }
  }
  return null;
}
function getDepots(sites) {
  let children = [];
  const flattenDepots = sites.map((m) => {
    if (m.children && m.children.length) {
      children = [...children, ...m.children];
    }
    return m;
  });

  return flattenDepots.concat(children.length ? getDepots(children) : children);
}

function capitalizeFirstLetter(string) {
  if (!string) return "";
  string = string.replace("_", " ").toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function viewFileUrl(url) {
  window.open(url, "_blank");
}

function convertJSONToCSV(data) {
  const array = typeof data !== "object" ? JSON.parse(data) : data;
  let str = "";

  for (let i = 0; i < array.length; i++) {
    let line = "";
    for (let index in array[i]) {
      if (line !== "") line += ",";

      line += '"' + array[i][index].toString().replace(/"/g, '""') + '"';
    }

    str += line + "\r\n";
  }

  return str;
}
function downloadCSV(csvString, fileName) {
  const blob = new Blob([csvString], { type: "text/csv" });
  const a = document.createElement("a");

  a.href = URL.createObjectURL(blob);
  a.download = `${fileName}.csv`;
  a.click();
}

async function removeCookies() {
  if (VueCookies.keys()) {
    await VueCookies.keys().forEach((key) => {
      if (key != "isSideMenu") {
        VueCookies.remove(key);
      }
    });
  }
}

function applyDynamicColorWithOpacity(color, opacity) {
  const rgbaColor = `rgba(${parseInt(color.substring(1, 3), 16)}, ${parseInt(
    color.substring(3, 5),
    16
  )}, ${parseInt(color.substring(5, 7), 16)}, ${opacity})`;
  return rgbaColor;
}

export {
  findDepotById,
  getDepots,
  isPilletOptionAvailable,
  convertAddressObject,
  getStateSubInAddress,
  disableAllField,
  formatDate,
  formatCleanDate,
  formatValues,
  filterRow,
  findRootUID,
  ObjectToQueryString,
  formatPaginationSettings,
  roundValue,
  print,
  removeStringHipen,
  removeStringUnderscore,
  cleanDateFormat,
  validateEmail,
  capitalizeFirstLetter,
  enableAllField,
  viewFileUrl,
  convertJSONToCSV,
  downloadCSV,
  calculateSeatCountAvailability,
  removeCookies,
  applyDynamicColorWithOpacity,
};
