<template>
  <div>
    <div v-if="loading" class="loading centered">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <div id="printCovered">
        <div class="flex text-right justify-end mb-5">
          <button
            v-if="sections"
            id="printBtn"
            class="h-6 pl-2 pr-4 mt-1 text-xs transition-colors duration-150 bg-blue-300 text-white hover:bg-blue-200 rounded-md focus:shadow-outline"
            @click="printThis('printCovered')"
          >
            <font-awesome-icon icon="print" class="my-auto mx-1 text-xs" />
            Print
          </button>
        </div>
        <div v-if="driver">
          <div class="mb-5">
            <div class="font-bold">
              {{ driver.driverName || "N/A" }}
            </div>
            <div class="text-xs">Driver</div>
          </div>
          <div class="mb-5">
            <div class="font-bold">
              {{ driver.driversLicenseNumber || "N/A" }}
            </div>
            <div class="text-xs">Driver Licence Number</div>
          </div>
        </div>
        <FilledFormSections :selectedSections="sections" />
        <div v-if="createdAt">
          <div class="mb-5">
            <div class="font-bold">
              {{ createdAt }}
            </div>
            <div class="text-xs">Created Date</div>
          </div>
        </div>
      </div>
      <div v-if="activeFilledForm && activeFilledForm.isAcknowledged">
        <div class="mb-10 font-bold">Acknowledgement Details</div>
        <div class="mb-5">
          <div class="font-bold">
            {{
              activeFilledForm.acknowledgedBy
                ? activeFilledForm.acknowledgedBy.userName
                : "N/A"
            }}
          </div>
          <div class="text-xs">Acknowledged By</div>
        </div>
        <div class="mb-5">
          <div class="font-bold">
            {{ activeFilledForm.acknowledgeDate }}
          </div>
          <div class="text-xs">Acknowledge Date</div>
        </div>
        <div class="mb-5">
          <div class="font-bold mb-5">Signature</div>
          <img
            :src="activeFilledForm.acknowledgeSignature"
            alt=""
            style="max-width: 200px"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate, print } from "@/_helper";
import FilledFormSections from "@/components/view/business/forms/filled-forms/filled-form-sections";

export default {
  name: "Filled-Form-View",
  components: { FilledFormSections },
  props: {
    activeFilledForm: {
      type: Object,
      default: () => {},
    },
  },
  watch: {
    activeFilledForm: {
      deep: true,
      handler() {
        this.setActiveHazard();
      },
    },
  },
  created() {
    this.loading = true;
    this.setActiveHazard();
    setTimeout(() => (this.loading = false), 300);
  },
  data() {
    return {
      loading: true,
      driver: {},
      sections: [],
      driverHazard: {},
      collapsed: true,
      isPassed: null,
      alertorSleepyHasIssue: false,
      riskAssessmentHasIssue: false,
      selectedAsnswer: "",
      createdAt: "",
    };
  },
  methods: {
    formatDate,
    setActiveHazard() {
      this.loading = true;
      this.driver = this.activeFilledForm?.driver;
      this.sections = this.activeFilledForm?.savedData?.sections;
      this.createdAt = this.cleanDateFormatWithTime(this.activeFilledForm?.savedData?.date);
      if (this.activeFilledForm?.isPassed !== null) {
        this.isPassed = this.activeFilledForm?.isPassed;
        this.selectedAsnswer = String(this.activeFilledForm?.isPassed);
      }
      setTimeout(() => (this.loading = false), 400);
    },
    async onChangedSelectAnswer() {
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      const businessId = me?.business?._id;

      const payload = {
        result: Boolean(this.selectedAsnswer),
        filledFormId: this.activeFilledForm._id,
        businessId: businessId,
        formType: this.activeFilledForm.formType,
      };
      await this.$store
        .dispatch(`filledform/updateResultStatus`, payload)
        .then((data) => {
          this.loading = false;
          this.isPassed = data.isPassed;
          this.selectedAsnswer = String(data.isPassed);
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Result status successfully updated.",
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });
    },
    onCollapsed() {
      this.collapsed = !this.collapsed;
    },
    convertBool(option) {
      return "Yes" == option;
    },
    printThis(toPrintId) {
      return print(toPrintId);
    },
    cleanDateFormatWithTime(date){
      if(!date) {
        return "N/A";
      }
      if(date['_seconds']) {
        return formatDate(new Date(date['_seconds'] * 1000));
      }
      return formatDate(date)
    }
  },
};
</script>
