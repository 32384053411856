<template>
  <div>
    <div
      v-if="writeDepots && treeData"
      class="py-8 px-5 text-sm border-t-2"
      style="min-height: calc(100vh - (112px + 2.75rem))"
    >
      <div
        v-if="!isNotEmptyDepot"
        class="w-full px-1 py-2 border-b border-gray-200 opacity-40"
        :style="{ color: theme.primaryColor }"
      >
        <small><em>Add a region to get started.</em></small>
      </div>

      <button
        v-if="writeDepots"
        class="btn btn-primary mb-4 text-sm"
        @click="addNode"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        <font-awesome-icon icon="plus" />&nbsp; Add Root Region
      </button>

      <div class="flex w-full flex-row md:flex-row lg:flex-row">
        <div class="w-full">
          <div class="border rounded px-5 pt-3 pb-2 w-full mr-5">
            <label class="font-bold text-sm mb-3 block border-b pb-3"
              >Active Sites</label
            >
            <span v-if="!treeData.children || treeData.children.length == 0"
              >No active sites</span
            >
            <VueTreeList
              v-else
              @change-name="onChangeName"
              @add-node="onAddNode"
              @delete-node="removeNode"
              ref="tree"
              :model="treeData"
              @drop="moveSite"
              @drop-before="moveSite"
              @drop-after="moveSite"
              default-tree-node-name="New Region"
              v-bind:default-expanded="false"
            >
              <template v-slot:leafNameDisplay="slotProps">
                <div
                  class="flex p-3 rounded-lg"
                  :class="{
                    'font-semibold text-orange-primary': slotProps.model.isLeaf,
                  }"
                >
                  <span class="text-sm">{{ slotProps.model.name }}</span>
                </div>
              </template>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Add Site"
                slot="addLeafNodeIcon"
              >
                <font-awesome-icon icon="building" />
              </span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Add Sub Region"
                slot="addTreeNodeIcon"
              >
                <font-awesome-icon icon="map-marked-alt" />
              </span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                slot="editNodeIcon"
              >
                <font-awesome-icon icon="edit" />
              </span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Region"
                slot="treeNodeIcon"
              >
                <font-awesome-icon icon="map-marked-alt" />
              </span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Site"
                slot="leafNodeIcon"
              >
                <font-awesome-icon icon="building" />
              </span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                slot="delNodeIcon"
              >
                <font-awesome-icon icon="trash" />
              </span>
            </VueTreeList>
          </div>

          <div class="mt-3 border rounded px-5 pt-3 pb-2">
            <label class="font-bold text-sm mb-3 block border-b pb-3"
              >Deactivated Sites</label
            >
            <span
              v-if="
                !deactivatedTreeData.children ||
                deactivatedTreeData.children.length == 0
              "
              >No deactivated sites</span
            >
            <VueTreeList
              v-else
              ref="tree"
              :model="deactivatedTreeData"
              @drop="removeNode"
              @drop-before="removeNode"
              @drop-after="removeNode"
              default-tree-node-name="New Region"
              v-bind:default-expanded="false"
            >
              <template v-slot:leafNameDisplay="slotProps">
                <div
                  class="flex p-3 rounded-lg"
                  :class="{
                    'font-semibold text-orange-primary': slotProps.model.isLeaf,
                  }"
                >
                  <span class="text-sm">{{ slotProps.model.name }}</span>
                </div>
              </template>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Add Site"
                slot="addLeafNodeIcon"
              >
                <font-awesome-icon icon="building"
              /></span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Add Sub Region"
                slot="addTreeNodeIcon"
              >
                <font-awesome-icon icon="map-marked-alt" />
              </span>

              <span class="icon hidden" slot="editNodeIcon"></span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Region"
                slot="treeNodeIcon"
              >
                <font-awesome-icon icon="map-marked-alt"
              /></span>

              <span
                class="icon pl-2 pt-2 text-gray-500 hover:text-orange-primary"
                title="Site"
                slot="leafNodeIcon"
              >
                <font-awesome-icon icon="building" />
              </span>

              <span class="icon hidden" slot="delNodeIcon"></span>
            </VueTreeList>
          </div>
        </div>

        <div
          v-if="activeSites.length > 0"
          class="w-full ml-5 depot-tree-wrapper"
        >
          <Tabs clickEvent @click="fetchTab" class="flex flex-col h-full">
            <Tab
              v-for="(activeSite, index) in activeSites"
              :key="index"
              :title="activeSite.name"
              class="flex-1 flex flex-col"
            >
              <div class="p-5">
                <div class="flex flex-col w-full mr-2">
                  <div
                    class="flex w-full flex-col sm:flex-col md:flex-col lg:flex-row"
                  >
                    <Card
                      :cardBackgroundColor="'white'"
                      :cardWidth="'100%'"
                      class="sm:w-full sm:mr-2 md:mr-5"
                    >
                      <template slot="cardContent">
                        <div
                          class="flex items-center w-full justify-between pb-3"
                        >
                          <div class="icon-container">
                            <font-awesome-icon
                              icon="car"
                              class="text-2xl"
                              :style="{ color: theme.primaryColor }"
                            />
                          </div>
                          <div
                            class="text-xs font-bold text-right"
                            :style="{ color: theme.accentColor }"
                          >
                            Active Vehicles
                          </div>
                        </div>
                        <div
                          class="pb-3 text-center font-bold text-3xl text-card-orange"
                        >
                          {{ vehicleCount }}
                        </div>
                      </template>
                    </Card>

                    <Card
                      :cardBackgroundColor="'white'"
                      :cardWidth="'100%'"
                      class="sm:w-full sm:mr-2 md:mr-5"
                    >
                      <template slot="cardContent">
                        <div
                          class="flex items-center w-full justify-between pb-3"
                        >
                          <div class="icon-container">
                            <font-awesome-icon
                              icon="user"
                              class="text-2xl"
                              :style="{ color: theme.primaryColor }"
                            />
                          </div>
                          <div
                            class="text-xs font-bold text-right"
                            :style="{ color: theme.accentColor }"
                          >
                            Drivers
                          </div>
                        </div>
                        <div
                          class="pb-3 text-center font-bold text-3xl text-card-orange"
                        >
                          {{ driversCount }}
                        </div>
                      </template>
                    </Card>
                  </div>
                </div>

                <Table
                  :isLoading="isLoading"
                  :tableHeader="tableHeader"
                  :paginationSettings="paginationSettings"
                  :data="driversData"
                  @onPageChange="onPageChange($event)"
                  :has-multi-filter="false"
                  :has-actions="false"
                >
                  <template #tableFilters>
                    <div class="flex flex-row mb-1 sm:mb-0">
                      <div class="relative">
                        <select
                          v-model="filter.limit"
                          @change="filterChange"
                          class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option :value="10">10</option>
                          <option :value="20">20</option>
                          <option :value="50">50</option>
                        </select>
                        <div
                          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                        >
                          <font-awesome-icon
                            icon="caret-down"
                            class="text-gray-400"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="block relative">
                      <span
                        class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                      >
                        <font-awesome-icon
                          icon="search"
                          class="text-gray-400"
                        />
                      </span>
                      <input
                        placeholder="Search for Driver"
                        class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                        v-model="filter.search"
                        @keydown="handleSearchKeyDown"
                      />
                    </div>
                  </template>
                </Table>

                <Table
                  :isLoading="isUserLoading"
                  :tableHeader="userTableHeader"
                  :paginationSettings="userPaginationSettings"
                  :data="usersData"
                  :has-actions="false"
                >
                </Table>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      <Modal
        ref="modal"
        :title="modalTitle"
        :size="modalSize"
        :height="modalHeight"
      >
        <div v-if="modalContent == 'rootRegion'">
          <NewRootRegion
            :tree="treeData"
            :newLeaf="leafParams"
            :entityId="entityId"
            @refetch="fetchDepots"
            @closeModal="closeAllModals"
          />
        </div>
        <div v-if="modalContent == 'region'">
          <NewRegion
            :tree="treeData"
            :newLeaf="leafParams"
            :entityId="entityId"
            @refetch="fetchDepots"
            @closeModal="closeAllModals"
          />
        </div>
        <div v-if="modalContent == 'new'">
          <NewSite
            :tree="treeData"
            :newLeaf="leafParams"
            :entityId="entityId"
            :rootBusinessName="rootBusinessName"
            @refetch="fetchDepots"
            @closeModal="closeAllModals"
          />
        </div>
        <div v-if="modalContent == 'deactivate'">
          <DeactivateSite
            :siteId="siteIdToDelete"
            @closeModal="closeAllModals"
          />
        </div>
      </Modal>
    </div>
    <div v-if="!readDepots" class="text-center">
      <h1>
        Not authorized to view depot configuration. Please contact your
        {{ userType }}.
      </h1>
    </div>
  </div>
</template>

<script>
import { Tree, VueTreeList } from "vue-tree-list";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { Card, Modal, Table } from "@/components/commons";
import {
  DeactivateSite,
  NewRegion,
  NewRootRegion,
  NewSite,
} from "@/components/forms/depot";
import { mapGetters } from "vuex";
import _ from "lodash";
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import { formatDate, formatPaginationSettings } from "@/_helper";

export default {
  components: {
    Table,
    Card,
    Tab,
    Tabs,
    VueTreeList,
    Modal,
    NewSite,
    NewRegion,
    NewRootRegion,
    DeactivateSite,
  },

  data() {
    return {
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalTitle: "",
      modalContent: false,
      modalSize: "2xl",
      leafParams: {},
      currentLeafPointer: false,
      entityId: "",
      treeData: new Tree([]),
      readDepots: false,
      writeDepots: false,
      userType: null,
      isNotEmptyDepot: false,
      rootBusinessName: "",
      depotLinkage: [],
      siteIdToDelete: null,
      deactivatedTreeData: new Tree([]),
      activeSites: [],
      vehicleCount: 0,
      driversCount: 0,
      tableHeader: [
        "Driver Full Name",
        "Email Address",
        "Licence",
        "Date Created",
      ],
      userTableHeader: ["User Full Name", "Email", "Phone No.", "Role"],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      userPaginationSettings: {
        page: 1,
        totalPages: 1,
        totalRecords: 2,
        visiblePageItemCount: 3,
      },
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      isLoading: false,
      drivers: [],
      selectedDepotName: "",
      selectedSiteId: "",
      users: [],
      isUserLoading: false,
      me: null,
    };
  },

  async created() {
    this.me = this.$store.getters[`account/me`];
    const proxySite = this.$store.getters[`account/proxySite`];
    let securityRoles = this.me?.securityRoles;
    this.userType = securityRoles[0].type;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = this.setSecurityAccess(securityRoles);
      const isProxySite =
        !_.isEmpty(proxySite) &&
        _.isObject(proxySite) &&
        !proxySite.isMainBusiness;
      this.writeDepots = isProxySite ? false : securityAccessObj.writeDepots;
      this.readDepots = isProxySite ? false : securityAccessObj.readDepots;
    }
    await this.fetchDepots();
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },

  methods: {
    async onChangeName(params) {
      const payload = {
        id: params.id,
        name: params.newName,
      };
      await this.$store.dispatch(`business/updateDepotName`, payload);
    },
    recursive(node) {
      for (let i = 0; i < node.children.length; i++) {
        this.recursive(node.children[i]);
        if (!_.isEmpty(this.depotLinkage)) {
          // Adding Web profile uid to the site
          for (let j = 0; j < this.depotLinkage.length; j++) {
            if (this.depotLinkage[j]) {
              if (
                this.depotLinkage[j]?.depotConfig?._id === node.children[i]?._id
              ) {
                node.children[i].uid =
                  this.depotLinkage[j].business?.webProfiles[0]?.uid;
              }
            }
          }
        }
      }
    },
    async fetchDepots() {
      this.entityId = this.me?.business?._id;
      this.rootBusinessName = this.me?.business?.persona?.businessName;

      const depots = await this.$store.dispatch(
        `business/getDepot`,
        this.entityId
      );
      const depotLinkageExist = await this.$store.dispatch(
        `business/getDepotLinkage`,
        this.entityId
      );

      // Trigger fetching of site to display on header
      this.$store.commit("driver/setUpdateHeaderSites", depots);

      const deactivatedSites = await this.$store.dispatch(`business/getSites`, {
        depotId: this.entityId,
        isDeleted: true,
      });
      if (deactivatedSites)
        this.deactivatedTreeData = new Tree(deactivatedSites);

      if (depotLinkageExist) {
        this.depotLinkage = depotLinkageExist;
      }

      const activeSites = await this.$store.dispatch(`business/getSites`, {
        depotId: this.entityId,
        isDeleted: false,
      });
      if (activeSites) this.activeSites = activeSites;

      if (depots.length > 0) {
        for (let i = 0; i < depots.length; i++) {
          this.recursive(depots[i]);
        }
        this.isNotEmptyDepot = true;
        const newDepots = depots.map((item) => {
          let tree = item;
          if (item.isRegion && item.children && item.children.length > 0) {
            tree.delNodeDisabled = true;
            tree.children = item.children.map((children) => {
              if (
                children.isRegion &&
                (!children.children || children.children.length === 0)
              ) {
                children.delNodeDisabled = false;
              }
              return children;
            });
          }
          return tree;
        });
        this.treeData = new Tree([...newDepots]);
      }

      this.$emit("reLoaded");
    },

    async closeAllModals() {
      await this.fetchDepots();
      this.$refs.modal.closeModal();
    },

    onAddNode(params) {
      if (params.isLeaf) {
        params.remove();
        this.modalContent = "new";
        this.modalTitle = "New Site";
        this.$refs.modal.toggleModal();
        this.leafParams = params;
      } else {
        params.remove();
        this.modalContent = "region";
        this.modalTitle = "New Sub-Region";
        this.$refs.modal.toggleModal();
        this.leafParams = params;
      }
    },
    addNode() {
      this.modalContent = "rootRegion";
      this.modalTitle = "New Region";
      this.$refs.modal.toggleModal();
    },
    setSecurityAccess(securityRoles) {
      let initialReadDepots = true;
      let initialWriteDepots = true;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole?.securityConfig;
        if (securityConfig.permissions?.readDepots?.value) {
          initialReadDepots = securityConfig?.permissions?.readDepots.value;
        }
        if (securityConfig.permissions?.writeDepots?.value) {
          initialWriteDepots = securityConfig?.permissions?.writeDepots?.value;
        }
      });

      return {
        readDepots: initialReadDepots,
        writeDepots: initialWriteDepots,
      };
    },
    async moveSite(e) {
      if (e.target.isRegion) {
        const result = await this.$swal.fire({
          title: `You are about to move site to another region.`,
          text: "This action cannot be undone!",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Okay, move it!",
          cancelButtonText: "Cancel",
        });
        const payload = {
          targetDepotId: e.target._id,
          sourceDepotId: e.src._id,
          nodeDepotId: e.node._id,
        };

        this.$emit("updateLoading", true);

        if (result.isConfirmed) {
          await this.$store
            .dispatch(`business/moveSite`, payload)
            .then(() => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Business site successfully moved.",
              });
            })
            .catch((err) => {
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: err.message,
              });
            });
        }
      }

      await this.fetchDepots();
    },
    async removeNode(e) {
      if (e.isRegion) {
        await this.$store
          .dispatch(`business/deleteRegion`, e._id)
          .then(() => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Region successfully deleted.",
            });
            e.remove();
          })
          .catch((err) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: err.message,
            });
          });
      } else {
        this.siteIdToDelete = e._id ? e._id : e.node._id;

        const { value: answer } = await this.$swal.fire({
          title: `Site Deactivation`,
          text: "Do you want to move Drivers and Vehicles?",
          type: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, lets do it!",
          cancelButtonText: "Cancel",
        });

        this.$emit("updateLoading", true);
        if (answer) {
          this.modalContent = "deactivate";
          this.modalTitle = "Site Deactivation";
          this.$refs.modal.toggleModal();
        }
      }

      await this.fetchDepots();
    },
    async fetchTab(name) {
      this.isLoading = true;
      this.isUserLoading = true;
      const selectedSite = this.activeSites.find((item) => item.name === name);
      this.selectedDepotName = name;
      this.selectedSiteId = selectedSite._id;

      await Promise.all([
        this.fetchDriverContracts(),
        this.fetchVehicles(),
        this.fetchDrivers(),
        this.fetchUsers(),
      ]);
    },

    async fetchDrivers(paged = 1) {
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {
          status: {
            $in: ["linked", "linked-non-ewd", "contractor", "pending"],
          },
          siteId: this.selectedSiteId,
        },
      };

      let driverContract = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: this.me?.business._id, query }
      );

      if (driverContract) {
        if (driverContract?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            driverContract?.metadata
          );
          driverContract = driverContract.results;
        }

        this.drivers = this.formatItem(driverContract);
      }

      this.isLoading = false;
    },
    async fetchUsers() {
      const roles = await this.$store.dispatch(`rolesandrespo/getRoles`, null);
      const users = await this.$store.dispatch(`rolesandrespo/getUsers`, {
        site: this.selectedSiteId,
      });
      this.users = this.formatUsers(users, roles);
      this.userPaginationSettings.totalRecords = this.users.length;

      this.isUserLoading = false;
    },
    async fetchVehicles() {
      const query = {
        skip: 0,
        limit: 10,
        search: "",
        filter: { isActive: { $eq: true }, siteId: this.selectedSiteId },
      };
      const vehicles = await this.$store.dispatch(
        `vehicle/getVehicleByEntityId`,
        {
          entityId: this.me?.business._id,
          query,
        }
      );
      this.vehicleCount = vehicles?.metadata?.total || 0;
    },
    async fetchDriverContracts() {
      let query = {
        skip: 0,
        limit: 10,
        search: "",
        filter: {
          status: {
            $in: ["linked", "linked-non-ewd", "contractor", "pending"],
          },
          siteId: this.selectedSiteId,
        },
      };
      let driverContract = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: this.me?.business._id, query }
      );
      this.driversCount = driverContract?.metadata?.total || 0;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.fetchDrivers(event.page);
      }
    },

    parseDrivers(data) {
      return data.map((driver) => {
        return [
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.name,
            itemType: "name",
            hasImage: true,
            status: driver.status,
            trainingStatus: driver.trainingStatus,
            hasDriverBusinessProfile: driver?.hasDriverBusinessProfile,
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.email,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.license,
            itemType: "string",
          },
          {
            driverId: driver.driverId,
            id: driver.id,
            name: driver.created,
            itemType: "string",
          },
        ];
      });
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item?._id,
            driverId: item?.driverId,
            name: item?.driver?.driverName,
            email: item?.driver?.emailAddress || "-",
            license: item?.driver?.driversLicenseNumber,
            status: item?.status ? item?.status.toUpperCase() : null,
            created: item?.createdAt ? formatDate(item?.createdAt) : "",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },

    formatUsers(users, roles) {
      return users.map((user) => {
        return {
          id: user._id,
          email: user.email || "",
          userName: user.userName || "",
          phoneNumber: user.phoneNumber || "",
          isRoot: user.isRoot,
          uid: user.uid || null,
          role: this.findRole(user.uid, roles),
        };
      });
    },

    parseUsers(data) {
      return data.map((userData) => [
        {
          id: userData.id,
          name: userData.userName,
          itemType: "string",
          uid: userData.uid,
        },
        {
          id: userData.id,
          name: userData.email,
          itemType: "string",
          uid: userData.uid,
        },
        {
          id: userData.id,
          name: userData.phoneNumber,
          itemType: "string",
          uid: userData.uid,
        },
        {
          id: userData.id,
          name: userData.role,
          itemType: "string",
          uid: userData.uid,
        },
      ]);
    },

    findRole(uid, roles) {
      let name = "Admin";
      roles.map((role) => {
        role.uids.map((roleuid) => {
          if (roleuid == uid) {
            name = role.name;
          }
        });
      });

      return name;
    },

    async filterChange() {
      await this.fetchDrivers();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.fetchTab(this.selectedDepotName);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    driversData() {
      if (this.drivers) {
        return this.parseDrivers(this.drivers);
      } else {
        return [];
      }
    },
    usersData() {
      if (this.users) {
        return this.parseUsers(this.users);
      } else {
        return [];
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" rel="stylesheet/scss" scoped>
.vtl {
  .vtl-drag-disabled {
    background-color: #d0cfcf;

    &:hover {
      background-color: #d0cfcf;
    }
  }

  .vtl-disabled {
    background-color: #d0cfcf;
  }
}

.icon {
  &:hover {
    cursor: pointer;
  }
}

.muted {
  color: gray;
  font-size: 80%;
}
</style>
<style lang="scss">
.swal2-title {
  font-size: 18px !important;
  padding-top: 20px !important;
  margin-top: 10px !important;
}

.swal2-label {
  font-size: 14px !important;
}

.depot-tree-wrapper .table-wrapper {
  display: inline !important;
}
</style>
