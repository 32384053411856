import { render, staticRenderFns } from "./duration-terms.vue?vue&type=template&id=178d3ed0"
import script from "./duration-terms.vue?vue&type=script&lang=js"
export * from "./duration-terms.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports