<template>
  <div>
    <div class="flex flex-row mb-5 w-full border-b pb-1">
      <div class="flex md:ml-0 ml-12 mt-3">
        <div class="font-bold">{{ configTitle }}</div>
      </div>
      <div class="ml-auto">
        <button
          class="btn btn-primary"
          @click.prevent="itemAdd"
          v-if="!loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add
        </button>
      </div>
    </div>
    <div class="overflow-y-auto">
      <div class="loading mt-32 h-32" v-if="loading">
        <ui-spinner class="mx-auto"></ui-spinner>
      </div>
      <table class="min-w-full leading-normal table-fixed" v-if="!loading">
        <thead>
          <tr>
            <th
              class="px-5 py-3 w-4/5 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
            >
              Name
            </th>
            <th
              class="px-5 py-3 border-gray-200 bg-gray-200 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
            >
              Status
            </th>
            <th
              class="px-5 py-3 border-gray-200 bg-gray-200 text-right text-xs font-semibold text-gray-600 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(configItem, index) in types"
            :key="index"
            class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
            :class="configItem.isEdit ? 'bg-yellow-50 hover:bg-yellow-50' : ''"
          >
            <td class="px-5 py-2">
              <input
                class="w-11/12"
                :disabled="!configItem.isEdit"
                configItem="text"
                v-model="configItem.name"
              />
            </td>
            <td class="px-2 py-2 text-center">
              <div v-if="!configItem.isDefault && configItem.id">
                <input
                  type="checkbox"
                  :disabled="!configItem.isEdit"
                  v-model="configItem.isActive"
                />
              </div>
              <div v-if="configItem.isDefault">
                <span class="text-xs text-gray-500 italic">default</span>
              </div>
            </td>
            <td class="px-2 py-2 flex justify-end">
              <div v-if="!configItem.isDefault">
                <div v-if="!configItem.isEdit" class="flex">
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="itemEdit(index)"
                    ><font-awesome-icon icon="edit" class="my-auto mx-1.5"
                  /></a>
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="itemImageUpload(index)"
                    ><font-awesome-icon icon="image" class="my-auto mx-1.5"
                  /></a>
                </div>
                <div v-if="configItem.isEdit" class="flex">
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="
                      itemSave(
                        configItem.id,
                        configItem.name,
                        configItem.isActive
                      )
                    "
                    ><font-awesome-icon icon="check" class="my-auto mx-1.5"
                  /></a>
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="itemDiscard(index)"
                    ><font-awesome-icon icon="times" class="my-auto mx-1.5"
                  /></a>
                </div>
              </div>
              <div v-if="configItem.isDefault">
                <div v-if="!configItem.isEdit" class="flex">
                  <font-awesome-icon
                    icon="edit"
                    class="my-auto mx-1.5 text-gray-200"
                  />
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Modal
      ref="vehicleTypeImageSelector"
      :title="
        targetVehicleType?.image && !selectOtherImageToggle
          ? 'Current Image'
          : 'Vehicle Type Image Selector'
      "
      size="5xl"
      :height="modalHeight"
      @closeModal="closeModal"
    >
      <!-- Image Viewer Component -->
      <div
        v-if="targetVehicleType?.image && !selectOtherImageToggle"
        class="text-center"
      >
        <div>
          <img
            :src="targetVehicleType?.image"
            alt="Vehicle Type Image"
            style="margin: 0 auto"
          />
        </div>

        <button
          @click="selectOtherImageToggle = true"
          class="btn btn-primary my-10"
          :style="{ backgroundColor: theme.primaryColor }"
        >
          Select Another Image
        </button>
      </div>
      <!-- Image Selector Component -->
      <div v-else>
        <!-- Pagination Top -->
        <div class="grid grid-cols-2 gap-8 px-20 mx-20">
          <button
            class="btn btn-primary"
            :style="{ backgroundColor: theme.secondaryColor }"
            @click="prevImage"
            :disabled="paginationSettings.page === 1"
          >
            Previous
          </button>
          <button
            class="btn btn-primary"
            :style="{ backgroundColor: theme.secondaryColor }"
            @click="nextImage"
            :disabled="
              paginationSettings.page === paginationSettings.totalPages
            "
          >
            Next
          </button>
        </div>
        <!-- Images -->
        <div class="grid grid-cols-4">
          <div v-if="imagesLoading" class="loading centered">
            <ui-spinner class="mx-auto"></ui-spinner>
          </div>
          <div v-else v-for="({ image }, index) in images" :key="index">
            <div class="vehicle-image" @click="selectedImageIndex = index">
              <img
                :src="image"
                alt="Vehicle Type Image"
                style="margin: 0 auto"
              />
            </div>
            <div class="text-center">
              <button
                v-if="selectedImageIndex === index"
                class="btn btn-primary"
                :style="{
                  backgroundColor: theme.secondaryColor,
                  padding: '0px 10px 0px 10px',
                  margin: '0px 30px 0px 30px',
                }"
                @click="updateVehicleTypeImage(index)"
              >
                Select
              </button>
            </div>
          </div>
        </div>
        <!-- Pagination Top -->
        <div class="grid grid-cols-2 gap-8 px-20 mx-20 mt-10">
          <button
            class="btn btn-primary"
            :style="{ backgroundColor: theme.secondaryColor }"
            @click="prevImage"
            :disabled="paginationSettings.page === 1"
          >
            Previous
          </button>
          <button
            class="btn btn-primary"
            :style="{ backgroundColor: theme.secondaryColor }"
            @click="nextImage"
            :disabled="
              paginationSettings.page === paginationSettings.totalPages
            "
          >
            Next
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { mapGetters } from "vuex";
import { Modal } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatPaginationSettings } from "@/_helper";

export default {
  name: "Vehicle-Config-Types",
  components: {
    Modal,
  },
  props: {
    vehicleClass: String,
  },
  data() {
    return {
      types: [],
      loading: false,
      title: "Vehicle Types",
      dataholder: {
        name: "",
      },
      dataFilter: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      targetVehicleType: null,
      images: [],
      limit: 12,
      imagesLoading: false,
      paginationSettings: { page: 1 },
      selectedImageIndex: null,
      selectOtherImageToggle: false,
    };
  },
  async mounted() {
    this.loading = true;
    var filter = "LV";
    if (this.vehicleClass === "vehicle-types-hv") filter = "HV";
    else if (this.vehicleClass === "vehicle-types-mv") filter = "MV";

    await this.$store
      .dispatch(`vehicle/getVehicleClassSearch`, { searchStr: filter })
      .then((item) => {
        if (item) {
          this.datafilter = item[0]._id;
          this.init(this.datafilter);
        }
      });
  },
  methods: {
    async init(filter) {
      if (filter) {
        const configData = await this.$store.dispatch(
          `vehicle/getVehicleTypeSearch`,
          { vehicleClassId: filter }
        );
        if (configData) {
          this.types = this.formatItem(
            orderBy(configData, ["createdAt"], ["asc"])
          );
          this.loading = false;
        }
      }
    },
    itemEdit(index) {
      this.dataholder.name = this.types[index].name;
      this.dataholder.select = this.types[index].class;
      this.types[index].isEdit = true;
    },
    itemSave(id, name, status) {
      if (id) {
        this.$store
          .dispatch(`vehicle/updateVehicleType`, {
            id: id,
            name: name,
            isActive: status,
            vehicleClassId: this.datafilter,
          })
          .then((item) => {
            if (item && item._id) {
              this.init(this.datafilter);
            }
          });
      } else {
        this.$store
          .dispatch(`vehicle/createVehicleType`, {
            name: name,
            vehicleClassId: this.datafilter,
          })
          .then((item) => {
            if (item && item._id) {
              this.init(this.datafilter);
            }
          });
      }
    },
    itemDiscard(index) {
      if (this.types[index].id == null) {
        this.types.splice(this.types.indexOf(index), 1);
      } else {
        this.types[index].name = this.dataholder.name;
        this.types[index].vehicleclass = this.dataholder.select;
        this.types[index].isEdit = false;
      }
    },
    itemAdd() {
      var valObj = this.types.filter(function (elem) {
        if (elem.id == null && elem.isEdit == true) {
          return elem;
        }
      });

      if (valObj.length == 0) {
        this.types.push({
          id: null,
          name: "",
          compat: null,
          status: false,
          isEdit: true,
        });
      }
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const tempItem = {
            id: item["_id"],
            name: item["name"],
            isActive: item["isActive"],
            isDefault: item["isDefault"],
            isEdit: false,
            image: item["image"],
          };
          nItems.push(tempItem);
        });
      }
      return nItems;
    },
    itemImageUpload(index) {
      this.targetVehicleType = this.types[index];
      this.$refs.vehicleTypeImageSelector.openModal();
      if (!this.images?.length) {
        this.fetchVehicleTypeImages();
      }
    },
    closeModal() {
      this.selectOtherImageToggle = false;
      this.targetVehicleType = null;
      this.paginationSettings.page = 1;
      this.$refs.vehicleTypeImageSelector.closeModal();
      this.init(this.datafilter);
    },

    async fetchVehicleTypeImages(page = 1) {
      this.imagesLoading = true;
      try {
        const results = await this.$store.dispatch(
          "vehicle/fetchVehicleTypeImages",
          {
            skip: page * this.limit - this.limit,
            limit: this.limit,
          }
        );

        this.paginationSettings = formatPaginationSettings(results.resultsMeta);
        this.images = results.data;
      } catch (error) {
        this.toast("error", "Fetching images failed");
      }
      this.imagesLoading = false;
    },

    nextImage() {
      this.paginationSettings.page += 1;
      this.fetchVehicleTypeImages(this.paginationSettings.page);
    },

    prevImage() {
      this.paginationSettings.page -= 1;
      this.fetchVehicleTypeImages(this.paginationSettings.page);
    },

    async updateVehicleTypeImage(index) {
      const { image } = this.images[index];
      this.imagesLoading = true;
      try {
        await this.$store.dispatch("vehicle/uploadImageVehicleType", {
          id: this.targetVehicleType?.id,
          image,
        });
        this.toast("success", "Vehicle type image update successful");
      } catch (error) {
        this.toast("error", "Vehicle type image update failed");
      }
      this.imagesLoading = false;
      this.closeModal();
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
  },
  computed: {
    configTitle() {
      if (this.vehicleClass === "vehicle-types-lv") return "Light Vehicles";
      else if (this.vehicleClass === "vehicle-types-mv")
        return "Medium Vehicles";
      else return "Heavy Vehicles";
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style>
.vehicle-image {
  border: 1px solid;
  padding: 10px;
  margin: 30px;
  min-width: 200px;
  max-width: 200px;
  min-height: 180px;
  max-height: 180px;
}
</style>
