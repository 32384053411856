var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormulateForm',{on:{"submit":_vm.onNext}},[_c('div',{staticClass:"text-center text-2xl"},[_vm._v("RRP")]),_c('div',{staticClass:"mt-5"},[(_vm.loading)?_c('div',{staticClass:"loading mt-32 h-32"},[_c('ui-spinner',{staticClass:"mx-auto"})],1):_vm._e(),_c('div',{staticClass:"mt-5 overflow-x-auto"},[_c('div',{staticClass:"inline-block min-w-full shadow rounded-lg overflow-hidden"},[(!_vm.loading)?_c('table',{staticClass:"table-auto leading-normal p-4"},[_c('thead',[_c('tr',[_c('th',{staticClass:"bg-gray-100 w-2/5 text-left"},[_vm._v("Product")]),_c('th',{staticClass:"bg-gray-200"},[_vm._v("Monthly")]),_c('th',{staticClass:"bg-gray-200"},[_vm._v("Yearly")])])]),_c('tbody',_vm._l((_vm.values),function(product,index){return _c('tr',{key:index,staticClass:"border-t-2"},[(product)?_c('td',{staticClass:"w-80 pl-5 pr-8 text-sm bg-gray-100"},[_vm._v(" "+_vm._s(product.name === "EWD-FT" ? "EWD" : product.name)+" ")]):_vm._e(),_c('td',{staticClass:"pt-5 px-5 text-center"},[_c('div',{staticClass:"flex relative pb-9 h-40"},[_c('span',{staticClass:"pt-2 w-0"},[_vm._v("$")]),_c('FormulateInput',{attrs:{"type":"number","step":"0.01","min":"0","placeholder":"0","input-class":"w-48 h-7 ml-3 text-right py-4 px-0 border outline-none","error-behavior":"live","value":_vm.parsePrice(product.rrp.monthly),"name":`${_vm.removeSpaces(product.name)}.rrp.monthly`,"autocomplete":"off"},on:{"input":function($event){return _vm.perMonthInput(
                        $event,
                        index,
                        false,
                        product.pricing.monthly
                      )}}}),_c('ul',{ref:"perMonthRef",refInFor:true,staticClass:"absolute mt-12 ml-6 opacity-90 text-xs list-disc text-left",domProps:{"innerHTML":_vm._s(_vm.perMonthDesc[index])}})],1)]),_c('td',{staticClass:"pt-5 px-5 text-center"},[_c('div',{staticClass:"flex relative pb-9 h-40"},[_c('span',{staticClass:"pt-2 w-0"},[_vm._v("$")]),_c('FormulateInput',{attrs:{"type":"number","step":"0.01","min":"0","placeholder":"0","input-class":"w-48 h-7 ml-3 text-right py-4 px-0 border outline-none","error-behavior":"live","value":_vm.parsePrice(product.rrp.yearly),"name":`${_vm.removeSpaces(product.name)}.rrp.yearly`,"autocomplete":"off"},on:{"input":function($event){return _vm.yearlyPerMonthInput(
                        $event,
                        index,
                        false,
                        product.pricing.yearly
                      )}}}),_c('ul',{ref:"yearlyPerMonthRef",refInFor:true,staticClass:"absolute mt-12 ml-8 opacity-90 text-xs list-disc text-left",domProps:{"innerHTML":_vm._s(_vm.yearlyPerMonthDesc[index])}})],1)])])}),0)]):_vm._e()])]),_c('div',{staticClass:"pt-4 flex flex-col mb-20"},[_c('FormulateInput',{style:({ backgroundColor: _vm.theme.secondaryColor }),attrs:{"type":"submit","input-class":"btn btn-primary w-full","disabled":_vm.submitLoading}},[(_vm.submitLoading)?_c('span',[_c('font-awesome-icon',{staticClass:"mr-1 loader",attrs:{"icon":"spinner"}}),_vm._v("Saving data... ")],1):_vm._e(),(!_vm.submitLoading)?_c('span',[_vm._v("Submit")]):_vm._e()]),_c('button',{staticClass:"btn bg-transparent border col-span-2 btn-info mt-2",attrs:{"disabled":_vm.submitLoading},on:{"click":function($event){$event.preventDefault();return _vm.onPrevious.apply(null, arguments)}}},[_vm._v(" Back ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }