<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <div class="p-5 mt-5 mb-10 border rounded-lg group">
          <div class="group-title pb-5 font-bold bg-gray-50">
            Duration and Product Count Details
          </div>

          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Contract Period:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ preData.contractDuration.label }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Billing Period:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ preData.billingPeriod.label }}</div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="loading && preData.selectedModules.cplValues.length > 0"
          class="text-center mb-16"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
          Generating Core platform licences pricing table...
        </div>
        <div
          v-if="!loading && cplValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold bg-gray-50">
            Core Platform Licences Pricing Summary
          </div>
          .

          <table class="table-auto leading-normal p-4 w-full">
            <thead>
              <th
                class="bg-gray-100 text-center p-3 font-semibold justify-center"
                width="40%"
              >
                Core Platform Licences Modules
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                Customer Buy Price
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                Your Buy Price
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                Margin
              </th>
            </thead>

            <tr v-for="(modules, index) in cplValues" :key="index">
              <td :key="index" class="px-3" width="40%">
                {{
                  modules.standardPricingMaster.name === "EWD-FT"
                    ? "EWD"
                    : modules.standardPricingMaster.name
                }}
              </td>
              <td class="p-2">
                <div class="text-center">${{ modules.rrpPrice }}</div>
              </td>
              <td class="p-2">
                <div class="text-center">
                  ${{ modules.manualOffer.toFixed(2) }}
                </div>
              </td>
              <td class="p-2">
                <div class="text-center">${{ modules.margin }}</div>
              </td>
            </tr>
          </table>
        </div>

        <div
          v-if="!loading && smValues.length > 0"
          class="p-5 mt-5 mb-10 border rounded-lg group"
        >
          <div class="group-title pb-5 font-bold bg-gray-50">
            Specialty Modules Pricing Summary
          </div>
          .

          <table class="table-auto leading-normal p-4 w-full">
            <thead>
              <th
                class="bg-gray-100 text-center p-3 font-semibold justify-center"
                width="40%"
              >
                Specialty Modules
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                Customer Buy Price
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                Your Buy Price
              </th>
              <th
                class="bg-gray-200 text-center p-3 font-semibold justify-center"
              >
                Margin
              </th>
            </thead>

            <tr v-for="(modules, index) in smValues" :key="index">
              <td :key="index" class="px-3" width="40%">
                {{
                  modules.standardPricingMaster.name === "EWD-FT"
                    ? "EWD"
                    : modules.standardPricingMaster.name
                }}
              </td>
              <td class="p-2">
                <div class="text-center">${{ modules.rrpPrice }}</div>
              </td>
              <td class="p-2">
                <div class="text-center">
                  ${{ modules.manualOffer.toFixed(2) }}
                </div>
              </td>
              <td class="p-2">
                <div class="text-center">${{ modules.margin }}</div>
              </td>
            </tr>
          </table>
        </div>

        <div class="text-center mt-3">
          <div class="font-semi text-black-primary text-2xl mb-4">
            {{ logmasterTerm.name }}
          </div>
        </div>
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="submitLoading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!submitLoading">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Summary",
  props: {
    preData: {},
  },
  data() {
    return {
      loading: false,
      submitLoading: false,
      values: {},
      errorMessage: "",
      cplValues: {},
      smValues: {},
      logmasterTerm: [],
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.preData.businessData.entityId = await this.$store.getters[
        `account/me`
      ].entity._id;

      this.loading = true;

      this.cplValues = this.preData.cplPricing.map((cpl) => {
        let rrpComputedPrice = 0;

        if (cpl.pricingType === "Base") {
          if (this.preData.billingPeriod.label === "Monthly") {
            rrpComputedPrice = Number(cpl.pricing.monthly);
          }
          if (this.preData.billingPeriod.label === "Yearly") {
            rrpComputedPrice = Number(cpl.pricing.monthly) * 12;
          }
        }

        if (cpl.pricingType === "Yearly Discount") {
          if (this.preData.billingPeriod.label === "Monthly") {
            rrpComputedPrice = Number(cpl.pricing.yearly) / 12;
          }
          if (this.preData.billingPeriod.label === "Yearly") {
            rrpComputedPrice = Number(cpl.pricing.yearly);
          }
        }

        return {
          ...cpl,
          rrpPrice: rrpComputedPrice.toFixed(2),
          margin: (rrpComputedPrice - cpl.manualOffer).toFixed(2),
        };
      });

      this.smValues = this.preData.smPricing.map((sm) => {
        let smComputedPrice = 0;

        if (sm.pricingType === "Base") {
          if (this.preData.billingPeriod.label === "Monthly") {
            smComputedPrice = Number(sm.pricing.monthly);
          }
          if (this.preData.billingPeriod.label === "Yearly") {
            smComputedPrice = Number(sm.pricing.monthly) * 12;
          }
        }

        if (sm.pricingType === "Yearly Discount") {
          if (this.preData.billingPeriod.label === "Monthly") {
            smComputedPrice = Number(sm.pricing.yearly) / 12;
          }
          if (this.preData.billingPeriod.label === "Yearly") {
            smComputedPrice = Number(sm.pricing.yearly);
          }
        }

        return {
          ...sm,
          rrpPrice: smComputedPrice.toFixed(2),
          margin: (smComputedPrice - sm.manualOffer).toFixed(2),
        };
      });

      const me = this.$store.getters[`account/me`];

      this.logmasterTerm =
        (await this.$store.dispatch(
          "contract/getLogmasterTermByPartner",
          me?.uid
        )) || [];

      this.loading = false;
    },
    async onSubmit() {
      this.submitLoading = true;
      const mergedModulesvalues = [...this.cplValues, ...this.smValues];
      const formatPayloadModules = mergedModulesvalues.map((modules) => ({
        minimums: modules.numberOfSeats,
        price: {
          monthly:
            this.preData.billingPeriod.label === "Monthly"
              ? Number(modules.rrpPrice)
              : 0,
          quarterly: 0, //Zero for now as we don't support it yet
          yearly:
            this.preData.billingPeriod.label === "Yearly"
              ? Number(modules.rrpPrice)
              : 0,
        },
        masterId: modules.standardPricingMaster._id,
      }));

      this.$store
        .dispatch(`business/createBusiness`, this.preData.businessData)
        .then((business) => {
          if (business && business._id) {
            this.loading = false;
            const businessModulesDto = formatPayloadModules.map((bModule) => {
              bModule.ownerMongoId = business._id;
              bModule.ownerRole = "business";
              return bModule;
            });
            const payload = {
              billingPeriodId: this.preData.billingPeriod.value,
              businessMongoId: business._id,
              businessModulesDto,
              contractDurationId: this.preData.contractDuration.value,
              contractDurationYears: Number(this.preData.yearCount) || 0,
            };

            this.$store
              .dispatch("contract/createBusinessContract", payload)
              .catch((error) => {
                this.loading = false;
                this.submitLoading = false;
                this.errorMessage = error?.message;
              });
            this.$emit("closeModal");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Business successfully created.",
            });
            setTimeout(() => {
              location.reload();
            }, 4000);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.submitLoading = false;
          this.errorMessage = error?.message;
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
</style>
