<template>
  <div class="px-5 py-8" style="min-height: calc(100vh - (112px + 2.75rem))">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <div>
        <div class="flex">
          <DashboardCard
            :cardIcon="{ name: 'id-card' }"
            :cardTitle="'Total Driver Count'"
            :notificationCount="driverCount"
            class="mr-5"
          />
          <DashboardCard
            :cardIcon="{ name: 'truck' }"
            :cardTitle="'Total Vehicle Count'"
            :notificationCount="vehicleCount"
            class="mr-5"
          />
        </div>
        <div class="flex">
          <DashboardCard
            :cardIcon="{ name: 'briefcase' }"
            :cardTitle="'Total Business Linked'"
            :notificationCount="businessCount"
            class="mr-5"
          />
        </div>
      </div>
      <div>
        <div class="flex">
          <Card :cardWidth="'100%'" class="sm:mx-0 md:mr-5">
            <template slot="cardContent">
              <div class="mb-3">
                <span
                  class="font-bold text-logmaster-500 text-l"
                  :style="{ color: theme.primaryColor }"
                >
                  Notifications
                </span>
                <span class="float-right pr-2 text-xs">
                  <a @click="refreshNotifications()"
                    ><font-awesome-icon
                      icon="retweet"
                      :style="{ color: theme.primaryColor }"
                    ></font-awesome-icon
                  ></a>
                </span>
                <span
                  v-if="notifications.length > 5"
                  class="float-right pr-2 text-xs"
                  :style="{ color: theme.primaryColor }"
                >
                  <a href="/account/notifications">More</a>
                </span>
              </div>
              <div class="flex w-full h-full" v-if="notificationLoading">
                <div class="m-auto">
                  <Spinner class="mx-auto mb-2"></Spinner>
                  <span class="font-semibold text-gray-300">Loading...</span>
                </div>
              </div>
              <table
                v-if="notifications && !notificationLoading"
                class="min-w-full leading-normal"
              >
                <tbody>
                  <tr
                    v-for="(notification, index) in notifications.slice(0, 5)"
                    :key="index"
                    class="text-sm border-b hover:bg-gray-200"
                  >
                    <td v-if="notification.title">
                      <p>
                        <b :style="{ color: theme.secondaryColor }">{{
                          notification.title
                        }}</b>
                      </p>
                    </td>
                    <td v-if="notification.description">
                      <a class="block px-0 py-3 overflow-hidden"
                        >{{ notification.description }}
                      </a>
                    </td>
                    <td>
                      <a
                        v-if="notification.createdAt"
                        class="block float-right px-0 py-3 overflow-hidden text-xs"
                        >{{ showDate(notification.createdAt) }}</a
                      >
                    </td>
                    <td>
                      <a
                        v-if="notification.createdAt"
                        class="block float-right px-0 py-3 overflow-hidden text-xs"
                        >{{ getHumanDate(notification.createdAt) }}</a
                      >
                    </td>
                    <td v-if="!notification.showForm">
                      <a
                        class="block float-right max-w-2xl px-0 py-3 overflow-hidden text-xs font-bold"
                        >{{ notification.note }}</a
                      >
                    </td>
                    <td
                      v-if="
                        notification.actionItems.length > 0 &&
                        !notification.showForm
                      "
                      title="Edit Note"
                      class="p-0 mr-0"
                    >
                      <button
                        @click="notification.showForm = !notification.showForm"
                        class="ml-4 text-sm btn btn-sm btn-primary"
                        :style="{ backgroundColor: theme.secondaryColor }"
                      >
                        <font-awesome-icon
                          icon="sticky-note"
                        ></font-awesome-icon>
                      </button>
                    </td>
                    <td
                      v-if="notification.showForm"
                      title="Note"
                      class="p-0 mr-0"
                    >
                      <FormulateInput
                        type="text"
                        v-model="notification.note"
                        placeholder="Note"
                        class="pt-4 ml-4 text-xs"
                      />
                    </td>
                    <td
                      v-if="notification.showForm"
                      title="Note"
                      class="p-0 mr-0"
                    >
                      <button
                        @click="
                          saveNote(notification);
                          notification.showForm = false;
                        "
                        class="ml-4 text-sm btn btn-sm btn-primary"
                        title="Save"
                        :style="{ backgroundColor: theme.secondaryColor }"
                      >
                        <font-awesome-icon icon="check"></font-awesome-icon>
                      </button>
                      <button
                        @click="notification.showForm = false"
                        class="ml-4 text-sm btn btn-sm btn-primary"
                        title="Close"
                        :style="{ backgroundColor: theme.secondaryColor }"
                      >
                        <font-awesome-icon icon="times"></font-awesome-icon>
                      </button>
                    </td>
                    <td
                      v-if="notification.actionItems.length > 0"
                      title="Acknowledge"
                      class="p-0 mr-0"
                    >
                      <button
                        @click="acknowledge(notification)"
                        class="ml-4 text-sm btn btn-sm btn-primary"
                        :style="{ backgroundColor: theme.secondaryColor }"
                      >
                        <font-awesome-icon icon="trash"></font-awesome-icon>
                      </button>
                    </td>
                    <td title="View" class="p-0 mr-0">
                      <button
                        @click="view(notification)"
                        class="ml-4 text-sm btn btn-sm btn-primary"
                        :style="{ backgroundColor: theme.secondaryColor }"
                      >
                        <font-awesome-icon icon="eye"></font-awesome-icon>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div v-if="notifications.length === 0">
                <p>No notification found.</p>
              </div>
            </template>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Card, DashboardCard } from "@/components/commons";
import Spinner from "@/components/commons/ui/Spinner.vue";
import moment from "moment/moment";
import { formatDate } from "@/_helper";

export default {
  name: "Partner-Dashboard",
  components: {
    Card,
    Spinner,
    DashboardCard,
  },
  data() {
    return {
      style: {},
      driverCount: "0",
      vehicleCount: "0",
      businessCount: "0",
      notifications: [],
      loading: false,
      notificationLoading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const uid = this.$store.getters[`account/me`]?.uid;

    const counts = await this.$store.dispatch(`partner/getDashboardData`, uid);
    const { driverCount, vehicleCount, businessCount } = counts;
    this.driverCount = driverCount?.toString() || "0";
    this.vehicleCount = vehicleCount?.toString() || "0";
    this.businessCount = businessCount?.toString() || "0";

    let notifications = await this.$store.dispatch(
      `notification/getAllUnAcknowledgedNotifications`
    );
    if (notifications) {
      this.notifications = this.formatNotifications(notifications);
    } else {
      this.notifications = [];
    }
    this.loading = false;
  },
  methods: {
    async saveNote(notification) {
      const payload = {
        note: notification.note,
        isCompleted: false,
        id: notification.actionItems[notification.actionItems.length - 1]._id,
      };
      await this.$store
        .dispatch(`notification/updateItemStatus`, payload)
        .then(() => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Note successfully saved.",
          });
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });
    },

    async refreshNotifications() {
      this.notificationLoading = true;
      const notifications = await this.$store.dispatch(
        `notification/getAllUnAcknowledgedNotifications`
      );
      if (notifications) {
        this.notifications = this.formatNotifications(notifications);
      } else {
        this.notifications = [];
      }
      this.notificationLoading = false;
    },

    formatNotifications(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item["_id"],
            actionItems: item["actionItems"] || [],
            createdAt: item["createdAt"] || "",
            description: item["description"] || "",
            metadata: item["metadata"] || "",
            title: item["title"] || "",
            note: item["actionItems"][item.actionItems.length - 1]?.note || "",
            showForm: false,
          };
          nItems.push(vehicle);
        });
      }

      return nItems;
    },

    getHumanDate: function (date) {
      return date ? moment(date).fromNow() : "";
    },

    showDate(date) {
      return formatDate(date);
    },

    view(notification) {
      switch (notification.title) {
        case "New Solo Driver":
          this.viewSoloDriver();
          break;
        case "New Business Lead":
          this.viewBusinessLead();
          break;
        case "Change Licence Count Request":
          this.viewLicenceRequest();
          break;
        default:
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: "Details Unavailable!",
          });
      }
    },

    viewSoloDriver() {
      this.$router.push("/partner/solo");
    },
    viewBusinessLead() {
      this.$router.push("/partner/lead-management");
    },
    viewLicenceRequest() {
      this.$router.push("/partner/licence-request");
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
