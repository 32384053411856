<template>
  <div class="mb-16">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Fetching data...</span>
      </div>
    </div>
    <div v-else class="items-center justify-between">
      <FormulateForm
        v-model="promoLinkForm"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="text"
          name="linkName"
          label="Promo Link Name"
          validation="required"
        />
        <FormulateInput
          type="select"
          name="linkTarget"
          label="Promo Link Target"
          validation="required"
          :options="{ business: 'Business', solo: 'Solo' }"
        />
        <FormulateInput
          type="select"
          name="promoType"
          label="Promo Type"
          validation="required"
          :options="{ demoPeriod: 'Demo Period' }"
        /><!-- to follow discount promo -->
        <FormulateInput
          type="number"
          v-if="promoLinkForm.promoType == 'discount'"
          name="promoValue"
          label="Value in %"
        />
        <FormulateInput
          type="number"
          v-else-if="promoLinkForm.promoType == 'demoPeriod'"
          name="promoValue"
          min="1"
          max="8"
          label="Demo Period in number of weeks"
          help="*Maximum of 8 weeks"
          validation="required|min:1|max:8"
        />
        <FormulateInput
          type="number"
          name="maxUserCount"
          label="Maximum user signup"
          validation="required"
          help="*0 for no limit"
        />
        <FormulateInput
          type="date"
          name="startDate"
          label="Promo Start Date"
          validation="required"
        />
        <FormulateInput
          type="date"
          name="expiryDate"
          label="Promo End Date"
          validation="required"
        />
        <FormulateInput
          type="submit"
          label="Submit"
          @click.prevent="onSubmit()"
          input-class="btn btn-primary w-full"
          :disabled="formLoading || hasErrors"
        >
          <span v-if="formLoading"
            ><font-awesome-icon
              icon="spinner"
              class="mr-1 loader"
            />Submitting</span
          >
          <span v-if="!formLoading">Submit</span>
        </FormulateInput>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/commons/ui/Spinner";
export default {
  name: "New-Promo-Link-Form",
  components: { Spinner },
  props: {},
  data() {
    return {
      promoLinkForm: {
        linkName: "",
        linkTarget: "",
        maxUserCount: 0,
        startDate: "",
        expiryDate: "",
        promoType: "demoPeriod",
        promoValue: 0,
      },
      loading: false,
      formLoading: false,
      errorMessage: "",
    };
  },
  methods: {
    onSubmit() {
      this.formLoading = true;
      this.createPromoLink();
    },
    createPromoLink() {
      this.me = this.$store.getters[`account/me`];
      this.promoLinkForm.partnerId = this.me.entity._id;
      this.promoLinkForm.linkType = "promo-signup";
      this.promoLinkForm.baseUrl = process.env.VUE_APP_URL;

      this.$store
        .dispatch("dynamicLink/createPromoSignupLink", this.promoLinkForm)
        .then(() => {
          this.loading = false;
          this.formLoading = false;
          this.$emit("closeModal");
          this.$emit("submitSuccess");
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Promo Link successfully created.",
          });
          this.$emit("refreshData");
        })
        .catch((error) => {
          this.loading = false;
          this.formLoading = false;
          this.promoLinkForm.errors = error.response.data.errors;
          this.errorMessage = error.response.data.errors;
        });
    },
  },
};
</script>
