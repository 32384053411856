<template>
  <div class="flex min-h-screen justify-center items-center bg-black">
    <div class="container py-10 flex h-full flex-col xl:w-1/2 lg:w-2/3 w-full px-4">      
      <div class="text-center m-5">
        <a :href="$store.getters['baseURL']"
          ><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"
        /></a>
      </div>
      <div v-if="isNewDriver" class="rounded-lg bg-gray-50 p-8 shadow">
        <NewContractorInviteForm 
            :link-data="linkData"
            @newDriverAdded="newDriverAdded"
        />
      </div>
      <div v-else class="rounded-lg bg-gray-50 p-8 shadow">
        <h2></h2>
        <div class="flex flex-col space-y-2 text-center">
          <FormulateInput 
            type="button"
            label="Create a new driver account"
            @click="isNewDriver = true"
            input-class="btn btn-primary w-full"
          />
          <span>or</span>
          <FormulateInput 
            type="button"
            label="Login with an existing driver account"
            input-class="btn btn-primary w-full"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NewContractorInviteForm from "@/components/forms/public/contractor-signup/new-contractor-invite-form.vue"
export default {
  components: {
    NewContractorInviteForm
  },
  data() {
    return {
      isNewDriver: false,
      linkData: {}
    }
  },

  async created() {
    await this.fetchContractorInviteLink();
  },
  methods: {
    async fetchContractorInviteLink() {
      const fullUrl = window.location.origin + window.location.pathname;
      const params = {
        url: fullUrl        
      }
      await this.$store.dispatch(`dynamicLink/getContractorLinkByUrl`, params).then((response) => {
        if (response) {
          this.linkData = response.data;
        }
      })
      .catch((error) => {
        this.hasError = true;
        this.errorMessage = error?.data.message;
        console.log(error.data)
      })
    },
    newDriverAdded(driver) {
      this.isDriverAdded = true;
      this.driver = driver;
    },
  }
  
}
</script>