<template>
  <div class="mb-16">
    <div
      v-if="activeData.activationOption !== 'recurring_payment'"
      class="p-4 flex justify-center items-center space-x-4"
    >
      <div class="font-medium">Invoice</div>
      <div class="font-medium">
        <font-awesome-icon icon="exchange-alt"></font-awesome-icon>
      </div>
      <div class="font-medium">Credit Card</div>
    </div>
    <div v-else class="p-4 flex justify-center items-center space-x-4">
      <div class="font-medium">Credit Card</div>
      <div class="font-medium">
        <font-awesome-icon icon="exchange-alt"></font-awesome-icon>
      </div>
      <div class="font-medium">Invoice</div>
    </div>
    <FormulateForm v-model="values" @submit="onSubmit" v-if="!loading">
      <div
        v-if="activeData.activationOption !== 'recurring_payment'"
        class="p-5 mt-5 mb-10 border rounded-lg group"
      >
        <div class="group-title pb-5 font-bold">Payment Details</div>
        <div>
          <FormulateInput
            type="checkbox"
            class="formulate-input"
            name="billedExternally"
            label="Let business add the credit card"
            v-model="businessInput"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            help="*Check this box if you want to let the business add their credit card."
            element-class="formulate-input-element formulate-input-element--checkbox"
          />
          <div v-if="!businessInput">
            <label class="font-medium text-sm mb-1">Card Details</label>
            <stripe-element-card
              class="mb-5"
              ref="cardRef"
              :pk="publishableKey"
              @token="tokenCreated"
              @element-change="updateSubmitButton"
              @element-blur="cardChange"
              :hidePostalCode="true"
            />
          </div>
        </div>
      </div>
      <FormulateInput
        type="checkbox"
        class="formulate-input"
        name="isFirstPayment"
        label="First payment"
        v-model="isFirstPayment"
        label-class="formulate-input-label formulate-input-label--after"
        input-class=""
        wrapper-class="formulate-input-wrapper"
        outer-class=""
        help="*Check this box if this is the first payment."
        element-class="formulate-input-element formulate-input-element--checkbox"
      />
      <FormulateInput
        type="date"
        name="lastPaymentDate"
        v-if="!isFirstPayment"
        v-model="values.lastPaymentDate"
        label="Last Payment Date"
        validation="required"
      />
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
        >
          <span v-if="submitLoading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />{{
              buttonText
            }}</span
          >
          <span v-if="!submitLoading">Submit</span>
        </FormulateInput>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeElementCard,
  },
  props: {
    activeData: Object,
  },
  data() {
    return {
      values: {},
      loading: true,
      hasErrorMessage: false,
      errorMessage: "",
      token: null,
      hasActivePaymentGateway: false,
      publishableKey: null,
      paymentGatewayId: null,
      paymentGateway: {},
      cardCheck: false,
      buttonText: "Saving data...",
      submitLoading: false,
      businessInput: false,
      isFirstPayment: false,
    };
  },
  async mounted() {
    if (this.activeData.activationOption !== "recurring_payment") {
      await this.getPaymentGateway();
    } else {
      this.loading = false;
    }
  },

  methods: {
    updateSubmitButton() {
      this.submitLoading = true;
      this.cardCheck = false;
      this.buttonText = "Validating Card Details...";
    },
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
      if (this.token) {
        this.submitLoading = false;
        this.cardCheck = true;
        this.buttonText = "Saving data...";
      }
    },
    async getPaymentGateway() {
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      await this.$store
        .dispatch("payment/getActivePaymentGatewayByEntityId", me.entity._id)
        .then((response) => {
          if (response) {
            this.paymentGatewayId = response[0]._id;
            this.publishableKey = response[0].apiKey;
            this.paymentGateway = response[0];
            this.hasActivePaymentGateway = true;
          }
          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = error;
          this.loading = false;
        });
    },
    async onSubmit() {
      this.submitLoading = true;
      this.buttonText = "Saving data...";
      let payload = {};
      if (this.activeData.activationOption !== "recurring_payment") {
        if (this.businessInput !== true) {
          payload = {
            entityId: this.activeData.businessMetaData._id,
            entityRole:
              this.activeData.contractType == "business"
                ? "business"
                : "driver",
            paymentGatewayId: this.paymentGatewayId,
            cardToken: this.token,
            isDefault: true,
            isActive: true,
          };
        }
      }
      await this.$store
        .dispatch("contract/updatePaymentTypeAndContract", {
          contractId: this.activeData._id,
          lastPaymentDate: this.values.lastPaymentDate || null,
          paymentDetails: payload,
          activationOption:
            this.activeData.activationOption == "recurring_payment"
              ? "invoice"
              : "recurring_payment",
          businessInput: this.businessInput,
          isFirstPayment: this.isFirstPayment,
          remarks:
            this.activeData.activationOption == "recurring_payment"
              ? "credit-card-to-invoice"
              : "invoice-to-credit-card",
        })
        .then(async (response) => {
          if (response) {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Payment type updated successfully.",
            });
            this.$emit("closeModal");
            setTimeout(() => {
              location.reload();
            }, 2500);
          }
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        })
        .finally(() => {
          this.loading = false;
          this.submitLoading = false;
        });
    },
  },
};
</script>
