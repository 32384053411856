<template>
  <div>
    <Table
      :tableHeader="tableHeaders"
      :paginationSettings="paginationSettings"
      :data="linkData"
      @search-table="queryForKeywords"
      @onPageChange="onPageChange($event)"
      :is-loading="isLoading"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="5">5</option>
              <option :value="10">10</option>
              <option :value="20">20</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template slot="callToActionTop">
        <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
          <button
            class="btn btn-primary"
            @click.prevent="createDynamicLink()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Add Promo Link
          </button>
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2">
          <div class="text-gray-900 whitespace-no-wrap text-lg flex">
            <!-- <StyledIconedLink
              class="flex cursor-pointer mx-1.5"
              @click.prevent="editPromoLink(data.id)"
              :iconHoverColor="theme.secondaryColor"
              ><font-awesome-icon icon="edit" class="my-auto"
            /></StyledIconedLink> -->
            <StyledIconedLink
              class="flex cursor-pointer mx-1.5"
              @click.prevent="deleteIntegrationLink(data)"
              :iconHoverColor="theme.secondaryColor"
              ><font-awesome-icon icon="trash" class="my-auto"
            /></StyledIconedLink>
          </div>
        </td>
      </template>
    </Table>

    <Modal
      ref="modal"
      :title="modalTitle"
      size="3xl"
      class="z-auto"
      :height="modalHeight"
    >
      <div v-if="modalContent == 'create'">
        <NewPromoLinkForm
          @closeRefresh="closeRefresh"
          @refreshData="refreshData"
        />
      </div>
      <div v-if="modalContent == 'edit'">
        <EditIntegrationLinkForm
          @closeRefresh="closeRefresh"
          :link-target="linkTarget"
          :entityId="entityId"
          :link-type="linkType"
          :selected-link="selectedLink"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Modal, Table } from "@/components/commons/";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import NewPromoLinkForm from "@/components/forms/dynamic-links/new-promo-link-form";
export default {
  name: "PromoLinks",
  props: {
    allPartnerLinks: {
      type: Boolean,
      default: false,
    },
    entityId: {
      type: String,
      default: "",
    },
    linkTarget: {
      type: String,
      default: "business",
    },
    linkType: {
      type: String,
      default: "promo-signup",
    },
  },
  components: {
    Table,
    Modal,
    StyledIconedLink,
    NewPromoLinkForm,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      integrationLinks: [],
      entityType: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeaders: [
        "Name of link",
        "Link",
        "User singup",
        "Maximum user signup",
        "Start Date",
        "End Date",
      ],
      tableData: [],
      filter: {
        limit: 5,
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      partners: [],
      me: [],
      selectedLink: {},
      rawData: [],
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    createDynamicLink: function () {
      this.modalContent = "create";
      this.modalTitle = "Create Link";
      this.$refs.modal.openModal();
    },
    deleteLink(id) {
      if (confirm("Are you sure you want to delete this link?")) {
        this.$store
          .dispatch(`dynamic-link/deleteDynamicLink`, id)
          .then(() => {
            this.init();
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
    async init(paged = 1) {
      if (this.allPartnerLeads && !this.tableHeader.includes("Partner")) {
        this.tableHeader.push("Partner");
      }
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: { allPartnerLinks: this.allPartnerLinks },
      };

      await this.$store
        .dispatch(`dynamicLink/getEntityPromoLinks`, {
          entityId: this.entityId,
          query,
        })
        .then((response) => {
          if (response) {
            if (response?.metadata) {
              this.paginationSettings = formatPaginationSettings(
                response?.metadata
              );
            } else {
              this.paginationSettings = formatPaginationSettings();
            }
            this.rawData = response;
            const integrationLinks = this.formatItem(
              _.orderBy(response.results, ["createdAt"], ["desc"])
            );

            this.integrationLinks = integrationLinks;
          }
        })
        .catch((error) => {
          this.integrationLinks = [];
          console.log(error);
        });
      this.isLoading = false;
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    refreshData() {
      this.$refs.modal.closeModal();
      this.init();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((data) => {
          const integrationLinks = {
            id: data._id,
            name: data.linkName,
            driverCount: data.userCount,
            link: data.link,
            userCount: data.userCount,
            maxUserCount:
              data.maxUserCount == 0 ? "No limit" : data.maxUserCount,
            startDate: formatDate(data.startDate),
            expiryDate: formatDate(data.expiryDate),
          };
          nItems.push(integrationLinks);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    newIntegrationLink() {
      this.modalContent = "create";
      this.modalTitle = "New Integration Links";
      this.$refs.modal.toggleModal();
    },

    newAPIIntegrationLink() {
      this.modalContent = "apiCreate";
      this.modalTitle = "New API Integration";
      this.$refs.modal.toggleModal();
    },

    editIntegrationLink(id) {
      this.selectedLink = this.rawData.results.find((data) => data._id == id);
      this.modalContent = "edit";
      this.modalTitle = "Update Integration Link";
      this.$refs.modal.toggleModal();
    },

    async deleteIntegrationLink(data) {
      if (
        confirm(
          `Are you sure you want to delete ${data.name}? It cannot be undone.`
        )
      ) {
        await this.$store
          .dispatch("dynamicLink/deleteLink", { id: data.id })
          .then((response) => {
            if (response.success == true) {
              this.$emit("closeModal");
              this.$emit("submitSuccess");
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Promo Link successfully deleted.",
              });
              this.init();
            }
          })
          .catch((err) => {
            this.errorMessage = err;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    parseLinks(data) {
      return data.map((linkData) => {
        const rowArray = [
          {
            id: linkData.id,
            name: linkData.name,
            itemType: "name",
          },
          {
            id: linkData.id,
            name: linkData.link,
            itemType: "string",
          },
          {
            id: linkData.id,
            name: linkData.userCount,
            itemType: "string",
          },
          {
            id: linkData.id,
            name: linkData.maxUserCount,
            itemType: "string",
          },
          {
            id: linkData.id,
            name: linkData.startDate,
            itemType: "string",
          },
          {
            id: linkData.id,
            name: linkData.expiryDate,
            itemType: "string",
          },
        ];
        return rowArray;
      });
    },
  },
  computed: {
    linkData() {
      if (this.integrationLinks) {
        return this.parseLinks(this.integrationLinks);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
