<template>
    <div class="flex flex-row overflow-hidden">
        <div :class="[showSidebar? 'relative -translate-x-0' : '-translate-x-full']"
          class="bg-gray-200 shadow-md w-48 h-screen
            absolute left-0
            md:relative md:-translate-x-0
            transform transition duration-200 ease-in-out">
            <ul class="relative w-full">
                <li v-for="item in items" :key="item.label" class="relative">
                    <a @click.prevent="changeContent(item.value, item.subitems)" :class="openTab.parent == item.value ? 'active-item' : ''"
                    class="flex items-center text-sm p-4 h-12 overflow-hidden text-gray-700 text-ellipsis whitespace-nowrap hover:text-black-primary hover:bg-gray-400 transition duration-300 ease-in-out" href="">{{ item.label }}</a>
                    <div v-if="item.subitems">
                        <a v-for="subitem in item.subitems" @click.prevent="changeContent(item.value, subitem.value)" :key="subitem.name" :class="openTab.child == subitem.value ? 'active-subitem' : ''" href=""
                        class="relative flex flex-row items-center h-9 focus:outline-none text-gray-700 bg-gray-300 hover:bg-gray-400 border-l-4 border-transparent pr-6 transition duration-300 ease-in-out">
                            <span class="text-xs tracking-wide ml-6 truncate">{{subitem.label}}</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="flex flex-1 overflow-y-auto mb-20 p-2 md:p-6">
            <div class="md:hidden">
                <button class="text-gray-500 w-10 h-10 shadow-md -mr-10 relative focus:outline-none bg-white" @click="showMenu(!showSidebar)">
                    <div class="block w-5 absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                        <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out" :class="{'rotate-45': showSidebar,' -translate-y-1.5': !showSidebar }"></span>
                        <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out" :class="{'opacity-0': showSidebar } "></span>
                        <span aria-hidden="true" class="block absolute h-0.5 w-5 bg-current transform transition duration-300 ease-in-out" :class="{'-rotate-45': showSidebar, ' translate-y-1.5': !showSidebar}"></span>
                    </div>
                </button>
            </div>
            <div class="w-full" v-if="openTab.child == 'vehicle-types-lv' || openTab.child == 'vehicle-types-mv' || openTab.child == 'vehicle-types-hv'">
                <VehicleTypes :key="openTab.child" :vehicleClass="openTab.child" />
            </div>
            <div class="w-full" v-if="openTab.child == 'front-couplings'">
                <VehicleFrontCouplings />
            </div>
            <div class="w-full" v-if="openTab.child == 'rear-couplings'">
                <VehicleRearCouplings />
            </div>
            <div class="w-full" v-if="openTab.child == 'wiring-connectors'">
                <VehicleWiringConnectors />
            </div>
            <div class="w-full" v-if="openTab.child == 'wiring-voltages'">
                <VehicleWiringVoltages />
            </div>
            <div class="w-full" v-if="openTab.child == 'braking-foundation'">
                <VehicleBrakingFoundation />
            </div>
            <div class="w-full" v-if="openTab.child == 'braking-types'">
                <VehicleBrakingTypes />
            </div>
            <div class="w-full" v-if="openTab.child === 'suspension'">
                <Suspension/>
            </div>
        </div>
    </div> 
</template>

<script>
import VehicleFrontCouplings from './FrontCouplings.vue'
import VehicleRearCouplings from './RearCouplings.vue'
import VehicleWiringConnectors from './WiringConnectors.vue'
import VehicleWiringVoltages from './WiringVoltages.vue'
import VehicleBrakingFoundation from './BrakingFoundation.vue'
import VehicleBrakingTypes from './BrakingType.vue'
import VehicleTypes from './VehicleTypes.vue'
import Suspension from "@/components/config/vehicle/Suspension.vue"

export default {
    name: "Vehicle-Configuration",
    components: {
        VehicleFrontCouplings,
        VehicleRearCouplings,
        VehicleWiringConnectors,
        VehicleWiringVoltages,
        VehicleBrakingFoundation,
        VehicleBrakingTypes,
        VehicleTypes,
        Suspension,
    },
    data() {
        return {
            showSidebar: false,
            openTab: {
                parent: 'vehicle-types',
                child: 'vehicle-types-lv'
            },
            items: [
                {
                    label: 'Vehicle Types',
                    value: 'vehicle-types',
                    subitems: [
                        {
                            label: 'Light Vehicles',
                            value: 'vehicle-types-lv',
                        },
                        {
                            label: 'Medium Vehicles',
                            value: 'vehicle-types-mv'
                        },
                        {
                            label: 'Heavy Vehicles',
                            value: 'vehicle-types-hv'
                        }
                    ]
                },
                {
                    label: 'Couplings',
                    value: 'couplings',
                    subitems: [
                        {
                            label: 'Rear Couplings',
                            value: 'rear-couplings'
                        },
                        {
                            label: 'Front Couplings',
                            value: 'front-couplings'
                        }
                    ]
                },
                {
                    label: 'Wiring Plugs',
                    value: 'wirings',
                    subitems: [
                        {
                            label: 'Voltages',
                            value: 'wiring-voltages'
                        },
                        {
                            label: 'Connectors',
                            value: 'wiring-connectors'
                        }
                    ]
                },
                {
                    label: 'Braking',
                    value: 'braking',
                    subitems: [
                        {
                            label: 'Foundation',
                            value: 'braking-foundation'
                        },
                        {
                            label: 'Types',
                            value: 'braking-types'
                        }
                    ]
                },
                {
                    label: 'Suspension',
                    value: 'suspension',
                    subitems: [
                        {
                            label: 'Suspension',
                            value: 'suspension'
                        }
                    ]
                }
            ]
        }
    },
    methods: {
        changeContent(tab, child) {
            this.openTab.parent = tab
            if(typeof child === 'string') {
                this.openTab.child = child
            }
            else if (typeof child === 'object') {
                this.openTab.child = child[0].value
            }
            else {
                this.openTab.child = tab
            }
            this.showSidebar = false;
        },
        showMenu(value) {
            this.showSidebar = value;
        }
    }
}
</script>

<style>
a.active-item{
  @apply text-black-primary bg-white border-l-4 font-semibold border-orange-primary outline-none;
}

select:disabled, input:disabled {
    background: none;
}

a.active-subitem{
  @apply text-black-primary bg-gray-400 border-orange-primary font-semibold;
}
</style>