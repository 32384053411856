<template>
  <div class="mb-16">
    <div class="flex flex-col justify-center items-center w-full mb-5 relative">
      <div class="w-full" v-if="loading">
        <div
          class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
        >
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-400">Loading vehicles...</span>
        </div>
      </div>
      <label
        class="flex flex-col justify-center items-center w-full h-full bg-gray-50 rounded-lg border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100"
      >
        <div class="flex flex-col justify-center items-center pt-10 pb-10">
          <font-awesome-icon
            icon="upload"
            class="mb-3 w-14 h-14 text-gray-400 text-6xl"
          />
          <p class="mb-2 text-lg text-gray-500">
            <span class="font-semibold">Click to upload</span> or drag and drop
          </p>
          <p class="text-xs text-gray-500">CSV FILE ONLY</p>
        </div>
        <FormulateInput
          type="file"
          name="file"
          accept=".csv"
          class="hidden"
          label="Select CSV file"
          @change="onFileChange"
        />
      </label>
      <a
        :href="csvTemplate"
        class="text-orange-200 text-sm mt-5 hover:underline"
        target="_blank"
        >Download CSV Template</a
      >
    </div>
  </div>
</template>

<script>
// import _ from "lodash";
import jCsv from "jquery-csv";
import Spinner from "@/components/commons/ui/Spinner";
import { formatDate } from "@/_helper";

export default {
  name: "Select-File",
  components: { Spinner },
  data() {
    return {
      csvTemplate: process.env.VUE_APP_DRIVER_TRIP_CSV_URL,
      loading: false,
      driverTrips: [],
    };
  },
  methods: {
    formatDate(date) {
      return formatDate(date, "DD/MM/YYYY", true);
    },
    onFileChange(e) {
      this.loading = true;
      const reader = new FileReader();
      reader.readAsText(e.target.files[0]);
      reader.onload = (event) => {
        const csv = event.target.result;
        const data = jCsv.toArrays(csv);

        data.slice(1).forEach((value) => {
          this.driverTrips.push({
            driverEmail: value[0],
            loadTime: this.formatDate(value[1]),
            location: value[2],
            destination: value[3],
            massLimit: value[4],
            loadDescription: value[5],
            grossWeight: value[6],
            netWeight: value[7],
            driveVehiclePlate: value[8],
            trailerVehicle1Plate: value[9],
            trailerVehicle2Plate: value[10],
            trailerVehicle3Plate: value[11],
            trailerVehicle4Plate: value[12],
            trailerVehicle5Plate: value[13],
            trailerVehicle6Plate: value[14],
            trailerVehicle7Plate: value[15],
            trailerVehicle8Plate: value[16],
          });
        });

        this.loading = false;
        this.$emit("nextStep", { driverTrips: this.driverTrips });
      };
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
