<template>
  <div>
    <div v-show="!isLoading" class="flex flex-row">
      <div class="w-1/3">
        <button @click="$router.go(-1)" class="btn btn-cancel flex flex-row">
          <StyledIconedLink class="w-1/4">
            <font-awesome-icon icon="arrow-left" class="" />
          </StyledIconedLink>
          <div class="w-3/4 px-2">Back</div>
        </button>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1 mt-10 ml-2">
      <div>
        <Card :cardBackgroundColor="'white'" :cardWidth="'80%'" class="">
          <template slot="cardContent">
            <div class="flex items-center pb-3">
              <div class="icon-container">
                <font-awesome-icon
                  icon="user"
                  class="text-2xl"
                  :style="{ color: theme.primaryColor }"
                />
              </div>
              <div class="font-bold mr-4" :style="{ color: theme.accentColor }">
                Driver:
              </div>
              <div class="" :style="{ color: theme.accentColor }">
                {{ individualDriverStats?.driverName }}
              </div>
            </div>
            <div class="flex items-center pb-3">
              <div class="icon-container">
                <font-awesome-icon
                  icon="truck"
                  class="text-2xl"
                  :style="{ color: theme.primaryColor }"
                />
              </div>
              <div class="font-bold mr-4" :style="{ color: theme.accentColor }">
                Vehicle:
              </div>
              <div class="" :style="{ color: theme.accentColor }">
                {{ individualDriverStats?.vehicle }}
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div>
        <Card :cardBackgroundColor="'white'" :cardWidth="'80%'">
          <template slot="cardContent">
            <div class="flex items-center pb-3">
              <div class="icon-container">
                <font-awesome-icon
                  icon="clock"
                  class="text-2xl"
                  :style="{ color: theme.primaryColor }"
                />
              </div>
              <div class="font-bold mr-4" :style="{ color: theme.accentColor }">
                Last Updated:
              </div>
              <div class="" :style="{ color: theme.accentColor }">
                {{ formatDate(individualDriverStats?.lastUpdated) }}
              </div>
            </div>
            <div class="flex items-center pb-3">
              <div class="icon-container">
                <font-awesome-icon
                  icon="pen"
                  class="text-2xl"
                  :style="{ color: theme.primaryColor }"
                />
              </div>
              <div class="font-bold mr-4" :style="{ color: theme.accentColor }">
                Fatigue Plan:
              </div>
              <div class="" :style="{ color: theme.accentColor }">
                {{ individualDriverStats?.fatiguePlan }}
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div class="grid lg:grid-cols-2 sm:grid-cols-1 xs:grid-cols-1">
      <div v-show="twentyFourHourRestData.length">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'90%'"
          :maxCardHeight="'200px'"
          :minCardHeight="'200px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="moon"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  24hr Rests Required
                </div>
              </div>
              <div class="grid grid-cols-3">
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Counting Point Start</div>
                <div class="text-xs font-bold">Calculated Latest Start</div>
              </div>
              <div style="max-height: 80px; overflow-y: scroll">
                <div
                  class="grid grid-cols-3"
                  v-for="(rule, index) in twentyFourHourRestData"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.startTime }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.latestTimeToBreak }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div v-show="fourteenDayWorkData.length">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'100%'"
          :maxCardHeight="'200px'"
          :minCardHeight="'200px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="truck"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  14 Days Work Rules
                </div>
              </div>
              <div class="grid grid-cols-5">
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Counting Point Start</div>
                <div class="text-xs font-bold">Counting Point End</div>
                <div class="text-xs font-bold">Total Work</div>
                <div class="text-xs font-bold">Work Remaining</div>
              </div>
              <div style="max-height: 80px; overflow-y: scroll">
                <div
                  class="grid grid-cols-5"
                  v-for="(rule, index) in fourteenDayWorkData"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div
                    class="text-xs"
                    :style="{
                      color: theme.accentColor,
                      maxWidth: '80px',
                    }"
                  >
                    {{ formatDate(rule.startTime) }}
                  </div>
                  <div
                    class="text-xs"
                    :style="{ color: theme.accentColor, maxWidth: '80px' }"
                  >
                    {{ getCountPointEnd(14, rule.startTime) }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{
                      parseWorkCompleted(rule.workCompleted).decimalFormat || 0
                    }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{
                      calculateWorkRemaining(
                        rule.ruleSet,
                        14,
                        rule.workCompleted
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div v-show="nightRestData.length">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'90%'"
          :maxCardHeight="'200px'"
          :minCardHeight="'200px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="moon"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  Night Rest Required
                </div>
              </div>
              <div class="grid grid-cols-3">
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Counting Point Start</div>
                <div class="text-xs font-bold">Calculated Latest Start</div>
              </div>
              <div style="max-height: 80px; overflow-y: scroll">
                <div
                  class="grid grid-cols-3"
                  v-for="(rule, index) in nightRestData"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ formatDate(rule.startTime) }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.nightRest }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div v-show="twentyEightDayWorkData.length">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'100%'"
          :maxCardHeight="'200px'"
          :minCardHeight="'200px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="truck"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  28 Days Work Rules
                </div>
              </div>
              <div class="grid grid-cols-5">
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Counting Point Start</div>
                <div class="text-xs font-bold">Counting Point End</div>
                <div class="text-xs font-bold">Total Work</div>
                <div class="text-xs font-bold">Work Remaining</div>
              </div>
              <div style="max-height: 80px; overflow-y: scroll">
                <div
                  class="grid grid-cols-5"
                  v-for="(rule, index) in twentyEightDayWorkData"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div
                    class="text-xs"
                    :style="{ color: theme.accentColor, maxWidth: '80px' }"
                  >
                    {{ formatDate(rule.startTime) }}
                  </div>
                  <div
                    class="text-xs"
                    :style="{ color: theme.accentColor, maxWidth: '80px' }"
                  >
                    {{ getCountPointEnd(28, rule.startTime) }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{
                      parseWorkCompleted(rule.workCompleted).decimalFormat || 0
                    }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{
                      calculateWorkRemaining(
                        rule.ruleSet,
                        28,
                        rule.workCompleted
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div v-show="tenHourRestData.length">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'90%'"
          :maxCardHeight="'200px'"
          :minCardHeight="'200px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="moon"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  10hr Rest Required
                </div>
              </div>
              <div class="grid grid-cols-3">
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Counting Point Start</div>
                <div class="text-xs font-bold">Calculated Latest Start</div>
              </div>
              <div style="max-height: 80px; overflow-y: scroll">
                <div
                  class="grid grid-cols-3"
                  v-for="(rule, index) in tenHourRestData"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ formatDate(rule.startTime) }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ formatDate(rule.latestTimeToBreak) }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div v-show="sevenDayRestData.length">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'100%'"
          :maxCardHeight="'200px'"
          :minCardHeight="'200px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="truck"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  7 Days Work Rules
                </div>
              </div>
              <div class="grid grid-cols-5">
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Counting Point Start</div>
                <div class="text-xs font-bold">Counting Point End</div>
                <div class="text-xs font-bold">Total Work</div>
                <div class="text-xs font-bold">Work Remaining</div>
              </div>
              <div style="max-height: 80px; overflow-y: scroll">
                <div
                  class="grid grid-cols-5"
                  v-for="(rule, index) in sevenDayRestData"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div
                    class="text-xs"
                    :style="{ color: theme.accentColor, maxWidth: '80px' }"
                  >
                    {{ formatDate(rule.startTime) }}
                  </div>
                  <div
                    class="text-xs"
                    :style="{ color: theme.accentColor, maxWidth: '80px' }"
                  >
                    {{ getCountPointEnd(7, rule.startTime) }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{
                      parseWorkCompleted(rule.workCompleted).decimalFormat || 0
                    }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{
                      calculateWorkRemaining(
                        rule.ruleSet,
                        7,
                        rule.workCompleted
                      )
                    }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <div class="grid grid-cols-1 mt-10">
      <div class="text-center">
        <Card
          :cardBackgroundColor="'white'"
          :cardWidth="'100%'"
          :maxCardHeight="'300px'"
          :minCardHeight="'300px'"
        >
          <template slot="cardContent">
            <div class="loading centered" v-show="isLoading">
              <ui-spinner class="mx-auto"></ui-spinner>
            </div>
            <div v-show="!isLoading">
              <div class="flex justify-center items-center pb-3">
                <div class="icon-container">
                  <font-awesome-icon
                    icon="border-all"
                    class="text-2xl"
                    :style="{ color: theme.secondaryColor }"
                  />
                </div>
                <div
                  class="text-lg font-bold mr-4"
                  :style="{ color: theme.accentColor }"
                >
                  Data Grid
                </div>
              </div>
              <div class="grid grid-cols-5">
                <div class="text-xs font-bold">Rule</div>
                <div class="text-xs font-bold">Rule Set</div>
                <div class="text-xs font-bold">Rule Type</div>
                <div class="text-xs font-bold">Latest Time To Break</div>
                <div class="text-xs font-bold">Rest Required</div>
              </div>
              <div style="max-height: 150px; overflow-y: scroll">
                <div
                  class="grid grid-cols-5"
                  v-for="(rule, index) in dataGridData()"
                  :key="index"
                >
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.rule }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.ruleSet }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.type }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ formatDate(rule.latestTimeToBreak) }}
                  </div>
                  <div class="text-xs" :style="{ color: theme.accentColor }">
                    {{ rule.restRequired }}
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { Card } from "@/components/commons";
import { formatDate } from "@/_helper";
import { StyledIconedLink } from "@/styled-components";
import moment from "moment";

export default {
  name: "Fatigue-Dashboard",
  components: {
    StyledIconedLink,
    Card,
  },
  data() {
    return {
      isLoading: false,
      individualDriverStats: null,
      driverId: null,
      twentyFourHourRestData: [],
      fourteenDayWorkData: [],
      nightRestData: [],
      twentyEightDayWorkData: [],
      tenHourRestData: [],
      sevenDayRestData: [],
    };
  },

  async mounted() {
    this.driverId = this.$route.params.id;
    await this.viewDriverStats();
  },

  methods: {
    async viewDriverStats() {
      try {
        this.isLoading = true;
        this.individualDriverStats = await this.$store.dispatch(
          "driver/getOneDriverStatistic",
          this.driverId
        );
        this.twentyFourHourRestData = this.get24HrRestData();
        this.fourteenDayWorkData = this.get14DaysWorkData();
        this.nightRestData = this.getNighRestData();
        this.twentyEightDayWorkData = this.get28DaysWorkData();
        this.tenHourRestData = this.get10HrRestData();
        this.sevenDayRestData = this.get7DaysWorkData();
      } catch (error) {
        console.log("error: ", error);
        this.toast("error", "Something went wrong");
      } finally {
        this.isLoading = false;
      }
    },

    dataGridData() {
      return this.individualDriverStats?.rules || [];
    },

    get24HrRestData() {
      const rules = this.individualDriverStats?.rules || [];

      if (!rules || !rules.length) {
        return [];
      }

      const targetRules = rules.filter(
        (rule) =>
          rule.restRequired === "24:00" && rule.type.toUpperCase() === "REST"
      );

      const targetBlocks = [];
      targetRules.forEach((rule) => {
        const { restBlocks = [], ruleSet, latestTimeToBreak, startTime } = rule;
        if (
          ruleSet &&
          !["STANDARD", "STANDARD-SOLO"].includes(ruleSet.toUpperCase())
        ) {
          if (restBlocks && restBlocks.length) {
            restBlocks.forEach((restBlock) => {
              const { achieved } = restBlock;
              let { block = "" } = restBlock;
              const keywordsToExclude = ["PM", "AM"];
              keywordsToExclude.forEach((keyword) => {
                block = block.replace(keyword, "");
              });
              if (!achieved) {
                targetBlocks.push({
                  ruleSet,
                  startTime: this.formatDate(startTime),
                  latestTimeToBreak: block,
                });
              }
            });
          }
        } else {
          targetBlocks.push({
            ruleSet,
            startTime: this.formatDate(startTime),
            latestTimeToBreak: this.formatDate(latestTimeToBreak),
          });
        }
      });

      return targetBlocks;
    },

    get10HrRestData() {
      const rules = this.individualDriverStats?.rules || [];

      if (!rules || !rules.length) {
        return [];
      }

      const targetRules = rules.filter(
        (rule) =>
          rule.restRequired === "10:00" && rule.type.toUpperCase() === "REST"
      );

      const targetBlocks = [];
      targetRules.forEach((rule) => {
        const { restBlocks = [], ruleSet, latestTimeToBreak } = rule;
        if (restBlocks && restBlocks.length) {
          restBlocks.forEach((restBlock) => {
            const { achieved } = restBlock;
            let { block = "" } = restBlock;
            const keywordsToExclude = ["PM", "AM"];
            keywordsToExclude.forEach((keyword) => {
              block = block.replace(keyword, "");
            });
            if (!achieved) {
              targetBlocks.push({
                ruleSet,
                startTime: block,
                latestTimeToBreak,
              });
            }
          });
        }
      });

      return targetRules;
    },

    get14DaysWorkData() {
      const rules = this.individualDriverStats?.rules || [];

      if (!rules || !rules.length) {
        return [];
      }

      return rules.filter(
        (rule) => rule.rule === "14 Days" && rule.type.toUpperCase() === "WORK"
      );
    },

    get28DaysWorkData() {
      const rules = this.individualDriverStats?.rules || [];

      if (!rules || !rules.length) {
        return [];
      }

      return rules.filter(
        (rule) => rule.rule === "28 Days" && rule.type.toUpperCase() === "WORK"
      );
    },

    get7DaysWorkData() {
      const rules = this.individualDriverStats?.rules || [];

      if (!rules || !rules.length) {
        return [];
      }

      return rules.filter(
        (rule) => rule.rule === "7 Days" && rule.type.toUpperCase() === "WORK"
      );
    },

    getNighRestData() {
      const rules = this.individualDriverStats?.rules || [];

      if (!rules || !rules.length) {
        return [];
      }

      const targetBlocks = [];
      rules.forEach((rule) => {
        const { startTime, restBlocks = [], ruleSet } = rule;
        if (restBlocks && restBlocks.length) {
          restBlocks.forEach((restBlock) => {
            const { achieved, nightRest } = restBlock;
            if (!achieved && nightRest) {
              targetBlocks.push({
                ruleSet,
                startTime,
                nightRest,
              });
            }
          });
        }
      });

      return targetBlocks;
    },

    getTotalWorkForRuleSet(ruleSet, rule) {
      let totalRequiredWork = 0;
      if (rule !== 7) {
        switch (ruleSet.toUpperCase()) {
          case "BFM":
          case "BFM SOLO":
            totalRequiredWork = 144;
            break;
          case "STANDARD":
          case "STANDARD-SOLO":
          case "STANDARD SOLO":
            totalRequiredWork = 144;
            break;
          case "BFM 2-UP":
            totalRequiredWork = 140;
            break;
          case "STANDARD 2-UP":
          case "STANDARD 2UP":
            totalRequiredWork = 120;
            break;
          case "STANDARD BUS":
            totalRequiredWork = 288;
            break;
        }
      } else {
        switch (ruleSet.toUpperCase()) {
          case "BFM":
          case "BFM SOLO":
            totalRequiredWork = 36;
            break;
          case "STANDARD":
          case "STANDARD-SOLO":
          case "STANDARD SOLO":
            totalRequiredWork = 72;
            break;
          case "BFM 2-UP":
            totalRequiredWork = 70;
            break;
          case "STANDARD 2-UP":
          case "STANDARD 2UP":
            totalRequiredWork = 60;
            break;
        }
      }

      return totalRequiredWork;
    },

    getCountPointEnd(workDays, startTime) {
      startTime = moment.parseZone(startTime);
      return this.formatDate(startTime.add(workDays, "days"));
    },

    parseWorkCompleted(timeString = "") {
      if (!timeString || timeString === "") {
        return {
          decimalFormat: "00:00",
          hourFormat: 0,
        };
      }
      // Split the string at the colon
      const timeArray = timeString.split(":");

      // Convert hours and minutes to decimal form
      const hours = parseFloat(timeArray[0]);
      const minutes = Math.ceil(parseFloat(timeArray[1]) / 60);

      // Calculate the total time in decimal form
      return {
        decimalFormat: timeString,
        hourFormat: hours + minutes,
      };
    },

    calculateWorkRemaining(ruleSet, rule, workCompleted = "") {
      const totalWorkForRuleSet = this.getTotalWorkForRuleSet(ruleSet, rule);

      if (!workCompleted || workCompleted === "") {
        workCompleted = "00:00";
      }

      // Split the string at the colon
      const timeArray = workCompleted.split(":");
      // Convert hours and minutes to decimal form
      const hours = parseInt(timeArray[0]);
      const minutes = parseInt(timeArray[1]);

      let remainingWorkHours = totalWorkForRuleSet - hours;
      let remainingWorkMinutes = 0;
      if (minutes !== 0) {
        remainingWorkHours--;
        remainingWorkMinutes = 60 - minutes;
      }

      remainingWorkHours =
        remainingWorkHours < 10 ? "0" + remainingWorkHours : remainingWorkHours;
      remainingWorkMinutes =
        remainingWorkMinutes < 10
          ? "0" + remainingWorkMinutes
          : remainingWorkMinutes;

      return `${remainingWorkHours}:${remainingWorkMinutes}`;
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    formatDate(date) {
      if (!date) {
        return;
      }
      return formatDate(date, "DD/MM/YYYY HH:mm", true);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
