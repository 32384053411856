<template>
   <div>
     <div v-if="loading" class="loading centered">
       <ui-spinner class="mx-auto"></ui-spinner>
     </div>
     <div id="printCovered" v-if="!loading" class="mb-16">
       <div class="text-center mb-10">
         <div class="flex text-right justify-end"> 
           <button id="printBtn" class="h-8 pl-2 pr-4 m-2 text-sm transition-colors duration-150 bg-blue-300 text-white hover:bg-blue-200 rounded-md focus:shadow-outline"
             @click="printThis('printCovered')">
             <font-awesome-icon 
               icon="print" 
               class="my-auto mx-1.5 text-sm"/> 
               Print
           </button>
         </div>
         <div v-if="vehicle" class="font-bold text-black-primary text-2xl mt-3">
           {{vehicle.vehiclePlate}}
         </div>
         <div
            class="font-bold text-xs"
           :class="[isPassed ? 'text-green-500' : 'text-red-500']"
         >
           VEHICLE CHECK {{isPassed ? 'PASSED' : 'FAILED'}}
         </div>
       </div>
       <div  v-if="vehiclePrestart">
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.A) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.A) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Engine, Driveline and Exhaust</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.B) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.B) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Wind Screens and Windows</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.C) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.C) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Mirrors</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.D) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.D) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Lights and Reflectors</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.E) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.E) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Structure and Body Conditions</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.F) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.F) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Wheels, Tyres and Hubs</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.G) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.G) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Couplings</div>
         </div>
         <div class="mb-2 flex items-center">
           <div class="option-pill-view">
             <div :class="[convertBool(vehiclePrestart.H) ? 'bg-green-400 text-white' : 'bg-gray-200']">Pass</div>
             <div :class="[!convertBool(vehiclePrestart.H) ? 'bg-red-400 text-white' : 'bg-gray-200']">Fail</div>
           </div>
           <div class="text-xs">Brakes</div>
         </div>
       </div>
   
       <Collapse :isCollapsed="collapsed">
         <template slot="collapseHeader">
           <div class="flex items-center font-bold">
             <div class="flex items-center">
               <div class="flex items-center">
                 <div class="icon-container mr-2 bg-gray-700">
                   <font-awesome-icon
                     icon="file"
                     style="height: 15px; width: 15px"
                   />
                 </div>
                 <div class="flex flex-col">
                   <div class="flex-auto">Pre Start Check Consent</div>
                 </div>
               </div>
             </div>
             <div class="ml-auto cursor-pointer">
               <font-awesome-icon
                 :icon="collapsed ? 'caret-down' : 'caret-right'"
                 class="text-2xl"
                 @click="onCollapsed()"
               />
             </div>
           </div>
         </template>
         <template #default="{props}">
           <Transition name="slide">
             <div v-if="props.collapsed" class="items-center mt-5 px-5">
               <div class="mb-3 text-xs">
                 You are about to submit your pre-start check declaration for the
                 day. Once you have submitted it, it cannot be corrected.
               </div>
               <div class="mb-3 text-xs">
                 By submitting your pre-start check declaration, you are declaring
                 that the entries:
               </div>
               <ul class="list-disc mb-3 ml-8 text-xs">
                 <li>were made by you personally</li>
                 <li>are not false or misleading</li>
               </ul>
               <div class="mb-3 text-xs">
                 Confirm that the information in the pre-start check form is
                 correct, [OR], if you are not sure that the information in the
                 pre-start check form is correct, correct it now prior to
                 submitting your pre-start check declaration record.
               </div>
               <div class="mb-3 text-xs">
                 Entries may be used in legal proceedings for an offence against
                 the Heavy Vehicle National Law Act 2012, or another law of a State
                 or the Commonwealth of Australia. Making a false or misleading
                 entry in an electronic work diary is an offence punishable by a
                 fine of over $10,000.
               </div>
             </div>
           </Transition>
         </template>
       </Collapse>

       <div class="items-center mt-5 px-5">
         <div class="w-full text-left items-start pt-7 mr-2">
           <span class="pl-6 text-sm">{{ cleanDateFormat(vehiclePrestart?.date) }}</span>
           <div class="w-40 border-t mt-1 pt-1 text-center text-xs">Date submitted</div>
         </div>
         <div class="w-full text-right flex flex-col items-end">
           <img class="fitness-signature" :src="vehiclePrestart.signature" alt="signature"/>
           <div class="border-t mt-3 text-center">Signature <b>{{driverName}}</b></div>
         </div>
       </div>

     </div>
   </div>
   </template>
   <script>
   import { Collapse } from "@/components/commons"
   import { cleanDateFormat, print } from '@/_helper'
   
   export default {
     name: "Pre-Start-Check-Details-View",
     components: { Collapse },
     props: {
       activePreStartCheck: {
         type: Object,
         default: () => {}
       },
     },
     watch: {
       activePreStartCheck: {
         deep: true,
         handler() {
           this.setActivePreStartCheckData()
         }
       }
     },
     async created() {
       this.loading = true
       this.setActivePreStartCheckData()
       const driverId = this.activePreStartCheck?.driverId;
       const driver = await this.$store.dispatch(`driver/getDriverByID`, driverId);
       if(driver) {
           this.driverName = driver.driverName;
       }
       setTimeout(() => this.loading = false, 400)
     },
     data() {
       return {
         vehicle: {},
         vehiclePrestart: {},
         driverName: '',
         isPassed: false,
         collapsed: false,
         loading: true
       };
     },
     methods: {
       setActivePreStartCheckData(){
         this.vehicle = this.activePreStartCheck.vehicle;
         this.vehiclePrestart = this.activePreStartCheck.savedData;
         this.isPassed = this.activePreStartCheck?.isPassed
       },
       onCollapsed() {
         this.collapsed = !this.collapsed;
       },
       convertBool(option){
         return ('Pass' == option);
       },
       printThis(toPrintId) {
         return print(toPrintId)
       },
       cleanDateFormat(date){
         if(!date) {
           return "N/A";
         }
         return cleanDateFormat(date, true, true)
       }
     }
   }
   </script>
   <style>
     .prestart-signature{
       width: 200px!important;
       height: 90px!important;
       background: rgb(68, 63, 63);
       border: 3px solid;
       margin-top: 10px;
     }
   </style>