<template>
  <div class="bg-black">
    <div class="container py-10 h-screen flex">
      <div v-if="!acceptForm" class="login-container w-96 m-auto">
        <div class="text-center m-5 -mt-40">
          <a :href="$store.getters['baseURL']"><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"></a>
        </div>
        <div class="rounded-lg bg-gray-50 p-10 shadow">
          <div>
          <div class="mb-2 font-italic text-md bg-white text-gray-600 px-5 py-3 rounded-xl">By creating an account for the Logmaster Australia application, you have accepted the Logmaster Terms and Conditions, Privacy Policy and the End User Licence Agreement. If you do not accept, close this page without creating a password.</div>
            <div class="mb-2"><a href="https://logmaster.com.au/terms-and-conditions/" target="_blank" class="underline text-blue-300">Terms and Conditions</a></div>
            <div class="mb-2"><a href="https://logmaster.com.au/privacy-statement/" target="_blank" class="underline text-blue-300">Privacy Statement</a></div>
            <div class="mb-2"><a href="https://logmaster.com.au/logmaster-end-user-licence-agreement/" target="_blank" class="underline text-blue-300">Logmaster End User Licence Agreement</a></div>
            <div class="flex flex-col">
              <button class="btn btn-primary w-full mb-2" @click.prevent="agreeInTerms()" :style="{backgroundColor: theme.secondaryColor}">
                  Agree
              </button>
              <button class="btn btn-cancel disabled w-full mb-2" @click.prevent="cancelInTerms()">
                  Close
              </button>
            </div>
           </div>
      </div>
        </div>
      <div v-if="acceptForm" class="login-container xl:w-1/2 lg:w-2/3 w-full px-4 m-auto">
          <div class="text-center m-5 -mt-40">
            <a :href="$store.getters['baseURL']"><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"></a>
          </div>
          <div class="rounded-lg bg-gray-50 p-10 shadow">
          <div class="text-center mb-5">
            <h3
              class="text-lg leading-6 font-black text-black uppercase"
              id="modal-title"
            >
              New Contractor Driver
            </h3>
          </div>
          <ContractorSignupAcceptForm></ContractorSignupAcceptForm>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContractorSignupAcceptForm from '@/components/forms/public/ContractorSignupAcceptForm.vue'
import { mapGetters } from 'vuex'

  export default {
    name: "Business-Accept",
    components: {ContractorSignupAcceptForm},
    data(){
      return {
        driverId: null,
        acceptForm: false
      }
    },
    async mounted(){
      this.driverId = this.$route.params['driverId'];
      this.driverId = this.driverId ? this.driverId:this.$route.params['id'];
      if(!this.driverId) window.location.href = '/404?message=Request ID not found.'
    },
    methods: {
      agreeInTerms(){
        this.acceptForm = true
      },
      cancelInTerms(){
        window.location.href = '/'
      }
    },
    computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
    }
  };
</script>
