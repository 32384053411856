import {
  createBreachEngineFromLastEvent,
  BreachStatus,
} from "@logmaster/logmaster-nhvr-breach";
import {
  BreachStatusWAHVA,
  createBreachEngineFromLastEventWAHVA,
} from "@logmaster/logmaster-wahva-breach";
import { NHVR_FATIGUE_PLAN } from "@/constants/fatigue-plan";
import _ from "lodash";
import moment from "moment";

function formatLevel(level) {
  level = typeof level == "string" ? level : level[0];
  return _.startCase(
    level == "no-breach" ? level.replace("-", " ") : level.split("-")[0]
  );
}
function formatTime(time, timezone) {
  time = Array.isArray(time) ? time[0] : time;
  return moment(time).utcOffset(timezone).format("YYYY-MM-DD HH:mm:ssZ");
}
function formatPeriod(period) {
  if (period.toLowerCase().includes("hours")) {
    let [hours, rest] = period.split(" ");
    if (!hours.includes(":")) {
      hours += ":00";
    }
    period = `${hours} ${rest}`;
  }

  return period;
}

function removeDuplicateBreaches(generatedBreaches) {
  return _.uniqWith(
    generatedBreaches,
    (breachA, breachB) =>
      formatTime(breachA.time, breachA.timezone) ===
        formatTime(breachB.time, breachB.timezone) &&
      breachA.option === breachB.option &&
      breachA.breachTime === breachB.breachTime &&
      breachA.type === breachB.type &&
      formatLevel(breachA.level) === formatLevel(breachB.level)
  );
}

const generateBreaches = (
  driverId,
  businessId,
  events,
  timezone,
  engineRegion = "NHVR"
) => {
  const nhvrFatiguePlans = Object.entries(NHVR_FATIGUE_PLAN).map((fatigue) => {
    return fatigue[1];
  });
  const mostUsedFatiguePlan = _.maxBy(
    _.keys(_.countBy(events, "fatiguePlan")),
    function (o) {
      return _.countBy(events, "fatiguePlan")[o];
    }
  );
  engineRegion = nhvrFatiguePlans.includes(mostUsedFatiguePlan)
    ? "NHVR"
    : "WAHVA";
  const breachEngine =
    engineRegion == "NHVR"
      ? createBreachEngineFromLastEvent(events, timezone)
      : createBreachEngineFromLastEventWAHVA(events, timezone);

  return getBreachCountingPoints(
    breachEngine.countingPointEvents,
    driverId,
    businessId
  );
};

function getBreachCountingPoints(countingPointEvents, driverId, businessId) {
  const breachCountingPointsEvents = countingPointEvents
    .sort((a, b) => b.countingPointStart - a.countingPointStart)
    .filter((countingPoint) => {
      if (Array.isArray(countingPoint.breachResult)) {
        return (
          countingPoint.breachResult.includes(
            BreachStatus.SUBSTANTIAL_BREACH
          ) ||
          countingPoint.breachResult.includes(BreachStatus.CRITICAL_BREACH) ||
          countingPoint.breachResult.includes(BreachStatus.MINOR_BREACH) ||
          countingPoint.breachResult.includes(BreachStatus.SEVERE_BREACH) ||
          countingPoint.breachResult.includes(BreachStatusWAHVA.BREACHED) ||
          countingPoint.breachResult.includes(BreachStatus.NO_BREACH) ||
          countingPoint.breachResult.includes(BreachStatus.MASKED_MINOR)
        );
      }
      return (
        countingPoint.breachingPoint &&
        countingPoint.breachResult !== BreachStatus.COMPLETED
      );
    });

  const now = new Date();
  const breachesMapped = breachCountingPointsEvents.map((cpe) => {
    return {
      level: cpe.breachResult,
      option: cpe.rulesetName,
      period: cpe.rule.Rule,
      time: cpe.breachingPointFormatted,
      timezone: cpe.timezone,
      type: cpe.countingType,
      driverId: driverId,
      created: now,
      business: businessId,
      location: cpe.events
        ? cpe.events[cpe.events.length - 1].location.address
        : "",
      startPoint: cpe.countingPointStartWithTimezone,
      breachTime:
        cpe.countingType === "Work"
          ? cpe.totalWorkFormatted
          : cpe.totalRestFormatted,
      noBreach: cpe.rule.Breaches.NoBreach,
    };
  });

  return breachesMapped;
}

export {
  generateBreaches,
  formatLevel,
  formatPeriod,
  formatTime,
  removeDuplicateBreaches,
};
