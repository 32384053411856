<template>
  <div class="flex flex-col w-full h-screen">
    <Table
      :isLoading="loading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="tableData"
      @onPageChange="onPageChange($event)"
    >
      <template #callToActionTop>
        <button
          class="btn btn-primary"
          @click.prevent="toggleReset"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Reset Default Template
        </button>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="downloadTemplate(data)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="download" class="my-auto mr-2" />
                  Download
                </StyledIconedLink>
              </li>
              <li v-if="isRecordKeeper">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="redirect(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li v-if="isRecordKeeper">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="toggleConfirmDefault(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="history" class="my-auto mr-2" />
                  Toggle
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :height="modalHeight"
    >
      <div v-if="modalContent === 'viewTemplate'">
        <DocumentPartContainer
          v-for="parts in content"
          :key="parts.uuid"
          :dynamicContent="parts"
          :previewMode="true"
        />
      </div>

      <div v-if="modalContent === 'activateTemplate'">
        <ConfirmDelete
          :templateId="templateToActivate"
          @confirmActivate="activateTemplate"
          @closeModal="this.$refs.modal.closeModal"
        />
      </div>

      <div v-if="modalContent === 'confirmDefault'">
        <ConfirmDefault
          :templateId="templateToDefault"
          @closeModal="this.$refs.modal.closeModal"
          @refetch="reload"
        />
      </div>

      <div v-if="modalContent === 'resetTemplate'">
        <ConfirmReset @closeModal="this.$refs.modal.closeModal" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import {
  SEARCH_DEBOUNCE_DELAY,
  DEFAULT_MODAL_HEIGHT,
} from "@/_helper/constants";
import { formatPaginationSettings, formatDate } from "@/_helper/";
import _ from "lodash";
import DocumentPartContainer from "@/components/forms/business/accreditation/document-part-container.vue";
import ConfirmDefault from "@/components/forms/business/accreditation/confirm-default.vue";
import ConfirmReset from "@/components/forms/business/accreditation/confirm-reset.vue";
import jsPDF from "jspdf";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "document-templates",
  components: {
    Table,
    Modal,
    Dropdown,
    DocumentPartContainer,
    ConfirmDefault,
    ConfirmReset,
    StyledIconedLink,
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },

  async mounted() {
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.isRecordKeeper = securityAccessObj.inductDrivers;
    }
    await this.fetchTemplates();
  },

  data() {
    return {
      loading: false,

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },

      filter: {
        limit: 10,
        status: "all",
        search: "",
      },

      templates: false,

      tableHeader: [
        "Template Name",
        "Template Type",
        "Creation Date",
        "Last Updated",
      ],

      modalTitle: "",
      modalSize: "4xl",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",

      selectedTemplate: false,

      images: false,
      content: false,

      templateToActivate: false,

      isRecordKeeper: false,
      templateToDefault: false,
    };
  },

  methods: {
    async fetchTemplates(paged = 1) {
      const { business } = await this.$store.getters["account/me"];

      try {
        this.loading = true;

        const query = {
          skip: paged * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
        };

        const templateDocs = await this.$store.dispatch(
          "accreditation/fetchTemplatesByBusiness",
          { query, businessId: business._id }
        );

        const templates = templateDocs?.data?.data?.targetDocument;

        if (!_.isEmpty(templates)) {
          this.templates = this.formatTemplates(
            _.orderBy(templates, ["createdAt"], ["desc"])
          );
        } else {
          this.templates = [];
        }

        if (templateDocs?.data?.data?.resultsMeta) {
          this.paginationSettings = formatPaginationSettings(
            templateDocs?.data?.data?.resultsMeta
          );
        }

        if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
        this.loading = false;
      } catch (e) {
        console.log("error:", e);
      }
    },

    setSecurityAccess(securityRoles) {
      let initialInductDrivers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.drivers.inductDrivers.value) {
          initialInductDrivers = securityConfig.drivers.inductDrivers.value;
        }
      });
      return {
        inductDrivers: initialInductDrivers,
      };
    },

    redirect(id) {
      this.$router.push(
        `/business/fatigue-management/document-templates/${id}/edit`
      );
    },

    async toggleConfirmDefault(id) {
      this.templateToDefault = id;

      this.modalSize = "md";
      this.modalHeight = "30vh";
      this.$refs.modal.openModal();

      this.modalContent = "confirmDefault";
      this.modalTitle = "Set as default template";
    },

    reload() {
      location.reload();
    },

    async onPageChange(event) {
      await this.fetchTemplates(event.page);
    },

    async debouncedSearchString() {
      await this.fetchTemplates();
    },

    async filterChange() {
      await this.fetchTemplates();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    formatTemplates(items) {
      let nItems = [];
      if (items) {
        items.map((data) => {
          const template = {
            id: data?._id,
            documentType: this.getDocumentType(data?.documentType),
            name: data?.name,
            isActive: data?.isActive,
            createdAt: formatDate(data?.createdAt),
            updatedAt: formatDate(data?.updatedAt),
            content: data?.content,
          };
          nItems.push(template);
        });
      }
      return nItems;
    },

    parseTemplates(data) {
      return data.map((templateData) => {
        return [
          {
            id: templateData?.id,
            documentType: templateData?.documentType,
            name: templateData?.name,
            itemType: "string",
            isActive: templateData?.isActive,
            content: templateData?.content,
          },
          {
            id: templateData?.id,
            name: templateData.documentType,
            itemType: "string",
          },
          {
            id: templateData?.id,
            name: templateData?.createdAt,
            itemType: "string",
          },
          {
            id: templateData?.id,
            name: templateData?.updatedAt,
            itemType: "string",
          },
        ];
      });
    },

    getDocumentType(type) {
      switch (type) {
        case "INDUCTION_LETTER":
          return "Induction Letter";
      }
    },

    getDocumentStatus(status) {
      return status === true ? "Active" : "Inactive";
    },

    toggleViewTemplate(data) {
      this.loading = true;

      this.modalSize = "4xl";
      this.modalHeight = DEFAULT_MODAL_HEIGHT;

      this.selectedTemplate = data;
      this.$refs.modal.openModal();
      this.content = data.content;
      this.modalContent = "viewTemplate";
      this.modalTitle = data.name;

      this.loading = false;
    },

    toggleActivateTemplate(data) {
      this.modalSize = "md";
      this.modalHeight = "30vh";
      this.templateToActivate = data;
      this.$refs.modal.openModal();

      this.modalContent = "activateTemplate";
      this.modalTitle = "Activate Template";
    },

    toggleReset() {
      this.modalSize = "md";
      this.modalHeight = "30vh";
      this.$refs.modal.openModal();
      this.modalContent = "resetTemplate";
      this.modalTitle = "Reset Default Template";
    },

    async activateTemplate() {
      try {
        await this.$store.dispatch("accreditation/activateTemplate", {
          templateId: this.templateToActivate,
        });

        location.reload();
      } catch (e) {
        console.log("error:", e);
        this.toast("error", "Error activating template");
        throw new Error(e);
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    async downloadTemplate(templateData) {
      const docSections = templateData.content;

      let doc = new jsPDF("p", "pt", "a4");

      const pageWidth = doc.internal.pageSize.getWidth();

      let typeDimension = doc.getTextWidth("Induction Approval");
      typeDimension = pageWidth - typeDimension;

      const setXCoordinate = 72;
      let baseYCoordinate = 200;

      let i;

      let leftImage;

      let rightImage;

      const header = _.find(
        docSections,
        (section) => section?.type === "Headers"
      );

      if (header) {
        const leftHeaderComponent = _.find(
          header.content,
          (section) => section?.part === "left"
        );

        const rightHeaderComponent = _.find(
          header.content,
          (section) => section?.part === "right"
        );

        if (leftHeaderComponent && leftHeaderComponent.value !== "") {
          const { value } = leftHeaderComponent;
          const { data } = await this.$store.dispatch(
            "accreditation/fetchPreviewImages",
            {
              signedUrl: value,
            }
          );

          leftImage = data;
          if (leftImage && leftHeaderComponent.value) {
            doc.addImage(data, "JPEG", 72, 72, 184.2519685039, 85.03937007874);
          }
        }

        if (rightHeaderComponent && rightHeaderComponent.value !== "") {
          const { value } = rightHeaderComponent;
          const { data } = await this.$store.dispatch(
            "accreditation/fetchPreviewImages",
            {
              signedUrl: value,
            }
          );

          rightImage = data;
          if (rightImage && rightHeaderComponent.value) {
            doc.addImage(data, "JPEG", 339, 72, 184.2519685039, 85.03937007874);
          }
        }
      }

      for (i = 0; i < docSections.length; i++) {
        if (docSections[i].type === "Paragraph") {
          const paragraphDimmensions = doc.getTextWidth(
            docSections[i].contentPreview
          );
          const paragraphHeight = 12 * (paragraphDimmensions / 450);

          if (baseYCoordinate + paragraphHeight + 30 >= 770) {
            this.addPDFPage(doc, leftImage, rightImage);
            baseYCoordinate = 200;
          }

          doc
            .setFont("Helvetica", "normal")
            .setFontSize(12)
            .text(
              docSections[i].contentPreview,
              setXCoordinate,
              baseYCoordinate,
              {
                align: "justify",
                maxWidth: 450,
              }
            );

          baseYCoordinate = parseInt(baseYCoordinate + paragraphHeight + 30);
        }

        if (docSections[i].type === "schemeType") {
          const schemeTitle =
            "National Heavy Vehicle Accreditation Scheme (NHVAS)";

          const schemeType = "Advanced Fatigue Management";

          const schemeTitleDimmensions = doc.getTextWidth(schemeTitle);
          const schemeTypeDimmensions = doc.getTextWidth(schemeType);

          const schemeTitleHeight = 12 * (schemeTitleDimmensions / 450);
          const schemeTypeHeight = 12 * (schemeTypeDimmensions / 450);

          const paragraphHeight = schemeTitleHeight + schemeTypeHeight;

          if (
            baseYCoordinate + (schemeTitleHeight + schemeTypeHeight) + 30 >=
            770
          ) {
            this.addPDFPage(doc, leftImage, rightImage);
            baseYCoordinate = 200;
          }

          doc
            .setFont("Helvetica", "bold")
            .setFontSize(12)
            .text(
              `National Heavy Vehicle Accreditation Scheme (NHVAS)`,
              setXCoordinate,
              baseYCoordinate,
              {
                align: "left",
                maxWidth: 450,
              }
            );

          baseYCoordinate += schemeTitleHeight + 12;

          doc
            .setFont("Helvetica", "normal")
            .setFontSize(12)
            .text(`\u2022 {{schemeType}}`, setXCoordinate, baseYCoordinate, {
              align: "left",
              maxWidth: 450,
            });

          baseYCoordinate = baseYCoordinate + paragraphHeight + 20;
        }

        if (docSections[i].type === "Title") {
          const paragraphDimmensions = doc.getTextWidth(
            docSections[i].contentPreview
          );
          const paragraphHeight = 14 * (paragraphDimmensions / 450);

          if (baseYCoordinate + paragraphHeight + 30 >= 770) {
            this.addPDFPage(doc, leftImage, rightImage);
            baseYCoordinate = 200;
          }

          doc
            .setFont("Helvetica", "bold")
            .setFontSize(14)
            .text(
              docSections[i].contentPreview,
              setXCoordinate,
              baseYCoordinate,
              {
                align: "left",
                maxWidth: 450,
              }
            );

          baseYCoordinate = baseYCoordinate + paragraphHeight + 20;
        }

        if (docSections[i].type === "bulletPoints") {
          if (docSections[i].content.length * 12 + 30 >= 770) {
            this.addPDFPage(doc, leftImage, rightImage);
            baseYCoordinate = 200;
          }

          docSections[i].content.map((bulletData) => {
            const parsedBullet = JSON.parse(JSON.stringify(bulletData));

            doc
              .setFont("Helvetica", "normal")
              .setFontSize(12)
              .text(
                `\u2022 ${parsedBullet.value}`,
                setXCoordinate,
                baseYCoordinate,
                {
                  align: "left",
                  maxWidth: 450,
                }
              );

            baseYCoordinate += 12;
          });

          baseYCoordinate += 30;
        }

        if (docSections[i].type === "Addresses") {
          const leftAddress = _.find(docSections[i].content, (data) => {
            return data.part === "left" && !_.isEmpty(data.value);
          });

          const rightAddress = _.find(docSections[i].content, (data) => {
            return data.part === "right" && !_.isEmpty(data.value);
          });

          if (leftAddress) {
            doc
              .setFont("Helvetica", "normal")
              .setFontSize(12)
              .text(`${leftAddress.value}`, 72, baseYCoordinate, {
                align: "left",
                maxWidth: 150,
              });
          }

          if (rightAddress) {
            doc
              .setFont("Helvetica", "normal")
              .setFontSize(12)
              .text(`${rightAddress.value}`, 523, baseYCoordinate, {
                align: "right",
                maxWidth: 150,
              });
          }

          baseYCoordinate += 30;
        }

        if (docSections[i].type === "Closing") {
          const contentCopy = JSON.parse(
            JSON.stringify(docSections[i].content)
          );

          const rightSlot = doc.internal.pageSize.getWidth() - 90;

          for (let x = 0; x < contentCopy.length; x++) {
            if (contentCopy[x].part === "left") {
              if (contentCopy[x].type === "Admin Signature") {
                doc
                  .setFont("Helvetica", "normal")
                  .setFontSize(12)
                  .text(
                    "{{Operator Signature}}",
                    setXCoordinate,
                    baseYCoordinate,
                    {
                      align: "left",
                      maxWidth: 150,
                    }
                  );
              }
              if (contentCopy[x].type === "Signature Line") {
                doc.line(72, baseYCoordinate + 50, 252, baseYCoordinate + 50);
              }
            }

            if (contentCopy[x].part === "right") {
              if (contentCopy[x].type === "Admin Signature") {
                doc
                  .setFont("Helvetica", "normal")
                  .setFontSize(12)
                  .text("{{Operator Signature}}", rightSlot, baseYCoordinate, {
                    align: "right",
                    maxWidth: 150,
                  });
              }

              if (contentCopy[x].type === "Signature Line") {
                doc.line(
                  rightSlot,
                  baseYCoordinate + 50,
                  rightSlot - 180,
                  baseYCoordinate + 50
                );
              }
            }
          }
          baseYCoordinate += 80;
        }
      }

      const pageCount = doc.internal.getNumberOfPages();

      for (let x = 0; x < pageCount; x++) {
        doc.setPage(x + 1);

        doc
          .setFont("Helvetica", "normal")
          .setFontSize(8)
          .text(`${x + 1} of ${pageCount}`, setXCoordinate, 780, {
            align: "left",
            maxWidth: "450",
          });

        doc
          .setFont("Helvetica", "normal")
          .setFontSize(8)
          .text("201712-0063", 297, 780, {
            align: "left",
            maxWidth: "450",
          });

        doc
          .setFont("Helvetica", "normal")
          .setFontSize(8)
          .text("Induction Approval", typeDimension, 780, {
            align: "left",
            maxWidth: "450",
          });
      }

      doc.save();
    },

    addPDFPage(doc, leftImage, rightImage) {
      doc.addPage();

      if (leftImage) {
        doc.addImage(leftImage, "JPEG", 72, 72, 184.2519685039, 85.03937007874);
      }

      if (rightImage) {
        doc.addImage(
          rightImage,
          "JPEG",
          339,
          72,
          184.2519685039,
          85.03937007874
        );
      }
    },
  },

  computed: {
    tableData() {
      if (this.templates) {
        return this.parseTemplates(this.templates);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
