<template>
  <div>
    <div class="text-center mb-5">
      <h3 class="text-md leading-6 font-black text-black uppercase">
        {{ dynamicLink.linkName }}
      </h3>
      <h3 class="text-lg leading-6 font-black text-black uppercase">
        {{ partner.persona.tradingName }} New Solo
      </h3>
    </div>
    <div class="flex flex-col md:flex-row justify-between text-center">
      <div>
        <div class="text-xs font-bold">ABN</div>
        <div class="text-xs mb-5">{{ partner.persona.abn }}</div>
      </div>
      <div>
        <div class="text-xs font-bold">Contact Number</div>
        <div class="text-xs mb-5">
          {{ partner.persona.contactPhoneNumber }}
        </div>
      </div>
      <div>
        <div class="text-xs font-bold">Support Email</div>
        <div class="text-xs mb-5">{{ partner.supportEmail }}</div>
      </div>
    </div>
    <FormulateForm
      v-model="values"
      @submit="onSubmit"
      :schema="schema"
      @check-email="checkEmail($event)"
    >
      <h5 class="text-black font-medium italic">
        *Signing up here includes a
        <span v-if="dynamicLink.promoType == 'demoPeriod'"
          >{{ dynamicLink.promoValue }} week(s) demo period.</span
        >
        <span v-else-if="dynamicLink.promoType == 'discount'"
          >{{ dynamicLink.promoValue }}% discount.</span
        >
      </h5>
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
        <div v-if="partner" class="text-center text-l font-bold mb-5">
          Sign up here or speak to {{ partner.persona.tradingName }} if you have
          any questions.
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="hasErrorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import soloSignupformSchema from "./solo-signupform-schema";
import { mapGetters } from "vuex";
export default {
  name: "Business-Signup-Form",
  props: {
    linkData: {
      default: () => {},
      type: Object,
    },
  },
  data() {
    return {
      partner: {},
      dynamicLink: {},
      values: {},
      schema: soloSignupformSchema,
      errorMessage: "",
      hasErrorMessage: false,
      loading: false,
    };
  },
  async created() {
    await this.init();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  methods: {
    async init() {
      this.partner = this.linkData.partnerId;
      this.dynamicLink = this.linkData;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    onSubmit() {
      this.loading = true;
      const formData = {
        driverData: this.values,
        parentRole: "partner",
        dynamicLinkId: this.dynamicLink._id,
      };

      this.$store
        .dispatch(`dynamicLink/createSoloFromPromoLink`, formData)
        .then((response) => {
          if (response && response._id) {
            this.loading = false;
            this.$emit("newDriverAdded", response);
          }
        })
        .catch((error) => {
          this.loading = false;
          this.hasErrorMessage = true;
          this.errorMessage = `Something went wrong please try again.`;
          if (error.message) this.errorMessage = error.message;
        });
    },
    checkEmail(value) {
      this.values.emailAddress = value.toLowerCase();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
