<template>
  <FormulateForm @submit="onNext">
    <div class="loading mt-32 h-32" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" class="text-center text-2xl">Buy Product Pricing</div>
    <div class="my-5 overflow-x-auto">
      <div class="inline-block min-w-full rounded-lg overflow-hidden">
        <table v-if="!loading" class="table-auto leading-normal p-4">
          <thead>
            <tr>
              <th class="bg-gray-100 w-2/5 text-left">Product</th>
              <th class="bg-gray-200">Monthly</th>
              <th class="bg-gray-200">Quarterly</th>
              <th class="bg-gray-200">Yearly</th>
            </tr>
          </thead>
          <tbody v-if="values">
            <tr
              v-for="(product, index) in values"
              :key="index"
              class="border-t-2"
            >
              <td class="w-80 pl-5 pr-9 text-sm bg-gray-100">
                {{ product.name == "EWD-FT" ? "EWD" : product.name }}
              </td>
              <td class="pt-3 px-5 text-center">
                <div class="flex">
                  <span class="pt-1 pl-4 w-0">$</span>
                  <FormulateInput
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0"
                    class="p-0 w-28"
                    input-class="w-28 h-7 ml-3 text-right py-4 px-0 border outline-none"
                    error-behavior="live"
                    :value="parsePrice(product.pricing.monthly)"
                    :name="`${removeSpaces(product.name)}.pricing.monthly`"
                  />
                </div>
              </td>
              <td class="pt-3 px-6 text-center">
                <div class="flex relative pt-2 pb-3">
                  <span class="pt-2 pl-4 w-0">$</span>
                  <FormulateInput
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0"
                    class="p-0 w-28"
                    input-class="w-28 h-7 ml-3 text-right py-4 px-0 border outline-none"
                    error-behavior="live"
                    :value="parsePrice(product.pricing.quarterly)"
                    :name="`${removeSpaces(product.name)}.pricing.quarterly`"
                    v-on:input="quarterlyPerMonthInput($event, index, false)"
                  />
                  <small
                    ref="quarterlyPerMonthRef"
                    class="absolute mt-9 ml-4 opacity-90 w-full text-xs"
                  >
                    ({{ quarterlyPerMonthDesc[index] || `$0.00 Per Month` }})
                  </small>
                </div>
              </td>
              <td class="pt-3 px-6 text-center">
                <div class="flex relative pt-2 pb-3">
                  <span class="pt-2 pl-4 w-0">$</span>
                  <FormulateInput
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0"
                    class="p-0 w-28"
                    input-class="w-28 h-7 ml-3 text-right py-4 px-0 border outline-none"
                    error-behavior="live"
                    :value="parsePrice(product.pricing.yearly)"
                    :name="`${removeSpaces(product.name)}.pricing.yearly`"
                    v-on:input="yearlyPerMonthInput($event, index, false)"
                  />
                  <small
                    ref="yearlyPerMonthRef"
                    class="absolute mt-9 ml-4 opacity-90 w-full text-xs"
                  >
                    ({{ yearlyPerMonthDesc[index] || `$0.00 Per Month` }})
                  </small>
                </div>
                <FormulateInput
                  type="hidden"
                  :value="parsePrice(product.rrp.monthly)"
                  :name="`${removeSpaces(product.name)}.rrp.monthly`"
                />
                <FormulateInput
                  type="hidden"
                  :value="parsePrice(product.rrp.quarterly)"
                  :name="`${removeSpaces(product.name)}.rrp.quarterly`"
                />
                <FormulateInput
                  type="hidden"
                  :value="parsePrice(product.rrp.yearly)"
                  :name="`${removeSpaces(product.name)}.rrp.yearly`"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <br />
        <div class="mt-5">
          <div class="rounded-lg">
            <table v-if="!loading" class="table-auto shadow leading-normal">
              <tr>
                <td class="bg-gray-100 w-2/5 text-left pl-6 pr-2" rowspan="6">
                  <p class="w-32">Pre-paid EWD</p>
                </td>
                <th class="bg-gray-200">
                  <p class="text-center">Packs</p>
                </th>
                <th class="bg-gray-200">
                  <p class="text-center">Product Pricing</p>
                </th>
              </tr>
              <tr class="border-t-2">
                <td class="w-3/5 pl-5 pr-9 text-center text-sm">1 Day</td>
                <td class="w-full pt-3 pr-7 text-center">
                  <div class="flex relative">
                    <span class="pt-3 pl-11 w-0">$</span>
                    <FormulateInput
                      v-if="prepaidPricing.oneDay"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-full"
                      input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                      :value="parsePrice(prepaidPricing.oneDay)"
                      name="pricingOneDay"
                    />
                  </div>
                </td>
              </tr>
              <tr class="border-t-2">
                <td class="w-3/5 pl-5 pr-9 text-center text-sm">5 Days</td>
                <td class="w-full pt-3 pr-7 text-center">
                  <div class="flex relative">
                    <span class="pt-3 pl-11 w-0">$</span>
                    <FormulateInput
                      v-if="prepaidPricing.fiveDays"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-full"
                      input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                      :value="parsePrice(prepaidPricing.fiveDays)"
                      name="pricingFiveDays"
                    />
                  </div>
                </td>
              </tr>
              <tr class="border-t-2">
                <td class="w-3/5 pl-5 pr-9 text-center text-sm">10 Days</td>
                <td class="w-full pt-3 pr-7 text-center">
                  <div class="flex relative">
                    <span class="pt-3 pl-11 w-0">$</span>
                    <FormulateInput
                      v-if="prepaidPricing.tenDays"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-full"
                      input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                      :value="parsePrice(prepaidPricing.tenDays)"
                      name="pricingTenDays"
                    />
                  </div>
                </td>
              </tr>
              <tr class="border-t-2">
                <td class="w-3/5 pl-5 pr-9 text-center text-sm">25 Days</td>
                <td class="w-full pt-3 pr-7 text-center">
                  <div class="flex relative">
                    <span class="pt-3 pl-11 w-0">$</span>
                    <FormulateInput
                      v-if="prepaidPricing.twentyFiveDays"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-full"
                      input-class="h-7 ml-3 mt-1 text-right py-5 px-0 border outline-none"
                      :value="parsePrice(prepaidPricing.twentyFiveDays)"
                      name="pricingTwentyFiveDays"
                    />
                  </div>
                </td>
              </tr>
              <tr class="border-t-2">
                <td class="w-3/5 pl-5 pr-9 text-center text-sm">50 Days</td>
                <td class="w-full pt-3 pr-7 text-center">
                  <div class="flex relative">
                    <span class="pt-3 pl-11 w-0">$</span>
                    <FormulateInput
                      v-if="prepaidPricing.fiftyDays"
                      type="number"
                      step="0.01"
                      min="0"
                      placeholder="0"
                      class="p-0 w-full"
                      input-class="h-7 ml-3 mt-1 text-right py-4 px-0 border outline-none"
                      :value="parsePrice(prepaidPricing.fiftyDays)"
                      name="pricingFiftyDays"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
      <button
        class="btn col-span-2 btn-primary mt-2"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        Next
      </button>
      <button
        @click.prevent="onPrevious"
        class="btn bg-transparent border col-span-2 btn-info mt-2"
      >
        Back
      </button>
    </div>
  </FormulateForm>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Edit-partner-Standard-Pricing",
  props: {
    msg: String,
    preData: null,
  },
  data() {
    return {
      errorMessage: "",
      loading: false,
      values: {},
      prepaid: {},
      prepaidPricing: {},
      prepaidEWDPricingWatch: null,
      prepaidRRP: {},
      standardPricingMasterId: null,
      prepaidName: null,
      quarterlyPerMonthDesc: [],
      yearlyPerMonthDesc: [],
      accountInfo: null,
      detailsId: null,
    };
  },
  async mounted() {
    this.loading = true;
    let standardpricing;

    if (!_.isEmpty(this.preData.pricing)) {
      standardpricing = this.preData.pricing;
      this.prepaid = this.preData.prepaid;
      this.accountInfo = this.preData.accountInfo;
      this.values = this.preData.pricing;
      this.rrpData = this.preData.rrp;
    } else {
      standardpricing = await this.$store.dispatch(
        `standardpricing/getStandardPricingByMongoId`,
        this.preData.detailsId
      );
      this.pricing = this.parseModules(
        _.orderBy(standardpricing, ["_id"], ["asc"])
      );
      this.values = this.pricing.filter((item) => item.isRrp === false);
      this.rrpData = this.pricing.filter((item) => item.isRrp === true);
      this.values.forEach((price) => {
        this.rrpData.forEach((rrp) => {
          if (price.name === rrp.name) {
            price.rrp = rrp.pricing;
          }
        });
      });
    }

    if (standardpricing) {
      if (_.isEmpty(this.preData.pricing)) {
        let prepaid = this.parsePrepaidModules(
          _.orderBy(standardpricing, ["_id"], ["asc"])
        );
        this.prepaid = prepaid.filter((item) => item.isRrp === false)[0];
        this.prepaidRRP = prepaid.filter(
          (item) => item.isRrp === true
        )[0]?.pricing;
        this.prepaidPricing = this.prepaid.pricing;

        this.standardPricingMasterId = this.prepaid.standardPricingMaster._id;
      } else {
        this.prepaid = this.preData.prepaid;
        this.prepaidRRP = this.preData.prepaid.rrp;
        this.prepaidPricing = this.preData.prepaid.pricing;

        this.standardPricingMasterId = this.prepaid.standardPricingMasterId;
      }

      if (!_.isEmpty(this.values)) {
        this.values.forEach((value, index) => {
          this.quarterlyPerMonthInput(
            this.parsePrice(value.pricing.quarterly),
            index,
            true
          );
          this.yearlyPerMonthInput(
            this.parsePrice(value.pricing.yearly),
            index,
            true
          );
        });
      }
      this.loading = false;
    }
  },
  methods: {
    parsePrice(price) {
      return parseFloat(price).toFixed(2);
    },
    parseModules(arr) {
      return arr.filter(
        (item) => item.name != "EWD-PT" && item.name != "Pre-Paid EWD"
      );
    },
    parsePrepaidModules(arr) {
      let prepaidArr;
      prepaidArr = arr.filter((item) => item.name == "Pre-Paid EWD");
      if (!_.isEmpty(prepaidArr)) {
        this.prepaidName = arr[0].name;
      }
      return prepaidArr;
    },
    quarterlyPerMonthInput(input, index, init) {
      let price = this.parsePrice(input / 3);
      if (init) {
        this.quarterlyPerMonthDesc.push(`$${price || 0.0} Per Month`);
      } else {
        this.$parent.$children[0].$refs.quarterlyPerMonthRef[
          index
        ].innerHTML = `$${price || 0.0} Per Month`;
      }
    },
    yearlyPerMonthInput(input, index, init) {
      let price = this.parsePrice(input / 12);
      if (init) {
        return this.yearlyPerMonthDesc.push(`$${price || 0.0} Per Month`);
      } else {
        this.$parent.$children[0].$refs.yearlyPerMonthRef[
          index
        ].innerHTML = `$${price || 0.0} Per Month`;
      }
    },
    removeSpaces(string) {
      return string.replace(/\s+/g, "");
    },
    onNext(formData) {
      let values = [];
      this.values.forEach((obj) => {
        if (obj) {
          let data = Object(formData);
          const pricing = {
            monthly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.pricing.monthly`]
            ),
            quarterly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.pricing.quarterly`]
            ),
            yearly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.pricing.yearly`]
            ),
          };
          let rrp = {
            monthly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.rrp.monthly`]
            ),
            quarterly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.rrp.quarterly`]
            ),
            yearly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.rrp.yearly`]
            ),
          };
          const dto = {
            name: obj.name,
            standardPricingMasterId:
              obj.standardPricingMaster?._id || obj.standardPricingMasterId,
            pricing: pricing,
            rrp: rrp,
          };
          values.push(dto);
        }
      });
      let prepaidData = formData;
      const prepaidPricing = {
        oneDay: this.parsePrice(prepaidData.pricingOneDay),
        fiveDays: this.parsePrice(prepaidData.pricingFiveDays),
        tenDays: this.parsePrice(prepaidData.pricingTenDays),
        twentyFiveDays: this.parsePrice(prepaidData.pricingTwentyFiveDays),
        fiftyDays: this.parsePrice(prepaidData.pricingFiftyDays),
      };
      let prepaid = {
        pricing: prepaidPricing,
        rrp: this.prepaidRRP,
        standardPricingMasterId: this.standardPricingMasterId,
        name: this.prepaidName,
      };
      this.$emit("nextStep", {
        pricing: values,
        prepaid: prepaid,
        businessProfile: this.preData.businessProfile,
        accountInfo: this.preData.accountInfo,
      });
    },
    onPrevious() {
      this.$emit("backStep", {
        pricing: this.pricing,
        prepaid: this.prepaid,
        businessProfile: this.preData.businessProfile,
        accountInfo: this.accountInfo,
      });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style scoped lang="scss">
th {
  @apply px-5 py-3 border-gray-700 text-sm font-semibold text-gray-600 uppercase;
}
</style>
