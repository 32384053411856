<template>
  <div class="w-full flex">
    <div v-if="isLoading" class="text-center">
      <Spinner class="m-auto mb-2"></Spinner> Loading user profile...
    </div>
    <div class="container mt-0 m-auto" v-else>
      <EditUserProfile :profile-data="profile" />
    </div>
  </div>
</template>

<script>
import { EditUserProfile } from "@/components/forms/userprofile";
import Spinner from "@/components/commons/ui/Spinner";
export default {
  name: "ProfileEditPage",
  components: {
    EditUserProfile,
    Spinner,
  },
  data() {
    return {
      isLoading: false,
      profile: {},
      role: {},
    };
  },
  async mounted() {
    this.isLoading = true;
    this.$emit("updateLoading", true);
    this.init();
  },
  methods: {
    async init() {
      const me = await this.$store.getters[`account/me`];
      this.role = me.role;

      if (this.role.name === "driver") {
        this.profile = me.driver;
      }

      this.isLoading = false;
      this.$emit("updateLoading", false);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
