<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="driversData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            v-on:keyup.enter="filterChange"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteEventsAnnotations(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon
                    icon="calendar-times"
                    class="my-auto mr-2"
                  />
                  Delete
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>
  </div>
</template>

<script>
import { Table, Dropdown } from "@/components/commons";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Admin-Driver-Events-Annotations",
  components: { Table, Dropdown },
  data() {
    return {
      drivers: [],
      tableHeader: [
        "Full Name",
        "Email Address",
        "Licence",
        "Phone",
        "Date Created",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      recordsPerPage: 50,
      searchKeyword: "",
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init(paged = 1) {
      this.$emit("updateLoading", true);
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };
      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };
      let driverData;

      const soloDrivers = await this.$store.dispatch(`driver/getDriver`, {
        id: null,
        query,
      });
      if (soloDrivers?.metadata) {
        this.paginationSettings = formatPaginationSettings(
          soloDrivers?.metadata
        );
        driverData = soloDrivers.results;

        if (driverData) {
          const driversTableData = this.formatItem(
            _.orderBy(driverData, ["createdAt"], ["desc"])
          );
          this.drivers = driversTableData;
        }
        this.$emit("updateLoading", false);
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    async deleteEventsAnnotations(driverId) {
      if (
        confirm(
          "Are you sure you want to delete all events and annotations linked to this driver?"
        )
      ) {
        let result;
        result = await this.$store.dispatch(
          `business/deleteDriverEventAnnotations`,
          driverId
        );
        if (result) {
          this.init();
        }
      }
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            uid: item["uid"],
            id: item["_id"],
            name: item["driverName"] || "",
            email: item["emailAddress"] || "",
            license: item["driversLicenseNumber"] || "",
            phone: item["supportPhone"] || "",
            created: formatDate(item["createdAt"]) || "",
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData.id,
          name: driverData.name,
          itemType: "name",
          hasImage: true,
        },
        { id: driverData.id, name: driverData.email, itemType: "string" },
        { id: driverData.id, name: driverData.license, itemType: "string" },
        { id: driverData.id, name: driverData.phone, itemType: "string" },
        { id: driverData.id, name: driverData.created, itemType: "string" },
      ]);
    },
  },
  computed: {
    driversData() {
      if (this.drivers) {
        return this.parseDrivers(this.drivers);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
