<template>
  <div>
    <div v-if="!loading" class="flex flex-col items-center space-y-4">
      <FormulateForm
        v-if="!loading"
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="flex flex-col w-full">
          <FormulateInput
            type="text"
            name="afmNumber"
            label="AFM Number"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="effectiveDate"
            label="Effective Date"
            validation="required"
          />
          <FormulateInput
            type="date"
            name="expiryDate"
            label="Expiry Date"
            validation="required"
            :min="values.effectiveDate"
          />
          <FormulateInput
            type="number"
            name="numberOfTables"
            label="Number of Tables"
            validation="required|number|min:1|max:4"
            min="1"
            max="4"
          />
          <FormulateInput
            type="checkbox"
            class="formulate-input"
            name="isActive"
            label="Mark as active"
            v-model="isActive"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            help="*Check this box if you want to set this as an active afm access number."
            element-class="formulate-input-element formulate-input-element--checkbox"
          />
          <StyledSubmit :backgroundColor="theme.secondaryColor">
            <FormulateInput
              type="submit"
              label="Submit"
              input-class="btn btn-primary w-full"
              :disabled="hasErrors || submitLoading"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              <span v-if="submitLoading"
                ><font-awesome-icon
                  icon="spinner"
                  class="mr-1 loader"
                />Submitting</span
              >
              <span v-if="!submitLoading">Submit</span>
            </FormulateInput>
          </StyledSubmit>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { StyledSubmit } from "@/styled-components";
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    businessId: {
      default: "",
      type: String,
    },
    afmAccessId: {
      default: null,
      type: String,
    },
  },
  components: { StyledSubmit },
  data() {
    return {
      business: {},
      loading: false,
      submitLoading: false,
      values: {},
      isActive: false,
      afmAccess: {},
    };
  },
  methods: {
    async getBusiness() {
      this.loading = true;
      try {
        const business = await this.$store.dispatch(`business/getBusiness`, {
          id: this.businessId,
        });

        this.business = business;
      } catch (error) {
        console.log(error);
      } finally {
        if (this.afmAccessId) {
          await this.getAfmAccess();
        }
        this.loading = false;
      }
    },
    async getAfmAccess() {
      this.loading = true;
      try {
        const afmAccess = await this.$store.dispatch(
          `business/findAFMAccessById`,
          { id: this.afmAccessId }
        );

        this.afmAccess = afmAccess;
        this.values = { ...afmAccess };
        this.values.effectiveDate = moment(afmAccess.effectiveDate).format(
          "YYYY-MM-DD"
        );
        this.values.expiryDate = moment(afmAccess.expiryDate).format(
          "YYYY-MM-DD"
        );
        this.values.numberOfTables = afmAccess.tables.length;
        this.isActive = afmAccess.isActive;
      } catch (error) {
        console.log(error);
      }
    },
    async onSubmit() {
      if (this.afmAccessId != null) {
        try {
          this.submitLoading = true;
          const payload = {
            afmNumber: this.values.afmNumber,
            effectiveDate: moment(this.values.effectiveDate).format(
              "YYYY-MM-DD"
            ),
            expiryDate: moment(this.values.expiryDate).format("YYYY-MM-DD"),
            isActive: this.isActive,
            id: this.afmAccess._id,
            business: this.business,
            numberOfTables: this.values.numberOfTables,
          };

          await this.$store.dispatch("business/updateAFMAccess", payload);

          this.submitLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Afm access updated successfully.",
          });
          this.$emit("closeModal");
        } catch (error) {
          this.submitLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: "Error updating afm access.",
          });
          console.log(error);
        }
      } else {
        try {
          this.submitLoading = true;
          const payload = {
            business: this.business,
            afmNumber: this.values.afmNumber,
            effectiveDate: moment(this.values.effectiveDate).format(
              "YYYY-MM-DD"
            ),
            expiryDate: moment(this.values.expiryDate).format("YYYY-MM-DD"),
            isActive: this.isActive,
            numberOfTables: this.values.numberOfTables,
          };

          await this.$store.dispatch("business/createAFMAccess", payload);

          this.submitLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Afm access added successfully.",
          });
          this.$emit("closeModal");
        } catch (error) {
          this.submitLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: "Error adding afm access.",
          });
          console.log(error);
        }
      }
    },
  },
  async created() {
    this.loading = true;
    await this.getBusiness();
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
