export default {
  updateThemeLookupOptions(state, themeLookupLists) {
    state.themeOptions = themeLookupLists;
  },
  updateActiveTheme(state, data) {
    const { themeData: colorScheme } = data;
    state.activeTheme = data;
    state.colorScheme = colorScheme;
  },
  setInitializedTheme(state, initializedTheme) {
    state.initializedTheme = initializedTheme;
  },
  updateEnableDynamicTheme(state, isEnableDynamicTheme) {
    state.isEnableDynamicTheme = isEnableDynamicTheme;
  },
};
