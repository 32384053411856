import { File } from "@/api";

export default {
  async fileUpload({ state }, payload) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await File.fileUpload(payload);
      return response.data.data;
    } catch (e) {
      console.log("error:", e);
      throw e;
    }
  },
};
