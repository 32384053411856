<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeRefresh="closeRefresh"
    @toast="toast"
  />
</template>
<script>
import { Stepper } from "@/components/commons";
import {
  FleetProviderSelection,
  FleetDetails,
  FleetCreationConfirmation,
} from "./stepper-component";
export default {
  name: "Add-Update-Fleet-Connection",
  components: {
    Stepper,
  },
  data() {
    return {
      stepperSteps: [
        {
          stepIcon: "user",
          stepTitle: "Provider",
          stepSequence: "first",
          stepComponent: FleetProviderSelection,
        },
        {
          stepIcon: "pen",
          stepTitle: "Details",
          stepSequence: "second",
          stepComponent: FleetDetails,
        },
        {
          stepIcon: "check",
          stepTitle: "Confirmation",
          stepSequence: "third",
          stepComponent: FleetCreationConfirmation,
        },
      ],
    };
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      });
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if (step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      });
    },
    toast(type, message) {
      this.$emit("toast", type, message);
    },
    closeRefresh() {
      this.$emit("closeRefresh");
    },
  },
};
</script>
