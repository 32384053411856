<template>
  <div class="mb-16 py-4 px-2">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" class="h-60">
      <div>
        <div class="text-s">Current Partner</div>
        <div class="font-bold text-black-primary text-base mb-3">
          {{
            currentPartner && currentPartner.persona
              ? currentPartner.persona?.businessName
              : "N/A"
          }}
        </div>

        <FormulateInput
            type="select"
            placeholder="Select Entity"
            class="w-full"
            name="entityType"
            :options="['Partner', 'Reseller']"
            validation="required"
            v-model="parentEntityType"
        />

        <FormulateInput
          v-if="parentEntityType != null && parentEntityType === 'Partner'"
          type="partner-select"
          placeholder="Select a partner"
          style="margin-bottom: 0px"
          class="pb-4 content-center"
          :getOptionKey="(option) => option.persona._id"
          :getOptionLabel="(option) => option.persona.businessName"
          :filterable="false"
          :clearable="false"
          validation="required"
          @input="onChange($event)"
        />

        <FormulateInput
            v-if="parentEntityType != null && parentEntityType === 'Reseller'"
            type="solo-reseller-select"
            placeholder="Select a reseller"
            style="margin-bottom: 0px"
            class="pb-4 content-center"
            :getOptionKey="(option) => option.persona._id"
            :getOptionLabel="(option) => option.persona.businessName"
            :filterable="false"
            :clearable="false"
            validation="required"
            @input="onChange($event)"
        />

        <Table
          v-if="partners.length && !selectedPartner"
          :tableHeader="tableHeader"
          :data="partners"
          :hasFilters="false"
          :hasActions="false"
        >
          <template #default="{ data }">
            <fragment v-if="data">
              <td class="px-5 py-2">
                <button
                  class="btn btn-primary mt-2"
                  @click="selectPartner(data)"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  Select
                </button>
              </td>
            </fragment>
          </template>
        </Table>

        <div v-if="selectedPartner">
          <button
            @click="clearPartner"
            title="Reset fields"
            class="text-red-900 float-right -mt-10"
          >
            <span class="cursor-pointer">
              <font-awesome-icon icon="times" class="text-gray-400" />
            </span>
          </button>

          <div v-if="selectedPartner" class="grid grid-cols-2 gap-4 mt-5">
            <div>
              <div class="text-s">Name</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedPartner.persona.businessName }}
              </div>
            </div>
            <div>
              <div class="text-s">Email Address</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedPartner.persona.contactEmail }}
              </div>
            </div>
            <div>
              <div class="text-s">Contact Number</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedPartner.persona.contactPhoneNumber }}
              </div>
            </div>
            <div>
              <div class="text-s">Business Address</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedPartner.persona.businessAddress }}
              </div>
            </div>
            <div>
              <div class="text-s">ABN</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedPartner.persona.abn }}
              </div>
            </div>
          </div>
          <br />
          <hr />
        </div>

        <div v-if="selectedPartner">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            label="Submit"
            :disabled="isSubmitting || loading"
            @click="onSubmit"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="isSubmitting"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Moving
              data...</span
            >
            <span v-if="!isSubmitting">Submit</span>
          </FormulateInput>
        </div>
      </div>

      <div class="text-red-500 text-center mb-3" v-if="error">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import { Table } from "@/components/commons";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Update-Business-Partner-Form",
  components: {
    Table,
  },
  data() {
    return {
      loading: false,
      values: {},
      error: false,
      errorMessage: [],
      options: [1, 2, 3, 4, 5],
      partners: [],
      partnersOrigin: [],
      selectedPartner: null,
      tableHeader: ["Partner Name", "Email", "Phone Number", "Actions"],
      isSubmitting: false,
      searchPartnerModel: "",
      activeBusinessContract: {},
      incomingBusinessContract: {},
      activeBusinessContractBusinessModules: [],
      incomingBusinessContractModules: [],
      moduleSelect: null,
      partner: {},
      currentPartner: {},
      parentEntityType: "",
    };
  },
  props: {
    activeBusiness: {
      type: Object,
      default: () => {},
    },
  },
  async created() {
    if (this.activeBusiness && this.activeBusiness.entityId) {
      try {
        this.loading = true;
        this.currentPartner = await this.$store.dispatch("partner/getPartner", {
          id: this.activeBusiness.entityId,
        });
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = true;
        this.errorMessage = "Error fetching current Partner";
      }
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;
      if (_.isEmpty(this.selectedPartner) && _.isEmpty(this.selectedPartner)) {
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "info",
          message: "Please select a partner.",
        });
      }

      if (
        confirm(
          "Are you sure you want to move this business to other partner/reseller? This action cannot be undone."
        )
      ) {
        let payload = {
          parentRole: this.parentEntityType.toLowerCase(),
          parentEntityId: this.selectedPartner._id,
          businessId: this.activeBusiness._id,
        };

        await this.$store
          .dispatch("business/updateBusinessParentConnection", payload)
          .then((res) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: res.message,
            });
            this.$emit("closeModal");
          })
          .catch((err) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: err.message,
            });
            this.partners = [];
            this.loading = false;
          });
      } else {
        this.$emit("closeModal");
      }
      this.isSubmitting = false;
    },
    clearPartner() {
      this.selectedPartner = null;
      this.partners = [];
    },
    async onChange(partner) {
      if (partner) {
        this.errorMessage = [];
        this.selectedPartner = partner;
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
<style>
:focus {
  outline: none;
}
.radio {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  border: 3px solid rgba(241, 241, 241, 0.507);
  border-top-color: rgb(228, 228, 228);
  border-left-color: rgb(240, 240, 240);
  background: #fff;
  padding: 22px;
  border-radius: 10px;
}
.radio-button:checked {
  border: 3px solid rgb(138, 223, 11);
}
</style>
