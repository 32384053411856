import { API } from "@/api/config";
import { ObjectToQueryString } from "../../../_helper";

export default new (class VehicleConfig {
  // @VehicleClass
  createVehicleClass(data) {
    // Create vehicle class
    return API.ACCOUNTS.post(`/vehicle-class`, data);
  }
  getVehicleClass(id = null) {
    // Get single Vehicle Class by Id or Get all VehicleClass
    if (id) {
      return API.ACCOUNTS.get(`/vehicle-class/${id}`);
    } else {
      return API.ACCOUNTS.get(`/vehicle-class`);
    }
  }
  getVehicleClassSearch(data) {
    return API.ACCOUNTS.post(`/vehicle-class/search/`, data);
  }
  patchVehicleClass(data) {
    // Update single Vehicle Class by Id
    return API.ACCOUNTS.patch(`/vehicle-class/${data.id}`, data);
  }
  deleteVehicleClass(id = null) {
    // Delete Vehicle Class by Id
    return API.ACCOUNTS.delete(`/vehicle-class/${id}`);
  }

  // @VehicleType
  createVehicleType(data) {
    // Create vehicle type
    return API.ACCOUNTS.post(`/vehicle-type`, data);
  }
  getVehicleType(id = null) {
    // Get single Vehicle Class by Id or Get all VehicleType
    if (id) {
      return API.ACCOUNTS.get(`/vehicle-type/${id}`);
    } else {
      return API.ACCOUNTS.get(`/vehicle-type`);
    }
  }
  getVehicleTypeSearch(data) {
    // Get Vehicle Types that are connected to a specific vehicleClassId, optional search string
    return API.ACCOUNTS.post(`/vehicle-type/search`, data);
  }
  getActiveVehicleTypeSearch(data) {
    // Get Active Vehicle Types that are connected to a specific vehicleClassId, optional, search string
    return API.ACCOUNTS.post(`/vehicle-type/search-active`, data);
  }
  patchVehicleType(data) {
    // Update single Vehicle Class by Id
    return API.ACCOUNTS.patch(`/vehicle-type/${data.id}`, data);
  }
  deleteVehicleType(id = null) {
    // Delete Vehicle Class by Id
    return API.ACCOUNTS.delete(`/vehicle-type/${id}`);
  }

  fetchVehicleTypeImages(query = { skip: 0, limit: 10 }) {
    query = ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/vehicle-type/images?${query}`);
  }

  uploadImageVehicleType(data) {
    const { id, image } = data;
    return API.ACCOUNTS.patch(`/vehicle-type/${id}/image`, { image });
  }

  // @RearCoupling
  createRearCoupling(data) {
    // Create rear coupling type
    return API.ACCOUNTS.post(`/rear-coupling`, data);
  }
  getRearCoupling(id = null) {
    // Get single Rear Coupling by Id or Get all RearCouplings
    if (id) {
      return API.ACCOUNTS.get(`/rear-coupling/${id}`);
    } else {
      return API.ACCOUNTS.get(`/rear-coupling`);
    }
  }
  getRearCouplingSearch(data) {
    return API.ACCOUNTS.post(`/rear-coupling/search/`, data);
  }
  getRearCouplingByUID(uid) {
    return API.ACCOUNTS.get(`/rear-coupling/find-by-uid/${uid}`);
  }
  getActiveRearCouplingByUID(uid) {
    return API.ACCOUNTS.get(`/rear-coupling/find-active-by-uid/${uid}`);
  }
  patchRearCoupling(data) {
    // Update single Rear Coupling by Id
    return API.ACCOUNTS.patch(`/rear-coupling/${data.id}`, data);
  }
  deleteRearCoupling(id = null) {
    // Delete Rear Coupling by Id
    return API.ACCOUNTS.delete(`/rear-coupling/${id}`);
  }

  // @FrontCoupling
  createFrontCoupling(data) {
    // Create front coupling type
    return API.ACCOUNTS.post(`/front-coupling`, data);
  }
  getFrontCoupling(id = null) {
    // Get single Front Coupling by Id or Get all FrontCouplings
    if (id) {
      return API.ACCOUNTS.get(`/front-coupling/${id}`);
    } else {
      return API.ACCOUNTS.get(`/front-coupling`);
    }
  }
  getFrontCouplingSearch(data) {
    return API.ACCOUNTS.post(`/front-coupling/search/`, data);
  }
  getFrontCouplingByUID(uid) {
    return API.ACCOUNTS.get(`/front-coupling/find-by-uid/${uid}`);
  }
  getActiveFrontCouplingByUID(uid) {
    return API.ACCOUNTS.get(`/front-coupling/find-active-by-uid/${uid}`);
  }
  patchFrontCoupling(data) {
    // Update single Front Coupling by Id
    return API.ACCOUNTS.patch(`/front-coupling/${data.id}`, data);
  }
  deleteFrontCoupling(id = null) {
    // Delete Front Coupling by Id
    return API.ACCOUNTS.delete(`/front-coupling/${id}`);
  }

  // @CouplingCompatibility
  createCouplingCompatibility(data) {
    // Create coupling compatibility type
    return API.ACCOUNTS.post(`/coupling-compatibility`, data);
  }
  getCouplingCompatibility(id = null) {
    // Get single Coupling by Id or Get all CouplingCompatibilitys
    if (id) {
      return API.ACCOUNTS.get(`/coupling-compatibility/${id}`);
    } else {
      return API.ACCOUNTS.get(`/coupling-compatibility`);
    }
  }
  getCouplingCompatibilitySearch(data) {
    return API.ACCOUNTS.post(`/coupling-compatibility/search/`, data);
  }
  getCouplingCompatibilityByUID(uid) {
    return API.ACCOUNTS.get(`/coupling-compatibility/find-by-uid/${uid}`);
  }
  patchCouplingCompatibility(data) {
    // Update single Coupling by Id
    return API.ACCOUNTS.patch(`/coupling-compatibility/${data.id}`, data);
  }
  deleteCouplingCompatibility(id = null) {
    // Delete Coupling by Id
    return API.ACCOUNTS.delete(`/coupling-compatibility/${id}`);
  }

  // @WiringPlugConnector
  createWiringPlugConnector(data) {
    // Create WiringPlugConnector type
    return API.ACCOUNTS.post(`/wiring-plug-connector`, data);
  }
  getWiringPlugConnector(id = null) {
    // Get single WiringPlugConnector by Id or Get all WiringPlugConnectors
    if (id) {
      return API.ACCOUNTS.get(`/wiring-plug-connector/${id}`);
    } else {
      return API.ACCOUNTS.get(`/wiring-plug-connector`);
    }
  }
  getWiringPlugConnectorSearch(data) {
    return API.ACCOUNTS.post(`/wiring-plug-connector/search/`, data);
  }
  getWiringPlugConnectorByUID(uid) {
    return API.ACCOUNTS.get(`/wiring-plug-connector/find-by-uid/${uid}`);
  }
  getActiveWiringPlugConnectorByUID(uid) {
    return API.ACCOUNTS.get(`/wiring-plug-connector/find-active-by-uid/${uid}`);
  }
  patchWiringPlugConnector(data) {
    // Update single WiringPlugConnector by Id
    return API.ACCOUNTS.patch(`/wiring-plug-connector/${data.id}`, data);
  }
  deactivateWiringPlugConnector(id) {
    // Update single WiringPlugConnector by Id
    return API.ACCOUNTS.patch(`/wiring-plug-connector/deactivate/${id}`);
  }
  deleteWiringPlugConnector(id = null) {
    // Delete WiringPlugConnector by Id
    return API.ACCOUNTS.delete(`/wiring-plug-connector/${id}`);
  }

  // @WiringPlugVoltage
  createWiringPlugVoltage(data) {
    // Create WiringPlugVoltage type
    return API.ACCOUNTS.post(`/wiring-plug-voltage`, data);
  }
  getWiringPlugVoltage(id = null) {
    // Get single WiringPlugVoltage by Id or Get all WiringPlugVoltages
    if (id) {
      return API.ACCOUNTS.get(`/wiring-plug-voltage/${id}`);
    } else {
      return API.ACCOUNTS.get(`/wiring-plug-voltage`);
    }
  }
  getWiringPlugVoltageSearch(data) {
    return API.ACCOUNTS.post(`/wiring-plug-voltage/search/`, data);
  }
  getWiringPlugVoltageByUID(uid) {
    return API.ACCOUNTS.get(`/wiring-plug-voltage/find-by-uid/${uid}`);
  }
  getActiveWiringPlugVoltageByUID(uid) {
    return API.ACCOUNTS.get(`/wiring-plug-voltage/find-active-by-uid/${uid}`);
  }
  patchWiringPlugVoltage(data) {
    // Update single WiringPlugVoltage by Id
    return API.ACCOUNTS.patch(`/wiring-plug-voltage/${data.id}`, data);
  }
  deactivateWiringPlugVoltage(id) {
    // Update single WiringPlugVoltage by Id
    return API.ACCOUNTS.patch(`/wiring-plug-voltage/deactivate/${id}`);
  }
  deleteWiringPlugVoltage(id = null) {
    // Delete WiringPlugVoltage by Id
    return API.ACCOUNTS.delete(`/wiring-plug-voltage/${id}`);
  }

  // @BrakeFoundation
  createBrakeFoundation(data) {
    // Create BrakeFoundation type
    return API.ACCOUNTS.post(`/brake-foundation`, data);
  }
  getBrakeFoundation(id = null) {
    // Get single BrakeFoundation by Id or Get all BrakeFoundations
    if (id) {
      return API.ACCOUNTS.get(`/brake-foundation/${id}`);
    } else {
      return API.ACCOUNTS.get(`/brake-foundation`);
    }
  }
  getBrakeFoundationSearch(data) {
    return API.ACCOUNTS.post(`/brake-foundation/search/`, data);
  }
  getBrakeFoundationByUID(uid) {
    return API.ACCOUNTS.get(`/brake-foundation/find-by-uid/${uid}`);
  }
  getActiveBrakeFoundationByUID(uid) {
    return API.ACCOUNTS.get(`/brake-foundation/find-active-by-uid/${uid}`);
  }
  patchBrakeFoundation(data) {
    // Update single BrakeFoundation by Id
    return API.ACCOUNTS.patch(`/brake-foundation/${data.id}`, data);
  }
  deactivateBrakeFoundation(id) {
    // Update single BrakeFoundation by Id
    return API.ACCOUNTS.patch(`/brake-foundation/deactivate/${id}`);
  }
  deleteBrakeFoundation(id = null) {
    // Delete BrakeFoundation by Id
    return API.ACCOUNTS.delete(`/brake-foundation/${id}`);
  }

  // @BrakeType
  createBrakeType(data) {
    // Create BrakeType type
    return API.ACCOUNTS.post(`/brake-type`, data);
  }
  getBrakeType(id = null) {
    // Get single BrakeType by Id or Get all BrakeTypes
    if (id) {
      return API.ACCOUNTS.get(`/brake-type/${id}`);
    } else {
      return API.ACCOUNTS.get(`/brake-type`);
    }
  }
  getBrakeTypeSearch(data) {
    return API.ACCOUNTS.post(`/brake-type/search/`, data);
  }
  getBrakeTypeByUID(uid) {
    return API.ACCOUNTS.get(`/brake-type/find-by-uid/${uid}`);
  }
  getActiveBrakeTypeByUID(uid) {
    return API.ACCOUNTS.get(`/brake-type/find-active-by-uid/${uid}`);
  }
  patchBrakeType(data) {
    // Update single BrakeType by Id
    return API.ACCOUNTS.patch(`/brake-type/${data.id}`, data);
  }
  deactivateBrakeType(id) {
    // Update single BrakeType by Id
    return API.ACCOUNTS.patch(`/brake-type/deactivate/${id}`);
  }
  deleteBrakeType(id = null) {
    // Delete BrakeType by Id
    return API.ACCOUNTS.delete(`/brake-type/${id}`);
  }

  // @Suspension
  createSuspension(data) {
    // Create BrakeType type
    return API.ACCOUNTS.post(`/suspension`, data);
  }
  getSuspension(id = null) {
    // Get single BrakeType by Id or Get all BrakeTypes
    if (id) {
      return API.ACCOUNTS.get(`/suspension/${id}`);
    } else {
      return API.ACCOUNTS.get(`/suspension`);
    }
  }
  getSuspensionSearch(data) {
    return API.ACCOUNTS.post(`/suspension/search/`, data);
  }
  getSuspensionByUID(uid) {
    return API.ACCOUNTS.get(`/suspension/find-by-uid/${uid}`);
  }
  getActiveSuspensionByUID(uid) {
    return API.ACCOUNTS.get(`/suspension/find-active-by-uid/${uid}`);
  }
  patchSuspension(data) {
    // Update single Suspension by Id
    return API.ACCOUNTS.patch(`/suspension/${data.id}`, data);
  }
  deactivateSuspension(id) {
    // Deactivate single Suspension by Id
    return API.ACCOUNTS.patch(`/suspension/deactivate/${id}`);
  }
  deleteSuspension(id = null) {
    // Delete Suspension by Id
    return API.ACCOUNTS.delete(`/suspension/${id}`);
  }

  updateVehicleTypeMassConfigs(payload) {
    return API.ACCOUNTS.patch("/vehicle-type/mass/config", payload);
  }
})();
