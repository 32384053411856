
import VueCookies from 'vue-cookies';

export default {
    // @Account / Auth
    setTokens(state, value){
        if(value['accessToken']) {
            state.accessToken = value['accessToken']
            VueCookies.set('accessToken',state.accessToken,3000,null,null,true,"None");
        }
        if(value['refreshToken']){
            state.refreshToken = value['refreshToken']
            VueCookies.set('refreshToken',state.refreshToken,604800,null,null,true,"None");
        }
        if(value['firebaseLoginToken']) state.firebaseToken = value['firebaseLoginToken'];

    },
    setProxyTokens(state, value){
        if(value['accessToken']) {
            state.accessProxyToken = value['accessToken']
            VueCookies.set('accessProxyToken',state.accessProxyToken,3000,null,null,true,"None");
        }
        if(value['refreshToken']){
            state.refreshProxyToken = value['refreshToken']
            VueCookies.set('refreshProxyToken',state.refreshProxyToken,604800,null,null,true,"None");
        }
    },
    setMe(state, value){
        state.me = value;
        state.role = value['role'];
        if(value['role']) state.roleName = value['role']['name'];
        if(value['roles']) state.roleNames = value['roles'];
        if (state.mainRole === '') {
            state.mainRole = value['role']['name'];
            VueCookies.set('mainRole', state.mainRole,null,null,null,true,"None");
        }
    },
    setProxyUser(state, value){
        state.isProxy = value;
        VueCookies.set('proxyUser', value,null,null,null,true,"None");
    },
    setProxyRole(state, value){
        state.proxyRole = value;
        VueCookies.set('proxyRole', value,null,null,null,true,"None");
    },
    setProxySite(state, value){
        state.proxySite = value;
        VueCookies.set('proxySite', value,null,null,null,true,"None");
    },
    setProxyDriverProfileUid(state, value){
        state.proxyDriverProfileUid = value;
        VueCookies.set('proxyDriverProfileUid', value,null,null,null,true,"None");
    },
    // @Partner
    setNewCreatedPartner(state, value){
        state.newCreatedPartner = value
    },
    // @Business
    setNewCreatedBusiness(state, value){
        state.newCreatedBusiness = value
    },
    // @Driver
    setNewCreatedDriver(state, value){
        state.newCreatedDriver = value
    },
    //Solo Driver
    setNoContract(state, value) {
        state.soloNoContract = value
    },
    setDisabledSubscription(state, value) {
        state.disabledSubscription = value
    },
    // @Geotab Account
    setIsGeotabAccount(state, value){
        state.isGeotabAccount = value
    },

    setIsSideMenu(state, value) {
        VueCookies.set('isSideMenu', value,null,null,null,true,"None");
        state.isSideMenu = value;
    },

    setSSOConfiguration(state, value) {
        state.ssoConfiguration = value;
    }
}