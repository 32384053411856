<template>
  <div>
    <Table
      :isLoading="isLoading"
      :loadingText="loadingText"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="reportJobData"
      @search-table="queryForKeywords"
      @onPageChange="onPageChange($event)"
    >
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative" style="width: 150px">
          <StyledIconedLink
            class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
            @click.prevent="processJob(data.id)"
            :iconHoverColor="theme.secondaryColor"
          >
            <font-awesome-icon icon="play" class="my-auto mr-1" />
            Execute
          </StyledIconedLink>
        </td>
      </template>
    </Table>
  </div>
</template>
<script>
import { Table } from "@/components/commons/";
import { formatDate } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT
} from "@/_helper/constants";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";

export default {
  name: "Admin-ReportJobs",
  components: {
    Table,
    StyledIconedLink,
  },
  data() {
    return {
      isLoading: false,
      loadingText: null,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      reportJobs: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Job name",
        "Recipient",
        "Status",
        "Retries",
        "Date",
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      hasRole: true,
      filter: {
        limit: 50,
        status: "all",
        search: "",
      },
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init() {
      this.isLoading = true;

      const reportJobs = await this.$store.dispatch(`report/getAllReportJobs`);

      if (reportJobs) {
        this.reportJobs = this.formatItem(
          _.orderBy(reportJobs, ["createdAt"], ["desc"])
        );
      } else {
        this.reportJobs = [];
      }

      this.isLoading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async processJob(id) {
      this.isLoading = true;
      this.loadingText = 'Processing report, please wait.';

      try {
        await this.$store.dispatch(`report/processReportJob`, id);
        await this.init();

        this.toast('success', 'Report successfully processed and sent.');
      } catch (error) {
        this.toast('error', 'Failed to process report.');
      }

      this.isLoading = false;
      this.loadingText = null;
    },
    async toast(state, msg) {
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true)
      this.$store.commit('setMessageNotify', message)
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    async submitSuccess() {
      await this.init();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const recipientEmail = item?.data?.recipientEmail ? item?.data?.recipientEmail : item?.data?.createReportDto?.recipientEmail || '';
          const recipient = typeof recipientEmail == 'string' ? recipientEmail  : recipientEmail.join(', ');
          const reportJob = {
            id: item?._id,
            name: item?.jobName,
            recipient: recipient,
            status: item?.status,
            retries: item?.retries,
            created: formatDate(item?.createdAt),
          };
          nItems.push(reportJob);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseReportJob(data) {
      return data.map((reportJobData) => [
        {
          id: reportJobData.id,
          name: reportJobData.name,
          itemType: "string",
        },
        {
          id: reportJobData.id,
          name: reportJobData.recipient,
          itemType: "string",
        },
        {
          id: reportJobData.id,
          name: reportJobData.status,
          itemType: "string",
        },
        {
          id: reportJobData.id,
          name: reportJobData.retries,
          itemType: "string",
        },
        {
          id: reportJobData.id,
          name: reportJobData.created,
          itemType: "string",
        },
      ]);
    },
  },
  computed: {
    reportJobData() {
      if (this.reportJobs) {
        return this.parseReportJob(this.reportJobs);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  }
};
</script>
