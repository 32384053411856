export const NHVR_FATIGUE_PLAN = {
  BFM_SOLO: "bfm-solo",
  BFM_TWO_UP: "bfm-2up",
  STANDARD_SOLO: "standard-solo",
  STANDARD_TWO_UP: "standard-2up",
  STANDARD_BUS: "standard-bus",
  AFM: "afm",
};

export const WAHVA_FATIGUE_PLAN = {
  SOLO_14_DAY: "solo-14-day",
  SOLO_28_DAY: "solo-28-day",
  TWO_UP_48_HOURS: "2up-48-hours",
  TWO_UP_7_DAY: "2up-7-day",
};
