<template>
  <div class="flex min-h-screen justify-center items-center bg-black">
    <div class="container py-10 flex h-full flex-col w-128 px-4">
      <div class="text-center m-5">
        <a :href="$store.getters['baseURL']"
          ><img
            src="assets/images/logo.png"
            alt=""
            class="w-full m-auto"
            style="width: 50%"
        /></a>
      </div>
      <div class="rounded-lg bg-gray-50 md:p-10 px-5 py-10 shadow">
        <LoginForm
          v-if="activeForm === 'login'"
          @toggleForm="toggleForm"
        ></LoginForm>
        <ForgotPasswordEmail
          v-if="activeForm === 'forgetPassword'"
          @toggleForm="toggleForm"
        ></ForgotPasswordEmail>
        <SSOLogin
          v-if="activeForm === 'sso'"
          @toggleForm="toggleForm"
        ></SSOLogin>
      </div>
    </div>
  </div>
</template>

<script>
import { LoginForm, ForgotPasswordEmail, SSOLogin } from "@/components/forms";
export default {
  data() {
    return {
      activeForm: "login",
    };
  },
  components: {
    LoginForm,
    ForgotPasswordEmail,
    SSOLogin,
  },
  mounted() {
    if (this.$route.query.isSideMenu) {
      const isSideMenu = this.$route.query.isSideMenu == "0" ? false : true;
      this.$store.commit("account/setIsSideMenu", isSideMenu);
    }
  },
  methods: {
    toggleForm(form) {
      this.activeForm = form;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
