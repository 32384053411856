<template>
    <div class="mb-16">
        <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div v-if="!loading && driver">
            <div class="text-center mb-10">
                <div class="flex text-right justify-end"> 
                    <a @click.prevent="editNonEWDDriver()" class="flex cursor-pointer"><font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/></a>
                </div>
                <div class="font-bold text-black-primary text-2xl"> 
                    {{driver.name}}
                </div>
                <div class="font-bold text-xs" :class="[driver.isActive ? 'text-green-500' : 'text-red-500']">{{driver.isActive ? 'ACTIVE' : 'INACTIVE'}}</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.birthday}}</div>
                <div class="text-xs">Date Of Birth</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.license}}</div>
                <div class="text-xs">Licence</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.licenseState}}</div>
                <div class="text-xs">Licence State</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.driversLicenseExpiry}}</div>
                <div class="text-xs">License Expiry</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base uppercase">{{driver.baseFatiguePlan}}</div>
                <div class="text-xs">Base Fatigue Plan</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.baseTimezone}}</div>
                <div class="text-xs">Base Time Zone</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{(status == 'linked-non-ewd')? 'Linked' : 'Delinked'}}</div>
                <div class="text-xs">Status</div>
            </div>
            <div class="mb-5">
                <div class="font-bold text-black-primary text-base">{{driver.engineRegion}}</div>
                <div class="text-xs">Region</div>
            </div>
        </div>
    </div>
</template>
<script>
import {formatDate} from '@/_helper'
export default {
   name: 'Driver-Details-View',
   props: {
      detailsId:{
         type: String,
         default: '',
      }
   },
   data(){
      return{
        driver: {},
        profile: {},
        status: '',
        businessid: '',
        driverid: '',
        licenseExpiry:'',
        phoneNumber:'',
        loading : false
      }
   },
   async mounted(){
      this.loading = true
    const driverDetails = await this.$store.dispatch(`business/getContractById`, this.detailsId)
    const soloDriverDetails = await this.$store.dispatch(`driver/getDriverByID`, driverDetails?.driverId);
      this.driver = this.parseDriver(soloDriverDetails)
      this.driverid = driverDetails?.driverId
      this.status = driverDetails.status || "-"
      this.loading = false
   },
   methods: {
      parseDriver(driver){
         return {
            baseLocation: driver.driversBaseLocation || "-",
            baseTimezone: driver.driversBaseTimeZone || "-",
            baseFatiguePlan: driver.driversBaseFatiguePlan.replace(/-/g, ' ') || "-",
            birthday: formatDate(driver.driverDateOfBirth, 'DD/MM/YYYY') || "-",
            bfmNumber: driver.driversBFMNumber || "-",
            driverId: driver._id || "-",
            email: driver.emailAddress || "-",
            isActive: driver.isActive || "-",
            license: driver.driversLicenseNumber || "-",
            licenseState: driver.driversLicenseState || "-",
            driversLicenseExpiry: formatDate(driver.driversLicenseExpiry, 'DD/MM/YYYY') || "-",
            name: driver.driverName || "-",
            phone: driver.supportPhone || "-",
            engineRegion: driver.engineRegion || "-"
         }
      },
      editNonEWDDriver(){
         this.$emit('editNonEWDDriver', this.driverid) 
      },
   }
}
</script>