<template>
  <div class="page-container px-2 pt-3">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-400">Loading data...</span>
      </div>
    </div>
    <div v-else>
      <label class="block mb-2 text-md font-medium text-gray-900"
        >Select the drivers to import</label
      >
      <div class="overflow-x-auto relative shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th
                scope="col"
                class="p-4"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                <div class="flex items-center">
                  <input
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                    @change="selectAll()"
                  />
                  <label class="sr-only">checkbox</label>
                </div>
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Name
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Licence
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                State
              </th>
              <th
                class="px-6 py-3 border-gray-200 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.primaryColor }"
              >
                Timezone
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(driver, index) in drivers"
              :key="index"
              :data-import-id="index"
              :ref="getRowRef(index)"
              :class="{ 'hover:bg-red-50': driver.hasFieldError }"
              class="bg-white hover:bg-gray-50 relative"
            >
              <td class="p-4 w-4">
                <div
                  v-if="driver.hasFieldError"
                  class="absolute top-0 left-0 right-0 bottom-0 bg-red w-full border-b border-white"
                >
                  <div
                    class="relative w-full h-full flex items-center justify-center"
                  >
                    <div class="absolute inset-0 bg-red-100 z-0 flex px-5">
                      <div class="flex items-center justify-center">
                        <font-awesome-icon
                          icon="exclamation-triangle"
                          class="text-xl m-auto text-red-700"
                        />
                      </div>
                      <div class="flex-1 flex flex-col justify-center pl-3">
                        <div
                          v-if="driver.hasFieldError"
                          class="font-medium text-red-700 py-1"
                        >
                          <span
                            v-if="
                              driver.missingFields.length &&
                              driver.invalidFields.length
                            "
                            >Driver data has missing and invalid fields.</span
                          >
                          <span v-else-if="driver.missingFields.length"
                            >Driver data has missing fields.</span
                          >
                          <span v-else-if="driver.invalidFields.length"
                            >Driver data has invalid fields.</span
                          >
                        </div>
                      </div>
                      <div
                        class="flex items-center justify-center capitalize text-sm font-medium text-right whitespace-nowrap"
                      >
                        <a
                          @click.prevent="editDriver(index, driver)"
                          class="text-grey-400 hover:underline cursor-pointer"
                          >Edit</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center" v-if="!driver.hasFieldError">
                  <input
                    v-model="driver.checked"
                    type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2"
                  />
                  <label class="sr-only">checkbox</label>
                </div>
              </td>
              <td
                scope="row"
                class="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap"
              >
                <div class="flex-shrink-0 w-10 h-10">
                  <div
                    class="w-full h-full rounded-full overflow-hidden flex bg-gray-200"
                  >
                    <font-awesome-icon
                      icon="user"
                      class="text-xl m-auto text-gray-600"
                    />
                  </div>
                </div>
                <div class="pl-3">
                  <div class="text-sm font-semibold">
                    {{ driver.driverName }}
                  </div>
                  <div
                    v-if="!isNonEwdImport"
                    class="font-normal text-gray-500 text-xs"
                  >
                    {{ driver.emailAddress }}
                  </div>
                </div>
              </td>
              <td class="py-4 px-6">{{ driver.driversLicenseNumber }}</td>
              <td class="py-4 px-6">{{ driver.driversLicenseState }}</td>
              <td class="py-4 px-6">{{ driver.driversBaseTimeZone }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <FormulateInput
        type="submit"
        label="Next"
        input-class="btn btn-primary w-full mt-5"
        :disabled="loading"
        @click="nextPage()"
      />
    </div>
    <div class="hidden" id="default-edit-driver-template">
      <tr id="edit-driver-template" class="border-b border-grey-400">
        <td colspan="5">
          <div class="w-full px-5 py-3">
            <FormulateForm v-model="values" @submit="onEditSave">
              <FormulateInput
                v-if="!isNonEwdImport"
                type="email"
                name="emailAddress"
                label="Email Address"
                validation="required|email"
              />
              <div class="grid grid-cols-2 gap-4">
                <FormulateInput
                  type="text"
                  name="driverName"
                  label="Full Name"
                  validation="required"
                />
                <FormulateInput
                  type="date"
                  format="dd-MM-yyyy"
                  name="driverDateOfBirth"
                  label="Date Of Birth"
                  validation="required"
                />
              </div>
              <div class="grid grid-cols-2 gap-4">
                <FormulateInput
                  type="text"
                  name="driversLicenseNumber"
                  label="Licence Number"
                  validation="required"
                />
                <FormulateInput
                  type="date"
                  name="driversLicenseExpiry"
                  label="Licence Expiry"
                  validation="required"
                />
              </div>
              <FormulateInput
                type="select"
                name="driversLicenseState"
                label="Licence State"
                :options="states"
                validation="required|validateState"
                :validation-rules="{
                  validateState: ({ value }) =>
                    states.map((x) => x.value).includes(value),
                }"
                :validation-messages="{
                  validateState: 'The entered state is not valid.',
                }"
              />
              <FormulateInput
                v-if="!isNonEwdImport"
                type="places-search-input"
                name="driverRecordKeeperAddress"
                label="Record Keeper Address"
                validation="required"
              />
              <FormulateInput
                v-if="!isNonEwdImport"
                type="places-search-input"
                name="driversBaseLocation"
                label="Base Location Address"
                validation="required"
              />
              <div class="grid grid-cols-2 gap-4">
                <FormulateInput
                  type="select"
                  name="driversBaseTimeZone"
                  label="Base Time Zone"
                  :options="timezones"
                  validation="required|validateTimezone"
                  :validation-rules="{
                    validateTimezone: ({ value }) =>
                      timezones.map((x) => x.value).includes(value),
                  }"
                  :validation-messages="{
                    validateTimezone: 'The entered timezone is not valid.',
                  }"
                />
                <FormulateInput
                  v-if="isNonEwdImport"
                  type="select"
                  name="driversBaseFatiguePlan"
                  label="Base Fatigue Plan"
                  :options="fatiguePlans"
                  validation="required"
                />
                <FormulateInput
                  v-if="!isNonEwdImport"
                  type="text"
                  name="driversBFMNumber"
                  label="BFM Number"
                />
              </div>
              <FormulateInput
                type="select"
                name="region"
                label="Region"
                :options="regions"
                validation="required"
              />

              <div class="grid grid-cols-2 gap-4">
                <FormulateInput
                  type="button"
                  label="Close"
                  input-class="btn btn-cancel w-full"
                  @click="closeEdit()"
                />
                <FormulateInput
                  type="submit"
                  label="Save"
                  input-class="btn btn-primary w-full"
                  :disabled="loading"
                  :style="{ backgroundColor: theme.secondaryColor }"
                />
              </div>
            </FormulateForm>
          </div>
        </td>
      </tr>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { validateEmail } from '@/_helper';
import { STATES, TIMEZONES } from '@/_helper/constants';
import { NHVR_FATIGUE_PLAN } from '@/constants/fatigue-plan';
import { REGION } from '@/constants/region';
import Spinner from '@/components/commons/ui/Spinner';

export default {
  name: 'Select-Driver',
  components: { Spinner },
  props: {
    preData: null,
    isNonEwdImport: Boolean,
  },
  mounted() {
    this.fatiguePlans = Object.entries(NHVR_FATIGUE_PLAN).map((fatigue) => {
      return { label: fatigue[0].replace(/_/g, ' '), value: fatigue[1] };
    });

    if (!isEmpty(this.preData)) {
      this.drivers = this.preData.drivers;
      this.validateFields();

      this.drivers.forEach((driver) => {
        if (!isEmpty(driver.driversBaseTimeZone))
          driver.driversBaseTimeZone = driver.driversBaseTimeZone.toUpperCase();
        if (driver.hasFieldError) {
          if (!isEmpty(driver.driverDateOfBirth))
            driver.driverDateOfBirth = this.formatDate(
              driver.driverDateOfBirth
            );
          if (!isEmpty(driver.driversLicenseExpiry))
            driver.driversLicenseExpiry = this.formatDate(
              driver.driversLicenseExpiry
            );
        }
      });
    }
  },
  data() {
    return {
      states: STATES,
      timezones: TIMEZONES,
      regions: REGION,
      fatiguePlans: [],
      loading: false,
      drivers: [],
      values: {},
      isSelectAll: false,
      selectedIndex: null,
    };
  },
  methods: {
    formatDate(dateToFormat) {
      let momentDate = moment(dateToFormat);
      if (!momentDate.isValid())
        momentDate = moment(dateToFormat, 'DD/MM/YYYY');

      return momentDate.format('YYYY-MM-DD');
    },
    getRowRef(index) {
      return 'ref' + index;
    },
    validateFields() {
      this.drivers.forEach((driver) => {
        driver.invalidFields = [];
        driver.missingFields = [];
        driver.hasFieldError = false;
        Object.entries(driver).forEach(([key, value]) => {
          if (
            [
              'driversBFMNumber',
              'checked',
              'invalidFields',
              'missingFields',
              'hasFieldError',
            ].includes(key)
          )
            return;
          if (
            key == 'emailAddress' &&
            !isEmpty(value) &&
            !validateEmail(value) &&
            !this.isNonEwdImport
          )
            driver.invalidFields.push(key);
          if (
            key == 'driversLicenseState' &&
            !isEmpty(value) &&
            !this.states.map((x) => x.value).includes(value)
          )
            driver.invalidFields.push(key);
          if (
            key == 'driversBaseTimeZone' &&
            !isEmpty(value) &&
            !this.timezones.map((x) => x.value).includes(value)
          )
            driver.invalidFields.push(key);
          if (
            key == 'driversBaseFatiguePlan' &&
            !isEmpty(value) &&
            !this.fatiguePlans
              .map((x) => x.label.toLowerCase())
              .includes(value.toLowerCase()) &&
            this.isNonEwdImport
          )
            driver.invalidFields.push(key);
          if (
            key == 'region' &&
            !isEmpty(value) &&
            !this.regions
              .map((x) => x.value.toLowerCase())
              .includes(value.toLowerCase()) &&
            this.isNonEwdImport
          )
            driver.invalidFields.push(key);
          if (isEmpty(value)) driver.missingFields.push(key);
        });

        driver.hasFieldError =
          driver.invalidFields.length > 0 || driver.missingFields.length > 0;
      });
    },
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.drivers.forEach((driver) => {
        if (!driver.hasFieldError)
          driver.checked = this.isSelectAll ? true : false;
      });
    },
    nextPage() {
      const selectedDrivers = this.drivers.filter((driver) => driver.checked);
      this.$emit('nextStep', { drivers: this.drivers, selectedDrivers });
    },
    editDriver(index, driver) {
      this.values = driver;
      this.selectedIndex = index;
      $($('#edit-driver-template').detach()).insertAfter(
        `tr[data-import-id="${index}"]`
      );
      this.$nextTick(() =>
        this.$refs[this.getRowRef(index)][0].scrollIntoView({
          scrollBehavior: 'smooth',
        })
      );
    },
    closeEdit() {
      this.values = {};
      this.selectedIndex = null;
      this.validateFields();
      $($('#edit-driver-template').detach()).appendTo(
        `#default-edit-driver-template`
      );
    },
    onEditSave() {
      this.drivers[this.selectedIndex] = this.values;
      this.closeEdit();
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
