<template>
  <FormulateForm @submit="onNext">
    <div class="loading mt-32 h-32" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading" class="text-center text-2xl">Buy Product Pricing</div>
    <div class="my-5 overflow-x-auto">
      <div class="inline-block min-w-full rounded-lg overflow-hidden">
        <table v-if="!loading" class="table-auto leading-normal p-4">
          <thead>
            <tr>
              <th class="bg-gray-100 w-2/5 text-left">Product</th>
              <th class="bg-gray-200">Monthly</th>
              <th class="bg-gray-200">Yearly</th>
            </tr>
          </thead>
          <tbody v-if="values">
            <tr
              v-for="(product, index) in values"
              :key="index"
              class="border-t-2"
            >
              <td class="w-80 pl-5 pr-9 text-sm bg-gray-100">
                {{ product.name === "EWD-FT" ? "EWD" : product.name }}
              </td>
              <td class="pt-3 px-5 text-center">
                <div class="flex">
                  <span class="pt-1 pl-4 w-0">$</span>
                  <FormulateInput
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0"
                    class="p-0 w-36"
                    input-class="w-36 h-7 ml-3 text-right py-4 px-0 border outline-none"
                    error-behavior="live"
                    :value="parsePrice(product.pricing.monthly)"
                    :name="`${removeSpaces(product.name)}.pricing.monthly`"
                    autocomplete="off"
                  />
                </div>
              </td>
              <td class="pt-3 px-6 text-center">
                <div class="flex relative pt-2 pb-3">
                  <span class="pt-2 pl-4 w-0">$</span>
                  <FormulateInput
                    type="number"
                    step="0.01"
                    min="0"
                    placeholder="0"
                    class="p-0 w-36"
                    input-class="w-36 h-7 ml-3 text-right py-4 px-0 border outline-none"
                    error-behavior="live"
                    :value="parsePrice(product.pricing.yearly)"
                    :name="`${removeSpaces(product.name)}.pricing.yearly`"
                    v-on:input="yearlyPerMonthInput($event, index, false)"
                    autocomplete="off"
                  />
                  <small
                    ref="yearlyPerMonthRef"
                    class="absolute mt-9 ml-4 opacity-90 w-full text-xs"
                  >
                    ({{ yearlyPerMonthDesc[index] || `$0.00 Per Month` }})
                  </small>
                </div>
              </td>
              <FormulateInput
                type="hidden"
                :value="parsePrice(product.rrp.monthly)"
                :name="`${removeSpaces(product.name)}.rrp.monthly`"
              />
              <FormulateInput
                type="hidden"
                :value="parsePrice(product.rrp.yearly)"
                :name="`${removeSpaces(product.name)}.rrp.yearly`"
              />
            </tr>
          </tbody>
        </table>
        <hr />
        <br />
      </div>
    </div>
    <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
      <button
        v-if="!loading"
        class="btn col-span-2 btn-primary mt-2"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        Next
      </button>
    </div>
  </FormulateForm>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Edit-Partner-Pricing",
  props: {
    detailsId: {
      type: String,
      default: null,
    },
    preData: null,
    postData: null,
  },
  data() {
    return {
      loading: false,
      values: {},
      standardPricingMasterId: null,
      yearlyPerMonthDesc: [],
      interChangingData: [],
    };
  },
  async mounted() {
    this.loading = true;
    let masterPricing;

    this.interChangingData = this.preData ? this.preData : this.postData;

    if (!_.isEmpty(this.interChangingData?.pricing)) {
      masterPricing = this.interChangingData.pricing;
    } else {
      const standardPricing = await this.$store.dispatch(
        `standardpricing/getStandardPricingByMongoId`,
        this.detailsId
      );
      masterPricing = standardPricing.filter(
        (standard) => standard.isRrp === false
      );
      const rrPPricing = standardPricing.filter(
        (standard) => standard.isRrp === true
      );

      masterPricing.map((master) => {
        rrPPricing.map((rrp) => {
          if (master.name === rrp.name) {
            master.rrp = rrp.pricing;
          }
        });
      });
    }

    if (masterPricing) {
      this.values = this.parseModules(
        _.orderBy(masterPricing, ["_id"], ["asc"])
      );
    }

    this.loading = false;
  },
  methods: {
    parsePrice(price) {
      return parseFloat(price).toFixed(2);
    },

    parseModules(arr) {
      return arr.filter(
        (item) => item.name !== "EWD-PT" && item.name !== "Pre-Paid EWD"
      );
    },

    yearlyPerMonthInput(input, index, init) {
      let price = this.parsePrice(input / 12);
      if (init) {
        return this.yearlyPerMonthDesc.push(`$${price || 0.0} Per Month`);
      } else {
        this.$parent.$children[0].$refs.yearlyPerMonthRef[
          index
        ].innerHTML = `$${price || 0.0} Per Month`;
      }
    },

    removeSpaces(string) {
      return string.replace(/\s+/g, "");
    },

    onNext(formData) {
      let setValues = [];
      this.values.forEach((obj) => {
        if (obj) {
          let data = Object(formData);

          const pricing = {
            monthly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.pricing.monthly`]
            ),
            yearly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.pricing.yearly`]
            ),
          };

          const rrp = {
            monthly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.rrp.monthly`]
            ),
            yearly: this.parsePrice(
              data[`${this.removeSpaces(obj.name)}.rrp.yearly`]
            ),
          };

          const dto = {
            name: obj.name,
            pricing: pricing,
            rrp: rrp,
          };

          setValues.push(dto);
        }
      });

      const data = { ...this.interChangingData, ...{ pricing: setValues } };
      this.$emit("nextStep", data);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
th {
  @apply px-5 py-3 border-gray-700 text-sm font-semibold text-gray-600 uppercase;
}
</style>
