<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <FormulateForm v-model="values" @submit="onSubmit">
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driverName"
            label="Full Name"
            validation="required"
            :disabled="hasPreData"
          />
          <FormulateInput
            type="date"
            name="driverDateOfBirth"
            label="Date Of Birth"
            validation="required"
            :disabled="hasPreData"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driversLicenseNumber"
            label="Licence Number"
            validation="required"
            :disabled="hasPreData"
          />
          <FormulateInput
            type="date"
            name="driversLicenseExpiry"
            label="Licence Expiry"
            validation="required"
            :disabled="hasPreData"
          />
        </div>

        <FormulateInput
          type="select"
          name="driversLicenseState"
          label="Licence State"
          :options="states"
          validation="required"
          :disabled="hasPreData"
        />

        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="select"
            name="driversBaseFatiguePlan"
            label="Base Fatigue Plan"
            :options="fatiguePlans"
            :disabled="hasPreData"
            validation="required"
          />
          <FormulateInput
            type="select"
            name="driversBaseTimeZone"
            label="Base Time Zone"
            :options="timezones"
            :disabled="hasPreData"
            validation="required"
          />
        </div>

        <FormulateInput
          type="email"
          name="emailAddress"
          label="Email Address"
          validation="required|email"
          :disabled="hasPreData"
        />

        <div class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Next"
            input-class="btn btn-primary w-full"
            :disabled="loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import { STATES } from '@/constants/location';
import { TIMEZONE } from '@/constants/location';
import { NHVR_FATIGUE_PLAN } from '@/constants/fatigue-plan';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import { formatDate } from '@/_helper';

export default {
  name: 'Add-Driver-Form',
  props: {
    msg: String,
    preData: null,
    detailsId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: '',
      states: STATES,
      timezones: TIMEZONE,
      fatiguePlans: [],
      hasPreData: false,
    };
  },
  async mounted() {
    this.loading = true;
    this.fatiguePlans = Object.entries(NHVR_FATIGUE_PLAN).map((fatigue) => {
      return { label: fatigue[0].replace(/_/g, ' '), value: fatigue[1] };
    });

    if (!_.isEmpty(this.preData)) {
      this.hasPreData = true;
    } else {
      const driver = await this.$store.dispatch(
        `driver/getDriverByID`,
        this.detailsId
      );
      if (!_.isEmpty(driver)) {
        this.values = this.parseDriver(driver);
      }
    }
    this.loading = false;
  },
  methods: {
    parseDriver(driver) {
      return {
        driversBaseLocation: driver.driversBaseLocation,
        driverDateOfBirth: formatDate(driver.driverDateOfBirth, 'YYYY-MM-DD'),
        bfmNumber: driver.driversBFMNumber,
        driversLicenseNumber: driver.driversLicenseNumber,
        driversLicenseState: driver.driversLicenseState,
        driversBaseFatiguePlan: driver.driversBaseFatiguePlan,
        driversBaseTimeZone: driver.driversBaseTimeZone,
        driversLicenseExpiry: formatDate(
          driver.driversLicenseExpiry,
          'YYYY-MM-DD'
        ),
        driverName: driver.driverName,
        driverId: driver._id,
      };
    },
    async onSubmit() {
      if (!_.isEmpty(this.preData)) {
        this.$emit('nextStep', { linkDriver: this.preData });
      } else {
        this.loading = true;
        this.values.emailAddress = this.values.emailAddress.toLowerCase();
        let newDriver = JSON.parse(JSON.stringify(this.values));
        if (newDriver) {
          this.$emit('nextStep', { newDriver: newDriver });
        }
      }
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
