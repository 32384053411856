<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="soloContractData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-full appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template slot="callToActionTop">
        <div class="flex space-x-4">
          <FormulateInput
            type="select"
            placeholder="Select Entity"
            class="w-48"
            name="entityType"
            :options="['Partner', 'Reseller']"
            validation="required"
            @change="onSelectEntity($event)"
            v-model="parentEntityType"
          />
          <FormulateInput
            v-if="parentEntityType != null && parentEntityType === 'Partner'"
            type="solo-partner-select"
            :placeholder="`Select ${parentEntityType}`"
            style="margin-bottom: 0px"
            class="w-48"
            :getOptionKey="(option) => option.persona._id"
            :getOptionLabel="(option) => option.persona.businessName"
            :filterable="false"
            :clearable="false"
            validation="required"
            @input="onChange($event)"
          />
          <FormulateInput
            v-if="parentEntityType != null && parentEntityType === 'Reseller'"
            type="solo-reseller-select"
            :placeholder="`Select ${parentEntityType}`"
            style="margin-bottom: 0px"
            class="w-48"
            :getOptionKey="(option) => option.persona._id"
            :getOptionLabel="(option) => option.persona.businessName"
            :filterable="false"
            :clearable="false"
            validation="required"
            @input="onChange($event)"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="transferSolo(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="exchange-alt" class="my-auto mr-2" />
                  Transfer
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="updateLastPaymentDate(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="addCredit(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="coins" class="my-auto mr-2" /> Add
                  Credit
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="cancelSubscription(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="credit-card" class="my-auto mr-2" />
                  Cancel
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deactivateContract(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="times-circle" class="my-auto mr-2" />
                  Deactivate
                </StyledIconedLink>
              </li>
              <li><DrillDown :uid="data.uid" role="driver" /></li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'updatePartner'">
        <UpdateSoloParentForm
          :activeSolo="activeSolo"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'updateLastPaymentDate'">
        <UpdateLastPaymentDateForm
          :activeSolo="activeSolo"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'cancelSubscription'">
        <ManageSubscription :activeSolo="activeSolo" @closeModal="closeModal" />
      </div>
      <div v-if="modalContent == 'add-credit'">
        <AddContractCreditForm
          :entity-id="modalId"
          entity-role="driver"
          @closeModal="closeModal"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table, Dropdown } from "@/components/commons";
import UpdateSoloParentForm from "@/components/forms/driver/update-solo-parent-form";
import ManageSubscription from "@/components/forms/payment/manage-subscription";
import UpdateLastPaymentDateForm from "@/components/forms/driver/update-last-payment-date-form";
import AddContractCreditForm from "@/components/forms/contract/add-contract-credit-form.vue";
import _ from "lodash";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "../../_helper";
import DrillDown from "@/components/modules/DrillDown.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Table,
    Modal,
    Dropdown,
    UpdateSoloParentForm,
    UpdateLastPaymentDateForm,
    ManageSubscription,
    DrillDown,
    AddContractCreditForm,
  },
  data() {
    return {
      me: {},
      selectedPartner: null,
      partners: [],
      partnerId: null,
      isLoading: false,
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",
      modalTitle: "",
      modalId: "",
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
      },
      tableHeader: [
        "Full Name",
        "Email Address",
        "Contract Duration",
        "Billing Period",
        "Last Payment Date",
        "Status",
      ],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      soloContracts: [],
      rawData: [],
      error: null,
      activeSolo: {},
      parentEntityType: null,
    };
  },

  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },

  methods: {
    async closeModal() {
      await this.getSoloContracts();
      this.$refs.modal.closeModal();
    },
    async init() {},
    async deactivateContract(contractId) {
      if (confirm("Are you sure you want to deactivate this contract?")) {
        await this.$store
          .dispatch("contract/deactivateContract", contractId)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);
            setTimeout(() => {
              location.reload();
            }, 2500);
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },

    async onChange(partner) {
      this.selectedPartner = partner;
      await this.getSoloContracts();
    },

    async transferSolo(id) {
      this.activeSolo = this.rawData.filter((item) => item._id === id)[0];

      this.modalContent = "updatePartner";
      this.modalTitle = "Move Solo to Another Parent Entity";
      this.modalId = id;
      this.modalHeight = "80vh";
      this.$refs.modal.openModal();
    },

    async updateLastPaymentDate(id) {
      this.activeSolo = this.rawData.filter((item) => item._id === id)[0];

      this.modalContent = "updateLastPaymentDate";
      this.modalTitle = "Change the last payment date";
      this.$refs.modal.openModal();
      this.modalId = id;
      this.modalHeight = "60vh";
    },

    async cancelSubscription(id) {
      this.activeSolo = this.rawData.filter((item) => item._id === id)[0];

      this.modalContent = "cancelSubscription";
      this.modalTitle = "Cancel Subscription";
      this.$refs.modal.openModal();
      this.modalId = id;
      this.modalHeight = "60vh";
    },
    addCredit(id) {
      this.modalContent = "add-credit";
      this.modalTitle = "Add Credit";
      this.$refs.modal.openModal();
      this.activeSolo = this.rawData.filter((item) => item._id === id)[0];
      this.modalId = this.activeSolo.businessMongoId;
      this.modalHeight = DEFAULT_MODAL_HEIGHT;
    },

    async getSoloContracts(paged = 1) {
      this.isLoading = true;

      const entityID = this.selectedPartner?._id;

      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };
      if (this.parentEntityType == "Partner") {
        await this.$store
          .dispatch(`contract/getPartnerDriverContract`, {
            partnerId: entityID,
            query,
          })
          .then(async (response) => {
            if (response) {
              if (response?.metadata) {
                this.paginationSettings = formatPaginationSettings(
                  response?.metadata
                );
              } else {
                this.paginationSettings = formatPaginationSettings();
              }

              this.rawData = response.results;

              const soloTableData = this.formatItem(
                _.orderBy(response.results, ["createdAt"], ["desc"])
              );
              this.soloContracts = soloTableData;
            } else {
              this.soloContracts = [];
            }
          })
          .catch(async (error) => {
            this.soloContracts = [];
            this.error = error;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else if (this.parentEntityType == "Reseller") {
        await this.$store
          .dispatch(`contract/getResellerDriverContract`, {
            resellerId: entityID,
            query,
          })
          .then(async (response) => {
            if (response) {
              if (response?.metadata) {
                this.paginationSettings = formatPaginationSettings(
                  response?.metadata
                );
              } else {
                this.paginationSettings = formatPaginationSettings();
              }

              this.rawData = response.results;

              const soloTableData = this.formatItem(
                _.orderBy(response.results, ["createdAt"], ["desc"])
              );
              this.soloContracts = soloTableData;
            } else {
              this.soloContracts = [];
            }
          })
          .catch(async (error) => {
            this.soloContracts = [];
            this.error = error;
          })
          .finally(() => {
            this.isLoading = false;
          });
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
    },

    onSelectEntity(event) {
      const entityType = event.target.value;

      if (entityType == "Business") {
        //fetch business contract
      } else if (entityType == "Driver") {
        //fetch solo contract
      }
    },

    async onPageChange(event) {
      if (event.page) {
        await this.getSoloContracts(event.page);
      }
    },

    async filterChange() {
      await this.getSoloContracts();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.getSoloContracts();
    },

    formatItem: function (items) {
      let nItems = [];
      if (items) {
        let status;
        items.forEach((item) => {
          switch (item?.status?.name) {
            case "Accepted":
              status = "Active";
              break;
            case "Suspended":
              status = "Suspended";
              break;
            case "Cancelled":
              status = "Cancelled";
              break;
            case "Saved":
              status = "Active";
              break;
            case "Business_Linked":
              status = "Business Linked";
              break;
            default:
              status = "Pending";
          }
          const solo = {
            id: item?._id,
            name: item?.businessMetaData?.driverName,
            email: item?.businessMetaData?.emailAddress,
            contractDuration: item?.contractDuration?.name,
            billingPeriod: item?.billingPeriod?.name,
            lastPaymentDate: formatDate(item?.lastPaymentDate),
            status: status,
            uid: item?.businessMetaData?.uid,
          };
          nItems.push(solo);
        });
      }
      return nItems;
    },
    parseDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData.id,
          name: driverData.name,
          itemType: "name",
          hasImage: true,
          status: driverData.status,
          uid: driverData.uid,
        },
        { id: driverData.id, name: driverData.email, itemType: "string" },
        {
          id: driverData.id,
          name: driverData.contractDuration,
          itemType: "string",
        },
        {
          id: driverData.id,
          name: driverData.billingPeriod,
          itemType: "string",
        },
        {
          id: driverData.id,
          name: driverData.lastPaymentDate,
          itemType: "string",
        },
        { id: driverData.id, name: driverData.status, itemType: "string" },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
  },

  computed: {
    soloContractData() {
      if (this.soloContracts) {
        return this.parseDrivers(this.soloContracts);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
<style scoped lang="scss">
.active {
  @apply border-orange-primary;
}
.vertical {
  min-width: 7px;
  line-height: 7px;
  writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  text-align: center;
  transform: rotate(180deg);
}
.compliance-container {
  min-height: calc(100vh - 92px);
}
</style>
