<template>
  <div ref="dropdownRef">
    <button
      class="inline-flex items-center p-2 text-sm font-medium text-center text-gray-900 bg-gray-200 rounded btn-action-dropdown"
      @click.prevent="toggleDropdown($event)"
      type="button"
    >
      <svg
        class="w-5 h-5"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z"
        ></path>
      </svg>
    </button>
    <div
      class="absolute z-10 text-left bg-white divide-y divide-gray-100 rounded action-dropdown w-44"
      @click.prevent="toggleDropdown($event)"
      @blur="onDropdownBlur"
      tabindex="-1"
    >
      <div class="px-4 py-3 text-sm font-medium text-gray-900 border-b">
        <div>Actions</div>
      </div>
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">
.action-dropdown {
  @apply overflow-hidden;
  right: 30px;
  transform: scaleY(0);
  transition: transform 0.26s ease;

  &.drop-down {
    top: 50px;
    transform-origin: top;

    &:before,
    &:after {
      top: -6px;
      transform: rotate(45deg);
    }
  }
  &.drop-up {
    bottom: 50px;
    transform-origin: bottom;

    &:before,
    &:after {
      bottom: -6px;
      transform: rotate(-135deg);
    }
  }

  &:before,
  &:after {
    content: "";
    background: inherit;
    position: absolute;
    height: 10px;
    width: 10px;
    right: 15px;
    border-top-width: 1px;
    border-left-width: 1px;
    border-style: solid;
    @apply border-gray-300 z-20;
  }

  &.active {
    transform: scaleY(1);
    @apply border border-gray-300 shadow overflow-visible;
  }
}
</style>

<script>
import $ from "jquery";

export default {
  name: "Table-Dropdown",
  data() {
    return {
      tableParentHeight: 0,
    };
  },
  mounted() {
    setTimeout(() => this.updateTableHeight(), 500);
  },
  methods: {
    updateTableHeight() {
      this.tableParentHeight =
        $(this.$refs.dropdownRef).closest("table").parent().parent().height() -
        50; // subtract 50 to adjust for horizontal scrollbar
    },
    onDropdownBlur(evt) {
      evt.currentTarget.classList.remove("active");
    },
    toggleDropdown(evt) {
      let elDropDown = evt.currentTarget.parentElement.lastChild;
      const dropDownPosY = evt.currentTarget.closest("tr").offsetTop;
      const dropDownHeight = elDropDown.clientHeight;
      let dropClass =
        dropDownHeight + dropDownPosY > this.tableParentHeight
          ? "drop-up"
          : "drop-down";
      if (dropDownHeight > this.tableParentHeight) dropClass = "drop-down";

      if (Object.values(elDropDown.classList).includes("active")) {
        elDropDown.classList.remove("active");
      } else {
        elDropDown.focus();
        elDropDown.classList.add("active", dropClass);
      }
    },
  },
};
</script>
