<template>
  <div class="bg-black">
  <div class="flex min-h-screen justify-center items-center bg-black">
    <div class="container py-10 flex h-full flex-col lg:w-1/2 md:w-2/3 w-full px-4">
      <div class="text-center m-5">
          <a :href="$store.getters['baseURL']"
            ><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"
          /></a>
        </div>
        <div class="">
          <div
            v-if="!loading"
            class="rounded-lg bg-gray-50 p-8 shadow"
          >
            <div class="text-center mb-5">
              <h3
                class="text-lg leading-6 font-black text-black uppercase"
                id="modal-title"
              >
                {{ entity.persona.tradingName }} New Business
              </h3>
            </div>
            <div
              v-if="windowWidth > mobileViewMaxWidth"
              class="text-center grid grid-cols-3 gap-2"
            >
              <div>
                <div class="text-xs font-bold">ABN</div>
                <div class="text-xs mb-5">{{ entity.persona.abn }}</div>
              </div>
              <div>
                <div class="text-xs font-bold">Contact Number</div>
                <div class="text-xs mb-5">
                  {{ entity.persona.contactPhoneNumber }}
                </div>
              </div>
              <div>
                <div class="text-xs font-bold">Support Email</div>
                <div class="text-xs mb-5">{{ entity.supportEmail }}</div>
              </div>
            </div>
            <div v-else class="text-center">
              <div class="text-xs font-bold">ABN</div>
              <div class="text-xs mb-5">{{ entity.persona.abn }}</div>
              <div class="text-xs font-bold">Contact Number</div>
              <div class="text-xs mb-5">
                {{ entity.persona.contactPhoneNumber }}
              </div>
              <div class="text-xs font-bold">Support Email</div>
              <div class="text-xs mb-5">{{ entity.supportEmail }}</div>
            </div>

            <NewBusinessForm
              :windowWidth="windowWidth"
              :mobileViewMaxWidth="mobileViewMaxWidth"
              :entity="entity"
              @newBusinessAdded="newBusinessAdded"
            />
          </div>
          <div
            v-else
            class="rounded-lg bg-gray-50 p-10 shadow flex flex-col items-center h-full mx-auto"
          >
            <ui-spinner class="mx-auto"></ui-spinner>
            Loading data...
          </div>
        </div>
        <!-- <div v-else>
          <div v-if="!loading" class="rounded-lg bg-gray-50 p-10 shadow">
            <div class="text-center mb-5">
              <h1 class="text-md text-center font-bold">
                An email has been sent to {{ business?.contactEmail }}.<br />
                Continue with your email to create a password and proceed with
                your registration.
              </h1>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { NewBusinessForm } from "@/components/forms/business";
export default {
  name: "Business-Creation",
  components: {
    NewBusinessForm,
  },
  data() {
    return {
      businessId: null,
      acceptForm: false,
      entity: {},
      fullUrl: "",
      loading: true,
      business: {},
      isBusinessAdded: false,
      windowWidth: window.innerWidth,
      mobileViewMaxWidth: 620,
    };
  },
  async mounted() {
    this.init();
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async init() {
      const fullUrl = window.location.origin + window.location.pathname;
      const params = this.$route.params;

      const entityType = params.entity;
      const urlType = params.urlType;

      if (entityType == "partner") {
        await this.$store
          .dispatch(`partner/getPartnerByURL`, {
            entityUrl: fullUrl,
            urlType: urlType,
          })
          .then((response) => {
            if (response) {
              this.entity = response;
              this.fullUrl = fullUrl;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error) {
              this.loading = false;
              this.errorMessage = error?.message;
              window.location.href = "/login?message=Invalid url request&state=error";
            }
          });
      } else if (entityType == "reseller") {
        await this.$store
          .dispatch(`reseller/getResellerByUrl`, {
            entityUrl: fullUrl,
            urlType: urlType,
          })
          .then((response) => {
            if (response) {
              this.entity = response;
              this.fullUrl = fullUrl;
              this.loading = false;
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
              this.loading = false;
              this.errorMessage = error?.message;
              window.location.href = "/login?message=Invalid url request&state=error";
            }
          });
      }
    },
    agreeInTerms() {
      this.acceptForm = true;
    },
    cancelInTerms() {
      window.location.href = "/";
    },

    newBusinessAdded(business) {
      this.isBusinessAdded = true;
      this.business = business.data;
      window.location.href = "/login?message=Lead Created Successfully";
    },
  },
};
</script>
<style scoped lang="scss">
.item {
  width: auto;
  height: 80px;
  margin: 5px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}
</style>
