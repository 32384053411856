<template>
  <div>
    <div class="h-full flex flex-col space-y-4">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="driverTrips"
        @onPageChange="onPageChange($event)"
      >
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.status"
                @change="filterChange"
                class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="(filterStatus, key) in filterStatuses"
                  :key="key"
                  :value="key"
                >
                  {{ filterStatus }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div>
          <!-- <div class="flex flex-row mb-1 sm:mb-0">
            <div class="relative">
              <select
                v-model="filter.sortingField"
                @change="sortBy"
                class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
              >
                <option
                  v-for="(field, key) in sortingFields"
                  :key="key"
                  :value="key"
                >
                  {{ field }}
                </option>
              </select>
              <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>
          </div> -->
          <div class="block relative">
            <span
              class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <font-awesome-icon icon="search" class="text-gray-400" />
            </span>
            <input
              placeholder="Driver"
              class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
              v-model="filter.search"
              @keydown="handleSearchKeyDown"
            />
          </div>
          <div class="block relative">
            <DatePicker
              class="ml-2"
              :date="selectedDateRangeData"
              :displayEvent="false"
              :displayBreach="false"
              @selected="selectedDateRangeData = $event"
              range
              noNavigation
              :clearable="true"
            />
          </div>
        </template>
        <template #callToActionTop>
          <div class="flex">
            <button
              class="btn btn-primary mr-2"
              @click.prevent="addDriverTrip()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Add
            </button>
            <button
              class="btn btn-primary"
              @click.prevent="addDriverTripImport()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Import
            </button>
          </div>
        </template>
        <template #default="{ data }">
          <td class="px-5 py-2 text-center relative">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewDriverTrip(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="editDriverTrip(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="edit" class="my-auto mr-2" /> Edit
                  </StyledIconedLink>
                </li>
                <li v-if="data.hasDocketPhotos">
                  <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewDriverTripDocketPhoto(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="image" class="my-auto mr-2" />
                    Docket Images
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>
    </div>
    <Modal
      ref="modal"
      :title="modalTitle"
      :size="modalSize"
      :bodyHeight="modalHeight"
      :description="modalDescription"
    >
      <div v-if="modalType === 'view'">
        <ViewDriverTrips :driverTripId="selectedDriverTripId" @toast="toast" />
      </div>
      <div v-if="modalType === 'edit'">
        <EditDriverTrips
          :driverTripId="selectedDriverTripId"
          @toast="toast"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalType === 'new'">
        <AddDriverTrip @toast="toast" @closeModal="closeModal" />
      </div>
      <div v-if="modalType === 'import'">
        <AddDriverTripImport @toast="toast" @closeModal="closeModal" />
      </div>
      <div v-if="modalType === 'view-docket-image'">
        <div>
          <div class="grid grid-cols-1 my-5">
            <img
              :src="currentImage"
              alt="Docket Image"
              style="margin: 0 auto"
            />
          </div>
          <div class="grid grid-cols-2 gap-8 px-20 mx-20">
            <button
              class="btn btn-primary"
              :style="{ backgroundColor: theme.secondaryColor }"
              @click="prevImage"
              :disabled="currentImageIndex === 0"
            >
              Previous
            </button>
            <button
              class="btn btn-primary"
              :style="{ backgroundColor: theme.secondaryColor }"
              @click="nextImage"
              :disabled="
                currentImageIndex === selectedDriverTrip.docketPhotos.length - 1
              "
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";
import { Table, Modal, Dropdown } from "@/components/commons";
import ViewDriverTrips from "@/components/view/driver/view-driver-trips";
import EditDriverTrips from "@/components/forms/driver/edit-driver-trips";
import AddDriverTrip from "@/components/forms/driver/add-driver-trips";
import { AddDriverTripImport } from "@/components/forms/driver/driver-trip-import-stepper-component";
import { formatPaginationSettings, formatDate } from "@/_helper";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { StyledIconedLink } from "@/styled-components";
import DatePicker from "@/components/commons/field/DatePicker.vue";

export default {
  name: "Drivers-Trips",
  components: {
    Table,
    Dropdown,
    StyledIconedLink,
    Modal,
    ViewDriverTrips,
    EditDriverTrips,
    AddDriverTrip,
    AddDriverTripImport,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      tableHeader: [
        "Driver",
        "Vehicle",
        "Date",
        "Pickup",
        "Dropoff",
        "Mass Limit",
        "Gross Weight",
        "Net Weight",
        "Material",
        "Status",
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
        paginationDescription: "Displaying drivers trips",
      },
      filter: {
        limit: 20,
        search: "",
        status: "all",
        sortingField: "none",
      },
      filterStatuses: {
        all: "All",
      },
      sortingFields: {
        none: "Sort By",
        remainingWork: "Current 24hr Total Work",
        lastUpdated: "Last Updated",
        lastEventAt: "Last Event",
        driverName: "Driver",
        breachLight: "Breach Light",
      },
      rawDriverTrips: [],
      me: null,
      business: null,
      modalSize: "6xl",
      modalTitle: "",
      modalHeight: "70vh",
      modalType: null,
      modalDescription: null,
      selectedDriverTrip: null,
      selectedDriverTripId: null,
      selectedDateRangeData: [],
      currentImageIndex: 0,
    };
  },

  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  mounted() {
    this.me = this.$store.getters[`account/me`];
    this.business = this.me?.business;
    this.init();
  },

  methods: {
    async init(page = 1) {
      try {
        this.isLoading = true;
        const query = {
          skip: page * this.filter.limit - this.filter.limit,
          limit: this.filter.limit,
          search: this.filter.search,
          filter: {},
          businessId: this.me.businessId
            ? this.me.businessId
            : this.me?.business?._id,
        };

        if (
          this.selectedDateRangeData &&
          this.selectedDateRangeData.length != 0 &&
          this.selectedDateRangeData[0]
        ) {
          query.from = moment(
            this.selectedDateRangeData[0],
            "YYYY-MM-DD"
          ).format();
          query.to = moment(
            this.selectedDateRangeData[1],
            "YYYY-MM-DD"
          ).format();
          // this.selectedDateRangeData = [
          //   new Date(moment(this.selectedDateRangeData[0])),
          //   new Date(moment(this.selectedDateRangeData[1])),
          // ];
        }

        if (this.filter.status !== "all") {
          query.filter = {
            status: this.filter.status,
          };
        }
        const result = await this.$store.dispatch("driver/getTrips", query);

        this.paginationSettings = {
          ...this.paginationSettings,
          ...formatPaginationSettings(result.resultsMeta),
        };
        this.rawDriverTrips = result.data;
      } catch (error) {
        console.log("error: ", error);
        this.toast("error", "Something went wrong");
      } finally {
        this.isLoading = false;
      }
    },

    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    formatData(items = []) {
      return items.map((item) => {
        const {
          id,
          vehiclePlate,
          status,
          destination,
          location,
          massLimit,
          grossWeight,
          netWeight,
          driverName,
          driverId,
          loadTime,
          loadDescription,
          docketPhotos = [],
          weightSelector,
        } = item;
        return [
          {
            id,
            name: driverName,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: vehiclePlate,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: this.formatDate(loadTime),
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: location,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: destination,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: massLimit.toLowerCase(),
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name:
              weightSelector && grossWeight
                ? `${grossWeight} ${weightSelector}`
                : grossWeight,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name:
              weightSelector && netWeight
                ? `${netWeight} ${weightSelector}`
                : netWeight,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: loadDescription,
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
          {
            id,
            name: status.toUpperCase(),
            itemType: "string",
            driverId,
            hasDocketPhotos: docketPhotos && docketPhotos.length ? true : false,
          },
        ];
      });
    },

    onPageChange(event) {
      if (event.page) {
        this.init(event.page);
      }
    },

    filterChange() {
      this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    sortBy() {
      if (this.filter.sortingField === "none") {
        return this.init();
      }

      this.rawDriverTrips = _.orderBy(this.rawDriverTrips, [
        `${this.filter.sortingField}`,
      ]);
    },
    formatDate(date) {
      return formatDate(date, "DD/MM/YYYY", true);
    },

    viewDriverTrip(driverTripId) {
      this.$refs.modal.openModal();
      this.modalType = "view";
      this.modalTitle = "Driver Trip";
      this.selectedDriverTripId = driverTripId;
      this.modalDescription = "";
    },
    editDriverTrip(driverTripId) {
      this.$refs.modal.openModal();
      this.selectedDriverTripId = driverTripId;
      this.modalDescription = "";
      this.modalType = "edit";
      this.modalTitle = "Edit Driver Trip";
      this.modalDescription = "";
    },
    viewDriverTripDocketPhoto(driverTripId) {
      this.currentImageIndex = 0;
      this.$refs.modal.openModal();
      this.modalType = "view-docket-image";
      this.modalTitle = "Driver Trip Docket Images";
      this.selectedDriverTrip = this.rawDriverTrips.filter(
        (item) => item.id === driverTripId
      )[0];
    },

    closeModal() {
      this.$refs.modal.closeModal();
      this.modalType = "";
      this.modalTitle = "";
      this.modalDescription = "";
      this.init();
    },

    addDriverTrip() {
      this.$refs.modal.openModal();
      this.modalType = "new";
      this.modalTitle = "Add Driver Trip";
      this.modalDescription = "";
    },
    addDriverTripImport() {
      this.$refs.modal.openModal();
      this.modalType = "import";
      this.modalTitle = "Driver Trip Import";
      this.modalDescription =
        "To efficiently add multiple driver trips at once, simply upload a CSV file. For proper formatting guidance, refer to the provided template CSV file.";
    },

    onDateRangeChange() {
      if (this.selectedDateRangeData.length && this.selectedDateRangeData[0]) {
        this.init();
      }
    },
    prevImage() {
      if (this.currentImageIndex > 0) {
        this.currentImageIndex--;
      }
    },
    nextImage() {
      if (
        this.currentImageIndex <
        this.selectedDriverTrip.docketPhotos.length - 1
      ) {
        this.currentImageIndex++;
      }
    },
  },
  watch: {
    selectedDateRangeData: {
      handler: function () {
        this.onDateRangeChange();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    driverTrips() {
      return this.formatData(this.rawDriverTrips);
    },
    currentImage() {
      return this.selectedDriverTrip.docketPhotos[this.currentImageIndex];
    },
  },
};
</script>
