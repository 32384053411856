<template>
  <div>
    <div
      v-if="loading"
      class="loading absolute mx-auto my-24 w-full h-full translate-y-2/4 flex flex-col items-center"
    >
      <ui-spinner class="mx-auto mb-3"></ui-spinner>
      Loading....
    </div>
    <div v-else>
      <div>
        <div class="items-center justify-between">
          <FormulateForm v-model="values" @submit="nextStep">
            <div class="mb-5 relative">
              <label
                >People who raised
                <em><small>(It can be multiple people)</small></em></label
              >
              <div class="pb-2 border rounded-lg w-full">
                <input
                  class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
                  v-model="newRaiser"
                  @keydown.enter.prevent="addRaiser"
                  placeholder="Add new raiser"
                  onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
                />

                <ul
                  v-if="newRaiser && leftOverRaisers.length"
                  class="w-full px-3 pb-3 pt-1 absolute z-30 bg-white shadow-lg overflow-y-auto max-h-44"
                >
                  <li
                    v-for="(result, i) in leftOverRaisers"
                    :key="i"
                    @click="addRaiser(result, true)"
                    class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600"
                  >
                    {{ result.name }}
                  </li>
                </ul>

                <div class="flex items-center flex-wrap px-2 pb-1">
                  <span class="py-2 invisible">:</span>
                  <small
                    v-for="(data, index) in selectedRaisers"
                    class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
                    :key="index"
                  >
                    {{ data.name
                    }}<button
                      @click.prevent="removeRaiser(data.name)"
                      class="ml-2 mr-1 px-1"
                    >
                      <b>x</b>
                    </button>
                  </small>
                </div>
              </div>
            </div>

            <div class="mb-5 relative">
              <label
                >Investigator(s)
                <em><small>(It can be multiple people)</small></em></label
              >
              <div class="pb-2 border rounded-lg w-full">
                <input
                  class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
                  v-model="newInvestigator"
                  @keydown.enter.prevent="addInvestigator"
                  placeholder="Add new investigator"
                  onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
                />

                <ul
                  v-if="newInvestigator && leftOverInvestigators.length"
                  class="w-full px-3 pb-3 pt-1 absolute z-30 bg-white shadow-lg overflow-y-auto max-h-44"
                >
                  <li
                    v-for="(result, i) in leftOverInvestigators"
                    :key="i"
                    @click="addInvestigator(result, true)"
                    class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600"
                  >
                    {{ result.name }}
                  </li>
                </ul>

                <div class="flex items-center flex-wrap px-2 pb-1">
                  <span class="py-2 invisible">:</span>
                  <small
                    v-for="(data, index) in selectedInvestigators"
                    class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
                    :key="index"
                  >
                    {{ data.name
                    }}<button
                      @click.prevent="removeInvestigator(data.name)"
                      class="ml-2 mr-1 px-1"
                    >
                      <b>x</b>
                    </button>
                  </small>
                </div>
              </div>
            </div>

            <div class="mb-5 relative">
              <label
                >Decision maker(s)
                <em><small>(It can be multiple people)</small></em></label
              >
              <div class="pb-2 border rounded-lg w-full">
                <input
                  class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mb-2"
                  v-model="newDecisionMaker"
                  @keydown.enter.prevent="addDecisionMaker"
                  placeholder="Add new decision maker"
                  onkeypress="return /[0-9a-zA Z]/i.test(event.key)"
                />

                <ul
                  v-if="newDecisionMaker && leftOverDecisionMakers.length"
                  class="w-full px-3 pb-3 pt-1 absolute z-20 bg-white shadow overflow-y-auto h-44"
                >
                  <li
                    v-for="(result, i) in leftOverDecisionMakers"
                    :key="i"
                    @click="addDecisionMaker(result, true)"
                    class="cursor-pointer py-2 px-4 w-full border-b border-gray-200 dark:border-gray-600"
                  >
                    {{ result.name }}
                  </li>
                </ul>

                <div class="flex items-center flex-wrap px-2 pb-1">
                  <span class="py-2 invisible">:</span>
                  <small
                    v-for="(data, index) in selectedDecisionMakers"
                    class="bg-green-400 text-white px-4 py-2 my-1 mr-1 rounded-full"
                    :key="index"
                  >
                    {{ data.name
                    }}<button
                      @click.prevent="removeDecisionMaker(data)"
                      class="ml-2 mr-1 px-1"
                    >
                      <b>x</b>
                    </button>
                  </small>
                </div>
              </div>
            </div>

            <div class="mb-5 relative">
              <FormulateInput
                type="date"
                name="dateRaised"
                label="Date raised"
                validation="required|after:2019-01-01"
                min="2018-12-01"
              />
            </div>
            <FormulateInput
              type="submit"
              label="Next"
              input-class="btn btn-primary w-full mt-2"
              :style="{ backgroundColor: theme.secondaryColor }"
            />
          </FormulateForm>
        </div>
      </div>
      <button class="btn btn-cancel w-full mb-2" @click="onPrevious">
        Back
      </button>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "Investigation-Details",
  props: {
    preData: null,
    postData: null,
  },
  data() {
    return {
      interChangingData: [],
      loading: true,
      results: [],
      isLoading: true,
      values: { dateRaised: moment().format("YYYY-MM-DD") },
      businessUsers: [],

      newRaiser: "",
      selectedRaisers: [],
      alreadyAssignedRaisers: [],

      newInvestigator: "",
      selectedInvestigators: [],
      alreadyAssignedInvestigators: [],

      newDecisionMaker: "",
      selectedDecisionMakers: [],
      alreadyAssignedDecisionMakers: [],
    };
  },
  computed: {
    leftOverRaisers() {
      let setArr = [],
        results = [];
      if (
        this.alreadyAssignedRaisers.length > 0 &&
        this.businessUsers.length > 0
      ) {
        let thisUsers = this.alreadyAssignedRaisers;

        if (this.alreadyAssignedRaisers.length > 0) {
          setArr = this.businessUsers.filter(function (val) {
            for (let i = 0; i < thisUsers.length; i++) {
              let arr = thisUsers[i];
              if (val.userId == arr.userId) return false;
            }
            return true;
          });
        } else {
          setArr = this.businessUsers;
        }
      } else {
        setArr = this.businessUsers;
      }

      // Search accurate match query
      if (this.newRaiser && setArr.length) {
        const thisType = this.newRaiser;
        results = setArr.filter((item) =>
          thisType
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v))
        );
      } else {
        results = setArr;
      }

      // Sort by name
      if (results.length) {
        return results.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return results;
      }
    },
    leftOverInvestigators() {
      let setArr = [],
        results = [];
      if (
        this.alreadyAssignedInvestigators.length > 0 &&
        this.businessUsers.length > 0
      ) {
        let thisUsers = this.alreadyAssignedInvestigators;

        if (this.alreadyAssignedInvestigators.length > 0) {
          setArr = this.businessUsers.filter(function (val) {
            for (let i = 0; i < thisUsers.length; i++) {
              let arr = thisUsers[i];
              if (val.userId == arr.userId) return false;
            }
            return true;
          });
        } else {
          setArr = this.businessUsers;
        }
      } else {
        setArr = this.businessUsers;
      }

      // Search accurate match query
      if (this.newInvestigator && setArr.length) {
        const thisType = this.newInvestigator;
        results = setArr.filter((item) =>
          thisType
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v))
        );
      } else {
        results = setArr;
      }

      // Sort by name
      if (results.length) {
        return results.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return results;
      }
    },
    leftOverDecisionMakers() {
      let setArr = [],
        results = [];
      if (
        this.alreadyAssignedDecisionMakers.length > 0 &&
        this.businessUsers.length > 0
      ) {
        let thisUsers = this.alreadyAssignedDecisionMakers;

        if (this.alreadyAssignedDecisionMakers.length > 0) {
          setArr = this.businessUsers.filter(function (val) {
            for (let i = 0; i < thisUsers.length; i++) {
              let arr = thisUsers[i];
              if (val.userId == arr.userId) return false;
            }
            return true;
          });
        } else {
          setArr = this.businessUsers;
        }
      } else {
        setArr = this.businessUsers;
      }

      // Search accurate match query
      if (this.newDecisionMaker && setArr.length) {
        const thisType = this.newDecisionMaker;
        results = setArr.filter((item) =>
          thisType
            .toLowerCase()
            .split(" ")
            .every((v) => item.name.toLowerCase().includes(v))
        );
      } else {
        results = setArr;
      }

      // Sort by name
      if (results.length) {
        return results.sort((a, b) => a.name.localeCompare(b.name));
      } else {
        return results;
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  async created() {
    let data = this.preData || this.postData || null;
    if (data?.steps) {
      this.populateFormValue(data.steps?.investigationDetails);
      this.interChangingData = data;
    }
    const proxySite = this.$store.getters[`account/proxySite`];
    const isProxySite = proxySite && !proxySite.isMainBusiness;

    const users = await this.$store.dispatch(`rolesandrespo/getUsers`, {
      ...(isProxySite ? { site: proxySite._id } : {}),
    });

    if (users.length > 0) {
      this.businessUsers = this.formatUsers(users);
    }
    setTimeout(() => (this.loading = false), 300);
  },
  methods: {
    populateFormValue(data) {
      if (data) {
        this.values = {
          investigationName: data?.investigationName,
          dateRaised: data?.dateRaised,
        };
        this.selectedRaisers = [...data?.raisers];
        this.alreadyAssignedRaisers = [...data?.raisers];
        this.selectedInvestigators = [...data?.investigators];
        this.selectedDecisionMakers = [...data?.decisionMakers];
        this.alreadyAssignedInvestigators = [...data?.investigators];
        this.alreadyAssignedDecisionMakers = [...data?.decisionMakers];
      }
      return;
    },
    formatUsers(users) {
      return users.map((user) => {
        return {
          userId: user._id,
          name: user.userName || "",
        };
      });
    },
    addRaiser(data, fromList = false) {
      let name = data.name || this.newRaiser;
      let exiting = this.selectedRaisers.filter((item) => item.name == name);
      if (_.isEmpty(exiting)) {
        if (!fromList) {
          if (_.isEmpty(this.newRaiser)) return;
          data = {
            userId: null,
            name: this.newRaiser,
          };
        }
        this.selectedRaisers.push(data);
        this.alreadyAssignedRaisers.push(data);
      }
      this.newRaiser = "";
    },
    removeRaiser(user) {
      this.selectedRaisers.splice(this.selectedRaisers.indexOf(user), 1);
      this.alreadyAssignedRaisers.splice(
        this.alreadyAssignedRaisers.indexOf(user),
        1
      );
    },
    addInvestigator(data, fromList = false) {
      let name = data.name || this.newInvestigator;
      let exiting = this.selectedInvestigators.filter(
        (item) => item.name == name
      );
      if (_.isEmpty(exiting)) {
        if (!fromList) {
          if (_.isEmpty(this.newInvestigator)) return;
          data = {
            userId: null,
            name: this.newInvestigator,
          };
        }
        this.selectedInvestigators.push(data);
        this.alreadyAssignedInvestigators.push(data);
      }
      this.newInvestigator = "";
    },
    removeInvestigator(user) {
      this.selectedInvestigators.splice(
        this.selectedInvestigators.indexOf(user),
        1
      );
      this.alreadyAssignedInvestigators.splice(
        this.alreadyAssignedInvestigators.indexOf(user),
        1
      );
    },
    addDecisionMaker(data, fromList = false) {
      let name = data.name || this.newDecisionMaker;
      let exiting = this.selectedDecisionMakers.filter(
        (item) => item.name == name
      );
      if (_.isEmpty(exiting)) {
        if (!fromList) {
          if (_.isEmpty(this.newDecisionMaker)) return;
          data = {
            userId: null,
            name: this.newDecisionMaker,
          };
        }
        this.selectedDecisionMakers.push(data);
        this.alreadyAssignedDecisionMakers.push(data);
      }
      this.newDecisionMaker = "";
    },
    removeDecisionMaker(user) {
      this.selectedDecisionMakers.splice(
        this.selectedDecisionMakers.indexOf(user),
        1
      );
      this.alreadyAssignedDecisionMakers.splice(
        this.alreadyAssignedDecisionMakers.indexOf(user),
        1
      );
    },
    onPrevious() {
      this.values.raisers = this.selectedRaisers;
      this.values.investigators = this.selectedInvestigators;
      this.values.decisionMakers = this.selectedDecisionMakers;

      const investigationDetails = this.values;
      const preData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData.steps,
          ...{ investigationDetails: investigationDetails },
        },
      };
      this.$emit("backStep", preData);
    },
    nextStep() {
      if (
        !this.selectedInvestigators.length ||
        !this.selectedDecisionMakers.length
      ) {
        this.$store.commit("setDialogNotify", true);
        if (this.selectedInvestigators)
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: "Requires atleast 1 investigator",
          });
        return false;
      }
      if (!this.selectedDecisionMakers.length) {
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: "Requires atleast 1 decision maker",
        });
        return false;
      }
      this.values.raisers = this.selectedRaisers;
      this.values.investigators = this.selectedInvestigators;
      this.values.decisionMakers = this.selectedDecisionMakers;

      const investigationDetails = this.values;
      const preData = {
        ...this.interChangingData,
        steps: {
          ...this.interChangingData?.steps,
          ...{ investigationDetails: investigationDetails },
        },
      };
      this.$emit("nextStep", preData);
    },
  },
};
</script>
