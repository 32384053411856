<template>
  <div>
    <div class="flex flex-col h-full">
      <div
        class="relative p-5 overflow-hidden bg-white rounded shadow-md report-container"
      >
        <div
          class="flex items-center justify-between w-full pb-4 mb-3 border-b border-gray-300"
        >
          <div class="flex items">
            <div class="mr-3" style="width: 13.5rem">
              <div class="block mb-1 text-xs font-bold text-grey-500">
                Driver
              </div>
              <FormulateInput
                name="selectedDriver"
                type="driver-select"
                placeholder="Select a driver"
                style="margin-bottom: 0px"
                class="content-center pb-4"
                :getOptionKey="(option) => option.driverId"
                :getOptionLabel="(option) => option.driver.driverName"
                :filterable="false"
                :clearable="false"
                @selected="selectedDriver = $event"
              />
            </div>
            <div class="mr-3" style="width: 13.5rem">
              <div class="block mb-1 text-xs font-bold text-grey-500">
                Date Range
              </div>
              <DatePicker
                ref="childDatePicker"
                :date="selectedDateRange"
                :displayEvent="false"
                :displayBreach="false"
                @selected="selectedDateRange = $event"
                range
                noNavigation
                :clearable="false"
                class="mt-1 mb-5 send-datepicker"
                placeholder="Select a date range"
              />
            </div>
          </div>
          <div>
            <button
              :disabled="!selectedDriver"
              :class="{ 'cursor-not-allowed': !selectedDriver }"
              class="flex items-center btn btn-primary"
              @click.prevent="onGetReport"
              style="height: 38px"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Get Report
            </button>
          </div>
        </div>

        <div class="flex items-center w-full driver-info-container">
          <DriverMassReport
            ref="childDriverMassReport"
            @sendReportloading="sendReportloading = $event"
          />
        </div>
      </div>
    </div>
    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content === 'send-report'">
        <div class="mb-16">
          <div v-if="!sendReportloading">
            <FormulateForm
              v-model="sendReportModel"
              @submit="onSubmitSendReport"
            >
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="email"
                  label="Email address"
                  validation="required|email"
                  @input="handleStripEmailCase($event)"
                />
                <div>
                  <label class="mb-1 text-sm font-medium">Date Range</label>
                  <DatePicker
                    ref="childDatePicker"
                    :date="selectedDateRange"
                    :displayEvent="false"
                    :displayBreach="false"
                    @selected="selectedDateRange = $event"
                    range
                    noNavigation
                    :clearable="false"
                    class="mt-1 mb-5 send-datepicker"
                  />
                </div>
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="w-full mt-5 btn btn-primary"
                  :disabled="!canSend() || sendReportloading"
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { Modal } from "@/components/commons";
import DatePicker from "@/components/commons/field/DatePicker.vue";
import DriverMassReport from "@/components/view/report/driver-mass.vue";
export default {
  name: "Driver-Mass-Report",
  components: {
    Modal,
    DatePicker,
    DriverMassReport,
  },

  data() {
    return {
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      selectedDateRange: [],
      selectedDriver: null,
      sendReportModel: null,
      sendReportloading: false,
    };
  },

  mounted() {},

  methods: {
    canSearch() {
      return (
        !_.isEmpty(this.selectedDriver) &&
        this.selectedDateRange.length &&
        this.selectedDateRange[0]
      );
    },

    canSend() {
      return (
        !_.isEmpty(this.sendReportModel?.email) &&
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },

    async onSearch() {
      if (this.canSearch()) {
        await this.$refs.childDriverMassReport.onSearch(
          this.selectedDriver,
          this.selectedDateRange
        );
      }
    },

    onGetReport() {
      this.$refs.modal.openModal();
      this.modal.content = "send-report";
      this.modal.title = "Send Report To Email";
    },
    handleStripEmailCase(value) {
      this.sendReportModel.email = value.toLowerCase();
    },
    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      await this.$refs.childDriverMassReport.onSubmitSendReport(recipientEmail);
    },
  },

  watch: {
    selectedDateRange: {
      handler: function () {
        this.onSearch();
      },
      deep: true,
    },
    selectedDriver: {
      handler: function () {
        this.onSearch();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped lang="scss">
.report-container {
  min-height: calc(100vh - 92px);
}

.send-datepicker::v-deep > div {
  @apply w-full;
}
</style>
