<template>
  <div>
    <div class="flex justify-between items-center w-full mb-4 mt-5">
      <div class="flex">
        <div class="block relative">
          <span
            class="h-full absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer"
            @click="searchDriver(seachKeyword)"
          >
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            v-on:keyup.enter="searchVehicle(seachKeyword)"
            type="email"
            placeholder="Search Vehicle..."
            class="h-full appearance-none rounded border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="seachKeyword"
          />
        </div>
      </div>
    </div>
    <div class="mb-16">
      <div
        class="text-red-500 font-bold text-sm text-center my-5"
        v-if="errorMessage.length"
      >
        {{ errorMessage[0] }}
      </div>
      <Table
        v-if="vehicles.length"
        :tableHeader="tableHeader"
        :data="vehicles"
        :hasFilters="false"
        :hasActions="false"
      >
        <template #default="{ data }">
          <fragment v-if="data">
            <td class="px-2.5 py-2">
              <button
                class="btn btn-primary mt-2"
                @click="nexStep(data)"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Select
              </button>
            </td>
          </fragment>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import { Table } from "@/components/commons";
import { mapGetters } from "vuex";

export default {
  name: "Driver-Lookup",
  components: { Table },
  data() {
    return {
      tableHeader: [
        "Vehicle Plate",
        "Vehicle Class",
        "Vehicle Type",
        "GVM",
        "VIN",
        "Actions",
      ],
      vehicles: [],
      seachKeyword: "",
      errorMessage: [],
      searchedVehicles: [],
    };
  },

  methods: {
    //Format table data
    parseVehicles(data) {
      return data.map((vehicleData) => [
        {
          id: vehicleData._id,
          name: vehicleData.vehiclePlate,
          itemType: "name",
          hasLink: true,
        },
        {
          id: vehicleData._id,
          name: vehicleData.vehicleClass.name,
          itemType: "string",
        },
        {
          id: vehicleData._id,
          name: vehicleData.vehicleType.name,
          itemType: "string",
        },
        { id: vehicleData._id, name: vehicleData.GMV, itemType: "string" },
        { id: vehicleData._id, name: vehicleData.VIN, itemType: "string" },
      ]);
    },
    validateEmail: (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async searchVehicle(search) {
      // if(!this.validateEmail(search)) this.errorMessage.push('Invalid email address please try again.')
      try {
        const vehicles = await this.$store.dispatch(
          `vehicle/getVehicle`,
          search
        );
        this.searchedVehicles = vehicles;
        if (vehicles) {
          this.vehicles = this.parseVehicles(vehicles);
        } else {
          this.vehicles = [];
        }
      } catch (error) {
        this.errorMessage = [error.message];
        this.vehicles = [];
      }
    },
    nexStep(data) {
      data.type = "vehicle";
      this.$emit("onSelect", data);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
