<template>
  <div>
    <div v-if="readVehicles" class="h-full flex flex-col">
      <Tabs
        clickEvent
        @click="fetchTab"
        :activeTab="0"
        class="flex flex-col h-full"
      >
        <Tab title="Active" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="disableVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye-slash" class="my-auto" />
                        Disable
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="setAsDefect(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="car-crash" class="my-auto" />
                        Defect
                      </StyledIconedLink>
                    </li>
                    <li v-if="isMassManagementModuleFound">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="configureVehicleMass(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="cog" class="my-auto" /> Mass
                      </StyledIconedLink>
                    </li>
                    <StyledIconedLink
                      class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                      @click.prevent="moveVehicle(data.id)"
                      :iconHoverColor="theme.secondaryColor"
                    >
                      <font-awesome-icon icon="arrows-alt" class="my-auto" />
                      Move Vehicle
                    </StyledIconedLink>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showConfig()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    <font-awesome-icon icon="cog" />
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="downloadVehicles()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                    title="Download All Vehicles"
                  >
                    <font-awesome-icon icon="download" class="my-auto" />
                  </button>
                  <button
                    v-show="isMassManagementModuleFound"
                    class="btn btn-primary"
                    @click.prevent="configureDefaultMass()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Mass
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Report
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Defected Vehicles" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="setAsNonDefect(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="car" class="my-auto" /> Clear
                        Defect
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showConfig()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    <font-awesome-icon icon="cog" />
                  </button>
                  <button
                    v-show="isMassManagementModuleFound"
                    class="btn btn-primary"
                    @click.prevent="configureDefaultMass()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Mass
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Report
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Disabled Vehicles" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="activateVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="check" class="my-auto" />
                        Re-enable
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showConfig()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    <font-awesome-icon icon="cog" />
                  </button>
                  <button
                    v-show="isMassManagementModuleFound"
                    class="btn btn-primary"
                    @click.prevent="configureDefaultMass()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Mass
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Report
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Driver Pending Vehicles">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="disableVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye-slash" class="my-auto" />
                        Disable
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showConfig()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    <font-awesome-icon icon="cog" />
                  </button>
                  <button
                    v-show="isMassManagementModuleFound"
                    class="btn btn-primary"
                    @click.prevent="configureDefaultMass()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Mass
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Report
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Duplicated Vehicles" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="duplicatedTableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto" /> View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto" /> Edit
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="mergeVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="clone" class="my-auto" /> Merge
                        Vehicle
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showConfig()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    <font-awesome-icon icon="cog" />
                  </button>
                  <button
                    v-show="isMassManagementModuleFound"
                    class="btn btn-primary"
                    @click.prevent="configureDefaultMass()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Mass
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Report
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab
          :visible="isRootBusiness && depots.length > 0"
          title="All Sites"
          class="flex-1 flex flex-col"
        >
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="eye" class="my-auto mr-2" />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
                  <button
                    class="btn btn-primary"
                    @click.prevent="showConfig()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    <font-awesome-icon icon="cog" />
                  </button>
                  <button
                    v-show="isMassManagementModuleFound"
                    class="btn btn-primary"
                    @click.prevent="configureDefaultMass()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Mass
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="vehicleReport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Report
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicleImport()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Import
                  </button>
                  <button
                    class="btn btn-primary"
                    @click.prevent="newVehicle()"
                    :style="{ backgroundColor: theme.secondaryColor }"
                  >
                    Add
                  </button>
                </div>
              </div>
            </template>
          </Table>
        </Tab>
        <Tab title="Connected Fleets" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="connectedFleetTableHeader"
            :paginationSettings="paginationSettings"
            :data="vehiclesData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
            :defaultMessage="selectedFleetId ? null : 'Please Select a Fleet'"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="selectedFilteredStatus"
                    @change="selectFilteredStatus"
                    class="appearance-none h-full border border-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus.label }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li v-if="!data.isVehicleSynced">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="newFleetVehicle(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="sign-in-alt"
                          class="my-auto mr-2"
                        />
                        Convert
                      </StyledIconedLink>
                    </li>
                    <li v-if="data.isVehicleSynced">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="openMergeVehicleModal(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="compress-arrows-alt"
                          class="my-auto mr-2"
                        />
                        Merge
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template v-if="writeVehicles" #callToActionTop>
              <div class="flex mx-10">
                <a
                  class="p-2 text-md font-bold"
                  :style="{ color: theme.primaryColor }"
                  v-if="isVehicleContractTarget"
                >
                  Available Seats: {{ totalAvailableSeat }}
                </a>
                <FormulateInput
                  style="min-width: 200px"
                  placeholder="Select Fleet"
                  type="fleet-connection-select"
                  label="Fleet Connection"
                  :getOptionKey="(option) => option._id"
                  :getOptionLabel="(option) => option.name"
                  @input="onSelectFleetConnection($event)"
                  :geotab="isGeotabAccount"
                />
              </div>
            </template>
          </Table>
        </Tab>
      </Tabs>
    </div>
    <div
      v-if="!readVehicles && hasRole"
      class="w-full min-h-full text-center mt-5"
    >
      <h1>
        Not authorized to view vehicles. Please contact your business admin.
      </h1>
    </div>
    <div v-if="!hasRole" class="text-center">
      <h1>
        You are currently not associated with a role. Please contact support.
      </h1>
    </div>

    <Modal
      ref="modal"
      :title="modal.modalTitle"
      :size="modal.modalSize"
      :height="modal.modalHeight"
    >
      <div v-if="modal.modalContent == 'new'">
        <NewVehicle @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modal.modalContent == 'view'">
        <VehicleDetailsView
          @closeRefresh="closeRefresh"
          @editVehicle="editVehicle"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent == 'edit'">
        <EditVehicle @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>
      <div v-if="modal.modalContent == 'notification'">
        <ApprovalVehicle />
      </div>
      <div v-if="modal.modalContent == 'new-vehicle-import'">
        <NewVehicleImport @closeRefresh="closeRefresh" />
      </div>
      <div v-if="modal.modalContent == 'new-fleet-vehicle'">
        <NewVehicle
          @closeRefresh="closeRefresh"
          :defaultData="modal.modalData"
        />
      </div>
      <div v-if="modal.modalContent === 'disable'">
        <ApprovalDisableVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'defect'">
        <ApprovalDefectVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'activate'">
        <ApprovalActivateVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'non-defect'">
        <ApprovalNonDefectVehicle
          @closeRefresh="closeRefresh"
          :detailsId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent === 'merge'">
        <MergeVehicle @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>
      <div v-if="modal.modalContent === 'move'">
        <MoveVehicle @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>
      <div v-if="modal.modalContent === 'odometer'">
        <EditOdometer @closeRefresh="closeRefresh" :detailsId="selectedId" />
      </div>

      <div v-if="modal.modalContent === 'add-vehicle-seat'">
        <div class="mb-16">
          <FormulateForm @submit="onSubmitBillingPage">
            <div class="mb-5">
              <label class="font-medium text-lg"
                >There is no available seat left. You have to add seats before
                add new drivers.</label
              >
            </div>
            <StyledSubmit :backgroundColor="theme.secondaryColor">
              <FormulateInput
                type="submit"
                label="Visit Billing Page"
                input-class="btn btn-primary w-full"
              />
            </StyledSubmit>
          </FormulateForm>
        </div>
      </div>
      <div v-if="modal.modalContent == 'mass'">
        <ConfigureVehicleMass
          @closeRefresh="closeRefresh"
          @toast="toast"
          :vehicleId="selectedId"
        />
      </div>
      <div v-if="modal.modalContent == 'merge-fleet-vehicle'">
        <div>
          <div class="mb-16">
            <div class="text-center mb-10">
              <div class="font-bold text-black-primary text-xl">
                Attempting to merge this vehicle
                {{ modal.modalData.vehiclePlate }} to an existing active vehicle
              </div>
            </div>
            <div>
              <div class="mb-5">
                <div class="flex flex-row">
                  <div class="font-bold text-black-primary text-base">
                    Are you sure you want to continue?
                  </div>
                </div>
                <div class="flex flex-row mt-3">
                  <button
                    @click.prevent="closeModal()"
                    class="w-1/2 px-2 mr-2 btn btn-primary"
                  >
                    Cancel
                  </button>
                  <button
                    @click.prevent="mergeFleetVehicle()"
                    class="w-1/2 px-2 ml-2 btn btn-primary"
                    :style="{
                      background: theme.primaryColor,
                      color: 'white',
                    }"
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal
      ref="configModal"
      title="Vehicle Configuration"
      size="5xl"
      :usepadding="false"
      :height="modal.modalHeight"
    >
      <VehicleConfiguration />
    </Modal>
  </div>
</template>

<script>
import { Dropdown, Modal, Table } from "@/components/commons";
import {
  ApprovalVehicle,
  EditVehicle,
  NewVehicle,
  ConfigureVehicleMass,
} from "@/components/forms/vehicle";
import { NewVehicleImport } from "@/components/forms/vehicle/vehicle-import";
import VehicleDetailsView from "@/components/view/vehicle/";
import { VehicleConfiguration } from "@/components/config/vehicle";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import {
  formatDate,
  formatPaginationSettings,
  convertJSONToCSV,
  downloadCSV,
} from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import ApprovalDisableVehicle from "@/components/forms/vehicle/approval-disable-vehicle.vue";
import ApprovalDefectVehicle from "@/components/forms/vehicle/approval-defect-vehicle.vue";
import ApprovalActivateVehicle from "@/components/forms/vehicle/approval-activate-vehicle.vue";
import ApprovalNonDefectVehicle from "@/components/forms/vehicle/approval-non-defect-vehicle.vue";
import MergeVehicle from "@/components/forms/vehicle/merge-vehicle.vue";
import MoveVehicle from "@/components/forms/vehicle/move-vehicle.vue";
import EditOdometer from "@/components/forms/vehicle/edit-odometer.vue";
import { calculateSeatCountAvailability } from "../../_helper";

export default {
  name: "Vehicles",
  components: {
    EditOdometer,
    Dropdown,
    MergeVehicle,
    MoveVehicle,
    ApprovalNonDefectVehicle,
    ApprovalActivateVehicle,
    ApprovalDefectVehicle,
    ApprovalDisableVehicle,
    Table,
    Modal,
    NewVehicle,
    EditVehicle,
    VehicleDetailsView,
    VehicleConfiguration,
    NewVehicleImport,
    ApprovalVehicle,
    Tabs,
    Tab,
    StyledIconedLink,
    ConfigureVehicleMass,
  },
  data() {
    return {
      isLoading: false,
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: DEFAULT_MODAL_HEIGHT,
        modalSize: "2xl",
        modalData: "",
      },
      vehicles: [],
      // tableHeader: [
      //   "Plate Number",
      //   "Registration Expiry",
      //   "Fleet ID",
      //   "GVM",
      //   "Vehicle Class",
      //   "Vehicle Type",
      //   "Last Known Odometer",
      //   "Date Created",
      //   "Status",
      //   // TODO: Optional to enable if needed
      //   // 'Drive Vehicle',
      //   // 'Concrete Agitator',
      //   // 'Livestock Vehicle',
      // ],
      tableHeader: [
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehiclePlate",
          isFilterable: true,
        },
        {
          name: "Registration Expiry",
          type: "date",
          fieldName: "registrationExpiry",
          isFilterable: true,
        },
        {
          name: "Fleet ID",
          type: "text",
          fieldName: "fleetId",
          isFilterable: true,
        },
        {
          name: "GVM",
          type: "number",
          fieldName: "GMV",
          isFilterable: true,
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicleClass.name",
          isFilterable: true,
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicleType.name",
          isFilterable: true,
        },
        {
          name: "Date Created",
          type: "text",
          fieldName: "createdAt",
          isFilterable: true,
        },
        {
          name: "Status",
          type: "boolean",
          fieldName: "isActive",
          isFilterable: true,
        },
      ],

      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      vehicleData: {},
      selectedId: "",
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: [
        {
          label: "All",
          value: "",
        },
        {
          label: "Drive",
          value: true,
        },
        {
          label: "Non Drive",
          value: false,
        },
      ],
      readVehicles: false,
      writeVehicles: false,
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      connectedFleetTableHeader: [
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehiclePlate",
          isFilterable: true,
        },
        {
          name: "VIN",
          type: "text",
          fieldName: "VIN",
          isFilterable: true,
        },
        {
          name: "Fleet",
          type: "text",
          fieldName: "fleetId",
          isFilterable: true,
        },
        {
          name: "Synced with Logmaster",
          type: "boolean",
          fieldName: "isVehicleSynced",
          isFilterable: true,
        },
      ],
      isRootBusiness: false,
      depots: [],
      duplicatedTableHeader: [
        {
          name: "Plate Number",
          type: "text",
          fieldName: "vehiclePlate",
          isFilterable: false,
        },
        {
          name: "Registration Expiry",
          type: "date",
          fieldName: "registrationExpiry",
          isFilterable: false,
        },
        {
          name: "Fleet ID",
          type: "text",
          fieldName: "fleetId",
          isFilterable: false,
        },
        {
          name: "GVM",
          type: "number",
          fieldName: "GMV",
          isFilterable: false,
        },
        {
          name: "Vehicle Class",
          type: "text",
          fieldName: "vehicleClass.name",
          isFilterable: false,
        },
        {
          name: "Vehicle Type",
          type: "text",
          fieldName: "vehicleType.name",
          isFilterable: false,
        },
        {
          name: "Date Created",
          type: "text",
          fieldName: "createdAt",
          isFilterable: false,
        },
        {
          name: "Status",
          type: "boolean",
          fieldName: "isActive",
          isFilterable: false,
        },
        {
          name: "Source",
          type: "text",
          fieldName: "source",
          isFilterable: false,
        },
      ],
      maximumSeatCount: {
        ewd: {
          count: 0,
          isSubscribed: false,
        },
        fatigue: {
          count: 0,
          isSubscribed: false,
        },
        km: {
          count: 0,
          isSubscribed: false,
        },
      },
      availableSeats: {},
      addImportAccess: {
        ewd: true,
        fatigue: true,
        kmtime: true,
      },
      multiFilter: {},
      isVehicleContractTarget: false,
      currentVehicleSeatCounts: {},
      totalAvailableSeat: 0,
      me: {},
      isMassManagementModuleFound: false,
      selectedFleetId: null,
    };
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.me = me;

    if (me?.business) {
      const proxySite = this.$store.getters[`account/proxySite`];
      const isProxySite = !_.isEmpty(proxySite) && _.isObject(proxySite);
      this.isRootBusiness = isProxySite ? false : true;
    }

    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readVehicles = securityAccessObj.readVehicles;
      this.writeVehicles = securityAccessObj.writeVehicles;
    } else {
      this.hasRole = false;
    }

    this.depots = await this.$store.dispatch(`business/getSites`, {
      depotId: me?.business?._id,
      isDeleted: false,
    });

    const modules = me.business?.modules || [];
    this.isMassManagementModuleFound = modules.find(
      (module) => module.apiTag === "mass-management"
    )
      ? true
      : false;
    this.init();
  },
  methods: {
    async onMultiFilter(data) {
      this.multiFilter = { ...data };
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;

      if (this.filter.status === "connected-fleets") {
        this.vehicles = [];
        if (this.selectedFleetId) {
          this.vehicles = await this.fetchFleetVehicles();
        }
        this.isLoading = false;
        return;
      }
      const entityId = this.$store.getters[`account/me`].business._id;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status === "all-sites") {
        const siteIds = this.depots.map((x) => x._id);
        query.filter.siteId = { $in: siteIds };
      }

      if (this.filter.status === "active") {
        query.filter.isActive = { $eq: true };
        query.filter.isDefected = { $nin: true };
        query.filter.isPending = { $exists: true, $eq: true };

        if (this.isRootBusiness) {
          query.filter = {
            ...query.filter,
            $or: [{ siteId: { $exists: false } }, { siteId: { $eq: null } }],
          };
        }
      }

      if (this.filter.status === "defected") {
        query.filter.isDefected = { $eq: true };
      }

      if (this.filter.status === "disabled") {
        query.filter.isActive = { $eq: false };
      }

      if (this.selectedFilteredStatus !== 0) {
        query.filter.driveVehicle = { $eq: this.selectedStatus };
      }

      if (this.filter.status === "driver-pending") {
        query.filter.isConfirmedByBusiness = { $in: [false] };
        query.filter.source = { $eq: "app" };
      }

      if (this.filter.status === "duplicated") {
        const response = await this.$store.dispatch(
          `vehicle/getDuplicatedVehicleByEntityId`,
          entityId
        );
        this.vehicles = this.formatItem(response.results);
        this.isLoading = false;
        return;
      }

      if (Object.keys(this.multiFilter).length > 0) {
        query.filter = { ...this.multiFilter };
      }

      const vehicle = await this.$store.dispatch(
        `vehicle/getVehicleByEntityId`,
        { entityId, query }
      );

      if (vehicle) {
        if (vehicle?.metadata) {
          this.paginationSettings = formatPaginationSettings(vehicle?.metadata);
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const vehicleTableData = this.formatItem(vehicle.results);
        this.vehicles = vehicleTableData;

        this.$emit("updateLoading", false);
      } else {
        this.$emit("updateLoading", false);
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      await this.getCurrentVehicleCountForBusiness();
      this.isLoading = false;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    setSecurityAccess(securityRoles) {
      let initialReadVehicles = false;
      let initialWriteVehicles = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.vehicles.readVehicles?.value) {
          initialReadVehicles = securityConfig.vehicles.readVehicles.value;
        }
        if (securityConfig.vehicles.writeVehicles?.value) {
          initialWriteVehicles = securityConfig.vehicles.writeVehicles.value;
        }
      });
      return {
        readVehicles: initialReadVehicles,
        writeVehicles: initialWriteVehicles,
      };
    },
    vehicleReport: function () {
      this.$router.push({ path: "reports/vehicles" });
    },
    newVehicle: function () {
      if (this.totalAvailableSeat < 1 && this.isVehicleContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-vehicle-seat";
        this.modal.modalTitle = "Add Vehicle Seats";
      } else {
        this.modal.modalContent = "new";
        this.modal.modalTitle = "Add Vehicle";
        this.$refs.modal.openModal();
      }
    },
    newVehicleImport() {
      if (this.totalAvailableSeat < 1 && this.isVehicleContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-vehicle-seat";
        this.modal.modalTitle = "Add Vehicle Seats";
      } else {
        this.modal.modalContent = "new-vehicle-import";
        this.modal.modalTitle = "Import Vehicle";
        this.modal.modalSize = "2xl";
        this.$refs.modal.openModal();
      }
    },
    newFleetVehicle(id) {
      if (this.totalAvailableSeat < 1 && this.isVehicleContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-vehicle-seat";
        this.modal.modalTitle = "Add Vehicle Seats";
      } else {
        const targetVehicle = this.vehicles.find(
          (vehicle) => vehicle.id === id
        );

        const defaultDataPayload = {
          ...targetVehicle,
          fleetConnectionId: this.selectedFleetId,
          externalEntityId:
            this.$store.getters[`account/me`]?.business?.externalSiteId,
        };
        this.modal.modalData = defaultDataPayload;
        this.$refs.modal.openModal();
        this.modal.modalContent = "new-fleet-vehicle";
        this.modal.modalTitle = "Add Fleet Vehicle";
      }
    },
    openMergeVehicleModal(id) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "merge-fleet-vehicle";
      this.modal.modalTitle = "Merge Fleet Vehicle";
      const targetVehicle = this.vehicles.find((vehicle) => vehicle.id === id);
      this.modal.modalData = targetVehicle;
    },
    viewVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "view";
      this.modal.modalTitle = "Vehicle Info";
      this.$refs.modal.openModal();
    },
    editVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "edit";
      this.modal.modalTitle = "Vehicle Edit";
      this.$refs.modal.openModal();
    },
    disableVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "disable";
      this.modal.modalTitle = "Vehicle Disable";
      this.$refs.modal.openModal();
    },
    setAsDefect: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "defect";
      this.modal.modalTitle = "Vehicle Defect";
      this.$refs.modal.openModal();
    },
    // setOdometer: function (id) {
    //   this.selectedId = id;
    //   this.modal.modalContent = 'odometer';
    //   this.modal.modalTitle = 'Edit Odometer';
    //   this.$refs.modal.openModal();
    // },
    activateVehicle: function (id) {
      if (this.totalAvailableSeat.ewd < 1 && this.isDriverContractTarget) {
        this.$refs.modal.openModal();
        this.modal.modalContent = "add-driver-seat";
        this.modal.modalTitle = "Add Driver Seats";
      } else {
        this.selectedId = id;
        this.modal.modalContent = "activate";
        this.modal.modalTitle = "Vehicle Re-enable";
        this.$refs.modal.openModal();
      }
    },
    setAsNonDefect: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "non-defect";
      this.modal.modalTitle = "Vehicle Clear Defect";
      this.$refs.modal.openModal();
    },
    mergeVehicle: function (id) {
      this.selectedId = id;
      this.modal.modalContent = "merge";
      this.modal.modalTitle = "Merge Vehicles";
      this.$refs.modal.openModal();
    },
    moveVehicle(id) {
      this.selectedId = id;
      this.modal.modalContent = "move";
      this.modal.modalTitle = "Move Vehicle";
      this.$refs.modal.openModal();
    },
    notifyVehicle: function () {
      this.modal.modalContent = "notification";
      this.modal.modalTitle = "Vehicle Approval";
      this.modal.modalSize = "4xl";
      this.$refs.modal.openModal();
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    closeModal() {
      this.$refs.modal.closeModal();
    },
    showConfig: function () {
      this.$refs.configModal.openModal();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item._id,
            vehiclePlate: item?.vehiclePlate,
            registrationExpiry: formatDate(
              item?.registrationExpiry,
              "DD/MM/YYYY"
            ),
            gvm: item?.GMV,
            vehicleClass: item?.vehicleClass?.name,
            vehicleType: item?.vehicleType?.name,
            fleetId: item?.fleetId || "-",
            created: formatDate(item.createdAt),
            status: item?.isActive,
            lastKnownOdometer: item?.lastKnownOdometer || 0,
            siteId: this.filter.status == "all-sites" ? item?.siteId : null,
            source:
              item?.source === "app" ? "Driver Created " : "Business Created ",
          };
          nItems.push(vehicle);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseVehicles(data) {
      return data.map((vehicle) => {
        const mapped = [
          {
            id: vehicle.id,
            name: vehicle.vehiclePlate,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.registrationExpiry,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.fleetId,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.gvm,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.vehicleClass,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.vehicleType,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.created,
            itemType: "string",
          },
          {
            id: vehicle.id,
            name: vehicle.status,
            itemType: "toggle",
          },
        ];
        let allSiteHeader = {
          name: "Site",
          type: "text",
          fieldName: "depot.name",
          isFilterable: false,
        };

        if (this.filter.status === "all-sites") {
          if (!this.tableHeader.some((header) => header.name === "Site")) {
            this.tableHeader.unshift(allSiteHeader);
          }
          const depot = _.find(this.depots, (x) => x._id === vehicle.siteId);
          mapped.unshift({
            id: vehicle.id,
            name: depot?.name || "Main Business",
            itemType: "string",
          });
        } else {
          const index = this.tableHeader.findIndex(
            (allSiteHeader) => allSiteHeader.name === "Site"
          );
          if (index >= 0) this.tableHeader.splice(index, 1);
          if (this.filter.status === "duplicated") {
            mapped.push({
              id: vehicle.id,
              name: vehicle.source,
              itemType: "string",
            });
          }
        }
        return mapped;
      });
    },
    parseFleetVehicles(data) {
      return data.map((vehicle) => [
        {
          id: vehicle.id,
          name: vehicle.vehiclePlate,
          itemType: "string",
          isVehicleSynced: vehicle.isVehicleSynced,
        },
        {
          id: vehicle.id,
          name: vehicle.VIN,
          itemType: "string",
          isVehicleSynced: vehicle.isVehicleSynced,
        },
        {
          id: vehicle.id,
          name: vehicle.fleetId,
          itemType: "string",
          isVehicleSynced: vehicle.isVehicleSynced,
        },
        {
          id: vehicle.id,
          name: vehicle.isVehicleSynced,
          itemType: "boolean",
          isVehicleSynced: vehicle.isVehicleSynced,
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus =
        this.filterStatuses[this.selectedFilteredStatus].value;
      this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    async fetchTab(data) {
      this.multiFilter = {};
      switch (data) {
        case "Connected Fleets":
          this.filter.status = "connected-fleets";
          break;
        case "All Sites":
          this.filter.status = "all-sites";
          break;
        case "Active":
          this.filter.status = "active";
          break;
        case "Defected Vehicles":
          this.filter.status = "defected";
          break;
        case "Disabled Vehicles":
          this.filter.status = "disabled";
          break;
        case "Driver Pending Vehicles":
          this.filter.status = "driver-pending";
          break;
        case "Duplicated Vehicles":
          this.filter.status = "duplicated";
          break;
      }
      this.init();
    },

    async fetchFleetVehicles() {
      const response = await this.$store.dispatch("business/getFleetData", {
        businessId: this.me.businessId
          ? this.me.businessId
          : this.me?.business?._id,
        fleetConnectionId: this.selectedFleetId,
        dataType: "vehicle",
      });

      let vehicles = [];
      if (response.success) {
        this.paginationSettings = {
          page: 1,
          totalPages: 1,
          totalRecords: response.data.length,
          visiblePageItemCount: 100,
        };
        vehicles = response.data;
      } else {
        this.toast("error", response.message);
      }

      return vehicles;
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    async downloadVehicles() {
      const me = this.$store.getters[`account/me`];
      const business = me.business;

      const query = {
        skip: 0,
        limit: -1,
        filter: {},
      };

      const vehicles = await this.$store.dispatch(
        `vehicle/getVehicleByEntityId`,
        {
          entityId: me?.businessId,
          query,
        }
      );
      if (vehicles.results && vehicles.results.length > 1) {
        let vehicleJSON = [];
        vehicles.results.forEach((vehicle) => {
          vehicleJSON.push({
            vehiclePlate: vehicle.vehiclePlate ?? "",
            registrationExpiry:
              formatDate(vehicle.registrationExpiry, "DD/MM/YYYY") ?? "",
            GMV: vehicle.GMV ?? "",
            driveVehicle: vehicle?.driveVehicle ?? false,
            concreteAgitator: vehicle?.concreteAgitator ?? false,
            livestockVehicle: vehicle?.livestockVehicle ?? false,
            VIN: vehicle?.VIN ?? "",
            fleetId: vehicle?.fleetId ?? "",
            vehicleClass: vehicle.vehicleClass?.name ?? "",
            vehicleType: vehicle.vehicleType?.name ?? "",
          });
        });

        let csvString =
          "Plate Number,Registration Expiry,GVM (Tonnes),Drive Vehicle (Yes/No),Concrete Agitator (Yes/No),Livestock Vehicle (Yes/No),VIN (Optional),Fleet ID (Optional),Vehicle Class,Vehicle Type\r\n";
        csvString += convertJSONToCSV(JSON.parse(JSON.stringify(vehicleJSON)));
        downloadCSV(
          csvString,
          `${business.persona?.businessName
            .toLowerCase()
            .replace(/\s/g, "-")}-vehicles`
        );
        await this.toast("success", "CSV successfully generated.");
      } else {
        await this.toast("error", "No vehicles found.");
      }
    },
    async getCurrentVehicleCountForBusiness() {
      if (this.me?.contract?.contractTarget === "vehicle") {
        this.isVehicleContractTarget = true;
        //calculate total seats of business
        this.me?.business?.modules.map((module) => {
          if (module.apiTag.toLowerCase() == "ewd-ft") {
            this.maximumSeatCount.ewd.count = module.minimums;
            this.maximumSeatCount.ewd.isSubscribed = true;
          } else if (module.apiTag.toLowerCase() == "fatigue-compliance") {
            this.maximumSeatCount.fatigue.count = module.minimums;
            this.maximumSeatCount.fatigue.isSubscribed = true;
          } else if (module.apiTag.toLowerCase() == "km-time") {
            this.maximumSeatCount.km.count = module.minimums;
            this.maximumSeatCount.km.isSubscribed = true;
          }
        });
      }
      const me = this.$store.getters[`account/me`];
      const business = me.business;

      await this.$store
        .dispatch(`vehicle/getCurrentVehicleCountForBusiness`, {
          businessId: business._id,
        })
        .then((response) => {
          this.currentVehicleSeatCounts = response.data;
          const seatCount = calculateSeatCountAvailability(
            this.maximumSeatCount,
            this.currentVehicleSeatCounts
          );
          this.availableSeats = seatCount.remainingSeatCount;
          this.addImportAccess = seatCount.crudAccess;
          let accessArray = [];

          if (this.maximumSeatCount.ewd.isSubscribed == true) {
            accessArray.push(this.availableSeats.ewd);
          }
          if (this.maximumSeatCount.fatigue.isSubscribed == true) {
            accessArray.push(this.availableSeats.fatigue);
          }
          if (this.maximumSeatCount.km.isSubscribed == true) {
            accessArray.push(this.availableSeats.kmtime);
          }

          const subscribedAccess = accessArray.filter(
            (seat) => seat !== undefined
          );

          const availableSeat = Math.min(...subscribedAccess);

          this.totalAvailableSeat = availableSeat;

          // if(this.maximumSeatCount.ewd.isSubscribed == true) {
          //   this.availableSeats.ewd = this.maximumSeatCount.ewd.count - this.currentVehicleSeatCounts.ewdCount;
          //   if(this.availableSeats.ewd < 1) {
          //     this.addImportAccess.ewd = false
          //   }
          // }

          // if(this.maximumSeatCount.fatigue.isSubscribed == true) {
          //   this.availableSeats.fatigue = this.maximumSeatCount.fatigue.count - this.currentSeatCounts.fatigueCount;
          //   if(this.availableSeats.fatigue < 1) {
          //     this.addImportAccess.fatigue = false
          //   }
          // }
        });
    },

    configureDefaultMass() {
      this.$router.push({ path: "/business/vehicles/mass" });
    },

    configureVehicleMass(id) {
      this.selectedId = id;
      this.modal.modalContent = "mass";
      this.modal.modalTitle = "Vehicle Mass Configuration";
      this.$refs.modal.openModal();
    },

    async mergeFleetVehicle() {
      const payload = {
        fleetVehicleId: this.modal.modalData.id,
        businessId: this.me.businessId
          ? this.me.businessId
          : this.me?.business?._id,
        fleetConnectionId: this.selectedFleetId,
        dataType: "vehicle",
      };
      try {
        await this.$store.dispatch("vehicle/mergeFleetVehicle", payload);
        this.toast("success", "Merge successful");
        this.closeRefresh();
      } catch (error) {
        if (error?.statusCode === 404) {
          this.toast("error", error.message);
        } else {
          this.toast("error", "Something went wrong");
        }
      }
    },

    onSelectFleetConnection(data) {
      this.selectedFleetId = data?._id;
    },
  },
  computed: {
    vehiclesData() {
      if (this.vehicles?.length) {
        if (this.filter.status === "connected-fleets") {
          return this.parseFleetVehicles(this.vehicles);
        } else {
          return this.parseVehicles(this.vehicles);
        }
      } else return [];
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    isGeotabAccount() {
      return this.$store.getters["account/getIsGeotabAccount"];
    },
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
  watch: {
    isGeotabAccount(value) {
      if (value) {
        this.fetchTab("Connected Fleets");
      }
    },
    selectedFleetId(value) {
      if (value) {
        this.init();
      } else if (this.filter.status === "connected-fleets") {
        this.vehicles = [];
      }
    },
  },
};
</script>