<template>
  <div class="">
    <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
      <div v-if="loading" class="text-center">
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
      <div v-if="!loading">
        <div class="form border-b-2 border-neutral-100 mb-4">
          <template v-for="(module, index) in contract.businessModules">
            <div :key="index">
              <div v-if="module.name !== 'One API'">
                <div class="font-bold text-sm border-b-2 mb-2 border-neutral">
                  {{ module.name }}
                </div>

                <div class="flex justify-between items-center">
                  <div class="flex justify-start space-x-2 items-center mb-2">
                    <FormulateInput
                      type="number"
                      :name="`${addedSeats[index].module}-seat`"
                      v-model="addedSeats[index].value"
                      input-class="shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500"
                      outer-class="z-50"
                      validation="required|number|min:-100"
                      min="-100"
                      max="100"
                      error-behavior="live"
                      :disabled="submitLoading"
                    />
                    <div class="text-sm text-gray-800">new seat</div>
                  </div>
                </div>

                <div
                  class="font-bold text-sm border-b-2 mb-2 border-neutral"
                  style="display: none"
                >
                  {{ getProratedSeatAmount(module, index) }}
                </div>
              </div>
            </div>
          </template>
        </div>

        <div class="flex flex-col mb-4 pb-4 border-b-2 border-neutral-100">
          <div class="text-xs text-red-500">
            *This will be added to your contract after your Partner accepts it.
          </div>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon
                icon="spinner"
                class="mr-1 loader"
              />Submitting</span
            >
            <span v-if="!submitLoading">Submit</span>
          </FormulateInput>
        </StyledSubmit>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";
export default {
  name: "Manage-Seats-Request-Form",
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    currentSeatCounts: {
      type: Object,
      default: () => {},
    },
    totals: {
      type: Object,
      default: () => {},
    },
  },
  components: { StyledSubmit },
  data() {
    return {
      loading: false,
      values: {
        addedSeatCount: 1,
      },
      billPeriod: "",
      addedSeats: [],
      proratedDates: {},
      billTotal: 0,
      submitLoading: false,
    };
  },
  async created() {
    this.loading = true;
    let addedSeats = [...this.contract.businessModules];
    addedSeats.forEach((seat) => {
      this.addedSeats.push({
        contractModuleId: seat._id,
        module: seat.name,
        value: 0,
        total: 0,
      });
    });
    await this.calculateProratedAmount();
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.submitLoading = true;

      let payload = {
        addedSeats: this.addedSeats,
        businessId: this.contract.businessMongoId,
        contractId: this.contract._id,
        remainingDays: this.proratedDates.remainingDays,
        daysPassed: this.proratedDates.daysPassed,
      };

      if (this.proratedDates.remainingDays > 0) {
        payload.isProrated = true;
        payload.total = this.billTotal;
      } else {
        payload.isProrated = false;
        payload.total = 0;
      }

      this.$store
        .dispatch(`contract/createChangeSeatCountRequest`, payload)
        .then(() => {
          this.$emit("createdRequest", true);
        })
        .catch(async (err) => {
          console.error(err);
          this.$emit("createdRequest", false);
        })
        .finally(() => {
          this.submitLoading = true;
        });
    },

    getActualPrice(modules) {
      let amount;
      switch (this.contract.billingPeriod.name.toUpperCase()) {
        case "MONTHLY":
          this.billPeriod = "month";
          amount = modules.price.monthly.toFixed(2);
          break;
        case "QUARTERLY":
          this.billPeriod = "quarter";
          amount = modules.price.quarterly.toFixed(2);
          break;
        case "YEARLY":
          this.billPeriod = "year";
          amount = modules.price.yearly.toFixed(2);
      }
      return amount;
    },

    async calculateProratedAmount() {
      let cutOffDate, remainingDays, daysPassed, remainingDurationName;
      const lastPaymentDate = moment(this.contract.lastPaymentDate).format(
        "MM-DD-YYYY"
      );
      const currentDate = moment(new Date()).format("MM-DD-YYYY");
      if (this.contract.billingPeriod.name.toUpperCase() === "MONTHLY") {
        cutOffDate = moment(lastPaymentDate, "MM-DD-YYYY")
          .add(28, "days")
          .format("MM-DD-YYYY");
        remainingDays = moment(cutOffDate).diff(currentDate, "days");
        daysPassed = moment(currentDate).diff(lastPaymentDate, "days");
        remainingDurationName = "day(s)";
      } else {
        cutOffDate = moment(lastPaymentDate, "MM-DD-YYYY")
          .add(12, "months")
          .format("MM-DD-YYYY");
        remainingDays = moment(cutOffDate).diff(currentDate, "months");
        daysPassed = moment(currentDate).diff(lastPaymentDate, "months");
        remainingDurationName = "month(s)";
      }
      //if days passed is more than 28 days, there will be no prorated amount.

      this.proratedDates = {
        lastPaymentDate: lastPaymentDate,
        currentDate: currentDate,
        cutOffDate: cutOffDate,
        remainingDays: remainingDays,
        daysPassed: daysPassed,
        remainingDurationName: remainingDurationName, // days, day, months, month
      };
    },

    getProratedSeatAmount(module, index) {
      const actualPrice = this.getActualPrice(module);
      let totalProratedPrice = 0,
        perSeatPrice = 0,
        seatPricePerPeriod;

      // totalProratedPrice = parseFloat(actualPrice * this.addedSeats[index].value).toFixed(2);
      // perSeatPrice = parseFloat(actualPrice).toFixed(2);

      if (this.contract.billingPeriod.name.toUpperCase() === "YEARLY") {
        if (this.proratedDates.remainingDays === 12) {
          totalProratedPrice = (
            actualPrice * this.addedSeats[index].value
          ).toFixed(2);
          perSeatPrice = parseFloat(actualPrice).toFixed(2);
        } else {
          //get the pricing per month of the seat
          seatPricePerPeriod = actualPrice / 12;
          //get the seat price for each, based on the remaining months
          perSeatPrice = (
            seatPricePerPeriod * this.proratedDates.remainingDays
          ).toFixed(2);
          // get the computed price
          const computedPrice =
            seatPricePerPeriod * this.addedSeats[index].value;

          totalProratedPrice = (
            computedPrice * this.proratedDates.remainingDays
          ).toFixed(2);
        }
      } else {
        totalProratedPrice = (
          actualPrice * this.addedSeats[index].value
        ).toFixed(2);
        perSeatPrice = parseFloat(actualPrice).toFixed(2);
      }

      this.addedSeats[index].total = parseFloat(totalProratedPrice);
      this.addedSeats[index].unitPrice = parseFloat(perSeatPrice);
      this.addedSeats[index].actualPrice = parseFloat(actualPrice);

      return parseFloat(totalProratedPrice).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },

    getProratedTotalAmount() {
      let totalAmount = 0;
      this.addedSeats.forEach((data) => {
        totalAmount += data.total;
      });

      this.billTotal = totalAmount;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  watch: {
    addedSeats: {
      handler: function () {
        this.getProratedTotalAmount();
      },
      deep: true,
    },
  },
};
</script>
