<template>
  <div class="w-full min-h-full flex-row items-center">
    <div class="" v-if="noContract">
      <div class="flex bg-white shadow-md rounded w-full p-4">
        <div
          class="flex flex-row justify-center items-center space-x-4 p-4 w-full"
        >
          <div class="text-lg font-medium">
            No contract found. Make sure you have an active contract.
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="flex w-full mt-5">
        <SeatChart :is-loading="isLoading" />
      </div>

      <!-- Seat count -->
      <div class="w-full flex flex-row items-center justify-between mt-4">
        <SeatDetails
          :seats="seats"
          :seats-total="seatsTotal"
          @onAddSeats="addSeats"
          :is-loading="isLoading"
        />
      </div>
    </div>

    <Modal v-if="isProcessingPayment" ref="modal" size="3xl">
      <div class="flex flex-col w-full justify-center items-center p-8">
        <div class="text-medium">Processing payment...</div>
        <Spinner class="m-8" />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal } from "@/components/commons";
import SeatDetails from "@/components/licence-management/SeatDetails";
import SeatChart from "@/components/licence-management/SeatChart";
import Spinner from "@/components/commons/ui/Spinner";

export default {
  components: {
    SeatDetails,
    SeatChart,
    Modal,
    Spinner,
  },
  data() {
    return {
      seats: [
        {
          id: "drivers",
          name: "Drivers",
          count: 0,
          isActive: false,
        },
        {
          id: "vehicles",
          name: "Vehicle",
          count: 0,
          isActive: false,
        },
        {
          id: "contract",
          name: "Contract",
          count: 0,
        },
        {
          id: "extra",
          name: "Extra",
          count: 0,
        },
      ],
      seatsTotal: {
        total: 0,
        remaining: 0,
        used: 0,
      },
      isProcessingPayment: true,
      businessID: null,
      filter: {
        limit: 10,
        search: "",
      },
      contractData: {},
      driverSeatCount: 0,
      vehicleSeatCount: 0,
      contractSeatCount: 0,
      extraSeatCount: 0,
      isLoading: false,
      noContract: true,
    };
  },

  async mounted() {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readLicenceManagement = securityAccessObj.readLicenceManagement;
      this.writeLicenceManagement = securityAccessObj.writeLicenceManagement;
    }
    await this.init();

    this.$emit("updateLoading", false);
  },

  methods: {
    addSeats() {
      this.$refs.modal.openModal();
    },
    async init(paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      this.businessID = me?.business?._id;

      let licences;

      const businessLicenceManagement = await this.$store.dispatch(
        "contract/getBusinessContract",
        { businessId: this.businessID, query }
      );
      if (businessLicenceManagement.results) {
        licences = businessLicenceManagement.results;

        this.contractData = licences[0];

        this.contractSeatCount = this.contractData.businessModules[0].minimums;

        await this.getContractByBusiness(query);

        await this.getVehicleByUID(query);

        let activeContractSeat;

        await this.seats.forEach((data) => {
          if (data.id === "drivers") {
            if (this.driverSeatCount < this.vehicleSeatCount) {
              activeContractSeat = this.driverSeatCount;
              data.isActive = true;
            } else {
              data.isActive = false;
            }
            data.count = this.driverSeatCount;
          } else if (data.id === "vehicles") {
            if (this.driverSeatCount > this.vehicleSeatCount) {
              activeContractSeat = this.vehicleSeatCount;
              data.isActive = true;
            } else {
              data.isActive = false;
            }
            data.count = this.vehicleSeatCount;
          } else if (data.id === "contract") {
            data.count = this.contractSeatCount;
          } else if (data.id === "extra") {
            data.count = this.extraSeatCount;
          }
        });

        const totalSeatCount = this.contractSeatCount + this.extraSeatCount;

        this.seatsTotal.total = totalSeatCount;
        this.seatsTotal.remaining = totalSeatCount - activeContractSeat;
      } else {
        this.noContract = true;
      }

      this.isLoading = false;
    },

    async getContractByBusiness(query) {
      let driverContract = await this.$store.dispatch(
        `business/getContractByBusiness`,
        { uid: this.businessID, query }
      );
      if (driverContract) {
        if (driverContract?.metadata) {
          this.driverSeatCount = driverContract.metadata?.total;
        }
      }
    },

    async getVehicleByUID(query) {
      const businessUID = this.$store.getters[`account/me`].uid;

      const vehicle = await this.$store.dispatch(`vehicle/getVehicleByUID`, {
        businessUID: businessUID,
        query,
      });
      if (vehicle) {
        if (vehicle?.metadata) {
          this.vehicleSeatCount = vehicle.metadata?.total;
        }
      }
    },

    setSecurityAccess(securityRoles) {
      let iReadContractTerms = false;
      let iWriteContractTerms = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.contractTerms?.readContractTerms?.value) {
          iReadContractTerms =
            securityConfig.contractTerms.readContractTerms.value;
        }
        if (securityConfig.contractTerms?.writeContractTerms?.value) {
          iWriteContractTerms =
            securityConfig.contractTerms.writeContractTerms.value;
        }
      });
      return {
        readLicenceManagement: iReadContractTerms,
        writeLicenceManagement: iWriteContractTerms,
      };
    },
  },
};
</script>
