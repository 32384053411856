<template>
  <div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="text-center text-xl -mt-3 mb-3 font-bold">Single sign-on</div>
    <FormulateForm name="ssoForm" v-model="payload" @submit="onSubmit">
      <FormulateInput
        type="text"
        name="email"
        label="Email"
        error-behavior="submit"
        validation="required|email"
      />
      <div class="items-center grid grid-cols-2 gap-4 justify-between">
        <FormulateInput
          type="submit"
          :label="!isLoading ? 'Submit' : 'Submitting..'"
          :disabled="isLoading"
          input-class="btn btn-primary w-full"
        />
        <button
          @click.prevent="resetForm"
          class="btn mb-3 bg-gray-400 hover:bg-gray-500 text-white w-full"
        >
          Cancel
        </button>
      </div>
    </FormulateForm>
    <div
      class="text-red-500 font-bold text-sm text-center mt-0 my-5"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SSOLogin",
  data() {
    return {
      success: false,
      error: false,
      errorMessage: "",
      payload: { email: "" },
      isLoading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      try {
        const { url } = await this.$store.dispatch(
          `account/getSSOLoginURL`,
          this.payload
        );
        window.location.href = url;
      } catch (error) {
        this.error = true;
        this.errorMessage = error.message;
      }
      this.isLoading = false;
    },
    resetForm() {
      this.$emit("toggleForm", "login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
