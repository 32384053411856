<template>
  <div>
    <FormulateForm v-model="values" #default="{ hasErrors }">
      <div v-if="loading" class="text-center">
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
      <FormulateInput
        type="text"
        name="name"
        label="Connection Name"
        validation="required"
      />
      <div>
        <FormulateInput
          type="text"
          name="username"
          label="Service Account"
          validation="required"
        />
        <FormulateInput
          type="password"
          name="password"
          label="Password"
          validation="required"
        />
        <FormulateInput
          type="password"
          name="confirmPassword"
          label="Confirm Password"
          validation="required"
        />
        <FormulateInput
          v-if="preData.provider && preData.provider === 'geotab'"
          type="text"
          name="database"
          label="Database"
        />
        <FormulateInput
          v-if="preData.provider && preData.provider === 'geotab'"
          type="text"
          name="server"
          label="Server"
          validation="required"
        />
        <FormulateInput
          v-if="preData.provider && preData.provider === 'mix'"
          type="text"
          name="clientId"
          label="Client ID"
          validation="required"
        />
        <FormulateInput
          v-if="preData.provider && preData.provider === 'mix'"
          type="password"
          name="clientSecret"
          label="Client Secret"
          validation="required"
        />
        <FormulateInput
          v-if="preData.provider && preData.provider === 'verizon'"
          type="text"
          name="appId"
          label="Application ID"
          validation="required"
        />
      </div>
      <div class="flex flex-row">
        <button
          @click.prevent="backStep()"
          class="w-1/2 px-2 mr-2 btn btn-primary"
          :style="{
            background: theme.primaryColor,
            color: 'white',
          }"
        >
          Back
        </button>
        <button
          :disabled="hasErrors"
          @click.prevent="onSubmit()"
          class="w-1/2 px-2 ml-2 btn btn-primary"
          :style="{
            background: theme.secondaryColor,
            color: 'white',
          }"
        >
          Submit
        </button>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Fleet-Details",
  components: {},
  props: {
    preData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      values: {},
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      const businessId = me.businessId ? me.businessId : me?.business?._id;
      const {
        name,
        password,
        confirmPassword,
        database,
        server,
        username,
        clientId,
        clientSecret,
        appId,
      } = this.values;
      try {
        await this.$store.dispatch("business/createFleetConnection", {
          businessId,
          name,
          password,
          confirmPassword,
          database,
          server,
          username,
          provider: this.preData.provider,
          clientId,
          clientSecret,
          appId,
        });
        this.$emit("nextStep");
      } catch (error) {
        const errorMessage = error.errors?.message
          ? error.errors.message
          : "Fleet connection creation failed";

        this.toast("error", errorMessage);
      }

      this.loading = false;
    },
    backStep() {
      this.$emit("backStep");
    },
    toast(state, message) {
      this.$emit("toast", state, message);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
