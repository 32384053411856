<template>
  <div class="form-builder noselect">
    <div class="w-full min-h-full flex-row items-center">

      <header>
        <FormulateForm v-model="activeFormData" @submit="submitForm" name="formBuilder"
            class="flex flex-wrap -mx-3 mb-6 items-stretch md:items-center">
          <div class="flex h-24	items-center border-b pb-4 pl-5">
            <div class="w-48 relative mt-3 mb-5">
              <button v-if="!submitting" class="w-48 btn btn-primary text-sm text-blue-300" type="submit"
                  :style="{backgroundColor: theme.secondaryColor}">
                <font-awesome-icon
                    :icon="['fas', 'save']"
                />&nbsp;
                Save
              </button>
              <button v-else type="button"
                  class="w-48 text-sm cursor-not-allowed p-4 opacity-70 btn btn-primary rounded focus:outline-none"
                  disable :style="{backgroundColor: theme.secondaryColor}">
                <font-awesome-icon
                    :icon="['fas', 'spinner']"
                    spin
                />&nbsp;
                Saving...
              </button>
            </div>
            <label class="block uppercase tracking-wide text-xs font-bold mb-2 ml-4"
                :style="{color: theme.primaryColor}">
              Name
            </label>
            <FormulateInput
                placeholder="Name"
                required
                name="name"
                class="block h-12 w-full px-4 ml-4 mb-3 mt-2 leading-tight relative"
                autocomplete="off"
            />
            <label v-if="!editMode && !isEditMaster" class="block uppercase tracking-wide text-xs font-bold mb-2 ml-4"
                :style="{color: theme.primaryColor}">
              Type
            </label>
            <FormulateInput
                v-if="!editMode && !isEditMaster"
                placeholder="--please select--"
                name="formType"
                validation="required"
                validation-error="required"
                class="block h-12 w-full px-4 ml-4 mb-3 mt-2 leading-tight relative"
                autocomplete="off"
                type="select"
                :options="typeOption"
                v-model="formType"
            />
            <label v-if="!editMode && !isEditMaster" class="block uppercase tracking-wide text-xs font-bold mb-2 ml-4"
                :style="{color: theme.primaryColor}">
              Template
            </label>
            <FormulateInput
                v-if="!editMode && !isEditMaster"
                placeholder="--please select--"
                class="block h-12 w-full px-4 ml-4 mb-3 mt-2 leading-tight relative"
                autocomplete="off"
                type="select"
                :options="typeTemplateOption"
                v-model="formTemplate"
                @change="onChangeTemplateType($event)"
            />
          </div>
        </FormulateForm>
      </header>

      <main class="flex w-full mt-5">

        <section class="w-80 overflow-y-auto pb-20" style="height: 600px;">
          <ControlContainer class="h-full border-r"/>
        </section>

        <section class="w-full">
          <div class="flex flex-col">
            <div class="flex justify-between items-center border-b mb-2" v-if="currentSection">
              <p class="text-sm p-4" :style="{color: theme.primaryColor}">Form</p>
            </div>
            <div class="p-4">
              <div class="overflow-y-auto px-3 pb-20" style="height: 640px;">
                <SectionContainer
                    v-for="(section, index) in sortedSections" :key="index" class="mb-4"
                    :section="section"
                    :uniqueId="section.uniqueId"
                    @onSelectComponent="onSelectComponent"
                    @updateCurrentSectionHeaders="updateCurrentSectionHeaders"
                />
                <AddSectionControl @addSection="addSection" class="mb-4"/>
              </div>
            </div>
          </div>
        </section>

        <section class="overflow-y-auto px-3  pb-20" style="height: 600px;width: 400px">
          <SettingsContainer
              class="h-full border-l"
              :selected-control="selectedControl.controls"
              :currentSection="currentSection"
              :currentControl="currentControl"
              :uniqueId="controlUniqueId"
              @controlChanged="updateControlFromSettings"
              @updateFormControl="onUpdateFormControl"
          />
        </section>

      </main>

    </div>
  </div>
</template>
<script>
import ControlContainer from '@/views/builder/containers/ControlContainer'
import SettingsContainer from '@/views/builder/containers/SettingsContainer'
import SectionContainer from '@/views/builder/containers/SectionContainer'
import AddSectionControl from '@/views/builder/add-controls/AddSectionControl'
import { mapGetters } from 'vuex'
import { HELPER } from '@/api/helper'
import { FORM_TYPES, FORM } from '@/_helper/constants'
import _ from 'lodash'

export default {
  components: {
    ControlContainer,
    SectionContainer,
    SettingsContainer,
    AddSectionControl
  },
  props: {
    editMode: {
      type: Boolean,
      required: false,
      default: false,
    },
    pulledFormData: {
      type: Object,
      required: false,
      default: null,
    },
    _id: {
      type: String,
      required: false,
      default: null,
    },
    isForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    isBusiness: {
      type: Boolean,
      required: false,
      default: false,
    },
    isContractTerms: {
      type: Boolean,
      required: false,
      default: false,
    },
    isFormMaster: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditMaster: {
      type: Boolean,
      required: false,
      default: false,
    },
    typeOption: {
      type: Array,
      default: () => [
        { value: FORM.INCIDENT.key, label: FORM.INCIDENT.label },
        { value: FORM.PRESTART.key, label: FORM.PRESTART.label },
        { value: FORM.FITNESS.key, label: FORM.FITNESS.label },
        { value: FORM.HAZARD.key, label: FORM.HAZARD.label },
        { value: FORM.INFRINGMENT.key, label: FORM.INFRINGMENT.label },
        { value: FORM.DEFECT.key, label: FORM.DEFECT.label },
      ]
    },
    typeTemplateOption: {
      type: Array,
      default: () => [
        { value: 'blank', label: 'Blank' },
        { value: FORM.INCIDENT.key, label: FORM.INCIDENT.label },
        { value: FORM.PRESTART.key, label: FORM.PRESTART.label },
        { value: FORM.FITNESS.key, label: FORM.FITNESS.label },
        { value: FORM.HAZARD.key, label: FORM.HAZARD.label },
        { value: FORM.INFRINGMENT.key, label: FORM.INFRINGMENT.label },
        { value: FORM.DEFECT.key, label: FORM.DEFECT.label },
      ]
    },
  },
  data: () => ({
    formData: {
      formConfig: {},
      sections: {},
      controls: {},
    },
    formType: 'prestart',
    formTypes: FORM_TYPES,
    formTemplate: 'prestart',
    formMasterId: null,
    sectionDefaultData: {
      uniqueId: '',
      headline: '',
      subHeadline: '',
      isShowHeadline: true,
      sortOrder: 0,
      type: '',
      controls: []
    },
    sortedSections: [],
    selectedControl: {},
    updatedControl: {},
    submitting: false,
    sectionLoaded: false,
    controlUniqueId: null,
    currentSection: null,
    currentControl: null
  }),
  computed: {
    activeFormData: {
      get () {
        return this.pulledFormData
      },
      set (newSavedForm) {
        return newSavedForm;
      }
    },
    sections: {
      get () {
        return this.$store.state.form.formSections
      },
      set (newSections) {
        return newSections;
      }
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
  watch: {
    sections: {
      deep: true,
      handler (newVal) {
        this.sortedSections = newVal
      }
    },
    pulledFormData: {
      deep: true,
      handler () {
        this.populateEdit()
      }
    },
    currentSection: {
      deep: true,
      handler (newVal) {
        this.currentSection = newVal
      }
    }
  },
  beforeMount () {
    this.$store.commit('form/setFormSections', [])
    this.$store.commit('form/setSelectedControl', {})
    this.$store.commit('form/setSelectedControlSection', {})
  },
  created () {
    this.$emit("updateLoading", true)
    if (this.isForm || this.isFormMaster) {
      this.formType = this.$route.params.formType || this.pulledFormData?.formType;
      const formType = this.formTypes.find(item => item == this.formType)

      if (formType) {
        this.formTemplate = formType
        if (!formType) window.location.href = "/error-404"
      }

      this.onChangeTemplateType(null, true)

      this.$emit("updateLoading", false)
    }
  },
  methods: {
    populateEdit () {
      if (this.editMode) {
        const sections = this.pulledFormData?.sections;
        this.$store.commit('form/setFormSections', sections)
      }
    },

    updateControlFromSettings (data) {
      this.updatedControl = HELPER.cloneDeep(data);
    },

    async onUpdateFormControl (data) {
      const sections = HELPER.cloneDeep(this.$store.state.form.formSections)

      const section = sections.find((obj) => obj.uniqueId == data.sectionUniqueId)

      section?.controls.forEach((control) => {
        if (control.uniqueId == data.control?.uniqueId) {
          Object.assign(control, data.control)
        }
      })
      const sectionIndex = sections.findIndex((obj) => obj.uniqueId == data.sectionUniqueId)

      sections[sectionIndex] = section

      this.currentSection = section
      this.$store.commit('form/setFormSections', sections)
    },


    async addSection (sectionType) {
      let newSortOrder = this.sortedSections.length + 1
      let sectionObject = await this.createNewSection(sectionType, newSortOrder)

      await this.updateSections(sectionObject)
    },

    updateSections (sectionObject) {
      const sortedSections = this.$store.state.form.formSections
      sortedSections.push(sectionObject)
      sortedSections.sort(function (a, b) {
        return a.sortOrder - b.sortOrder;
      })
      if (!sortedSections) {
        this.$store.commit('form/setSelectedControlSection', sortedSections[0])
      }
      this.$store.commit('form/setFormSections', sortedSections)
    },

    onSelectComponent (data) {
      const section = this.sections.find((obj) => obj.uniqueId === data.sectionId)

      section.controls.map((obj) => {
        if (obj.uniqueId !== data.selectedControl.uniqueId) {
          obj.selected = false
        } else {
          obj.selected = true
        }
      })

      const otherSections = this.sections.filter((obj) => obj.uniqueId !== data.sectionId)

      for (let sect of otherSections)
        sect.controls.map((obj) => {
          obj.selected = false
        })

      this.currentSection = section
      this.controlUniqueId = data.selectedControl.uniqueId
      this.currentControl = data.selectedControl
      this.$store.commit('form/setSelectedControl', data.selectedControl)
    },

    createNewSection (type, sortOrder = 0) {
      let newSectionData = HELPER.cloneDeep(this.sectionDefaultData)
      newSectionData.type = type;
      newSectionData.uniqueId = 'section-' + HELPER.getUUIDv4();
      newSectionData.headline = 'New Section';
      newSectionData.subHeadline = 'This is the sub-headline of the new section';
      newSectionData.sortOrder = sortOrder;
      return newSectionData;
    },

    async submitForm (data) {
      this.submitting = true;
      const sections = this.$store.state.form.formSections;

      if (!sections[0]?.controls.length) {
        this.toast('error', 'Form must have at least one section control!');
        this.submitting = false;
      } else {
        const me = this.$store.getters[`account/me`];
        const businessId = me?.business?._id;
        const proxySite = this.$store.getters[`account/proxySite`];
        const isProxySite = !_.isEmpty(proxySite) && _.isObject(proxySite) && !proxySite.isMainBusiness;

        const form = {
          _id: this._id || null,
          name: data.name,
          formType: data.formType || this.formType,
          businessId: businessId,
          sections: sections,
          version: data.version,
          isActive: true,
          partnerUid: null,
          resellerUid: null,
        }

        if(isProxySite) { form.siteId = proxySite?._id; }

        if (me?.role?.name === "partner") {
          form.partnerUid = me?.uid;
        } else if (me?.role?.name === "reseller") {
          form.resellerUid = me?.uid;
        }

        if (this.isForm) {
          const resData = await this.$store.dispatch('form/createForm', form)
              .catch((err) => {
                this.toast('error', err.message);
                this.submitting = false
              })

          if (!_.isEmpty(resData)) {
            this.submitting = false
            this.$store.dispatch('form/clearForm')
            this.$formulate.reset('formBuilder')
            this.toast('success', 'Form successfully created.')

          }
        }

        if (!this.editMode) {
          if (this.isFormMaster) {
            await this.$store.dispatch('form/createFormMaster', form)
                .then((res) => {
                  this.$emit('formSubmitted', res);
                  this.submitting = false;
                  this.$store.dispatch('form/createForm');
                  this.$formulate.reset('formBuilder')
                  this.toast('success', 'Form master successfully created.')
                }).catch(err => {
                  this.toast('error', err.message);
                  this.submitting = false;
                })
          }

          if (this.isContractTerms) {
            form.businessId = null;
            await this.$store.dispatch('form/createContractTermForm', form)
                .then((res) => {
                  this.$emit('formSubmitted', res);
                  this.submitting = false;
                  this.$store.dispatch('form/createForm');
                  this.$formulate.reset('formBuilder')
                  this.toast('success', 'Contract term successfully created.')
                }).catch(err => {
                  this.toast('error', err.message);
                  this.submitting = false;
                })
          }
        } else {

          if (this.isContractTerms) {
            await this.$store.dispatch('form/updateContractTermForm', form)
                .then((res) => {
                  this.$emit('formSubmitted', res);
                  this.submitting = false;
                  this.$store.dispatch('form/createForm');
                  // window.location.href = `/business/contracts/terms-forms`;
                }).catch(err => {
                  this.toast('error', err.message);
                  this.submitting = false;
                })
          }
        }
      }
    },
    toast (state, msg) {
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true)
      this.$store.commit('setMessageNotify', message)

      if (state == 'success') {
        setTimeout(() => {
          this.$router.push({
            path: `/business/fatigue-management/form/`
          })
        }, 200)
      }
    },
    async onChangeTemplateType (event, newLoad) {
      const formType = event ? event.target.value : this.formTemplate
      if (newLoad) {
        await this.pullFormType(formType)

      } else {
        if (confirm("Are your sure? It will clear/replace charges.")) {
          await this.pullFormType(formType)
        }
      }
      this.$store.commit('form/setSelectedControl', {})
    },
    async pullFormType (formType) {
      if (!this.editMode) {
        await this.$store.dispatch('form/getActiveFormMasterByType', formType)
            .then((res) => {
              const sections = res?.sections ? res.sections : [];
              this.$store.commit('form/setFormSections', sections)
            })
      }
    },
    async updateCurrentSectionHeaders (data) {
      const sections = await this.$store.state.form.formSections
      sections.forEach((section) => {
        if (section.uniqueId == data.uniqueId) {
          section.headline = data.headline
          section.subHeadline = data.subHeadline
        }
      })
      this.$store.commit('form/setFormSections', sections)
    }
  }
}
</script>
<style>
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}
</style>