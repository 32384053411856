<template>
  <div class="bg-black text-white">
    <div class="container py-3">
      <div class="grid grid-cols-2 gap-4">
        <div>
          <a :href="$store.getters['baseURL']"><img src="assets/images/logo.png" alt="" class="max-w-xs"></a>
        </div>
        <div class="flex">
          <div class="nav my-auto">
            <ul class="flex">
              <li class="mr-6 py-1 border-orange-primary">
                <a class="hover:font-bold" href="#">Light Vehicle</a>
              </li>
              <li class="mr-6 py-1 border-orange-primary">
                <a class="hover:font-bold" href="./contact-us">Contact Us</a>
              </li>
              <li class="mr-6 py-1 border-b-2 border-orange-primary font-bold hidden">
                <div class=" relative inline-block text-left dropdown">
                  <button>Contact <font-awesome-icon icon="chevron-down" class="ml-1 text-xs" /></button>
                  <div class="opacity-0 invisible dropdown-menu transition-all duration-300 transform origin-top-right -translate-y-2 scale-95">
                    <div class="absolute right-0 w-56 mt-2 origin-top-right bg-gray-800 border border-gray-700 divide-y divide-gray-100 shadow-lg outline-none" aria-labelledby="headlessui-menu-button-1" id="headlessui-menu-items-117" role="menu">
                      <div class="">
                        <a href="#" tabindex="0" class="text-white flex justify-between w-full px-4 py-2 text-sm leading-5 text-left border-b border-gray-700" role="menuitem">Record Keeper Account</a>
                        <a href="#" tabindex="1" class="text-white flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"  role="menuitem">Solo Driver</a>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="my-auto flex">
            <a href="./login" v-if="!isLogged"><button class="btn hover:bg-orange btn-light mx-3">HV Login</button></a>
            <a :href="portalURL" v-if="isLogged"><button class="btn hover:bg-orange btn-light mx-3">Portal</button></a>
            <div class="inline text-3xl my-auto">
              <a href="#" class="mx-2 hover:text-orange-primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'google-play' }" /></a>
              <a href="#" class="mx-2 hover:text-orange-primary"><font-awesome-icon :icon="{ prefix: 'fab', iconName: 'app-store-ios' }" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueCookies from 'vue-cookies'

export default {
  name: 'Header',
  props: {
    msg: String
  },
  data(){
    return { isLogged: false, portalURL:'/' }
  },
  async mounted(){
    const isLogged = await this.$store.dispatch(`account/isLogged`);
    this.isLogged = isLogged;
    if(isLogged){
      const me = await this.$store.dispatch(`account/getMe`);
      if(!me){
        if(VueCookies.keys()){
          await VueCookies.keys().forEach(key => {
                VueCookies.remove(key)
        })
        }
         this.portalURL = './login'
      }
      
      if(me['role']){
        this.portalURL = './'+me['role']['name']
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dropdown:focus-within button svg {
  @apply transform rotate-180
}
.dropdown:focus-within .dropdown-menu {
  opacity:1;
  transform: translate(0) scale(1);
  visibility: visible;
}
</style>
