<template>
  <div class="mb-16">
    <FormulateForm
      name="editBusinessForm"
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
    >
      <div>
        <FormulateInput
          type="group"
          name="certificate"
          label="Accreditation (Optional)"
          labelClass="font-semibold text-sm block -top-7 -left-0 absolute"
          class="relative p-5 mt-10 mb-5 border rounded"
        >
          <button @click.prevent="showDriverList">
            <small
              v-if="!isDriverListOpen"
              class="px-3 rounded-lg shadow btn-primary btn-xs"
            >
              + Select from accreditation list
            </small>
            <small
              v-else
              class="px-3 text-white bg-blue-200 rounded-lg shadow btn-xs"
            >
              <font-awesome-icon icon="times" />
            </small>
          </button>

          <FormulateInput
            v-if="!isDriverListOpen"
            type="text"
            label="BFM Number"
            v-model="BFMStringBased"
            valdation="optional"
          />

          <FormulateInput
            v-else
            type="cert-select"
            name="BFMNumber"
            style="margin-bottom: 0px"
            label="BFM Number"
            class="content-center pb-4"
            validation="optional"
            @input="onChange($event)"
            v-model="BFMselectionBased"
            :clearable="false"
          />

          <FormulateInput
            v-if="isDriverListOpen"
            type="date"
            name="inductionDate"
            label="Induction Date"
            validation="optional"
            v-model="inductionDate"
          />
          <FormulateInput
            type="afm-access-select"
            placeholder="Select an AFM Number"
            @input="selectAFMNumber($event)"
          />
        </FormulateInput>
      </div>

      <div class="items-center justify-between">
        <button
          type="submit"
          class="w-full btn btn-primary"
          :style="{ backgroundColor: theme.secondaryColor }"
          :disabled="loading"
        >
          {{ !loading ? "Submit" : "Submitting.." }}
        </button>
      </div>
    </FormulateForm>

    <div class="mb-3 text-center text-red-500" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "./business-profile-create-schema";
import moment from "moment";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Add-Business-Profile-Form",
  props: {
    msg: String,
    isBusinessDriverProfile: {
      type: Boolean,
      default: false,
    },
    preData: null,
  },
  data() {
    this.$emit("schema", schema);
    return {
      driverId: null,
      newDriver: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      isDriverListOpen: false,
      BFMStringBased: "",
      BFMselectionBased: {},
      inductionDate: "",
      selectedAFMNumber: null,
    };
  },

  watch: {
    isDriverListOpen: function () {
      this.BFMselectionBased = {};
      this.inductionDate = "";
    },
  },

  mounted() {
    if (!_.isEmpty(this.preData)) {
      if (!_.isEmpty(this.preData["linkDriver"])) {
        this.driverId = this.preData["linkDriver"];
      }
      if (!_.isEmpty(this.preData["newDriver"])) {
        this.newDriver = this.preData["newDriver"];
      }
    }
  },
  methods: {
    async onSubmit() {
      this.errorMessage = "";
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      const entityID = me?.businessId;

      if (
        (this.isDriverListOpen &&
          !_.isEmpty(this.BFMselectionBased) &&
          _.isEmpty(this.inductionDate)) ||
        (!_.isEmpty(this.inductionDate) && _.isEmpty(this.BFMselectionBased))
      ) {
        this.errorMessage = "Accreditation details incomplete";
        this.loading = false;
        return;
      }

      let newContract = JSON.parse(JSON.stringify(this.values));

      newContract["profile"] = newContract["profile"][0];
      newContract["profile"]["recordKeeperAddress"] =
        newContract["profile"]["recordKeeperAddress"][0][
          "record-keeper-street"
        ];
      newContract["profile"]["baseLocation"] =
        newContract["profile"]["baseLocation"][0]["base-location-street"];

      if (this.selectedAFMNumber != null) {
        newContract["profile"]["afmAccessId"] = this.selectedAFMNumber._id;
        newContract["profile"]["AFMNumber"] = this.selectedAFMNumber.afmNumber;
      }

      const proxySite = this.$store.getters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite))
        newContract["siteId"] = proxySite._id;
      newContract["businessId"] = entityID;

      if (this.driverId) {
        newContract["driverId"] = this.driverId.id;
        this.submitNewContract(newContract);
      } else {
        if (this.newDriver) {
          this.newDriver["entityId"] = entityID;
          this.newDriver["isDemoAccount"] = false;
          this.newDriver["engineRegion"] =
            newContract["profile"]["engineRegion"];
          if (this.isBusinessDriverProfile)
            this.newDriver["isBusinessDriverProfile"] = true;

          await this.$store
            .dispatch(`driver/createDriver`, this.newDriver)
            .then(async (driver) => {
              if (driver && driver._id) {
                newContract["driverId"] = driver._id;

                await this.submitNewContract(newContract);
                if (
                  newContract["profile"] &&
                  this.isDriverListOpen &&
                  !_.isEmpty(this.inductionDate) &&
                  !_.isEmpty(this.BFMselectionBased)
                ) {
                  const assignCertPayload = {
                    driverIds: driver._id,
                    inductionDate: this.inductionDate,
                  };
                  await this.$store.dispatch("accreditation/assignDriver", {
                    data: assignCertPayload,
                    certId: this.BFMselectionBased._id,
                  });

                  this.$emit(
                    "toast",
                    "success",
                    "Driver creation successfully"
                  );
                }
              }
            })
            .catch((error) => {
              this.loading = false;
              this.errorMessage = error?.message;
              this.$emit("toast", "success", "Driver creation failed");
            });
        }
      }
    },
    async submitNewContract(data) {
      data["from"] = "business";
      data["startDate"] = moment();
      data["profile"]["BFMSolo"] = data["profile"]["BFMSolo"] == true;
      data["profile"]["BFM2up"] = data["profile"]["BFM2up"] == true;
      data["profile"]["BFMNumber"] = "";

      if (!this.isDriverListOpen && !_.isEmpty(this.BFMStringBased.trim())) {
        const certificate = [
          {
            BFMNumber: this.BFMStringBased,

            inductionDate: null,
          },
        ];
        data["profile"]["certificate"] = certificate;
        data["profile"]["BFMNumber"] = this.BFMStringBased;
      } else {
        const certificate = [
          {
            BFMNumber: this.BFMselectionBased.certNumber,
            inductionDate: this.inductionDate,
          },
        ];
        data["profile"]["certificate"] = certificate;
        data["profile"]["BFMNumber"] = this.BFMselectionBased.certNumber;
      }

      delete data["certificate"];

      await this.$store
        .dispatch(`business/createContract`, data)
        .then(async () => {
          if (this.isBusinessDriverProfile) {
            await this.autoAcceptInvitation(data.driverId);
          }

          this.loading = false;
          this.$emit("closeRefresh");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error?.message;
        });
    },
    async autoAcceptInvitation(driverId) {
      await this.$store.dispatch(`driver/acceptDriver`, driverId);
    },

    onChange(data) {
      if (data) {
        this.BFMselectionBased = data;
      }
    },

    showDriverList() {
      this.isDriverListOpen = !this.isDriverListOpen;
    },
    selectAFMNumber(data) {
      this.selectedAFMNumber = data;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
</style>