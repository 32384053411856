<template>
  <div>
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="text-red-500 text-center mb-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="text-center text-xl - mt-3 mb-5 font-bold">
      {{ formTitle }}
    </div>

    <div v-if="!loading">
      <div class="grid-container mb-5">
        <div
          v-for="(partner, index) in filteredPartners"
          :key="index"
          class="text-center text-l text-black-primary font-bold cursor-pointer select-none hover:bg-gray-400 hover:text-white relative px-4 py-3"
        >
          {{ partner.persona.tradingName }}

          <div>
            <img
              v-if="partner.logoData"
              :src="partner.logoData.url"
              class="item mb-5"
            />
            <img
              v-if="!partner.logoData"
              :src="partnersLogo"
              class="item mb-5"
            />
          </div>

          <button
            @click="onSubmit(partner)"
            class="btncolor btn mb-5 bg-yellow-400 hover:bg-yellow-500 text-white w-full"
          >
            Select
          </button>
        </div>
      </div>
      <div>
        <button
          v-if="isSolo"
          @click="getRandomPartner()"
          class="btncolor btn mb-3 bg-yellow-400 hover:bg-yellow-500 text-white w-full"
        >
          Pick One For Me
        </button>
        <button
          v-else
          @click="getRandomPartner()"
          class="btncolor btn mb-3 bg-yellow-400 hover:bg-yellow-500 text-white w-full"
        >
          I Would Like More Information
        </button>
        <button
          @click.prevent="navigateToLoginPage('login')"
          class="btn mb-3 bg-gray-400 hover:bg-gray-500 text-white w-full"
          role="link"
        >
          Cancel
        </button>
      </div>
    </div>
    <div
      class="text-red-500 font-bold text-sm text-center mt-0 my-5"
      v-if="errorMessage"
    >
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectPartnerForm",
  props: {
    isSolo: Boolean,
    formTitle: String,
  },

  data() {
    return {
      selectPartnerForm: { partnerId: null },
      success: false,
      error: false,
      errorMessage: "",
      filteredPartners: [],
      loading: false,
      partners: [],
      selectedPartner: null,
      partnersLogo: "/assets/images/logo-min.png",
    };
  },
  async mounted() {
    this.loading = true;
    await this.init();
  },
  watch: {
    isSolo() {
      this.init();
    },
  },
  methods: {
    async init() {
      let partners;

      if (this.isSolo) {
        partners = await this.$store.dispatch(
          `partner/getPartnersWithPaymentGateway`
        );
      } else {
        partners = await this.$store.dispatch(`partner/getPartnersRaw`);
      }

      if (partners) {
        this.partners = partners;

        this.filteredPartners = await this.filterPartners(partners);
      }
      this.loading = false;
    },

    toggleForm(form) {
      this.$emit("toggleForm", form);
    },
    navigateToLoginPage() {
      this.$router.push("/login");
    },
    onSubmit(entityData) {
      if (this.isSolo) {
        window.location.href = entityData.soloUrl;
      } else {
        window.location.href = entityData.businessUrl;
      }
    },
    getRandomPartner() {
      if (this.isSolo) {
        const randomPartnerIndex = Math.floor(
          Math.random() * this.filteredPartners.length
        );
        const randomPartner = this.filteredPartners[randomPartnerIndex];
        window.location.href = randomPartner.soloUrl;
      } else {
        this.$router.push("/generalbusiness");
        //  window.location.href = .entityData.businessUrl;
      }
    },
    filterPartners(partners) {
      const selectedPartners = partners.filter((partner) => {
        if (this.isSolo) {
          return partner.soloUrl;
        } else {
          return partner.businessUrl;
        }
      });

      return selectedPartners;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  max-height: 400px;
  overflow-y: scroll;
}

.item {
  width: auto;
  height: 80px;
  margin: 5px;
  padding: 5px;
  margin-left: auto;
  margin-right: auto;
}
.btncolor {
  background-color: #ff9900;
}
</style>
