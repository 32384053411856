import { render, staticRenderFns } from "./edit-partner-pricing-rrp.vue?vue&type=template&id=dae98298&scoped=true"
import script from "./edit-partner-pricing-rrp.vue?vue&type=script&lang=js"
export * from "./edit-partner-pricing-rrp.vue?vue&type=script&lang=js"
import style0 from "./edit-partner-pricing-rrp.vue?vue&type=style&index=0&id=dae98298&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dae98298",
  null
  
)

export default component.exports