// List of Micro services api endpoint URL/PORT

const servicesList = {
    'ACCOUNTS' : {
        LOCAL_URL: 'http://localhost:3005', 
        SANDBOX_URL: 'https://sandbox-api-au.logmaster.shop',
        // SANDBOX_URL: 'http://localhost:3005',
        STAGING_URL : 'https://staging-api.logmaster.au',
        PRODUCTION_URL : 'https://prod-api.logmaster.au',
        PORT: null,
        description: 'Logmaster Accounts.',
    }
}

export { servicesList }; 