<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <FormulateInput
        type="email"
        name="emailAddress"
        label="Email Address"
        validation="required|email"
        :disabled="hasPreData || isBusinessDriverProfile"
      />
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="text"
          name="driverName"
          label="Full Name"
          validation="required"
          :disabled="hasPreData"
        />
        <FormulateInput
          type="date"
          name="driverDateOfBirth"
          label="Date Of Birth"
          validation="required"
          :disabled="hasPreData"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="text"
          name="driversLicenseNumber"
          label="Licence Number"
          validation="required"
          :disabled="hasPreData"
        />
        <FormulateInput
          type="date"
          name="driversLicenseExpiry"
          label="Licence Expiry"
          validation="required"
          :disabled="hasPreData"
        />
      </div>
      <FormulateInput
        type="select"
        name="driversLicenseState"
        label="Licence State"
        :options="states"
        validation="required"
        :disabled="hasPreData"
      />

      <div class="items-center justify-between">
        <button
          type="submit"
          class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Next
        </button>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { STATES } from "@/constants/location";
import { formatValues } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Add-Driver-Form",
  props: {
    msg: String,
    isBusinessDriverProfile: {
      type: Boolean,
      default: false,
    },
    preData: null,
    defaultData: null,
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      states: STATES,
      hasPreData: false,
    };
  },
  async mounted() {
    if (this.isBusinessDriverProfile) {
      const me = this.$store.getters[`account/me`];
      this.values["emailAddress"] = me.email;
    }

    if (!_.isEmpty(this.defaultData) && _.isEmpty(this.preData)) {
      const {
        emailAddress,
        driverName,
        driverDateOfBirth,
        driversLicenseNumber,
        driversLicenseExpiry,
        driversLicenseState,
        externalSiteId,
        parentExternalSiteId,
        isFromExternal,
        parentRole,
        parentUid,
        fleetMetadata,
      } = this.defaultData;

      this.values = {
        emailAddress,
        driverName,
        driverDateOfBirth,
        driversLicenseNumber,
        driversLicenseExpiry,
        driversLicenseState,
        externalSiteId,
        parentExternalSiteId,
        isFromExternal,
        parentRole,
        parentUid,
        fleetMetadata,
      };
      return;
    }

    if (!_.isEmpty(this.preData)) {
      this.hasPreData = true;
      const soloDrivers = await this.$store.dispatch(
        `driver/getDriver`,
        this.preData
      );
      if (soloDrivers) {
        this.values = formatValues(soloDrivers);
      }
    }
  },
  methods: {
    async onSubmit() {
      if (!_.isEmpty(this.preData)) {
        this.$emit("nextStep", { linkDriver: this.preData });
      } else {
        this.loading = true;
        this.values.emailAddress = this.values.emailAddress.toLowerCase();
        let newDriver = JSON.parse(JSON.stringify(this.values));
        if (newDriver) {
          this.$emit("nextStep", { newDriver: newDriver });
        }
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>