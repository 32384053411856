<template>
  <div>
    <div class="w-full min-h-full flex-row items-left">
      <div class="flex w-full mt-5 flex-col-reverse lg:flex-row">
        <aside class="w-full lg:w-96 h-full lg:border-r rounded-md bg-white mt-5 lg:mt-0 lg:mr-5">
          <div class="border-b">
            <p class="font-bold text-base text-black-primary px-8 py-4 lg:px-4">Release Notes</p>
          </div>
          <div class="border-b p-1">
            <button
                class="btn btn-primary w-full"
                @click.prevent="createReleaseNote()"
                :style="{ backgroundColor: theme.secondaryColor }"
            >
              Create Release Note
            </button>
          </div>
          <div class="h-96 overflow-y-scroll">
            <div>
              <div v-if="releaseNotes.length > 0">
                <div v-for="(releaseNote, index) in releaseNotes" :key="index">
                  <button
                      class="text-left w-full flex px-8 lg:px-4 py-3 border-b border-gray-200"
                      :class="{ 'bg-green-100': activeReleaseNoteVersion === releaseNote.version }"
                      type="button"
                      :style="{color: activeReleaseNoteVersion === releaseNote.version ? 'rgb(55 65 81)' : theme.primaryColor}"
                      @click="setActiveReleaseNote(releaseNote.version)"
                  >
                    <div class="w-full flex justify-between">
                      <div>
                        <font-awesome-icon
                            v-if="activeReleaseNoteVersion === releaseNote.version"
                            :icon="['fas', 'book-open']"
                            class="my-auto mr-1.5"
                            size="lg"
                        >
                        </font-awesome-icon>
                        <font-awesome-icon
                            v-else
                            :icon="['fas', 'book']"
                            class="my-auto mr-1.5"
                            size="lg"
                        >
                        </font-awesome-icon>
                        <small class="relative ml-1">Version - {{releaseNote.version}}</small>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              <div v-else class="w-full px-1 py-2 border-b border-gray-200 opacity-40" :style="{color: theme.primaryColor}">
                <small class="relative ml-5"><em>No Release Note found</em></small>
              </div>
            </div>
          </div>
        </aside>
        <div class="w-full flex flex-col">
          <div v-if="!releaseNoteLoading">
            <div class="bg-white py-4 px-8 rounded-md">

              <div class="mb-5">
                <div class="font-bold text-black-primary text-base">
                  Version - {{ activeReleaseNote.version }}
                  <span
                      class="text-xs capitalize px-3 py-2 ml-2 rounded"
                      :style="{
                      'background-color': `${activeReleaseNote.statusColor}`,
                      'border': `1px solid ${activeReleaseNote.color}`,
                      'color': `${activeReleaseNote.color}`
                    }"
                  >
                    {{ activeReleaseNote.statusAlt }}
                  </span>
                </div>
              </div>

              <div class=" -mt-1">
                <div v-html="activeReleaseNote.details" class="ql-editor"></div>
              </div>

            </div>
          </div>
          <div v-else class="w-full text-center overflow-y-auto px-3 text-lg inset-0 flex flex-col justify-center items-center" style="height: 669px;">
            <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
            <h6 class="mt-4">Loading</h6>
          </div>
        </div>
      </div>
    </div>


    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'create'">
        <div class="mb-16">

          <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
          </div>

          <div v-if="!loading">
            <FormulateInput
              type="text"
              label="Version"
              v-model="version"
              />

            <VueEditor v-model="content" :editorToolbar="customToolbar"></VueEditor>

            <div class="mt-8">
              <div class="flex items-center justify-end w-full mt-4 space-x-4">
                <FormulateInput
                    type="button"
                    label="Cancel"
                    input-class="btn btn-cancel w-full"
                    @click.prevent="closeModal"
                />
                <FormulateInput
                    type="submit"
                    label="Save"
                    input-class="btn btn-primary w-full"
                    :style="{ backgroundColor: theme.secondaryColor }"
                    @click.prevent="save"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { formatDate } from '@/_helper'
import { Modal } from "@/components/commons";

export default {
  name: 'Release-Notes',
  components: { Modal },
  data () {
    return {
      isLoading: false,
      releaseNoteLoading: false,
      activeReleaseNote: {},
      activeReleaseNoteVersion: "",
      modalTitle: "",
      modalContent: "",
      modalHeight: "20vh",
      loading: false,
      content: null,
      version: "",
      releaseNotes: [],
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['link']
      ],
    }
  },

  async mounted () {
    this.isLoading = true;
    this.$emit("updateLoading", true);

    await this.init();

    this.$emit("updateLoading", false);
    this.isLoading = false;
  },
  methods: {

    async init() {
      this.isLoading = true
      await this.getReleaseNotes();
      if(this.releaseNotes.length > 0) {
        this.activeReleaseNote = this.releaseNotes[0];
        this.activeReleaseNoteVersion = this.activeReleaseNote.version;
      }
      this.isLoading = false;
    },


    async getReleaseNotes() {
      let releaseNotes = await this.$store.dispatch(`settings/getReleaseNotes`);
      if(releaseNotes) {
        this.releaseNotes = this.formatItem(releaseNotes);
      }
    },


    setActiveReleaseNote(releaseNoteVersion) {
      this.releaseNoteLoading = true;
      this.activeReleaseNote = this.releaseNotes.filter(item => item.version === releaseNoteVersion)[0];
      this.activeReleaseNoteVersion = this.activeReleaseNote.version
      setTimeout(()=> {
        this.releaseNoteLoading = false;
      }, 300)
    },


    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const note = {
            id: item?.id,
            version: item?.version,
            details: item?.details,
            createdAt: formatDate(item?.createdAt),
            updatedAt: formatDate(item?.updatedAt),
          };
          nItems.push(note);
        });
      }
      return nItems;
    },

    createReleaseNote: function () {
      this.modalContent = "create";
      this.modalTitle = "Create Release Note";
      this.$refs.modal.openModal();
    },

    async save() {
      try {
        this.loading = true;
        if(this.version.trim() !== "" && this.content !== null) {
          const payload = {
            version: this.version,
            details: this.content,
          }
          await this.$store.dispatch(`settings/createReleaseNote`, payload);
        }
        await this.toast('success', 'Release Note successfully created.');
        this.loading = false;
        this.closeModal();
      } catch (error) {
        await this.toast('error', 'Failed to create.');
      }
    },

    closeModal() {
      this.$refs.modal.closeModal();
    },

    async toast(state, msg) {
      const message = {
        state: state,
        message: msg
      }
      this.$store.commit('setDialogNotify', true)
      this.$store.commit('setMessageNotify', message)
    },

  },

  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  }
}
</script>

<style scoped>
:deep ul {
  list-style-type: disc !important;
  padding-left: 20px !important;
}
:deep ol {
  list-style-type: decimal !important;
  padding-left: 20px !important;
}
:deep li {
  margin-bottom: 5px !important;
  margin-left: 20px !important;
}
:deep h1 {
  display: block;
  font-size: 2em;
  font-weight: bold;
}
:deep h2 {
  display: block;
  font-size: 1.5em;
  font-weight: bold;
}
</style>