<template>
  <div class="w-full border border-gray-200 rounded p-5">
    <h4 class="text-orange-primary mb-1 font-bold">Driver Full Information</h4>
    <div class="grid grid-cols-3 gap-x-4 text-xs">
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-32">Name</div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ driver.driverName || 'N/A' }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-32">
            UDI
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ driver.udi || 'N/A' }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-32">
            Date of Birth
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ driver.driverDateOfBirth || 'N/A' }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-32">
            Vehicle Number
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ driver['vehicle'] || 'N/A' }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-32">
            Licence Number
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ driver.driversLicenseNumber || 'N/A' }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-32">
            State of Licence
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ driver.driversLicenseState || 'N/A' }}</div>
        </div>
      </div>
    </div>

    <h4 class="text-orange-primary mb-1 mt-5 font-bold">{{reportName}} System Details</h4>
    <div class="grid grid-cols-2 gap-x-4 text-xs">
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-36">
            Approval Holder
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ graphDetails.approvalHolder }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-36">
            Approval Number
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ graphDetails.approvalNumber }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-36">
            System Name
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ graphDetails.systemName }}</div>
        </div>
      </div>
      <div class="w-full">
        <div class="flex text-gray-500 border border-gray">
          <div class="bg-blue font-bold text-white py-1 px-3 w-36">
            System Number
          </div>
          <div class="bg-gray-50 flex-auto py-1 px-3">{{ graphDetails.systemNumber }}</div>
        </div>
      </div>
    </div>

    <h4 class="text-orange-primary mb-1 mt-5 font-bold">Record Keeper Log</h4>
    <table class="w-full">
      <thead>
        <tr class="bg-blue text-xs text-left text-white border">
          <th scope="col" class="px-3 py-1">
            Location
          </th>
          <th scope="col" class="px-3 py-1">
            Effective From
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="logs['recordKeeper'] && logs['recordKeeper'].length <= 0" class="bg-white border-b">
          <td colspan="9" class="py-3 px-3 text-sm text-center border-l border-r">
            No record keeper log this day
          </td>
        </tr>
        <tr
          v-for="(log, index) in logs['recordKeeper']"
          :key="index"
          class="bg-white border-b text-xs"
        >
          <td class="py-2 px-3 border-l">
            {{ log.location }}
          </td>
          <td class="py-2 px-3 text-black-primary border-r">
            {{ log.effectiveFrom }}
          </td>
        </tr>
      </tbody>
    </table>

    <h4 class="text-orange-primary mb-1 mt-5 font-bold">Base Location Log</h4>
    <table class="w-full">
      <thead>
        <tr class="bg-blue text-xs text-left text-white border">
          <th scope="col" class="px-3 py-1">
            Location
          </th>
          <th scope="col" class="px-3 py-1">
            Time Zone
          </th>
          <th scope="col" class="px-3 py-1">
            Effective From
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="logs['base'] && logs['base'].length <= 0" class="bg-white border-b">
          <td colspan="9" class="py-3 px-3 text-sm text-center border-l border-r">
            No base location log this day
          </td>
        </tr>
        <tr
          v-for="(log, index) in logs['base']"
          :key="index"
          class="bg-white border-b text-xs"
        >
          <td class="py-2 px-3 border-l">
            {{ log.location }}
          </td>
          <td class="py-2 px-3 text-gray-500">
            {{ log.timeZone }}
          </td>
          <td class="py-2 px-3 text-black-primary border-r">
            {{ log.effectiveFrom }}
          </td>
        </tr>
      </tbody>
    </table>

    <h4 class="text-orange-primary mb-1 mt-5 font-bold">BFM/AFM Accreditation</h4>
    <table class="w-full">
      <thead>
        <tr class="bg-blue text-xs text-left text-white border">
          <th scope="col" class="px-3 py-1">
            Accreditation Type
          </th>
          <th scope="col" class="px-3 py-1">
            Accreditation Number
          </th>
          <th scope="col" class="px-3 py-1">
            Effective From
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="logs['accreditation'] && logs['accreditation'].length <= 0" class="bg-white border-b">
          <td colspan="9" class="py-3 px-3 text-sm text-center border-l border-r">
            No accreditation log this day
          </td>
        </tr>
        <tr
          v-for="(log, index) in logs['accreditation']"
          :key="index"
          class="bg-white border-b text-xs"
        >
          <td class="py-2 px-3 border-l">
            {{ log.accreditationType }}
          </td>
          <td class="py-2 px-3 text-gray-500">
            {{ log.accreditationNumber }}
          </td>
          <td class="py-2 px-3 text-black-primary border-r">
            {{ log.effectiveFrom }}
          </td>
        </tr>
      </tbody>
    </table>

    <h4 class="text-orange-primary mb-1 mt-5 font-bold">Device Log</h4>
    <table class="w-full">
      <thead>
        <tr class="bg-blue text-xs text-left text-white border">
          <th scope="col" class="px-3 py-1">
            Brand
          </th>
          <th scope="col" class="px-3 py-1">
            Model
          </th>
          <th scope="col" class="px-3 py-1">
            Operating System
          </th>
          <th scope="col" class="px-3 py-1">
            App Version
          </th>
          <th scope="col" class="px-3 py-1">
            Effective From
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="logs['device'] && logs['device'].length <= 0" class="bg-white border-b">
          <td colspan="9" class="py-3 px-3 text-sm text-center border-l border-r">
            No device log this day
          </td>
        </tr>
        <tr
          v-for="(log, index) in logs['device']"
          :key="index"
          class="bg-white border-b text-xs"
        >
          <td class="py-2 px-3 border-l capitalize">
            {{ log.maker }}
          </td>
          <td class="py-2 px-3 text-gray-500">
            {{ log.model }}
          </td>
          <td class="py-2 px-3 text-gray-500 capitalize">
            {{ log.os }}
          </td>
          <td class="py-2 px-3 text-black-primary capitalize">
            {{ log.app_version || 'N/A' }}
          </td>
          <td class="py-2 px-3 text-black-primary border-r">
            {{ log.createdAt }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "Log",
  props: {
    driver: Object,
    graphDetails: Object,
    logs: Object,
    reportName: String
  },
};
</script>