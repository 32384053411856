<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <FormulateForm
      v-if="!loading"
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      #default="{ hasErrors }"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors || loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import schema from "./new-techprovider-schema";
import { mapGetters } from "vuex";

export default {
  name: "Edit-TechProvider-Form",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      loading: false,
      //sample mock data for edit
      values: {},
      schema: schema,
    };
  },
  async mounted() {
    this.loading = true;
    const techProviderDetails = await this.$store.dispatch(
      `techprovider/getTechProvider`,
      { id: this.detailsId }
    );
    if (techProviderDetails) {
      this.values = this.parseTechProvider(techProviderDetails);
      this.loading = false;
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const parsedTechProvider = this.parseForSubmit(this.values);

      this.$store
        .dispatch(`techprovider/updateTechProvider`, parsedTechProvider)
        .then((techprovider) => {
          this.loading = false;
          if (techprovider && techprovider._id) {
            this.$emit("closeRefresh");
          }
        })
        .catch((error) => {
          this.errorMessage = `Something went wrong please try again.`;
          if (error.message) this.errorMessage = error.message[0];
        });
    },
    parseTechProvider(techprovider) {
      return {
        name: techprovider.name,
        inboundApiKey: techprovider.inboundApiKey,
        outboundApiKey: techprovider.outboundApiKey,
        url: techprovider.url,
      };
    },
    parseForSubmit(values) {
      return {
        id: this.detailsId,
        name: values.name,
        inboundApiKey: values.inboundApiKey,
        outboundApiKey: values.outboundApiKey,
        url: values.url,
      };
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
