<template>
  <div class="mt-10 h-20" :style="{ backgroundColor: theme.primaryColor }">
    <p class="text-center text-white">
      © Logmaster {{ new Date().getFullYear() }}. All rights reserved
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      theme: "theme/getColorScheme",
    }),
  },
};
</script>
