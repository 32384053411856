<template>
  <div class="border border-dashed border-gray-200 hover:border-orange-300 transition ease-in-out hover:duration-300 text-gray-400 rounded-md w-full p-4 draggable-zone flex flex-col items-stretch">
    <div class="h-fit min-h-full flex justify-end">
      <button 
        class="float-right ml-2 text-red-400 hover:text-red-800 text-sm font-bold rounded px-2 py-1"
        title="Remove this section"
        @click="removeSection(section)"
        >
        Remove
      </button>
    </div>
    <div class="flex w-full items-center p-0">
      <div class="flex w-full border-b mb-2">
        <div class="formulate-input-wrapper ">
          <label class="font-medium text-sm mb-1">Headline</label>
          <div data-type="number" class="formulate-input-element formulate-input-element--number">
            <FormulateInput
              name="headline"
              v-model="currentSectionHeaders.headline"
              validation-error="required"
              class="block w-full mb-3 mt-2 leading-tight"
              autocomplete="off"
            />
          </div>
        </div>
        <div class="formulate-input-wrapper pl-5  w-full ">
          <label class="font-medium text-sm mb-1">Sub-headline</label>
          <div data-type="number" class="formulate-input-element formulate-input-element--number">
            <FormulateInput
              name="subHeadline"
              v-model="currentSectionHeaders.subHeadline"
              class="block w-full mb-3 mt-2 leading-tight"
              autocomplete="off"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!basicComponents.length"
      class="h-12 border border-dashed rounded-lg text-xs border-gray-200 hover:border-orange-300 cursor-move px-6 py-1 flex justify-center items-center"
    >
      <font-awesome-icon 
        :icon="['fas', 'arrow-down']"
      />&nbsp;&nbsp; <p>Drop the component below...</p>
    </div>
    <draggable
      class="flex-1 h-12"
      tag="ul"
      :list="basicComponents"
      group="controls"
      @change="onDropComponent"
      ghost-class="moving-card"
    >
      <li
        v-for="(component, index) in basicComponents"
        :key="component.uniqueId"
        @click="selectComponent(component)"
        :class="component.selected ? 'border-orange-100 border-4' : 'border-gray-100 border hover:border-gray-300'"
        class="relative cursor-move shadow-lg my-2 border-solid rounded p-2 hover:border-dashed  transition ease-in-out hover:duration-300"
        >
        <FieldContainer :component="component" :unique-id="component.uniqueId" :section-id="currentSection.uniqueId" :isDisplayValueData="true"/>

        <div
          v-if="component.selected"
          class="absolute rounded-t-lg bg-orange-100 z-20 w-20 bottom-0 right-0 mr-4 h-7 px-3 py-0 -mb-3 flex justify-between items-center"
          >
          <button title="Clone" class="text-red-500 hover:text-red-400" @click.stop="cloneComponent(component)">
            <font-awesome-icon 
              :icon="['fas', 'copy']"/>
          </button>
          <button title="Remove" class="text-red-500 hover:text-red-400" @click.stop="removeComponent(index)">
            <font-awesome-icon 
              :icon="['fas', 'trash']"/>
          </button>
        </div>
      </li>
    </draggable>
  </div>
</template>
<script>
import Draggable from 'vuedraggable'
import FieldContainer from '@/views/builder/containers/FieldContainer.vue'
import { HELPER } from '@/api/helper'
import _ from 'lodash'

export default {
  components: {
    Draggable,
    FieldContainer
  },
  props: {
    section: Object,
    uniqueId: String
  },

  data: () => ({
    basicComponents: [], 
    currentSection: {},
    currentSectionHeaders: {}
  }),

  watch: {
    currentSectionHeaders: {
      deep: true,
      handler(newData) {
        this.$emit('updateCurrentSectionHeaders', {headline: newData.headline, subHeadline: newData.subHeadline, uniqueId: newData.uniqueId})
      }
    },
    
    section() { 
      this.updateCurrentSection()
    },
  },

  computed: {
    controls() {
      return this.currentSection.controls
    },
    updatedComponent() {
      const stateComponent = this.$store.state.form.updatedControl
      return stateComponent
    },
    sections() {
      return this.$store.state.form.formSections
    }
  },

  created() {
    this.updateCurrentSection()
  },

  methods: {
    async onDropComponent(data) {
      if ('added' in data) {
        await this.updateControls(data)
      }
      if ('moved' in data) {
        await this.updateControls()
      }
    },
    
    async updateControls(data = null) {
      const sections = HELPER.cloneDeep(this.sections)

      const selectedSection = sections.find((obj) => obj.uniqueId == this.uniqueId)

      const sectionIndex = sections.findIndex((obj) => obj.uniqueId == this.uniqueId)

      selectedSection.controls = this.basicComponents

      sections[sectionIndex] = selectedSection
      
      this.$store.commit('form/setSelectedControlSection', selectedSection)

      this.$store.commit('form/setFormSections', sections)

      if(!_.isEmpty(data?.added?.element)){
        const selectedComponent = data.added.element
        const formName = `${data?.added?.element.name}-${ HELPER.getUUIDv4() }`
        selectedComponent.parentId = selectedSection.uniqueId
        selectedComponent.name = formName
        selectedComponent.id = formName
        await this.updateSelectedComponent(selectedComponent)
      }
    },

    async updateSelectedComponent(component) {
      component = {
        sectionId: this.section.uniqueId,
        selectedControl: component
      }
      this.$emit('onSelectComponent', component)
    },

    async updateCurrentSection() {
      this.currentSection = HELPER.cloneDeep(this.section)
      const sections = HELPER.cloneDeep(this.$store.state.form.formSections)

      const section = sections.find((item) => item.uniqueId == this.currentSection.uniqueId)

      this.currentSectionHeaders = {
        headline: section.headline,
        subHeadline: section.subHeadline,
        uniqueId: section.uniqueId
      }
      
      this.$store.commit('form/setSelectedControlSection', this.section)
      this.basicComponents = HELPER.cloneDeep(this.controls)
    },

    async selectComponent(component) {
      this.$store.commit('form/setSelectedControlSection', this.section)
      this.updateSelectedComponent(component)
    },

    async cloneComponent(component) {
      const newComponent = HELPER.cloneDeep(component)
      const formName = `${(newComponent.component).trim()}-${ HELPER.getUUIDv4() }`
      newComponent.uniqueId = 'control-' + HELPER.getUUIDv4()
      newComponent.parentId = this.section.uniqueId
      newComponent.name = formName
      newComponent.id = formName

      let basicComponents = [...this.basicComponents, newComponent]
      let allBasicComponents = basicComponents

      const sections = HELPER.cloneDeep(this.sections)

      const selectedSection = sections.find((obj) => obj.uniqueId == this.uniqueId)

      const sectionIndex = sections.findIndex((obj) => obj.uniqueId == this.uniqueId)

      selectedSection.controls = allBasicComponents

      sections[sectionIndex] = selectedSection
      
      this.$store.commit('form/setSelectedControlSection', selectedSection)

      this.$store.commit('form/setFormSections', sections)

      this.updateSelectedComponent(newComponent)
    },

    async removeSection(section) {
      const indexOfSection = this.sections.findIndex((object) => {
        return object.uniqueId == section.uniqueId
      })
      this.sections.splice(indexOfSection, 1)

      // Clear Component Settings
      await this.$store.commit('form/setSelectedControl', {})
    },

    async removeComponent(index) {
      this.basicComponents.splice(index, 1)

      // Set default true to the remaining component
      if(this.basicComponents.length == 1){
        this.basicComponents[0].selected = true;
      }

      // Update control section on vuex
      this.updateControls();

      // Clear Component Settings
      await this.$store.commit('form/setSelectedControl', {})
    },
  }
}
</script>