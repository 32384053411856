import { Theme } from "@/api";
import { isEmpty } from "lodash";

export default {
  async createTheme({ commit }, data) {
    try {
      const theme = await Theme.createTheme(data);
      if (theme.data.success) {
        commit("updateActiveTheme", theme.data?.data?.themeData);
      }
      return theme.data?.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  //@GET: Fetch theme lists via account Id
  async getThemeListsById({ commit, state }, accountId) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const response = await Theme.getThemeListsById(accountId);

      const data = response?.data?.data;

      if (!isEmpty(data)) {
        commit("updateThemeLookupOptions", data);
        if (accountId === "logmaster") {
          commit("updateActiveTheme", data[0]);
        }
      }

      return data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  //@GET: Fetch all user themes
  async getUserThemes({ commit, state }, accountId) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const response = await Theme.getUserThemes(accountId);

      const data = response?.data?.data || [];

      if (!isEmpty(data)) {
        commit("updateThemeLookupOptions", data);
      }

      return data;
    } catch (e) {
      // throw new Error(e.message)
    }
  },
};
