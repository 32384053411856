<template>
  <div class="px-2 pt-3 page-container">
    <div class="w-full" v-if="loading">
      <div
        class="absolute inset-0 z-10 flex flex-col items-center justify-center m-auto bg-gray-50"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-400">Loading data...</span>
      </div>
    </div>
    <div v-else>
      <FormulateForm v-if="availableSeats == 0" @submit="onSubmitBillingPage">
        <div class="mb-5">
          <label class="text-lg font-medium">
            There is no available seat left. You have to add seats before add
            new drivers.
          </label>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Visit Billing Page"
            input-class="w-full btn btn-primary"
          />
        </StyledSubmit>
      </FormulateForm>
      <div v-else>
        <label
          v-if="availableSeats != -1 || availableSeats < drivers.length"
          class="block mb-2 text-lg font-medium"
        >
          You can only select <strong>{{ availableSeats }}</strong> driver{{
            availableSeats > 1 ? 's' : ''
          }}.
        </label>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="p-4"
                  :style="{ backgroundColor: theme.primaryColor }"
                >
                  <div class="flex items-center">
                    <input
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 rounded"
                      @change="selectAll()"
                      :disabled="
                        availableSeats != -1 && availableSeats < drivers.length
                      "
                    />
                    <label class="sr-only">checkbox</label>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-semibold tracking-wider text-left text-white uppercase border-gray-200"
                  :style="{ backgroundColor: theme.primaryColor }"
                >
                  Email Address
                </th>
                <th
                  class="px-6 py-3 text-xs font-semibold tracking-wider text-left text-white uppercase border-gray-200"
                  :style="{ backgroundColor: theme.primaryColor }"
                >
                  Timezone
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(driver, index) in drivers"
                :key="index"
                :data-import-id="index"
                :ref="getRowRef(index)"
                :class="{ 'hover:bg-red-50': driver.hasFieldError }"
                class="relative bg-white hover:bg-gray-50"
              >
                <td class="w-4 p-4">
                  <div
                    v-if="driver.hasFieldError"
                    class="absolute top-0 bottom-0 left-0 right-0 w-full border-b border-white bg-red"
                  >
                    <div
                      class="relative flex items-center justify-center w-full h-full"
                    >
                      <div class="absolute inset-0 z-0 flex px-5 bg-red-100">
                        <div class="flex items-center justify-center">
                          <font-awesome-icon
                            icon="exclamation-triangle"
                            class="m-auto text-xl text-red-700"
                          />
                        </div>
                        <div class="flex flex-col justify-center flex-1 pl-3">
                          <div
                            v-if="driver.hasFieldError"
                            class="py-1 font-medium text-red-700"
                          >
                            <span
                              v-if="
                                driver.missingFields.length &&
                                driver.invalidFields.length
                              "
                              >Driver data has missing and invalid fields.</span
                            >
                            <span v-else-if="driver.missingFields.length"
                              >Driver data has missing fields.</span
                            >
                            <span v-else-if="driver.invalidFields.length"
                              >Driver data has invalid fields.</span
                            >
                          </div>
                        </div>
                        <div
                          class="flex items-center justify-center text-sm font-medium text-right capitalize whitespace-nowrap"
                        >
                          <a
                            @click.prevent="editDriver(index, driver)"
                            class="cursor-pointer text-grey-400 hover:underline"
                            >Edit</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center" v-show="!driver.hasFieldError">
                    <input
                      v-model="driver.checked"
                      :disabled="driver.disabled"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                      @change="onDriverCheck"
                    />
                    <label class="sr-only">checkbox</label>
                  </div>
                </td>
                <td
                  scope="row"
                  class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap"
                >
                  <div>
                    <div class="text-xs font-normal text-gray-500">
                      {{ driver.emailAddress }}
                    </div>
                  </div>
                </td>
                <td class="px-6 py-4">{{ driver.driverBaseTimeZone }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <FormulateInput
          type="submit"
          label="Next"
          input-class="w-full mt-5 btn btn-primary"
          :disabled="loading || availableSeats == 0"
          @click.prevent="nextPage()"
        />
      </div>
    </div>
    <div class="hidden" id="default-edit-driver-template">
      <tr id="edit-driver-template" class="border-b border-grey-400">
        <td colspan="5">
          <div class="w-full px-5 py-3">
            <FormulateForm v-model="values" @submit="onEditSave">
              <FormulateInput
                type="email"
                name="emailAddress"
                label="Email Address"
                validation="required|email"
              />
              <FormulateInput
                type="places-search-input"
                name="driverRecordKeeperAddress"
                label="Record Keeper Address"
                validation="required"
              />
              <FormulateInput
                type="places-search-input"
                name="driverBaseLocation"
                label="Base Location Address"
                validation="required"
              />
              <div class="grid grid-cols-2 gap-4">
                <FormulateInput
                  type="select"
                  name="driverBaseTimeZone"
                  label="Base Time Zone"
                  :options="timezones"
                  validation="required|validateTimezone"
                  :validation-rules="{
                    validateTimezone: ({ value }) =>
                      timezones.map((x) => x.value).includes(value),
                  }"
                  :validation-messages="{
                    validateTimezone: 'The entered timezone is not valid.',
                  }"
                />
                <FormulateInput
                  v-if="isNonEwdImport"
                  type="select"
                  name="driversBaseFatiguePlan"
                  label="Base Fatigue Plan"
                  :options="fatiguePlans"
                  validation="required"
                />
                <FormulateInput
                  v-if="!isNonEwdImport"
                  type="text"
                  name="driverBFMNumber"
                  label="BFM Number"
                />
              </div>
              <FormulateInput
                type="select"
                name="region"
                label="Region"
                :options="regions"
                validation="required"
              />

              <div class="grid grid-cols-2 gap-4">
                <FormulateInput
                  type="button"
                  label="Close"
                  input-class="w-full btn btn-cancel"
                  @click="closeEdit()"
                />
                <FormulateInput
                  type="submit"
                  label="Save"
                  input-class="w-full btn btn-primary"
                  :disabled="loading"
                  :style="{ backgroundColor: theme.secondaryColor }"
                />
              </div>
            </FormulateForm>
          </div>
        </td>
      </tr>
    </div>
  </div>
</template>

<script>
import $ from 'jquery';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { mapGetters } from 'vuex';
import { validateEmail } from '@/_helper';
import { STATES, TIMEZONES } from '@/_helper/constants';
import { NHVR_FATIGUE_PLAN } from '@/constants/fatigue-plan';
import { REGION } from '@/constants/region';
import Spinner from '@/components/commons/ui/Spinner';

export default {
  name: 'Select-Driver',
  components: { Spinner },
  props: {
    preData: null,
    isNonEwdImport: Boolean,
    availableSeats: Number,
  },
  mounted() {
    this.fatiguePlans = Object.entries(NHVR_FATIGUE_PLAN).map((fatigue) => {
      return { label: fatigue[0].replace(/_/g, ' '), value: fatigue[1] };
    });

    if (!isEmpty(this.preData)) {
      this.drivers = this.preData.drivers;
      this.validateFields();

      this.drivers.forEach((driver) => {
        if (!isEmpty(driver.driverBaseTimeZone))
          driver.driverBaseTimeZone = driver.driverBaseTimeZone.toUpperCase();
      });
    }
  },
  data() {
    return {
      states: STATES,
      timezones: TIMEZONES,
      regions: REGION,
      fatiguePlans: [],
      loading: false,
      drivers: [],
      values: {},
      isSelectAll: false,
      selectedIndex: null,
    };
  },
  methods: {
    onDriverCheck() {
      const checkedCount = this.drivers.filter((x) => x.checked);
      if (checkedCount.length == this.availableSeats) {
        // Disable all unchecked checkboxes
        this.drivers.forEach((x) => {
          if (!x.checked) x.disabled = true;
        });
      } else {
        this.drivers.forEach((x) => (x.disabled = false));
      }
    },
    onSubmitBillingPage() {
      this.$router.push(`/business/billing`);
    },
    formatDate(dateToFormat) {
      let momentDate = moment(dateToFormat);
      if (!momentDate.isValid())
        momentDate = moment(dateToFormat, 'DD/MM/YYYY');

      return momentDate.format('YYYY-MM-DD');
    },
    getRowRef(index) {
      return 'ref' + index;
    },
    selectAll() {
      this.isSelectAll = !this.isSelectAll;
      this.drivers.forEach((driver) => {
        if (!driver.hasFieldError)
          driver.checked = this.isSelectAll ? true : false;
      });
    },
    validateFields() {
      this.drivers.forEach((driver) => {
        driver.invalidFields = [];
        driver.missingFields = [];
        driver.hasFieldError = false;
        Object.entries(driver).forEach(([key, value]) => {
          if (
            [
              'driverBFMNumber',
              'checked',
              'disabled',
              'invalidFields',
              'missingFields',
              'hasFieldError',
            ].includes(key)
          )
            return;
          if (
            key == 'emailAddress' &&
            !isEmpty(value) &&
            !validateEmail(value)
          ) {
            driver.invalidFields.push(key);
          }
          if (
            key == 'driverBaseTimeZone' &&
            !isEmpty(value) &&
            !this.timezones.map((x) => x.value).includes(value)
          ) {
            driver.invalidFields.push(key);
          }
          if (
            key == 'region' &&
            !isEmpty(value) &&
            !this.regions
              .map((x) => x.value.toLowerCase())
              .includes(value.toLowerCase())
          ) {
            driver.invalidFields.push(key);
          }
          if (isEmpty(value)) driver.missingFields.push(key);
        });

        driver.hasFieldError =
          driver.invalidFields.length > 0 || driver.missingFields.length > 0;
      });
    },
    nextPage() {
      if (this.availableSeats != 0) {
        const selectedDrivers = this.drivers.filter((driver) => driver.checked);
        this.$emit('nextStep', { drivers: selectedDrivers });
      }
    },
    editDriver(index, driver) {
      this.values = driver;
      this.selectedIndex = index;
      $($('#edit-driver-template').detach()).insertAfter(
        `tr[data-import-id="${index}"]`
      );
      this.$nextTick(() =>
        this.$refs[this.getRowRef(index)][0].scrollIntoView({
          scrollBehavior: 'smooth',
        })
      );
    },
    closeEdit() {
      this.values = {};
      this.selectedIndex = null;
      this.validateFields();
      $($('#edit-driver-template').detach()).appendTo(
        `#default-edit-driver-template`
      );
    },
    onEditSave() {
      this.drivers[this.selectedIndex] = this.values;
      this.closeEdit();
    },
  },
  computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
