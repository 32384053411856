var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full min-h-full flex-row items-left"},[_c('div',{staticClass:"flex w-full mt-5 flex-col-reverse lg:flex-row"},[_c('aside',{staticClass:"w-full lg:w-96 h-full lg:border-r rounded-md bg-white mt-5 lg:mt-0 lg:mr-5"},[_vm._m(0),_c('div',{staticClass:"h-96 overflow-y-scroll"},[_c('div',[(_vm.releaseNotes.length > 0)?_c('div',_vm._l((_vm.releaseNotes),function(releaseNote,index){return _c('div',{key:index},[_c('button',{staticClass:"text-left w-full flex px-8 lg:px-4 py-3 border-b border-gray-200",class:{ 'bg-green-100': _vm.activeReleaseNoteVersion === releaseNote.version },style:({color: _vm.activeReleaseNoteVersion === releaseNote.version ? 'rgb(55 65 81)' : _vm.theme.primaryColor}),attrs:{"type":"button"},on:{"click":function($event){return _vm.setActiveReleaseNote(releaseNote.version)}}},[_c('div',{staticClass:"w-full flex justify-between"},[_c('div',[(_vm.activeReleaseNoteVersion === releaseNote.version)?_c('font-awesome-icon',{staticClass:"my-auto mr-1.5",attrs:{"icon":['fas', 'book-open'],"size":"lg"}}):_c('font-awesome-icon',{staticClass:"my-auto mr-1.5",attrs:{"icon":['fas', 'book'],"size":"lg"}}),_c('small',{staticClass:"relative ml-1"},[_vm._v("Version - "+_vm._s(releaseNote.version))])],1)])])])}),0):_c('div',{staticClass:"w-full px-1 py-2 border-b border-gray-200 opacity-40",style:({color: _vm.theme.primaryColor})},[_vm._m(1)])])])]),_c('div',{staticClass:"w-full flex flex-col"},[(!_vm.releaseNoteLoading)?_c('div',[_c('div',{staticClass:"bg-white py-4 px-8 rounded-md"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" Version - "+_vm._s(_vm.activeReleaseNote.version)+" "),_c('span',{staticClass:"text-xs capitalize px-3 py-2 ml-2 rounded",style:({
                    'background-color': `${_vm.activeReleaseNote.statusColor}`,
                    'border': `1px solid ${_vm.activeReleaseNote.color}`,
                    'color': `${_vm.activeReleaseNote.color}`
                  })},[_vm._v(" "+_vm._s(_vm.activeReleaseNote.statusAlt)+" ")])])]),_c('div',{staticClass:"-mt-1"},[_c('div',{staticClass:"ql-editor",domProps:{"innerHTML":_vm._s(_vm.activeReleaseNote.details)}})])])]):_c('div',{staticClass:"w-full text-center overflow-y-auto px-3 text-lg inset-0 flex flex-col justify-center items-center",staticStyle:{"height":"669px"}},[_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":"cog","spin":"","size":"3x"}}),_c('h6',{staticClass:"mt-4"},[_vm._v("Loading")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-b"},[_c('p',{staticClass:"font-bold text-base text-black-primary px-8 py-4 lg:px-4"},[_vm._v("Release Notes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',{staticClass:"relative ml-5"},[_c('em',[_vm._v("No Release Note found")])])
}]

export { render, staticRenderFns }