<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <div class="text-center mb-10">
        <div class="flex text-right justify-end">
          <a @click.prevent="onClickEdit()" class="flex cursor-pointer"
            ><font-awesome-icon
              icon="edit"
              class="my-auto mx-1.5 hover:text-orange text-xs"
          /></a>
          <a @click.prevent="onClickDelete()" class="flex cursor-pointer"
            ><font-awesome-icon
              icon="trash"
              class="my-auto mx-1.5 hover:text-orange text-xs"
          /></a>
        </div>
        <div class="font-bold text-gray-900 text-2xl">
          {{ lead.name }}
        </div>
        <div
          class="font-bold text-xs"
          :class="[lead.isActive ? 'text-green-500' : 'text-red-500']"
        >
          {{ lead.status }}
        </div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
        <div class="text-s font-bold">Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">{{ lead.abn }}</div>
        <div class="text-xs">ABN</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.businessName }}
        </div>
        <div class="text-xs">Name</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.contactEmail }}
        </div>
        <div class="text-xs">Email</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.contactPhoneNumber }}
        </div>
        <div class="text-xs">Contact Number</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.businessAddress }}
        </div>
        <div class="text-xs">Address</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">{{ lead.type }}</div>
        <div class="text-xs">Type</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.parentType }}
        </div>
        <div class="text-xs">Parent Type</div>
      </div>

      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.isConverted }}
        </div>
        <div class="text-xs">Converted</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.createdAt }}
        </div>
        <div class="text-xs">Created At</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ lead.updatedAt }}
        </div>
        <div class="text-xs">Updated At</div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/_helper";
export default {
  name: "Lead-Details-View",
  props: {
    leadId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      lead: {},
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const leadDetails = await this.$store.dispatch(
      `business/getLead`,
      this.leadId
    );
    if (leadDetails) {
      this.lead = this.parseLead(leadDetails);
      this.loading = false;
    }
  },
  methods: {
    parseLead(lead) {
      return {
        abn: lead.data.abn,
        businessAddress: lead.data.businessAddress,
        businessName: lead.data.businessName,
        contactEmail: lead.data.contactEmail,
        contactPhoneNumber: lead.data.contactPhoneNumber,
        type: lead.data.type == "business" ? "Business" : "Driver",
        parentType: lead.data.parentType == "admin" ? "Logmaster" : "Partner",
        isConverted: lead.data.isConverted ? "True" : "False",
        createdAt: formatDate(lead.data.createdAt),
        updatedAt: formatDate(lead.data.updatedAt),
      };
    },
    onClickEdit() {
      this.$emit("editLead", this.leadId);
    },
    onClickDelete() {
      this.$emit("deleteLead", this.leadId);
    },
  },
};
</script>

<style></style>
