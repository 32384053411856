<template>
  <div class="mb-16 border-2 border-gray-300 rounded p-7">
    <FormulateForm v-model="values" @submit="onSubmit" class="relative">
      <FormulateInput
        type="file"
        name="file"
        label="Select your document to upload"
        @change="onFileChange()"
      />

      <div class="grid grid-cols-2 gap-4 my-5">
        <button
          class="w-full mb-3 bg-gray-300 btn hover:bg-gray-400"
          @click.prevent="onPrevious()"
        >
          Back
        </button>
        <FormulateInput
          language="en"
          type="submit"
          label="Next"
          input-class="w-full btn btn-primary"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Manual-Event-Document-Upload",
  props: {
    msg: String,
    preData: null,
    postData: null,
  },
  data() {
    return {
      loading: false,
      file: [],
      values: {},
      errorMessage: "",
      schema: [],
      breaches: [],
      eventDetails: [],
      stepsData: [],
      engineRegion: null,
    };
  },
  async mounted() {
    let preData = this.preData;

    if (!_.isEmpty(this.postData)) {
      this.stepsData = this.postData;
      preData = this.stepsData.step4Data;
    }

    if (!_.isEmpty(preData)) {
      if (preData?.stepsData) {
        this.stepsData = preData.stepsData;
      }

      if (preData?.breaches) {
        this.breaches = preData?.breaches;
      }

      if (preData?.engineRegion) {
        this.engineRegion = preData.engineRegion;
      }

      if (preData?.eventDetails) {
        this.eventDetails = preData.eventDetails;
      }
    }
  },
  methods: {
    onPrevious() {
      this.$emit("backStep", this.stepsData);
    },
    async fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    onFileChange() {
      const files = this.values.file.files;
      files.forEach(async (file) => {
        this.file = {
          filename: file.name,
          filetype: file.file.type,
          fileimage: await this.fileToBase64(file.file),
        };
      });
    },
    async onSubmit() {
      const step5Data = {
        engineRegion: this.engineRegion,
        eventDetails: this.eventDetails,
        breaches: this.breaches,
        file: this.file,
      };
      this.stepsData = { ...this.stepsData, step5Data };

      this.$emit("nextStep", { ...step5Data, stepsData: this.stepsData });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
