<template>
  <div>
    <ul class="tabs flex flex-wrap -mb-px">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click.prevent="selectTab(index)"
        :style="{ color: theme.primaryColor }"
      >
        <button
          v-if="tab.visible"
          :class="{ tabs__selected: index == selectedIndex }"
          class="inline-block py-1 px-3 text-sm font-medium text-center text-gray-500 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300"
          :style="{
            color: theme.primaryColor,
            ...(index === selectedIndex && {
              borderColor: theme.secondaryColor,
            }),
          }"
        >
          {{ tab.title }}
          <span
            v-if="tab.badge"
            class="tab-badge font-medium text-white rounded-full"
            :style="{ backgroundColor: theme.primaryColor }"
            >{{ tab.badge }}</span
          >
        </button>
      </li>
    </ul>
    <div class="border-b border-gray-200"></div>
    <slot></slot>
  </div>
</template>

<style scoped>
.tabs__selected {
  @apply border-orange-primary hover:border-orange-primary;
}
.tab-badge {
  position: relative;
  padding: 2px 6px;
  font-size: 11px;
  top: -4px;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Tabs",

  props: {
    clickEvent: {
      type: Boolean,
      default: false,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      selectedIndex: 0,
      tabs: [],
    };
  },
  created() {
    this.tabs = this.$children;
  },
  async mounted() {
    this.selectTab(this.activeTab);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
      this.$emit("activeTab", this.tabs[i].title, this.selectedIndex); //Added capability to get the current active title
      if (this.clickEvent) {
        this.$emit("click", this.tabs[i].title, this.selectedIndex);
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
