<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onSubmit">
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="text"
          name="driverName"
          label="Full Name"
          validation="required"
        />
        <FormulateInput
          type="date"
          name="driverDateOfBirth"
          label="Date Of Birth"
          validation="required"
        />
      </div>
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="text"
          name="driversLicenseNumber"
          label="Licence Number"
          validation="required"
        />
        <FormulateInput
          type="date"
          name="driversLicenseExpiry"
          label="Licence Expiry"
          validation="required"
        />
      </div>
      <FormulateInput
        type="select"
        name="driversLicenseState"
        label="Licence State"
        :options="states"
        validation="required"
      />
      <div class="grid grid-cols-2 gap-4">
        <FormulateInput
          type="select"
          name="driversBaseFatiguePlan"
          label="Base Fatigue Plan"
          :options="fatiguePlans"
          validation="required"
        />
        <FormulateInput
          type="select"
          name="driversBaseTimeZone"
          label="Base Time Zone"
          :options="timezones"
          validation="required"
        />
      </div>

      <FormulateInput
        type="select"
        name="engineRegion"
        label="Region"
        :options="regions"
        validation="required"
        @change="setFatiguePlans"
      />

      <div class="items-center justify-between mt-4">
        <button
          type="submit"
          class="w-full btn btn-primary"
          :disabled="loading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="loading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />
            Submitting..</span
          >
          <span v-if="!loading">Submit</span>
        </button>
      </div>
    </FormulateForm>
    <div class="mb-3 text-center text-red-500" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { STATES, TIMEZONE } from "@/constants/location";
import {
  NHVR_FATIGUE_PLAN,
  WAHVA_FATIGUE_PLAN,
} from "@/constants/fatigue-plan";
import { REGION } from "@/constants/region";

export default {
  data() {
    return {
      values: {},
      loading: false,
      hasErrorMessage: false,
      errorMessage: "",
      states: STATES,
      timezones: TIMEZONE,
      regions: REGION,
      fatiguePlans: [],
    };
  },
  mounted() {
    this.setFatiguePlans();
  },
  methods: {
    async setFatiguePlans() {
      let fatiguePlans =
        this.values.engineRegion == "WAHVA"
          ? WAHVA_FATIGUE_PLAN
          : NHVR_FATIGUE_PLAN;

      this.fatiguePlans = [];
      _.mapValues(fatiguePlans, (val, key) => {
        return this.fatiguePlans.push({
          value: val,
          label: key.replace(/_/g, " "),
        });
      });
      this.values.driversBaseFatiguePlan = this.fatiguePlans[0].value;
    },
    async onSubmit() {
      this.errorMessage = "";
      this.loading = true;
      const me = this.$store.getters[`account/me`];
      const entityID = me.business._id;

      let payload = {
        ...this.values,
        demoOption: "NO_ACCOUNT",
        isDemoAccount: false,
        entityId: entityID,
        businessId: entityID,
      };

      const proxySite = this.$store.getters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
        payload["siteId"] = proxySite._id;
      }

      await this.$store
        .dispatch(`driver/createNonEWDDriver`, payload)
        .then(() => {
          this.$emit("closeRefresh");
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Non-EWD Driver successfully created.",
          });
        })
        .catch((err) => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
