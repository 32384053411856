<template>
  <div>
    <Tabs>
      <Tab title="Certificates">
        <ConfigureAccreditation class="mt-5"></ConfigureAccreditation>
      </Tab>
      <Tab title="Documents Builder">
        <DocumentBuilder class="mt-5"></DocumentBuilder>
      </Tab>
      <Tab title="Document Templates">
        <DocumentTemplates class="mt-5"></DocumentTemplates>
      </Tab>
    </Tabs>
  </div>
</template>


<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import ConfigureAccreditation from "@/pages/business/ConfigureAccreditation.vue";
import DocumentBuilder from "@/pages/business/DocumentBuilder.vue";
import DocumentTemplates from "@/pages/business/DocumentTemplates.vue";
export default {
  name: "Conformance",
  components: {DocumentTemplates, DocumentBuilder, Tab, Tabs, ConfigureAccreditation},
}
</script>