<template>
  <div>
    <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="changeLicenceRequests"
        @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
                v-model="filter.limit"
                @change="filterChange"
                class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400"/>
            </div>
          </div>
          <div class="relative">
            <select
                v-model="filter.status"
                @change="filterChange"
                class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option v-for="(filterStatus,index) in filterStatuses" :key="index" :value="index">{{ filterStatus }}</option>
            </select>
            <div
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400"/>
            </div>
          </div>
        </div>

      </template>
      <template #default="{data}">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap text-lg flex">
            <a class="flex cursor-pointer"
                @click.prevent="viewRequest(data.id)"
            ><font-awesome-icon
                icon="eye"
                class="my-auto mx-1.5 hover:text-orange-primary"
            /></a>
            <a v-if="data.status === 'PENDING'" class="flex cursor-pointer"
                @click.prevent="editRequest(data.id)"
            ><font-awesome-icon
                icon="edit"
                class="my-auto mx-1.5 hover:text-orange-primary"
            /></a>
          </div>
        </td>
      </template>
      <div>Not Found</div>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'view'">
        <ChangeLicenceRequestDetails :request-id="modalId" @closeModal="closeModal" @editRequest="editRequest"/>
      </div>
      <div v-if="modalContent === 'edit'">
        <ChangeLicenceRequestEdit :request-id="modalId" @closeModal="closeModal"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table } from "@/components/commons/";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import ChangeLicenceRequestEdit from "@/components/licence-management/ChangeLicenceRequestEdit.vue";
import ChangeLicenceRequestDetails from "@/components/licence-management/ChangeLicenceRequestDetails.vue";

export default {
  name: "Change-Licence-Requests",
  components: {
    ChangeLicenceRequestEdit,
    ChangeLicenceRequestDetails,
    Modal,
    Table,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      changeRequests: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        'Business Name',
        'Email',
        'Contact Phone',
        'Created At',
        'Status'
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      userType: null,
      filterStatuses: {
        pending:"Pending",
        accepted:"Accepted",
        declined:"Declined",
        cancelled:"Cancelled",
        all:"All",
      },
      filter:{
        limit: 10,
        status: 'pending',
      },
    };
  },
  async mounted() {
    await this.init()
  },
  methods: {
    viewRequest: function (id) {
      this.modalContent = "view";
      this.modalTitle = "Change Licence Count Request";
      this.$refs.modal.toggleModal();
      this.modalId = id;
    },
    editRequest: function (id) {
      this.modalContent = "edit";
      this.modalTitle = "Edit Licence Count Request";
      this.$refs.modal.openModal();
      this.modalId = id;
    },
    formatItem: function (items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const business = {
            id:item?._id,
            name:item?.businessId?.persona?.businessName || '',
            email:item?.businessId?.supportEmail || '',
            phone:item?.businessId?.persona?.contactPhoneNumber || '',
            created: formatDate(item?.createdAt),
            status:item?.status.toUpperCase() || '',
            addedSeats:item?.addedSeats || {},
          };
          nItems.push(business);
        });
      }
      return nItems;
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.init();
    },
    async init( paged = 1 ) {
      this.isLoading = true;
      this.$emit("updateLoading", true);
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: ( paged * this.filter.limit ) - this.filter.limit,
        limit: this.filter.limit,
        search: '',
        filter: {}
      }

      if(this.filter.status !== 'all') query.filter.status = { $eq : this.filter.status };
      query.filter.parentUid = { $eq : me.uid };


      const changeSeatRequestsData = await this.$store.dispatch(`contract/getChangeSeatCountRequest`, {query});
      if (changeSeatRequestsData) {
        if(changeSeatRequestsData?.metadata){
          this.paginationSettings = formatPaginationSettings(changeSeatRequestsData?.metadata)
        }else{
          this.paginationSettings = formatPaginationSettings()
        }

        this.changeRequests = this.formatItem(
            _.orderBy(changeSeatRequestsData.results, ["createdAt"], ["desc"])
        );

        this.isLoading = false;
        this.$emit("updateLoading", false);
      }else{
        this.changeRequests = []
      }
    },
    async onPageChange(event){
      if(event.page){
        await this.init(event.page)
      }
    },
    async filterChange(){
      await this.init()
    },
    parseChangeLicenceRequest(data){
      return data.map((requestData) => ([
        { id: requestData.id, name: requestData.name, itemType: 'name', hasImage: true, status: requestData.status},
        { id: requestData.id, name: requestData.email, itemType: 'string'},
        { id: requestData.id, name: requestData.phone, itemType: 'string'},
        { id: requestData.id, name: requestData.created, itemType: 'string'},
        { id: requestData.id, name: requestData.status, itemType: 'string'},
      ]))
    },
  },
  computed: {
    changeLicenceRequests() {
      if(this.changeRequests){
        return this.parseChangeLicenceRequest(this.changeRequests)
      }else{
        return [];
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
