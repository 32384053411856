<template>
  <div>
    <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
      <FormulateInput
        type="text"
        name="fullname"
        label="Full Name"
        validation="required"
      />
      <FormulateInput
        type="email"
        name="email"
        label="Email"
        validation="^required|email"
        @input="handleStritEmailCase($event)"
      />
      <FormulateInput
        type="text"
        name="subject"
        label="Subject"
        validation="required"
      />
      <FormulateInput
        type="textarea"
        name="message"
        label="Message"
        validation="required|max:100,length"
        help="Keep it under 100 characters."
      />
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :disabled="hasErrors"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Contact-Form",
  props: {
    msg: String,
  },
  data() {
    return {
      values: {},
    };
  },
  methods: {
    onSubmit() {},
    handleStritEmailCase(value) {
      this.values.email = value.toLowerCase();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
