<template>
  <div>
    <section v-if="readReports">
      <Table
        :isLoading="isLoading"
        :tableHeader="tableHeader"
        :paginationSettings="paginationSettings"
        :data="investigationData"
        @onPageChange="onPageChange($event)"
      >
        <template #tableFilters>
          <div class="flex flex-row mb-1 sm:mb-0">
            <div>
              <select
                v-model="filter.limit"
                @change="filterChange"
                class="block w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-l appearance-none focus:outline-none focus:bg-white focus:border-gray-500"
              >
                <option :value="10">10</option>
                <option :value="20">20</option>
                <option :value="50">50</option>
              </select>
              <div
                class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700 pointer-events-none"
              >
                <font-awesome-icon icon="caret-down" class="text-gray-400" />
              </div>
            </div>

            <div>
              <input
                placeholder="Search"
                autofill="true"
                class="w-full h-full px-4 py-2 pr-8 leading-tight text-gray-700 bg-white border border-gray-400 rounded-r-none appearance-none border-r-none -backdrop-hue-rotate-15block focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                v-model="filter.search"
                @keyup="handleSearchKeyUp"
              />
            </div>

            <div class="relative block">
              <DatePickerWithRange
                class="ml-2"
                ref="childDatePicker"
                @selected="selectedDateRange"
                :date="selectedDateRangeData"
              />
            </div>
          </div>
        </template>
        <template #callToActionTop>
          <button
            class="btn btn-primary"
            @click.prevent="newInvestigation()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Create Investigation
          </button>
        </template>
        <template #buttons>
          <div class="flex flex-row"></div>
          <div class="mr-2">
            <button
              class="btn btn-primary"
              :style="{ backgroundColor: theme.secondaryColor }"
              @click.prevent="handleSummaryOrDetailedReportClick('summary')"
            >
              Summary Report
            </button>
          </div>
          <div class="mr-2">
            <button
              class="btn btn-primary"
              :style="{ backgroundColor: theme.primaryColor }"
              @click.prevent="sendDetailedReport('detailed')"
            >
              Detailed Report
            </button>
          </div>
        </template>
        <template #default="{ data }">
          <td class="relative px-5 py-2 text-center">
            <Dropdown ref="dropdown">
              <ul class="py-2 text-sm">
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    @click="viewInvestigation(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                  </StyledIconedLink>
                </li>
                <li v-if="finished(data.status)">
                  <StyledIconedLink
                    class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    @click="reOpenInvestigation(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="history" class="my-auto mr-2" />
                    Re-Open
                  </StyledIconedLink>
                </li>
                <li v-if="unfinished(data.status)">
                  <StyledIconedLink
                    class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    @click="addDocuments(data.id, true)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                    Add Docs
                  </StyledIconedLink>
                </li>
                <li>
                  <StyledIconedLink
                    class="block px-4 py-2 text-gray-700 cursor-pointer hover:bg-gray-100"
                    @click="viewChainOfActions(data.id)"
                    :iconHoverColor="theme.secondaryColor"
                  >
                    <font-awesome-icon icon="link" class="my-auto mr-2" />
                    Actions Chain
                  </StyledIconedLink>
                </li>
              </ul>
            </Dropdown>
          </td>
        </template>
      </Table>
    </section>

    <section>
      <div
        v-if="!readReports && hasRole"
        class="w-full min-h-full mt-5 text-center"
      >
        <h1>
          Not authorized to view reports. Please contact your business admin.
        </h1>
      </div>
      <div v-if="!hasRole" class="text-center">
        <h1>
          You are currently not associated with a role. Please contact support.
        </h1>
      </div>
    </section>

    <Modal ref="modal" :title="modal.modalTitle" size="3xl">
      <div v-if="modal.modalContent == 'new'">
        <div class="mb-16">
          <AddInvestigationRegister @closeModal="closeModal" />
        </div>
      </div>

      <div v-if="modal.modalContent == 'view' && activeInvestigationFromServer">
        <ViewInvestigationRegisterDetails
          @addDocuments="addDocuments"
          :activeInvestigation="activeInvestigationFromServer"
          @closeModal="closeModal"
          @raiseNonConformance="raiseNonConformance"
        />
      </div>

      <div
        v-if="
          modal.modalContent == 'add-documents' && activeInvestigationFromServer
        "
      >
        <AddInvestigationDocumets
          @closeModal="closeModal"
          @backToViewDocs="viewInvestigation"
          :activeInvestigation="activeInvestigationFromServer"
          :isFromAddDocs="isFromAddDocs"
        />
      </div>

      <div v-if="modal.modalContent == 'raise'">
        <RaiseNonConformanceForm
          :entityMongoId="businessId"
          :entityRole="'business'"
          :investigation="activeInvestigation"
          @closeModal="closeModal"
          @toast="toast"
        />
      </div>

      <div v-if="modal.modalContent === 'summary'">
        <SummaryReport
          :dateRange="selectedDateRangeData"
          :businessId="businessId"
        />
      </div>

      <div v-if="modal.modalContent === 'detailed'">
        <div class="mb-16">
          <div v-if="!sendReportLoading">
            <FormulateForm
              v-model="sendReportModel"
              @submit="onSubmitSendReport"
            >
              <div class="items-center justify-between">
                <FormulateInput
                  type="email"
                  name="email"
                  label="Email address"
                  validation="required|email"
                  @input="handleStripEmailCase($event)"
                />
                <div>
                  <label class="mb-1 text-sm font-medium"
                    >Report Date Range</label
                  >
                  <DatePicker
                    ref="childDatePicker"
                    :date="selectedDateRange1"
                    :disabledBefore="null"
                    :displayEvent="false"
                    :displayBreach="false"
                    @selected="selectedDateRange1 = $event"
                    range
                    noNavigation
                    :clearable="false"
                    class="mt-1 mb-5 send-datepicker"
                  />
                </div>
                <FormulateInput
                  type="submit"
                  label="Submit"
                  input-class="w-full mt-5 btn btn-primary"
                  :disabled="!canSend() || sendReportLoading"
                />
              </div>
            </FormulateForm>
          </div>

          <div
            v-else
            class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
          >
            <font-awesome-icon
              icon="cog"
              spin
              size="3x"
              class="text-gray-400"
            />
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>

      <div v-if="modal.modalContent === 'viewChainOfActions'">
        <ViewChainOfActionsModal
          :entityId="investigationId"
          :type="'investigation'"
          @toast="toast"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { AddInvestigationRegister } from "@/components/forms/business/investigation-register/";
import ViewInvestigationRegisterDetails from "@/components/view/business/investigation-register/investigation-register-details";
import DatePickerWithRange from "@/components/commons/field/DatePickerWithRange";
import moment from "moment";
import {
  formatDate,
  formatPaginationSettings,
  capitalizeFirstLetter,
} from "@/_helper";
import {
  UNFINISHED_INVESTIGATION,
  FINISHED_INVESTIGATION,
} from "@/_helper/constants";
import _ from "lodash";
import { AddInvestigationDocumets } from "@/components/forms/business/investigation-register/";
import { RaiseNonConformanceForm } from "@/components/forms";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import { SummaryReport } from "../../components/view/business/fatigue-management/investigation";
import ViewChainOfActionsModal from "../../components/view/business/fatigue-management/ViewChainOfActions.vue";
import DatePicker from "@/components/commons/field/DatePicker.vue";

export default {
  name: "Investigation-Register-Page",
  components: {
    Table,
    Modal,
    DatePickerWithRange,
    AddInvestigationRegister,
    ViewInvestigationRegisterDetails,
    AddInvestigationDocumets,
    RaiseNonConformanceForm,
    Dropdown,
    StyledIconedLink,
    SummaryReport,
    ViewChainOfActionsModal,
    DatePicker,
  },
  data() {
    return {
      isDirty: true,
      selectedDateRangeData: [],
      selectedDriver: null,
      readReports: false,
      writeReports: false,
      disabledBefore: null,
      disabledAfter: moment().format("YYYY-MM-DD"),
      hasRole: true,
      isLoading: false,
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      tableHeader: [
        "Driver Name",
        "Investigation ID",
        "Date raised",
        "Status",
        "Source",
      ],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      modal: {
        modalContent: null,
        modalTitle: "",
        modalId: "",
      },
      formatInvestigations: [],
      investigationOrgnData: [],
      activeInvestigationFromServer: null,
      isFromAddDocs: false,
      UNFINISHED_INVESTIGATION: UNFINISHED_INVESTIGATION,
      FINISHED_INVESTIGATION: FINISHED_INVESTIGATION,
      businessId: null,
      activeInvestigation: null,
      investigationId: null,
      sendReportLoading: false,
      sendReportModel: null,
      selectedDateRange1: [
        moment().format("YYYY-MM-DD"),
        moment().format("YYYY-MM-DD"),
      ],
    };
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readReports = securityAccessObj.readReports;
    } else {
      this.hasRole = false;
    }

    await this.onSearch();
    this.businessId = me.businessId? me.businessId: me.business?._id
    this.init();
  },
  methods: {
    async init(paged = 1) {
      this.selectedDateRange(null, paged);
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const data = {
            driverName: item?.driver?.driverName || item?.driverStringBasedName,
            id: item._id,
            dateRaised: formatDate(item.dateRaised),
            status: capitalizeFirstLetter(item?.investigationStatus),
            source: this.getInvestigationSource(item),
          };
          nItems.push(data);
        });
      }
      return nItems;
    },
    parseInvestigations(data) {
      return data.map((investigationData) => [
        {
          id: investigationData.id,
          name: investigationData.driverName,
          itemType: "string",
          hasImage: false,
          status: investigationData.status,
        },
        {
          id: investigationData.id,
          name: investigationData.id,
          itemType: "string",
        },
        {
          id: investigationData.id,
          name: investigationData.dateRaised,
          itemType: "string",
        },
        {
          id: investigationData.id,
          name: investigationData.status,
          itemType: "string",
        },
        {
          id: investigationData.id,
          name: investigationData.source,
          itemType: "string",
        },
      ]);
    },
    canSearch() {
      return (
        !_.isEmpty(this.selectedDriver) &&
        this.selectedDateRange.length > 1 &&
        !_.isEmpty(this.selectedDateRange[0])
      );
    },
    setSecurityAccess(securityRoles) {
      let initialReadReports = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.reports.readReports?.value) {
          initialReadReports = securityConfig.reports.readReports.value;
        }
      });
      return {
        readReports: initialReadReports,
      };
    },
    onClear() {
      this.isDirty = true;
      this.linkDataType = "prestart";
      this.$refs.childInvestigationDatePicker.onClear();
    },
    updatelinkDataType() {
      if (this.selectedDateRange.length && this.selectedDriver) {
        this.isDirty = false;
      }
    },
    async onSearch() {
      this.disabledAfter = moment().format(this.dateFormat);
      if (this.canSearch()) {
        const me = this.$store.getters[`account/me`];
        let filter = {
          businessId: me?.businessId,
          driverId: this.selectedDriver.driverId,
        };

        const proxySite = this.$store.getters[`account/proxySite`];
        if (!_.isEmpty(proxySite) && _.isObject(proxySite)) {
          filter["siteId"] = proxySite._id;
        }
        await this.$store
          .dispatch(`business/getContractByDriver`, {
            uid: this.selectedDriver.driverId,
            query: {
              skip: 0,
              limit: 10,
              filter,
            },
          })
          .then(({ results }) => {
            if (results.length) {
              const sortedContracts = _.orderBy(
                results,
                ["startDate"],
                ["desc"]
              );
              const contract = _.first(sortedContracts);
              this.isContractor = contract?.isContractor ? true : false;
              const contractDate = contract.startDate
                ? contract.startDate
                : contract.createdAt;
              const dateToDisable =
                this.isContractor &&
                contract?.contractorAccess == "moving-forward"
                  ? moment(contractDate, this.dateFormat)
                  : moment(contractDate, this.dateFormat).subtract(56, "days");
              this.disabledBefore = dateToDisable.format(this.dateFormat);
              if (contract?.endDate)
                this.disabledAfter = moment(contract.endDate).format(
                  "YYYY-MM-DD"
                );
            } else {
              this.disabledBefore = moment(this.selectedDriver.createdAt)
                .subtract(56, "days")
                .format(this.dateFormat);
            }
          });
        this.$refs.childInvestigationRegister.onSearch();
      }
    },
    newInvestigation() {
      this.$refs.modal.openModal();
      this.modal.modalContent = "new";
      this.modal.modalTitle = "Create New Investigation";
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.init();
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyUp() {
      this.selectedDateRange(this.selectedDateRangeData);
    },
    async selectedDateRange(selectedDateRangeData = [null], paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const businessId = me?.business?._id;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
        businessId: businessId,
      };

      if (
        selectedDateRangeData &&
        selectedDateRangeData.length != 0 &&
        selectedDateRangeData[0]
      ) {
        query.from = moment(selectedDateRangeData[0], "YYYY-MM-DD").format();
        query.to = moment(selectedDateRangeData[1], "YYYY-MM-DD").format();
        this.selectedDateRangeData = [
          new Date(moment(selectedDateRangeData[0])),
          new Date(moment(selectedDateRangeData[1])),
        ];
      } else {
        this.selectedDateRangeData = [];
      }

      let data = await this.$store
        .dispatch("investigation/getInvestigationsByBusinessId", {
          businessId: businessId,
          query,
        })
        .catch((err) => {
          this.isLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });

      if (!_.isEmpty(data)) {
        this.paginationSettings = formatPaginationSettings(
          new Object(data["metadata"])
        );
        this.investigationOrgnData = data.results;
        this.formatInvestigations = this.formatItem(
          _.orderBy(data.results, ["createdAt"], ["desc"])
        );
      } else {
        this.investigationOrgnData = [];
        this.formatInvestigations = [];
      }

      if (this.$refs["dropdown"]) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    debouncedSearchString() {
      this.init();
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async addDocuments(itemId, isFromAddDocs = false) {
      this.$refs.modal.openModal();
      this.isFromAddDocs = isFromAddDocs;
      this.modal.modalContent = "add-documents";
      this.modal.modalTitle = "Add Documents";
      await this.activeInvestigationData(itemId);
    },
    async viewInvestigation(itemId) {
      this.activeInvestigationFromServer = null;
      this.$refs.modal.openModal();
      this.modal.modalContent = "view";
      this.modal.modalTitle = "Investigation Details";
      await this.activeInvestigationData(itemId);
    },
    raiseNonConformance(investigation) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "raise";
      this.modal.modalTitle = "Raise a Non Conformance";
      this.activeInvestigation = investigation;
    },
    async reOpenInvestigation(itemId) {
      const me = this.$store.getters[`account/me`];
      const businessId = me?.business?._id;

      let payload = {};
      payload.id = itemId;
      payload.businessId = businessId;

      await this.$store
        .dispatch("investigation/reOpenInvestigation", payload)
        .then(() => {
          setTimeout(() => {
            this.init();
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Investigation successfully re-opened.",
            });
          }, 400);
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });
    },
    async activeInvestigationData(id) {
      await this.$store
        .dispatch(`investigation/getInvestigation`, id)
        .then((data) => {
          this.activeInvestigationFromServer = data;
        })
        .catch((err) => {
          this.$refs.modal.closeModal();
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
        });
    },
    unfinished(status) {
      return UNFINISHED_INVESTIGATION.includes(status);
    },
    finished(status) {
      return FINISHED_INVESTIGATION.includes(status);
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    handleSummaryOrDetailedReportClick(reportType) {
      if (!this.selectedDateRangeData || !this.selectedDateRangeData.length) {
        alert("Please select date range!");
        return;
      }

      this.modal.modalContent = reportType;
      this.modal.modalTitle = `Investigation ${capitalizeFirstLetter(
        reportType
      )} Report`;
      this.$refs.modal.openModal();
    },
    sendDetailedReport(reportType) {
      this.modal.modalContent = reportType;
      this.modal.modalTitle = `Investigation ${capitalizeFirstLetter(
        reportType
      )} Report`;
      this.$refs.modal.openModal();
    },
    getInvestigationSource(investigation) {
      const { performance, filledFormType } = investigation;

      if (performance) {
        return "Performance";
      } else if (filledFormType) {
        return capitalizeFirstLetter(filledFormType);
      } else {
        return "Default";
      }
    },
    viewChainOfActions(investigationId) {
      this.$refs.modal.openModal();
      this.modal.modalContent = "viewChainOfActions";
      this.modal.modalTitle = "Chain Of Actions";
      this.investigationId = investigationId;
    },

    handleStripEmailCase(value) {
      this.sendReportModel.email = value.toLowerCase();
    },

    canSend() {
      return (
        !_.isEmpty(this.sendReportModel?.email) &&
        this.selectedDateRange1.length > 1 &&
        !_.isEmpty(this.selectedDateRange1[0])
      );
    },

    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      if (recipientEmail) {
        this.sendReportLoading = true;

        const me = this.$store.getters[`account/me`];
        const data = {
          recipientEmail: recipientEmail,
          businessId: me?.businessId,
          startDate: moment().format(`${this.selectedDateRange1[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange1[1]}THH:mm:ssZ`),
        };

        await this.$store
          .dispatch(`business/sendInvestigationSummaryReport`, data)
          .then((res) => {
            this.toast("success", res.message);
          })
          .catch((err) => {
            this.toast("error", err.message);
          });

        this.sendReportLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    investigationData() {
      if (this.formatInvestigations) {
        return this.parseInvestigations(this.formatInvestigations);
      } else {
        return [];
      }
    },
  },
};
</script>
<style scoped lang="scss">
.send-datepicker::v-deep > div {
  @apply w-full;
}
.active {
  @apply border-orange-primary;
}
.vertical {
  min-width: 7px;
  line-height: 7px;
  writing-mode: tb-rl;
  writing-mode: vertical-rl;
  white-space: nowrap;
  text-align: center;
  transform: rotate(180deg);
}
.compliance-container {
  min-height: calc(100vh - 92px);
}
</style>
