<template>
  <div class="w-full">
    <div v-if="!isSuccess" class="w-full h-full">
      <div v-if="!isLoading" class="flex items-center p-4 bg-gray-100 rounded">
        <font-awesome-icon
          icon="info-circle"
          size="2x"
          :style="{ color: theme.primaryColor }"
        />
        <strong class="ml-1 text-sm font-semibold uppercase"
          >Search a breach report.</strong
        >
      </div>
      <div
        v-if="isLoading"
        class="absolute inset-0 flex flex-col items-center justify-center w-full p-2 text-lg text-center"
      >
        <font-awesome-icon icon="cog" spin size="4x" class="text-gray-400" />
        <h6 class="mt-4">{{ defaultLoadingMessage }}</h6>
      </div>
    </div>

    <div v-if="isSuccess" class="w-full compliance-tabs-wrapper">
      <div class="flex">
        <div class="flex-initial w-80">
          <small>
            <strong>Date Range: </strong>
            {{ momentFormat(selectedDateRange[0], true) }} to
            {{ momentFormat(selectedDateRange[1], true) }}
          </small>
        </div>
        <div class="flex-initial w-48">
          <small>
            <strong>Found:&nbsp;</strong>
            {{ breachCount }} Breach{{ breachCount ? "es" : "" }}
          </small>
        </div>
      </div>

      <div class="flex mt-2">
        <small class="mt-1">
          <strong class="break-keep">Drivers: </strong>
        </small>
        <div v-if="selectAllData" class="ml-2">
          <span
            class="inline-flex items-center px-2 py-1 mb-1 mr-2 text-sm font-medium text-gray-700 bg-gray-100 rounded"
          >
            {{ selectAllData?.driverCount }} Drivers
          </span>
        </div>
        <div v-else class="ml-2">
          <span
            v-for="(driver, index) in selectedDrivers"
            :key="index"
            class="inline-flex items-center px-2 py-1 mb-1 mr-2 text-sm font-medium text-gray-700 bg-gray-100 rounded"
          >
            {{ driver.driver.driverName }}
          </span>
        </div>
      </div>

      <div class="relative mt-5 overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 bg-gray-50">Driver</th>
              <th scope="col" class="px-6 py-3 bg-gray-50">Time</th>
              <th scope="col" class="px-6 py-3">Option</th>
              <th scope="col" class="px-6 py-3">Period</th>
              <th scope="col" class="px-6 py-3">Work/Rest</th>
              <th scope="col" class="px-6 py-3">Level</th>
              <th scope="col" class="px-6 py-3">Note</th>
              <th scope="col" class="px-6 py-3">Action</th>
            </tr>
          </thead>

          <tbody v-if="breachData.length > 0">
            <tr
              v-for="(item, index) in breachData"
              :key="index"
              class="bg-white border-b"
            >
              <td class="px-6 py-4">
                {{ item.driver.driverName }}
              </td>
              <td class="px-6 py-4">
                {{ formatTime(item.time, item.timezone) }}
              </td>
              <td class="px-6 py-4">
                {{ item.option }}
              </td>
              <td class="px-6 py-4">
                {{ item.period }}
              </td>
              <td class="px-6 py-4">
                {{ item.type }}
              </td>
              <td class="px-6 py-4 bg-gray-50 dark:bg-gray-800">
                {{ cleanLevel(item.level) }}
              </td>
              <td class="px-6 py-4">
                {{ item.note }}
              </td>
              <td class="px-6 py-4">
                <div class="flex text-lg whitespace-no-wrap text-black-primary">
                  <a class="flex cursor-pointer" @click.prevent="editNote(item)"
                    ><font-awesome-icon
                      icon="sticky-note"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="viewBreach(item._id)"
                    ><font-awesome-icon
                      icon="eye"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="sendBreach(item.driverId)"
                    ><font-awesome-icon
                      icon="paper-plane"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="
                      handleAIReponseGeneration(item._id, item.aiInterpretation)
                    "
                    ><font-awesome-icon
                      icon="brain"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>

          <tbody v-if="!breachData.length">
            <tr class="border-b border-gray-200">
              <td colspan="9" class="px-5 py-8 text-sm text-center">
                No breach found
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal ref="modal" :title="modal.title" size="xl" :height="modal.height">
      <div v-if="modal.content == 'view'">
        <BreachDetailsView :activeBreach="activeBreach" />
      </div>

      <div v-if="modal.content == 'note'">
        <FormulateInput
          v-model="activeBreach.breachData.note"
          type="textarea"
          name="breachNote"
          label="Breach Note"
        />

        <FormulateInput
          type="submit"
          label="Submit"
          input-class="w-full btn btn-primary"
          :disabled="
            selectedDateRange[0] == null ||
            selectedDateRange[1] == null ||
            !activeBreach.breachData.note
          "
          :style="{ backgroundColor: theme.secondaryColor }"
          @click="saveNote()"
        />
      </div>
      <div v-if="modal.content == 'ai'">
        <FormulateInput
          v-model="selectedAIInterpretation"
          :rows="30"
          type="textarea"
          :disabled="true"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { Modal } from "@/components/commons";
import BreachDetailsView from "@/components/view/breach";
import { mapGetters } from "vuex";

export default {
  name: "Breach-Engine-Report",
  components: { Modal, BreachDetailsView },
  data() {
    return {
      isLoading: false,
      isSuccess: false,
      annotationData: [],
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
      selectedDrivers: [],
      selectedDateRange: [],
      breachData: [],
      breachCount: 0,
      selectAllData: null,
      activeBreach: null,
      dateFormat: "YYYY-MM-DD",
      selectedOption: "",
      selectedAIInterpretation: "",
      businessId: null,
      defaultLoadingMessage: "Searching driver details...",
    };
  },
  methods: {
    onClear() {
      this.selectedDrivers = [];
      this.isSuccess = false;
    },
    async onSearch(
      selectedDrivers,
      selectedDateRange,
      selectAllData = null,
      selectedOption = null,
      businessId
    ) {
      this.selectAllData = selectAllData;
      this.selectedDrivers = selectedDrivers;
      this.selectedDateRange = selectedDateRange;
      this.selectedOption = selectedOption;
      this.businessId = businessId;
      this.isLoading = true;
      this.isSuccess = false;

      let filter = {
        entityId: businessId,
      };

      if (selectAllData) {
        filter.selectAll = true;
        if (selectAllData?.except)
          filter.driverId = { $nin: selectAllData?.except };
      } else {
        const driverIds = this.selectedDrivers.map((driver) => driver.driverId);
        filter.driverId = {
          $in: _.union(driverIds, this.selectedDrivers[0]?.driverId),
        };
      }
      if (selectedOption && selectedOption !== "") {
        if (selectedOption == "Standard Solo") {
          filter.option = { $in: ["Standard", "Standard Solo"] };
        } else if (selectedOption == "BFM Solo") {
          filter.option = { $in: ["BFM", "BFM Solo"] };
        } else {
          filter.option = selectedOption;
        }
      }

      const from = moment(selectedDateRange[0], this.dateFormat);
      const to = moment(selectedDateRange[1], this.dateFormat);
      const payload = {
        filter,
        driverId: this.selectedDrivers[0]?.driverId,
        from: from.format(),
        to: to.format(),
      };

      await this.$store
        .dispatch(`report/getBreachesByDateRange`, payload)
        .then((breaches) => {
          this.breachCount = breaches.length || 0;
          this.breachData = breaches;
        });

      this.isLoading = false;
      this.isSuccess = true;
    },
    momentFormat(date, isShort = false) {
      if (date) {
        if (isShort) {
          return date ? moment(date).format("MMM D, YYYY") : "-";
        } else {
          return date ? moment(date).format("MMMM D, YYYY") : "-";
        }
      }
    },
    cleanLevel(level) {
      level = typeof level == "string" ? level : level[0];
      return _.startCase(
        level == "no-breach" ? level.replace("-", " ") : level.split("-")[0]
      );
    },
    formatTime(time, timezone) {
      return moment(time).utcOffset(timezone).format("YYYY-MM-DD HH:mm:ssZ");
    },
    viewBreach(id) {
      this.$refs.modal.openModal();
      this.modal.content = "view";
      this.modal.title = "Breach Info";

      const breachData = this.breachData.filter((item) => item._id === id)[0];
      this.activeBreach = {
        breachData: this.breachData.filter((item) => item._id === id)[0],
        driverData: breachData?.driver,
      };
    },
    sendBreach(driverId) {
      this.$emit("sendDriverBreach", driverId);
    },
    editNote(breach) {
      this.$refs.modal.openModal();
      this.modal.content = "note";
      this.modal.title = "Breach Note";
      this.activeBreach = {
        breachData: breach,
      };
    },
    async saveNote() {
      if (this.activeBreach.breachData) {
        const payload = {
          note: this.activeBreach.breachData.note,
          id: this.activeBreach.breachData._id,
        };
        await this.$store
          .dispatch(`breach/updateNote`, payload)
          .then(() => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Note successfully updated.",
            });
            this.$refs.modal.closeModal();
          })
          .catch((err) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: err?.message,
            });
            this.$refs.modal.closeModal();
            this.activeBreach = null;
          });
      }
    },
    async handleAIReponseGeneration(breachId, aiInterpretation) {
      if (aiInterpretation) {
        this.$refs.modal.openModal();
        this.modal.content = "ai";
        this.modal.title = "AI Interpretation";
        this.selectedAIInterpretation = aiInterpretation;
      } else {
        const payload = {
          type: "BREACH_INTERPRETATION",
          breachId,
        };
        try {
          this.defaultLoadingMessage = "Generating AI Interpretation...";
          this.isSuccess = false;
          this.isLoading = true;
          const response = await this.$store.dispatch(
            `ai/generateAIResponse`,
            payload
          );

          this.isLoading = false;
          this.isSuccess = true;
          // Reverting loading message
          this.defaultLoadingMessage = "Searching driver details...";
          this.selectedAIInterpretation = response;
          await this.onSearch(
            this.selectedDrivers,
            this.selectedDateRange,
            this.selectAllData,
            this.selectedOption,
            this.businessId
          );
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "AI Interpretation successfully generated.",
          });
          this.$refs.modal.openModal();
          this.modal.content = "ai";
          this.modal.title = "AI Interpretation";
        } catch (error) {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: error?.message,
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
