<template>
  <div>
    <div v-if="readPartners" class="h-full">
      <Tabs clickEvent @click="fetchTab" class="flex flex-col h-full">
        <Tab title="All" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="partnersData"
            @onPageChange="onPageChange($event)"
            :has-multi-filter="true"
            @onMultiFilter="onMultiFilter"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="filter.status"
                    @change="filterChange"
                    class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewPartner(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="clipboard"
                          class="my-auto mr-2"
                        />
                        View
                      </StyledIconedLink>
                    </li>
                    <li v-if="writePartners">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editPartner(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="edit" class="my-auto mr-2" />
                        Edit
                      </StyledIconedLink>
                    </li>
                    <li v-if="writePartners">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="editPricing(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="cash-register" class="my-auto mr-2" />
                        Update Pricing
                      </StyledIconedLink>
                    </li>
                    <li>
                      <DrillDown
                        v-if="writePartners"
                        :uid="data.uid"
                        role="partner"
                      />
                    </li>
                    <li v-if="!data.uid">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="resendInvite(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="paper-plane"
                          class="my-auto mr-2"
                        />
                        Resend
                      </StyledIconedLink>
                    </li>
                    <li v-if="!data.uid">
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="deletePartner(data)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon icon="trash" class="my-auto mr-2" />
                        Delete
                      </StyledIconedLink>
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template #callToActionTop>
              <button
                v-if="writePartners"
                class="btn btn-primary"
                @click.prevent="newPartner()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                New Partner
              </button>
            </template>
          </Table>
        </Tab>
        <Tab title="Solo Orphan" class="flex-1 flex flex-col">
          <Table
            :isLoading="isLoading"
            :tableHeader="tableHeader"
            :paginationSettings="paginationSettings"
            :data="partnersData"
            :has-multi-filter="true"
            @onPageChange="onPageChange($event)"
          >
            <template #tableFilters>
              <div class="flex flex-row mb-1 sm:mb-0">
                <div class="relative">
                  <select
                    v-model="filter.limit"
                    @change="filterChange"
                    class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  >
                    <option :value="10">10</option>
                    <option :value="20">20</option>
                    <option :value="50">50</option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
                <div class="relative">
                  <select
                    v-model="filter.status"
                    @change="filterChange"
                    class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
                  >
                    <option
                      v-for="(filterStatus, index) in filterStatuses"
                      :key="index"
                      :value="index"
                    >
                      {{ filterStatus }}
                    </option>
                  </select>
                  <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                  >
                    <font-awesome-icon
                      icon="caret-down"
                      class="text-gray-400"
                    />
                  </div>
                </div>
              </div>
              <div class="block relative">
                <span
                  class="h-full absolute inset-y-0 left-0 flex items-center pl-2"
                >
                  <font-awesome-icon icon="search" class="text-gray-400" />
                </span>
                <input
                  placeholder="Search"
                  class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
                  v-model="filter.search"
                  @keydown="handleSearchKeyDown"
                />
              </div>
            </template>
            <template #default="{ data }">
              <td class="px-5 py-2 text-center relative">
                <Dropdown ref="dropdown">
                  <ul class="py-2 text-sm">
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="viewPartner(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="clipboard"
                          class="my-auto mr-2"
                        />
                        View
                      </StyledIconedLink>
                    </li>
                    <li>
                      <StyledIconedLink
                        class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                        @click.prevent="removeFromOrphan(data.id)"
                        :iconHoverColor="theme.secondaryColor"
                      >
                        <font-awesome-icon
                          icon="times-circle"
                          class="my-auto mr-2"
                        />
                        Remove
                      </StyledIconedLink>
                    </li>
                    <li>
                      <DrillDown
                        v-if="writePartners"
                        :uid="data.uid"
                        role="partner"
                      />
                    </li>
                  </ul>
                </Dropdown>
              </td>
            </template>
            <template #callToActionTop>
              <button
                v-if="writePartners"
                class="btn btn-primary"
                @click.prevent="openAddPartnerModal()"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Add Partner
              </button>
            </template>
          </Table>
        </Tab>
      </Tabs>
      <div v-if="!readPartners && hasRole" class="text-center">
        <h1>
          Not authorized to view partners. Please contact logmaster support.
        </h1>
      </div>
      <div v-if="!hasRole" class="text-center">
        <h1>
          You are currently not associated with a role. Please contact support.
        </h1>
      </div>
      <Modal
        ref="modal"
        :title="modalTitle"
        :size="modalSize"
        :height="modalHeight"
      >
        <div v-if="modalContent == 'new'">
          <NewConnectionForm
            @closeModal="closeModal"
            @submitSuccess="submitSuccess"
            connectionType="partner"
          />
        </div>
        <div v-if="modalContent == 'view'">
          <ConnectionView
            @editPartner="editPartner"
            :detailsId="modalId"
            connectionType="partner"
          />
        </div>
        <div v-if="modalContent == 'edit'">
          <EditConnectionForm
            @closeModal="closeModal"
            @submitSuccess="submitSuccess"
            :detailsId="modalId"
            connectionType="partner"
          />
        </div>
        <div v-if="modalContent === 'edit-pricing'">
          <EditPricingForm
              @closeModal="closeModal"
              @submitSuccess="submitSuccess"
              :detailsId="modalId"
              connectionType="partner"
          />
        </div>
        <div v-if="modalContent == 'orphan-list'">
          <AddPartnerToOrphanList
            @closeModal="closeModal"
            @submitSuccess="submitSuccess"
            :detailsId="modalId"
            connectionType="partner"
          />
        </div>
      </Modal>
    </div>
  </div>
</template>
<script>
import { Modal, Table, Dropdown } from "@/components/commons";
import Tabs from "@/components/commons/tab/Tabs";
import Tab from "@/components/commons/tab/Tab";
import {
  NewConnectionForm,
  EditConnectionForm,
} from "@/components/forms/user/connection";
import { AddPartnerToOrphanList } from "@/components/forms/partner";
import ConnectionView from "@/components/view/admin/connection";
import DrillDown from "@/components/modules/DrillDown.vue";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import EditPricingForm from "@/components/forms/admin/pricing/edit-pricing-form.vue";

export default {
  name: "Admin-Partners",
  components: {
    EditPricingForm,
    Modal,
    Table,
    Dropdown,
    ConnectionView,
    DrillDown,
    NewConnectionForm,
    EditConnectionForm,
    StyledIconedLink,
    Tabs,
    Tab,
    AddPartnerToOrphanList,
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalSize: "3xl",
      modalId: "",
      partners: [],
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        {
          name: "Business Name",
          type: "text",
          fieldName: "persona.businessName",
          isFilterable: true
        },
        {
          name: "Email",
          type: "text",
          fieldName: "persona.contactEmail",
          isFilterable: true
        },
        {
          name: "Phone",
          type: "text",
          fieldName: "persona.contactPhoneNumber",
          isFilterable: true
        },
        {
          name: "ABN",
          type: "text",
          fieldName: "persona.abn",
          isFilterable: true
        },
        {
          name: "Date Created",
          type: "date", //text, date, number
          fieldName: "createdAt",
          isFilterable: true
        },
      ],
      tableData: [],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      filterStatuses: {
        all: "All",
      },
      connectionType: null,
      readPartners: false,
      writePartners: false,
      hasRole: true,
      filter: {
        limit: 10,
        status: "all",
        search: "",
        type: "",
      },
      multiFilter: {}
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async onMultiFilter(data) {
      this.multiFilter = {...data};
      this.init();
    },
    async fetchTab(data) {
      switch (data) {
        case "All":
          this.filter.type = "";
          break;
        case "Solo Orphan":
          this.filter.type = "orphan";
          break;
      }
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      let securityRoles = me?.securityRoles;
      if (securityRoles && securityRoles.length > 0) {
        this.connectionType = securityRoles[0].type;
        const securityAccessObj = await this.setSecurityAccess(securityRoles);
        this.readPartners = securityAccessObj.readPartners;
        this.writePartners = securityAccessObj.writePartners;
      } else {
        this.hasRole = false;
      }
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if(Object.keys(this.multiFilter).length > 0) {
        query.filter = {...this.multiFilter}
      }

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };

      if (this.filter.type == "orphan") {
        query.filter.soloOrphan = { $eq: true };
      }

      const partners = await this.$store.dispatch(`partner/getPartner`, {
        query,
      });
      
      if (partners?.metadata) {
        this.paginationSettings = formatPaginationSettings(
          partners?.metadata
        );
      } else {
        this.paginationSettings = formatPaginationSettings();
      }

      const partnersTableData = this.formatItem(
        _.orderBy(partners?.results, ["createdAt"], ["desc"])
      );
      this.partners = partnersTableData;
      this.isLoading = false;
      

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    newPartner() {
      this.modalContent = "new";
      this.modalTitle = "New Partner";
      this.$refs.modal.openModal();
    },
    viewPartner(id) {
      this.modalId = id;
      this.modalContent = "view";
      this.modalTitle = "Partner Info";
      this.$refs.modal.openModal();
    },
    editPartner(id) {
      this.modalId = id;
      this.modalContent = "edit";
      this.modalTitle = "Partner Edit";
      this.$refs.modal.openModal();
    },
    editPricing(id) {
      this.modalId = id;
      this.modalContent = "edit-pricing";
      this.modalTitle = "Partner Pricing Edit";
      this.$refs.modal.openModal();
    },
    async openAddPartnerModal() {
      this.modalSize = "5xl";
      this.modalContent = "orphan-list";
      this.modalTitle = "Add Partner to orphan list";
      this.$refs.modal.openModal();
    },
    async removeFromOrphan(partnerId) {
      if (
        confirm("Are you sure you want to remove this partner from orphan?")
      ) {
        await this.$store
          .dispatch("partner/removeFromOrphan", partnerId)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);
            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },
    async resendInvite(partnerId) {
      if (confirm("Resend invite email to this partner?")) {
        await this.$store
          .dispatch("partner/resendInvite", partnerId)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },
    async deletePartner(data) {
      if (
        confirm(
          `Are you sure you want to delete ${data.name}? It cannot be undone.`
        )
      ) {
        await this.$store
          .dispatch("partner/deletePartner", data.id)
          .then((res) => {
            if (res.statusCode > 400) {
              this.$store.commit("setMessageNotify", {
                state: "error",
                message: res.message,
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: res.message,
              });
            }
            this.$store.commit("setDialogNotify", true);

            this.init();
          })
          .catch(() => {
            const errorMessage = `Something went wrong, please try again.`;
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: errorMessage,
            });
          });
      }
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const driver = {
            uid: item?.uid,
            id: item?._id,
            name: item?.persona?.businessName,
            email: item?.persona?.contactEmail,
            phone: item?.persona?.contactPhoneNumber,
            abn: item?.persona?.abn,
            created: formatDate(item?.createdAt),
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    closeModal() {
      this.modalSize = "3xl";
      this.$refs.modal.closeModal();
    },
    async submitSuccess() {
      await this.init();
    },
    closeRefresh() {
      this.$refs.modal.toggleModal();
      this.init();
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parsePartners(data) {
      return data.map((partnerData) => [
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.name,
          itemType: "name",
          hasImage: true,
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.email,
          itemType: "string",
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.phone,
          itemType: "string",
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.abn,
          itemType: "string",
        },
        {
          uid: partnerData.uid,
          id: partnerData.id,
          name: partnerData.created,
          itemType: "string",
        },
      ]);
    },
    setSecurityAccess(securityRoles) {
      let initialReadPartners = false;
      let initialWritePartners = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.partners.readPartners.value) {
          initialReadPartners = securityConfig.partners.readPartners.value;
        }
        if (securityConfig.partners.writePartners.value) {
          initialWritePartners = securityConfig.partners.writePartners.value;
        }
      });

      return {
        readPartners: initialReadPartners,
        writePartners: initialWritePartners,
      };
    },
  },
  computed: {
    partnersData() {
      if (this.partners) {
        return this.parsePartners(this.partners);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
