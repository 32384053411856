<template>
  <div class="mb-16">
    <FormulateForm v-model="values" @submit="onNext">
      <div v-if="loading" class="text-center mt-28"><ui-spinner class="m-auto"></ui-spinner> Loading...</div>
      <div v-if="!loading" class="items-center justify-between">
        <div class="formulate-input-group-repeatable">
            <FormulateInput
              type="text"
              name="businessName"
              label= 'Business Name'
              validation= 'required'
            />
            <div class="grid grid-cols-2 gap-4">
              <FormulateInput
                type="text"
                name="tradingName"
                label= 'Trading Name'
                validation= 'required'
              />
              <FormulateInput
                type="text"
                name="abn"
                label= 'ABN'
                validation= 'required'
              />
            </div>
            <div class="grid grid-cols-2 gap-4">
              <FormulateInput
                type="email"
                name="supportEmail"
                label= 'Support Email'
                validation= 'optional|email'
                @input="handleStritEmailCase($event)"
              />
              <FormulateInput
                type="text"
                name="supportPhoneNumber"
                label= 'Support Phone Number'
              />
            </div>
            <div class="border-t-2 mt-2 pt-4">
               <div class="formulate-input-group-repeatable">
                <FormulateInput
                type="places-search-input"
                name= "businessAddress"
                label= 'Business address'
                validation= 'required'
                />
               </div>
            </div>
         </div>
        <div class="pt-4 grid grid-cols-2 gap-4 mb-20">
          <button class="btn col-span-2 btn-primary mt-2" :style="{backgroundColor: theme.secondaryColor}">Next</button>
        </div>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    connectionType: String,
    postData: null
  },
  data() {
    return {
      errorMessage: '',
      loading: false,
      values: {},
      interChangingData: {}
    };
  },
  mounted(){
    if(this.postData){
      this.values = this.postData?.businessDetails || null
      this.interChangingData = this.postData
    }
  },
  methods: {
    onNext() {
      this.loading = true
      const businessDetails = JSON.parse(JSON.stringify(this.values))
      if(businessDetails){

        const data = {...this.interChangingData, ...{
          businessDetails: businessDetails,
          connectionType: this.connectionType
        }}
        
        this.$emit('nextStep', data)
      }
    },
    handleStritEmailCase(value){
      this.values.supportEmail = value.toLowerCase()
    }
  },
   computed: {
    ...mapGetters('theme', {
        theme: 'getColorScheme'
    })
   }
}
</script>