import { render, staticRenderFns } from "./pricing.vue?vue&type=template&id=f8a2d522&scoped=true"
import script from "./pricing.vue?vue&type=script&lang=js"
export * from "./pricing.vue?vue&type=script&lang=js"
import style0 from "./pricing.vue?vue&type=style&index=0&id=f8a2d522&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8a2d522",
  null
  
)

export default component.exports