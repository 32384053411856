<template>
  <div>
    <Table
      :isLoading="isLoading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="fitNessDeclarationData"
      @onPageChange="onPageChange($event)"
      :has-multi-filter="true"
      @onMultiFilter="onMultiFilter"
    >
      <template #tableFilters>
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="filter.status"
              @change="filterChange"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @keydown="handleSearchKeyDown"
          />
        </div>
      </template>
      <template #callToActionTop>
        <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
          <button
              class="btn btn-primary"
              @click.prevent="getReport()"
              :style="{ backgroundColor: theme.secondaryColor }"
              title="Generate Report"
          >
            <font-awesome-icon icon="file-export" class="my-auto" />
            Generate Report
          </button>
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="
                    viewFitnessDeclaration(data.id, data.isDefault)
                  "
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="clipboard" class="my-auto mr-2" />
                  View
                </StyledIconedLink>
              </li>
              <li>
                <StyledIconedLink
                    class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                    @click.prevent="viewEditNote(data.id, data.note)"
                    :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="sticky-note" class="my-auto mr-2" />
                  Note
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
    </Table>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'view'">
        <FitnessDeclarationDetailsView
          v-if="!isOldView"
          :activeFitnessDeclaration="activeFitnessDeclaration"
        />
        <FitnessDeclarationDetailsOldView
          v-else
          :activeFitnessDeclaration="activeFitnessDeclaration"
        />
      </div>

      <div v-if="modalContent === 'edit-note'">
        <div class="mb-16">
          <div class="loading centered" v-if="loading">
            <ui-spinner class="mx-auto"></ui-spinner>
          </div>
          <div v-if="!loading">
            <FormulateForm @submit="onSubmit">
              <FormulateInput
                  type="text"
                  name="note"
                  v-model="note"
                  label="Note"
                  :disabled="false"
              />
              <StyledSubmit :backgroundColor="theme.secondaryColor">
                <FormulateInput
                    type="submit"
                    label="Save"
                    input-class="btn btn-primary w-full"
                />
              </StyledSubmit>
            </FormulateForm>
          </div>
        </div>
      </div>

      <div v-if="modalContent === 'send-report'">
        <div class="mb-16">
          <div v-if="!sendReportLoading">
            <FormulateForm v-model="sendReportModel" @submit="onSubmitSendReport">
              <div class="items-center justify-between">
                <FormulateInput
                    type="email"
                    name="email"
                    label="Email address"
                    validation="required|email"
                    @input="handleStripEmailCase($event)"
                />
                <div>
                  <label class="font-medium text-sm mb-1">Report Date Range</label>
                  <DatePicker
                      ref="childDatePicker"
                      :date="selectedDateRange"
                      :disabledBefore="null"
                      :displayEvent="false"
                      :displayBreach="false"
                      @selected="selectedDateRange = $event"
                      range noNavigation :clearable="false"
                      class="mb-5 mt-1 send-datepicker"
                  />
                </div>
                <FormulateInput
                    type="submit"
                    label="Submit"
                    input-class="btn btn-primary w-full mt-5"
                    :disabled="!canSend() || sendReportLoading"
                />
              </div>
            </FormulateForm>
          </div>

          <div v-else class="p-2 w-full text-center text-lg absolute inset-0 flex flex-col justify-center items-center">
            <font-awesome-icon icon="cog" spin size="3x" class="text-gray-400"/>
            <h6 class="mt-4">Sending the report...</h6>
          </div>
        </div>
      </div>

    </Modal>
  </div>
</template>
<script>
import { Table, Modal, Dropdown } from "@/components/commons";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import FitnessDeclarationDetailsView from "@/components/view/business/fitness-declaration";
import FitnessDeclarationDetailsOldView from "@/components/view/business/fitness-declaration/oldView";
import { formatDate, formatPaginationSettings } from "@/_helper";
import _ from "lodash";
import { SEARCH_DEBOUNCE_DELAY } from "@/_helper/constants";
import { StyledIconedLink, StyledSubmit } from "@/styled-components";
import { mapGetters } from "vuex";
import moment from "moment";
import DatePicker from "@/components/commons/field/DatePicker.vue";

export default {
  name: "Fitness-Declarations",
  components: {
    StyledSubmit,
    Table,
    Modal,
    Dropdown,
    FitnessDeclarationDetailsView,
    FitnessDeclarationDetailsOldView,
    StyledIconedLink,
    DatePicker
  },
  data() {
    return {
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      isLoading: false,
      tableHeader: [
        {
          name: "Driver Name",
          type: "text",
          fieldName: "driver.driverName",
          isFilterable: true
        },
        {
          name: "Email Address",
          type: "text",
          fieldName: "driver.emailAddress",
          isFilterable: true
        },
        {
          name: "Licence",
          type: "text",
          fieldName: "driver.driversLicenseNumber",
          isFilterable: true
        },
        {
          name: "Result",
          type: "boolean",
          fieldName: "isPassed",
          isFilterable: true,
          filterOptions: {
            true: "Pass",
            false: "Fail",
          },
        },
        {
          name: "Date Submitted",
          type: "date",
          fieldName: "savedData.date",
          isFilterable: true
        },
        {
          name: "Note",
          type: "text",
          fieldName: "note",
          isFilterable: true
        },
      ],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      fitnessformatItems: [],
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: {
        all: "All",
      },
      readFitness: false,
      hasRole: true,
      activeFitnessDeclaration: null,
      isOldView: false,
      note: '',
      loading: false,
      selectedFormId: '',
      multiFilter: {},
      selectedDateRange: [
        moment().format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      sendReportLoading: false,
      sendReportModel: null,
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async onMultiFilter(data) {
      this.multiFilter = {...data};
      this.init();
    },
    async init(paged = 1) {
      this.isLoading = true;
      const me = this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      if (this.filter.status != "all")
        query.filter.status = { $eq: this.filter.status };
      // Reads from businessId to get business site id, otherwise use main business id
      const businessId = me.businessId? me.businessId: me.business?._id

      if(Object.keys(this.multiFilter).length > 0) {
        query.filter = {...this.multiFilter, hasMultiFilter: true}
      }

      let data = await this.$store.dispatch(
        `driver/getFitToDriveByBusinessId`,
        { businessId, query }
      );

      if (data) {
        this.paginationSettings = formatPaginationSettings(
          new Object(data["metadata"])
        );

        this.fitnessformatItems = this.formatItem(
          _.orderBy(data.results, ["createdAt"], ["desc"])
        );
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    async viewFitnessDeclaration(id, isDefault) {
      let data;
      if (typeof isDefault == "boolean") {
        data = await this.$store.dispatch(`fittodrive/getFitToDriveById`, id);
        this.isOldView = false;
      } else {
        data = await this.$store.dispatch(
          `fittodrive/getOldFitToDriveById`,
          id
        );
        this.isOldView = true;
      }

      if (!_.isEmpty(data)) {
        this.activeFitnessDeclaration = data;
      }

      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Fitness Declaration Details";
    },
    parseFitnessDeclaration(data) {
      return data.map((fitnessDeclarationData) => [
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.name,
          itemType: "name",
          hasImage: false,
          isDefault: fitnessDeclarationData?.isDefault,
          note: fitnessDeclarationData?.note,
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.email,
          itemType: "string",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.license,
          itemType: "string",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.isPassed,
          itemType: "boolean",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.date,
          itemType: "string",
        },
        {
          id: fitnessDeclarationData.id,
          name: fitnessDeclarationData.note,
          itemType: "string",
        },
      ]);
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item._id,
            name: item?.driver?.driverName,
            email: item?.driver?.emailAddress,
            license: item?.driver?.driversLicenseNumber,
            phone: item?.driver?.supportPhone,
            isPassed: item?.isPassed ? "Pass" : "Fail",
            isDefault:
              typeof item?.isDefault == "boolean" ? item?.isDefault : null,
            date: formatDate(item?.savedData?.date),
            note: item?.note,
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },
    async filterChange() {
      await this.init();
    },
    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },
    async viewEditNote(id, note) {
      this.loading = true;
      this.$refs.modal.openModal();
      this.modalContent = "edit-note";
      this.modalTitle = "Edit Note";
      this.selectedFormId = id;
      this.note = note;
      this.loading = false;
    },
    async onSubmit(){
      this.loading = true;
      const payload = {
        note: this.note,
        id: this.selectedFormId,
      }
      await this.$store.dispatch(`fittodrive/saveNote`,payload)
          .then(()=> {
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'success',message: 'Note successfully saved.'});
          }).catch((err) => {
            this.$store.commit('setDialogNotify',true);
            this.$store.commit('setMessageNotify',{state:'error',message:err.message});
          });
      this.loading = false;
      this.$refs.modal.closeModal();
      await this.init();
    },

    getReport() {
      this.$refs.modal.openModal()
      this.modalContent = 'send-report';
      this.modalTitle = 'Send Report To Email';
    },

    handleStripEmailCase(value){
      this.sendReportModel.email = value.toLowerCase()
    },

    canSend() {
      return !_.isEmpty(this.sendReportModel?.email) && this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },

    canSearch() {
      return this.selectedDateRange.length > 1 && !_.isEmpty(this.selectedDateRange[0]);
    },

    async onSubmitSendReport() {
      const recipientEmail = this.sendReportModel.email;
      if (this.canSearch() && recipientEmail) {
        this.sendReportLoading = true;

        const me = this.$store.getters[`account/me`];
        const data = {
          recipientEmail: recipientEmail,
          businessId: me?.businessId,
          startDate: moment().format(`${this.selectedDateRange[0]}THH:mm:ssZ`),
          endDate: moment().format(`${this.selectedDateRange[1]}THH:mm:ssZ`),
        };

        await this.$store
            .dispatch(`business/sendFitToDriveSummaryReport`, data)
            .then((res) => {
              this.toast('success', res.message);
            })
            .catch((err) => {
              this.toast('error', err.message);
            });

        this.sendReportLoading = false;
      }
    },

    toast (state, message) {
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', { state, message });
    },
  },
  computed: {
    fitNessDeclarationData() {
      if (this.fitnessformatItems) {
        return this.parseFitnessDeclaration(this.fitnessformatItems);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>

<style scoped lang="scss">
.send-datepicker::v-deep > div {
  @apply w-full
}
</style>
