import { API } from "@/api/config";

export default new (class Theme {
  //@GET: Fetch all theme options per account
  getThemeListsById(accountId = null) {
    return API.ACCOUNTS.get(`/theme/find-by-accountId/${accountId}`);
  }

  //@GET: Fetch all user created themes
  getUserThemes(accountId = null) {
    return API.ACCOUNTS.get(`/theme/custom-themes/${accountId}`);
  }

  /** Create custom theme */
  createTheme({ accountId, themeName, themeData, themeColors, isActive }) {
    return API.ACCOUNTS.post(`/theme/`, {
      accountId,
      themeName,
      themeData,
      themeColors,
      isActive,
    });
  }
})();
