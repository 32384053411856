import { DriverManagement, Reseller } from "@/api";
import { findRootUID } from '@/_helper';
import _ from 'lodash'

export default{
    // @Reseller
    async createReseller({ commit, dispatch }, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Reseller.createReseller(data)
            commit("setNewCreatedReseller", response.data.data);
            if(!_.isEmpty(data['create_password'])){ // Create reseller with Password
                const newPassword = await dispatch('createResellerPassword',{ id:response.data.data._id, password: data['create_password']})
                return newPassword;
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },
    async updateReseller({state}, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Reseller.patchReseller(data.id, data)
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async generateShareableUrl({state}, data){
        if(!data || _.isEmpty(data)) throw 'Invalid data object'
        try{
            const response = await Reseller.generateShareableUrl(data.id, data)
            return response.data.data
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async createResellerPassword({ commit, state }, data){
        if(!data.id) throw 'Invalid reseller id';
        if(!data.password) throw 'Invalid data password';
        try{
            const response = await Reseller.patchResellerCreatePassword(data.id,{password:data.password, confirmPassword:data.confirmPassword});
            if(!_.isEmpty(state.newCreatedReseller)) commit("setNewCreatedReseller", {...state.newCreatedReseller,password:response.data.data});
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getReseller({ state }, param){
        try{
            const response = await Reseller.getReseller(param);
            if(!response?.data?.data) return [];
            if(param.id){
                response.data.data.uid = findRootUID(response.data.data);
                return response.data.data;
            }else{
                const data = response?.data?.data.map((data) => {
                    data.uid = findRootUID(data);
                    return data
                })
                return { results : data, metadata: response.data?.resultsMeta }
            }
        }catch(error){
            console.log(state);
            throw error.response.data
        }
    },
    async getResellerByUID( { state }, uid ){
        if(!uid) throw 'Invalid uid '+state.me;
        try{
            const response = await Reseller.getResellerByUID(uid);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getResellerByUrl( { state }, data ){
        if(!data) throw 'Invalid uid '+state.me;
        try{
            const response = await Reseller.getResellerByUrl(data);
            response.data.data.uid = findRootUID(response.data.data);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getResellerByWebProfileID( { state }, id ){
        if(!id) throw 'Invalid id '+state.me;
        try{
            const response = await Reseller.getResellerByWebProfileId(id);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getBusinessByResellerUID( { state }, param ){
        if(!param) throw 'Invalid uid '+state.me;
        try{
            const response = await Reseller.getBusinessByReseller(param);
            if(!response?.data?.data) return [];

            return { results : response?.data?.data, metadata:response?.data?.resultsMeta }
        }catch(error){
            throw error.response.data
        }
    },
    
    async getResellerBusinessesByID( { state }, param ){
        if(!param.id) throw 'Invalid uid '+state.me;
        const response = await Reseller.getResellerBusinessesById(param);
        
        if(!response?.data?.data) return [];
        try{
            if(response?.data?.data){
                return { results : response?.data?.data, metadata: response.data?.resultsMeta }
            }

        }catch(error){
            throw error.response.data
        }
    },

    async getResellerOfLogmasterByUID( { state }, uid ){
        if(!uid) throw 'Invalid uid '+state.me;
        try{
            const response = await Reseller.getResellerOfLogmasterByUID(uid);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },
    async getDriverContractByResellerUID( _ , param ){
        if(typeof param === 'string') param = { uid:param, query: { skip: 0, limit: 999 } }
        if(!param.uid) throw 'Invalid UID or ID';
        try{
            const response = await DriverManagement.getContractByResellerUID(param.uid, param.query);
            if(response.data?.resultsMeta){
                return { results : response.data.data, metadata:response.data.resultsMeta }
            }else{
                return response.data.data
            }
        }catch(error){
            throw error.response.data
        }
    },

    async acceptReseller({state}, id) {
        if(!id) throw `Invalid id ${Number(state.me)}`

        try{
            const response = await Reseller.acceptReseller(id);
            return response.data.data
        }catch(error){
            throw error.response
        }
    },

    async resendInvite( { state }, id ){
        if(!id) throw 'Invalid reseller id'+state.me
        try{
            const data = {
                id: id
            }
            const response = await Reseller.resendInvite(data);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async deleteReseller( { state }, id ){
        if(!id) throw 'Invalid partner id'+state.me
        try{
            const response = await Reseller.deleteReseller(id);
            return response.data
        }catch(error){
            throw error.response.data
        }
    },

    async addModuleToResellers({state}, standardPricingMasterId) {
        if(!standardPricingMasterId) throw 'Invalid standardPricingMasterId '+ state.me;
        try{
            const response = await Reseller.addModuleToResellers(standardPricingMasterId);
            return response.data.data
        }catch(error){
            throw error.response.data
        }
    },

    async getDashboardData({ state }, uid) {
        if (!uid) throw "Invalid uid " + state.me;
        try {
            const response = await Reseller.getDashboardData(uid);
            return response.data.data;
        } catch (error) {
            throw error.response.data;
        }
    },
}