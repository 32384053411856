
export const STATES = [
    { value: 'ACT', label: 'ACT' },
    { value: 'NSW', label: 'NSW' },
    { value: 'NT', label: 'NT' },
    { value: 'QLD', label: 'QLD' },
    { value: 'SA', label: 'SA' },
    { value: 'TAS', label: 'TAS' },
    { value: 'VIC', label: 'VIC' },
    { value: 'WA', label: 'WA' },
]

export const TIMEZONE = [
    { value: 'ACT +10:00', label: 'ACT +10:00' },
    { value: 'ACT +11:00', label: 'ACT +11:00' },
    { value: 'NSW +10:00', label: 'NSW +10:00' },
    { value: 'NSW +11:00', label: 'NSW +11:00' },
    { value: 'NT +09:30', label: 'NT +09:30' },
    { value: 'QLD +10:00', label: 'QLD +10:00' },
    { value: 'SA +10:30', label: 'SA +10:30' },
    { value: 'SA +09:30', label: 'SA +09:30' },
    { value: 'TAS +10:00', label: 'TAS +10:00' },
    { value: 'TAS +11:00', label: 'TAS +11:00' },
    { value: 'VIC +10:00', label: 'VIC +10:00' },
    { value: 'VIC +11:00', label: 'VIC +11:00' },
    { value: 'WA +08:00', label: 'WA +08:00' },
  ]