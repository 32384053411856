<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <div class="mb-10 text-center">
        <div class="flex justify-end text-right">
          <a @click.prevent="onClickEdit()" class="flex cursor-pointer"
            ><font-awesome-icon
              icon="edit"
              class="my-auto mx-1.5 hover:text-orange-primary text-xs"
          /></a>
          <a
            v-if="showInvite && status === 'pending'"
            @click.prevent="resendInvite()"
            class="flex cursor-pointer"
            title="Resend Invite Email"
            ><font-awesome-icon
              icon="paper-plane"
              class="my-auto mx-1.5 hover:text-orange-primary text-xs"
          /></a>
          <a
            v-if="showDelink && status === 'linked'"
            @click.prevent="onClickDelink()"
            class="flex cursor-pointer"
            ><font-awesome-icon
              icon="unlink"
              class="my-auto mx-1.5 hover:text-orange-primary text-xs"
          /></a>
          <a class="flex cursor-pointer"
            ><font-awesome-icon
              icon="trash"
              class="my-auto mx-1.5 hover:text-orange-primary text-xs"
          /></a>
        </div>
        <div class="text-2xl font-bold text-black-primary">
          {{ driver.name }}
        </div>
        <div
          class="text-xs font-bold"
          :class="[driver.isActive ? 'text-green-500' : 'text-red-500']"
        >
          {{ driver.isActive ? "ACTIVE" : "INACTIVE" }}
        </div>
      </div>
      <div class="w-full pb-1 mb-5 border-b">
        <div class="text-xs font-bold">Business Profile</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ profile.baseLocation }}
        </div>
        <div class="text-xs">Base Location</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ profile.baseTimeZone }}
        </div>
        <div class="text-xs">Base Time Zone</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ profile.BFMNumber }}
        </div>
        <div class="text-xs">BFM Accreditation Number</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ profile.AFMNumber }}
        </div>
        <div class="text-xs">AFM Accreditation Number</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ profile.recordKeeperAddress }}
        </div>
        <div class="text-xs">Record Keeper Location</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ profile.engineRegion }}
        </div>
        <div class="text-xs">Region</div>
      </div>
      <div class="w-full pb-1 mb-5 border-b">
        <div class="text-xs font-bold">Driver Details</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ driver.email }}
        </div>
        <div class="text-xs">Email Address</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ driver.driverId }}
        </div>
        <div class="text-xs">Driver ID</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ driver.driverUDI }}
        </div>
        <div class="text-xs">Driver UDI</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ phoneNumber }}
        </div>
        <div class="text-xs">Phone</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ driver.birthday }}
        </div>
        <div class="text-xs">Date Of Birth</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ driver.license }}
        </div>
        <div class="text-xs">Licence</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ driver.licenseState }}
        </div>
        <div class="text-xs">Licence State</div>
      </div>
      <div class="mb-5">
        <div class="text-base font-bold text-black-primary">
          {{ licenseExpiry }}
        </div>
        <div class="text-xs">License Expiry</div>
      </div>
    </div>
  </div>
</template>
<script>
import { formatDate, convertAddressObject } from "@/_helper";
import _ from "lodash";
export default {
  name: "Driver-Details-View",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
    showDelink: {
      type: Boolean,
      default: false,
    },
    showInvite: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      driver: {},
      profile: {},
      status: "",
      businessid: "",
      driverid: "",
      licenseExpiry: "",
      phoneNumber: "",
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const driverDetails = await this.$store.dispatch(
      `business/getContractById`,
      this.detailsId
    );
    const soloDriverDetails = await this.$store.dispatch(
      `driver/getDriverByID`,
      driverDetails?.driverId
    );

    const driverNumber = _.get(
      soloDriverDetails,
      "webProfiles[0].phoneNumber",
      null
    );
    const driverLicenseExpiry = _.get(
      soloDriverDetails,
      "driversLicenseExpiry",
      null
    );
    if (driverDetails) {
      this.driver = this.parseDriver(driverDetails.driver);
      this.profile = this.parseProfile(driverDetails.profile);
      this.driverid = driverDetails.driverId;
      this.status = driverDetails.status;
      this.licenseExpiry = formatDate(driverLicenseExpiry) ?? "None";
      this.phoneNumber = driverNumber ?? "None";
      this.loading = false;
    }
  },
  methods: {
    parseDriver(driver) {
      return {
        baseLocation: convertAddressObject(driver.driversBaseLocation) || "-",
        baseTimezone: driver.driversBaseTimeZone || "-",
        birthday: formatDate(driver.driverDateOfBirth, "DD/MM/YYYY") || "-",
        bfmNumber: driver.driversBFMNumber || "-",
        driverId: driver._id || "-",
        email: driver.emailAddress || "-",
        isActive: driver.isActive || "-",
        license: driver.driversLicenseNumber || "-",
        licenseState: driver.driversLicenseState || "-",
        name: driver.driverName || "-",
        phone: driver.supportPhone || "-",
        driverUDI: driver.udi || "-",
      };
    },
    parseProfile(profile) {
      const baseLocation =
        typeof profile.baseLocation == "string"
          ? profile.baseLocation
          : convertAddressObject(profile.baseLocation);
      return {
        baseLocation: baseLocation || "-",
        baseTimeZone: profile.baseTimeZone || "-",
        BFMNumber: profile.BFMNumber || "-",
        AFMNumber: profile.AFMNumber || "-",
        recordKeeperAddress: profile.recordKeeperAddress || "-",
        engineRegion: profile.engineRegion || "-",
      };
    },
    onClickEdit() {
      this.$emit("editDriver", this.detailsId);
    },
    onClickDelink() {
      if (confirm("Delink this driver?")) {
        this.loading = true;
        const me = this.$store.getters[`account/me`];
        this.businessid = me?.business?._id;
        this.$store
          .dispatch("business/delinkContract", {
            driverId: this.driverid,
            businessId: this.businessid,
          })
          .then((driver) => {
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Driver has been delinked.",
            });
            if (driver) {
              this.$emit("closeRefresh");
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "error",
              message: this.errorMessage,
            });
          });
      }
    },
    resendInvite() {
      const contractId = this.detailsId;
      if (confirm("Resend invite email to this driver?")) {
        this.$store
          .dispatch("business/resendInvite", contractId)
          .then((driver) => {
            if (driver) {
              location.reload();
            }
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
