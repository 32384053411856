<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <FormulateForm
      v-else
      name="editBusinessForm"
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
    >
      <div>
        <FormulateInput
          type="group"
          name="certificate"
          label="Accreditation (Optional)"
          labelClass="font-semibold text-sm block -top-7 -left-0 absolute"
          class="relative p-5 mt-10 mb-5 border rounded"
        >
          <button @click.prevent="showDriverList">
            <small
              v-if="!isDriverListOpen"
              class="px-3 rounded-lg shadow btn-primary btn-xs"
            >
              + Select from accreditation list
            </small>
            <small
              v-else
              class="px-3 text-white bg-blue-200 rounded-lg shadow btn-xs"
            >
              <font-awesome-icon icon="times" />
            </small>
          </button>

          <FormulateInput
            v-if="!isDriverListOpen"
            type="text"
            label="BFM Number"
            name="BFMNumber"
            v-model="BFMStringBased"
            valdation="optional"
          />

          <FormulateInput
            v-else
            type="cert-select"
            name="BFMNumber"
            style="margin-bottom: 0px"
            label="BFM Number"
            class="content-center pb-4"
            validation="optional"
            @input="onChange($event)"
            v-model="BFMselectionBased"
            :clearable="false"
          />

          <FormulateInput
            v-if="isDriverListOpen"
            type="date"
            name="inductionDate"
            label="Induction Date"
            validation="optional"
            v-model="inductionDate"
          />
          <FormulateInput
            type="afm-access-select"
            placeholder="Select an AFM Number"
            name="afmAccess"
            :getOptionKey="(option) => option._id"
            :getOptionLabel="(option) => option.afmNumber"
            v-model="selectedAFMNumber"
            @input="selectAFMNumber($event)"
          />
        </FormulateInput>
      </div>

      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="w-full btn btn-primary"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>

    <div class="mb-3 text-center text-red-500" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "../driver/stepper-component/business-profile-create-schema";
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Edit-Business-Profile-Form",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },

  data() {
    this.$emit("schema", schema);
    return {
      profile: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",

      isDriverListOpen: false,
      BFMStringBased: "",
      BFMselectionBased: {},
      inductionDate: "",
      selectedAFMNumber: null,
    };
  },
  async mounted() {
    this.loading = true;
    const profileDetails = await this.$store.dispatch(
      `business/getContractById`,
      this.detailsId
    );
    if (profileDetails) {
      const profile = this.parseProfile(profileDetails.profile);
      let stringBased;

      if (
        profile?.certificate.length > 0 &&
        typeof profile?.certificate[0]?.BFMNumber === "object"
      ) {
        this.isDriverListOpen = true;
        this.inductionDate = profile?.certificate[0]?.inductionDate;
        this.BFMselectionBased = profile?.certificate[0]?.BFMNumber;
      } else if (typeof profile?.certificate[0]?.BFMNumber === "string") {
        stringBased = { BFMNumber: profile?.certificate[0]?.BFMNumber };
      } else {
        stringBased = { BFMNumber: profile.BFMNumber };
      }

      this.values = {
        profile: [
          {
            recordKeeperAddress: [
              {
                "record-keeper-street": profile.recordKeeperAddress,
              },
            ],
            baseLocation: [
              {
                "base-location-street": profile.baseLocation,
              },
            ],
            baseTimeZone: profile.baseTimeZone,
            BFMNumber: profile.BFMNumber,
            AFMNumber: profile?.AFMNumber,
            BFMSolo: profile.BFMSolo,
            BFM2up: profile.BFM2up,
            engineRegion: profile.engineRegion,
            afmAccess: profile?.afmAccess,
          },
        ],
        certificate: [{ ...stringBased }],
      };

      this.BFMStringBased = profile?.BFMNumber;
      this.selectedAFMNumber = profile?.AFMNumber;
      this.loading = false;
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      if (
        (this.isDriverListOpen &&
          !_.isEmpty(this.BFMselectionBased) &&
          _.isEmpty(this.inductionDate)) ||
        (!_.isEmpty(this.inductionDate) && _.isEmpty(this.BFMselectionBased))
      ) {
        this.errorMessage = "Accreditation details incomplete";
        this.loading = false;
        return;
      }

      if (this.values.certificate.length > 0) {
        this.values.profile[0].certificate = this.values.certificate;
      }

      if (this.isDriverListOpen) {
        const { certNumber } = this.BFMselectionBased;

        this.values.profile[0].certificate = this.values.certificate;
        this.values.profile[0].BFMNumber = certNumber;
      } else {
        this.values.profile[0].certificate = [
          {
            BFMNumber: this.BFMStringBased,
            inductionDate: null,
          },
        ];

        this.values.profile[0].BFMNumber = this.BFMStringBased;
      }

      if (
        this.selectedAFMNumber != null &&
        typeof this.selectedAFMNumber != "string"
      ) {
        this.values.profile[0].afmAccess = this.selectedAFMNumber;
      }

      delete this.values.certificate;

      const parsedProfile = this.parseForSubmit(this.values);

      const updateProfile = await this.$store.dispatch(
        `business/updateContract`,
        { data: parsedProfile, isApproval: false }
      );
      if (updateProfile && updateProfile._id) {
        this.loading = false;
        this.$emit("closeModal");
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "success",
          message: "Business successfully updated.",
        });
        this.$emit("closeRefresh");
      }

      this.loading = false;
    },
    parseProfile(profile) {
      return {
        AFMNumber: profile?.AFMNumber || null,
        baseLocation: profile.baseLocation || null,
        baseTimeZone: profile.baseTimeZone || null,
        BFM2up: profile.BFM2up || false,
        BFMSolo: profile.BFMSolo || false,
        BFMNumber: profile.BFMNumber || null,
        recordKeeperAddress: profile.recordKeeperAddress || null,
        certificate: profile.certificate || [],
        engineRegion: profile.engineRegion,
        afmAccess: profile?.afmAccess,
      };
    },
    parseForSubmit(values) {
      let data = {
        id: this.detailsId,
        profile: {
          AFMNumber: values?.profile[0]?.afmAccess?.afmNumber || null,
          baseLocation:
            `${values.profile[0].baseLocation[0]["base-location-street"]}` ||
            "-",
          baseTimeZone: values.profile[0].baseTimeZone || null,
          BFM2up: values.profile[0].BFM2up || false,
          BFMSolo: values.profile[0].BFMSolo || false,
          BFMNumber: values.profile[0].BFMNumber || null,
          recordKeeperAddress:
            `${values.profile[0].recordKeeperAddress[0]["record-keeper-street"]}` ||
            null,
          certificate: values.profile[0].certificate || [],
          engineRegion: values.profile[0].engineRegion || null,
          afmAccessId: values?.profile[0]?.afmAccess?._id,
        },
      };
      return data;
    },
    showDriverList() {
      this.isDriverListOpen = !this.isDriverListOpen;
    },
    programaticSubmit() {
      this.$formulate.submit("editBusinessForm");
    },
    onChange(data) {
      if (data) {
        this.BFMselectionBased = data;
      }
    },
    selectAFMNumber(data) {
      this.selectedAFMNumber = data;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
