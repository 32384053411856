<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <StyledSubmit :backgroundColor="theme.secondaryColor">
      <FormulateForm
        v-if="!loading"
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <div class="mb-5">
          <FormulateInput
            type="partner-select"
            placeholder="Select a partner"
            style="margin-bottom: 0px"
            class="pb-4 content-center"
            :filterable="false"
            :clearable="false"
            validation="required"
            @input="onChange($event)"
          />
        </div>
        <div class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          />
        </div>
        <div class="text-red-500 text-center mb-3" v-if="errorMessage">
          {{ errorMessage }}
        </div>
      </FormulateForm>
    </StyledSubmit>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "Transfer-Lead-Form",
  props: {
    partners: Array,
    partner: String,
    leadId: String,
  },

  components: { StyledSubmit },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      selectedPartner: null,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      const payload = {
        leadId: this.leadId,
        parentEntityId: this.selectedPartner._id,
        parentEntityType: "partner",
      };

      this.$store
        .dispatch(`business/transferLead`, payload)
        .then(() => {
          this.toast("success", "Lead successfully transferred.");
          this.loading = false;
          this.$emit("closeRefresh");
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = `Something went wrong please try again.`;
          if (error.message) this.errorMessage = error.message;
          this.toast("error", this.errorMessage);
        });
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },

    onChange(event) {
      this.selectedPartner = event;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
