<template>
  <fragment>
    <li class="px-5 border-b border-opacity-5" v-if="items.length">
      <div class="flex flex-row items-center h-11" v-bind:class="{ hidden:!name }"><div class="text-sm font-bold tracking-wide text-white">{{name}}</div></div>
    </li>
    <StyledRouterList v-if="!items.length && !isSub" :backgroundColor="theme.primaryColor" :hoverColor="theme.accentColor" :hoverBackgroundColor="theme.accentColor">
      <router-link v-if="link && !isExternalLink" :to="link" v-bind:class="{ active:isActive(link) || isChildActive() }" class="relative flex flex-row items-center h-11 focus:outline-none border-l-4 border-transparent pr-6">
        <font-awesome-icon v-if="icon" :icon="icon" class="text-lg inline-flex justify-center items-center ml-6"/>
        <span class="text-sm tracking-wide truncate" v-bind:class="{ 'ml-2.5': icon, 'ml-6': !icon }">{{name}}</span>
      </router-link>
      <a v-else-if="link && isExternalLink" :href="link" target="_blank" class="relative flex flex-row items-center h-11 focus:outline-none border-l-4 border-transparent pr-6">
        <font-awesome-icon v-if="icon" :icon="icon" class="text-lg inline-flex justify-center items-center ml-6"/>
        <span class="text-sm tracking-wide truncate" v-bind:class="{ 'ml-2.5': icon, 'ml-6': !icon }">{{name}}</span>
      </a>
      <a v-if="!link" @click.prevent="toggleSubmenu()" v-bind:class="{ active:isChildActive() }" class="relative flex flex-row cursor-pointer items-center h-11 focus:outline-none border-l-4 border-transparent pr-6">
        <font-awesome-icon v-if="icon" :icon="icon" class="text-lg inline-flex justify-center items-center ml-6"/>
        <span class="text-sm tracking-wide truncate" v-bind:class="{ 'ml-2.5': icon, 'ml-6': !icon }">{{name}}</span>
        <font-awesome-icon v-if="link == ''"
            icon="caret-right"
            class="absolute transition-transform duration-200 transform right-3 my-auto mx-1.5 opacity-50"
            :class="{ 'rotate-90':showSubMenu }"
        />
      </a>
    </StyledRouterList>
    <div v-show="showSubMenu">
      <StyledRouterList v-for="(subitem, index) in filteredItems(subitems)" :key="subitem.name" :backgroundColor="theme.primaryColor" :hoverColor="theme.accentColor" :hoverBackgroundColor="theme.accentColor">
        <router-link v-if="!subitem.isExternalLink" :index="index" :to="subitem.link" v-bind:class="{ activesub:isActive(subitem.link) }" class="relative flex flex-row items-center h-9 focus:outline-none border-l-4 border-transparent pr-6">
          <font-awesome-icon v-if="subitem.icon" :icon="subitem.icon" class="text-lg inline-flex justify-center items-center ml-6"/>
          <span class="text-xs tracking-wide truncate" v-bind:class="{ 'ml-2.5': subitem.icon, 'ml-9': !subitem.icon }">{{subitem.name}}</span>
        </router-link>
        <a v-else :href="subitem.link" target="_blank" class="relative flex flex-row items-center h-9 focus:outline-none border-l-4 border-transparent pr-6">
          <font-awesome-icon v-if="subitem.icon" :icon="subitem.icon" class="text-lg inline-flex justify-center items-center ml-6"/>
          <span class="text-xs tracking-wide truncate" v-bind:class="{ 'ml-2.5': subitem.icon, 'ml-9': !subitem.icon }">{{subitem.name}}</span>
        </a>
      </StyledRouterList>
      <div class="mt-2 mb-3 mx-4" :style="{borderBottom: `solid 1px ${theme.secondaryColor}`, backgroundColor:theme.secondaryColor}"></div>
    </div>
    <slot></slot>
  </fragment>
</template>

<script>

import {StyledRouterList} from '@/styled-components'
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar-Item',
  components: {StyledRouterList},
  props: {
    name: String,
    icon: String,
    link: String,
    isProduction: Boolean,
    items: {
        type: Array,
        default: () => []
    },
    subitems: {
      type: Array,
      default: () => []
    },
    isSub: {
      type: Boolean,
      default: false
    },
    parentLink: String,
    isExternalLink: Boolean
  },
  data(){
    return {
      showSubMenu: false,
    };
  },
  mounted() {
    this.showSubMenu = this.isChildActive()
  },
 computed: {
    ...mapGetters('theme', {
      theme: 'getColorScheme'
    })
  },
  methods: {
    toggleSubmenu() {
      this.showSubMenu = !this.showSubMenu
    },
    isActive(link) {
      return this.$route.path == link ? true : false;
    },
    isChildActive() {
      var link = this.$route.path;
      var valObj = this.subitems.filter(function(elem){
        if(elem.link == link) return elem.name;
      });

      if(valObj.length > 0) {
          return true
      }
      return false;
    },
    filteredItems(item) {
      if(process.env.VUE_APP_ENV == 'production') {
        return item.filter((data) => {
          return data.production !== false
        })
      } else {
        return item
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.border-bottom-1{
  border-bottom-width: 1px;
  border-color: rgb(71,133,173)
}
</style>
