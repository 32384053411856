<template>
  <div>
    <FormulateForm v-model="values" :schema="schema" @submit="onSubmit">
      <div class="border-t border-neutral-light mt-8">
        <div class="flex items-center justify-end w-full mt-4">
          <button
            type="submit"
            class="btn btn-primary w-50"
            :disabled="loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Next
          </button>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import schema from "./driver-create-schema";
import _ from "lodash";
import { disableAllField, formatValues, enableAllField } from "@/_helper";
import { mapGetters } from "vuex";

export default {
  name: "Add-Driver-Form",
  props: {
    msg: String,
    preData: {
      type: Object,
      required: false,
      default: () => {},
    },
    postData: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      linkDriver: {},
    };
  },
  async mounted() {
    if (!_.isEmpty(this.preData)) {
      if (this.preData?.steps) {
        this.values = this.preData.steps?.driverDetails || null;
        this.interChangingData = this.preData;
      }

      this.schema = disableAllField(this.schema);
      const soloDrivers = await this.$store.dispatch(
        `driver/getDriver`,
        this.preData
      );
      if (soloDrivers) {
        this.linkDriver = { ...formatValues(soloDrivers) };
        this.values = { ...formatValues(soloDrivers) };
      }
    } else {
      this.schema = enableAllField(this.schema);
    }
    if (this.postData?.steps) {
      this.values = this.postData.steps?.driverDetails || null;
      this.interChangingData = this.postData;
    }
  },
  methods: {
    async onSubmit() {
      if (!_.isEmpty(this.preData)) {
        this.$emit("nextStep", {
          linkDriver: this.linkDriver,
          ...this.interChangingData,
          steps: {
            ...this.interChangingData?.steps,
            ...{
              driverDetails: this.linkDriver,
            },
          },
          driverDetails: this.linkDriver,
        });
      } else {
        this.loading = true;
        this.values.emailAddress = this.values.emailAddress.toLowerCase();
        let newDriver = JSON.parse(JSON.stringify(this.values));
        if (newDriver) {
          const preData = {
            ...this.interChangingData,
            newDriver: newDriver,
            steps: {
              ...this.interChangingData?.steps,
              ...{ driverDetails: newDriver },
            },
            driverDetails: newDriver,
          };
          this.$emit("nextStep", preData);
        }
      }
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
