var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"w-full min-h-full flex-row items-left"},[_c('div',{staticClass:"bg-yellow-400 text-white p-5 mb-3 rounded",style:({ backgroundColor: _vm.theme.primaryColor })},[(!_vm.isTrainingCompleted)?_c('div',{staticClass:"flex items-center"},[_c('svg',{staticClass:"w-7 h-7",attrs:{"fill":"#fff","viewBox":"0 0 20 20","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})]),_c('span',{staticClass:"ml-2"},[_vm._v("Training not completed, please complete before "+_vm._s(_vm.trainingCompletionDate)+".")])]):_c('span',[_vm._v("Great, you have completed all trainings.")])]),_c('div',{staticClass:"flex w-full mt-5 flex-col-reverse lg:flex-row"},[_c('aside',{staticClass:"w-full lg:w-96 h-full lg:border-r rounded-md bg-white mt-5 lg:mt-0 lg:mr-5"},[_vm._m(0),_c('div',{staticClass:"h-96"},[_c('div',[(_vm.trainings.length > 0)?_c('div',_vm._l((_vm.trainings),function(training,index){return _c('div',{key:index},[_c('button',{staticClass:"text-left w-full flex px-8 lg:px-4 py-3 border-b border-gray-200",class:{
                    'bg-green-100': _vm.activeTrainingId === training.id,
                  },style:({
                    color:
                      _vm.activeTrainingId === training.id
                        ? 'rgb(55 65 81)'
                        : _vm.theme.primaryColor,
                  }),attrs:{"type":"button"},on:{"click":function($event){return _vm.setActiveTraining(training.id)}}},[_c('div',{staticClass:"w-full flex justify-between"},[_c('div',[(_vm.activeTrainingId === training.id)?_c('font-awesome-icon',{staticClass:"my-auto mr-1.5",attrs:{"icon":['fas', 'book-open'],"size":"lg"}}):_c('font-awesome-icon',{staticClass:"my-auto mr-1.5",attrs:{"icon":['fas', 'book'],"size":"lg"}}),_c('small',{staticClass:"relative ml-1"},[_vm._v(_vm._s(training.name))])],1),_c('StyledIconedLink',{attrs:{"iconColor":training.color,"iconHoverColor":_vm.theme.secondaryColor}},[_c('font-awesome-icon',{staticClass:"my-auto mx-1.5",attrs:{"icon":"circle"}})],1)],1)])])}),0):_c('div',{staticClass:"w-full px-1 py-2 border-b border-gray-200 opacity-40",style:({ color: _vm.theme.primaryColor })},[_vm._m(1)])])])]),_c('div',{staticClass:"w-full flex flex-col"},[(!_vm.trainingLoading)?_c('div',[_c('div',{staticClass:"bg-white py-4 px-8 rounded-md"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-bold text-black-primary text-base"},[_vm._v(" "+_vm._s(_vm.activeTraining.name)+" "),_c('span',{staticClass:"text-xs capitalize px-3 py-2 ml-2 rounded",style:({
                    'background-color': `${_vm.activeTraining.statusColor}`,
                    border: `1px solid ${_vm.activeTraining.color}`,
                    color: `${_vm.activeTraining.color}`,
                  })},[_vm._v(" "+_vm._s(_vm.activeTraining.statusAlt)+" ")])])]),_c('div',{staticClass:"frame-container mb-5 -mt-1"},[_c('iframe',{ref:"youtubePlayer",staticClass:"responsive-iframe w-full",attrs:{"src":_vm.activeTraining.videoLink,"title":"YouTube video player","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share","allowfullscreen":""}})]),(_vm.activeTraining.status != 'finished')?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"font-normal text-black-primary text-base mb-3"},[_vm._v(" I confirm that I have understood the content of the video and declare I have been trained. ")]),_c('button',{staticClass:"btn btn-primary ml-auto",style:({ backgroundColor: _vm.theme.secondaryColor }),on:{"click":function($event){$event.preventDefault();return _vm.declare()}}},[_vm._v(" Declare ")])]):_vm._e()])]):_c('div',{staticClass:"w-full text-center overflow-y-auto px-3 text-lg inset-0 flex flex-col justify-center items-center",staticStyle:{"height":"669px"}},[_c('font-awesome-icon',{staticClass:"text-gray-400",attrs:{"icon":"cog","spin":"","size":"3x"}}),_c('h6',{staticClass:"mt-4"},[_vm._v("Loading")])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-b"},[_c('p',{staticClass:"font-bold text-base text-black-primary px-8 py-4 lg:px-4"},[_vm._v(" Portal Trainings ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('em',[_vm._v("No training found")])])
}]

export { render, staticRenderFns }