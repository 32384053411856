<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <div class="text-center mb-10">
        <div
          v-if="business.persona"
          class="font-bold text-black-primary text-2xl"
        >
          {{ business.persona.businessName }}
        </div>
        <div class="font-bold text-xs text-green-500">Linked</div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Business Profile</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ businessProfile.baseLocation }}
        </div>
        <div class="text-xs">Base Location</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ businessProfile.baseTimeZone }}
        </div>
        <div class="text-xs">Base Time Zone</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ businessProfile.recordKeeperAddress }}
        </div>
        <div class="text-xs">Record Keeper Address</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-black-primary text-base">
          {{ businessProfile.BFMNumber }}
        </div>
        <div class="text-xs">BFM Number</div>
      </div>
    </div>
  </div>
</template>
<script>
import { convertAddressObject } from "@/_helper";
export default {
  name: "Driver-Details-View",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      business: {},
      businessProfile: {},
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const driverDetails = await this.$store.dispatch(
      `business/getContractById`,
      this.detailsId
    );
    if (driverDetails) {
      this.business = driverDetails?.business;
      this.businessProfile = driverDetails.profile;
      this.loading = false;
    }
  },
  methods: {
    parseProfile(profile) {
      return {
        baseLocation: convertAddressObject(profile.baseLocation) || "-",
        baseTimeZone: profile.baseTimeZone || "-",
        BFMNumber: profile.BFMNumber || "-",
        recordKeeperAddress: profile.recordKeeperAddress || "-",
      };
    },
  },
};
</script>
