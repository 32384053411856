<template>
  <div>
    <Table
      :isLoading="loading"
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="billingData"
      @onPageChange="onPageChange($event)"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0 h-12">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
      </template>

      <template #default="{ data }">
        <td class="px-5 py-2">
          <div
            class="text-black-primary whitespace-no-wrap text-sm flex justify-center"
          >
            <a class="flex cursor-pointer" @click.prevent="viewReceipt(data)"
              ><font-awesome-icon
                icon="eye"
                class="my-auto mx-1.5 hover:text-orange-primary-primary"
              />View Receipt</a
            >
          </div>
        </td>
      </template>
      <template slot="callToActionTop">
        <div
          class="flex flex-row mb-1 space-x-1 sm:mb-0"
          v-if="checkContract()"
        >
          <button
            class="btn btn-primary"
            @click.prevent="manageSubscription()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Manage Subscription
          </button>
        </div>
      </template>
    </Table>

    <Modal
      ref="modal"
      :title="modalTitle"
      size="3xl"
      :height="modalHeight"
      @closeModal="closeModal()"
    >
      <div v-if="modalContent == 'manageSubscription'">
        <ManageSubscription
          :activeSolo="me.contract"
          @closeModal="closeModal"
        />
      </div>
      <div v-if="modalContent == 'viewReceipt'">
        <PaymentReceipt
          :receipt-data="receiptData"
          :entity-data="me"
          @closeModal="closeModal"
        />
      </div>
    </Modal>
  </div>
</template>
<script>
import { Table, Modal } from "@/components/commons";
import ManageSubscription from "@/components/forms/payment/manage-subscription";
import PaymentReceipt from "@/components/view/payment/payment-receipt";
import _ from "lodash";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import { mapGetters } from "vuex";
export default {
  name: "Billing-Centre",
  components: {
    Table,
    Modal,
    ManageSubscription,
    PaymentReceipt,
  },
  data() {
    return {
      modalHeight: DEFAULT_MODAL_HEIGHT,
      modalContent: "",
      modalTitle: "",
      modalId: "",
      receiptData: {},
      tableHeader: [
        "Total Amount",
        "Payment Date",
        "Transaction Status",
        "Remarks",
      ],
      billingHistories: [],
      tableData: [],
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      loading: false,
      driverId: null,
      filter: {
        limit: 10,
        search: "",
      },
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: "80vh",
      },
      me: {},
      hideButton: true,
    };
  },

  async mounted() {
    this.init();
  },
  methods: {
    manageSubscription() {
      this.modalContent = "manageSubscription";
      this.modalTitle = "Subscription Details";
      this.$refs.modal.toggleModal();
    },
    closeModal() {
      this.$refs.modal.closeModal();
      this.receiptData = {};
      this.modalId = "";
    },
    async init(paged = 1) {
      this.loading = true;
      const me = await this.$store.getters[`account/me`];
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      this.driverId = me?.driver?._id;

      let billingHistories;

      const billingHistory = await this.$store.dispatch(
        "payment/getPaymentHistoryByEntityId",
        {
          id: this.driverId,
          query,
        }
      );
      if (!billingHistory?.results) {
        this.billingHistories = [];
      }

      if (billingHistory?.metadata) {
        this.paginationSettings = formatPaginationSettings(
          billingHistory?.metadata
        );
        billingHistories = billingHistory.results;
      }

      if (billingHistories) {
        const billingTableData = this.formatItem(
          _.orderBy(billingHistories, ["createdAt"], ["desc"])
        );
        this.billingHistories = billingTableData;
      }

      this.me = me;

      this.loading = false;
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },
    debouncedSearchString() {
      this.init();
    },

    async filterByDate(event) {
      this.selectedDateRange = event;
      await this.init();
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const device = {
            id: item._id,
            invoice: item.receipt,
            totalAmount: `$${item.totalAmount}`,
            status: item.status.toUpperCase(),
            createdAt: formatDate(item.createdAt),
            remarks: item.remarks,
          };
          nItems.push(device);
        });
      }
      return nItems;
    },

    filterRow(keyword, attributeToFilter) {
      return keyword
        .toLowerCase()
        .split(" ")
        .every((v) => attributeToFilter.trim().toLowerCase().includes(v));
    },

    parseBillings(data) {
      return data.map((billingHistory) => [
        {
          id: billingHistory.id,
          name: billingHistory.totalAmount,
          itemType: "string",
        },
        {
          id: billingHistory.id,
          name: billingHistory.createdAt,
          itemType: "string",
        },
        {
          id: billingHistory.id,
          name: billingHistory.status,
          itemType: "label",
        },
        {
          id: billingHistory.id,
          name: billingHistory.remarks,
          itemType: "label",
        },
      ]);
    },

    checkContract() {
      if (this.me.contract) {
        if (this.me.contract.status.name == "Cancelled") {
          return false;
        } else {
          return true;
        }
      }
    },

    viewReceipt(data) {
      this.$refs.modal.openModal();
      this.modalContent = "viewReceipt";
      this.modalTitle = "Receipt Details";

      this.receiptData = data;
      this.modalId = data._id;
    },
  },
  computed: {
    billingData() {
      if (this.billingHistories) {
        return this.parseBillings(this.billingHistories);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
