import { Breach } from '@/api';
import _ from 'lodash';

export default {
  async sendSingleDriverBreachReport({ state }, data) {
    if (!data || _.isEmpty(data)) throw 'Invalid data ' + state.me;
    try {
      const response = await Breach.sendSingleDriverBreachReport(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async sendMultipleDriverBreachReport({ state }, data) {
    if (!data || _.isEmpty(data)) throw 'Invalid data ' + state.me;
    try {
      const response = await Breach.sendMultipleDriverBreachReport(data);
      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },

  async updateNote({ state }, data) {
    if (!data || _.isEmpty(data)) throw 'Invalid data ' + state.me;
    try {
      const response = await Breach.updateNote(data);
      return response.data.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },

  async confirmBreaches({ state }, data) {
    if (!data || _.isEmpty(data)) throw 'Invalid data ' + state.me;
    try {
      const response = await Breach.confirmBreaches(data);
      return response.data.data;
    } catch (error) {
      throw error?.response?.data;
    }
  },

  async getBreach({ state }, payload) {
    try {
      const response = await Breach.getBreach(payload);
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      console.log(state);
      throw error.response.data;
    }
  },

  async getBreachByBusinessId(_, param) {
    if (typeof param === 'string')
      param = { businessId: param, query: { skip: 0, limit: 999 } };
    if (!param.businessId) throw 'Invalid UID or ID';
    try {
      const response = await Breach.getBreachByBusinessId(
        param.businessId,
        param.query
      );
      if (response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta,
        };
      } else {
        return response.data.data;
      }
    } catch (error) {
      throw error.response.data;
    }
  },
};
