<template>
  <div class="text-center">
    <div class="my-5">
      <div class="font-bold">
        🎉 Congratulations! You have successfully created a fleet connection. 🎉
      </div>
      <div class="text-sm mt-2">
        You can now navigate to
        <i><b>Vehicles -> Vehicle List -> Connected Fleets </b></i>to add
        vehicles from this fleet to your account.
      </div>
    </div>
    <div class="mt-5">
      <button
        @click.prevent="closeRefresh()"
        class="w-1/2 px-2 mr-2 btn btn-primary"
        :style="{
          background: theme.secondaryColor,
        }"
      >
        Done
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "Fleet-Creation-Confirmation",
  data() {
    return {};
  },
  methods: {
    closeRefresh() {
      this.$emit("closeRefresh");
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
