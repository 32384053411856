<template>
  <div class="">
    <FormulateForm v-model="values" @submit="onSubmit" #default="{ hasErrors }">
      <div v-if="loading" class="text-center">
        <ui-spinner class="m-auto"></ui-spinner> Loading...
      </div>
      <div v-if="!loading">
        <div class="form border-b-2 border-neutral-100 mb-4">
          <template v-for="(module, index) in contract.businessModules">
            <div :key="index">
              <div>
                <div
                  class="flex justify-between items-center border-b-2 mb-2 border-neutral"
                >
                  <div class="font-bold text-sm">
                    {{ module.name }}
                  </div>
                  <div>
                    <span class="font-semibold">{{
                      getSeatCount(module, index)
                    }}</span>
                    of
                    <span class="font-semibold"
                      >{{ module.minimums }} seats</span
                    >
                    used
                  </div>
                </div>
                <div class="flex justify-between items-center">
                  <div class="flex justify-start space-x-2 items-center mb-2">
                    <FormulateInput
                      type="number"
                      :name="`${updatedSeats[index].module}-seat`"
                      v-model="updatedSeats[index].value"
                      input-class="shadow-sm border border-gray-300 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none border-box w-full mt-1 mb-2 font-medium text-sm text-neutral-dark-500"
                      label="Updated Seat Count"
                      outer-class="z-50"
                      :validation="`required|number|min:${getSeatCount(
                        module,
                        index
                      )}|max:${module.minimums}`"
                      :min="getSeatCount(module, index)"
                      :max="module.minimums"
                      error-behavior="live"
                      :disabled="
                        submitLoading || module.name.toLowerCase() === 'one api'
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            label="Submit"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || loading"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon
                icon="spinner"
                class="mr-1 loader"
              />Submitting</span
            >
            <span v-if="!submitLoading">Submit</span>
          </FormulateInput>
        </StyledSubmit>
      </div>
    </FormulateForm>
  </div>
</template>
<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";
export default {
  name: "Manage-Seats-Form",
  props: {
    contract: {
      type: Object,
      default: () => {},
    },
    currentSeatCounts: {
      type: Object,
      default: () => {},
    },
    paymentMethod: {
      type: Object,
      default: () => {},
    },
    paymentGateway: {
      type: Object,
      default: () => {},
    },
    totals: {
      type: Object,
      default: () => {},
    },
  },
  components: { StyledSubmit },
  data() {
    return {
      loading: false,
      values: {
        addedSeatCount: 1,
      },
      billPeriod: "",
      updatedSeats: [],
      proratedDates: {},
      billTotal: {},
      submitLoading: false,
    };
  },
  async created() {
    this.loading = true;
    //set seat availability
    let updatedSeats = [...this.contract.businessModules];
    updatedSeats.forEach((seat) => {
      this.updatedSeats.push({
        contractModuleId: seat._id,
        module: seat.name,
        value: seat.minimums,
        currentSeatCount: seat.minimums,
        actualPrice: 0,
      });
    });
    await this.calculateProratedAmount();
    this.loading = false;
  },
  methods: {
    async onSubmit() {
      this.submitLoading = true;

      //Form data
      let formData = {
        updatedSeats: this.updatedSeats,
        businessId: this.contract.businessMongoId,
        contractId: this.contract._id,
      };
      await this.$store
        .dispatch(`contract/updateSeatCount`, formData)
        .then(async () => {
          this.$emit("updatedSeats", true);
        })
        .catch(async (err) => {
          console.error(err);
          this.$emit("updatedSeats", false);
        })
        .finally(() => {
          this.submitLoading = true;
        });
    },
    init() {},

    getActualPrice(modules) {
      let amount;
      switch (this.contract.billingPeriod.name.toUpperCase()) {
        case "MONTHLY":
          this.billPeriod = "month";
          amount = modules.price.monthly.toFixed(2);
          break;
        case "QUARTERLY":
          this.billPeriod = "quarter";
          amount = modules.price.quarterly.toFixed(2);
          break;
        case "YEARLY":
          this.billPeriod = "year";
          amount = modules.price.yearly.toFixed(2);
      }
      return amount;
    },

    getSeatCount(module, index) {
      let moduleCount;
      switch (module.name) {
        case "EWD-FT":
          moduleCount = this.currentSeatCounts.ewdCount;
          break;
        case "Fatigue Compliance":
          moduleCount = this.currentSeatCounts.fatigueCount;
          break;
        case "KM-TIME":
          moduleCount = this.currentSeatCounts.kmTimeCount;
          break;
        case "One API":
          moduleCount = this.currentSeatCounts.fatigueCount;
          break;
        case "Mass Compliance":
          moduleCount = this.currentSeatCounts.fatigueCount;
          break;
        default:
          moduleCount = 0;
      }

      if (module.name.toLowerCase() === "one api") {
        this.updatedSeats[index].value =
          this.updatedSeats[0].value > this.updatedSeats[1].value
            ? this.updatedSeats[0].value
            : this.updatedSeats[1].value;
      }

      this.updatedSeats[index].actualPrice = parseFloat(
        this.getActualPrice(module)
      );

      return moduleCount;
    },

    async calculateProratedAmount() {
      let cutOffDate, remainingDays, daysPassed, remainingDurationName;
      const lastPaymentDate = moment(this.contract.lastPaymentDate).format(
        "MM-DD-YYYY"
      );
      const currentDate = moment(new Date()).format("MM-DD-YYYY");
      if (this.contract.billingPeriod.name.toUpperCase() === "MONTHLY") {
        cutOffDate = moment(lastPaymentDate, "MM-DD-YYYY")
          .add(28, "days")
          .format("MM-DD-YYYY");
        remainingDays = moment(cutOffDate).diff(currentDate, "days");
        daysPassed = moment(currentDate).diff(lastPaymentDate, "days");
        remainingDurationName = "day(s)";
      } else {
        cutOffDate = moment(lastPaymentDate, "MM-DD-YYYY")
          .add(12, "months")
          .format("MM-DD-YYYY");
        remainingDays = moment(cutOffDate).diff(currentDate, "months");
        daysPassed = moment(currentDate).diff(lastPaymentDate, "months");
        remainingDurationName = "month(s)";
      }
      //if days passed is more than 28 days, there will be no prorated amount.

      this.proratedDates = {
        lastPaymentDate: lastPaymentDate,
        currentDate: currentDate,
        cutOffDate: cutOffDate,
        remainingDays: remainingDays,
        daysPassed: daysPassed,
        remainingDurationName: remainingDurationName, // days, day, months, month
      };
    },

    getProratedSeatAmount(module, index) {
      const actualPrice = this.getActualPrice(module);
      let totalProratedPrice, perSeatPrice, seatPricePerPeriod;
      //if the billing period is yearly, set a prorated amount.

      if (this.contract.billingPeriod.name.toUpperCase() === "YEARLY") {
        if (this.proratedDates.remainingDays === 12) {
          totalProratedPrice = parseFloat(
            actualPrice * this.addedSeats[index].value
          ).toFixed(2);
          perSeatPrice = parseFloat(actualPrice).toFixed(2);
        } else {
          //get the pricing per month of the seat
          seatPricePerPeriod = actualPrice / 12;

          //get the seat price for each, based on the remaining months
          perSeatPrice = (
            seatPricePerPeriod * this.proratedDates.remainingDays
          ).toFixed(2);
          // get the computed price
          const computedPrice =
            seatPricePerPeriod * this.addedSeats[index].value;

          totalProratedPrice = (
            computedPrice * this.proratedDates.remainingDays
          ).toFixed(2);
        }
      } else {
        totalProratedPrice = parseFloat(
          actualPrice * this.addedSeats[index].value
        ).toFixed(2);
        perSeatPrice = parseFloat(actualPrice).toFixed(2);
      }

      this.addedSeats[index].total = parseFloat(totalProratedPrice);
      this.addedSeats[index].unitPrice = parseFloat(perSeatPrice);
      this.addedSeats[index].actualPrice = parseFloat(actualPrice);
      if (module.name.toLowerCase() === "one api") {
        this.addedSeats[index].value = this.addedSeats[0].value;
      }

      return `$${totalProratedPrice}`;
    },

    getProratedTotalAmount() {
      let totalAmount = 0;

      this.addedSeats.forEach((data) => {
        totalAmount += data.total;
      });
      const gst = (totalAmount / 100) * this.paymentGateway.gstPercentageFee;
      const totalGST = totalAmount + gst;
      const gatewayFee =
        (totalGST / 100) * this.paymentGateway.surchargePercentageFee +
        this.paymentGateway.otherFees;
      const amountDue = totalGST + gatewayFee;
      this.billTotal = {
        subTotal: totalAmount,
        totalGST: totalGST,
        gst: gst,
        gatewayFee: gatewayFee,
        amountDue: amountDue,
      };
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  watch: {
    addedSeats: {
      handler: function () {
        this.getProratedTotalAmount();
      },
      deep: true,
    },
  },
};
</script>
