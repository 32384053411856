<template>
  <div>
    <div>
      <Tabs @activeTab="getActiveTab">
        <Tab title="NHVR">
          <ComplianceReport :isActive="activeTab === 'NHVR'" />
        </Tab>
        <Tab title="WAHVA">
          <WaComplianceReport :isActive="activeTab === 'WAHVA'" />
        </Tab>
        <Tab title="Driver Compliance">
          <CompliancePeriodReport :isActive="activeTab === 'Driver Compliance'" />
        </Tab>
      </Tabs>
    </div>
  </div>
</template>


<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import WaComplianceReport from "@/pages/business/WAComplianceReport.vue";
import ComplianceReport from "@/pages/business/ComplianceReport.vue";
import CompliancePeriodReport from "@/pages/business/CompliancePeriodReport.vue";
export default {
  name: "ReportCompliance",
  components: { CompliancePeriodReport, ComplianceReport, WaComplianceReport, Tab, Tabs },
  data(){
    return{
      activeTab: 'NHVR',
      isFatigueSubscribed: false,
    }
  },
  mounted () {
    const me = this.$store.getters[`account/me`];
    if (me?.menuItems?.length > 0) {
      const fatigueModuleMenus = me.menuItems.filter((item) => {
        return item.name === "Fatigue Compliance";
      });
      if(fatigueModuleMenus.length > 0) {
        this.isFatigueSubscribed = true;
      }
    }
  },
  methods: {
    getActiveTab (tab){
      this.activeTab = tab
    }
  }
}
</script>