import { API } from '@/api/config';
import { ObjectToQueryString } from '../../../_helper';

export default new (class BillingInvoice {
  getAllInvoice(
    query = { skip: 0, limit: 10, filter: {}, userMongoId: {} }
  ) {
    let filter = '';
    if (typeof query.filter === 'object') {
      try {
        filter = JSON.stringify(query.filter);
      } catch (e) {
        throw 'Invalid Filter object';
      }
    }

    query.filter = filter;

    filter = JSON.stringify(query.filter);

    if (query) query = '?' + ObjectToQueryString(query);

    return API.ACCOUNTS.get(`billing-invoice/${query}`);
  }

  getOneInvoice(id) {
    return API.ACCOUNTS.get(`billing-invoice/${id}`);
  }

  //Subscription Invoices
  getAllContractInvoices(param, query = { skip: 0, limit: 10, filter: {} }) {
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/subscription-invoice/all-invoices/contract/${param.contractId}${query}`);
  }

  getContractUnpaidInvoice(contractId) {
    return API.ACCOUNTS.get(`/subscription-invoice/current-invoice/contract/${contractId}`);
  }

  getInvoiceByPaymentHistory(contractId, payload) {
    return API.ACCOUNTS.post(`/subscription-invoice/current-invoice/payment-history/${contractId}`, payload);
  }

  createBacktrackInvoice(payload) {
    return API.ACCOUNTS.post(`/subscription-invoice/create-backtrack-invoice`, payload);
  }
  addInvoiceItemAndUpdateInvoice(payload) {
    return API.ACCOUNTS.post(`/subscription-invoice/add-invoice-item-and-update-invoice/${payload.contractId}`, payload);
  }

  unsubscribeBusinessModule(payload) {
    return API.ACCOUNTS.patch(`subscription-invoice/unsubscribe-module/${payload.contractId}`, payload);
  }
})();
