<template>
  <div class="">
    <FormulateForm
      name="newBusinessForm"
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
    >
      <div>
        <FormulateInput
          type="group"
          name="certificate"
          label="Accreditation (Optional)"
          labelClass="font-semibold text-sm block -top-7 -left-0 absolute"
          class="border rounded p-5 mb-5 mt-10 relative"
        >
          <button @click.prevent="showDriverList">
            <small
              v-if="!isDriverListOpen"
              class="btn-primary btn-xs px-3 shadow rounded-lg"
            >
              + Select from accreditation list
            </small>
            <small
              v-else
              class="bg-blue-200 text-white btn-xs px-3 shadow rounded-lg"
            >
              <font-awesome-icon icon="times" />
            </small>
          </button>

          <FormulateInput
            v-if="!isDriverListOpen"
            type="text"
            label="BFM Number"
            v-model="BFMStringBased"
            valdation="optional"
          />

          <FormulateInput
            v-else
            type="cert-select"
            name="BFMNumber"
            style="margin-bottom: 0px"
            label="BFM Number"
            class="pb-4 content-center"
            validation="optional"
            @input="onChange($event)"
            v-model="BFMSelectionBased"
            :clearable="false"
          />

          <FormulateInput
            v-if="isDriverListOpen"
            type="date"
            name="inductionDate"
            label="Induction Date"
            validation="optional"
            v-model="inductionDate"
          />
        </FormulateInput>
      </div>

      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
        />
      </div>
    </FormulateForm>

    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import schema from "./business-profile-create-schema";
import _ from "lodash";
import moment from "moment";
export default {
  props: {
    preData: null,
    defaultData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    this.$emit("schema", schema);
    return {
      schema: schema,
      values: {},
      loading: false,
      errorMessage: "",
      isDriverListOpen: false,
      BFMStringBased: "",
      BFMSelectionBased: {},
      inductionDate: "",
      newDriver: {},
    };
  },
  watch: {
    isDriverListOpen: function () {
      this.BFMSelectionBased = {};
      this.inductionDate = "";
    },
  },

  mounted() {
    if (!_.isEmpty(this.preData)) {
      this.newDriver = this.preData["newDriver"];
    }
  },

  methods: {
    async onSubmit() {
      this.errorMessage = "";
      this.loading = true;

      const businessID = this.defaultData.businessMongoId;

      if (
        (this.isDriverListOpen &&
          !_.isEmpty(this.BFMSelectionBased) &&
          _.isEmpty(this.inductionDate)) ||
        (!_.isEmpty(this.inductionDate) && _.isEmpty(this.BFMSelectionBased))
      ) {
        this.errorMessage = "Accreditation details incomplete";
        this.loading = false;
        return;
      }

      let newContract = JSON.parse(JSON.stringify(this.values));

      newContract["profile"] = newContract["profile"][0];
      newContract["profile"]["recordKeeperAddress"] =
        newContract["profile"]["recordKeeperAddress"][0][
          "record-keeper-street"
        ];
      newContract["profile"]["baseLocation"] =
        newContract["profile"]["baseLocation"][0]["base-location-street"];
      newContract["businessId"] = businessID;

      if (!this.isDriverListOpen && !_.isEmpty(this.BFMSelectionBased.trim())) {
        const certificate = [
          {
            BFMNumber: this.BFMStringBased,
            inductionDate: null,
          },
        ];
        newContract["profile"]["certificate"] = certificate;
        newContract["profile"]["BFMNumber"] = this.BFMStringBased;
      } else {
        const certificate = [
          {
            BFMNumber: this.BFMSelectionBased.certNumber,
            inductionDate: this.inductionDate,
          },
        ];
        newContract["profile"]["certificate"] = certificate;
        newContract["profile"]["BFMNumber"] = this.BFMSelectionBased.certNumber;
      }

      delete newContract["certificate"];

      newContract["profile"]["BFMSolo"] = false;
      newContract["profile"]["BFM2up"] = false;
      newContract["profile"]["BFMNumber"] = "";

      this.newDriver["contract"] = newContract;
      this.newDriver["contract"]["startDate"] = moment();

      this.newDriver["entityId"] = businessID;
      this.newDriver["isDemoAccount"] = false;
      this.newDriver["engineRegion"] = newContract["profile"]["engineRegion"];
    },
    async onChange(data) {
      if (data) {
        this.BFMSelectionBased = data;
      }
    },

    showDriverList() {
      this.isDriverListOpen = !this.isDriverListOpen;
    },
  },
};
</script>
