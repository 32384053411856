<template>
  <Stepper
    :stepperSteps="stepperSteps"
    @completed-step="completeStep"
    @active-step="isStepActive"
    :showNavigation="false"
    @closeRefresh="closeRefresh"
  />
</template>

<script>
import { Stepper } from "@/components/commons";
import ImportDriverTrip from "./import-driver-trip.vue";
import SelectDriverTrip from "./select-driver-trip.vue";
import SelectFile from "./select-file.vue";
export default {
  name: "New-Driver-Trip-Import",
  components: { Stepper },
  data() {
    return {
      stepperSteps: [
        {
          stepIcon: "upload",
          stepTitle: "Upload File",
          stepSequence: "first",
          stepComponent: SelectFile,
          isCompleted: true,
        },
        {
          stepIcon: "users",
          stepTitle: "Select Driver Trip",
          stepSequence: "second",
          stepComponent: SelectDriverTrip,
          isCompleted: false,
        },
        {
          stepIcon: "download",
          stepTitle: "Import",
          stepSequence: "third",
          stepComponent: ImportDriverTrip,
          isCompleted: false,
        },
      ],
      activeStep: 0,
    };
  },
  methods: {
    completeStep(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          step.isCompleted = true;
        }
      });
    },
    isStepActive(payload) {
      this.stepperSteps.forEach((step) => {
        if (step.stepSequence === payload.stepSequence) {
          if (step.isCompleted === true) {
            step.isCompleted = false;
          }
        }
      });
    },
    closeRefresh() {
      this.$emit("closeModal");
    },
  },
};
</script>
