<template>
  <div class="relative">
    <div class="w-full h-full" v-if="loading">
      <div
        class="absolute inset-0 z-10 flex flex-col items-center justify-center m-auto bg-gray-50"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Calculating...</span>
      </div>
    </div>
    <div class="mt-2 overflow-x-auto">
      <p class="flex items-center mb-3 text-sm">
        <font-awesome-icon
          icon="info-circle"
          size="lg"
          class="mr-1 text-blue"
        />
        By checking the box, you hereby declare that the breach is correct.
      </p>
      <p
        v-if="isDisplayNotice"
        class="px-4 py-2 mb-3 text-xs border border-gray-300 rounded"
      >
        <strong class="block mb-1 text-lg font-semibold">Notice</strong>
        Driver has been left in work mode, we have automatically deselected the
        breaches so it won't create incorrect information within your portal.
      </p>
      <table class="min-w-full border-2 border-gray-300">
        <thead class="bg-gray-300">
          <tr class="text-xs tracking-wider text-gray-700 uppercase">
            <th scope="col" class="px-5 py-3 text-center">Declare</th>
            <th scope="col" class="px-5 py-3 text-left">Counting Point</th>
            <th scope="col" class="px-5 py-3 text-left">Breach Time</th>
            <th scope="col" class="px-5 py-3 text-left">Option</th>
            <th scope="col" class="px-5 py-3 text-left">Rule</th>
            <th scope="col" class="px-5 py-3 text-left">Rule Type</th>
            <th scope="col" class="px-5 py-3 text-left">Level</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-show="breaches.length > 0"
            v-for="(breach, index) in breaches"
            :key="index"
            class="text-xs font-medium text-gray-500 bg-white border-b"
          >
            <td class="px-5 py-3 text-center">
              <input
                type="checkbox"
                v-model="breach.isChecked"
                class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 rounded"
              />
            </td>
            <td class="px-5 py-3 capitalize text-black-primary">
              {{ formatTime(breach.time, breach.timezone) }}
            </td>
            <td class="px-5 py-3 capitalize text-black-primary">
              {{ formatTime(breach.startPoint, breach.timezone) }}
            </td>
            <td class="px-5 py-3">
              {{ breach.option }}
            </td>
            <td class="px-5 py-3">
              {{ breach.period }}
            </td>
            <td class="px-5 py-3">
              {{ breach.type }}
            </td>
            <td class="px-5 py-3">
              {{ cleanLevel(breach.level) }}
            </td>
          </tr>
          <tr v-show="breaches.length == 0">
            <td colspan="10" class="px-5 py-3 text-sm text-center">
              No breaches found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="grid grid-cols-2 gap-4 my-5">
      <button
        class="w-full mb-3 bg-gray-300 btn hover:bg-gray-400"
        @click="onPrevious()"
      >
        Back
      </button>
      <button
        class="w-full mb-3 btn btn-primary"
        :disabled="loading"
        @click="onSubmit()"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        {{ isTripCheck ? "Done" : "Next" }}
      </button>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { generateBreaches } from "@/_helper/breach";
import Spinner from "@/components/commons/ui/Spinner";

export default {
  name: "Check-For-Breaches",
  props: {
    msg: String,
    preData: null,
    postData: null,
    isTripCheck: {
      type: Boolean,
      default: false,
    },
  },
  components: { Spinner },
  data() {
    return {
      params: {},
      loading: false,
      values: [],
      errorMessage: "",
      schema: [],
      businessId: null,
      breaches: [],
      isDisplayNotice: false,
      stepsData: [],
      engineRegion: null,
    };
  },
  computed: {
    driver: {
      get() {
        return this.params.selectedDriver?.driver?.driverName;
      },
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.businessId = me?.business?._id;
    if (me.role.name == "driver") this.businessId = me[me.role.name].entityId;
    let preData = this.preData;

    if (!_.isEmpty(this.postData)) {
      this.stepsData = this.postData;
      preData = this.stepsData.step3Data;
    }

    if (!_.isEmpty(preData)) {
      if (preData?.stepsData) {
        this.stepsData = preData.stepsData;
      }

      if (preData?.engineRegion) {
        this.engineRegion = preData.engineRegion;
      }

      if (preData?.eventDetails) {
        this.params = preData.eventDetails;

        this.loading = true;
        const driverId = this.params[0].selectedDriver?.driverId;
        const dateRange = this.params[0].selectedDateRange;
        const timeZone = this.params[0].selectedTimezone;

        // Get all manual events
        let allEvents = [];
        let allPassedEvents = [];
        let existingBreaches = [];

        const requestData = {
          driverId,
          from: moment(dateRange[0], this.dateFormat).format(),
          to: moment(dateRange[1], this.dateFormat).format(),
        };

        await Promise.all([
          this.$store.dispatch(`report/getBreachesByDateRange`, requestData),
          this.$store.dispatch(`business/getEventByDateRange`, {
            driverId,
            from: moment(dateRange[0], this.dateFormat)
              .subtract(56, "days")
              .format(),
            to: moment(dateRange[1], this.dateFormat).add(56, "days").format(),
          }),
        ]).then((data) => {
          existingBreaches = data[0];
          allEvents = data[1];
        });

        for (const param of Object.values(this.params)) {
          const manualEvents =
            param?.events?.filter((x) => _.isEmpty(x.eventId)) || [];
          allPassedEvents = [...allPassedEvents, ...manualEvents];
          allEvents = [...allEvents, ...manualEvents];
        }

        allEvents = _.sortBy(allEvents, ["startTimeWithTimezone"]).filter(
          (x) => x.status != "deleted"
        );
        allEvents.forEach((x) => {
          x.startTime = new Date(x.startTime);
          return x;
        });

        let generatedBreaches = generateBreaches(
          driverId,
          this.businessId,
          allEvents,
          timeZone
        );

        // Remove existing breaches from generated breaches
        existingBreaches.forEach((breach) => {
          const updateBreachTime = moment(breach.time)
            .utcOffset(breach.timezone)
            .format("YYYY-MM-DD HH:mmZ");
          _.remove(generatedBreaches, (x) => x.time == updateBreachTime);
        });

        // Sort breaches
        generatedBreaches = _.sortBy(generatedBreaches, ["time"]).reverse();

        // Add isChecked property to each generated breaches
        const isChecked =
          allPassedEvents.length > 0 && _.last(allPassedEvents).type == "work"
            ? false
            : true;
        this.isDisplayNotice = !isChecked;
        generatedBreaches.forEach((breach) => (breach.isChecked = isChecked));

        // Remove duplicate breaches
        generatedBreaches = _.uniqWith(
          generatedBreaches,
          (breachA, breachB) =>
            breachA.time === breachB.time &&
            breachA.option === breachB.option &&
            breachA.period === breachB.period &&
            breachA.type === breachB.type &&
            this.cleanLevel(breachA.level) === this.cleanLevel(breachB.level)
        );

        this.breaches = generatedBreaches;

        this.loading = false;
      }
    }
  },
  methods: {
    cleanLevel(level) {
      if (typeof level == "object") level = level[0];
      return _.startCase(
        level == "no-breach" ? level.replace("-", " ") : level.split("-")[0]
      );
    },
    formatTime(time, timezone) {
      return moment(time).utcOffset(timezone).format("YYYY-MM-DD HH:mm:ssZ");
    },
    onPrevious() {
      this.$emit("backStep", this.stepsData);
    },
    async onSubmit() {
      if (!this.isTripCheck) {
        const declaredBreaches = this.breaches.filter((x) => x.isChecked);

        const step4Data = {
          eventDetails: this.params,
          breaches: declaredBreaches,
          engineRegion: this.engineRegion,
        };
        this.stepsData = { ...this.stepsData, step4Data };

        this.$emit("nextStep", { ...step4Data, stepsData: this.stepsData });
      } else {
        this.$emit("closeModal");
      }
    },
  },
};
</script>
