<template>
  <div class="pb-4 mx-auto w-full max-w-full h-full overflow-auto">
    <div
      class="flex flex-col justify-start items-start space-y-4"
      v-if="!loading"
    >
      <FormulateForm @submit="submitForm" #default="{ hasErrors }">
        <div class="w-full p-2">
          <h3 class="text-neutral-darker font-bold text-lg mb-4">
            Subscription details
          </h3>
          <div
            class="text-left bg-white border rounded p-2 sm:p-4 flex flex-col w-full"
          >
            <FormulateInput
              type="date"
              v-if="!isNewContract"
              :disabled="submitLoading"
              class="w-full"
              element-class="w-1/4"
              label="Bill Starting"
              help="This is also when the account will be charged."
              name="billStartingDate"
            />

            <FormulateInput
              type="select"
              class="w-full"
              :disabled="submitLoading"
              element-class="w-1/3"
              label="Free trial period"
              :options="{
                NO_DEMO: 'No Demo',
                ONE_WEEK: '1 week',
                TWO_WEEKS: '2 weeks',
                THREE_WEEKS: '3 weeks',
                FOUR_WEEKS: '4 weeks',
                FIVE_WEEKS: '5 weeks',
                SIX_WEEKS: '6 weeks',
                SEVEN_WEEKS: '7 weeks',
                EIGHT_WEEKS: '8 weeks',
              }"
              v-model="selectedTrialPeriod"
            />

            <FormulateInput
              type="select"
              class="w-full"
              :disabled="submitLoading"
              element-class="w-1/3"
              label="Billing Period"
              :options="{ Yearly: 'Yearly', Monthly: 'Monthly' }"
              v-model="selectedBillingPeriod"
            />
            <div class="font-semibold border-b-2 pb-2">Pricing</div>
            <div class="w-full my-2">
              <div class="text-left mx-auto max-w-[510px]">
                <div class="relative overflow-x-auto">
                  <table
                    class="w-full text-sm text-left text-gray-500 border-b-2"
                  >
                    <thead class="text-xs text-gray-700 uppercase bg-gray-100">
                      <tr>
                        <th scope="col" class="px-6 py-3 rounded-l-lg">
                          Description
                        </th>
                        <th scope="col" class="px-6 py-3 text-left">
                          Quantity
                        </th>
                        <th scope="col" class="px-6 py-3 text-right">
                          Unit Price
                        </th>
                        <th scope="col" class="px-6 py-3 text-right">Amount</th>
                        <th
                          scope="col"
                          class="px-6 py-3 rounded-r-lg text-right"
                        ></th>
                      </tr>
                    </thead>
                    <tbody v-if="!tableLoading">
                      <tr
                        class="bg-white"
                        v-for="(invoice, index) in invoiceItems"
                        :key="`table-body-${index}`"
                      >
                        <th
                          scope="row"
                          class="px-6 py-2 font-medium text-black font-bold whitespace-nowrap"
                        >
                          <div v-if="!invoice.alreadySelected">
                            <FormulateInput
                              type="select"
                              :disabled="submitLoading"
                              class="w-full"
                              element-class="w-full"
                              error-class="text-xs text-red-500 text-left"
                              help-class=""
                              outer-class=""
                              :options="rrpModules"
                              v-model="invoice.tempData"
                              placeholder="Select a module"
                              @change="updateProduct(invoice, index)"
                            />
                          </div>
                          <div v-else>
                            {{ invoice.name }}
                          </div>
                        </th>
                        <td
                          class="px-6 py-2 text-center text-gray-500 font-bold text-sm"
                        >
                          <FormulateInput
                            type="text"
                            min="1"
                            :disabled="submitLoading"
                            class="w-full"
                            element-class="w-1/3"
                            error-class="text-xs text-red-500 text-left"
                            help-class=""
                            outer-class=""
                            v-model="invoice.quantity"
                            name="Quantity"
                            validation="required|number|min:1"
                          />
                        </td>
                        <td
                          class="px-6 py-2 text-right text-gray-500 font-bold text-sm"
                        >
                          ${{ invoice.unitPrice.toFixed(2) }}
                        </td>
                        <td
                          class="px-6 py-2 text-right text-gray-500 font-bold text-sm"
                        >
                          ${{ calculateInvoiceAmount(invoice, index) }}
                        </td>
                        <td>
                          <button
                            @click.prevent="removeModule(invoice, index)"
                            class="btn btn-link hover:text-error"
                          >
                            <font-awesome-icon
                              :icon="['fas', 'times-circle']"
                            />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr class="animate-pulse">
                        <td class="h-4 rounded"></td>
                        <td class="h-4 rounded"></td>
                        <td class="h-4 rounded"></td>
                        <td class="h-4 rounded"></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="flex">
                    <a
                      class="text-sm ml-6 mt-2 font-semibold text-orange-primary hover:text-orange-primary-100 cursor-pointer"
                      @click.prevent="addProduct()"
                      >Add Product</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="flex justify-end space-x-4 my-4 items-center border-b-2 pb-4"
          >
            <div class="text-md font-medium text-gray-800">
              Total without charges:
            </div>
            <div class="text-md text-gray-800 font-medium">
              ${{ processBillTotal() }} AUD /
              {{ selectedBillingPeriod == "Yearly" ? "year" : "month" }}
            </div>
          </div>
          <div class="my-4">
            <h3 class="text-neutral-darker font-bold text-lg">
              Subscription settings
            </h3>
            <span class="text-xs text-neutral"
              >These settings apply to the entire subscription.</span
            >
            <div class="mt-4">
              <h4 class="font-semibold text-neutral border-b-2 pb-2">
                Payment Option
              </h4>
              <FormulateInput
                :options="paymentOptions"
                :disabled="submitLoading"
                v-model="selectedPaymentOption"
                type="radio"
                class="custom-options mt-4"
              />
              <!-- <FormulateInput
                type="checkbox"
                class="formulate-input"
                name="billedExternally"
                label="Let the business decide when to start the contract"
                v-model="businessInput"
                label-class="formulate-input-label formulate-input-label--after"
                input-class=""
                wrapper-class="formulate-input-wrapper"
                outer-class=""
                help="*Check this box if you want to show the contract popup on business login."
                element-class="formulate-input-element formulate-input-element--checkbox"
              />
              <div v-if="selectedPaymentOption == 'credit-card' && businessInput == false && hasActivePaymentGateway">
                <label class="font-medium text-sm mb-1">Card Details</label>
                <stripe-element-card
                  class="mb-5"
                  ref="cardRef"
                  :pk="publishableKey"
                  @token="tokenCreated"
                  @element-change="updateSubmitButton"
                  @element-blur="cardChange"
                  :hidePostalCode="true"
                />
              </div> -->
            </div>
          </div>
          <FormulateInput
            type="submit"
            class="w-full flex justify-end mt-4"
            input-class="btn btn-primary w-full"
            :disabled="hasErrors || submitLoading"
          >
            <font-awesome-icon
              v-if="submitLoading"
              :icon="['fas', 'spinner']"
              spin
              class="text-white mr-2"
            />

            <span v-if="!submitLoading">Update Subscription</span>
            <span v-if="submitLoading">Submitting . . .</span>
          </FormulateInput>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {},
  props: {
    isNewContract: {
      default: false,
      type: Boolean,
    },
    contractDetails: {
      default: () => {
        return {};
      },
      type: Object,
    },
    paymentGateway: {
      default: () => {
        return {};
      },
      type: Object,
    },
    publishableKey: {
      default: null,
      type: String,
    },
    paymentGatewayId: {
      default: null,
      type: String,
    },
    hasActivePaymentGateway: {
      default: false,
      type: Boolean,
    },
    entityRole: {
      default: "business",
      type: String,
    },
  },
  data() {
    return {
      invoiceItems: [],
      loading: false,
      billTotal: 0,
      formValues: {},
      selectedTrialPeriod: "",
      selectedPaymentOption: "",
      selectedBillingPeriod: "",
      paymentOptions: {
        "credit-card": "Automatically charge a payment from credit card",
        invoice: "Invoice the customer to pay manually",
      },
      businessInput: true,
      buttonText: "Update Subscription",
      submitLoading: false,
      me: {},
      rrpModules: [],
      productOptions: [],
      tableLoading: false,
    };
  },
  async created() {
    await this.init();
  },

  methods: {
    async getRRPPricing() {
      await this.$store
        .dispatch("standardpricing/getActiveRRPforBusiness", {
          id: this.me.entity._id,
        })
        .then((response) => {
          let rrpModules = response.map((item) => {
            //disabled if the module with same name is part of invoiceItems
            let disabled = this.invoiceItems.some((invoiceItem) => {
              return item.standardPricingMaster.apiTag == invoiceItem.apiTag;
            });
            return {
              name: item.name,
              id: item._id,
              pricing: item.pricing,
              apiTag: item.standardPricingMaster.apiTag,
              label: item.name,
              value: {
                name: item.name,
                id: item._id,
                pricing: item.pricing,
                apiTag: item.standardPricingMaster.apiTag,
                disabled: disabled,
              },
              disabled: disabled,
            };
          });

          this.rrpModules = rrpModules;
        });
    },
    async updateRRPModules() {
      let rrpModules = this.rrpModules.map((item) => {
        //disabled if the module with same name is part of invoiceItems
        let disabled = this.invoiceItems.some((invoiceItem) => {
          return item.apiTag == invoiceItem.apiTag;
        });
        return {
          name: item.name,
          id: item.id,
          pricing: item.pricing,
          apiTag: item.apiTag,
          label: item.label,
          value: item,
          disabled: disabled,
        };
      });
      this.rrpModules = rrpModules;
    },
    async addProduct() {
      this.invoiceItems.push({
        name: "",
        quantity: 1,
        unitPrice: 0,
        totalAmount: 0,
        id: "",
        apiTag: "",
        newProduct: true,
        tempData: {},
      });
    },
    async init() {
      this.loading = true;
      this.tableLoading = true;
      this.me = this.$store.getters[`account/me`];
      this.selectedTrialPeriod = await this.contractDetails.businessMetaData
        .demoOption;
      this.selectedBillingPeriod = await this.contractDetails.billingPeriod
        .name;
      this.selectedPaymentOption = this.contractDetails.hasRecurringPayment
        ? "credit-card"
        : "invoice";
      //initialize data
      this.invoiceItems = await this.contractDetails.businessModules.map(
        (item) => {
          let unitPrice =
            this.selectedBillingPeriod == "Yearly"
              ? item.price?.yearly
              : item.price?.monthly;
          return {
            name: item.name,
            quantity: item.minimums,
            unitPrice: unitPrice,
            totalAmount: 0,
            id: item._id,
            apiTag: item.apiTag,
            newProduct: false,
            alreadySelected: true,
          };
        }
      );
      await this.getRRPPricing();
      this.loading = false;
      this.tableLoading = false;
    },
    async submitForm() {
      this.submitLoading = true;
      const formData = {
        demoOption: this.selectedTrialPeriod,
        billingPeriod: this.selectedBillingPeriod,
        hasRecurringPayment:
          this.selectedPaymentOption == "credit-card" ? true : false,
        businessModules: this.invoiceItems,
        contractId: this.contractDetails._id,
        entityRole: this.entityRole,
      };

      await this.$store
        .dispatch("contract/updateSubscriptionAndContract", formData)
        .then(() => {
          this.toast("success", "Subscription successfully updated.");
          this.$emit("subscriptionUpdated");
        })
        .catch((error) => {
          this.toast("error", `Error updating subscription.${error}`);
        })
        .finally(() => {
          this.submitLoading = false;
        });
    },

    async toast(state, message) {
      this.isLoading = true;

      const toastPayload = {
        state,
        message,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", toastPayload);
      this.isLoading = false;
    },

    calculateInvoiceAmount(invoice, index) {
      let totalAmount = parseFloat(invoice.quantity * invoice.unitPrice);
      this.invoiceItems[index].totalAmount = totalAmount;
      return totalAmount.toFixed(2);
    },

    processBillTotal() {
      let totalAmount = 0;
      this.invoiceItems.forEach((item) => {
        totalAmount += item.totalAmount;
      });
      this.billTotal = totalAmount;

      return totalAmount;
    },

    removeModule(module, index) {
      this.invoiceItems.splice(index, 1);
      // this.calculateInvoiceAmount(module, index);
      this.updateRRPModules();
    },

    updateProduct(invoice, index) {
      let currentModule;
      this.contractDetails.businessModules.find((item) => {
        if (item.apiTag === invoice.tempData.apiTag) {
          let unitPrice =
            this.selectedBillingPeriod == "Yearly"
              ? item.price?.yearly
              : item.price?.monthly;
          currentModule = {
            name: item.name,
            quantity: invoice.quantity,
            unitPrice: unitPrice,
            totalAmount: 0,
            id: item._id,
            apiTag: item.apiTag,
            newProduct: false,
            alreadySelected: true,
          };
        } else {
          let unitPrice =
            this.selectedBillingPeriod == "Yearly"
              ? invoice.tempData?.pricing?.yearly
              : invoice.tempData?.pricing?.monthly;
          //if the module is a newly added module
          currentModule = {
            name: invoice.tempData.name,
            quantity: invoice.quantity,
            unitPrice: parseFloat(unitPrice),
            totalAmount: 0,
            id: invoice.tempData.id,
            pricingId: invoice.tempData.id,
            apiTag: invoice.tempData.apiTag,
            newProduct: true,
            alreadySelected: true,
            pricing: invoice.tempData.pricing,
          };
        }
      });

      this.invoiceItems[index] = currentModule;
    },
  },
  watch: {
    async selectedBillingPeriod() {
      this.invoiceItems = await this.contractDetails.businessModules.map(
        (item) => {
          let unitPrice =
            this.selectedBillingPeriod == "Yearly"
              ? item.price?.yearly
              : item.price?.monthly;
          return {
            name: item.name,
            quantity: item.minimums,
            unitPrice: unitPrice,
            totalAmount: 0,
            id: item._id,
            apiTag: item.apiTag,
            newProduct: false,
            alreadySelected: true,
          };
        }
      );
    },
    async businessInput() {
      if (this.businessInput == true) {
        this.submitLoading = false;
        this.buttonText = "Update Subscription";
      }
    },
    async invoiceItems() {
      await this.updateRRPModules();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
