<template>
  <div>
    <div class="flex justify-between items-center w-full mb-4 mt-5">
      <div class="flex">
        <div class="block relative">
          <span
            class="h-full absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer"
          >
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            type="text"
            placeholder="Search AFM Access..."
            class="h-full appearance-none rounded border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="filter.search"
            @input="handleSearchKeyDown"
          />
        </div>
      </div>
      <div>
        <button
          class="btn btn-primary"
          @click="addAfmAccess()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add AFM
        </button>
      </div>
    </div>
    <div class="flex w-full py-5" v-if="isLoading">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
    <div v-if="!isLoading" class="mb-16">
      <div
        class="text-red-500 font-bold text-sm text-center my-5"
        v-if="errorMessage.length"
      >
        {{ errorMessage[0] }}
      </div>
      <div
        class="text-gray-500 text-sm text-center my-5"
        v-if="!afmAccessData.length"
      >
        <strong v-if="!errorMessage.length">No AFM Access found.<br /></strong>
      </div>
      <Table
        v-if="afmAccessData.length"
        :tableHeader="tableHeader"
        :data="afmAccessData"
        :hasFilters="false"
        :hasActions="false"
      >
        <template #default="{ data }">
          <fragment v-if="data">
            <td class="px-5 py-2">
              <button
                class="btn btn-primary mt-2"
                @click="editAfmAccess(data)"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Edit
              </button>
            </td>
          </fragment>
        </template>
      </Table>
      <!-- Modal -->
      <Modal
        ref="modal"
        :title="modal.modalTitle"
        size="3xl"
        :height="modal.modalHeight"
      >
        <div v-if="modal.modalContent == 'add-afm'">
          <NewAfmAccessForm
            :business-id="businessId"
            @closeModal="closeModal"
          />
        </div>
        <div v-if="modal.modalContent == 'edit-afm'">
          <NewAfmAccessForm
            :business-id="businessId"
            :afm-access-id="modal.modalId"
            :afm-access="modal.modalData"
            @closeModal="closeModal"
          />
        </div>
      </Modal>
      <!-- End Modal -->
    </div>
  </div>
</template>
<script>
import { Table, Modal } from "@/components/commons";
import _ from "lodash";
import Spinner from "@/components/commons/ui/Spinner";
import { mapGetters } from "vuex";
import { formatDate, formatPaginationSettings } from "@/_helper";
import {
  DEFAULT_MODAL_HEIGHT,
  SEARCH_DEBOUNCE_DELAY,
} from "@/_helper/constants";
import NewAfmAccessForm from "./new-afm-access-form.vue";

export default {
  name: "AFM-Access-Form",
  components: { Table, Spinner, NewAfmAccessForm, Modal },
  props: {
    businessId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableHeader: [
        "AFM Number",
        "Effective Date",
        "Expiry Date",
        "Number of Tables",
        "Status",
        "Actions",
      ],
      filter: {
        limit: 10,
        status: "all",
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      afmAccess: [],
      afmAccessRaw: [],
      searchKeyword: "",
      errorMessage: [],
      isLoading: false,
      modal: {
        modalContent: false,
        modalTitle: "",
        modalId: "",
        modalHeight: DEFAULT_MODAL_HEIGHT,
        modalData: {},
      },
    };
  },
  mounted() {
    this.init();
  },

  methods: {
    async init(paged = 1) {
      this.isLoading = true;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: {},
      };

      const afmAccess = await this.$store.dispatch(
        "business/findBusinessAFMAccess",
        { businessId: this.businessId, query }
      );
      if (afmAccess) {
        if (afmAccess?.metadata) {
          this.paginationSettings = formatPaginationSettings(
            afmAccess?.metadata
          );
          this.afmAccessRaw = afmAccess?.results;
        } else {
          this.paginationSettings = formatPaginationSettings();
        }

        const afmAccessTableData = this.formatItem(afmAccess.results);
        this.afmAccess = afmAccessTableData;
      } else {
        this.afmAccess = [];
      }
      this.isLoading = false;
      this.$emit("updateLoading", false);
    },

    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },

    handleSearchKeyDown() {
      this.stoppedTyping();
    },

    debouncedSearchString() {
      this.init();
    },

    addAfmAccess() {
      this.modal.modalContent = "add-afm";
      this.modal.modalTitle = "Add AFM Access";
      this.$refs.modal.openModal();
      this.modal.modalId = this.businessId;
      this.modal.modalHeight = DEFAULT_MODAL_HEIGHT;
    },

    editAfmAccess(data) {
      this.modal.modalContent = "edit-afm";
      this.modal.modalTitle = "Edit AFM Access";
      this.$refs.modal.openModal();
      this.modal.modalId = data.id;
      this.modal.modalHeight = DEFAULT_MODAL_HEIGHT;
      this.modal.modalData = data;
    },

    closeModal() {
      this.init();
    },

    formatItem(items) {
      let nItems = [];
      if (items) {
        items.forEach((item) => {
          const afmAccess = {
            id: item?._id,
            afmNumber: item?.afmNumber,
            effectiveDate: formatDate(item?.effectiveDate),
            expiryDate: formatDate(item?.expiryDate),
            numberOfTables: item?.tables?.length,
            isActive: item.isActive ? true : false,
          };
          nItems.push(afmAccess);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    // Format table data
    parseAFMAccess(data) {
      return data.map((afmAccessData) => [
        {
          id: afmAccessData.id,
          name: afmAccessData.afmNumber,
          itemType: "string",
        },
        {
          id: afmAccessData.id,
          name: afmAccessData.effectiveDate,
          itemType: "string",
        },
        {
          id: afmAccessData.id,
          name: afmAccessData.expiryDate,
          itemType: "string",
        },
        {
          id: afmAccessData.id,
          name: afmAccessData.numberOfTables,
          itemType: "string",
        },
        {
          id: afmAccessData.id,
          name: afmAccessData.isActive ? "Active" : "Deactivated",
        },
      ]);
    },
  },

  computed: {
    afmAccessData() {
      if (this.afmAccess) {
        return this.parseAFMAccess(this.afmAccess);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.stoppedTyping = _.debounce(
      this.debouncedSearchString,
      SEARCH_DEBOUNCE_DELAY,
      {
        leading: false,
        trailing: true,
      }
    );
  },
};
</script>
