<template>
  <div class="mb-8">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Fetching data...</span>
      </div>
    </div>

    <div class="items-center justify-between" v-else>
      <div class="grid">
        <div class="mb-4">
          <FormulateInput
            placeholder="Link Name"
            name="linkName"
            v-model="inputValue.linkName"
            label="Link Name"
            type="text"
            validation="required"
            validation-error="required"
            class="block h-12 w-full leading-tight"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            placeholder="https://logmaster.au"
            name="link"
            v-model="inputValue.link"
            label="Link"
            type="text"
            validation="required"
            validation-error="required"
            class="block h-12 w-full leading-tight"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            type="select"
            name="requestType"
            label="Request Type"
            v-model="inputValue.httpRequestMethod"
            :options="requestTypes"
            placeholder="Select a request type"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            type="textarea"
            name="apiHeaderAuth"
            label="API Header Authentication"
            v-model="inputValue.apiHeaderAuth"
            :options="requestTypes"
            validation="required"
            placeholder="API Header Authentication token"
          />
        </div>
        <div class="mb-4">
          <div
            class="mb-2 font-semibold text-sm block form-label text-neutral-dark"
          >
            <h4>Query Parameters</h4>
          </div>
          <div class="border rounded p-5">
            <template v-for="(queryParameter, index) in queryParameters">
              <div class="flex space-x-4 mb-4" :key="index">
                <FormulateInput
                  name="fieldName"
                  v-model="queryParameter.fieldName"
                  label="Field"
                  type="text"
                  validation="required"
                  validation-error="required"
                  class="h-12 leading-tight"
                />
                <FormulateInput
                  name="fieldType"
                  v-model="queryParameter.fieldType"
                  label="Type"
                  type="select"
                  :options="fieldTypes"
                  validation="required"
                  validation-error="required"
                  class="h-12 leading-tight"
                />
                <FormulateInput
                  v-if="queryParameter.fieldType === 'string'"
                  name="fieldValue"
                  v-model="queryParameter.fieldValue"
                  label="Value"
                  type="text"
                  :options="fieldTypes"
                  validation="required"
                  validation-error="required"
                  class="h-12 leading-tight"
                />
                <FormulateInput
                  v-if="queryParameter.fieldType === 'number'"
                  name="fieldValue"
                  v-model="queryParameter.fieldValue"
                  label="Value"
                  type="text"
                  validation="required|number"
                  validation-error="required"
                  class="h-12 leading-tight"
                  min="0"
                />
                <div
                  v-if="queryParameter.fieldType === 'dateTime'"
                  class="flex space-x-2"
                >
                  <FormulateInput
                    name="dateValue"
                    v-model="queryParameter.dateValue"
                    label="Date"
                    type="date"
                    validation="required"
                    validation-error="required"
                    class="h-12 leading-tight"
                    min="0"
                  />
                  <FormulateInput
                    name="timeValue"
                    v-model="queryParameter.timeValue"
                    label="Time"
                    type="time"
                    validation="required"
                    validation-error="required"
                    class="h-12 leading-tight"
                    min="0"
                  />
                </div>
                <div class="flex items-end mb-1">
                  <button
                    class="bg-red-400 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                    @click.prevent="removeRow(index)"
                  >
                    <font-awesome-icon icon="minus" class="my-auto" />
                  </button>
                </div>
              </div>
            </template>
            <div class="flex w-full justify-start mt-4">
              <button
                class="bg-gray-400 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded-full"
                @click.prevent="addParameter()"
              >
                <font-awesome-icon icon="plus" class="my-auto" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormulateInput
        type="submit"
        @click.prevent="onSubmit()"
        input-class="btn btn-primary w-full"
        :disabled="formLoading"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        <span v-if="formLoading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Submitting
          data...</span
        >
        <span v-if="!formLoading">Submit</span>
      </FormulateInput>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Create-Notification-API-Link-Form",
  props: {
    entityId: {
      type: String,
      default: "",
    },
    businessId: {
      type: String,
      default: "",
    },
    values: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      inputValue: {},
      requestTypes: {
        post: "POST",
        get: "GET",
      },
      queryParameters: [
        {
          fieldName: "",
          fieldType: "string",
          fieldValue: "",
        },
      ],
      fieldTypes: {
        formId: "Form ID",
        currentTimeStamp: "Current Timestamp",
        createdAt: "Date Created",
        object: "Notification Object",
      },
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  async created() {
    if (Object.keys(this.values).length > 0) {
      this.inputValue = { ...this.values };
      await this.setQueryParameters();
    }
  },
  methods: {
    addParameter() {
      this.queryParameters.push({
        fieldName: "",
        fieldType: "string",
        fieldValue: "",
      });
    },
    removeRow(index) {
      this.queryParameters.splice(index, 1);
    },
    async onSubmit() {
      this.formLoading = true;
      let finalParams = {};

      this.queryParameters.map((param) => {
        if (param.fieldType === "formId") {
          finalParams[param.fieldName] = "formId";
        } else if (param.fieldType === "currentTimeStamp") {
          finalParams[param.fieldName] = "currentTimeStamp";
        } else if (param.fieldType === "createdAt") {
          finalParams[param.fieldName] = "createdAt";
        } else if (param.fieldType === "object") {
          finalParams[param.fieldName] = "object";
        }
      });
      this.inputValue.queryParameters = finalParams;
      this.me = this.$store.getters[`account/me`];
      const formData = { ...this.inputValue };
      formData.partnerId = this.entityId;
      formData.businessId = this.businessId;
      formData.id = this.inputValue._id;
      formData.linkType = "notification-link";

      await this.$store
        .dispatch(`dynamicLink/createNotificationAPILink`, formData)
        .then((response) => {
          if (response.success == true) {
            this.$emit("closeModal");
            this.$emit("submitSuccess");
            this.$store.commit("setDialogNotify", true);

            if (Object.keys(this.values).length > 0) {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Notification Link successfully updated.",
              });
            } else {
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Notification Link successfully created.",
              });
            }
            location.reload();
          }
        })
        .catch((err) => {
          this.errorMessage = err;
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
    async setQueryParameters() {
      const queryParameters = await this.values.queryParameters;
      const editQueryParameters = [];
      for (const param of Object.keys(queryParameters)) {
        if (queryParameters[param] === "formId") {
          editQueryParameters.push({
            fieldName: param,
            fieldType: "formId",
            fieldValue: "",
          });
        } else if (queryParameters[param] === "currentTimeStamp") {
          editQueryParameters.push({
            fieldName: param,
            fieldType: "currentTimeStamp",
            fieldValue: "",
          });
        } else if (queryParameters[param] === "createdAt") {
          editQueryParameters.push({
            fieldName: param,
            fieldType: "createdAt",
            fieldValue: "",
          });
        } else if (queryParameters[param] === "object") {
          editQueryParameters.push({
            fieldName: param,
            fieldType: "object",
            fieldValue: "",
          });
        }
      }
      this.queryParameters = editQueryParameters;
    },
  },
};
</script>
