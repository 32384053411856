import { Settings, Theme } from "@/api";
import { isEmpty } from "lodash";
export default {
  //@PATCH: Update theme via theme options id
  async updateThemeSettings({ commit }, data) {
    try {
      const themeData = await Settings.updateThemeSettings(data);
      if (themeData.data.success) {
        commit("theme/updateActiveTheme", data?.themeData, { root: true });
      }
    } catch (e) {
      throw new Error(e.message);
    }
  },

  //@GET: Action to fetch settings per account
  async getUserSettings({ commit, state }, accountId) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const response = await Settings.getUserSettings({ accountId });

      if (response?.data?.data?.themeId) {
        commit("theme/updateActiveTheme", response.data.data.theme, {
          root: true,
        });
      } else { // If there is no data found, use Logmaster one.
        const response = await Theme.getThemeListsById("logmaster");
        const data = response?.data?.data;

        if (!isEmpty(data)) {
          commit("theme/updateThemeLookupOptions",
            data, { root: true });
          commit("theme/updateActiveTheme",
            data[0], { root: true, });
        }
      }

      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  //@POST: Create a settings per account
  async createSettings({ state }, settingsData) {
    if (!state) throw `Invalid + ${state}`;

    try {
      const response = await Settings.createSettings(settingsData);

      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  /** Release Notes */
  //@POST: Create a Release Note
  async createReleaseNote({ state }, settingsData) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Settings.createReleaseNote(settingsData);

      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  //@PATCH: Update Release Notes
  async updateReleaseNote({ state }, payload) {
    if (!payload.id) throw `Invalid data ${state}`;
    try {
      const response = await Settings.updateReleaseNote(payload);

      return response.data;
    } catch (e) {
      throw new Error(e.message);
    }
  },

  //@GET: Get Release Note(s)
  async getReleaseNotes({ state }) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await Settings.getReleaseNotes();

      return response.data.data;
    } catch (error) {
      throw error.response.data;
    }
  },
};
