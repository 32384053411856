<template>
  <div class="w-full flex">
    <div class="w-10/12 mx-auto max-w-xl">
      <FormulateForm class="bg-white border border-gray-300 rounded">
        <div class="mb-5">
          <div
            class="text-sm text-neutral-dark font-bold form-label pr-2 p-5 rounded-t border-b border-gray-300"
          >
            Menu Display Preference
          </div>

          <div class="mx-5 mt-2 text-sm text-neutral-dark">
            <span
              >Enhance your menu presentation by positioning it either along the
              side or at the top of the screen.</span
            >
          </div>

          <div class="flex items-center mt-5 mr-1 mx-5">
            <div
              class="relative block w-12 align-middle select-none transition duration-200 ease-in"
            >
              <input
                type="checkbox"
                name="isSideMenu"
                v-model="isSideMenu"
                class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
                @change="onChange"
              />
              <label
                for="toggle"
                class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
              ></label>
            </div>

            <div class="text-neutral-dark font-semibold text-sm ml-2">
              {{ isSideMenu ? "Side" : "Top" }}
            </div>
          </div>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      isSideMenu: true,
    };
  },
  mounted() {
    this.isSideMenu = this.$store.getters["account/getIsSideMenu"];
  },
  methods: {
    onChange() {
      this.$store.commit("account/setIsSideMenu", this.isSideMenu);
      window.location.reload();
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
