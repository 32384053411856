<template>
  <div>
    <div class="isLoading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>

    <div v-if="!isLoading" class="mb-16">
      <div class="mb-10 text-center">
        <div class="text-2xl font-bold text-black-primary">
          {{ driver.name }}
        </div>
        <div class="text-xs">{{ driver.email }}</div>
      </div>
      <div class="w-full pb-1 mb-5 border-b">
        <div class="text-xs font-bold">Firebase User Settings</div>
      </div>
      <div class="mb-5">
        <div v-if="userSettings.length == 0">
          <h2>Driver doesn't have any firebase settings.</h2>
        </div>
        <div v-else class="grid grid-cols-2 gap-x-4 gap-y-3">
          <div v-for="setting of userSettings" :key="setting.key">
            <div v-if="typeof setting.value == 'string'">
              <FormulateInput
                name="emailAddress"
                v-model="setting.value"
                type="text"
                class="w-5/6"
                :label="setting.key"
              />
            </div>
            <div v-else>
              <div
                class="pr-2 mb-1 text-sm font-semibold form-label text-neutral-dark"
              >
                {{ setting.key }}
              </div>
              <div
                class="relative inline-block w-10 mr-2 align-middle transition duration-200 ease-in select-none"
              >
                <input
                  type="checkbox"
                  v-model="setting.value"
                  name="toggle"
                  class="absolute block w-6 h-6 bg-white border-4 border-gray-300 rounded-full appearance-none cursor-pointer toggle-checkbox"
                />
                <label
                  for="toggle"
                  class="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer toggle-label"
                ></label>
              </div>
            </div>
          </div>
        </div>
        <FormulateInput
          type="submit"
          class="mt-5 rounded"
          @click="updateSettings"
          input-class="w-full btn btn-primary"
          :disabled="isSubmitting || isLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="isSubmitting"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" />
            Submitting...</span
          >
          <span v-if="!isSubmitting">Submit</span>
        </FormulateInput>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'View-Driver-Details',
  props: {
    activeDriver: {
      type: Object,
      required: true,
    },
    showDelink: {
      type: Boolean,
      default: false,
    },
    showInvite: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      businessid: '',
      driverid: '',
      isLoading: false,
      isSubmitting: false,
      userSettings: [],
    };
  },
  async mounted() {
    this.isLoading = true;
    const response = await this.$store.dispatch(
      'driver/getFirebaseUserSettings',
      this.activeDriver._id
    );
    this.isLoading = false;

    if (response) {
      let settings = response.filter((x) => x.key !== 'last_seen');
      settings.sort((a, b) => (a.key > b.key ? 1 : -1));
      let deviceIndex = settings.findIndex((obj) => obj.key === 'device');
      if (deviceIndex >= 0) {
        let deviceObj = settings.splice(deviceIndex, 1)[0];
        settings.unshift(deviceObj);
      }

      this.userSettings = settings;
    }
  },
  methods: {
    async updateSettings() {
      this.isSubmitting = true;
      try {
        const payload = {
          id: this.activeDriver._id,
          data: this.userSettings,
        };
        await this.$store.dispatch(
          'driver/updateFirebaseUserSettings',
          payload
        );
        this.toast('success', 'Firebase user settings successfully updated.');
      } catch (error) {
        this.toast('error', 'Something went wrong');
      }
      this.isSubmitting = false;
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit('setDialogNotify', true);
      this.$store.commit('setMessageNotify', message);
    },
  },
  computed: {
    driver() {
      return {
        driverId: this.activeDriver._id || '-',
        email: this.activeDriver.emailAddress || '-',
        isActive: this.activeDriver.isActive || '-',
        name: this.activeDriver.driverName || '-',
      };
    },
    ...mapGetters('theme', {
      theme: 'getColorScheme',
    }),
  },
};
</script>
