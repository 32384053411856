import { API } from "@/api/config";
import { ObjectToQueryString } from "@/_helper";

class Report {
  getAllReportJobs() {
    return API.ACCOUNTS.get(`/report-job/`);
  }

  processReportJob(id) {
    return API.ACCOUNTS.get(`/report-job/${id}`);
  }

  downdloadReport(data) {
    return API.ACCOUNTS.post(`/report-v2/report-transfer/download`, data);
  }

  sendReport(data) {
    return API.ACCOUNTS.post(`/report-v2/report-transfer/send`, data);
  }

  getBreachesByDateRange(payload) {
    const driverId = payload.driverId;
    if (payload?.filter && "driverIds" in payload?.filter) {
      payload.filter.driverId = payload.filter.driverIds;
      delete payload.filter.driverIds;
    }
    return API.ACCOUNTS.post(
      `/breach-engine/findbydaterange/${driverId}`,
      payload
    );
  }

  getBreachesById(id) {
    return API.ACCOUNTS.get(`/breach-engine/${id}`);
  }

  getBreach(payload) {
    return API.ACCOUNTS.get(`/breach-engine/${payload.id}`);
  }

  sendBreachIndividualReport(data) {
    return API.ACCOUNTS.post(`/report-v2/breach/individual`, data);
  }

  sendBreachSummaryReport(data) {
    return API.ACCOUNTS.post(`/report-v2/breach/summary`, data);
  }

  sendFormsReport(data) {
    return API.ACCOUNTS.post(`/form-report/send`, data);
  }

  getNonConformance(pagination = { skip: 0, limit: 10, filter: {} }) {
    let filter = "";
    if (typeof pagination.filter === "object") {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    }

    pagination.filter = filter;

    filter = JSON.stringify(pagination.filter);

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";
    return API.ACCOUNTS.get(`/non-conformance/${pagination}`);
  }

  getNhvrReportData() {
    return API.ACCOUNTS.post("/report/nhvr-report");
  }

  generateReport(payload) {
    return API.ACCOUNTS.post(`/report/generate-report`, payload);
  }

  getNonConformanceByRegisterId(payload) {
    // registerId is incidentId, hazardId, defectId etc.
    let { registerId, pagination = { skip: 0, limit: 10 } } = payload;
    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";

    return API.ACCOUNTS.get(
      `/non-conformance/register/${registerId}${pagination}`
    );
  }

  getNonConformanceCountByRegisterType(payload) {
    const { startDate, endDate, type } = payload;
    return API.ACCOUNTS.get(
      `non-conformance/register-type/${type}/count?startDate=${startDate}&endDate=${endDate}`
    );
  }

  reviewNonConformance(payload) {
    const { documentId } = payload;
    delete payload.documentId;
    return API.ACCOUNTS.patch(`/non-conformance/review/${documentId}`, payload);
  }

  createNonConformance(payload) {
    return API.ACCOUNTS.post(`/non-conformance`, payload);
  }

  getDriverStats(payload) {
    return API.ACCOUNTS.post("/report/driver-stats", payload);
  }

  getCompliancePeriodReport(payload) {
    return API.ACCOUNTS.post("/report/compliance-period", payload);
  }

  sendCompliancePeriodReport(payload) {
    return API.ACCOUNTS.post("/report-v2/compliance-period/send", payload);
  }

  getVehicleReport(payload) {
    return API.ACCOUNTS.post("/report/vehicle-report", payload);
  }

  sendVehicleReport(payload) {
    return API.ACCOUNTS.post("/report/vehicle-report/send", payload);
  }

  getNonConformanceSummaryReport(paylaod) {
    return API.ACCOUNTS.post(`non-conformance/summary-report`, paylaod);
  }

  getNonConformanceChainOfActions(id) {
    return API.ACCOUNTS.get(`non-conformance/${id}/chain/actions`);
  }

  getVehicleMassReport(payload) {
    return API.ACCOUNTS.post("/report/vehicle-mass-report", payload);
  }

  sendVehicleMassReport(payload) {
    return API.ACCOUNTS.post("/report/vehicle-mass-report/send", payload);
  }

  getDriverMassReport(payload) {
    return API.ACCOUNTS.post("/report/driver-mass-report", payload);
  }

  sendDriverMassReport(payload) {
    return API.ACCOUNTS.post("/report-v2/driver-mass/send", payload);
  }

  getFleetMassReport(payload) {
    return API.ACCOUNTS.post("/report/fleet-mass-report", payload);
  }

  sendFleetMassReport(payload) {
    return API.ACCOUNTS.post("/report/fleet-mass-report/send", payload);
  }

  sendPreStartSummaryReport(payload) {
    return API.ACCOUNTS.post("/report/pre-start-summary-report/send", payload);
  }

  sendFitToDriveSummaryReport(payload) {
    return API.ACCOUNTS.post(
      "/report/fit-to-drive-summary-report/send",
      payload
    );
  }

  sendInvestigationSummaryReport(payload) {
    return API.ACCOUNTS.post(
      "/report/investigation-summary-report/send",
      payload
    );
  }

  sendPerformanceSummaryReport(payload) {
    return API.ACCOUNTS.post(
      "/report/performance-summary-report/send",
      payload
    );
  }

  sendNonConformanceSummaryReport(payload) {
    return API.ACCOUNTS.post(
      "/report/non-conformance-summary-report/send",
      payload
    );
  }

  getDriverContractsReport(pagination = { skip: 0, limit: 10, filter: {} }) {
    let filter = "";
    if (typeof pagination.filter === "object") {
      try {
        filter = JSON.stringify(pagination.filter);
      } catch (e) {
        throw "Invalid Filter object";
      }
    }

    pagination.filter = filter;

    if (pagination) pagination = "?" + ObjectToQueryString(pagination) + "&";
    return API.ACCOUNTS.get(`/report/driver-contracts${pagination}`);
  }

  sendDriverContractsReport(payload) {
    return API.ACCOUNTS.post("/report/driver-contracts/send", payload);
  }
}

export default new Report();
