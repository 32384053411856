<template>
  <!-- Payment Failed -->
  <div
    class="pb-4 px-4 mx-auto w-full max-w-full h-full lg:pb-4 lg:px-4 overflow-auto"
  >
    <div
      class="flex flex-col justify-start items-start space-y-4"
      v-if="!loading"
    >
      <div class="w-full p-2 text-left bg-white border rounded-lg sm:p-4">
        <h3 class="text-neutral-darker font-bold mb-4 text-lg border-b pb-2">
          Contract Details
        </h3>
        <div class="flex justify-between items-center space-x-4 mb-8">
          <div class="flex flex-col justify-start items-start w-1/3">
            <p class="text-sm">Contract Duration</p>
            <p class="text-neutral-darker font-bold text-lg mb-2">
              {{
                contractDetails.contractDurationYears
                  ? contractDetails.contractDurationYears
                  : ""
              }}
              {{
                contractDetails.contractDuration.name == "Yearly"
                  ? "Year(s)"
                  : "Monthly"
              }}
            </p>
          </div>
          <div class="flex flex-col justify-start items-start w-1/3">
            <p class="text-sm">Billing Period</p>
            <p class="text-neutral-darker font-bold text-lg mb-2">
              {{ contractDetails.billingPeriod.name }}
            </p>
          </div>
          <div class="flex flex-col justify-start items-start w-1/3">
            <p class="text-sm">Contract End Date</p>
            <p class="text-neutral-darker font-bold text-lg mb-2">
              {{ convertedDate(contractDetails.expiryDate) }}
            </p>
          </div>
        </div>
        <div class="flex justify-between items-center space-x-4">
          <div class="flex flex-col justify-start items-start w-1/3">
            <p class="text-sm">Cost</p>
            <p class="text-neutral-darker font-bold text-lg mb-2">
              {{ totalCost(contractDetails) }}/{{
                contractDetails.billingPeriod.name == "Yearly"
                  ? "Year"
                  : "Month"
              }}
              + GST
            </p>
          </div>
          <div class="flex flex-col justify-start items-start w-1/3">
            <p class="text-sm">Last Payment Date</p>
            <p
              class="text-neutral-darker font-bold text-lg mb-2"
              v-if="!isDemoPopup"
            >
              {{ convertedDate(contractDetails.lastPaymentDate) }}
            </p>
            <p
              class="text-neutral-darker font-bold text-lg mb-2"
              v-else-if="isDemoPopup"
            >
              DEMO PERIOD
            </p>
          </div>
          <div class="flex flex-col justify-start items-start w-1/3">
            <p class="text-sm">Payment Details</p>
            <p
              class="text-neutral-darker text-lg font-bold mb-2"
              v-if="paymentMethodDetails.last4"
            >
              <font-awesome-icon
                :icon="paymentDetailsIcon"
                class="mr-1"
              />••••{{ paymentMethodDetails.last4 }}
            </p>
            <p v-else class="text-neutral-darker text-lg font-bold mb-2">
              No Card
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="flex justify-center items-center p-8">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="flex flex-col mt-4 w-full">
      <FormulateInput
        v-if="contractDetails.hasRecurringPayment"
        type="button"
        class="w-full"
        input-class="btn btn-primary w-full text-sm md:text-base"
        :disabled="submitLoading"
        @click.prevent="openUpdatePaymentForm"
      >
        <span v-if="submitLoading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
          data...</span
        >
        <span v-if="!submitLoading">Update Card Details</span>
      </FormulateInput>
      <FormulateInput
        v-if="!isDemoPopup"
        type="button"
        class="w-full"
        input-class="btn btn-outline-error w-full text-sm md:text-base"
        :disabled="submitLoading"
        @click.prevent="openCancelSubscriptionModal"
      >
        <span v-if="submitLoading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
          data...</span
        >
        <span v-if="!submitLoading">Cancel Subscription</span>
      </FormulateInput>
      <FormulateInput
        type="button"
        v-if="isDemoPopup"
        class="w-full"
        input-class="btn btn-outline-error w-full text-sm md:text-base"
        :disabled="submitLoading"
        @click.prevent="openCancelDemoModal"
      >
        <span v-if="submitLoading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
          data...</span
        >
        <span v-if="!submitLoading">Cancel Demo</span>
      </FormulateInput>
    </div>

    <!-- Cancel Subscription Modal -->
    <Modal
      ref="cancelSubscriptionModal"
      size="3xl"
      :height="paymentModalHeight"
    >
      <div class="flex flex-col items-center mx-4 my-4">
        <div v-if="!modalLoading" class="text-center mt-2 w-full">
          <div class="flex flex-col justify-center py-4 items-center space-y-4">
            <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"
              class="my-auto text-5xl text-orange-100"
            />
            <div class="font-semibold text-xl text-neutral-dark">
              Are you sure you want to cancel your subscription?
            </div>
            <div class="text-base text-neutral-main text-center">
              Once you confirm the cancellation of your subscription, Your
              account will automatically be deactivated when your subscription
              ends.
            </div>
            <!-- <div class="p-5 mt-5 mb-10 border rounded-lg group" v-if="showSummary">
              <div class="group-title pb-5 font-bold text-lg">Estimated Bill Summary</div>
              <div>
                <div class="py-2 mx-auto mt-5 w-full w-full">
                  <div class="flex justify-between mb-3">
                    <div class="text-gray-800 text-left flex-1">Total incl. GST</div>
                    <div class="text-right w-40">
                      <div class="text-gray-800 font-medium" x-html="netTotal">${{ totals.totalGST.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="flex justify-between mb-4">
                    <div class="text-sm text-gray-600 text-left flex-1">GST(10%) incl. in Total</div>
                    <div class="text-right w-40">
                      <div class="text-sm text-gray-600" x-html="totalGST">${{ totals.gst.toFixed(2) }}</div>
                    </div>
                  </div>
                  <div class="flex justify-between mb-4">
                    <div class="text-sm text-gray-600 text-left flex-1">Gateway Fee({{ paymentGateway.surchargePercentageFee }}% + ${{ paymentGateway.otherFees }})</div>
                    <div class="text-right w-40">
                      <div class="text-sm text-gray-600" x-html="totalGST">{{ totals.gatewayFee.toFixed(2) }}</div>
                    </div>
                  </div>
                
                  <div class="py-2 border-t border-b">
                    <div class="flex justify-between">
                      <div class="text-xl text-gray-600 text-left flex-1">Amount due</div>
                      <div class="text-right w-40">
                        <div class="text-xl text-gray-800 font-bold" x-html="netTotal">${{ totals.amountDue.toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
            <div
              class="flex justify-center items-center space-x-4 lg:w-1/3 w-1/2"
            >
              <FormulateInput
                type="button"
                class="w-full"
                input-class="btn btn-primary w-full text-sm md:text-base"
                :disabled="submitLoading"
                @click.prevent="closeCancelModal"
              >
                Go Back
              </FormulateInput>
              <FormulateInput
                type="button"
                class="w-full"
                input-class="btn btn-outline-error w-full text-sm md:text-base"
                :disabled="submitLoading"
                @click.prevent="cancelSubscription"
              >
                <span v-if="submitLoading"
                  ><font-awesome-icon icon="spinner" class="mr-1 loader"
                /></span>
                <span v-if="!submitLoading">Confirm</span>
              </FormulateInput>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex space-x-2 items-center justify-center text-center mt-2"
        >
          <ui-spinner class=""></ui-spinner>
          <div class="text-neutral-dark">Loading</div>
        </div>
      </div>
    </Modal>

    <!-- Cancel Demo Modal Form -->
    <Modal ref="cancelDemoFormModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div v-if="!modalLoading" class="text-center mt-2 w-full">
          <div class="flex flex-col justify-center py-4 items-center space-y-4">
            <font-awesome-icon
              :icon="['fas', 'exclamation-triangle']"
              class="my-auto text-5xl text-orange-100"
            />
            <div class="font-semibold text-xl text-neutral-dark">
              Are you sure you want to cancel the demo and contract?
            </div>
            <div class="text-base text-neutral-main text-center">
              Once you confirm the cancellation of your contract, Your account
              will automatically be deactivated.
            </div>
            <div
              class="flex justify-center items-center space-x-4 lg:w-1/3 w-1/2"
            >
              <FormulateInput
                type="button"
                class="w-full"
                input-class="btn btn-primary w-full text-sm md:text-base"
                :disabled="submitLoading"
                @click.prevent="closeCancelModal"
              >
                Go Back
              </FormulateInput>
              <FormulateInput
                type="button"
                class="w-full"
                input-class="btn btn-outline-error w-full text-sm md:text-base"
                :disabled="submitLoading"
                @click.prevent="cancelDemoAndContract"
              >
                <span v-if="submitLoading"
                  ><font-awesome-icon icon="spinner" class="mr-1 loader"
                /></span>
                <span v-if="!submitLoading">Confirm</span>
              </FormulateInput>
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex space-x-2 items-center justify-center text-center mt-2"
        >
          <ui-spinner class=""></ui-spinner>
          <div class="text-neutral-dark">Loading</div>
        </div>
      </div>
    </Modal>

    <!-- Update Card Details Form -->
    <Modal ref="updatePaymentFormModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12"
        >
          <font-awesome-icon
            icon="credit-card"
            class="text-4xl text-neutral-dark"
          />
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-xl leading-6 font-semibold text-neutral-dark"
            id="modal-title"
          >
            Update Card Details
          </h3>
        </div>
        <div v-if="!modalLoading" class="text-center mt-2 w-full">
          <FormulateForm v-model="values" @submit="onSubmit" class="text-left">
            <div class="text-md mb-2 text-gray-600 text-left flex-1">
              Card Details
            </div>
            <stripe-element-card
              class="mb-5"
              ref="cardRef"
              :pk="publishableKey"
              @token="tokenCreated"
              @element-change="updateSubmitButton"
              @element-blur="cardChange"
              :hidePostalCode="true"
              @error="onCardError"
            />
            <FormulateInput
              type="submit"
              input-class="btn btn-primary w-full"
              :disabled="!cardCheck"
            >
              <span v-if="submitLoading"
                ><font-awesome-icon icon="spinner" class="mr-1 loader" />{{
                  buttonText
                }}</span
              >
              <span v-if="!submitLoading">Update</span>
            </FormulateInput>
          </FormulateForm>
        </div>
        <div
          v-else
          class="flex space-x-2 items-center justify-center text-center mt-2"
        >
          <ui-spinner class=""></ui-spinner>
          <div class="text-neutral-dark">Loading</div>
        </div>
      </div>
    </Modal>

    <!-- Payment Processing Modal -->
    <Modal ref="processingPaymentModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 sm:mx-0 sm:h-12 sm:w-12"
        >
          <ui-spinner class="mx-auto"></ui-spinner>
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Processing your payment
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            Please wait while we process your payment request.
          </p>
        </div>
      </div>
    </Modal>
    <!-- Payment Successful Modal -->
    <Modal ref="paymentSuccessfulModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div
          class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-12 sm:w-12"
        >
          <font-awesome-icon icon="check" class="h-6 w-6 text-green-800" />
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Payment successful
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            We have received your payment successfully. Please wait as we
            redirect you back to the main page.
          </p>
        </div>
      </div>
    </Modal>
    <!-- Payment Successful Modal -->
    <Modal ref="paymentFailedModal" size="3xl" :height="paymentModalHeight">
      <div class="flex flex-col items-center mx-4 my-4">
        <div class="flex flex-col justify-center py-4 items-center space-y-4">
          <font-awesome-icon
            icon="times-circle"
            class="my-auto text-5xl text-red-500"
          />
        </div>
        <div class="text-center mt-4 w-full">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-title"
          >
            Payment failed
          </h3>
        </div>
        <div class="text-center mt-2">
          <p class="text-sm text-gray-500">
            We can't process your payment at the moment.
          </p>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal } from "@/components/commons";
import { StripeElementCard } from "@vue-stripe/vue-stripe";
// import { formatDate } from '../../../_helper';
import moment from "moment";
export default {
  components: {
    StripeElementCard,
    Modal,
  },
  props: {
    activeSolo: {
      type: Object,
      default: () => {},
    },
    isDemoPopup: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      clonedActiveSolo: {},
      contractDetails: {},
      paymentMethodDetails: {},
      submitLoading: false,
      paymentModalHeight: "20vh",
      values: {},
      publishableKey: null,
      paymentGatewayId: null,
      paymentGateway: {},
      token: null,
      cardCheck: false,
      buttonText: "Saving data...",
      modalLoading: false,
      errorMessage: "",
      loading: true,
      paymentDetailsIcon: "credit-card",
      totals: {
        subTotal: 0,
        totalGST: 0,
        gst: 0,
        gatewayFee: 0,
        amountDue: 0,
      },
      showSummary: false,
    };
  },

  watch: {
    async activeSolo() {
      this.contractDetails = await { ...this.activeSolo };
    },
  },

  async created() {
    this.loading = true;
    this.contractDetails = await { ...this.activeSolo };
    await this.getPaymentMethod();
    this.loading = false;
  },

  methods: {
    updateSubmitButton() {
      this.submitLoading = true;
      this.cardCheck = false;
      this.buttonText = "Validating Card Details...";
    },
    cardChange() {
      this.$refs.cardRef.submit();
    },
    tokenCreated(token) {
      this.token = token.id;
      if (this.token) {
        this.submitLoading = false;
        this.cardCheck = true;
        this.buttonText = "Saving data...";
      }
    },
    closeCancelModal() {
      this.$refs.cancelSubscriptionModal.closeModal();
      this.$refs.cancelDemoFormModal.closeModal();
    },
    async retryPayment() {
      this.submitLoading = true;
      this.$refs.processingPaymentModal.toggleModal();
      this.$store
        .dispatch("payment/chargeCard", {
          entityId: this.clonedPaymentMethod.entityId,
          entityRole: this.paymentMethod.entityRole,
          amount: this.amount,
        })
        .then((response) => {
          if (response) {
            this.$refs.processingPaymentModal.closeModal();
            this.$refs.paymentSuccessfulModal.toggleModal();
            setTimeout(() => {
              this.$refs.paymentSuccessfulModal.closeModal();
              this.$refs.updatePaymentFormModal.closeModal();
              this.submitLoading = false;
              this.cardCheck = false;
              this.$emit("closeModal");
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Payment charged successfully.",
              });
              setTimeout(() => {
                location.reload();
              }, 4000);
            }, 2500);
          }
        })
        .catch((e) => {
          console.log(e);
          this.$refs.processingPaymentModal.closeModal();
          this.$refs.paymentFailedModal.toggleModal();
          this.submitLoading = false;
          setTimeout(() => {
            this.$refs.paymentFailedModal.closeModal();
          }, 2000);
        });
    },

    async getPaymentMethod() {
      await this.$store
        .dispatch(
          "payment/getPaymentMethodByEntityId",
          this.contractDetails.businessMongoId
        )
        .then((response) => {
          if (response) {
            this.paymentMethodDetails = response;
            if (
              this.paymentMethodDetails.paymentName.toUpperCase() ==
              "MASTERCARD"
            ) {
              this.paymentDetailsIcon = ["fab", "cc-mastercard"];
            } else if (
              this.paymentMethodDetails.paymentName.toUpperCase() == "VISA"
            ) {
              this.paymentDetailsIcon = ["fab", "cc-visa"];
            } else {
              this.paymentDetailsIcon = "credit-card";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async openUpdatePaymentForm() {
      this.modalLoading = true;
      this.$refs.updatePaymentFormModal.toggleModal();
      await this.$store
        .dispatch(
          "payment/getActivePaymentGatewayByEntityId",
          this.contractDetails.businessMetaData.entityId
        )
        .then((response) => {
          if (response) {
            this.paymentGatewayId = response[0]._id;
            this.publishableKey = response[0].apiKey;
            this.paymentGateway = response[0];
          }
          this.modalLoading = false;
        })
        .catch((error) => {
          this.errorMessage = error;
          this.modalLoading = false;
        });
    },

    async openCancelDemoModal() {
      this.modalLoading = true;
      this.$refs.cancelDemoFormModal.toggleModal();
      this.modalLoading = false;
    },

    async openCancelSubscriptionModal() {
      if (this.contractDetails.contractDuration.name == "Yearly") {
        let startDate = moment(this.contractDetails.startDate).format(
          "MM-DD-YYYY"
        );
        let suspensionDate = moment(startDate).add(1, "years");

        let lastPaymentDate = moment(
          this.contractDetails.lastPaymentDate
        ).format("MM-DD-YYYY");

        let dateDiff = moment(suspensionDate).diff(lastPaymentDate, "months");

        dateDiff -= 1;

        if (this.contractDetails.billingPeriod.name == "Monthly") {
          let amountToPay =
            this.contractDetails.businessModules[0].price.monthly * dateDiff;

          await this.$store
            .dispatch(
              "payment/getActivePaymentGatewayByEntityId",
              this.contractDetails.businessMetaData.entityId
            )
            .then((response) => {
              if (response) {
                this.paymentGatewayId = response[0]._id;
                this.publishableKey = response[0].apiKey;
                this.paymentGateway = response[0];
              }
              this.modalLoading = false;
            })
            .catch((error) => {
              this.errorMessage = error;
              this.modalLoading = false;
            });

          const gst =
            (amountToPay / 100) * this.paymentGateway.gstPercentageFee;
          const totalGST = amountToPay + gst;
          const gatewayFee =
            (amountToPay / 100) * this.paymentGateway.surchargePercentageFee +
            this.paymentGateway.otherFees;
          const amountDue = totalGST + gatewayFee;

          this.totals = {
            subTotal: amountToPay,
            totalGST: totalGST,
            gst: gst,
            gatewayFee: gatewayFee,
            amountDue: amountDue,
          };
          this.showSummary = true;
        }
      }
      this.modalLoading = true;
      this.$refs.cancelSubscriptionModal.toggleModal();
      this.modalLoading = false;
    },

    async cancelSubscription() {
      this.submitLoading = true;

      await this.$store
        .dispatch("contract/cancelSubscription", {
          entityId: this.contractDetails.businessMongoId,
        })
        .then((response) => {
          if (response) {
            this.$emit("closeModal");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Subscription cancelled successfully.",
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        });
    },

    async cancelDemoAndContract() {
      this.submitLoading = true;

      await this.$store
        .dispatch("contract/cancelDemoAndContract", {
          entityId: this.contractDetails.businessMongoId,
        })
        .then((response) => {
          if (response) {
            this.$emit("closeModal");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Demo and Contract cancelled successfully.",
            });
            setTimeout(() => {
              location.reload();
            }, 3000);
          }
        });
    },

    async onSubmit() {
      this.submitLoading = true;
      this.buttonText = "Saving data...";
      const payload = {
        entityId: this.contractDetails.businessMongoId,
        entityRole:
          this.contractDetails.contractType == "business"
            ? "business"
            : "driver",
        paymentGatewayId: this.paymentGatewayId,
        cardToken: this.token,
        isDefault: true,
        isActive: true,
      };
      await this.$store
        .dispatch("payment/createCardPaymentMethod", payload)
        .then((response) => {
          if (response) {
            setTimeout(() => {
              this.$refs.paymentSuccessfulModal.closeModal();
              this.$refs.updatePaymentFormModal.closeModal();
              this.submitLoading = false;
              this.cardCheck = false;
              this.$emit("closeModal");
              this.$store.commit("setDialogNotify", true);
              this.$store.commit("setMessageNotify", {
                state: "success",
                message: "Payment method updated successfully.",
              });
              setTimeout(() => {
                location.reload();
              }, 4000);
            }, 2500);
          }
        })
        .catch((error) => {
          this.submitLoading = false;
          this.cardCheck = false;
          this.errorMessage = error?.message;
        });
    },

    async onCardError() {
      this.cardCheck = false;
      this.buttonText = "Saving data...";
      this.submitLoading = false;
    },

    convertedDate(currentDate) {
      return moment(currentDate).format("DD/MM/YYYY");
    },

    totalCost(contractDetails) {
      if (contractDetails.billingPeriod.name == "Yearly") {
        return (
          "$" +
          parseFloat(contractDetails.businessModules[0].price.yearly).toFixed(2)
        );
      } else if (contractDetails.billingPeriod.name == "Monthly") {
        return (
          "$" +
          parseFloat(contractDetails.businessModules[0].price.monthly).toFixed(
            2
          )
        );
      }
    },
  },
};
</script>
