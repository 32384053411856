<template>
    <div
        :data-type="context.type"
        :class="`formulate-input-element formulate-input-element--${context.type} ${context.classes.element}`"
    >
        <list-select
          ref="selectRef"
          :options="options"
          :context="context"
          :isLoading="isLoading"
          :hasPrevPage="hasPrevPage"
          :hasNextPage="hasNextPage"
          noResultText="No business contracts found."
          @prevPage="onPrevPage"
          @nextPage="onNextPage"
          @search="onSearch"
        >
            <template slot="option" slot-scope="option">
                <div class="text-sm">
                    <h3 class="m-0 font-semibold">{{ option.businessMetaData?.persona?.businessName }}</h3>
                    <em class="text-xs">{{ option.businessMetaData?.persona?.contactEmail }}</em>
                </div>
            </template>
            <template slot="selected-option" slot-scope="option">
                <div class="selected text-sm">
                    {{ option.businessMetaData?.persona?.businessName }}
                </div>
            </template>
        </list-select>
    </div>
</template>
<script>
import { debounce } from 'lodash'
import { formatPaginationSettings } from '@/_helper'
import ListSelect from '@/components/commons/ui/list-select/ListSelect'

const BusinessContractSelect = {
  name: 'BusinessContractSelect',
  components: {
    ListSelect
  },
  props: {
    context: {
        type: Object,
        required: true
    }
  },
  mounted() {
        this.$refs.selectRef.onSearch();
  },
  data: () => ({
      contracts: [],
      options: [],
      isLoading: false,
      hasPrevPage: false,
      hasNextPage: false,
      filter: {
          limit: 10,
          status: 'all',
          search: '',
      },
      paginationSettings: {
          page: 1,
          totalPages: 5,
          totalRecords: 50,
          visiblePageItemCount: 3
      }
  }),
  methods: {
    async onSearch(search = '') {
      debounce(async () => {
        this.filter.search = search
        this.options = []
        this.isLoading = true
        const query = {
            skip: ( this.paginationSettings.page * this.filter.limit ) - this.filter.limit,
            limit: this.filter.limit,
            search: this.filter.search,
            filter: {}
        }
        query.filter.contractType = { $eq: 'business' }

        if(this.filter.status != 'all') query.filter.status = { $eq : this.filter.status }

        const contracts = await this.$store.dispatch(`contract/getAllContracts`, {query})
        if (contracts) {
            if (contracts?.metadata){
                this.paginationSettings = formatPaginationSettings(contracts?.metadata)
                this.hasNextPage = this.paginationSettings.page < this.paginationSettings.totalPages
                this.hasPrevPage = this.paginationSettings.page > 1;
            } else {
                this.paginationSettings = formatPaginationSettings()
            }
            
            this.contracts = contracts.results
            this.options = this.contracts
        }
        this.isLoading = false
      }, 500)();
    },
    onPrevPage() {
      if (this.hasPrevPage) {
          this.paginationSettings.page = this.paginationSettings.page - 1
          this.onSearch();
      }
    },
    onNextPage() {
      if (this.hasNextPage) {
          this.paginationSettings.page = this.paginationSettings.page + 1
          this.onSearch();
      }
    }
  }
}

export const VueFormulateBusinessContractSelect = (formulateInstance) => {
  formulateInstance.extend({
    components: {
      BusinessContractSelect,
    },
    library: {
      "business-contract-select": {
        classification: "select",
        component: "BusinessContractSelect",
      },
    }
  })
}
export default BusinessContractSelect
</script>