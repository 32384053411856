<template>
  <div class="w-full min-h-full flex-row account-view w-full">
    <div v-if="readForms" class="w-full min-h-full flex-row items-left">
      <header class="flex justify-between text-lg font-bold items-center pt-3">
        Contract Terms Form
      </header>
      <hr />
      <FormulateForm
        @submit="submitForm"
        v-model="activeWorkingForm"
        class="flex w-full mt-5"
      >
        <aside class="w-80 h-full border-r">
          <div class="overflow-y-auto flex flex-col border-b mb-2">
            <p class="text-sm text-gray-700 py-4 px-3">Names</p>
          </div>
          <div v-if="activeForm" class="mt-2 overflow-y-auto h-80">
            <div v-for="(datas, index) in computedForms" :key="index">
              <div v-for="(ndata, index) in datas" :key="index">
                <button
                  v-show="ndata.name"
                  :title="ndata.name"
                  :class="{ 'btn-primary': activeForm.id == ndata.id }"
                  class="text-left w-full px-1 py-2 border-b border-gray-200"
                  type="button"
                  @click="selectForm(ndata.id)"
                  :style="{ backgroundColor: theme.secondaryColor }"
                >
                  <font-awesome-icon
                    v-if="activeForm.id == ndata.id"
                    :icon="['fas', 'folder-open']"
                    class="my-auto mx-1.5"
                    size="sm"
                  >
                  </font-awesome-icon>
                  <font-awesome-icon
                    v-else
                    :icon="['fas', 'folder']"
                    class="my-auto mx-1.5"
                    size="sm"
                  >
                  </font-awesome-icon>
                  <small>{{ ndata.name }}</small>
                </button>
              </div>
            </div>
          </div>
        </aside>
        <div class="w-full flex flex-col">
          <div class="flex justify-between items-center border-b mb-2">
            <p class="text-sm text-gray-700 p-4">Form Preview</p>
          </div>
          <div class="p-4 overflow-y-scroll">
            <div class="w-full" v-if="activeForm">
              <div
                v-if="forms.filter((item) => item.id == activeForm.id)[0]"
                class="bg-white shadow-md rounded px-5 mt-4 pt-5 pb-8 mb-4"
              >
                <h6
                  class="font-medium text-center pb-3 leading-tight text-base mt-5 mb-3 text-blue-600"
                >
                  {{ activeForm.name }}
                </h6>
                <section
                  v-for="(section, index) in activeForm.sections"
                  :key="index"
                >
                  <h6
                    class="text-large font-semibold text-left leading-tight inline-block align-middle mt-1 pb-1"
                  >
                    {{ section.headline }}
                  </h6>
                  <span v-if="section.subHeadline"> - </span
                  ><small class="italic text-small inline-block pt-4"
                    >{{ section.subHeadline }}
                  </small>
                  <hr />
                  <div
                    class="mt-5"
                    v-for="(control, index) in section.controls"
                    :key="index"
                  >
                    <FieldContainer
                      :component="control"
                      :isDisplayValue="true"
                    />
                  </div>
                </section>
              </div>
            </div>
            <div
              v-else
              class="bg-white-100 border-l-4 border-orange-500 text-orange-700 p-4"
              role="alert"
            >
              <p class="font-bold">Empty</p>
              <p>Form is not found.</p>
            </div>
          </div>
        </div>
        <aside class="w-24 h-full border-l" selected-control="[object Object]">
          <div class="overflow-y-auto flex flex-col border-b mb-2">
            <p class="text-sm text-gray-700 py-4 px-3">Actions</p>
          </div>
          <div class="pl-5 pt-2" v-if="activeForm">
            <ul>
              <li
                v-if="writeForms && !activeForm.isLocked"
                class="hover:text-orange-primary py-4 pl-1 cursor-pointer"
              >
                <a
                  :href="`/business/contracts/terms-forms/builder/${activeForm.id}/edit`"
                  class="flex cursor-pointer"
                  title="Edit Form"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </a>
              </li>
              <li
                v-if="!writeForms"
                class="hover:text-orange-primary py-4 pl-1"
              >
                <button
                  class="flex opacity-20 cursor-not-allowed"
                  title="Not allowed to edit the form"
                  disabled="true"
                >
                  <font-awesome-icon
                    :icon="['fas', 'edit']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </button>
              </li>
              <li v-if="writeForms" class="hover:text-orange-primary py-4 pl-1">
                <button
                  class="flex cursor-pointer"
                  title="Clone Form"
                  @click.prevent="cloneContractTerm(activeForm.id)"
                >
                  <font-awesome-icon
                    :icon="['fas', 'clone']"
                    class="my-auto mx-1.5"
                    size="lg"
                  >
                  </font-awesome-icon>
                </button>
              </li>
              <!--               <li class="hover:text-orange-primary py-4  cursor-pointer pl-1">-->
              <!--                <button -->
              <!--                    type="submit"-->
              <!--                    title="Simulate Form" -->
              <!--                    class="flex cursor-pointer"-->
              <!--                  >-->
              <!--                    <font-awesome-icon-->
              <!--                      v-if="!formSimulating"-->
              <!--                      :icon="['fas', 'play-circle']"-->
              <!--                      class="my-auto mx-1.5"-->
              <!--                      size="lg"-->
              <!--                      >-->
              <!--                    </font-awesome-icon>-->
              <!--                    <font-awesome-icon-->
              <!--                      v-else-->
              <!--                      spin-->
              <!--                      :icon="['fas', 'cog']"-->
              <!--                      class="my-auto mx-1.5 spinning"-->
              <!--                      size="lg"-->
              <!--                      >-->
              <!--                    </font-awesome-icon>-->
              <!--                </button>-->
              <!--              </li>-->
            </ul>
          </div>
        </aside>
      </FormulateForm>
    </div>
    <div v-if="!readForms" class="w-full min-h-full text-center mt-5">
      <h1>
        Not authorized to view terms forms. Please contact your business admin.
      </h1>
    </div>
  </div>
</template>
<script>
import { formatDate } from "@/_helper";
import _ from "lodash";
import FieldContainer from "@/views/builder/containers/FieldContainer.vue";
import { mapGetters } from "vuex";

export default {
  name: "Forms",
  components: { FieldContainer },
  data() {
    return {
      forms: [],
      activeForm: null,
      BusinessId: "",
      isFormActivated: false,
      formSimulating: false,
      activeWorkingForm: null,
      resSemForm: null,
      readForms: false,
      writeForms: false,
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    await this.init();
    this.$emit("updateLoading", false);
  },
  methods: {
    async init() {
      const me = this.$store.getters[`account/me`];
      let securityRoles = me?.securityRoles;
      if (securityRoles && securityRoles.length > 0) {
        const securityAccessObj = await this.setSecurityAccess(securityRoles);
        this.readForms = securityAccessObj.readForms;
        this.writeForms = securityAccessObj.writeForms;
      }
      const forms = await this.$store.dispatch(`contract/getContractTerms`);
      if (forms) {
        const formData = this.formFormatItem(
          _.orderBy(forms, ["createdAt"], ["desc"])
        );
        this.forms = formData;
        this.activeForm = formData[0];
      }
    },
    setSecurityAccess(securityRoles) {
      let initialReadForms = false;
      let initialWriteUsers = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.forms.readForms?.value) {
          initialReadForms = securityConfig.forms.readForms.value;
        }
        if (securityConfig.forms.writeForms?.value) {
          initialWriteUsers = securityConfig.forms.writeForms.value;
        }
      });
      return {
        readForms: initialReadForms,
        writeForms: initialWriteUsers,
      };
    },
    selectForm(formId) {
      this.activeForm = this.forms.filter((item) => item.id == formId)[0];
    },
    formFormatItem(items) {
      let fItems = [];
      if (items) {
        items.map((item) => {
          const form = {
            id: item["_id"],
            isActive: item["isActive"],
            name: item["name"] || "",
            created: formatDate(item["createdAt"]) || "",
            sections: item["sections"] || "",
            parentId: item["parentId"],
            isDefault: item["isDefault"],
          };
          fItems.push(form);
        });
      }
      return fItems;
    },
    parseForms(data) {
      return data.map((formData) => [
        { id: formData.id, name: formData.name, itemType: "string" },
        { id: formData.id, isDefault: formData.isDefault, itemType: "string" },
        { id: formData.id, isActive: formData.isActive, itemType: "string" },
        { id: formData.id, sections: formData.sections, itemType: "array" },
        {
          id: formData.id,
          createdAt: formData.createdAt,
          itemType: "createdAt",
        },
        { id: formData.id, parentId: formData.parentId, itemType: "string" },
      ]);
    },
    submitForm() {},
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
    async cloneContractTerm(uid) {
      await this.$store
        .dispatch(`contract/cloneContractTerms`, uid)
        .then((res) => {
          this.forms.push(res);
          this.toast("success", `${res.name} successfully cloned!`);
        })
        .catch(() => {
          this.toast("error", "Error required fields.");
        });
      await this.init();
    },
  },
  computed: {
    computedForms() {
      return this.forms ? this.parseForms(this.forms) : [];
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
