<template>
	<div>
		<div v-show="loading" class="loading centered">
			<ui-spinner class="mx-auto"></ui-spinner>
		</div>
		<div v-show="!loading" class="mt-5 px-10">
			<div>
				<FormulateForm v-model="defaultMassConfigs" @submit="onSubmit">
					<label class="font-bold"> Steer </label>
					<FormulateInput
						label="GML"
						type="number"
						name="gmlSteer"
						step="0.01"
					/>
					<FormulateInput
						label="CML"
						type="number"
						name="cmlSteer"
						step="0.01"
					/>
					<FormulateInput
						label="HML"
						type="number"
						name="hmlSteer"
						step="0.01"
					/>

					<label class="font-bold"> Drive</label>
					<FormulateInput
						label="GML"
						type="number"
						name="gmlDrive"
						step="0.01"
					/>
					<FormulateInput
						label="CML"
						type="number"
						name="cmlDrive"
						step="0.01"
					/>
					<FormulateInput
						label="HML"
						type="number"
						name="hmlDrive"
						step="0.01"
					/>

					<div class="flex flex-row justify-between">
						<label class="font-bold"
							>Add/Remove Axle Groups ({{
								defaultMassConfigs.axleGroupArray?.length || 0
							}})
						</label>
						<div>
							<button
								class="btn btn-primary mr-1"
								@click.prevent="addRow()"
								:style="{ backgroundColor: theme.secondaryColor }"
								:disabled="defaultMassConfigs.axleGroupArray?.length > 3"
							>
								<font-awesome-icon icon="plus" />
							</button>
							<button
								class="btn btn-primary ml-1"
								@click.prevent="deleteRow()"
								:style="{ backgroundColor: theme.secondaryColor }"
								:disabled="defaultMassConfigs.axleGroupArray?.length <= 0"
							>
								<font-awesome-icon icon="minus" />
							</button>
						</div>
					</div>
					<div
						v-for="(element, index) in defaultMassConfigs.axleGroupArray"
						:key="index"
					>
						<label class="font-bold">Axle Group # {{ index + 1 }}</label>
						<FormulateInput
							label="GML"
							type="number"
							:name="`gmlAxleGroup${index + 1}`"
							step="0.01"
							required
						/>
						<FormulateInput
							label="CML"
							type="number"
							:name="`cmlAxleGroup${index + 1}`"
							step="0.01"
							required
						/>
						<FormulateInput
							label="HML"
							type="number"
							:name="`hmlAxleGroup${index + 1}`"
							step="0.01"
							required
						/>
					</div>
					<button
						type="submit"
						class="btn btn-primary w-full mt-10"
						:style="{ backgroundColor: theme.secondaryColor }"
					>
						Submit
					</button>
				</FormulateForm>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "Configure-Vehicle-Mass",
	components: {},
	props: {
		vehicleId: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			loading: false,
			defaultMassConfigs: {},
		};
	},
	async created() {
		this.loading = true;
		try {
			const vehicle = await this.$store.dispatch(`vehicle/getVehicle`, {
				id: this.vehicleId,
			});
			// Try getting custom mass configs from vehicle, otherwise get default values from vehicle type.
			this.defaultMassConfigs = vehicle?.massConfigs
				? this.parseMassConfigs(vehicle.massConfigs)
				: this.parseMassConfigs(vehicle?.vehicleType?.defaultMassConfigs || {});
		} catch (error) {
			this.$emit("toast", "error", "Something went wrong");
		}
		this.loading = false;
	},
	methods: {
		async onSubmit() {
			this.loading = true;
			try {
				const parsedPayload = this.parsedMassConfigsForSubmit(
					this.defaultMassConfigs
				);
				await this.$store.dispatch(`vehicle/updateVehicleMassConfig`, {
					id: this.vehicleId,
					...parsedPayload,
				});
				this.$emit("toast", "success", "Vehicle mass configured");
			} catch (error) {
				this.$emit("toast", "error", "Something went wrong");
			}

			this.loading = false;
		},

		parseMassConfigs(massConfigs) {
			const { axleGroups = [], steer = {}, drive = {} } = massConfigs || {};

			const existingAxleGroupData = {};
			axleGroups.forEach((axleGroup, index) => {
				existingAxleGroupData[`gmlAxleGroup${index + 1}`] = axleGroup.gml;
				existingAxleGroupData[`cmlAxleGroup${index + 1}`] = axleGroup.cml;
				existingAxleGroupData[`hmlAxleGroup${index + 1}`] = axleGroup.hml;
			});

			return {
				axleGroupArray: Array.from({
					length: axleGroups.length,
				}),
				...existingAxleGroupData,
				gmlSteer: steer?.gml,
				cmlSteer: steer?.cml,
				hmlSteer: steer?.hml,
				gmlDrive: drive?.gml,
				cmlDrive: drive?.cml,
				hmlDrive: drive?.hml,
			};
		},

		parsedMassConfigsForSubmit(values) {
			const {
				gmlSteer,
				cmlSteer,
				hmlSteer,
				gmlDrive,
				hmlDrive,
				cmlDrive,
				axleGroupArray,
			} = values;
			const parsedAxleGroups = [];
			axleGroupArray.forEach((element, index) => {
				parsedAxleGroups.push({
					gml: values[`gmlAxleGroup${index + 1}`],
					cml: values[`cmlAxleGroup${index + 1}`],
					hml: values[`hmlAxleGroup${index + 1}`],
				});
			});
			const parsedSteer = gmlSteer &&
				cmlSteer &&
				hmlSteer && {
					gml: gmlSteer,
					cml: cmlSteer,
					hml: hmlSteer,
				};

			const parsedDrive = gmlDrive &&
				cmlDrive &&
				hmlDrive && {
					gml: gmlDrive,
					cml: cmlDrive,
					hml: hmlDrive,
				};

			return {
				steer: parsedSteer || null,
				drive: parsedDrive || null,
				axleGroups: parsedAxleGroups,
			};
		},

		addRow() {
			const currentAxleGroupArray =
				this.defaultMassConfigs.axleGroupArray || [];
			const updatedAxleGroupArray = Array.from({
				length: currentAxleGroupArray.length + 1,
			});
			this.defaultMassConfigs.axleGroupArray = updatedAxleGroupArray;
		},

		deleteRow() {
			const currentAxleGroupArray =
				this.defaultMassConfigs.axleGroupArray || [];
			const updatedAxleGroupArray = Array.from({
				length: currentAxleGroupArray.length - 1,
			});
			this.defaultMassConfigs.axleGroupArray = updatedAxleGroupArray;
		},
	},
	computed: {
		...mapGetters("theme", {
			theme: "getColorScheme",
		}),
	},
};
</script>
