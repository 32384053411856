<template>
  <div class="mb-8">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Fetching data...</span>
      </div>
    </div>

    <div class="items-center justify-between" v-else>
      <div class="grid">
        <div class="mb-4">
          <FormulateInput
            placeholder="Link Name"
            name="linkName"
            v-model="inputValue.linkName"
            label="Link Name"
            type="text"
            validation="required"
            validation-error="required"
            class="block h-12 w-full leading-tight"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            placeholder="https://logmaster.au"
            name="link"
            v-model="inputValue.link"
            label="Link"
            type="text"
            validation="required"
            validation-error="required"
            class="block h-12 w-full leading-tight"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            type="checkbox"
            name="isIncludingUid"
            label="Is Including driver UID?"
            v-model="inputValue.isIncludingUid"
            class="formulate-input"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            help="*Check this box if you want to include the UID of the driver in the link"
            element-class="formulate-input-element formulate-input-element--checkbox"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            type="checkbox"
            name="isIncludingPlate"
            label="Is Including Plate #?"
            v-model="inputValue.isIncludingPlate"
            class="formulate-input"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            help="*Check this box if you want to include the plate # of the vehicle in the link"
            element-class="formulate-input-element formulate-input-element--checkbox"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            type="checkbox"
            name="isMainMenu"
            label="Is Main Menu?"
            v-model="inputValue.isMainMenu"
            class="formulate-input"
            label-class="formulate-input-label formulate-input-label--after"
            input-class=""
            wrapper-class="formulate-input-wrapper"
            outer-class=""
            help="*Check this box if you want to the link to be shown on the main menu instead of the integration link sub-menu"
            element-class="formulate-input-element formulate-input-element--checkbox"
          />
        </div>
        <div class="mb-4">
          <FormulateInput
            type="select"
            name="requestType"
            label="Request Type"
            v-model="inputValue.httpRequestMethod"
            :options="requestTypes"
            placeholder="Select a request type"
          />
        </div>
        <div class="mb-4">
          <div
            class="mb-2 font-semibold text-sm block form-label text-neutral-dark"
          >
            <h4>Query Parameters</h4>
          </div>
          <div class="border rounded p-5">
            <template v-for="(queryParameter, index) in queryParameters">
              <div class="flex space-x-4 mb-4" :key="index">
                <FormulateInput
                  name="fieldName"
                  v-model="queryParameter.fieldName"
                  label="Field"
                  type="text"
                  validation="required"
                  validation-error="required"
                  class="h-12 leading-tight"
                />
                <FormulateInput
                  name="fieldType"
                  v-model="queryParameter.fieldType"
                  label="Type"
                  type="select"
                  :options="fieldTypes"
                  validation="required"
                  validation-error="required"
                  class="h-12 leading-tight"
                />
                <FormulateInput
                  v-if="queryParameter.fieldType === 'string'"
                  name="fieldValue"
                  v-model="queryParameter.fieldValue"
                  label="Value"
                  type="text"
                  :options="fieldTypes"
                  validation="required"
                  validation-error="required"
                  class="h-12 leading-tight"
                />
                <FormulateInput
                  v-if="queryParameter.fieldType === 'number'"
                  name="fieldValue"
                  v-model="queryParameter.fieldValue"
                  label="Value"
                  type="text"
                  validation="required|number"
                  validation-error="required"
                  class="h-12 leading-tight"
                  min="0"
                />
                <div
                  v-if="queryParameter.fieldType === 'dateTime'"
                  class="flex space-x-2"
                >
                  <FormulateInput
                    name="dateValue"
                    v-model="queryParameter.dateValue"
                    label="Date"
                    type="date"
                    validation="required"
                    validation-error="required"
                    class="h-12 leading-tight"
                    min="0"
                  />
                  <FormulateInput
                    name="timeValue"
                    v-model="queryParameter.timeValue"
                    label="Time"
                    type="time"
                    validation="required"
                    validation-error="required"
                    class="h-12 leading-tight"
                    min="0"
                  />
                </div>
                <div class="flex items-end mb-1">
                  <button
                    class="bg-red-400 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                    @click.prevent="removeRow(index)"
                  >
                    <font-awesome-icon icon="minus" class="my-auto" />
                  </button>
                </div>
              </div>
            </template>
            <div class="flex w-full justify-start mt-4">
              <button
                class="bg-gray-400 hover:bg-gray-700 text-white font-bold py-1 px-2 rounded-full"
                @click.prevent="addParameter()"
              >
                <font-awesome-icon icon="plus" class="my-auto" />
              </button>
            </div>
          </div>
        </div>
      </div>
      <FormulateInput
        type="submit"
        label="Submit"
        @click.prevent="onSubmit()"
        input-class="btn btn-primary w-full"
        :disabled="formLoading"
        :style="{ backgroundColor: theme.secondaryColor }"
      />
    </div>
  </div>
</template>
<script>
// import moment from 'moment';
import { mapGetters } from "vuex";
export default {
  name: "New-API-Integration-Link-Form",
  props: {
    msg: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    entityId: {
      type: String,
      default: "",
    },
    linkTarget: {
      type: String,
      default: "business",
    },
    linkType: {
      type: String,
      default: "integration-links",
    },
  },
  data() {
    return {
      loading: false,
      inputValue: {},
      requestTypes: {
        post: "POST",
        get: "GET",
      },
      queryParameters: [
        {
          fieldName: "",
          fieldType: "string",
          fieldValue: "",
        },
      ],
      fieldTypes: {
        string: "String",
        number: "Number",
        dateTime: "DateTime",
        driverId: "Driver ID",
        driverUDI: "Driver UDI",
        driverUID: "Driver UID",
        driverEmail: "Driver Email",
        currentTimeStamp: "Current Timestamp",
      },
      formLoading: false,
    };
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {},
  methods: {
    addParameter() {
      this.queryParameters.push({
        fieldName: "",
        fieldType: "string",
        fieldValue: "",
      });
    },
    removeRow(index) {
      this.queryParameters.splice(index, 1);
    },
    async onSubmit() {
      this.formLoading = true;
      let finalParameters = {};
      this.queryParameters.map((param) => {
        if (param.fieldType === "string") {
          finalParameters[param.fieldName] = param.fieldValue;
        } else if (param.fieldType === "number") {
          finalParameters[param.fieldName] = Number(param.fieldValue);
        } else if (param.fieldType === "dateTime") {
          finalParameters[param.fieldName] = new Date(
            `${param.dateValue} ${param.timeValue}`
          ).toISOString();
        } else if (param.fieldType === "driverId") {
          finalParameters[param.fieldName] = "driverId";
        } else if (param.fieldType === "driverUDI") {
          finalParameters[param.fieldName] = "driverUDI";
        } else if (param.fieldType === "driverUID") {
          finalParameters[param.fieldName] = "driverUID";
        } else if (param.fieldType === "driverEmail") {
          finalParameters[param.fieldName] = "driverEmail";
        } else if (param.fieldType === "currentTimeStamp") {
          finalParameters[param.fieldName] = "currentTimeStamp";
        }
      });
      this.inputValue.queryParameters = finalParameters;

      this.me = this.$store.getters[`account/me`];
      const formData = { ...this.inputValue };
      formData.partnerId = this.me.entity._id;
      formData.businessMongoId = this.entityId;
      formData.linkTarget = this.linkTarget;
      formData.linkType = this.linkType;

      await this.$store
        .dispatch(`dynamicLink/createBusinessIntegrationLink`, formData)
        .then((response) => {
          if (response.success == true) {
            this.$emit("closeModal");
            this.$emit("submitSuccess");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Integration Link successfully created.",
            });
            location.reload();
          }
        })
        .catch((err) => {
          this.errorMessage = err;
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
};
</script>
