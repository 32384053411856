<template>
  <div>
    <div v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-else>
      <FormulateForm
        v-model="payload"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          type="driver-select"
          label="Assigned Driver"
          style="margin-bottom: 0px"
          class="content-center pb-4"
          :getOptionKey="(option) => option.driverId"
          :getOptionLabel="(option) => option.driver.driverName"
          :filterable="false"
          :clearable="false"
          @input="selectDriver($event)"
        />
        <FormulateInput
          type="vehicle-select"
          label="Drive Vehicle"
          style="margin-bottom: 0px"
          class="content-center pb-4"
          :getOptionKey="(option) => option._id"
          :getOptionLabel="(option) => option.vehiclePlate"
          :filterable="false"
          :clearable="false"
          :isDriveVehicle="true"
          @input="selectDriveVehicle($event)"
        />
        <div class="flex flex-row justify-between mt-5">
          <label class="font-bold"
            >Add/Remove Trailer Vehicles ({{
              payload.numberOfTrailerVehicles
            }})</label
          >
          <div>
            <button
              class="mr-1 btn btn-primary"
              @click.prevent="addRow()"
              :style="{ backgroundColor: theme.secondaryColor }"
              :disabled="payload.numberOfTrailerVehicles > 7"
            >
              <font-awesome-icon icon="plus" />
            </button>
            <button
              class="ml-1 btn btn-primary"
              @click.prevent="deleteRow()"
              :style="{ backgroundColor: theme.secondaryColor }"
              :disabled="payload.numberOfTrailerVehicles <= 0"
            >
              <font-awesome-icon icon="minus" />
            </button>
          </div>
        </div>
        <div
          v-for="(element, index) in Array.from({
            length: payload.numberOfTrailerVehicles,
          })"
          :key="index"
        >
          <FormulateInput
            class="text-s"
            type="text"
            :label="`Trailer Vehicle #${index + 1}`"
            required
            :name="`trailer_vehicle:${index}`"
          />
          <div class="grid grid-cols-4">
            <FormulateInput
              v-for="(element2, index2) in Array.from({
                length: 4,
              })"
              :key="index2"
              class="text-s"
              type="number"
              step="any"
              min="0"
              :label="`Trailer Axle#${index2 + 1}`"
              :name="`trailer_axle:${index}:${index2}`"
            />
          </div>
        </div>
        <FormulateInput
          v-model="payload.loadTime"
          class="text-s"
          type="datetime-local"
          label="Pickup Time"
          required
          name="loadTime"
        />
        <FormulateInput
          v-model="payload.location"
          class="text-s"
          type="text"
          label="Pickup"
          required
          name="location"
        />
        <FormulateInput
          v-model="payload.destinationDate"
          class="text-s"
          type="datetime-local"
          label="Destination Time"
          required
          name="destinationDate"
        />
        <FormulateInput
          v-model="payload.destination"
          class="text-s"
          type="text"
          label="Destination"
          required
          name="destination"
        />
        <FormulateInput
          v-model="payload.massLimit"
          class="text-s"
          type="select"
          label="Mass Limit"
          :options="massLimitOptions"
          required
          name="massLimit"
        />
        <FormulateInput
          v-model="payload.loadDescription"
          class="text-s"
          type="text"
          label="Material"
          name="loadDescription"
        />
        <FormulateInput
          v-model="payload.weightSelector"
          class="text-s"
          type="select"
          label="Weight Unit (Optional)"
          :options="weightUnitOptions"
          name="weightSelector"
        />
        <FormulateInput
          v-model="payload.steerWeight"
          class="text-s"
          type="number"
          step="any"
          label="Steer Weight (Optional)"
          name="steerWeight"
          min="0"
        />
        <FormulateInput
          v-model="payload.driveWeight"
          class="text-s"
          type="number"
          step="any"
          label="Drive Weight (Optional)"
          name="driveWeight"
          min="0"
        />
        <FormulateInput
          v-model="payload.grossWeight"
          class="text-s"
          type="number"
          step="any"
          label="Gross Weight (Optional)"
          name="grossWeight"
          min="0"
        />
        <FormulateInput
          v-model="payload.netWeight"
          class="text-s"
          type="number"
          step="any"
          label="Net Weight (Optional)"
          name="netWeight"
          min="0"
        />
        <FormulateInput
          v-model="payload.docketNumber"
          class="text-s"
          type="text"
          label="Docket Number"
          name="docketNumber"
        />
        <FormulateInput
          class="text-s"
          type="submit"
          label="Submit"
          input-class="w-full btn btn-primary"
          :disabled="hasErrors || isLoading"
        />
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "add-driver-trips",
  components: {},
  data() {
    return {
      me: null,
      isLoading: false,
      payload: {
        numberOfTrailerVehicles: 0,
      },
      massLimitOptions: [
        {
          label: "Concessional Mass Limits (CML)",
          value: "cml",
        },
        {
          label: "General Mass Limits (GML)",
          value: "gml",
        },
        {
          label: "Higher Mass Limits (HML)",
          value: "hml",
        },
      ],
      weightUnitOptions: [
        {
          label: "Kilograms",
          value: "kg",
        },
        {
          label: "Tonnes",
          value: "tonnes",
        },
      ],
    };
  },
  created() {
    this.me = this.$store.getters["account/me"];
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const fields = Object.keys(this.payload);
      const trailerVehicles = fields.filter((field) =>
        field.includes("trailer_vehicle")
      );

      const trailerAxles = fields.filter((field) =>
        field.includes("trailer_axle")
      );

      const trailerPayload = [];
      for (const trailerVehicle of trailerVehicles) {
        const index = trailerVehicle.split(":")[1];
        trailerPayload[index] = {
          axles: [],
          trailerPlate: this.payload[trailerVehicle],
        };
        const targetTrailerAxles = trailerAxles.filter((axle) =>
          axle.includes(`trailer_axle:${index}:`)
        );
        for (const trailerAxle of targetTrailerAxles) {
          if (this.payload[trailerAxle]) {
            const splitFields = trailerAxle.split(":");
            const trailerIndex = splitFields[1];
            const axleIndex = splitFields[2];
            if (trailerPayload[trailerIndex]?.axles) {
              trailerPayload[trailerIndex].axles[axleIndex] =
                this.payload[trailerAxle];
            } else {
              trailerPayload[trailerIndex] = {
                axles: [this.payload[trailerAxle]],
                trailerPlate: this.payload[trailerVehicle],
              };
            }
          }
        }
      }

      const payload = {
        businessId: this.me.businessId
          ? this.me.businessId
          : this.me.business?._id,
        loadTime: this.payload.loadTime,
        vehicleId: this.payload.vehicleId,
        driverId: this.payload.driverId,
        data: {
          destinationDate: this.payload.destinationDate,
          destination: this.payload.destination,
          location: this.payload.location,
          steer: Number(this.payload.steerWeight),
          drive: Number(this.payload.driveWeight),
          grossWeight: Number(this.payload.grossWeight),
          netWeight: Number(this.payload.netWeight),
          massLimit: this.payload.massLimit,
          weightSelector: this.payload.weightSelector,
          trailers: trailerPayload,
          loadDescription: this.payload.loadDescription,
        },
      };

      try {
        await this.$store.dispatch("driver/createTrip", payload);
        this.$emit("toast", "success", "Driver trip successfully created.");
      } catch (error) {
        this.$emit("toast", "error", "Something went wrong");
      }
      this.isLoading = false;
      this.$emit("closeModal");
    },
    addRow() {
      this.payload.numberOfTrailerVehicles += 1;
    },

    deleteRow() {
      this.payload.numberOfTrailerVehicles -= 1;
    },

    selectDriver(data) {
      this.payload.driverId = data.driverId;
    },
    selectDriveVehicle(data) {
      this.payload.vehicleId = data._id;
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
