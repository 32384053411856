<template>
  <div class="h-full">
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="leadsData"
      @search-table="queryForKeywords"
      @onPageChange="onPageChange($event)"
      :isLoading="isLoading"
    >
      <template slot="tableFilters">
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="filter.limit"
              @change="filterChange"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="5">5</option>
              <option :value="10">10</option>
              <option :value="20">20</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            @input="queryForKeywords($event.target.value)"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2 text-center relative">
          <Dropdown ref="dropdown">
            <ul class="py-2 text-sm ">
              <li>
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="viewLead(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="eye" class="my-auto mr-2" /> View
                </StyledIconedLink>
              </li>
              <li v-if="entityType == 'partner' && !data.isConverted">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="convertLead(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="user-plus" class="my-auto mr-2" />
                  Convert
                </StyledIconedLink>
              </li>
              <li v-if="!data.isConverted">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="deleteLead(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="trash" class="my-auto mr-2" /> Delete
                </StyledIconedLink>
              </li>
              <li v-if="entityType == 'admin' && !allPartnerLeads">
                <StyledIconedLink
                  class="block px-4 py-2 hover:bg-gray-100 text-gray-700 cursor-pointer"
                  @click.prevent="transferLead(data.id)"
                  :iconHoverColor="theme.secondaryColor"
                >
                  <font-awesome-icon icon="arrow-right" class="my-auto mr-2" />
                  Transfer
                </StyledIconedLink>
              </li>
            </ul>
          </Dropdown>
        </td>
      </template>
      <template slot="callToActionTop">
        <div class="flex flex-row mb-1 space-x-1 sm:mb-0">
          <button
              v-if="entityType === 'partner'"
              class="btn btn-primary"
              @click.prevent="addDestinationEmail()"
              :style="{ backgroundColor: theme.secondaryColor }"
          >
            Add Destination
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="createLead()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Create Lead
          </button>
        </div>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'create'">
        <CreateLead
          @closeRefresh="closeRefresh"
          :entityType="entityType"
          :entityId="entityId"
        />
      </div>
      <div v-if="modalContent === 'transfer'">
        <TransferLead
          @closeRefresh="closeRefresh"
          :partners="partners"
          :partner="partners[0].id"
          :leadId="modalId"
        />
      </div>
      <div v-if="modalContent === 'view'">
        <LeadView @deleteLead="deleteLead" :leadId="modalId" />
      </div>
      <div v-if="modalContent === 'convert'">
        <ConvertLead @closeRefresh="closeRefresh" :leadId="modalId" />
      </div>
      <div v-if="modalContent === 'destination-box'">
        <LeadsDestinationBox @closeRefresh="closeRefresh"/>
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table, Dropdown } from "@/components/commons/";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatDate, formatPaginationSettings } from "@/_helper";
import LeadView from "@/components/view/lead";
import {
  TransferLead,
  ConvertLead,
  CreateLead,
} from "@/components/forms/lead/";
import _ from "lodash";
import { mapGetters } from "vuex";
import { StyledIconedLink } from "@/styled-components";
import LeadsDestinationBox from "@/components/forms/partner/lead-destination-box.vue";

export default {
  name: "Leads",
  components: {
    LeadsDestinationBox,
    Modal,
    Table,
    Dropdown,
    LeadView,
    CreateLead,
    TransferLead,
    ConvertLead,
    StyledIconedLink,
  },
  props: {
    allPartnerLeads: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      modalContent: false,
      modalTitle: "",
      modalId: "",
      leads: [],
      entityType: "",
      entityId: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: [
        "Name",
        "Email",
        "Type",
        "Parent Type",
        "Converted",
        "Created At",
      ],
      tableData: [],
      filter: {
        limit: 5,
        search: "",
      },
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      partners: [],
      me: [],
    };
  },
  async mounted() {
    this.init();
    const partnerList = await this.$store.dispatch(
      `partner/getPartnerOfLogmasterByUID`,
      this.me?.uid
    );
    if (partnerList)
      this.partners = this.formatUser(
        _.orderBy(partnerList, ["createdAt"], ["desc"])
      );
  },
  methods: {
    createLead: function () {
      this.modalContent = "create";
      this.modalTitle = "Create Lead";
      this.$refs.modal.openModal();
    },
    transferLead: function (id) {
      this.modalContent = "transfer";
      this.modalTitle = "Transfer Lead";
      this.$refs.modal.openModal();
      this.modalId = id;
    },
    viewLead: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "view";
      this.modalTitle = "Lead Info";
      this.modalId = id;
    },
    convertLead: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "convert";
      this.modalTitle = "Lead Convert";
      this.modalId = id;
    },
    addDestinationEmail: function (id) {
      this.$refs.modal.openModal();
      this.modalContent = "destination-box";
      this.modalTitle = "Add Destination Box";
      this.modalId = id;
    },
    deleteLead(id) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.$store
          .dispatch(`business/deleteLead`, id)
          .then(() => {
            this.init();
          })
          .catch((error) => {
            this.errorMessage = `Something went wrong please try again.`;
            if (error.message) this.errorMessage = error.message[0];
          });
      }
    },

    async init(paged = 1) {
      if (this.allPartnerLeads && !this.tableHeader.includes("Partner"))
        this.tableHeader.push("Partner");
      (this.isLoading = true), (this.me = this.$store.getters[`account/me`]);
      this.entityType = this.me.role.name;
      this.entityId = this.me.entity._id;
      const query = {
        skip: paged * this.filter.limit - this.filter.limit,
        limit: this.filter.limit,
        search: this.filter.search,
        filter: { allPartnerLeads: this.allPartnerLeads },
      };
      const leads = await this.$store.dispatch(
        `business/getBusinessLead`,
        query
      );

      if (leads.success) {
        this.paginationSettings = formatPaginationSettings(leads.resultsMeta);
        this.leads = this.formatItem(leads);
      }

      if (this.$refs['dropdown']) this.$refs.dropdown.updateTableHeight();
      this.isLoading = false;
    },
    closeRefresh() {
      this.$refs.modal.closeModal();
      this.init();
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.data.forEach((data) => {
          // let type;
          // if (data.type == "business") {
          //   type = "Business";
          // } else {
          //   type = "Driver";
          // }
          const lead = {
            abn: data.abn,
            type: data.type == "business" ? "Business" : "Driver",
            parentType: data.parentType == "admin" ? "Logmaster" : "Partner",
            parentEntityId: data.parentEntityId,
            tradingName: data.tradingName,
            businessAddress: data.businessAddress,
            businessName: data.businessName,
            contactEmail: data.contactEmail,
            contactPhoneNumber: data.contactPhoneNumber,
            contactUserName: data.contactUserName,
            isConverted: data.isConverted,
            createdAt: formatDate(data.createdAt),
            updatedAt: formatDate(data.updatedAt),
            id: data._id,
            entity: data.entity,
          };
          nItems.push(lead);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    formatUser(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const data = {
            id: item["_id"],
            name: (item["persona"] && item["persona"]["businessName"]) || "N/A",
            created: formatDate(item["createdAt"]) || "N/A",
            uid: item["uid"] || null,
          };
          nItems.push(data);
        });
      }
      return nItems;
    },
    async onPageChange(event) {
      if (event.page) {
        await this.init(event.page);
      }
    },

    async filterChange() {
      await this.init();
    },

    parseLead(data) {
      return data.map((leadData) => {
        const rowArray = [
          {
            id: leadData.id,
            name: leadData.businessName,
            itemType: "name",
            isConverted: leadData.isConverted,
          },
          {
            id: leadData.id,
            name: leadData.contactEmail,
            itemType: "string",
            isConverted: leadData.isConverted,
          },
          {
            id: leadData.id,
            name: leadData.type,
            itemType: "string",
            isConverted: leadData.isConverted,
          },
          {
            id: leadData.id,
            name: leadData.parentType,
            itemType: "string",
            isConverted: leadData.isConverted,
          },
          {
            id: leadData.id,
            name: leadData.isConverted,
            itemType: "boolean",
            isConverted: leadData.isConverted,
          },
          {
            id: leadData.id,
            name: leadData.createdAt,
            itemType: "string",
            isConverted: leadData.isConverted,
          },
        ];

        if (this.allPartnerLeads) {
          const partnerRow = {
            id: leadData.id,
            name: leadData.entity.persona.businessName,
            itemType: "string",
            isConverted: leadData.isConverted,
          };
          if (!rowArray.includes(partnerRow)) rowArray.push(partnerRow);
        }

        return rowArray;
      });
    },
  },
  computed: {
    leadsData() {
      if (this.leads) {
        let leadComputedData = this.leads;

        if (this.searchKeyword) {
          leadComputedData = leadComputedData.filter((item) =>
            this.searchKeyword
              .toLowerCase()
              .split(" ")
              .every((v) => item.businessName.trim().toLowerCase().includes(v))
          );
        }

        return this.parseLead(leadComputedData);
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style>
.loading.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
