<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
      <div
        class="text-red-500 font-bold text-sm text-center my-5"
        v-if="errorMessage"
      >
        {{ errorMessage }}
      </div>
    </div>
    <div v-else>
      <FormulateForm v-model="values" :schema="schema" @submit="onSubmit">
        <div class="items-center justify-between">
          <StyledSubmit v-if="!showFullSchema" @click="updateSchema" :backgroundColor="theme.secondaryColor" >
            <FormulateInput
                type="switch"
                label="Show All Fields"
                input-class="custom-options"
            >
              <span>Show All Fields</span>
            </FormulateInput>
          </StyledSubmit>
          <StyledSubmit :backgroundColor="theme.secondaryColor">

            <FormulateInput
              type="submit"
              label="Submit"
              input-class="btn btn-primary w-full"
              :disabled="loading"
            >
              <span v-if="loading"
                ><font-awesome-icon icon="spinner" class="mr-1 loader" />
                Submitting...</span
              >
              <span v-if="!loading">Submit</span>
            </FormulateInput>
          </StyledSubmit>
        </div>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import schema from "./new-vehicle-schema";
import _ from "lodash";
import { VEHICLE_CLASS } from "@/_helper/constants";
import { mapGetters } from "vuex";
import { StyledSubmit } from "@/styled-components";
export default {
  name: "New-Vehicle-Form",
  components: { StyledSubmit },
  props: {
    msg: String,
    detailsId: {
      type: String,
      default: "",
    },
    defaultData: null,
  },
  data() {
    return {
      loading: true,
      error: false,
      errorMessage: "",
      schema,
      entityId: "",
      values: {},
      classSchema: [],
      typeSchema: [],
      businessUID: "",
      origVehicleClass: "",
      origVehicleType: "",
      frontCouplingSchema: [],
      rearCouplingSchema: [],
      brakeTypeSchema: [],
      wiringPlugVoltageSchema: [],
      powerCouplingSchema: [],
      suspensionSchema: [],
      commentSchema: [
        {
          type: "textarea",
          name: "lAETBSysSupplier",
          label: "LSV, ABS, EBS, T-EBS Brake System Supplier (Optional)",
        },
        {
          type: "textarea",
          name: "tSTBCSRefNumber",
          label:
            "Trailer SARN, Trailer Brake Control System Reference Number (Optional)",
        },
        {
          type: "textarea",
          name: "comments",
          label: "Comments (Optional)",
        },
      ],
      showFullSchema: false,
    };
  },
  async mounted() {
    const me = this.$store.getters[`account/me`];
    this.businessUID = me.uid;
    this.entityId = me?.businessId;
    this.loading = true;
    this.renderDefaultDataOnForm();
    await Promise.all([
      this.getVehicleClasses(),
      this.getVehicleFrontCouplings(),
      this.getVehicleRearCouplings(),
      this.getPowerCouplings(),
      this.getVehicleBrakeTypes(),
      this.getVehicleBrakeFoundations(),
      this.getVehicleWiringPlugVoltages(),
      this.getSuspensions(),
    ]);
    this.shortSchema();
    this.loading = false;
  },
  methods: {
    shortSchema() {
      this.schema = [
        ...schema,
        ...this.classSchema,
        ...this.typeSchema
      ];
    },
    updateSchema() {
      this.loading = true;
      this.showFullSchema = true;
      this.schema = [
        ...schema,
        ...this.classSchema,
        ...this.typeSchema,
        ...this.frontCouplingSchema,
        ...this.rearCouplingSchema,
        ...this.powerCouplingSchema,
        ...this.brakeTypeSchema,
        ...this.brakeFoundationSchema,
        ...this.wiringPlugVoltageSchema,
        ...this.suspensionSchema,
        ...this.commentSchema,
      ];
      this.loading = false;
    },
    async onSubmit() {
      this.loading = true;
      let parsedVehicle = this.parseForSubmit(this.values);
      const proxySite = this.$store.getters[`account/proxySite`];
      if (!_.isEmpty(proxySite) && _.isObject(proxySite))
        parsedVehicle.siteId = proxySite._id;
      this.$store
        .dispatch(`vehicle/createVehicle`, parsedVehicle)
        .then((vehicle) => {
          this.loading = false;
          if (vehicle && vehicle._id) {
            this.$emit("closeRefresh");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Vehicle successfully created.",
            });
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: error.message,
          });
        });
    },
    async getVehicleTypes() {
      let typeOptions = {};
      const vehicleTypes = await this.$store.dispatch(
        `vehicle/getActiveVehicleTypeSearch`,
        { vehicleClassId: this.values.vehicleClass }
      );
      if (vehicleTypes) {
        this.types = vehicleTypes;
        _.orderBy(this.types, ["createdAt"], ["asc"]).forEach((type) => {
          typeOptions[type._id] = `${type["name"]}`;
        });
      }
      this.typeSchema = [
        {
          name: "vehicleType",
          type: "select",
          label: "Vehicle Type",
          options: {
            "": " - Select - ",
            ...typeOptions,
          },
          validation: "required",
        },
      ];
    },
    async getVehicleClasses() {
      let classOptions = {};
      const vehicleClasses = await this.$store.dispatch(
        `vehicle/getVehicleClass`
      );
      if (vehicleClasses) {
        this.rawVehicleClasses = vehicleClasses;
        //ordering should be control by the BE. This is a temporary fix to control the order for vehicle types
        let updateVehicleClasses = vehicleClasses.map((vehicleTypes) => {
          return _.assign(
            vehicleTypes,
            _.find(VEHICLE_CLASS, ["name", vehicleTypes.name])
          );
        });
        updateVehicleClasses = _.sortBy(updateVehicleClasses, ["order"]);
        updateVehicleClasses.forEach((vclass) => {
          let vclassName;
          switch (vclass["name"]) {
            case "HV":
              vclassName = "Heavy Vehicle (12t and greater)";
              break;
            case "MV":
              vclassName = "Medium Vehicle (4.5t to Less then 12t)";
              break;
            case "LV":
              vclassName = "Light Vehicle (Less then 4.5t)";
              break;
          }
          classOptions[vclass._id] = vclassName || `${vclass["name"]}`;
        });
        this.values.vehicleClass = updateVehicleClasses[0]._id;
      }
      this.classSchema = [
        {
          component: "div",
          class: "custom-options",
          children: [
            {
              type: "radio",
              name: "vehicleClass",
              label: "Vehicle Class",
              options: {
                ...classOptions,
              },
              // value: "LV",
              validation: "required",
            },
          ],
        },
      ];
      if (this.values.vehicleClass) {
        await this.getVehicleTypes();
      }
    },
    async getVehicleFrontCouplings() {
      let frontCouplingOptions = {};
      const frontCouplings = await this.$store.dispatch(
        `vehicle/getActiveFrontCouplingByUID`,
        this.businessUID
      );
      if (frontCouplings) {
        frontCouplings.forEach((vclass) => {
          frontCouplingOptions[vclass._id] = `${vclass["name"]}`;
        });
      }
      this.frontCouplingSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "checkbox",
              name: "frontCouplings",
              label: "Front Couplings (Optional)",
              options: {
                ...frontCouplingOptions,
              },
            },
          ],
        },
      ];
    },
    async getPowerCouplings() {
      let powerCouplingOptions = {};
      const powerCouplings = await this.$store.dispatch(
        `vehicle/getActiveWiringPlugConnectorByUID`,
        this.businessUID
      );
      if (powerCouplings) {
        powerCouplings.forEach((vclass) => {
          powerCouplingOptions[vclass._id] = `${vclass.name}`;
        });
      }
      this.powerCouplingSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "checkbox",
              name: "powerCouplings",
              label: "Power Couplings (Optional)",
              options: {
                ...powerCouplingOptions,
              },
            },
          ],
        },
      ];
    },
    async getVehicleRearCouplings() {
      let rearCouplingOptions = {};
      const rearCouplings = await this.$store.dispatch(
        `vehicle/getActiveRearCouplingByUID`,
        this.businessUID
      );
      if (rearCouplings) {
        rearCouplings.forEach((vclass) => {
          if (vclass.isActive) {
            rearCouplingOptions[vclass._id] = `${vclass["name"]}`;
          }
        });
      }
      this.rearCouplingSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "checkbox",
              name: "rearCouplings",
              label: "Rear Couplings (Optional)",
              options: {
                ...rearCouplingOptions,
              },
            },
          ],
        },
      ];
    },
    async getVehicleBrakeTypes() {
      let brakeTypeOptions = {};
      const brakeTypes = await this.$store.dispatch(
        `vehicle/getActiveBrakeTypeByUID`,
        this.businessUID
      );
      if (brakeTypes) {
        brakeTypes.forEach((vclass) => {
          brakeTypeOptions[vclass._id] = `${vclass["name"]}`;
        });
      }
      this.brakeTypeSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "radio",
              name: "brakeType",
              label: "Brake System Type (Optional)",
              options: {
                ...brakeTypeOptions,
              },
            },
          ],
        },
      ];
    },
    async getVehicleBrakeFoundations() {
      let brakeFoundationOptions = {};
      const brakeFoundations = await this.$store.dispatch(
        `vehicle/getActiveBrakeFoundationByUID`,
        this.businessUID
      );
      if (brakeFoundations) {
        brakeFoundations.forEach((vclass) => {
          brakeFoundationOptions[vclass._id] = `${vclass["name"]}`;
        });
      }
      this.brakeFoundationSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "radio",
              name: "brakeFoundation",
              label: "Brake Foundation (Optional)",
              options: {
                ...brakeFoundationOptions,
              },
            },
          ],
        },
      ];
    },
    async getVehicleWiringPlugVoltages() {
      let wiringPlugVoltageOptions = {};
      const wiringPlugVoltages = await this.$store.dispatch(
        `vehicle/getActiveWiringPlugVoltageByUID`,
        this.businessUID
      );
      if (wiringPlugVoltages) {
        wiringPlugVoltages.forEach((vclass) => {
          wiringPlugVoltageOptions[vclass._id] = `${vclass["name"]}`;
        });
      }
      this.wiringPlugVoltageSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "radio",
              name: "supplyVoltageToTrailer",
              label: "Supply Voltage to Trailer (Optional)",
              options: {
                ...wiringPlugVoltageOptions,
              },
            },
          ],
        },
      ];
    },
    async getSuspensions() {
      let suspensionOptions = {};
      const suspensions = await this.$store.dispatch(
        `vehicle/getActiveSuspensionByUID`,
        this.businessUID
      );
      if (suspensions) {
        suspensions.forEach((suspension) => {
          suspensionOptions[suspension._id] = `${suspension["name"]}`;
        });
      }
      this.suspensionSchema = [
        {
          component: "div",
          class: "custom-options checkbox",
          children: [
            {
              type: "checkbox",
              name: "suspensions",
              label: "Suspension (Optional)",
              options: {
                ...suspensionOptions,
              },
            },
          ],
        },
      ];
    },
    parseForSubmit(values) {
      const {
        fleetConnectionId,
        id: externalVehicleId,
        externalEntityId,
      } = this.defaultData || {};
      return {
        vehiclePlate: values.vehiclePlate,
        registrationExpiry: values.registrationExpiry,
        GMV: Number(values.gvm),
        vehicleClassId: values.vehicleClass,
        vehicleTypeId: values.vehicleType,
        driveVehicle:
          typeof values.driveVehicle === "boolean"
            ? values.driveVehicle
            : values.driveVehicle === "true",
        concreteAgitator:
          typeof values.concreteAgitator === "boolean"
            ? values.concreteAgitator
            : values.concreteAgitator === "true",
        livestockVehicle:
          typeof values.livestockVehicle === "boolean"
            ? values.livestockVehicle
            : values.livestockVehicle === "true",
        VIN: values.vin ? values.vin : undefined,
        alias: values.alias ? values.vin : undefined,
        frontCouplingIds: values.frontCouplings
          ? values.frontCouplings
          : undefined,
        rearCouplingIds: values.rearCouplings
          ? values.rearCouplings
          : undefined,
        powerCouplingIds: values.powerCouplings
          ? values.powerCouplings
          : undefined,
        brakeFoundationId: values.brakeFoundation
          ? values.brakeFoundation
          : undefined,
        brakeTypeId: values.brakeType ? values.brakeType : undefined,
        supplyVoltageToTrailerId: values.supplyVoltageToTrailer
          ? values.supplyVoltageToTrailer
          : undefined,
        lAETBSysSupplier: values.lAETBSysSupplier,
        tSTBCSRefNumber: values.tSTBCSRefNumber,
        comments: values.comments,
        suspensionIds: values.suspensions ? values.suspensions : undefined,
        fleetId: values.fleetId,
        entityId: this.entityId,
        externalEntityId,
        ...(this.defaultData
          ? { fleetMetadata: { fleetConnectionId, externalVehicleId } }
          : {}),
      };
    },
    renderDefaultDataOnForm() {
      if (!this.defaultData) return;
      const { vehiclePlate, fleetId, VIN: vin } = this.defaultData;
      this.values = {
        vehiclePlate,
        vin,
        fleetId,
      };
    },
  },
  watch: {
    "values.vehicleClass"() {
      if (this.values.vehicleClass) {
        Promise.all([this.getVehicleTypes()])
          .then(() => {
            this.shortSchema();
          })
          .catch((error) => {
            console.log("error: ", error);
          });
      }
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>