<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="contractTermsData"
    >
      <template #tableFilters>
        <div class="flex flex-row mb-1 sm:mb-0">
          <div class="relative">
            <select
              v-model="recordsPerPage"
              class="appearance-none h-full rounded-l border block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
          <div class="relative">
            <select
              v-model="selectedFilteredStatus"
              @change="selectFilteredStatus"
              class="appearance-none h-full border border-r-none rounded-r-none -backdrop-hue-rotate-15block w-full bg-white border-gray-400 text-gray-700 py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-l focus:border-r focus:bg-white focus:border-gray-500"
            >
              <option
                v-for="(filterStatus, index) in filterStatuses"
                :key="index"
                :value="index"
              >
                {{ filterStatus }}
              </option>
            </select>
            <div
              class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
            >
              <font-awesome-icon icon="caret-down" class="text-gray-400" />
            </div>
          </div>
        </div>
        <div class="block relative">
          <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            placeholder="Search"
            class="h-[38px] appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            @input="queryForKeywords($event.target.value)"
          />
        </div>
      </template>
      <template #default="{ data }">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap flex">
            <StyledIconedLink
              class="flex cursor-pointer mx-1.5"
              @click.prevent="viewContractTerm(data.id)"
              :iconHoverColor="theme.secondaryColor"
            >
              <font-awesome-icon icon="clipboard" class="my-auto" />
            </StyledIconedLink>
            <font-awesome-icon
              v-if="data.isLocked"
              icon="lock"
              class="my-auto mx-1.5 hover:text-orange-primary-primary opacity-90"
            />
          </div>
        </td>
      </template>
    </Table>

    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent === 'editWithComponents'">
        <TermsFormWithComponents
          :activeTerm="activeTerm"
          :term-type="termType"
          :editMode="true"
          @cancelEdit="viewContractTerm"
        />
      </div>
      <div v-if="modalContent === 'edit'">
        <ContractsTermsForm :activeTerm="activeTerm" :editMode="true" />
      </div>
      <div v-if="modalContent === 'view'">
        <FullContractsTermsView
          :activeTerm="activeTerm"
          @editContractTermWithComponents="editContractTermWithComponents"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
import { Modal, Table } from "@/components/commons";
import { filterRow } from "@/_helper/";
import { DEFAULT_MODAL_HEIGHT } from "@/_helper/constants";
import { formatDate } from "@/_helper";
import TermsFormWithComponents from "@/components/view/business/contracts/termsFormWithComponents";
import FullContractsTermsView from "@/components/view/business/contracts/FullTerms";
import ContractsTermsForm from "@/components/view/business/contracts/termsForm";
import { StyledIconedLink } from "@/styled-components";

export default {
  components: {
    Table,
    Modal,
    TermsFormWithComponents,
    FullContractsTermsView,
    ContractsTermsForm,
    StyledIconedLink,
  },
  data() {
    return {
      modalContent: "",
      modalTitle: "",
      modalHeight: DEFAULT_MODAL_HEIGHT,
      tableHeader: ["Name", "EWD", "Date Created"],
      contractTerms: [],
      activatedContractTerms: [],
      activeTerm: null,
      recordsPerPage: 50,
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      selectedFilteredStatus: 0,
      filterStatuses: ["All"],
      readContractTerms: false,
      writeContractTerms: false,
      termType: "",
    };
  },
  async mounted() {
    this.$emit("updateLoading", true);
    const me = this.$store.getters[`account/me`];
    let securityRoles = me?.securityRoles;
    if (securityRoles && securityRoles.length > 0) {
      const securityAccessObj = await this.setSecurityAccess(securityRoles);
      this.readContractTerms = securityAccessObj.readContractTerms;
      this.writeContractTerms = securityAccessObj.writeContractTerms;
    }
    await this.init();
  },
  methods: {
    async init() {
      const me = this.$store.getters[`account/me`];
      const contractTerms = await this.$store.dispatch(
        `contract/getContractTermsGroupByBusiness`,
        me?.uid
      );
      if (contractTerms) {
        this.contractTerms = this.formatItem(contractTerms);
        this.$emit("updateLoading", false);
      } else {
        this.$emit("updateLoading", false);
      }
    },
    editContractTerm(contractTermId) {
      this.$refs.modal.openModal();
      this.activeTerm = this.contractTerms.filter(
        (item) => item.id === contractTermId
      )[0];
      this.modalContent = "edit";
      this.modalTitle = "Edit Contract Terms";
    },
    editContractTermWithComponents(data) {
      this.$refs.modal.openModal();
      this.activeTerm = this.contractTerms.filter(
        (item) => item.id === data.contractTermId
      )[0];
      this.termType = data.type;
      this.modalContent = "editWithComponents";
      this.modalTitle = `Edit ${data.name}`;
    },
    viewContractTerm(contractTermId) {
      this.$refs.modal.openModal();
      this.activeTerm = this.contractTerms.filter(
        (item) => item.id === contractTermId
      )[0];
      this.modalContent = "view";
      this.modalTitle = "Contract Terms Details";
    },
    parsePreContractTerm(data) {
      return data.map((contractTermData) => [
        {
          id: contractTermData.id,
          name: contractTermData.name,
          itemType: "name",
          hasImage: false,
          isDefault: contractTermData.isDefault,
          isLocked: contractTermData.isLocked,
        },
        {
          id: contractTermData.id,
          name: contractTermData.ewdType.name,
          itemType: "string",
        },
        {
          id: contractTermData.id,
          name: contractTermData.date,
          itemType: "string",
        },
      ]);
    },
    selectFilteredStatus() {
      this.selectedStatus = this.filterStatuses[this.selectedFilteredStatus];
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const driver = {
            id: item["_id"],
            name: item["name"] || "",
            ewdType: item["ewdType"] || "",
            date: formatDate(item["createdAt"]) || "",
            isLocked: item["isLocked"],
            status: item["status"],
            logmasterTerm: item["logmasterTerm"] || [],
            partnerTerm: item["partnerTerm"] || [],
            resellerTerm: item["resellerTerm"] || [],
            signature: item["signature"],
          };
          nItems.push(driver);
        });
      }
      return nItems;
    },
    setSecurityAccess(securityRoles) {
      let iReadContractTerms = false;
      let iWriteContractTerms = false;
      securityRoles.forEach((securityRole) => {
        const securityConfig = securityRole.securityConfig;
        if (securityConfig.contractTerms?.readContractTerms?.value) {
          iReadContractTerms =
            securityConfig.contractTerms.readContractTerms.value;
        }
        if (securityConfig.contractTerms?.writeContractTerms?.value) {
          iWriteContractTerms =
            securityConfig.contractTerms.writeContractTerms.value;
        }
      });
      return {
        readContractTerms: iReadContractTerms,
        writeContractTerms: iWriteContractTerms,
      };
    },
    toast(state, message) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", { state, message });
    },
  },
  computed: {
    contractTermsData() {
      if (this.contractTerms) {
        let preContractTermComputedData = this.contractTerms;

        if (this.selectedFilteredStatus) {
          preContractTermComputedData = preContractTermComputedData.filter(
            (item) => this.selectedStatus === item.status
          );
        }
        if (this.searchKeyword) {
          preContractTermComputedData = preContractTermComputedData.filter(
            (item) => {
              filterRow(this.searchKeyword, item.name) ||
                filterRow(this.searchKeyword, item.date);
            }
          );
        }
        return this.parsePreContractTerm(preContractTermComputedData);
      } else {
        return [];
      }
    },
  },
};
</script>
