<template>
  <nav
    class="w-full"
    :style="{
      backgroundColor: theme.primaryColor,
      position: 'relative',
    }"
  >
    <div class="logo-container">
      <img src="@/assets/images/logo.png" alt="Logo" class="logo" />
    </div>
    <ul class="menu-list">
      <li
        v-for="mainItem in menuItems"
        :key="mainItem.category"
        class="menu-item"
      >
        <button
          @click="toggleMenu(mainItem)"
          class="menu-button"
          :style="
            activeMenu === mainItem.category ? { fontWeight: 'bold' } : {}
          "
        >
          {{ mainItem.category }}
        </button>
      </li>
    </ul>
    <div v-show="isActive(activeItem.category)" class="dropdown py-3">
      <div v-for="subItem in activeItem.subCategories" :key="subItem.name">
        <p class="sub-menu-title px-10 py-2">{{ subItem.name }}</p>
        <ul>
          <StyledLiMegaMenu
            v-for="subSubItem in subItem.items"
            :key="subSubItem.name"
            :hoverColor="theme.accentColor"
          >
            <a
              v-if="!subSubItem.isExternalLink"
              class="px-10 py-2"
              :href="subSubItem.link"
              >{{ subSubItem.name }}</a
            >
            <a
              v-else
              class="px-10 py-2"
              :href="subSubItem.link"
              target="_blank"
              >{{ subSubItem.name }}</a
            >
          </StyledLiMegaMenu>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
import businessItems from "./menuItems/top/business";
import { StyledLiMegaMenu } from "../../../styled-components";

export default {
  name: "MegaMenu",
  components: {
    StyledLiMegaMenu,
  },

  data() {
    const me = this.$store.getters["account/me"];

    // Supporting only for businesses for now.
    let menuItems = businessItems;
    if (me?.menuItems?.length > 0) {
      const fatigueModuleMenus = me.menuItems.find((item) => {
        return item.name === "Fatigue Compliance";
      });

      const kmAndTimeReportModuleMenu = me.menuItems.find((item) => {
        return item.name == "KM & Time";
      });

      const oneAPIModuleMenu = me.menuItems.find((item) => {
        return item.name === "One API";
      });

      const massModuleMenus = me.menuItems.find((item) => {
        return item.name === "Mass Management";
      });

      if (kmAndTimeReportModuleMenu) {
        const reportsMenu = menuItems.find((menu) => {
          return menu.category == "Reports";
        });

        if (reportsMenu) {
          const reportsMenuIndex = menuItems.indexOf(reportsMenu);
          const targetSubItems = menuItems[reportsMenuIndex].subCategories.find(
            (subCategory) => subCategory.name === "Other Reports"
          );

          const otherReportsMenuIndex =
            menuItems[reportsMenuIndex].subCategories.indexOf(targetSubItems);
          if (
            targetSubItems &&
            !targetSubItems.items.includes(kmAndTimeReportModuleMenu.items[0])
          ) {
            menuItems[reportsMenuIndex].subCategories[
              otherReportsMenuIndex
            ].items.push(kmAndTimeReportModuleMenu.items[0]);
          }
        }
      }

      if (fatigueModuleMenus) {
        menuItems = menuItems.concat(
          this.reformatMenuItems(fatigueModuleMenus)
        );
      }

      const isGeotabAccount = this.$store.getters["account/getIsGeotabAccount"];
      if (!isGeotabAccount && massModuleMenus) {
        menuItems = menuItems.concat(this.reformatMenuItems(massModuleMenus));
      }
      if (oneAPIModuleMenu) {
        menuItems = menuItems.concat(this.reformatMenuItems(oneAPIModuleMenu));
      }
    }
    return {
      menuItems,
      activeMenu: null,
      activeItem: {},
    };
  },
  methods: {
    toggleMenu(mainItem) {
      this.activeMenu =
        this.activeMenu === mainItem.category ? null : mainItem.category;
      this.activeItem = mainItem;
    },
    isActive(category) {
      return this.activeMenu === category;
    },
    reformatMenuItems(moduleMenus) {
      if (!moduleMenus) {
        return {};
      }
      const { name, items } = moduleMenus;
      return {
        category: name,
        subCategories: items.map((item) => {
          const { name: itemName, subitems: subItems = [], link } = item;
          if (!subItems.length) {
            subItems.push({
              name: itemName,
              link,
            });
          }
          return {
            name: itemName,
            items: subItems,
          };
        }),
      };
    },
  },
  computed: {
    ...mapGetters({
      theme: "theme/getColorScheme",
    }),
  },
};
</script>

<style scoped>
.logo-container {
  padding: 10px;
  margin-right: 50px;
  float: left; /* Align to the left */
}

.logo {
  height: 40px;
}
.menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: left;
}

.menu-item {
  position: relative;
  color: white;
}

.menu-button {
  background: none;
  border: none;
  padding: 18px 15px 18px 15px;
  cursor: pointer;
}

.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 60px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border: 1px solid #ddd;
  width: 100vw;
  z-index: 1000;
}

.sub-menu-title {
  border-bottom: 1px solid #eee;
  text-align: left;
  width: 100%;
  font-weight: bold;
  color: black;
}

nav:focus-within .dropdown {
  display: flex;
  flex-direction: row;
}
</style>
