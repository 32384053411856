<template>
  <div>

    <FormulateForm
        name="changeEmail"
        v-model="changeEmailForm"
        @submit="onSubmit"
    >
      <FormulateInput
          type="email"
          name="newEmailAddress"
          label="New Email Address"
          validation="^required"
          autocomplete="off"
      />
      <FormulateInput
          type="email"
          name="newEmailAddress_confirm"
          label="Confirm New Email"
          validation="required|confirm"
          autocomplete="off"
      />
      <div class="items-center mt-7">
        <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :disabled="loading"
            :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="loading"
          ><font-awesome-icon icon="spinner" class="mr-1 loader"/>
            Submitting...</span
          >
          <span v-if="!loading">Submit</span>
        </FormulateInput>
        <button
            @click.prevent="$router.go(-1)"
            class="text-center block btn btn-cancel w-full mb-2"
        >
          Cancel
        </button>
      </div>
    </FormulateForm>

  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: 'Change-Pending-Driver-Email',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      changeEmailForm: {},
      errorMessage: "",
      successMessage: "",
      loading: false,
    };
  },
  methods: {
    async onSubmit () {
      if (!this.id)
        this.errorMessage = `Something went wrong please try again.`;

      this.loading = true;

      try {

        const payLoad = {
          id: this.id,
          newEmailAddress: this.changeEmailForm.newEmailAddress,
          password: "validation-purpose"
        };

        let res = await this.$store.dispatch(`driver/changePendingDriverEmail`, payLoad);
        if (res.success) {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message:
                "Email changed and welcome email is successfully sent to new email.",
          });

          this.loading = false;
        }
      } catch (error) {

        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "error",
          message: error.message,
        });
        this.loading = false;

      }

      this.$emit('closeRefresh');
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
