<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div class="mb-3 text-center text-red-500" v-if="error">
      {{ errorMessage }}
    </div>
    <div v-if="!isLoading">
      <FormulateForm
        v-model="values"
        @submit="onSubmit"
        #default="{ hasErrors }"
      >
        <FormulateInput
          label="User Type"
          type="select"
          :options="userTypeOptions"
          v-model="selectedUserType"
        />
        <div v-if="selectedUserType == 'driver'">
          <FormulateInput
            type="driver-select"
            placeholder="Select a driver"
            style="margin-bottom: 0px"
            class="content-center pb-4"
            :getOptionKey="(option) => option.driverId"
            :getOptionLabel="(option) => option.driver.driverName"
            :filterable="false"
            :clearable="false"
            @input="selectDriver($event)"
          />
        </div>
        <div v-if="selectedUserType == 'user'">
          <FormulateInput
            type="web-profile-select"
            placeholder="Select a user"
            style="margin-bottom: 0px"
            class="content-center pb-4"
            :filterable="false"
            :clearable="false"
            @input="selectUserWebProfile($event)"
          />
        </div>

        <FormulateInput type="date" label="NCR Date" name="ncrDate" />
        <FormulateInput type="text" label="Description" name="description" />
        <FormulateInput
          type="textarea"
          label="Short Term Suggestion"
          name="shortTermSuggestion"
        />
        <FormulateInput
          type="textarea"
          label="Long Term Suggestion"
          name="longTermSuggestion"
        />
        <FormulateInput
          v-if="investigation._id"
          type="text"
          label="Linked Investigation"
          name="investigationId"
          v-model="investigation._id"
          disabled
        />
        <div class="w-full p-2">Sign Below</div>
        <VueSignaturePad
          id="signature"
          class="bg-gray-200 canvas"
          ref="signaturePad"
          scaleToDevicePixelRatio
        />
        <button
          class="w-full mb-2 btn btn-secondary"
          @click.prevent="clearSignature"
        >
          Clear Signature
        </button>
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="w-full btn btn-primary"
          :disabled="hasErrors || isLoading"
        />
      </FormulateForm>
    </div>
  </div>
</template>
<script>
export default {
  name: "Raise-Non-Conformance",
  components: {},
  props: {
    investigation: {
      type: Object,
      default: () => {
        return {};
      },
    },
    entityMongoId: {
      type: String,
    },
    entityRole: {
      type: String,
    },
  },
  data() {
    return {
      error: false,
      errorMessage: null,
      isLoading: false,
      values: {},
      signatureBuffer: null,
      drivers: [],
      users: [],
      selectedDriverId: null,
      selectedUserWebProfileId: null,
      userTypeOptions: [
        {
          label: "User",
          value: "user",
        },
        {
          label: "Driver",
          value: "driver",
        },
      ],
      selectedUserType: "driver",
    };
  },
  mounted() {
    if (this.investigation && this.investigation._id) {
      this.userTypeOptions = this.userTypeOptions.filter((option) => {
        return option.value != "user";
      });
    }
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.signatureBuffer = data;
      if (isEmpty) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Please provide your signature";
      }
      if (!this.selectedDriverId && !this.selectedUserWebProfileId) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Please select an auditee";
      }

      const {
        ncrDate,
        description,
        shortTermSuggestion,
        longTermSuggestion,
        investigationId,
      } = this.values;

      const payload = {
        ncrDate,
        description,
        shortTermSuggestion,
        longTermSuggestion,
        driver: null,
        auditee: null,
        investigation: null,
        auditorSignature: null,
      };

      // this.entityRole should be only business for now.
      payload[`${this.entityRole}`] = this.entityMongoId;

      if (investigationId) {
        payload.investigation = investigationId;
      }

      if (this.selectedDriverId) {
        payload.driver = this.selectedDriverId;
      }

      if (this.selectedUserWebProfileId) {
        payload.auditee = this.selectedUserWebProfileId;
      }

      const uploadedSignature = await this.uploadSignature();
      if (!this.error && uploadedSignature) {
        // createdUserDocument.document is link of where signature is stored
        payload.auditorSignature = uploadedSignature[0].data.data.document;
        try {
          await this.$store.dispatch("report/createNonConformance", payload);
          this.isLoading = false;
          this.$emit("closeModal");
          this.$emit(
            "toast",
            "success",
            "Non Conformance successfully created."
          );
        } catch (e) {
          this.isLoading = false;
          this.$emit("closeModal");
          this.$emit("toast", "error", "Opps, something went wrong.");
        }
      }
    },
    clearSignature() {
      this.$refs.signaturePad.openSignaturePad();
      this.$refs.signaturePad.clearSignature();
    },
    validateEmail: (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async uploadSignature() {
      try {
        // TODO: fetch existing signature on load
        // Use signaturePad.fromDataURL(data, {width: 100, height: 100}) where data is buffer of already saved signature
        const signaturePayload = {
          fileType: "SIGNATURE",
          userMongoId: this.entityMongoId,
          userType: this.entityRole,
          file: this.signatureBuffer,
          isBase64: true,
          mimetype: "image/png",
        };
        return this.$store.dispatch(`accreditation/uploadUserDocument`, {
          signaturePayload,
        });
      } catch (e) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Upload failed";
        return;
      }
    },
    selectDriver(data) {
      this.selectedDriverId = data.driverId;
    },
    selectUserWebProfile(data) {
      this.selectedUserWebProfileId = data.value;
    },
  },
};
</script>
