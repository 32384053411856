<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!loading">
      <div class="text-center mb-10">
        <div class="flex text-right justify-end">
          <a @click.prevent="onClickEdit()" class="flex cursor-pointer"
            ><font-awesome-icon
              icon="edit"
              class="my-auto mx-1.5 hover:text-orange text-xs"
          /></a>
          <a @click.prevent="onClickDelete()" class="flex cursor-pointer"
            ><font-awesome-icon
              icon="trash"
              class="my-auto mx-1.5 hover:text-orange text-xs"
          /></a>
        </div>
        <div class="font-bold text-gray-900 text-2xl">
          {{ contract.name }}
        </div>
        <div
          class="font-bold text-xs"
          :class="[contract.isActive ? 'text-green-500' : 'text-red-500']"
        >
          {{ contract.status }}
        </div>
      </div>
      <div class="mb-5 w-full border-b pb-1">
        <div class="text-xs font-bold">Details</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ contract.startDate }}
        </div>
        <div class="text-xs">Start Date</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ contract.expiryDate }}
        </div>
        <div class="text-xs">Expiry Date</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ contract.businessName }}
        </div>
        <div class="text-xs">Business</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ contract.resellerName }}
        </div>
        <div class="text-xs">Reseller</div>
      </div>
      <div class="mb-5">
        <div class="font-bold text-gray-900 text-base">
          {{ contract.partnerName }}
        </div>
        <div class="text-xs">Partner</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contract-Details-View",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      contract: {},
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    const contractDetails = await this.$store.dispatch(
      `contract/getContract`,
      this.detailsId
    );
    if (contractDetails) {
      this.contract = this.parseContract(contractDetails);
      this.loading = false;
    }
  },
  methods: {
    parseContract(contract) {
      return {
        businessName: contract.businessMetaData.persona.businessName,
        resellerName: contract.resellerMetaData.persona.businessName,
        partnerName: contract.partnerMetaData.persona.businessName,
        status: contract.status.name,
        startDate: contract.startDate,
        expiryDate: contract.expiryDate,
      };
    },
    onClickEdit() {
      this.$emit("editContract", this.detailsId);
    },
    onClickDelete() {
      this.$emit("deleteContract", this.detailsId);
    },
  },
};
</script>

<style></style>
