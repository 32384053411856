<template>
  <ChangeLicenceRequests/>
</template>


<script>
import ChangeLicenceRequests from "@/components/view/contract/change-licence-requests.vue";

export default {
  name: "Reseller-Change-Licence-Requests",
  components: {
    ChangeLicenceRequests
  }
}

</script>