import { BillingInvoice } from '@/api';
import _ from 'lodash';

export default {
  async getAllInvoice({ state }, data) {
    if (!state) throw `Invalid + ${state}`;
    try {
      const response = await BillingInvoice.getAllInvoice(data);
      return response;
    } catch (e) {
      console.log('error:', e);
      throw e;
    }
  },

  //Subscription Invoice
  async getAllContractInvoices({ state }, payload) {
    if(!payload.contractId) throw 'Invalid contractID' + state.me;

    const params = {
      contractId: payload.contractId
    }

    const pagination = payload.query

    try {
      const response = await BillingInvoice.getAllContractInvoices(params, pagination);
      if(response.data?.resultsMeta) {
        return {
          results: response.data.data,
          metadata: response.data.resultsMeta
        }
      } else {
        return response.data
      }
    } catch(error) {
      throw error.response.data
    }
  },

  async getContractUnpaidInvoice({ state }, contractId) {
    if(!contractId) throw 'Invalid contractID' + state.me;

    try {
      const response = await BillingInvoice.getContractUnpaidInvoice(contractId);
      return response.data.data;
    } catch(error) {
        throw error.response.data;
    }
  },

  async getInvoiceByPaymentHistory({ state }, payload) {
    if(!payload.contractId) throw 'Invalid contractID' + state.me;

    try {
      const response = await BillingInvoice.getInvoiceByPaymentHistory(payload.contractId, payload);
      return response.data.data;
    } catch(error) {
        throw error.response.data;
    }
  },

  async createBacktrackInvoice({ commit }, payload) {
    if(!payload || _.isEmpty(payload)) throw 'Invalid data Object'

    try {
      const response = await BillingInvoice.createBacktrackInvoice(payload)
      commit("setNewlyCreatedInvoice", response.data.data);

      return response.data.data;
    } catch(error) {
      throw error.response.data;
    }
  },

  async addInvoiceItemAndUpdateInvoice({ commit }, payload) {
    if(!payload || _.isEmpty(payload)) throw 'Invalid data Object'
    try {
      const response = await BillingInvoice.addInvoiceItemAndUpdateInvoice(payload)
      commit("setNewlyUpdatedInvoice", response.data.data);

      return response.data.data;
    } catch(error) {
      throw error.response.data;
    }
  },

  async unsubscribeBusinessModule({ commit }, payload) {
    if(!payload || _.isEmpty(payload)) throw 'Invalid data Object'
    try {
      const response = await BillingInvoice.unsubscribeBusinessModule(payload)
      commit("setNewlyUpdatedInvoice", response.data.data);

      return response.data;
    } catch(error) {
      throw error.response.data;
    }
  }
};
