<template>
  <div class="w-full mt-10 flex">
    <div class="flex-1 mx-10">
      <FormulateForm v-model="themeForm" @submit="updateTheme">
        <FormulateInput
          type="text"
          name="themeName"
          label="Custom Theme Name"
          validation="^required"
          placeholder="Midnight Blue"
          :validation-messages="{
            matches: 'Name is required',
          }"
        />
        <div class="flex items-center my-5">
          <div class="relative block w-12 align-middle">
            <input
              type="checkbox"
              v-model="isBackgroundColorConfigured"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 border-gray-300 appearance-none cursor-pointer"
            />
            <label
              for="toggle"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>

          <div class="text-neutral-dark font-semibold text-sm ml-2">
            {{
              isBackgroundColorConfigured
                ? "Background Color Enabled"
                : "Background Color Disabled"
            }}
          </div>
        </div>
        <!-- Primary -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Primary Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.primaryColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Secondary -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Secondary Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.secondaryColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Accent -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Accent Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.accentColor"
            model="hex"
            menuPosition="right"
          />
        </div>
        <!-- Error -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Error Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.errorColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Info -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Info Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.infoColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Success -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Success Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.successColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Warning -->
        <div class="flex justify-between mb-3">
          <div class="mr-5 text-xs">Warning Color</div>
          <!-- TODO: Create a component for color picker -->
          <Verte
            picker="square"
            v-model="colorScheme.warningColor"
            model="hex"
            menuPosition="right"
          />
        </div>

        <!-- Background -->
        <div
          v-if="isBackgroundColorConfigured"
          class="flex justify-between mb-3"
        >
          <div class="mr-5 text-xs">Background Color</div>
          <Verte
            picker="square"
            v-model="colorScheme.backgroundColor"
            model="hex"
            menuPosition="right"
            :disabled="true"
          />
        </div>
        <div>
          <button
            type="submit"
            class="btn btn-primary"
            :style="{ backgroundColor: theme.primaryColor }"
          >
            {{ hasTheme ? "Update Theme" : "Create Theme" }}
          </button>
        </div>
      </FormulateForm>
    </div>
    <div class="d-flex flex-1 mx-1">
      <!-- Alerts -->
      <div class="pb-3">
        <!-- info -->
        <div
          class="flex items-center w-full p-4 bg-white rounded-lg mb-2"
          role="alert"
        >
          <div
            class="inline-flex items-center justify-center flex-shrink-0 w-8 rounded-lg h-8"
            :style="{ backgroundColor: colorScheme.infoColor }"
          >
            <svg
              class="w-5 h-5"
              fill="#fff"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div class="ml-3 text-sm font-normal w-full">
            Our privacy policy has changed
          </div>
          <button
            type="button"
            class="float-right bg-white rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Warning -->
        <div
          class="flex items-center w-full p-4 bg-white rounded-lg mb-2"
          role="alert"
        >
          <div
            class="inline-flex items-center justify-center flex-shrink-0 w-8 rounded-lg h-8"
            :style="{
              backgroundColor: colorScheme.warningColor,
            }"
          >
            <svg
              class="w-5 h-5"
              fill="#fff"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div class="ml-3 text-sm font-normal w-full">
            Be careful changing driver settings
          </div>
          <button
            type="button"
            class="float-right bg-white rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Error -->
        <div
          class="flex items-center w-full p-4 bg-white rounded-lg mb-2"
          role="alert"
        >
          <div
            class="inline-flex items-center justify-center flex-shrink-0 w-8 rounded-lg h-8"
            :style="{
              backgroundColor: getRgbaColorStyling(
                colorScheme.errorColor,
                '0.3'
              ),
              color: getRgbaColorStyling(colorScheme.errorColor, '0.8'),
            }"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div class="ml-3 text-sm font-normal w-full">
            Something went wrong, please contact support.
          </div>
          <button
            type="button"
            class="float-right bg-white rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Success -->
        <div
          class="flex items-center w-full p-4 bg-white rounded-lg mb-2"
          role="alert"
        >
          <div
            class="inline-flex items-center justify-center flex-shrink-0 w-8 rounded-lg h-8"
            :style="{
              backgroundColor: getRgbaColorStyling(
                colorScheme.successColor,
                '0.3'
              ),
              color: getRgbaColorStyling(colorScheme.successColor, '0.8'),
            }"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
          <div class="ml-3 text-sm font-normal w-full">
            Profile updated successfully.
          </div>
          <button
            type="button"
            class="float-right bg-white rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
      </div>
      <!-- Table -->

      <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
        <Table
          :tableHeader="[
            'Full Name',
            'Email Address',
            'Licence',
            'Enabled/Disabled',
          ]"
          :data="sampleTableData"
        ></Table>
      </div>
      <!-- Buttons -->
      <div class="d-flex mt-3">
        <button
          class="btn btn-primary mx-2"
          :style="{
            backgroundColor: colorScheme.secondaryColor,
          }"
        >
          Secondary
        </button>
        <button
          class="btn btn-primary mx-2"
          :style="{
            backgroundColor: colorScheme.primaryColor,
          }"
        >
          Primary
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Verte from "verte";
import "verte/dist/verte.css";
import { mapGetters, mapMutations } from "vuex";
import { Table } from "@/components/commons/";
import { applyDynamicColorWithOpacity } from "@/_helper";

export default {
  name: "Theme-Configurator",
  components: { Verte, Table },
  data() {
    return {
      themeForm: { themeName: "", isActive: "" },
      hasTheme: false,
      isBackgroundColorConfigured: false,
      colorScheme: {
        primaryColor: "",
        secondaryColor: "",
        backgroundColor: "",
        accentColor: "",
        errorColor: "",
        infoColor: "",
        successColor: "",
        warningColor: "",
      },
    };
  },
  async mounted() {
    this.accountDetails = await this.$store.getters["account/me"];
    const themeOptions = await this.$store.dispatch(
      "theme/getUserThemes",
      this.accountDetails.uid
    );
    if (themeOptions) {
      this.hasTheme = true;
      const themeData = themeOptions[0].themeData;
      this.themeForm.themeName = themeOptions[0].themeName;
      this.colorScheme = themeData;
      this.isBackgroundColorConfigured = !!this.colorScheme.backgroundColor;
    } else {
      alert("No theme found");
      //set default logmaster colors
      this.colorScheme = {
        primaryColor: "#2972a4",
        secondaryColor: "#f17226",
        accentColor: "#78a5c5",
        errorColor: "#EF5350",
        infoColor: "#81D4FA",
        successColor: "#81C784",
        warningColor: "#FDD835",
        backgroundColor: "",
      };
    }
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
    ...mapMutations("theme", {}),
    sampleTableData() {
      return [
        [
          {
            name: "John Doe",
          },
          {
            name: "johndoe@gmail.com",
          },
          {
            name: "ABCD6789",
          },
          {
            name: "Enabled",
          },
          {
            name: "Edit",
          },
        ],
      ];
    },
  },
  methods: {
    async updateTheme() {
      this.accountDetails = this.$store.getters["account/me"];
      const { primaryColor, secondaryColor, accentColor, successColor } =
        this.colorScheme;

      const theme = {
        accountId: this.accountDetails.uid,
        themeName: this.themeForm.themeName,
        themeData: {
          ...this.colorScheme,
          ...(!this.isBackgroundColorConfigured ? { backgroundColor: "" } : {}),
        },
        isActive: true,
        themeColors: [primaryColor, secondaryColor, accentColor, successColor],
      };

      try {
        const createdOrUpdatedTheme = await this.$store.dispatch(
          "theme/createTheme",
          theme
        );

        await this.$store.dispatch("settings/updateThemeSettings", {
          accountId: this.accountDetails.uid,
          themeData: createdOrUpdatedTheme,
        });

        this.toast("success", "Theme updated successfully");
      } catch (error) {
        console.log("updateTheme error: ", JSON.stringify(error));
        this.toast("error", "Something went wrong");
      }
    },
    toast(state, msg) {
      const message = {
        state: state,
        message: msg,
      };
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
    },
    getRgbaColorStyling(color, opacity) {
      if (color) {
        return applyDynamicColorWithOpacity(color, opacity);
      }
    },
  },
};
</script>
