<template>
  <div>
    <FormulateForm v-model="values" @submit="onSubmit">      
      <div class="items-center justify-between">
        <FormulateInput
          type="text"
          name="contactUserName"
          label= 'Full Name'
          validation= 'required'
        /> 
       <FormulateInput
          type="email"
          name="emailAddress"
          label= 'Email Address'
          validation= '^required:trim|email'
          @input="handleStritEmailCase($event)"
        />
         
        <div class="border-t border-neutral-light mt-8">
          <StyledSubmit :backgroundColor="theme.secondaryColor">
              <FormulateInput
                type="submit"
                input-class="btn btn-primary w-full"
                :disabled="submitLoading"
                :style="{backgroundColor: theme.secondaryColor}"
              >
              <span v-if="submitLoading"><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving data...</span>  
              <span v-if="!submitLoading">Submit</span>  
              </FormulateInput>
          </StyledSubmit>

          <button class="btn btn-cancel w-full mb-2" @click.prevent="onPrevious()">
              Back
          </button>
        </div>
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">{{errorMessage}}</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {StyledSubmit} from '@/styled-components'
export default {
name: "Business-Account-Info",
components: {StyledSubmit}, 
props: {
  preData: {
    type: Object,
    required: false,
    default: null,
  },
  postData: {
  type: Object,
  required: false,
  default: null,
  },
},
data() {
  return {
    loading: false,
    values: {},
    errorMessage:'',
    submitLoading: false
  }
},
mounted(){
  if(this.preData?.steps){
    this.values = this.preData.steps?.accountDetails || null
    this.interChangingData = this.preData
  }
  if(this.postData?.steps){
    this.values = this.postData.steps?.accountDetails || null
    this.interChangingData = this.postData
  }
},
methods: {
  async onSubmit() {
    this.submitLoading = true
    const businessAccountInfo = JSON.parse(JSON.stringify(this.values));
    if(businessAccountInfo) {
      const businessAddress = this.interChangingData?.steps.businessDetails.location[0].busineessAddress;
      const businessData = {
        persona: {
          businessName: this.interChangingData?.steps.businessDetails.businessName || '',
          businessAddress,
          abn: this.interChangingData?.steps.businessDetails.abn || '',
          contactPhoneNumber: this.interChangingData?.steps.businessDetails.phoneNumber || '',
          contactUserName: businessAccountInfo.contactUserName,
          contactEmail : businessAccountInfo.emailAddress,
        },
        supportEmail: businessAccountInfo.emailAddress,
        isActive: true,
        id: this.interChangingData?.steps.businessDetails.id
      }

      await this.$store.dispatch(`business/updateBusinessAndAdminDetails`, {
        businessId: this.interChangingData?.steps.businessDetails.id,
        data: businessData
      }).then((response) => {
        this.$emit('closeModal')
        this.$emit('updateSuccessful', response);
        this.$store.commit('setDialogNotify',true)
        this.$store.commit('setMessageNotify',{state:'success',message:'Business details successfully updated.'})
      }).catch((error) => {
        this.errorMessage = error?.message
      }).finally(() => {
        this.submitLoading = false
      })


    }
  },
  onPrevious(){
    const businessAccountInfo = this.values ? JSON.parse(JSON.stringify(this.values)) : {}
    const postData = {...this.interChangingData, steps: {...this.interChangingData.steps, ...{accountDetails: businessAccountInfo}}}
    this.$emit('backStep', postData)
  },
  handleStritEmailCase(value){
    this.values.emailAddress = value.toLowerCase()
  }
},
 computed: {
  ...mapGetters('theme', {
  theme: 'getColorScheme'
  })
},
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@keyframes loader-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  border-right-color: transparent;
  animation: loader-rotate 2s linear infinite;
}
</style>