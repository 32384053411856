<template>
  <div
    class="shadowed absolute w-96 right-0 px-5 py-2 mt-3 bg-white"
    v-click-outside="onClickOutside"
  >
    <div
      class="w-full text-sm font-medium font-bold"
      :style="{ color: theme.primaryColor }"
    >
      Dashboard Settings
    </div>

    <div class="mb-3 flex">
      <div class="flex flex-col">
        <div class="flex items-center justify-between pt-2">
          <p>Dashboard Color Theme</p>
        </div>
        <ColorThemeSelect
          :options="colorOptions"
          :value="activeColorOptions"
          @onChange="onChangeTheme"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { ENABLED_LOGO_UPLOAD_ACCOUNT } from "@/_helper/constants";
import ColorThemeSelect from "@/components/commons/ui/select/color-select";

export default {
  name: "Dashboard-Settings",
  components: { ColorThemeSelect },
  data() {
    return {
      enableLogoUpload: false,
      accountDetails: null,
      colorOptions: [],
    };
  },
  async mounted() {
    this.accountDetails = await this.$store.getters["account/me"];
    const themeOptions = await this.$store.dispatch(
      "theme/getThemeListsById",
      this.accountDetails.uid
    );

    this.colorOptions = themeOptions;
    if (this.accountDetails.role.name) {
      this.enableLogoUpload = ENABLED_LOGO_UPLOAD_ACCOUNT.includes(
        this.accountDetails.role.name
      );
    }
  },
  methods: {
    onClickOutside() {
      this.$emit("toggleSetting");
    },
    async onChangeTheme(themeData) {
      // Update partner theme color so its reflected for its businesses and drivers
      if (this.accountDetails.role.name === "partner") {
        await this.$store.dispatch("settings/updateThemeSettings", {
          accountId: this.accountDetails.uid,
          themeData,
        });
      }
    },
  },
  computed: {
    theme() {
      return this.$store.getters["theme/getColorScheme"];
    },
    activeColorOptions() {
      return this.$store.getters["theme/getActiveTheme"];
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
