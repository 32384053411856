<template>
  <div class="mb-16">
    <div class="loading centered" v-if="loading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <FormulateForm
      v-model="values"
      :schema="schema"
      @submit="onSubmit"
      v-if="!loading"
    >
      <div class="items-center justify-between">
        <FormulateInput
          type="submit"
          label="Submit"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "../driver/stepper-component/user-created-business-profile-edit-schema";
import { mapGetters } from "vuex";

export default {
  name: "Edit-Business-Profile-Form",
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$emit("schema", schema);
    return {
      profile: {},
      loading: false,
      values: {},
      schema: schema,
      errorMessage: "",
    };
  },
  async mounted() {
    this.loading = true;
    const profileDetails = await this.$store.dispatch(
      `business/getContractById`,
      this.detailsId
    );
    if (profileDetails) {
      const profile = this.parseProfile(profileDetails.profile);
      const splitRecordKeeperAddress = profile.recordKeeperAddress.split(",");
      const splitBaseLocation = profile.baseLocation.split(", ");
      this.values = {
        profile: [
          {
            recordKeeperAddress: [
              {
                street: splitRecordKeeperAddress[0],
                city: splitRecordKeeperAddress[1],
                state: splitRecordKeeperAddress[2]
                  ? splitRecordKeeperAddress[2].trim()
                  : null,
              },
            ],
            baseLocation: [
              {
                street: splitBaseLocation[0],
                city: splitBaseLocation[1],
                state: splitBaseLocation[2]
                  ? splitBaseLocation[2].trim()
                  : null,
              },
            ],
            baseTimeZone: profile.baseTimeZone,
            BFMNumber: profile.BFMNumber,
            AFMNumber: profile.AFMNumber,
            BFMSolo: profile.BFMSolo,
            BFM2up: profile.BFM2up,
          },
        ],
      };
      this.loading = false;
    }
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const parsedProfile = this.parseForSubmit(this.values);
      const updateProfile = await this.$store.dispatch(
        `business/updateContract`,
        parsedProfile
      );
      if (updateProfile && updateProfile._id) {
        this.loading = false;
        this.$emit("closeModal");
        this.$store.commit("setDialogNotify", true);
        this.$store.commit("setMessageNotify", {
          state: "success",
          message: "Business successfully updated.",
        });
        setTimeout(() => {
          location.reload();
        }, 3000);
      }
    },
    parseProfile(profile) {
      return {
        AFMNumber: profile.AFMNumber || "-",
        baseLocation: profile.baseLocation || "-",
        baseTimeZone: profile.baseTimeZone || "-",
        BFM2up: profile.BFM2up || false,
        BFMSolo: profile.BFMSolo || false,
        BFMNumber: profile.BFMNumber || "-",
        recordKeeperAddress: profile.recordKeeperAddress || "-",
      };
    },
    parseForSubmit(values) {
      return {
        id: this.detailsId,
        profile: {
          AFMNumber: values.profile[0].AFMNumber || "-",
          baseLocation:
            `${values.profile[0].baseLocation[0].street}, ${values.profile[0].baseLocation[0].city}, ${values.profile[0].baseLocation[0].state}` ||
            "-",
          baseTimeZone: values.profile[0].baseTimeZone || "-",
          BFM2up: values.profile[0].BFM2up || false,
          BFMSolo: values.profile[0].BFMSolo || false,
          BFMNumber: values.profile[0].BFMNumber || "-",
          recordKeeperAddress:
            `${values.profile[0].recordKeeperAddress[0].street}, ${values.profile[0].recordKeeperAddress[0].city}, ${values.profile[0].recordKeeperAddress[0].state}` ||
            "-",
        },
      };
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
