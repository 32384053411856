<template>
  <div class="pb-12">
    <div class="items-center">
      <label>Recipients</label>
      <div class="flex flex-row mb-2">
        <div class="w-2/3">
          <input
            class="shadow-sm border border-gray-400 rounded px-3 py-2 leading-none focus:border-orange-primary focus:shadow outline-none w-full"
            v-model="newRecipient"
            placeholder="Add recipient"
            @focus="clearValidation"
            ref="recipient"
          />
        </div>
        <div class="w-1/3 text-right text-xs">
          <button
            class="btn btn-primary"
            @click.prevent="addRecipient()"
            :style="{ backgroundColor: theme.secondaryColor, height: '100%' }"
          >
            Add Email
          </button>
        </div>
      </div>

      <div
        class="py-2 border rounded-lg w-full"
        v-if="selectedRecipients && selectedRecipients.length > 0"
      >
        <div class="flex items-center flex-wrap px-2">
          <small
            v-for="(data, index) in selectedRecipients"
            class="text-white px-4 py-2 mx-1 my-1 rounded-full"
            :style="{ backgroundColor: theme.primaryColor }"
            :key="index"
          >
            {{ data }}
            <button @click.prevent="removeRecipient(data)" class="ml-2 mr-1">
              <b>x</b>
            </button>
          </small>
        </div>
      </div>

      <div class="text-red-500 text-center mb-3" v-if="validationError">
        {{ validationError }}
      </div>

      <div class="text-xs text-red-500 mt-5">
        *This will be applied to only current user.
      </div>

      <StyledSubmit :backgroundColor="theme.secondaryColor" class="mt-5">
        <FormulateInput
          type="submit"
          input-class="btn btn-primary w-full"
          :disabled="submitLoading"
          :style="{ backgroundColor: theme.secondaryColor }"
          @click="saveLeadsDestination()"
        >
          <span v-if="submitLoading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Saving
            data...</span
          >
          <span v-if="!submitLoading">Save</span>
        </FormulateInput>
      </StyledSubmit>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "LeadsDestinationBox",
  components: { StyledSubmit },

  data() {
    return {
      webProfile: null,
      error: false,
      loading: false,
      submitLoading: false,
      validationError: "",
      newRecipient: "",
      selectedRecipients: [],
    };
  },
  destroyed() {
    this.$emit("closeModal");
  },
  async mounted() {
    this.loading = true;

    const me = this.$store.getters[`account/me`];
    this.webProfile = me?.webProfile;

    if (!this.webProfile) {
      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", {
        state: "error",
        message: "Something went wrong, please try again later!",
      });
      this.$emit("closeRefresh");
    }

    if (this.webProfile.leadsDestinationBox) {
      this.selectedRecipients = this.webProfile.leadsDestinationBox;
    }

    this.loading = false;
  },

  methods: {
    async saveLeadsDestination() {
      this.submitLoading = true;

      let payload = {
        id: this.webProfile._id,
        leadsDestinationBox: this.selectedRecipients,
      };

      await this.$store
        .dispatch(`rolesandrespo/updateLeadsDestinationBox`, payload)
        .then(() => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Lead Destination successfully saved.",
          });
          this.submitLoading = false;
          this.$emit("closeRefresh");
        })
        .catch((err) => {
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "error",
            message: err.message,
          });
          this.submitLoading = false;
        });

      this.submitLoading = false;
    },

    addRecipient() {
      this.$refs.recipient.blur();
      let name = this.newRecipient;
      let existing = this.selectedRecipients?.filter((item) => item === name);

      // eslint-disable-next-line no-useless-escape
      const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
        name
      );

      if (isEmail) {
        if (_.isEmpty(existing)) {
          this.selectedRecipients.push(name);
        }
        this.newRecipient = "";
      } else {
        this.validationError = "Invalid Recipient";
        this.newRecipient = "";
      }
    },

    removeRecipient(user) {
      this.selectedRecipients.splice(this.selectedRecipients.indexOf(user), 1);
    },

    clearValidation() {
      if (this.validationError !== "") {
        this.validationError = "";
      }
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style scoped></style>
