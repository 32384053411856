<template>
  <div class="mb-16">
    <div class="loading centered" v-if="isLoading">
      <ui-spinner class="mx-auto"></ui-spinner>
    </div>
    <div v-if="!isLoading">
      <div class="text-center mb-10">
        <div class="font-bold text-black-primary text-2xl">
          {{ driverTrip.driverName }}
        </div>
        <div class="text-xs">Driver Name</div>
        <div class="font-bold text-xs">
          {{ driverTrip.status }}
        </div>
      </div>

      <section class="mt-5 border rounded-lg group bg-gray-100">
        <header class="flex">
          <div class="pb-2 font-bold pt-3 px-3">Location</div>
        </header>
        <hr />
        <div class="px-5 py-5 bg-white">
          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Pick Up:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.location }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Destination:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.destination }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Load Time:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ formatDate(driverTrip.loadTime) }}</div>
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section class="mt-5 border rounded-lg group bg-gray-100">
        <header class="flex">
          <div class="pb-2 font-bold pt-3 px-3">Mass</div>
        </header>
        <hr />
        <div class="px-5 py-5 bg-white">
          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Mass Limit:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.massLimit }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Gross Weight:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">
                  {{ driverTrip.grossWeight }} {{ driverTrip.weightSelector }}
                </div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Net Weight:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">
                  {{ driverTrip.netWeight }} {{ driverTrip.weightSelector }}
                </div>
              </td>
            </tr>
          </table>
        </div>
      </section>

      <section class="mt-5 border rounded-lg group bg-gray-100">
        <header class="flex">
          <div class="pb-2 font-bold pt-3 px-3">Drive Vehicle</div>
        </header>
        <hr />
        <div class="px-5 py-5 bg-white">
          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Drive Vehicle:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.vehiclePlate }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Steer Axle:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.steer }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Drive Axle:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.drive }}</div>
              </td>
            </tr>
          </table>
        </div>
      </section>

      <div
        class="mb-2"
        v-for="(trailer, index) in driverTrip.trailers"
        :key="index"
      >
        <section class="mt-5 border rounded-lg group bg-gray-100">
          <header class="flex">
            <div class="pb-2 font-bold pt-3 px-3">Trailer #{{ index + 1 }}</div>
          </header>
          <hr />
          <div class="px-5 py-5 bg-white">
            <table>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Plate Number:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">{{ trailer.trailerPlate }}</div>
                </td>
              </tr>
              <tr>
                <td class="pb-1">
                  <div class="font-semibold">Axles:</div>
                </td>
                <td class="pb-1">
                  <div class="ml-3">
                    {{
                      trailer.axles && trailer.axles.length
                        ? trailer.axles.toString()
                        : "N/A"
                    }}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </section>
      </div>

      <section class="mt-5 border rounded-lg group bg-gray-100">
        <header class="flex">
          <div class="pb-2 font-bold pt-3 px-3">Additional Information</div>
        </header>
        <hr />
        <div class="px-5 py-5 bg-white">
          <table>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Material:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.loadDescription }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Docket Number:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.docketNumber }}</div>
              </td>
            </tr>
            <tr>
              <td class="pb-1">
                <div class="font-semibold">Driver Comment:</div>
              </td>
              <td class="pb-1">
                <div class="ml-3">{{ driverTrip.comment }}</div>
              </td>
            </tr>
          </table>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/_helper";

export default {
  name: "view-driver-trip",
  components: {},

  props: {
    driverTripId: {
      type: String,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      driverTrip: {},
    };
  },

  created() {
    this.init();
  },
  methods: {
    async init() {
      this.isLoading = true;
      try {
        this.driverTrip = await this.$store.dispatch(
          "driver/getOneTrip",
          this.driverTripId
        );
      } catch (error) {
        this.emit("toast", "error", "Something went wrong");
      }

      this.isLoading = false;
    },
    formatDate(date) {
      return formatDate(date, undefined, true);
    },
  },
};
</script>

<style scoped lang="scss">
.centered {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
</style>
