<template>
  <div class="mb-16">
    <div class="w-full" v-if="loading">
      <div
        class="bg-gray-50 m-auto absolute inset-0 z-10 flex flex-col justify-center items-center"
      >
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Fetching data...</span>
      </div>
    </div>
    <div class="items-center justify-between">
      <div class="grid">
        <div class="flex w-full justify-end mb-4">
          <button
            class="btn btn-primary"
            @click.prevent="addRow()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Add Row
          </button>
        </div>
        <table class="min-w-full leading-normal mb-4">
          <thead>
            <tr>
              <th
                v-for="(header, index) in tableHeader"
                :key="index"
                class="px-5 py-4 bg-gray-100 text-left text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.accentColor }"
              >
                {{ header }}
              </th>
              <th
                class="px-5 py-4 bg-gray-100 text-center text-xs font-semibold text-white uppercase tracking-wider"
                :style="{ backgroundColor: theme.accentColor }"
              ></th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr
              class="border-b border-gray-200 text-sm bg-white hover:bg-gray-100 cursor-default"
              v-for="(data, index) in inputValues"
              :key="index"
            >
              <td class="text-left">
                <FormulateInput
                  placeholder="Link Name"
                  required
                  name="linkName"
                  v-model="data.linkName"
                  type="text"
                  validation="required"
                  validation-error="required"
                  class="block h-12 w-full ml-5 pr-5 leading-tight relative"
                  outer-class="mb-0"
                />
              </td>
              <td>
                <FormulateInput
                  placeholder="Link"
                  required
                  name="link"
                  v-model="data.link"
                  validation="required"
                  validation-error="required"
                  class="block h-12 w-full ml-5 pr-5 leading-tight relative"
                  outer-class="mb-0"
                />
              </td>
              <td>
                <FormulateInput
                  type="checkbox"
                  name="isIncludingUid"
                  v-model="data.isIncludingUid"
                  class="formulate-input"
                  label-class="formulate-input-label formulate-input-label--after"
                  input-class=""
                  wrapper-class="formulate-input-wrapper justify-center"
                  outer-class=""
                  element-class="formulate-input-element formulate-input-element--checkbox"
                />
              </td>
              <td>
                <FormulateInput
                  type="checkbox"
                  name="isIncludingPlate"
                  v-model="data.isIncludingPlate"
                  class="formulate-input"
                  label-class="formulate-input-label formulate-input-label--after"
                  input-class=""
                  wrapper-class="formulate-input-wrapper justify-center"
                  outer-class=""
                  element-class="formulate-input-element formulate-input-element--checkbox"
                />
              </td>
              <td>
                <FormulateInput
                  type="checkbox"
                  name="isMainMenu"
                  v-model="data.isMainMenu"
                  class="formulate-input"
                  label-class="formulate-input-label formulate-input-label--after"
                  input-class=""
                  wrapper-class="formulate-input-wrapper justify-center"
                  outer-class=""
                  element-class="formulate-input-element formulate-input-element--checkbox"
                />
              </td>
              <td>
                <button
                  class="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full"
                  @click.prevent="removeRow(index)"
                >
                  <font-awesome-icon icon="minus" class="my-auto" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <FormulateInput
        type="submit"
        label="Submit"
        @click.prevent="onSubmit()"
        input-class="btn btn-primary w-full"
        :disabled="formLoading"
        :style="{ backgroundColor: theme.secondaryColor }"
      />
    </div>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>
<script>
import schema from "./create-integration-link-schema";
// import { StyledIconedLink } from "@/styled-components";
// import { StyledSubmit } from "@/styled-components";
import { mapGetters } from "vuex";

export default {
  name: "New-Integration-Link-Form",
  components: {},
  props: {
    msg: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    entityId: {
      type: String,
      default: "",
    },
    linkTarget: {
      type: String,
      default: "business",
    },
    linkType: {
      type: String,
      default: "integration-links",
    },
  },
  // components: { StyledSubmit },
  data() {
    this.$emit("schema", schema);
    return {
      loading: false,
      formLoading: false,
      values: {},
      schema: schema,
      errorMessage: "",
      selectedPartner: {},
      tableHeader: [
        "Name of link",
        "Link",
        "Include UID?",
        "Include Plate #?",
        "Add to Main Menu?",
      ],
      inputValues: [
        {
          linkName: "",
          link: "",
          isIncludingUid: false,
          isIncludingPlate: false,
          isMainMenu: false,
        },
      ],
      me: {},
    };
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  created() {
    this.loading = true;
    this.loading = false;
  },
  methods: {
    async getBusiness() {},
    addRow() {
      this.inputValues.push({
        linkName: "",
        link: "",
        isIncludingUid: false,
        isIncludingPlate: false,
        isMainMenu: false,
      });
    },
    removeRow(index) {
      this.inputValues.splice(index, 1);
    },
    async onSubmit() {
      this.formLoading = true;
      this.me = this.$store.getters[`account/me`];
      const formData = {};
      formData.partnerId = this.me.entity._id;
      formData.businessId = this.entityId;
      formData.linkTarget = this.linkTarget;
      formData.linkType = this.linkType;
      formData.batchIntegrationLink = this.inputValues;

      await this.$store
        .dispatch(`dynamicLink/createBatchBusinessIntegrationLinks`, formData)
        .then((response) => {
          if (response.success == true) {
            this.$emit("closeModal");
            this.$emit("submitSuccess");
            this.$store.commit("setDialogNotify", true);
            this.$store.commit("setMessageNotify", {
              state: "success",
              message: "Integration Links successfully created.",
            });
            location.reload();
          }
        })
        .catch((err) => {
          this.errorMessage = err;
        })
        .finally(() => {
          this.formLoading = false;
        });
    },
  },
  watch: {},
};
</script>
