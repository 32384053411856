import { STATES } from '@/constants/location'

export default [
    {
        type: 'group',
        name: 'persona',
        children: [
          {
            type: 'text',
            name: 'businessName',
            label: 'Business Name',
            validation: 'required'
          },
          {
            type: 'text',
            name: 'tradingName',
            label: 'Trading Name',
            validation: 'required'
          },
          {
            type: 'text',
            name: 'abn',
            label: 'ABN',
            validation: 'required'
          },
          {
            type: 'group',
            name: 'businessAddress',
            label: 'Business Address',
            labelClass: 'font-medium text-sm mb-1 block -top-7 -left-0 absolute',
            class: 'border rounded p-5 mb-5 mt-11 relative bg-white',
            children: [
              {
                name: 'street',
                label: 'Street Address',
                validation: 'required'
              },
              {
                component: 'div',
                class: 'grid grid-cols-2 gap-4',
                children: [
                  {
                    name: 'city',
                    label: 'City',
                    validation: 'required'
                  },
                  {
                    name: 'state',
                    type: 'select',
                    label: 'State',
                    options: STATES,
                    validation: 'required'
                  },
                ]
              }
            ]
          },
          {
            type: 'text',
            name: 'contactUserName',
            label: 'Contact Username',
            validation: 'required'
          },
          {
            type: 'email',
            name: 'contactEmail',
            label: 'Contact Email',
            validation: '^required|email'
          },
          {
            type: 'text',
            name: 'contactPhoneNumber',
            label: 'Contact Phone Number',
            validation: 'required'
          },
        ],
      },
      {
        type: 'email',
        name: 'supportEmail',
        label: 'Support Email',
        validation: '^required|email'
      },
      {
        type: 'text',
        name: 'supportPhoneNumber',
        label: 'Support Phone Number',
        validation: 'required'
      },
      {
        type: "image",
        name: "businessLogoLightBackground",
        label: "Logo (Light Background)",
        //help: "Select an image to upload.",
        validation: "mime:image/jpeg,image/png,image/gif"
      },
      {
        type: "image",
        name: "businessLogoDarkBackground",
        label: "Logo (Dark Background)",
        //help: "Select an image to upload.",
        validation: "mime:image/jpeg,image/png,image/gif"
      },
      {
        component: 'div',
        class: 'border rounded p-5 mb-5 bg-white',
        children: [
          {
            type: 'group',
            name: 'create_password',
            children: [
              {
                type: 'password',
                name: 'password',
                label: 'Enter a Password',
                validation: 'required'
              },
              {
                type: 'password',
                name: 'confirmPassword',
                label: 'Confirm your password',
                validation: '^required|confirm:password',
                validationName: 'Password confirmation'
              }
            ]
          }
        ]
      },
      
    ]