<template>
  <div class="bg-black">
    <div class="flex min-h-screen justify-center items-center bg-black">
      <div class="container py-10 flex h-full flex-col lg:w-1/2 md:w/23 w-full px-4">
        <div class="text-center m-5">
          <a :href="$store.getters['baseURL']">
            <img src="/assets/images/logo.png" alt="Logmaster Australia" class="w-64 m-auto">
          </a>
        </div>
        <div v-if="!isEntityAdded" class="">
          <div v-if="!loading" class="rounded-lg bg-gray-50 p-8 shadow">
            <BusinessSignupForm 
              v-if="linkData.linkTarget == 'business'" 
              :link-data="linkData"
            />
            <SoloSignupForm 
              v-else-if="linkData.linkTarget == 'solo'"
              :link-data="linkData"
              @newDriverAdded="newEntityAdded"
            />
            <div class="flex justify-center items-center font-bold text-lg" v-if="hasError">{{ errorMessage }}</div>
          </div>
        </div>
        <div v-else>
          <div v-if="!loading" class="rounded-lg bg-gray-50 p-10 shadow">
            <div class="text-center mb-5">
              <h1 class="text-md text-center font-bold">
                An email has been sent to {{ userEmail }}.<br />
                Continue with your email to create a password and proceed with
                your registration.
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessSignupForm from '@/components/forms/account/business-signup-form.vue';
import SoloSignupForm from '@/components/forms/account/solo-signup-form.vue';
export default {
  components: {
    BusinessSignupForm,
    SoloSignupForm
  },
  data () {
    return {
      linkData: {},
      loading: false,
      errorMessage: '',
      isEntityAdded: false,
      userEmail: '',
      hasError: false
    }
  },
  async created() {
    await this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      await this.getPromoLinkByUrl();
      this.loading = false;
      
    },

    async getPromoLinkByUrl() {
      const fullUrl = window.location.origin + window.location.pathname;
      const params = {
        url: fullUrl        
      }

      await this.$store.dispatch(`dynamicLink/getPromoLinkByUrl`, params)
        .then((response) => {
          this.linkData = response.data;
        })
        .catch((error) => {
          this.hasError = true;
          this.errorMessage = error?.data.message;
          console.log(error.data)
        })
    },

    async newEntityAdded(data) {
      this.isEntityAdded = true;
      if(this.linkData.linkTarget == 'solo') {
        this.userEmail = data.emailAddress
      }
    }
  }
}
</script>