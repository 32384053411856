<template>
  <div>
    <div class="flex w-full" v-if="loading">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
    <div v-if="passwordCreated">
      <h1 class="text-2xl text-green-600 text-center font-bold">
        Welcome to Logmaster Australia, your account has been created.
      </h1>
      <div class="text-sm text-center">Redirecting...</div>
    </div>
    <div v-if="!passwordCreated">
      <FormulateForm v-model="values" @submit="onSubmit">
        <FormulateInput
          type="email"
          name="emailAddress"
          label="Email Address"
          v-model="driver.emailAddress"
          validation="required|email"
          :disabled="driver.emailAddress"
        />
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driverName"
            label="Full Name"
            validation="required"
            :disabled="loading"
          />
          <FormulateInput
            type="date"
            name="driverDateOfBirth"
            label="Date Of Birth"
            validation="required"
            :disabled="loading"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="password"
            name="password"
            label="Password"
            validation="^required|min:6"
            :disabled="loading"
          />
          <FormulateInput
            type="password"
            name="password_confirm"
            label="Confirm Password"
            validation="required|confirm"
            validation-name="Password confirmation"
            :disabled="loading"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <FormulateInput
            type="text"
            name="driversLicenseNumber"
            label="Licence Number"
            validation="required"
            :disabled="loading"
          />
          <FormulateInput
            type="date"
            name="driversLicenseExpiry"
            label="Licence Expiry"
            validation="required"
            :disabled="loading"
          />
        </div>
        <FormulateInput
          type="select"
          name="driversLicenseState"
          label="Licence State"
          :options="states"
          validation="required"
          :disabled="loading"
        />

        <div class="items-center justify-between">
          <FormulateInput
            type="submit"
            label="Register"
            input-class="btn btn-primary w-full"
            :disabled="loading"
          />
        </div>
      </FormulateForm>
      <div class="text-red-500 text-center mb-3" v-if="errorMessage">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>
<script>
import Spinner from "@/components/commons/ui/Spinner";
import { STATES } from "../../../_helper/constants";
import moment from "moment";
export default {
  name: "Contractor-Accept-Form",
  components: { Spinner },
  data() {
    return {
      values: {},
      loading: true,
      errorMessage: "",
      isValidDriver: false,
      passwordCreated: false,
      driver: {},
      states: STATES,
    };
  },

  async mounted() {
    this.loading = true;
    if (!this.driverId) {
      this.contractorId = this.$route.params["acceptId"];
      this.contractorId = this.contractorId
        ? this.contractorId
        : this.$route.params["id"];
    }

    if (this.contractorId) {
      await this.$store
        .dispatch("driver/getContractorDriverByID", this.contractorId)
        .then((contractor) => {
          if (contractor) {
            if (contractor.status != "pending_invite")
              window.location.href =
                "/login?message=Request is already accepted&state = error";
            this.loading = false;
            this.isValidDriver = true;
            this.driver = contractor;
          } else {
            this.loading = false;
            this.errorMessage = "No drivers account found!";
            window.location.href =
              "/login?message=Driver not found&state=error";
          }
        })
        .catch((error) => {
          this.loading = false;
          this.errorMessage = error?.message;
          // window.location.href = '/login?message=Invalid request&state=error'
        });
    }
  },

  methods: {
    async onSubmit() {
      this.loading = true;
      let newSoloDriver = JSON.parse(JSON.stringify(this.values));

      newSoloDriver = {
        ...newSoloDriver,
        driverRecordKeeperAddress: this.driver.recordKeeperAddress,
        driverBaseLocation: this.driver.baseLocation,
        driverBaseTimeZone: this.driver.baseTimeZone,
        parentUid: this.driver.parentUid,
        parentRole: this.driver.parentRole,
        emailAddress: this.driver.emailAddress,
        engineRegion: this.driver.engineRegion,
        isDemoAccount: false,
        entityId: this.driver.businessId,
        contractorId: this.driver._id,
        demoOption: "NO_DEMO",
        create_password: {
          password: newSoloDriver.password,
          confirmPassword: newSoloDriver.password_confirm,
        },
      };
      this.$store
        .dispatch(`driver/createContractorDriver`, newSoloDriver)
        .then((response) => {
          if (response && response._id) {
            this.loading = false;
            window.location.href = "/login";

            // this.createBusinessDriverContract(response);
          }
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = "Something went wrong";
          // window.location.href = '/login?message=Something went wrong&state=error'
        });
    },

    async acceptContractAndUpdateStatus(driver) {
      await this.$store
        .dispatch("driver/acceptDriver", driver._id)
        .then(async () => {
          await this.$store
            .dispatch(`driver/updateContractorStatus`, {
              id: this.driver._id,
              status: "pending_contract",
            })
            .then(() => {
              this.loading = false;
              window.location.href = "/login";
            })
            .catch(() => {
              this.loading = false;
              this.errorMessage = "Something went wrong";
              // window.location.href = '/login?message=Something went wrong&state=error'
            });
        })
        .catch(() => {
          this.loading = false;
          this.errorMessage = "Something went wrong";
          // window.location.href = '/login?message=Something went wrong&state=error'
        });
    },

    async createBusinessDriverContract(driver) {
      const newContract = {
        from: "business",
        driverId: driver._id,
        businessId: this.driver.businessId,
        profile: {
          BFM2up: false,
          BFMSolo: false,
          baseTimeZone: this.driver.baseTimeZone,
          baseLocation: this.driver.baseLocation,
          recordKeeperAddress: this.driver.recordKeeperAddress,
          engineRegion: this.driver.engineRegion,
        },
        isContractor: true,
        startDate: moment(),
      };

      await this.$store
        .dispatch(`business/createContract`, newContract)
        .then(async () => {
          //acceptDriver
          try {
            const acceptDriver = this.$store.dispatch(
              "driver/acceptDriver",
              driver._id
            );
            this.loading = false;
            if (acceptDriver) {
              this.passwordCreated = true;
              window.location.href = "/login";
            } else {
              this.errorMessage = "Driver has accepted the invitation";
              window.location.href =
                "/login?message=Request already accepted&state=error";
            }
          } catch (error) {
            this.loading = false;
            this.errorMessage = "Something went wrong";
            window.location.href =
              "/login?message=Something went wrong&state=error";
          }
        });
    },
  },
};
</script>
