<template>
  <div class="flex flex-row mb-1 sm:mb-0 w-full justify-center">
    <div v-if="!loading" class="w-full">
      <div v-if="!isUrlAvailable" class="flex flex-col items-center mx-4 my-8">
        <h3
          class="text-lg leading-6 font-medium text-gray-900 mb-4"
          id="modal-title"
        >
          No Link Generated
        </h3>
        <button
          class="btn btn-primary"
          @click.prevent="generateLink()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          <span v-if="submitLoading"
            ><font-awesome-icon icon="spinner" class="mr-1 loader" /> Generating
            url...</span
          >
          <span v-if="!submitLoading">Generate Link</span>
        </button>
      </div>
      <div v-else class="flex flex-col items-center my-8 w-full">
        <h3 class="font-bold">{{ urlData.linkName }}</h3>
        <FormulateInput
          class="w-full"
          id="modal-title"
          v-model="shareableUrl"
        />
        <div class="flex items-center space-x-2">
          <button
            class="btn btn-primary"
            @click.prevent="copyShareableLink()"
            :style="{ backgroundColor: theme.primaryColor }"
          >
            <span v-if="copyLink">Copied!</span>
            <span v-if="!copyLink">Copy Link</span>
          </button>
          <!-- <button
            class="btn btn-primary"
            @click.prevent="generateLink()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            <span v-if="submitLoading"
              ><font-awesome-icon icon="spinner" class="mr-1 loader" />
              Generating url...</span
            >
            <span v-if="!submitLoading">Generate New Link</span>
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Invite-Link",
  components: {},
  data() {
    return {
      submitLoading: false,
      copyLink: false,
      urlType: "",
      me: {},
      shareableUrl: "",
      isUrlAvailable: false,
      loading: false,
      urlData: {},
    };
  },
  async mounted() {
    this.loading = true;
    this.me = this.$store.getters[`account/me`];
    await this.fetchContractorInviteLink();
    this.loading = false;
  },

  methods: {
    async generateLink() {
      this.submitLoading = true;
      await this.$store
        .dispatch(`dynamicLink/createContractorSignupLink`, {
          businessId: this.me.entity?._id,
          linkType: "contractor-signup",
          baseUrl: process.env.VUE_APP_URL,
        })
        .then((response) => {
          if (response) {
            setTimeout(() => {
              this.fetchContractorInviteLink();
            }, 4000);
          }
        })
        .catch((error) => {
          this.businessData = [];
          console.log(error);
        });
    },
    async fetchContractorInviteLink() {
      await this.$store
        .dispatch(`dynamicLink/getBusinessContractorInviteLink`, {
          businessId: this.me?.entity?._id,
        })
        .then((response) => {
          if (response) {
            this.shareableUrl = response.data.link;
            this.urlData = response.data;
            if (this.shareableUrl) {
              this.isUrlAvailable = true;
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.isUrlAvailable = false;
        });
    },
    async updateMe(user) {
      if (user?.entity || user?.business || user?.driver) {
        this.$emit("fetchedProfile", user);
      } else {
        await this.$store.dispatch(`account/updateMe`, user).then(() => {
          this.submitLoading = false;
          this.$store.commit("setDialogNotify", true);
          this.$store.commit("setMessageNotify", {
            state: "success",
            message: "Shareable url created successfully.",
          });
          this.me = this.$store.getters[`account/me`];
          if (this.isSolo == true) {
            this.shareableUrl = this.me?.entity?.soloUrl;
          } else {
            this.shareableUrl = this.me?.entity?.businessUrl;
          }

          if (this.shareableUrl) {
            this.isUrlAvailable = true;
          }
        });
      }
    },

    async copyShareableLink() {
      this.copyLink = true;
      navigator.clipboard.writeText(this.shareableUrl);
      setTimeout(() => {
        this.copyLink = false;
      }, 4000);
    },
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>

<style></style>
