<template>
  <div>
    <Tabs class="h-full" v-if="entityType == 'admin'" :clickEvent="true" @click="fetchTab()">
      <Tab class="h-full" :title="tabTitle">
        <Leads :allPartnerLeads="false" ref="leadsComponent"></Leads>
      </Tab>
      <Tab class="h-full" :visible="entityType == 'admin'" title="Partners Leads">
        <Leads :allPartnerLeads="true" ref="leadsComponent"></Leads>
      </Tab>
      <Tab title="Integration Links">
        <IntegrationLinks />
      </Tab>
    </Tabs>
    <Tabs class="h-full" v-if="entityType == 'partner'" :clickEvent="true" @click="fetchTab()">
      <Tab class="h-full" :title="tabTitle">
        <Leads :allPartnerLeads="false" ref="leadsComponent"></Leads>
      </Tab>
      <Tab class="h-full" title="Promo Links">
        <PromoLinks :entityId="this.me.entity._id" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
import Tabs from "@/components/commons/tab/Tabs.vue";
import Tab from "@/components/commons/tab/Tab.vue";
import Leads from "@/pages/Leads.vue";
import PromoLinks from "@/pages/PromoLinks.vue"
import IntegrationLinks from './IntegrationLinks.vue';
export default {
  name: "Leads-Main",
  components: { Leads, Tab, Tabs, PromoLinks, IntegrationLinks },
  data() {
    return {
      me: null,
      entityType: null,
      tabTitle: null,
    };
  },
  async mounted() {
    this.me = this.$store.getters["account/me"];
    this.entityType = this.me.role.name;
    if (this.entityType == "admin") {
      this.tabTitle = "Logmaster Leads";
    } else if (this.entityType == "partner") {
      this.tabTitle = "Partner Leads";
    }
  },
  methods: {
    async fetchTab() {
      await this.$refs.leadsComponent.init();
    },
  },
};
</script>
