<template>
  <div class="flex flex-col">
    <div class="loading flex justify-center items-center p-4" v-if="loading">
        <ui-spinner class="mx-auto"></ui-spinner>
    </div> 
    <div class="flex justify-center items-center" v-else-if="!loading">
      <button
        v-if="!hasExistingNotificationLink"
        class="btn btn-primary"
        @click.prevent="createNotificationLink()"
        :style="{ backgroundColor: theme.secondaryColor }"
      >
        Create Notification API link
      </button>
      <!-- If there is an existin api link, show the api link details -->
      <div v-else class="flex flex-col w-full space-y-4">
        <div class="flex text-right justify-end items-center">
          <a @click.prevent="editNotificationLink()" class="flex cursor-pointer"><font-awesome-icon icon="edit" class="my-auto mx-1.5 hover:text-orange-primary text-xs"/></a>
        </div>
        <div class="flex flex-col">
          <div class="font-bold text-black-primary text-base">{{notificationLink.linkName}}</div>
          <div class="text-xs">Link Name</div>
        </div>
        <div class="flex flex-col">
          <div class="font-bold text-black-primary text-base">{{notificationLink.link}}</div>
          <div class="text-xs">URL</div>
        </div>
        <div class="flex flex-col">
          <div class="font-bold text-black-primary text-base">{{notificationLink.httpRequestMethod.toUpperCase()}}</div>
          <div class="text-xs">Request Method</div>
        </div>
        <div class="flex flex-col">
          <div class="font-bold text-black-primary text-base">{{notificationLink.apiHeaderAuth}}</div>
          <div class="text-xs">API Authorization</div>
        </div>
        <div class="flex flex-col">
          <div class="font-bold text-black-primary text-base">{{notificationLink.queryParameters}}</div>
          <div class="text-xs">Query Parameters</div>
        </div>
      </div>
    </div>
    <Modal ref="modal" :title="modalTitle" size="3xl" :height="modalHeight">
      <div v-if="modalContent == 'createNotificationLink'">
        <CreateNotificationApiLinkForm 
          :entity-id="entityId"
          :business-id="businessId"
          :values="notificationLink"
        />
      </div>
    </Modal>
  </div>
</template>

<script>
// import { StyledIconedLink } from "@/styled-components";
import {Modal} from "@/components/commons/"
import { mapGetters } from "vuex";
import { DEFAULT_MODAL_HEIGHT } from '@/_helper/constants';
import CreateNotificationApiLinkForm from '@/components/forms/dynamic-links/create-notification-api-link-form.vue';

export default {
  name: "Notification-Link",
  components: {
    Modal,
    CreateNotificationApiLinkForm,
    // StyledIconedLink
  },
  props: {
    businessId: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      modalContent: '',
      modalTitle: '',
      modalHeight: DEFAULT_MODAL_HEIGHT,
      loading: false,
      me: {},
      entityId: '',
      notificationLink: {},
      errorMessage: '',
      hasError: false,
      hasExistingNotificationLink: false,
    }
  },
  async created() {
    this.loading = true
    this.me = this.$store.getters['account/me'];
    this.entityId = this.$store.getters[`account/me`].entity._id;
    await this.getExistingNotificationAPILink();
    this.loading = false
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
  methods: {
    createNotificationLink() {
      this.modalContent = "createNotificationLink";
      this.modalTitle = "Create Notification API Link";
      this.$refs.modal.toggleModal();
    },
    editNotificationLink() {
      this.modalContent = "createNotificationLink";
      this.modalTitle = "Edit Notification API Link";
      this.$refs.modal.toggleModal();
    },
    async getExistingNotificationAPILink() {
      await this.$store
        .dispatch(`dynamicLink/getBusinessNotificationLink`, {businessId: this.businessId})
        .then((response) => {
          if(response.data == null) {
            this.hasExistingNotificationLink = false
          }
          else {
            this.hasExistingNotificationLink = true
            this.notificationLink = response.data
          }
        })
        .catch((error) => {
          this.hasError = true
          this.errorMessage = error.data
        })
    }
  }
}

</script>