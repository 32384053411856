<template>
  <div>
    <div class="flex justify-between items-center w-full mb-4 mt-5">
      <div class="flex">
        <div class="block relative">
          <span
            class="h-full absolute inset-y-0 left-0 flex items-center pl-2 cursor-pointer"
          >
            <font-awesome-icon icon="search" class="text-gray-400" />
          </span>
          <input
            type="email"
            placeholder="Search Driver..."
            class="h-full appearance-none rounded border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
            v-model="seachKeyword"
            @input="valueChanged"
          />
        </div>
      </div>
      <div>
        <button
          class="btn btn-primary"
          @click="nexStep()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add Driver
        </button>
      </div>
    </div>
    <div class="flex w-full py-5" v-if="searchLoading">
      <div class="m-auto">
        <Spinner class="mx-auto mb-2"></Spinner>
        <span class="font-semibold text-gray-300">Loading...</span>
      </div>
    </div>
    <div v-if="!searchLoading" class="mb-16">
      <div
        class="text-red-500 font-bold text-sm text-center my-5"
        v-if="errorMessage.length"
      >
        {{ errorMessage[0] }}
      </div>
      <div
        class="text-gray-500 text-sm text-center my-5"
        v-if="!drivers.length"
      >
        <strong v-if="!errorMessage.length">No Driver found.<br /></strong>
        Add new one instead?<br />
        <button
          class="btn btn-primary mt-2"
          @click="nexStep()"
          :style="{ backgroundColor: theme.secondaryColor }"
        >
          Add Driver
        </button>
      </div>
      <Table
        v-if="drivers.length"
        :tableHeader="tableHeader"
        :data="drivers"
        :hasFilters="false"
        :hasActions="false"
      >
        <template #default="{ data }">
          <fragment v-if="data">
            <td class="px-5 py-2">
              <button
                class="btn btn-primary mt-2"
                @click="nexStep(data)"
                :style="{ backgroundColor: theme.secondaryColor }"
              >
                Select
              </button>
            </td>
          </fragment>
        </template>
      </Table>
    </div>
  </div>
</template>
<script>
import { Table } from "@/components/commons";
import { debounce } from "lodash";
import Spinner from "@/components/commons/ui/Spinner";
import { mapGetters } from "vuex";

export default {
  name: "Driver-Lookup",
  components: { Table, Spinner },
  props: {
    detailsId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      tableHeader: [
        "Driver Name",
        "Email Address",
        "Licence Number",
        "Licence State",
        "Action",
      ],
      drivers: [],
      seachKeyword: "",
      errorMessage: [],
      searchLoading: false,
    };
  },

  async mounted() {
    if (this.detailsId !== "") {
      await this.nexStep({ id: this.detailsId });
    }
  },

  methods: {
    //Format table data
    parseDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData._id,
          name: driverData.driverName,
          itemType: "name",
          hasImage: true,
          hasLink: true,
        },
        {
          id: driverData._id,
          name: driverData.emailAddress,
          itemType: "string",
        },
        {
          id: driverData._id,
          name: driverData.driversLicenseNumber,
          itemType: "string",
        },
        {
          id: driverData._id,
          name: driverData.driversLicenseState,
          itemType: "string",
        },
      ]);
    },
    validateEmail: (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async searchDriver(search) {
      if (!this.validateEmail(search)) {
        this.errorMessage.push("Invalid email address please try again.");
        this.searchLoading = false;
      } else {
        this.errorMessage = [];
        try {
          const soloDrivers = await this.$store.dispatch(
            `driver/getDriverByEmail`,
            search
          );

          if (soloDrivers) {
            this.drivers = this.parseDrivers([soloDrivers]);
          } else {
            this.drivers = [];
          }
          this.searchLoading = false;
        } catch (error) {
          this.errorMessage = [error.message];
          this.drivers = [];
          this.searchLoading = false;
        }
      }
    },
    nexStep(data) {
      this.$emit("nextStep", data ? data : false);
    },
  },
  created() {
    this.valueChanged = debounce(
      function (event) {
        if (!event.target.value) {
          this.searchLoading = false;
          this.errorMessage = [];
        } else {
          this.searchLoading = true;
          this.searchDriver(event.target.value);
        }
      }.bind(this),
      500
    );
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
