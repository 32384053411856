<template>
  <div class="mb-16">
    <div
      class="loading absolute mx-auto w-full h-full translate-y-2/4 flex flex-col items-center"
      v-if="loading"
    >
      <ui-spinner class="mx-auto"></ui-spinner>
      Generating duration terms options...
    </div>
    <FormulateForm v-if="!loading" v-model="values" @submit="onSubmit">
      <div class="items-center justify-between">
        <FormulateInput
          name="contractDuration"
          :options="contractDurationOptions"
          type="radio"
          placeholder="Select an option"
          label="Select a contract duration"
          class="custom-options checkbox"
          validation="^required"
          :validation-messages="{ required: 'Contract Duration is required.' }"
        />

        <div
          v-if="
            selectedContractDuration &&
            selectedContractDuration.label === 'Yearly'
          "
        >
          <FormulateInput
            type="number"
            label="Set number of years"
            name="yearCount"
            placeholder="Input number of year(s)"
            class="w-max"
            validation="^required"
            :validation-messages="{ required: `Number of years required` }"
          />
        </div>

        <FormulateInput
          name="billingPeriod"
          v-if="selectedContractDuration"
          v-model="selectedBillingPeriod"
          :options="billingPeriodFilteredOptions"
          type="radio"
          placeholder="Select an option"
          label="Select a billing period"
          class="custom-options checkbox"
          validation="^required"
          :validation-messages="{ required: 'Billing Period is required.' }"
        />

        <FormulateInput
          type="submit"
          label="Next"
          input-class="btn btn-primary w-full"
          :style="{ backgroundColor: theme.secondaryColor }"
        />
      </div>
    </FormulateForm>
    <div class="text-red-500 text-center mb-3" v-if="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Duration-Terms",
  props: {
    preData: {},
  },
  data() {
    return {
      loading: false,
      values: {},
      errorMessage: "",
      contractDurationOptions: [],
      billingPeriodOptions: [],
      billingPeriodFilteredOptions: [],
      selectedContractDuration: "",
      selectedBillingPeriod: "",
    };
  },
  async mounted() {
    this.init();
  },
  methods: {
    async init() {
      this.loading = true;
      const contractDuration = await this.$store.dispatch(
        "lookup/getContractDurationOptions"
      );

      if (contractDuration) {
        this.contractDurationOptions = contractDuration
          .map((options) => ({
            value: options._id,
            label: options.name,
          }))
          .filter((options) =>
            ["Monthly", "Yearly", "Quarterly"].includes(options.label)
          );
        this.contractDurationOptions = orderBy(
          this.contractDurationOptions,
          "label",
          "asc"
        );
      }

      const billingPeriod = await this.$store.dispatch(
        "lookup/getBillingPeriodOptions"
      );

      if (billingPeriod) {
        this.billingPeriodOptions = billingPeriod.map((options) => ({
          value: options._id,
          label: options.name,
        }));

        this.billingPeriodOptions = orderBy(
          this.billingPeriodOptions,
          "label",
          "asc"
        );
        this.billingPeriodFilteredOptions = this.billingPeriodOptions;
      }

      if (contractDuration && billingPeriod) {
        this.loading = false;
      }
    },
    async onSubmit() {
      const findBillingPeriod = this.billingPeriodOptions.find(
        (option) => option.value === this.values.billingPeriod
      );
      const findContractDuration = this.contractDurationOptions.find(
        (option) => option.value === this.values.contractDuration
      );
      const updatedValues = {
        ...this.values,
        ...{
          billingPeriod: findBillingPeriod,
          contractDuration: findContractDuration,
        },
      };
      const formData = { ...this.preData, ...updatedValues };
      this.$emit("nextStep", JSON.parse(JSON.stringify(formData)));
    },
  },
  watch: {
    values: {
      handler() {
        this.selectedContractDuration = this.values.contractDuration;
        if (this.values.contractDuration) {
          this.selectedBillingPeriod = this.values.billingPeriod;
          this.selectedContractDuration = this.contractDurationOptions.find(
            (value) => value.value === this.values.contractDuration
          );

          this.billingPeriodFilteredOptions = this.billingPeriodOptions;
          this.selectedBillingPeriod = this.billingPeriodOptions.at(2).value;
          if (
            this.selectedContractDuration &&
            this.selectedContractDuration.label !== "Yearly"
          ) {
            this.billingPeriodFilteredOptions =
              this.billingPeriodOptions.filter(
                (value) => value.label === this.selectedContractDuration.label
              );
            this.selectedBillingPeriod =
              this.billingPeriodFilteredOptions[0].value;
          }
        }
      },
    },
    deep: true,
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
