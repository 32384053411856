import { API } from '@/api/config'
import { ObjectToQueryString } from '@/_helper';

export default new class Incident {
  getIncidentByBusinessId(businessId, query = { skip:0, limit:10, filter:{} }){
    let filter = '';
    if(typeof query.filter === 'object') try{ filter = JSON.stringify(query.filter) }catch(e) { throw 'Invalid Filter object'}
    query.filter = filter;
    if(query) query = '?'+ObjectToQueryString(query);
    return API.ACCOUNTS.get(`/incident/find-by-businessId/${businessId}${query}`)
  }

  getIncidentByDateRange( data ){
    return API.ACCOUNTS.post(`/incident/date-range`, data);
  }

  getIncidentById( query ){
      return API.ACCOUNTS.get(`/incident/${query}`);
  }

  getSummaryReport( payload ){
    return API.ACCOUNTS.post(`/incident/summary-report`, payload);
  }

  getDetailedReport( payload ){
    return API.ACCOUNTS.post(`/incident/detailed-report`, payload);
  }

}