<template>
  <div class="w-full">
    <div class="w-full compliance-tabs-wrapper">
      <div class="relative mt-5 overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" class="px-6 py-3 bg-gray-50"></th>
              <th
                scope="col"
                class="block px-6 py-3 text-center w-44 bg-gray-50"
              >
                Status
              </th>
              <th scope="col" class="px-6 py-3 bg-gray-50">Counting Point</th>
              <th scope="col" class="px-6 py-3">Time</th>
              <th scope="col" class="px-6 py-3">Option</th>
              <th scope="col" class="px-6 py-3">Period</th>
              <th scope="col" class="px-6 py-3">Work/Rest</th>
              <th scope="col" class="px-6 py-3">Level</th>
              <th scope="col" class="px-6 py-3">Breach Time</th>
              <th scope="col" class="px-6 py-3">Action</th>
            </tr>
          </thead>

          <tbody>
            <tr
              class="text-sm bg-white border-b border-gray-200 cursor-default hover:bg-gray-100"
            >
              <td v-if="isLoading" colspan="9" class="p-4 text-center loading">
                <ui-spinner class="mx-auto mb-2"></ui-spinner>
                <span v-if="loadingText">{{ loadingText }}</span>
              </td>
            </tr>
            <tr
              v-show="breaches.length == 0 && !isLoading"
              class="border-b border-gray-200"
            >
              <td colspan="9" class="px-5 py-8 text-sm text-center">
                No breach found
              </td>
            </tr>
            <tr
              v-show="breaches.length > 0 && !isLoading"
              v-for="(breach, index) in breachesData"
              :key="index"
              class="text-xs bg-white border-b"
              :style="{ 'background-color': rowColor(breach.status) }"
            >
              <td class="px-6 py-4">
                <FormulateInput
                  type="checkbox"
                  name="breachCheck"
                  v-model="breach.isChecked"
                  v-if="breach.hasCheckbox && breach.status != 'remain'"
                  :disabled="breach.status == 'remove'"
                  value="false"
                  input-class="w-4 h-4"
                  style="margin-bottom: 0px !important"
                />
              </td>
              <td class="px-6 py-4 capitalize">
                <span class="block px-2 py-1 text-xs text-center bg-red">{{
                  breach.displayStatus
                }}</span>
              </td>
              <td class="px-6 py-4">
                {{ breach.countingPoint }}
              </td>
              <td class="px-6 py-4">
                {{ breach.time }}
              </td>
              <td class="px-6 py-4">
                {{ breach.option }}
              </td>
              <td class="px-6 py-4">
                {{ breach.period }}
              </td>
              <td class="px-6 py-4">
                {{ breach.type }}
              </td>
              <td class="px-6 py-4">
                {{ breach.level }}
              </td>
              <td class="px-6 py-4">
                {{ breach.breachTime }}
              </td>
              <td class="px-6 py-4">
                <div class="flex text-lg whitespace-no-wrap text-black-primary">
                  <a
                    class="flex cursor-pointer"
                    @click.prevent="viewBreach(breach)"
                    ><font-awesome-icon
                      icon="eye"
                      class="my-auto mx-1.5 hover:text-orange-primary"
                  /></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal ref="modal" :title="modal.title" size="lg" :height="modal.height">
      <div v-if="modal.content == 'view'">
        <BreachDetailsView :activeBreach="activeBreach" />
      </div>
    </Modal>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { Modal } from "@/components/commons";
import {
  generateBreaches,
  formatLevel,
  formatTime,
  formatPeriod,
  removeDuplicateBreaches,
} from "@/_helper/breach";
import BreachDetailsView from "@/components/view/breach";

export default {
  name: "NHVR-Breach-Engine-Report",
  components: { Modal, BreachDetailsView },
  data() {
    return {
      isLoading: false,
      loadingText: null,
      selectedDriver: null,
      selectedDateRange: [],
      businessId: null,
      events: [],
      breaches: [],
      breachCount: 0,
      dateFormat: "YYYY-MM-DD",
      me: null,
      activeBreach: null,
      modal: {
        title: "",
        content: null,
        height: "50vh",
      },
    };
  },
  methods: {
    viewBreach(breach) {
      this.$refs.modal.openModal();
      this.modal.content = "view";
      this.modal.title = "Breach Info";

      this.activeBreach = {
        breachData: breach,
        driverData: this.selectedDriver.driver,
      };
    },
    rowColor(status) {
      return status == "remove"
        ? "rgba(244, 106, 106, 0.06)"
        : status == "new"
        ? "rgba(52, 195, 143, 0.10)"
        : "";
    },
    async onSearch(selectedDriver, selectedDateRange, businessId) {
      this.selectedDriver = selectedDriver;
      this.selectedDateRange = selectedDateRange;
      this.businessId = businessId;
      this.isLoading = true;
      this.loadingText = "Fetching breaches...";

      const from = moment(selectedDateRange[0], this.dateFormat);
      const to = moment(selectedDateRange[1], this.dateFormat);
      const payload = {
        driverId: selectedDriver.driverId,
        from: from.format(),
        to: to.format(),
      };

      await Promise.all([
        this.$store.dispatch(`business/getEventByDateRange`, {
          driverId: selectedDriver.driverId,
          from: moment(selectedDateRange[0], this.dateFormat)
            .subtract(56, "days")
            .format(),
          to: moment(selectedDateRange[1], this.dateFormat)
            .add(56, "days")
            .format(),
        }),
        this.$store.dispatch(`report/getBreachesByDateRange`, payload),
      ]).then(async (data) => {
        const events = data[0];
        let existingBreaches = data[1];

        // Update breach period to :00 to the hours
        existingBreaches.forEach((breach) => {
          breach.period = formatPeriod(breach.period);
        });

        this.events = _.sortBy(events, ["startTimeWithTimezone"]).filter(
          (x) => x.status != "deleted"
        );
        this.events.forEach((x) => {
          x.startTime = new Date(x.startTime);
          return x;
        });

        const timezone =
          this.events.length > 1
            ? _.last(this.events).timezone
            : existingBreaches.length > 1
            ? _.last(existingBreaches).timezone
            : "VIC +10:00";

        let generatedBreaches = [];
        if (this.events.length > 0) {
          const data = {
            driverId: selectedDriver.driverId,
            businessId: this.businessId,
            events: this.events,
            timezone,
          };
          generatedBreaches = this.calculateBreaches(
            data,
            selectedDriver?.profile?.engineRegion
          );
        }

        let includedGeneratedBreaches = [];
        let startPointOfIncludedBreaches = new Set();

        // Filter breaches within date range
        for (const breach of generatedBreaches) {
          const breachDate = moment(breach.time, this.dateFormat).utcOffset(
            breach.timezone
          );
          if (breachDate.isBetween(from, to, undefined, "[]")) {
            includedGeneratedBreaches.push(breach);
            startPointOfIncludedBreaches.add(breach.startPoint);
          }
        }

        // Include breaches with the same startPoint as included breaches
        for (const breach of generatedBreaches) {
          if (
            !includedGeneratedBreaches.includes(breach) &&
            startPointOfIncludedBreaches.has(breach.startPoint)
          ) {
            includedGeneratedBreaches.push(breach);
          }
        }

        // Remove duplicate generated breaches
        includedGeneratedBreaches = removeDuplicateBreaches(
          includedGeneratedBreaches
        );

        includedGeneratedBreaches.forEach((breach) => {
          const breachExist = this.breachExistCheck(breach, existingBreaches);
          breach.entityId = this.businessId;
          breach.status = breachExist ? "remain" : "new";
          breach.displayStatus = breachExist ? "To remain" : "To be added";
          breach.isChecked = !breachExist;
          breach.hasCheckbox = !breachExist;
        });

        existingBreaches = existingBreaches.filter((breach) => {
          const breachExist = this.breachExistCheck(
            breach,
            includedGeneratedBreaches
          );
          if (!breachExist) {
            breach.status = "remove";
            breach.displayStatus = "To be removed";
          }
          breach.isChecked = !breachExist;
          breach.hasCheckbox = true;

          return breach?.status ? true : false;
        });

        this.breaches = [...includedGeneratedBreaches, ...existingBreaches];
        this.breaches = _.sortBy(this.breaches, ["time"]);

        const hasBreach =
          this.breaches.filter((x) => x.hasCheckbox && x.isChecked).length > 0
            ? true
            : false;
        this.$emit("updateHasBreach", hasBreach);
      });

      this.isLoading = false;
      this.loadingText = null;
    },
    async onConfirmBreaches() {
      this.isLoading = true;
      this.loadingText = "Saving changes, please wait...";

      try {
        let selectedBreaches = _.cloneDeep(this.breaches);
        selectedBreaches = selectedBreaches.filter((x) => x.isChecked);
        selectedBreaches.forEach((x) => {
          delete x.isChecked;
          delete x.hasCheckbox;
        });
        const payload = {
          breaches: selectedBreaches,
        };
        await this.$store.dispatch("breach/confirmBreaches", payload);
        this.breaches.forEach((x) => {
          x.displayStatus = "To remain";
          x.status = "remain";
        });
        this.isLoading = false;
        this.loadingText = null;
        this.onSearch(
          this.selectedDriver,
          this.selectedDateRange,
          this.businessId
        );
        return true;
      } catch (e) {
        this.isLoading = false;
        this.loadingText = null;
        this.$emit("closeModal");
        return false;
      }
    },
    breachExistCheck(breach, breachesData) {
      return breachesData.some((breachData) => {
        return (
          formatTime(breach.time, breachData.timezone) ==
            formatTime(breachData.time, breachData.timezone) &&
          breach.type == breachData.type &&
          breach.option == breachData.option &&
          formatPeriod(breach.period) == formatPeriod(breachData.period) &&
          formatLevel(breach.level) == formatLevel(breachData.level)
        );
      });
    },
    calculateBreaches(settings, engineRegion) {
      const { driverId, businessId, events, timezone } = settings;
      return generateBreaches(
        driverId,
        businessId,
        events,
        timezone,
        engineRegion
      );
    },
    momentFormat(date, isShort = false) {
      if (date) {
        if (isShort) {
          return date ? moment(date).format("MMM D, YYYY") : "-";
        } else {
          return date ? moment(date).format("MMMM D, YYYY") : "-";
        }
      }
    },
  },
  computed: {
    breachesData() {
      return this.breaches.map((breach) => {
        return {
          ...breach,
          countingPoint: formatTime(breach.startPoint, breach.timezone),
          time: formatTime(breach.time, breach.timezone),
          period: formatPeriod(breach.period),
          level: formatLevel(breach.level),
        };
      });
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
