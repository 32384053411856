<template>
  <div class="relative">
    <div class="p-4">
      <div class="flex items-center">
        <template v-for="(stepperStep, index) in stepperSteps">
          <div
            :key="index"
            class="flex items-center relative transition duration-300 ease-in-out"
            :style="{
              color: stepperStep.isCompleted ? theme.secondaryColor : '#666666',
            }"
          >
            <div
              class="rounded-full transition duration-300 ease-in-out h-12 w-12 py-3 border-2 flex justify-center items-center"
              :style="{
                borderColor:
                  stepperStep.isCompleted ||
                  currentStep.stepSequence == stepperStep.stepSequence
                    ? theme.secondaryColor
                    : '#666666',
                backgroundColor:
                  stepperStep.isCompleted ||
                  currentStep.stepSequence == stepperStep.stepSequence
                    ? theme.secondaryColor
                    : 'inherit',
              }"
            >
              <font-awesome-icon
                :icon="stepperStep.stepIcon"
                class="text-xl m-auto"
                :style="{
                  color:
                    stepperStep.isCompleted ||
                    currentStep.stepSequence == stepperStep.stepSequence
                      ? '#FFFFFF'
                      : '#666666',
                }"
              />
            </div>
            <div
              class="absolute top-0 -ml-10 text-center mt-16 w-32 text-xs font-medium uppercase px-2 transition duration-300 ease-in-out"
              :style="{
                color:
                  stepperStep.isCompleted ||
                  currentStep.stepSequence == stepperStep.stepSequence
                    ? theme.secondaryColor
                    : '#666666',
              }"
            >
              {{ stepperStep.stepTitle }}
            </div>
          </div>
          <div
            v-if="index !== stepperSteps.length - 1"
            :key="'border-' + index"
            class="flex-auto border-t-2 transition duration-500 ease-in-out w-full border-gray-500"
            :style="{
              width: `${100 / stepperSteps.length}%`,
              borderColor: stepperStep.isCompleted
                ? theme.secondaryColor
                : '#666666',
            }"
          ></div>
        </template>
      </div>
    </div>
    <div class="mt-9 py-4">
      <div>
        <component
          :is="stepperSteps[currentStep.index].stepComponent"
          @nextStep="nextCompStep"
          @backStep="prevCompStep"
          :preData="nextData"
          :postData="previousData"
          @closeModal="closeModal"
          @closeRefresh="closeRefresh"
          @toast="toast"
          :detailsId="stepperSteps[currentStep.index].detailsId"
          :connectionType="stepperSteps[currentStep.index].connectionType"
          :parentUid="stepperSteps[currentStep.index].parentUid"
          :soloDriver="stepperSteps[currentStep.index].soloDriver"
          :availableSeats="stepperSteps[currentStep.index].availableSeats"
          :isBusinessDriverProfile="
            stepperSteps[currentStep.index].isBusinessDriverProfile
          "
          :isNonEwdImport="stepperSteps[currentStep.index].isNonEwdImport"
          :isTripCheck="stepperSteps[currentStep.index].isTripCheck"
          :defaultData="stepperSteps[currentStep.index].defaultData"
          :performance="stepperSteps[currentStep.index].performance"
          :filledForm="stepperSteps[currentStep.index].filledForm"
        ></component>
      </div>
      <div
        class="flex p-2 mt-4 absolute bottom-0 left-0 w-full"
        v-if="showNavigation"
      >
        <button
          class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-orange-primary hover:text-white hover:border-orange-primary bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
          v-if="currentStep.index > 0"
          @click="backStep()"
        >
          Previous
        </button>

        <div class="flex-auto flex flex-row-reverse">
          <button
            class="text-base ml-2 hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-orange-primary hover:text-white hover:border-orange-primary bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
            @click="nextStep()"
            :style="{ backgroundColor: theme.secondaryColor }"
          >
            Next
          </button>

          <button
            class="text-base hover:scale-110 focus:outline-none flex justify-center px-4 py-2 rounded font-bold cursor-pointer hover:bg-orange-primary hover:text-white hover:border-orange-primary bg-gray-100 text-gray-700 border duration-200 ease-in-out border-gray-600 transition"
            v-if="hasSkip"
          >
            Skip
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Stepper",
  props: {
    stepperSteps: {
      type: Array,
      default: function () {
        return [
          {
            stepIcon: "user",
            stepTitle: "Personal Info",
            stepSequence: "first",
            stepComponent: null,
            isCompleted: false,
            detailsId: null,
            parentUid: null,
            connectionType: null,
            defaultData: null,
          },
          {
            stepIcon: "briefcase",
            stepTitle: "Employment History",
            stepSequence: "second",
            stepComponent: null,
            isCompleted: false,
            detailsId: null,
            parentUid: null,
            connectionType: null,
            defaultData: null,
          },
        ];
      },
    },
    hasSkip: {
      type: Boolean,
      default: false,
    },
    defaultActiveStep: {
      type: Number,
      default: 0,
    },
    showNavigation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentStep: {},
      previousStep: {},
      nextButton: {},
      finalStep: false,
      nextData: {},
      previousData: {},
    };
  },
  methods: {
    activateStep(index, back = false) {
      if (this.stepperSteps[index]) {
        this.previousStep = this.currentStep;
        this.currentStep = {
          stepSequence: this.stepperSteps[index].stepSequence,
          index: index,
        };

        if (index + 1 === this.stepperSteps.length) {
          this.finalStep = true;
        } else {
          this.finalStep = false;
        }

        if (!back) {
          this.$emit("completed-step", this.previousStep);
        }
      }

      this.$emit("active-step", this.currentStep);
    },
    nextStepAction() {
      this.nextButton[this.currentStep.stepSequence] = true;
      if (this.finalStep) {
        this.$emit("active-step", this.currentStep.name);
      }
      let currentIndex = this.currentStep.index + 1;

      this.activateStep(currentIndex);
    },
    nextStep() {
      if (!this.$listeners || !this.$listeners["before-next-step"]) {
        this.nextStepAction();
      }

      this.$emit(
        "before-next-step",
        { currentStep: this.currentStep },
        (next = true) => {
          if (next) {
            this.nextStepAction();
          }
        }
      );
    },
    backStep() {
      this.$emit("clicking-back");
      let currentIndex = this.currentStep.index - 1;
      if (currentIndex >= 0) {
        this.activateStep(currentIndex, true);
      }
    },
    init() {
      this.activateStep(this.defaultActiveStep);
      this.stepperSteps.forEach((step) => {
        this.nextButton[step.stepSequence] = false;
      });
    },
    nextCompStep(preData) {
      this.nextData = preData;
      this.previousData = null;
      this.nextStep();
    },
    prevCompStep(postData) {
      this.previousData = postData;
      this.nextData = null;
      this.backStep();
    },
    closeModal() {
      this.$emit("closeModal");
    },
    closeRefresh() {
      this.$emit("closeRefresh");
    },
    toast(type, message) {
      this.$emit("toast", type, message);
    },
  },
  created() {
    this.init();
  },
  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
