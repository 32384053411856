<template>
  <div>
    <Table
      :tableHeader="tableHeader"
      :paginationSettings="paginationSettings"
      :data="approvalData"
      :hasFilters="{ pageCount: false, status: false, search: true }"
      @search-table="queryForKeywords"
    >
      <template slot="callToActionTableField">
        <td class="px-5 py-2">
          <div class="text-black-primary whitespace-no-wrap text-lg flex">
            <button
              class="btn btn-primary text-xs p-1"
              @click.prevent="approveVehicle()"
              :style="{ backgroundColor: theme.secondaryColor }"
            >
              Approve
            </button>
          </div>
        </td>
      </template>
      <!--TODO: This will be used for notification approve button -->
      <!-- <template slot="callToActionTop">
        <button class="btn btn-primary" @click.prevent="approveVehicle()">
          Approve
        </button>
      </template> -->
      <template slot="callToActionTableFieldBefore">
        <td class="px-5 py-2">
          <input
            v-model="approvedVehicles"
            class="form-check-input appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            id="flexCheckDefault"
          />
        </td>
      </template>
    </Table>
  </div>
</template>

<script>
import { Table } from "@/components/commons";
import { orderBy } from "lodash";
import { mapGetters } from "vuex";

export default {
  name: "Approval-Vehicle-Form",
  components: { Table },
  data() {
    return {
      approvalItems: [],
      modalHeight: "80vh",
      tableHeader: ["", "Plate Number", "Vehicle Class", "Vehicle Type"],
      paginationSettings: {
        page: 1,
        totalPages: 5,
        totalRecords: 50,
        visiblePageItemCount: 3,
      },
      searchKeyword: "",
      approvedVehicles: [],
    };
  },
  async mounted() {
    // TODO: Add fetch vehicles here
    // const me = this.$store.getters[`account/me`];
    // const vehicles = await this.$store.dispatch(`*vehicles fetch here! `,me.uid)
    // if(vehicles){
    //   const vehicleTableData = this.formatItem(_.orderBy(vehicles, ['createdAt'],['desc']));
    //   this.vehicles = vehicleTableData
    // }

    const mockData = [
      {
        _id: "qka8fprb880cn4vjht1bib59",
        registrationNumber: "0164311",
        vehicleClass: "HV",
        vehicleType: "Prime Mover",
      },
      {
        _id: "6upxzi2buoj96gqhunusfl19",
        registrationNumber: "723974",
        vehicleClass: "LV",
        vehicleType: "Motorbike",
      },
    ];

    const approvalTableData = this.formatItem(
      orderBy(mockData, ["createdAt"], ["desc"])
    );
    this.approvalItems = approvalTableData;
  },
  methods: {
    approveVehicle: function () {},
    formatItem(items) {
      let nItems = [];
      if (items) {
        items.map((item) => {
          const vehicle = {
            id: item["_id"],
            registrationNumber: item["registrationNumber"] || "",
            vehicleClass: item["vehicleClass"] || "",
            vehicleType: item["vehicleType"] || "",
          };
          nItems.push(vehicle);
        });
      }
      return nItems;
    },
    queryForKeywords(value) {
      this.searchKeyword = value;
    },
    parseVehicles(data) {
      return data.map((vehicleData) => [
        {
          id: vehicleData.id,
          name: vehicleData.registrationNumber,
          itemType: "string",
        },
        {
          id: vehicleData.id,
          name: vehicleData.vehicleClass,
          itemType: "string",
        },
        {
          id: vehicleData.id,
          name: vehicleData.vehicleType,
          itemType: "string",
        },
      ]);
    },
  },
  computed: {
    approvalData() {
      if (this.approvalItems) {
        if (this.searchKeyword) {
          const searchKeywordData = this.approvalItems.filter(
            (item) =>
              this.searchKeyword
                .toLowerCase()
                .split(" ")
                .every((v) =>
                  item.registrationNumber.trim().toLowerCase().includes(v)
                ) ||
              this.searchKeyword
                .toLowerCase()
                .split(" ")
                .every((v) =>
                  item.vehicleClass.trim().toLowerCase().includes(v)
                ) ||
              this.searchKeyword
                .toLowerCase()
                .split(" ")
                .every((v) => item.vehicleType.trim().toLowerCase().includes(v))
          );
          return this.parseVehicles(searchKeywordData);
        } else {
          return this.parseVehicles(this.approvalItems);
        }
      } else {
        return [];
      }
    },
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
