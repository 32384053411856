<template>
  <div>
    <div class="flex items-center mb-3 text-sm">
      <input
        v-model="showEndOfDay"
        type="checkbox"
        class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 rounded"
        @change="toggleEndOfDay()"
      />
      <label class="font-semibold">Show End of Day Flags</label>
    </div>
    <div
      class="w-full overflow-x-auto overflow-y-hidden driver-graph"
      v-html="data"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.driver-graph::v-deep {
  padding-bottom: 15px;
  .graphical-summary {
    margin-left: 0px;
  }
  .g-bg-blue {
    @apply bg-blue;
  }
}
</style>

<script>
export default {
  name: "GraphicalSummary",
  props: {
    data: String,
    displayEndOfDay: Boolean,
  },
  data() {
    return {
      showEndOfDay: this.displayEndOfDay,
    };
  },
  methods: {
    toggleEndOfDay() {
      this.$emit("toggleEndOfDay", this.showEndOfDay);
    },
  },
};
</script>
