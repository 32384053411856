import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import account from './account';
import partner from './partner';
import reseller from './reseller';
import business from './business';
import driver from './driver';
import techprovider from './techprovider';
import rolesandrespo from './role-respo';
import vehicle from './vehicle';
import form from './forms/form';
import hazard from './forms/filled-form/hazard';
import incident from './forms/filled-form/incident'
import defect from './forms/filled-form/defect'
import infringement from './forms/filled-form/infringement'
import filledform from './forms/filled-form/index'
import fittodrive from './forms/filled-form/fittodrive';
import prestart from './forms/filled-form/prestart';

import standardpricing from './standardpricing'
import devices from './devices'
import report from './report';
import breach from './breach';
import contract from './contract';
import lookup from './lookup';
import notification from './notification';
import billinginvoice from './billing-invoice';
import payment from './payment';
import theme from './theme'
import accreditation from './accreditation';
import jsonbuilder from './json-builder';
import reportscheduling from "./report-scheduling";
import investigation from "./investigation";
import modal from './modal'
import settings from './settings'
import performance from './performance'
import geotabDrivers from './geotab/driver'
import geotabVehicles from './geotab/vehicles'
import emailList from './email-list'
import logmasterIntegration from './logmaster-integration'
import dynamicLink from './dynamic-link';
import geotabUsers from './geotab/user'
import geotab from './geotab'
import file from './file'
import ai from './ai'


Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    account,
    billinginvoice,
    partner,
    reseller,
    business,
    driver,
    techprovider,
    form,
    hazard,
    incident,
    fittodrive,
    prestart,
    rolesandrespo,
    vehicle,
    standardpricing,
    devices,
    contract,
    report,
    breach,
    lookup,
    notification,
    payment,
    theme,
    accreditation,
    jsonbuilder,
    reportscheduling,
    investigation,
    modal,
    settings,
    performance,
    filledform,
    defect,
    infringement,
    geotabDrivers,
    geotabVehicles,
    emailList,
    logmasterIntegration,
    dynamicLink,
    geotabUsers,
    geotab,
    file,
    ai
  },
});
