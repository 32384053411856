<template>
  <div class="bg-black">
    <div class="container py-10 h-screen flex">
      <div class="login-container w-96 m-auto">
        <div class="text-center m-5 -mt-40">
          <a :href="$store.getters['baseURL']"
            ><img src="/assets/images/logo.png" alt="" class="w-64 m-auto"
          /></a>
        </div>
        <div class="rounded-lg bg-gray-50 p-10 shadow">
          <div v-if="!acceptForm">
            <div
              class="mb-2 font-italic text-md bg-white text-gray-600 px-5 py-3 rounded-xl"
            >
              By creating a password for the Logmaster Australia application,
              you have accepted the Logmaster Terms and Conditions, Privacy
              Policy and the End User Licence Agreement. If you do not accept,
              close this page without creating a password.
            </div>
            <div class="mb-2">
              <a
                href="https://logmaster.com.au/terms-and-conditions/"
                target="_blank"
                class="underline text-blue-300"
                >Terms and Conditions</a
              >
            </div>
            <div class="mb-2">
              <a
                href="https://logmaster.com.au/privacy-statement/"
                target="_blank"
                class="underline text-blue-300"
                >Privacy Statement</a
              >
            </div>
            <div class="mb-2">
              <a
                href="https://logmaster.com.au/logmaster-end-user-licence-agreement/"
                target="_blank"
                class="underline text-blue-300"
                >Logmaster End User Licence Agreement</a
              >
            </div>
            <div class="flex flex-col">
              <button
                class="btn btn-primary w-full mb-2"
                @click.prevent="agreeInTerms()"
              >
                Agree
              </button>
              <button
                class="btn btn-cancel disabled w-full mb-2"
                @click.prevent="cancelInTerms()"
              >
                Close
              </button>
            </div>
          </div>
          <div v-if="acceptForm">
            <PartnerAcceptForm></PartnerAcceptForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PartnerAcceptForm } from "@/components/forms";
export default {
  name: "Partner-Accept",
  components: { PartnerAcceptForm },
  data() {
    return {
      partnerId: null,
      acceptForm: false,
    };
  },
  async mounted() {
    this.partnerId = this.$route.params["partnerId"];
    this.partnerId = this.partnerId
      ? this.partnerId
      : this.$route.params["partnerId"];

    if (!this.partnerId)
      window.location.href = "/login?message=Request ID not found.&state=error";
  },
  methods: {
    agreeInTerms() {
      this.acceptForm = true;
    },
    cancelInTerms() {
      window.location.href = "/";
    },
  },
};
</script>
