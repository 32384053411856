<template>
  <div>
    <div v-if="!selectedDriver">
      <FormulateInput
        label="Email Address"
        name="emailAddress"
        type="email"
        v-model="searchKeyword"
        @input="inputChange($event)"
        validation="required"
        help="Contractor driver's email address."
      />
      <div class="flex w-full py-5" v-if="searchLoading">
        <div class="m-auto">
          <Spinner class="mx-auto mb-2"></Spinner>
          <span class="font-semibold text-gray-300">Loading...</span>
        </div>
      </div>
      <div
        class="text-red-500 font-bold text-sm text-center mt-5"
        v-if="errorMessage.length"
      >
        {{ errorMessage[0] }}
      </div>
      <Table
        :tableHeader="tableHeader"
        :data="drivers"
        :hasFilters="false"
        :hasActions="false"
      >
        <template #default="{ data }">
          <fragment v-if="data">
            <td class="px-5 py-2">
              <button class="btn btn-primary mt-2" @click="selectDriver(data)">
                Select
              </button>
            </td>
          </fragment>
        </template>
      </Table>
    </div>
    <div v-if="selectedDriver" class="mb-16">
      <FormulateForm v-model="values" @submit="onSendInvitation">
        <div class="text-center mb-6">
          <div class="font-bold text-black-primary text-2xl">
            {{ selectedDriver.driverName }}
          </div>
          <div
            class="font-bold text-xs"
            :class="[
              selectedDriver.isActive ? 'text-green-500' : 'text-red-500',
            ]"
          >
            {{ selectedDriver.isActive ? "ACTIVE" : "INACTIVE" }}
          </div>
        </div>
        <div class="w-full pb-1">
          <div class="text-sm font-bold">Driver Details</div>
        </div>
        <div class="bg-white px-5 pt-5 pb-3 border rounded">
          <div class="mb-3 flex">
            <div class="flex-1">
              <div class="text-xs">Email Address</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedDriver.emailAddress }}
              </div>
            </div>
            <div class="flex-1">
              <div class="text-xs">Date Of Birth</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedDriver.driverDateOfBirth }}
              </div>
            </div>
          </div>
          <div class="mb-3 flex">
            <div class="flex-1">
              <div class="text-xs">Licence</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedDriver.driversLicenseNumber }}
              </div>
            </div>
            <div class="flex-1">
              <div class="text-xs">Licence State</div>
              <div class="font-bold text-black-primary text-base">
                {{ selectedDriver.driversLicenseState }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-full pb-1 mt-3">
          <div class="text-sm font-bold">Business Details</div>
        </div>
        <div class="bg-white px-5 pt-5 pb-3 border rounded mb-5">
          <div class="flex-1">
            <div class="text-xs">Record Keeper Address</div>
            <FormulateInput
              type="places-search-input"
              name="recordKeeperAddress"
              validation="required"
            />
          </div>
          <div class="flex-1">
            <div class="text-xs">Base Location</div>
            <FormulateInput
              type="places-search-input"
              name="baseLocation"
              validation="required"
            />
          </div>
          <div class="flex-1">
            <div class="text-xs">Base Time Zone</div>
            <FormulateInput
              type="select"
              name="baseTimeZone"
              :options="timezones"
            />
          </div>
          <div class="flex-1">
            <div class="text-xs">Region</div>
            <FormulateInput
              type="select"
              name="engineRegion"
              :options="regions"
            />
          </div>
          <div>
            <FormulateInput
              type="group"
              name="certificate"
              label="Accreditation (Optional)"
              labelClass="font-semibold text-sm block -top-7 -left-0 absolute"
              class="border rounded p-5 mb-5 mt-10 relative"
            >
              <button @click.prevent="showDriverList">
                <small
                  v-if="!isDriverListOpen"
                  class="btn-primary btn-xs px-3 shadow rounded-lg"
                >
                  + Select from accreditation list
                </small>
                <small
                  v-else
                  class="bg-blue-200 text-white btn-xs px-3 shadow rounded-lg"
                >
                  <font-awesome-icon icon="times" />
                </small>
              </button>

              <FormulateInput
                v-if="!isDriverListOpen"
                type="text"
                label="BFM Number"
                v-model="BFMStringBased"
                valdation="optional"
              />

              <FormulateInput
                v-else
                type="cert-select"
                name="BFMNumber"
                style="margin-bottom: 0px"
                label="BFM Number"
                class="pb-4 content-center"
                validation="optional"
                @input="onChange($event)"
                v-model="BFMselectionBased"
                :clearable="false"
              />

              <FormulateInput
                v-if="isDriverListOpen"
                type="date"
                name="inductionDate"
                label="Induction Date"
                validation="optional"
                v-model="inductionDate"
              />
            </FormulateInput>
          </div>
        </div>

        <StyledSubmit :backgroundColor="theme.secondaryColor">
          <FormulateInput
            type="submit"
            input-class="btn btn-primary w-full"
            :disabled="submitting"
          >
            <font-awesome-icon
              v-if="submitting"
              :icon="['fas', 'spinner']"
              spin
            />&nbsp;
            {{ !submitting ? "Send Invitation" : "Sending..." }}
          </FormulateInput>
        </StyledSubmit>
      </FormulateForm>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import { Table } from "@/components/commons";
import Spinner from "@/components/commons/ui/Spinner";
import { TIMEZONE } from "@/constants/location";
import { REGION } from "@/constants/region";
import { StyledSubmit } from "@/styled-components";

export default {
  name: "New-Driver-Contractor",
  components: { Table, Spinner, StyledSubmit },
  data() {
    return {
      values: {},
      timezones: TIMEZONE,
      regions: REGION,
      submitting: false,
      tableHeader: ["Driver Name", "Email Address", "Licence", "Action"],
      drivers: [],
      driverData: [],
      searchKeyword: "",
      errorMessage: [],
      searchLoading: false,
      selectedDriver: null,
      isDriverListOpen: false,
      BFMStringBased: "",
      BFMselectionBased: {},
      inductionDate: "",
    };
  },

  watch: {
    isDriverListOpen: function () {
      this.BFMselectionBased = {};
      this.inductionDate = "";
    },
  },

  methods: {
    parseDrivers(data) {
      return data.map((driverData) => [
        {
          id: driverData._id,
          name: driverData.driverName,
          itemType: "name",
          hasImage: false,
          hasLink: true,
        },
        {
          id: driverData._id,
          name: driverData.emailAddress,
          itemType: "string",
        },
        {
          id: driverData._id,
          name: driverData.driversLicenseNumber,
          itemType: "string",
        },
      ]);
    },
    validateEmail: (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    async searchDriver(search) {
      if (!this.validateEmail(search)) {
        this.errorMessage.push("Invalid email address please try again.");
        this.searchLoading = false;
      }
      if (this.validateEmail(search)) {
        this.errorMessage = [];
        try {
          const soloDrivers = await this.$store.dispatch(
            `driver/getDriverByEmail`,
            search
          );
          if (soloDrivers) {
            this.driverData = [soloDrivers];
            this.drivers = this.parseDrivers([soloDrivers]);
          } else {
            this.drivers = [];
            this.driverData = [];
          }
          this.searchLoading = false;
        } catch (error) {
          this.errorMessage = [error.message];
          this.searchLoading = false;
          this.soloDrivers = [];
          this.drivers = [];
        }
      }
    },
    async selectDriver(driver) {
      const me = this.$store.getters[`account/me`];
      let selectedDriver = this.driverData.find((x) => x._id == driver.id);
      const contract = await this.$store.dispatch(
        `business/getContractByDriver`,
        {
          uid: driver.id,
          query: {
            skip: 0,
            limit: 10,
            filter: { businessId: { $eq: me?.business?._id }, status: { $in: ['linked', 'pending'] } },
          },
        }
      );

      this.values["baseTimeZone"] = selectedDriver?.driversBaseTimeZone;

      if (selectedDriver.entityId == me?.business?._id) {
        this.errorMessage = [
          "This driver is already under your account. Contractor invitation is not needed.",
        ];
      } else if (contract && contract?.results?.length) {
        this.errorMessage = ["This driver is already invited as a contractor."];
      } else {
        this.selectedDriver = selectedDriver;
        this.selectedDriver.driverDateOfBirth = moment(
          this.selectedDriver.driverDateOfBirth
        ).format("YYYY-MM-DD");
      }
    },
    inputChange: debounce(function (event) {
      if (!event) {
        this.searchLoading = false;
        this.errorMessage = [];
      }
      if (event) {
        this.searchLoading = true;
        this.searchDriver(event);
      }
    }, 500),
    async onSendInvitation() {
      this.submitting = true;
      const me = this.$store.getters[`account/me`];

      if (
        (this.isDriverListOpen &&
          !_.isEmpty(this.BFMselectionBased) &&
          _.isEmpty(this.inductionDate)) ||
        (!_.isEmpty(this.inductionDate) && _.isEmpty(this.BFMselectionBased))
      ) {
        this.errorMessage = "Accreditation details incomplete";
        this.loading = false;
        return;
      }

      const newContract = {
        from: "business",
        driverId: this.selectedDriver._id,
        businessId: me?.business?._id,
        profile: {
          BFM2up: false,
          BFMSolo: false,
          baseTimeZone: this.values["baseTimeZone"],
          baseLocation: this.values["baseLocation"],
          recordKeeperAddress: this.values["recordKeeperAddress"],
          engineRegion: this.values["engineRegion"],
        },
        startDate: moment(),
        isContractor: true,
      };

      let message = null;
      await this.$store.dispatch(`business/createContract`, newContract).then(
        async () => {
          if (
            newContract["profile"] &&
            this.isDriverListOpen &&
            !_.isEmpty(this.inductionDate) &&
            !_.isEmpty(this.BFMselectionBased)
          ) {
            const assignCertPayload = {
              driverIds: this.selectedDriver._id,
              inductionDate: this.inductionDate,
            };
            await this.$store.dispatch("accreditation/assignDriver", {
              data: assignCertPayload,
              certId: this.BFMselectionBased._id,
            });
          }

          message = {
            state: "success",
            message: "Contractor Invitation sent.",
          };
        },
        (error) => (message = { state: "error", message: error?.message })
      );

      this.$store.commit("setDialogNotify", true);
      this.$store.commit("setMessageNotify", message);
      this.$emit("closeRefresh");
      this.submitting = false;
    },

    onChange(data) {
      if (data) {
        this.BFMselectionBased = data;
      }
    },

    showDriverList() {
      this.isDriverListOpen = !this.isDriverListOpen;
    },
  },

  computed: {
    ...mapGetters("theme", {
      theme: "getColorScheme",
    }),
  },
};
</script>
